import {
    srpURL,
    minimumBreadcrumbLength,
    productLength,
    toGetLastBreadcrumb,
    defaultCheckValue,
    toGetPrevCategory,
} from './Breadcrumb.constant'
import { isArrayNotEmpty, libUtils } from '@nl/lib'
import { pageTypes } from '../../config'
import getPageType from '../../utils/getPageType'
import { BreadcrumbParentPageLink, BreadcrumbDataProps } from './BreadcrumbsComp.type'
import { BreadcrumbLinkData, Facet, PremiumFilter, SortOption } from '../../redux/models/productData.interface'
import { MagicNumber } from '../../analytics/analytics.type'
import sessionStorageService from '../../utils/sessionStorageService'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { storageData } from '../../globalConstants'
import { CategoryPagesType } from '../../global.type'
import { CategoryBreadCrumb } from '../../redux/models/category.interface'

const {
    plp,
    pdpPage,
    searchPage,
    categoryPages,
    eventListing,
    promoListing,
    checkout,
    packageLanding,
    store,
    holiday,
} = pageTypes
/**
 * Function to display breadcrumb links for back navigation in pdp page based on plp and srp in url condition
 * @param {string | undefined} backLabel - backToResults link
 * @returns {string | undefined} returns backNavigation link
 */
const backNavigationPDP = (backLabel: string): string | undefined => {
    if (
        !!RegExp(srpURL).exec(window.location.href) ||
        checkDataLength(sessionStorageService.getItem(storageData.backToResultLink))
    ) {
        return backLabel
    } else return
}

export const getBreadcrumbParentPageLink = (name?: string, link?: string, pName?: string): BreadcrumbParentPageLink => {
    return {
        name,
        link,
        pName,
    }
}

/**
 * Function to display breadcrumb mobile links in pdp page
 * @param {BreadcrumbParentPageLink} homePageItem - homePageItem
 * @param {BreadcrumbParentPageLink[]} breadcrumbData - breadcrumbData
 * @param {BreadcrumbParentPageLink[]} backNavigation - backNavigation
 * @param {string} backToLabel - backToLabel
 * @param {string} currentPageName - currentPageName
 * @param {boolean} isStaticPages - isStaticPages
 * @returns {BreadcrumbParentPageLink[]} returns breadcrumb mobile array of brandPage
 */
const getMobileBreadcrumb = (
    homePageItem: BreadcrumbParentPageLink,
    breadcrumbData: BreadcrumbParentPageLink[],
    backNavigation: BreadcrumbParentPageLink,
    backToLabel = '',
    currentPageName: string,
    isStaticPages: boolean,
): BreadcrumbParentPageLink => {
    const pageType = getPageType()
    if (isArrayNotEmpty(breadcrumbData)) {
        const breadcrumbLength = breadcrumbData.length
        const mobileBreadcrumb =
            breadcrumbLength > minimumBreadcrumbLength
                ? categoryPages.includes(pageType as CategoryPagesType)
                    ? breadcrumbData[breadcrumbLength - toGetPrevCategory]
                    : breadcrumbData[breadcrumbLength - toGetLastBreadcrumb]
                : isStaticPages && currentPageName
                ? breadcrumbData[MagicNumber.ZERO]
                : homePageItem
        if (!backNavigation.name) {
            return {
                name: `${backToLabel} ${String(mobileBreadcrumb.name)}`,
                link: `${String(mobileBreadcrumb.link)}`,
            }
        } else return mobileBreadcrumb
    } else {
        return homePageItem
    }
}

/**
 *@param {string} productName
 * @param {string} pName
 * @param {string} link
 * @param {BreadcrumbParentPageLink[]} breadcrumb
 * @param {BreadcrumbParentPageLink} homePageItem
 * @param {BreadcrumbParentPageLink} backNavigation
 *@param {string} displayProductName
 * @returns {BreadcrumbParentPageLink[]} returns breadcrumb array of pdp page
 */
const getPdpBreadcrumbData = (
    productName: string,
    pName: string,
    link: string,
    breadcrumb: BreadcrumbParentPageLink[],
    homePageItem: BreadcrumbParentPageLink,
    backNavigation: BreadcrumbParentPageLink,
    displayProductName: string,
): BreadcrumbParentPageLink[] => {
    const newBreadcrumb = [...breadcrumb]

    if (productName) {
        newBreadcrumb.pop()
        if (displayProductName) {
            newBreadcrumb.push({ name: productName, pName, link })
        }
    }

    const baseBreadcrumb = [homePageItem, ...newBreadcrumb]

    if (backNavigation.name) {
        return [backNavigation, ...baseBreadcrumb]
    }

    return baseBreadcrumb
}

/**
 * Function to get desktop breadcrumbs list
 * @param {BreadcrumbDataProps} breadcrumbDataProps - breadcrumbDataProps
 * @returns {BreadcrumbParentPageLink[]} returns breadcrumb array
 */
// eslint-disable-next-line complexity
export const getBreadcrumbDesktopLink = (breadcrumbDataProps: BreadcrumbDataProps): BreadcrumbParentPageLink[] => {
    const {
        backLabel,
        breadcrumb,
        currentPageName,
        currentPageLink,
        homePageItem,
        displayProductName,
        isBackToSearchResults,
        backToCartItem,
    } = breadcrumbDataProps
    const pageType = getPageType()
    const currentPageItem = getBreadcrumbParentPageLink(currentPageName, currentPageLink)
    const navLabel = backNavigationPDP(backLabel)
    const backNavigationLink = checkDataLength(sessionStorageService.getItem(storageData.backToResultLink))
        ? sessionStorageService.getItem(storageData.backToResultLink)
        : document.referrer
    const backNavigation = getBreadcrumbParentPageLink(navLabel, backNavigationLink || '')

    /**
     * Function to get Product Name with a character count approx. 25 characters before truncation
     * @returns {string} returns product name
     */
    const fetchProductName = (): string => {
        const pName: string = breadcrumb[breadcrumb.length - MagicNumber.ONE].name || ''
        return pName.length > productLength ? `${pName.substring(0, productLength)}...` : pName
    }

    const defaultPLPBreadCrumbList: BreadcrumbParentPageLink[] = [homePageItem, ...breadcrumb]
    const defaultSRPBreadCrumbList: BreadcrumbParentPageLink[] = [
        homePageItem,
        breadcrumb[toGetLastBreadcrumb - MagicNumber.ONE],
    ]

    if (pageType === plp) {
        return defaultPLPBreadCrumbList
    } else if (pageType === pdpPage) {
        // pageType is pdp
        const productName = fetchProductName()
        const pName = breadcrumb[breadcrumb.length - MagicNumber.ONE].name
        const link = breadcrumb[breadcrumb.length - MagicNumber.ONE].link

        return getPdpBreadcrumbData(
            productName,
            pName,
            link,
            breadcrumb,
            homePageItem,
            backNavigation,
            displayProductName,
        )
    } else if (pageType === searchPage || categoryPages.includes(pageType as CategoryPagesType)) {
        return isBackToSearchResults
            ? ([homePageItem, breadcrumb[toGetLastBreadcrumb]] as BreadcrumbParentPageLink[])
            : pageType === searchPage
            ? defaultSRPBreadCrumbList
            : defaultPLPBreadCrumbList
    } else if (pageType === checkout) {
        return [backToCartItem] as BreadcrumbParentPageLink[]
    } else {
        // non pdp/plp pages
        return [homePageItem, ...breadcrumb, currentPageItem] as BreadcrumbParentPageLink[]
    }
}

/**
 * Function to get mobile breadcrumbs list
 * @param {BreadcrumbDataProps} breadcrumbDataProps - breadcrumbDataProps
 * @returns {BreadcrumbParentPageLink[]} returns breadcrumb array
 */
// eslint-disable-next-line complexity
export const getBreadcrumbMobileLink = (breadcrumbDataProps: BreadcrumbDataProps): BreadcrumbParentPageLink[] => {
    const {
        backLabel,
        breadcrumb,
        backToSearchResults,
        backToLabel = '',
        homePageItem,
        displayProductName,
        isBackToSearchResults,
        backToCartItem,
    } = breadcrumbDataProps

    const pageType = getPageType()
    const breadcrumbLength = breadcrumb.length
    const navLabel = backNavigationPDP(backLabel)
    const backNavigationLink = checkDataLength(sessionStorageService.getItem(storageData.backToResultLink))
        ? sessionStorageService.getItem(storageData.backToResultLink)
        : document.referrer
    const backNavigation =
        pageType === pageTypes.pdpPage && getBreadcrumbParentPageLink(navLabel, backNavigationLink || '')
    const isStaticPages =
        pageType === eventListing ||
        pageType === promoListing ||
        pageType === packageLanding ||
        pageType === store ||
        pageType === holiday
    const isDynamicPages =
        pageType === plp || pageType === pdpPage || categoryPages.includes(pageType as CategoryPagesType)
    if (isDynamicPages || isStaticPages) {
        /**
         * Function to get product name if pName is greater than 28 char will add '...'
         * @returns {string} will return the product name
         */
        const fetchProductName = (): string => {
            const pName: string = breadcrumb[breadcrumb.length - MagicNumber.ONE].name || ''
            return pName.length > productLength ? `${pName.substring(0, productLength)}...` : pName
        }
        if (pageType === pdpPage) {
            const productName = fetchProductName()
            const pName = breadcrumb[breadcrumb.length - MagicNumber.ONE].name
            const link = breadcrumb[breadcrumb.length - MagicNumber.ONE].link
            return getPdpBreadcrumbData(
                productName,
                pName,
                link,
                breadcrumb,
                homePageItem,
                backNavigation,
                displayProductName,
            )
        } else if (pageType === plp) {
            return [homePageItem, ...breadcrumb]
        } else {
            const mobileBreadcrumb = getMobileBreadcrumb(
                homePageItem,
                breadcrumb,
                backNavigation,
                backToLabel,
                breadcrumbDataProps?.currentPageName || '',
                isStaticPages,
            )
            return !!backNavigation.name ? [backNavigation, mobileBreadcrumb] : [mobileBreadcrumb]
        }
    } else if (pageType === searchPage) {
        return isBackToSearchResults
            ? [
                  {
                      name: backToSearchResults,
                      link: breadcrumb[toGetLastBreadcrumb].link,
                  },
              ]
            : [
                  {
                      name: `${backToLabel} ${String(homePageItem.name)}`,
                      link: homePageItem.link,
                  },
              ]
    } else if (pageType === checkout) {
        return [backToCartItem] as BreadcrumbParentPageLink[]
    } else {
        return isArrayNotEmpty(breadcrumb)
            ? ([breadcrumb[breadcrumbLength - toGetLastBreadcrumb]] as BreadcrumbParentPageLink[])
            : [
                  {
                      name: `${backToLabel} ${String(homePageItem.name)}`,
                      link: homePageItem.link,
                  },
              ]
    }
}

/**
 * This function returns breadcrumbList
 * @param {BreadcrumbLinkData[]} breadcrumbList - breadcrumbList
 * @returns {BreadcrumbParentPageLink[]} returns breadcrumbList
 */
export const getBreadcrumbList = (breadcrumbList: BreadcrumbLinkData[]): BreadcrumbParentPageLink[] => {
    return breadcrumbList.map((breadcrumb: BreadcrumbLinkData) => {
        const { label, url } = breadcrumb
        return {
            name: label,
            link: url,
        }
    })
}

/**
 * Memorized FacetValue selected check function
 * @param {Facet[]} facetsData - facetsData
 * @returns {boolean} returns boolean
 */
export const checkFacetSelectedValue = (facetsData: Facet[]): boolean => {
    let selectedFacet = 0
    isArrayNotEmpty(facetsData) && facetsData.forEach((value: Facet) => (value.selected ? selectedFacet++ : null))
    return !!selectedFacet
}

/**
 * Memorized SortValue selected check function
 * @param {SortOption[]} sortData - sortData
 * @returns {boolean} returns boolean
 */
export const checkSortSelectedValue = (sortData: SortOption[]): boolean => {
    let selectedSort = false
    const updatedSortList = isArrayNotEmpty(sortData) ? sortData.slice(defaultCheckValue) : []
    updatedSortList.forEach((value: SortOption) => (value.selected ? (selectedSort = true) : null))
    return selectedSort
}

/**
 * Memorized  function to check SaleItem & inStockAtMyStore selected value
 * @param {PremiumFilter | undefined } itemData - itemData
 * @returns {boolean|undefined} returns boolean or undefined as these objects are optional
 */
export const checkSaleInstoreItemSelected = (itemData: PremiumFilter | undefined): boolean | undefined => {
    return itemData && Object.values(itemData) && itemData.selected
}

/**
 * Function to check for non pdp/non plp pages
 * @returns {boolean} returns boolean
 */
export const isDynamicPage = (): boolean => {
    const pageType = getPageType()
    return (
        pageType !== pdpPage &&
        pageType !== plp &&
        pageType !== searchPage &&
        !categoryPages.includes(pageType as CategoryPagesType) &&
        pageType !== pageTypes.store
    )
}

/**
 * Function to convert categoryBreadCrumbs array to BreadcrumbLinkData array
 * @param {CategoryBreadCrumb[]} categoryBreadCrumbs
 * @returns {BreadcrumbLinkData[]}
 */
export const convertToBreadCrumbLinkData = (categoryBreadCrumbs: CategoryBreadCrumb[]): BreadcrumbLinkData[] => {
    return categoryBreadCrumbs.map(categoryBreadCrumb => ({
        ...categoryBreadCrumb,
        label: libUtils.getStringOrDefault(categoryBreadCrumb.label),
        url: libUtils.getStringOrDefault(categoryBreadCrumb.url),
    }))
}
