import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators/stickyBanner.actionCreators'
type Action = ActionType<typeof actions>

export interface stickyBannerState {
    isStickyBannerVisible: boolean
}

const initialState: stickyBannerState = {
    isStickyBannerVisible: false,
}

/**
 * Reducer function for managing the state of the sticky banner visibility.
 * @param {stickyBannerState} state - The current state of the sticky banner.
 * @param {Action} action - The action dispatched to update the state.
 * @returns {stickyBannerState} The updated state of the sticky banner.
 */
export const stickyBannerReducer = (state: stickyBannerState = initialState, action: Action): stickyBannerState => {
    if (action.type === getType(actions.setIsStickyBannerVisibleAction)) {
        return { ...state, isStickyBannerVisible: action.payload }
    } else {
        return state
    }
}
