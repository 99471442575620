import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/automotiveVehicle.actionCreators'
import {
    setTabSelectorAction,
    updateTiresDataAction,
    updateVehicleDataAction,
    fetchTiresDataSuccessAction,
    fetchWheelsDataSuccessAction,
    setVehicleSelectionModeAction,
    resetTireDataAction,
    setVehicleApiStatusAction,
} from '../actionCreators/automotiveVehicle.actionCreators'
import { ProductDataType } from '../models/productData.interface'

import { TireType } from '../models/tireVehicle.interface'
import { Vehicle } from '@nl/lib'

type Action = ActionType<typeof actions>

export interface InitialVehicleTireState {
    vehicleData: Vehicle | null
    tiresData: TireType | null
    isTiresDataSyncedWithLSOnPageLoad: boolean
    selectedTab?: string
    packageTiresData: ProductDataType
    packageWheelsData: ProductDataType
    vehicleSelectionMode: string
    vehicleApiStatus: boolean
}

export const initialState: InitialVehicleTireState = {
    vehicleData: null,
    tiresData: null,
    isTiresDataSyncedWithLSOnPageLoad: false,
    packageTiresData: {} as unknown as ProductDataType,
    packageWheelsData: {} as unknown as ProductDataType,
    vehicleSelectionMode: '',
    vehicleApiStatus: false,
}

export const updatedVehicleTiresDataReducer = (
    state: InitialVehicleTireState = initialState,
    action: Action,
): InitialVehicleTireState => {
    switch (action.type) {
        case getType(updateTiresDataAction):
            return { ...state, tiresData: action.payload }
        case getType(actions.setIsTiresDataSyncedWithLSOnPageLoadAction):
            return { ...state, isTiresDataSyncedWithLSOnPageLoad: action.payload }
        case getType(updateVehicleDataAction):
            return { ...state, vehicleData: action.payload }
        case getType(setTabSelectorAction):
            return { ...state, selectedTab: action.payload }
        case getType(fetchTiresDataSuccessAction): {
            return { ...state, packageTiresData: action.payload }
        }
        case getType(fetchWheelsDataSuccessAction): {
            return { ...state, packageWheelsData: action.payload }
        }
        case getType(setVehicleSelectionModeAction): {
            return { ...state, vehicleSelectionMode: action.payload }
        }
        case getType(resetTireDataAction): {
            return { ...state, tiresData: null }
        }
        case getType(setVehicleApiStatusAction): {
            return { ...state, vehicleApiStatus: action.payload }
        }
        default:
            return state
    }
}
