import { pageTypes } from '../../config'
import { StorePageHelper } from './storePage.helper'
import { PdpPageHelper } from './pdpPage.helper'
import { PlpPageHelper } from './plpPage.helper'
import { BrandPageHelper } from './brandPage.helper'
import { ProductResponseData } from '../../redux/models/product.interface'

/**
 * This function return pageHelper by page name
 * @param {string} pageName page name
 * @param {ProductResponseData} productData product data
 * @returns {class} pageHelper class
 */
export function getPageHelper(
    pageName: string,
    productData?: ProductResponseData,
): StorePageHelper | PdpPageHelper | PlpPageHelper | BrandPageHelper {
    switch (pageName) {
        case pageTypes.store:
            return new StorePageHelper()
        case pageTypes.pdpPage:
            return new PdpPageHelper(productData as ProductResponseData)
        case pageTypes.brandPage:
            return new BrandPageHelper()
        default:
            return new PlpPageHelper()
    }
}
