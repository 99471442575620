import { Dispatch } from 'redux'

import { CertonaResponseType } from '../../certona/certona.type'
import { removeSpecificSchemaFromCertonaData, storeCertonaProductRecommendationData } from '../actionCreators'

export const storeCertonaRecommendationData =
    (data: CertonaResponseType) =>
    (dispatch: Dispatch): void => {
        dispatch(storeCertonaProductRecommendationData(data))
    }

/**
 * function to dispatch action for removal of specific schema id from certona redux state.
 * This is needed to refresh certona data. (ex:-CartAccessoriesFlyout scenario where we are calling same schema id for different products and getting different pcodes.)
 * @param {string} data
 * @returns {void}
 */
export const removeSpecificSchemaFromCertona =
    (data: string) =>
    (dispatch: Dispatch): void => {
        dispatch(removeSpecificSchemaFromCertonaData(data))
    }
