import { Dispatch } from 'react'

import { setToastDetails } from '../../redux/actions'
import { ToastProps } from '../ToastWrapper/ToastWrapper.type'
import sessionStorageService from '../../utils/sessionStorageService'
import { libUtils } from '@nl/lib'
import { IGlobalProps } from '../../redux/models/commonContent.interface'

/**
 *
 * @param {boolean} showToast
 * @param {ToastProps} toastProps - only static constants but not callbacks.
 * @param {string} componentName - Name to identify the toast to extract callbacks.
 * @param {Dispatch<any>} dispatch
 */
export const dispatchToast = (
    showToast: boolean,
    toastProps: ToastProps,
    componentName: string,
    dispatch: Dispatch<any>,
): void => {
    dispatch(
        setToastDetails({
            showToast,
            toastProps,
            componentName,
        }),
    )
}

/**
 * Function returns caution toast props for display warning message with icon
 * @param {stirng} message to display in error toast
 * @returns {ToastProps} object with message and icon
 */
export const getCautionToastProps = (message: string): ToastProps => {
    return {
        success: false,
        options: {
            toastErrorMessage: message,
            toastErrorIcon: 'ct-notification-caution',
        },
    }
}

/**
 * Function get loginSuccess toast props for display after sso login
 * @returns {ToastProps} object with message and icon
 */
export const getLoginSuccessToastProps = (): ToastProps => {
    const language = libUtils.getLanguage()
    const commonContent = sessionStorageService.getItem(`global_props_${language}`)
    const hasContent = JSON.parse(commonContent as string) as IGlobalProps
    return {
        options: {
            toastSuccessMessage: hasContent?.authentication?.loginSuccessMessage,
            toastSuccessIcon: 'ct-notification-success-green',
        },
    }
}
