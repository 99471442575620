// This file contains Shoppable Component's constants
export const constantValues = {
    twoProductsClassName: 'two-products',
    fourProductsClassName: 'four-products',
    maximumProductQuantity: 4,
    minimumProductQuantity: 2,
    acceptedBadges: ['PROMO', 'CLEARANCE', 'SALE'] as ('PROMO' | 'CLEARANCE' | 'SALE')[],
    analyticsLocValueForShoppableComponent: 'sc',
} as const

export const shoppable = 'shoppable'
