import { Dispatch } from 'redux'

import {
    setShowSpinner,
    startSpinnerWithCounter as startSpinner,
    stopSpinnerWithCounter as stopSpinner,
} from '../actionCreators/spinner.actionCreators'
import {
    START_SPINNER_WITH_COUNTER,
    STOP_SPINNER_WITH_COUNTER,
    SET_SHOW_SPINNER,
} from '../types/spinner/spinner.actionTypes.constant'
import { ShowSpinner } from '../reducers/spinner.reducer'
import { SpinnerTypes } from '@nl/lib'

export const showSpinner =
    (show: boolean, type: string = SpinnerTypes.DEFAULT) =>
    (dispatch: Dispatch<{ type: typeof SET_SHOW_SPINNER; payload: ShowSpinner }>): void => {
        dispatch(setShowSpinner(show, type))
    }

export const startSpinnerWithCounter =
    () =>
    (dispatch: Dispatch<{ type: typeof START_SPINNER_WITH_COUNTER }>): void => {
        dispatch(startSpinner())
    }

export const stopSpinnerWithCounter =
    () =>
    (dispatch: Dispatch<{ type: typeof STOP_SPINNER_WITH_COUNTER }>): void => {
        dispatch(stopSpinner())
    }
