import { createAction } from 'typesafe-actions'

import { EstimateDeliveryDate } from '../models/estimateDeliveryDate.interface'
import {
    FETCH_ESTIMATE_DELIVERY_DATE_DATA_SUCCESS,
    FETCH_ESTIMATE_DELIVERY_DATE_DATA_ERROR,
    RESET_ESTIMATE_DELIVERY_DATE,
} from '../types/estimateDeliveryDate/estimateDeliveryDate.actionTypes.constant'

export const fetchEstimateDeliveryDateAction = createAction(
    FETCH_ESTIMATE_DELIVERY_DATE_DATA_SUCCESS,
)<EstimateDeliveryDate>()

export const estimateDeliveryDateFailureAction = createAction(FETCH_ESTIMATE_DELIVERY_DATE_DATA_ERROR)()

export const resetEstimateDeliveryDateAction = createAction(RESET_ESTIMATE_DELIVERY_DATE)()
