import { useCallback, useEffect } from 'react'

import { isArrayEmpty } from '@nl/lib'
import { VariantsInfo } from '../../../redux/models/miniPDPFlyout.interface'
import {
    resetWishlistActive,
    setSelectedVariantIDAction,
    setSelectedVariantValueAction,
    updateSelectedProductCodeAction,
} from '../../../redux/actionCreators'
import {
    selectedFirstVariantKey,
    selectedSecondVariantKey,
    selectedThirdVariantKey,
} from '../components/MiniBuyBox/MiniBuyBox.constant'
import { MagicNumber } from '../../../analytics/analytics.type'
import { getProductSKUCode } from '../../BuyBox/BuyBox.helper'
import { isAtleastOneParamValid } from '../../../utils/getFilteredCartItems'
import { useAppDispatch } from '../../../hooks/react-redux.hook'

/**
 *
 * @param dispatch
 * @param variantsInfo
 * @param setShowErrorMsgForVariantSelection
 */
export const useVariantsHandlers = (
    variantsInfo: VariantsInfo,
    setShowErrorMsgForVariantSelection: (value: boolean) => void,
): Record<string, (value: string, altParam?: string) => void> => {
    const dispatch = useAppDispatch()
    const { productData, selectedFirstVariantId, selectedSecondVariantId, selectedThirdVariantId } = variantsInfo
    const setSelectedCode = useCallback(
        (selectedCode: string): void => {
            dispatch(updateSelectedProductCodeAction(selectedCode))
            setShowErrorMsgForVariantSelection(false)
        },
        [dispatch],
    )

    const resetWishlistActiveHandler = useCallback(() => dispatch(resetWishlistActive()), [dispatch])

    /**
     * This function used to set selected first variant name
     * @param {string} selectedFirstVariantValue selected first variant name
     * @param {string} selectedFirstVariantDisplayNameVal selected first variant display name
     * @returns {void} returns void
     */
    const setSelectedFirstVariantValue = useCallback(
        (selectedFirstVariantValue: string, selectedFirstVariantDisplayNameVal?: string): void => {
            dispatch(
                setSelectedVariantValueAction({
                    variantKey: selectedFirstVariantKey,
                    variantValue: selectedFirstVariantValue,
                    selectedFirstVariantDisplayName: selectedFirstVariantDisplayNameVal,
                }),
            )
            setShowErrorMsgForVariantSelection(false)
        },
        [dispatch, setShowErrorMsgForVariantSelection],
    )

    /**
     * This function used to set selected second variant name
     * @param {string} selectedSecondVariantValue selected second variant name
     * @returns {void} returns void
     */
    const setSelectedSecondVariantValue = useCallback(
        (selectedSecondVariantValue: string): void => {
            dispatch(
                setSelectedVariantValueAction({
                    variantKey: selectedSecondVariantKey,
                    variantValue: selectedSecondVariantValue,
                }),
            )
            setShowErrorMsgForVariantSelection(false)
        },
        [dispatch, setShowErrorMsgForVariantSelection],
    )

    /**
     * This function used to set third variant name
     * @param {string} selectedThirdVariantValue third variant name
     * @returns {void} returns void
     */
    const setSelectedThirdVariantValue = useCallback(
        (selectedThirdVariantValue: string): void => {
            dispatch(
                setSelectedVariantValueAction({
                    variantKey: selectedThirdVariantKey,
                    variantValue: selectedThirdVariantValue,
                }),
            )
            setShowErrorMsgForVariantSelection(false)
        },
        [dispatch, setShowErrorMsgForVariantSelection],
    )

    /**
     * This function used to set first variant Id
     * @param {number} firstVariantId first variant Id
     * @returns {void} returns void
     */
    const setSelectedFirstVariantIdValue = useCallback(
        (firstVariantId: string): void => {
            resetWishlistActiveHandler()
            dispatch(
                setSelectedVariantIDAction({
                    variantKey: selectedFirstVariantKey,
                    variantValue: firstVariantId,
                }),
            )
        },
        [dispatch, resetWishlistActiveHandler],
    )

    /**
     * This function used to set second variant Id
     * @param {number} secondVariantId second variant Id
     * @returns {void} returns void
     */
    const setSelectedSecondVariantIdValue = useCallback(
        (secondVariantId: string): void => {
            resetWishlistActiveHandler()
            dispatch(
                setSelectedVariantIDAction({
                    variantKey: selectedSecondVariantKey,
                    variantValue: secondVariantId,
                }),
            )
        },
        [dispatch, resetWishlistActiveHandler],
    )
    /**
     * This function used to set third variant Id
     * @param {number} thirdVariantId third variant Id
     * @returns {void} returns void
     */
    const setSelectedThirdVariantIdValue = useCallback(
        (thirdVariantId: string): void => {
            resetWishlistActiveHandler()
            dispatch(
                setSelectedVariantIDAction({
                    variantKey: selectedThirdVariantKey,
                    variantValue: thirdVariantId,
                }),
            )
        },
        [dispatch, resetWishlistActiveHandler],
    )

    useEffect(() => {
        if (isAtleastOneParamValid(!!selectedFirstVariantId, selectedSecondVariantId, selectedThirdVariantId)) {
            const isMultiSku = productData?.skus?.length > MagicNumber.ONE
            const isOptionsDataEmpty = isArrayEmpty(productData?.options)
            const productSKUCode =
                getProductSKUCode(
                    selectedFirstVariantId,
                    String(selectedSecondVariantId),
                    String(selectedThirdVariantId),
                    productData,
                ) || productData.code

            const selectedCode = isMultiSku && isOptionsDataEmpty ? productData?.skus[0]?.code : productSKUCode
            setSelectedCode(selectedCode)
        }
    }, [productData, selectedFirstVariantId, selectedThirdVariantId, selectedSecondVariantId, setSelectedCode])

    return {
        setSelectedCode,
        setSelectedFirstVariantValue,
        setSelectedSecondVariantValue,
        setSelectedThirdVariantValue,
        setSelectedFirstVariantIdValue,
        setSelectedSecondVariantIdValue,
        setSelectedThirdVariantIdValue,
    }
}
