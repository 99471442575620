/**
 * Function to extract category lvl as number for custom PLPs
 * If categoryLevel is 'ast-id-level-n' whhere 'n' can be from 1 to 5 then this function will extract category level as number
 * Otherwise this function will return 0
 * @param {string} categoryLevel
 * @return {number}
 */
export const extractCategoryLevelNumberForCustomPlp = (categoryLevel: string): number => {
    return categoryLevel?.includes('ast-id-level') ? Number(categoryLevel.match(/\d/g)?.[0] || 0) : 0
}
