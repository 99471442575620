import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { CommonCardProps } from './CommonCard.type'

/**
 * CommonCard component
 * @param {CommonCardProps} props
 * @return {JSX.Element} returns CommonCard component
 */

const CommonCard: React.FC<CommonCardProps> = ({ ...props }) => {
    const { children, showWarningToast, propClass, noPadding, isSTS } = props

    const commonCardClass = noPadding ? `${PREFIX}-common-card__no-padding` : `${PREFIX}-common-card`
    return (
        <div
            className={
                showWarningToast
                    ? `${commonCardClass} ${PREFIX}-common--error`
                    : isSTS
                    ? `${commonCardClass} ${PREFIX}-${propClass} ${PREFIX}-common-card-sts`
                    : `${commonCardClass} ${PREFIX}-${propClass}`
            }>
            {children}
        </div>
    )
}

CommonCard.defaultProps = {
    showWarningToast: false,
}

CommonCard.propTypes = {
    children: PropTypes.element,
    showWarningToast: PropTypes.bool,
    propClass: PropTypes.string,
    noPadding: PropTypes.bool,
}

CommonCard.displayName = 'CommonCard'

export default CommonCard
