import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { SearchBarProps, Suggestion, searchConstants, HistorySuggestion } from './SearchBar.type'
import Icon from '../Icon'
import Button from '../Button'
import { Suggestions } from './Suggestions'
import { stringKeyCodes, magicNumber, customDebounce, replaceStrWithDynamicVal } from '../../utils'
import { isArrayEmpty } from '../../utils/isArrayEmpty'
import { getSuggestionGAType } from './utils'
import { pageTypes } from '../config'
import { getPageType } from '../../utils/getPageType'
import { getIsMobile } from '../../utils/getIsMobile'

/**
 * SearchBar component
 * @param {SearchBarProps} props -
 *   path,
     searchIconSize,
     a11ySearchIconLabel,
     allyCloseIconLabel,
     a11yUpdateQueryIconLabel,
     id,
     suggestionPannel,
     searchBoxPlaceholder,
     searchLabel,
     categoriesLabel,
     didYouMeanLabel,
     searchHistoryTitle,
     isCodeLookup,
     suggestedKeywordList,
     suggestedCategoryList,
     getSearchHistoryList,
     addSearchHistory,
     clearSearchHistoryList,
     suggestedProductList,
     getProducts,
     searchHistoryClearLabel,
     showModal,
     searchCallBack,
     searchPagePath,
     pushAnalyticsData,
     closeModal,
     delay,
     searchingString,
     pushQueue,
 * @return {JSX.Element} returns SearchBar Component
 */
const SearchBar: React.FC<SearchBarProps> = props => {
    const searchBarEl = useRef<HTMLInputElement>(null)
    const modalRef = useRef<HTMLDivElement>(null)
    const ariaLiveRef = useRef<HTMLDivElement>(null)

    const {
        path,
        searchIconSize,
        a11ySearchIconLabel,
        allyCloseIconLabel,
        a11yUpdateQueryIconLabel,
        id,
        suggestionPannel,
        searchBoxPlaceholder,
        searchLabel,
        categoriesLabel,
        didYouMeanLabel,
        searchHistoryTitle,
        isCodeLookup,
        suggestedKeywordList,
        suggestedCategoryList,
        getSearchHistoryList,
        addSearchHistory,
        clearSearchHistoryList,
        searchHistoryClearLabel,
        showModal,
        searchCallBack,
        searchPagePath,
        pushAnalyticsData,
        closeModal,
        delay,
        searchingString,
        pushQueue,
        minChars,
        topHit,
        showCloseButton,
        a11yArrowButtonsAriaLabel,
        a11ySuggestionsAvailableLabel,
        searchInputVal,
        setSearchInputVal,
        setInputAtPageLoad,
    } = props

    // eslint will flag hardcoded numeric values as 'magic-number'
    const MIN_REQUIRED_CHARACTERS = minChars
    const MINUS_ONE = magicNumber.MINUS_ONE
    const ONE = magicNumber.ONE

    // this variable is introduced to prevent highlighting the current search input because that value gets updated as selections change. this value wlil only update when
    // a search is performed so that the highlight on keyword remains for the originally searched term
    const [originalSearchValue, setOriginalSearchValue] = useState('')
    const [showSuggestionPanel, setShowSuggestionPanel] = useState(false)
    const [showClearButton, setShowClearButton] = useState(false)
    const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(MINUS_ONE)
    const [keyboardNavigationMode, setKeyboardNavigationMode] = useState(false)
    const [searchHistoryList, setSearchHistoryList] = useState<Suggestion[]>([])
    const [debounceTimeOut, setDebounceTimeOut] = useState<NodeJS.Timeout>(null)
    const [notFilterHistory, setNotFilterHistory] = useState(true)
    const [filteredHistoryList, setFilteredHistoryList] = useState([])
    const [searchSuggHeight, setSearchSuggHeight] = useState(0)

    const isTypeaheadUsed = useRef(false)
    const setSearchHistoryRunOnce = useRef(0)
    const RunTriggerSuggestForMobileOnce = useRef(0)
    const pageType = getPageType()

    const currentSuggestionRef = useRef<HTMLDivElement>(null)

    const enableSearchPageParameter = useCallback(
        () =>
            pageType === pageTypes.brandCategoryPage ||
            pageType === pageTypes.promoListing ||
            pageType === pageTypes.eventListing,
        [pageType],
    )
    const onCloseTassModal = useCallback(() => {
        setShowSuggestionPanel(false)
    }, [])
    // reset panel data to original state
    const resetPanel = useCallback(() => {
        setCurrentSuggestionIndex(MINUS_ONE)
        setKeyboardNavigationMode(false)
    }, [MINUS_ONE])
    // trigger search. if no input, fetch popular products, if there is input with minimum characters, trigger suggest call for the term and populate suggestions and related procuts
    const triggerSuggest = useCallback(() => {
        const searchTerm = searchBarEl?.current?.value.trim()

        if (searchTerm && searchTerm.length >= MIN_REQUIRED_CHARACTERS) {
            resetPanel()
            pushQueue(searchTerm)
            setOriginalSearchValue(searchTerm)
        }

        setShowSuggestionPanel(true)
        setCurrentSuggestionIndex(MINUS_ONE)
    }, [MINUS_ONE, MIN_REQUIRED_CHARACTERS, pushQueue, resetPanel])

    const throttledTriggerSuggest = useMemo(() => {
        const searchTerm = searchBarEl?.current?.value
        if (searchTerm && searchTerm.length >= MIN_REQUIRED_CHARACTERS) {
            setOriginalSearchValue(searchTerm)
        }
        return customDebounce(triggerSuggest, delay, debounceTimeOut, setDebounceTimeOut)
    }, [debounceTimeOut, triggerSuggest, MIN_REQUIRED_CHARACTERS, delay])

    useEffect(() => {
        if (searchInputVal && !notFilterHistory) {
            setFilteredHistoryList(
                searchHistoryList.filter(it => it?.label?.toLowerCase().includes(searchInputVal.toLowerCase())),
            )
        } else {
            setFilteredHistoryList(searchHistoryList)
        }
    }, [searchHistoryList, searchInputVal, notFilterHistory])

    const distOnScroll = document.querySelector(`.${PREFIX}-primary-navigation-bar__search-bar`)
    /**
     * useCallback to set distance of search bar from top on scroll of a page
     */
    const scrollEvent = useCallback(() => {
        setSearchSuggHeight(distOnScroll?.getBoundingClientRect().top)
    }, [distOnScroll])

    useEffect(() => {
        if (!getIsMobile()) {
            window?.addEventListener(
                'scroll',
                () => {
                    scrollEvent()
                },
                { passive: true },
            )
            // closing sugestion bar as we scroll down to some height.
            if (searchSuggHeight <= magicNumber.MINUS_ONEHUNDREDFIFTY) {
                setShowSuggestionPanel(false)
            }
        }
    }, [scrollEvent, searchSuggHeight])

    useEffect(() => {
        if (searchingString && searchingString !== '*' && !enableSearchPageParameter()) {
            setOriginalSearchValue(searchingString)
            setShowClearButton(true)
        }
    }, [searchingString, enableSearchPageParameter])

    useEffect(() => {
        if (showCloseButton && showModal && RunTriggerSuggestForMobileOnce.current === magicNumber.ZERO) {
            setOriginalSearchValue(searchBarEl.current.value)
            throttledTriggerSuggest()
            searchBarEl.current.focus()
            RunTriggerSuggestForMobileOnce.current++
        }
    }, [showModal, throttledTriggerSuggest, showCloseButton])

    // Puts search bar in focus on render if mobile + showModal and opens the keyboard on iOS  device bug DIGIDATA-2505
    // Source: https://blog.maisie.ink/react-ref-autofocus/#callback-refs
    const focusAndOpenKeyboard = useCallback(
        (inputElement: HTMLInputElement): void => {
            if (inputElement && showModal) {
                // Align temp input element approx. to be where the input element is
                const __tempEl__ = document.createElement('input')
                __tempEl__.style.position = 'absolute'
                __tempEl__.style.top = `${String(inputElement.offsetTop + 7)}px`
                __tempEl__.style.left = `${String(inputElement.offsetLeft)}px`
                __tempEl__.style.height = '0'
                __tempEl__.style.opacity = '0'
                // Put this temp element as a child of the page <body> and focus on it
                document.body.appendChild(__tempEl__)
                __tempEl__.focus()

                // The keyboard is open. Now do a delayed focus on the target element
                setTimeout(function () {
                    inputElement.focus()
                    // Remove the temp element
                    document.body.removeChild(__tempEl__)
                }, 100)
            }
        },
        [showModal],
    )

    const callbackRef = useCallback(
        (inputElement: HTMLInputElement): void => {
            searchBarEl.current = inputElement
            getIsMobile() && focusAndOpenKeyboard(inputElement)
        },
        [focusAndOpenKeyboard],
    )

    const setIsTypeaheadUsed = useCallback((val: boolean): void => {
        isTypeaheadUsed.current = val
    }, [])

    // clear search input
    const clearInput = useCallback(() => {
        setIsTypeaheadUsed(false)
        setSearchInputVal('')
        searchBarEl.current.value = ''
        setOriginalSearchValue('')

        setShowClearButton(false)
        throttledTriggerSuggest()

        searchBarEl.current.focus()
    }, [setIsTypeaheadUsed, setSearchInputVal, throttledTriggerSuggest])

    /**
     * useEffect to add data to search history list
     */

    useEffect(() => {
        if (setSearchHistoryRunOnce.current === magicNumber.ZERO) {
            setSearchHistoryList(getSearchHistoryList())
            setSearchHistoryRunOnce.current++
        }
    }, [getSearchHistoryList])

    const suggestionsLength = useMemo(() => {
        return suggestedKeywordList?.length + suggestedCategoryList?.length + filteredHistoryList.length
    }, [suggestedKeywordList, suggestedCategoryList, filteredHistoryList])

    /**
     * useEffect to set announcement message after suggestion panel open for SR users
     * Used set time out to wait till suggestions are updating.
     */

    useEffect(() => {
        if (showSuggestionPanel) {
            setTimeout(() => {
                if (ariaLiveRef.current) {
                    ariaLiveRef.current.innerHTML =
                        searchInputVal === ''
                            ? replaceStrWithDynamicVal(
                                  `${a11ySuggestionsAvailableLabel ?? ''}.`,
                                  String(suggestionsLength),
                              )
                            : replaceStrWithDynamicVal(a11ySuggestionsAvailableLabel, String(suggestionsLength))
                }
            }, magicNumber.ONEHUNDREDFIFTY)
        }
    }, [a11ySuggestionsAvailableLabel, showSuggestionPanel, suggestionsLength, searchInputVal])

    /**
     * handle search input change
     * @param {ChangeEvent} event - change event
     */
    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = event.target.value
            setShowClearButton(inputValue && inputValue.length > 0)
            setSearchInputVal(inputValue)
            setInputAtPageLoad(false)
            notFilterHistory && setNotFilterHistory(false)
            if (!inputValue) {
                setOriginalSearchValue('')
                setIsTypeaheadUsed(false)
            }

            if (!delay) {
                triggerSuggest()
            } else {
                throttledTriggerSuggest()
            }
        },
        [
            setSearchInputVal,
            setInputAtPageLoad,
            notFilterHistory,
            delay,
            setIsTypeaheadUsed,
            triggerSuggest,
            throttledTriggerSuggest,
        ],
    )

    const suggestionList = document.getElementById('suggestion-panel')
    const searchBarEle = document.getElementById('mobile-search-bar')

    /**
     * calculate height of search bar in mobile view
     * @return {number} height of search bar
     */

    const searchBarMobileView = useCallback((): number => {
        if (getIsMobile() && searchBarEle) {
            return Number(searchBarEle?.clientHeight || magicNumber.ZERO)
        }
        return magicNumber.ZERO
    }, [searchBarEle])

    /**
     * useEffect to set height of search bar suggestion list.
     */
    useEffect(() => {
        const headersHeight = Number(
            document.getElementsByClassName(`${PREFIX}-primary-navigation`)[magicNumber.ZERO]?.clientHeight ||
                magicNumber.ZERO,
        )
        const allInfoBanner = document.querySelectorAll(`.${PREFIX}-information-banner`)
        let infoBannerTotalHeight = magicNumber.ZERO
        allInfoBanner?.forEach(item => {
            infoBannerTotalHeight = infoBannerTotalHeight + item.clientHeight
            return infoBannerTotalHeight || magicNumber.ZERO
        })
        const pencilBannerHeight = Number(
            document.getElementsByClassName(`pencilBanner`)[magicNumber.ZERO]?.clientHeight || magicNumber.ZERO,
        )

        if (suggestionList) {
            if (getIsMobile()) {
                suggestionList.style.maxHeight = `calc(${magicNumber.HUNDRED}vh - ${searchBarMobileView()}px)`
            } else {
                suggestionList.style.maxHeight = `calc(${magicNumber.HUNDRED}vh - ${
                    headersHeight + infoBannerTotalHeight + pencilBannerHeight
                }px)`
            }
        }
    }, [suggestionList, searchBarMobileView])

    /**
     * called from keyboard navigation handler. highlight the suggestion at index, update the search input to display the highlighted suggestion labe, and fetch & display products related to that suggestion
     * @param {number} index - current index of highlighted suggestion
     */
    const handleSuggestionKeyboardNavigation = (index: number) => {
        const highlightedSuggestion = getSelectedSuggestion(index)

        setIsTypeaheadUsed(false)
        setSearchInputVal(highlightedSuggestion?.label || originalSearchValue)
        !showClearButton && setShowClearButton(true)
        setNotFilterHistory(true)
    }

    /**
     * function to handle scroll of suggestion with keyboard
     * @return {void}
     */
    const handleScrollWithKeyboard = (): void => {
        setTimeout(() => {
            currentSuggestionRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            })
        }, magicNumber.HUNDRED)
    }

    /**
     * handle arrow down. if not already in keyboard navigation mode, trigger keyboard navigation mode. if on the last suggestion/product, move to first item
     */
    const handleArrowDown = () => {
        // if already in keyboard nvagiation mode, navigate within the section of current highlighted element
        if (keyboardNavigationMode) {
            setIsTypeaheadUsed(false)
            const newIndex =
                (currentSuggestionIndex + ONE) %
                (suggestedKeywordList.length + suggestedCategoryList.length + searchHistoryList.length)
            setCurrentSuggestionIndex(newIndex)
            handleSuggestionKeyboardNavigation(newIndex)
            handleScrollWithKeyboard()
            // if not in keyboard navigation mode, enter keyboard navigation mode, starting with suggestions on the left side
        } else {
            setKeyboardNavigationMode(true)

            setIsTypeaheadUsed(false)
            if (
                !isArrayEmpty(suggestedKeywordList) ||
                !isArrayEmpty(suggestedCategoryList) ||
                !isArrayEmpty(searchHistoryList)
            ) {
                const newIndex =
                    (currentSuggestionIndex + ONE) %
                    (suggestedKeywordList.length + suggestedCategoryList.length + searchHistoryList.length)
                setCurrentSuggestionIndex(newIndex)
                handleSuggestionKeyboardNavigation(newIndex)
            }
        }
    }

    /**
     * handle arrow up. if not in keyboard navigation mode, toggle keyboard navigation mode on. if on the first item of suggestion/products, move to the last item
     */
    const handleArrowUp = () => {
        if (!keyboardNavigationMode) {
            setKeyboardNavigationMode(true)
        }

        setIsTypeaheadUsed(false)
        const newIndex =
            (currentSuggestionIndex - ONE) %
                (suggestedKeywordList.length + suggestedCategoryList.length + searchHistoryList.length) <
            0
                ? suggestedKeywordList.length + suggestedCategoryList.length + searchHistoryList.length - ONE
                : (currentSuggestionIndex - ONE) %
                  (suggestedKeywordList.length + suggestedCategoryList.length + searchHistoryList.length)
        setCurrentSuggestionIndex(newIndex)
        handleSuggestionKeyboardNavigation(newIndex)
        handleScrollWithKeyboard()
    }

    /**
     * find the current selected suggestion. it can be keyword/category/history
     * @param {number | undefined} - get suggestion by index if present
     * @return {Suggestion} selectedSuggestion
     */
    const getSelectedSuggestion = useCallback(
        (index?: number) => {
            const useIndex = index || index === 0 ? index : currentSuggestionIndex
            if (useIndex == MINUS_ONE) {
                return null
            } else if (useIndex < suggestedKeywordList.length) {
                return suggestedKeywordList[useIndex]
            } else if (useIndex < suggestedKeywordList.length + suggestedCategoryList.length) {
                return suggestedCategoryList[useIndex - suggestedKeywordList.length]
            } else {
                return searchHistoryList[useIndex - suggestedKeywordList.length - suggestedCategoryList.length]
            }
        },
        [currentSuggestionIndex, suggestedKeywordList, suggestedCategoryList, searchHistoryList, MINUS_ONE],
    )

    /**
     * record analytics related fields
     * @param {boolean} enterPressed
     * @param {string} searchType
     */
    const recordAnalytics = useCallback(
        // eslint-disable-next-line complexity
        (enterPressed: boolean, searchType: string, selectedSuggestionIndex?: number) => {
            const selectedSuggestion = getSelectedSuggestion(selectedSuggestionIndex)

            if (keyboardNavigationMode && selectedSuggestion) {
                searchType = searchConstants.SEARCH_SUGGESTION
            }

            const compareLabelWithSearchValue = (label: string): boolean => {
                let labelValue = label

                try {
                    labelValue = decodeURI(label)
                } catch (error) {
                    console.error(error)
                }

                return originalSearchValue.trim() === labelValue.trim()
            }

            const isFastSubmit = originalSearchValue
                ? !topHit?.some?.(it => compareLabelWithSearchValue(it.label))
                : false

            const suggestionType =
                selectedSuggestion && (!isFastSubmit || !originalSearchValue)
                    ? getSuggestionGAType(selectedSuggestion.type)
                    : ''

            if (!keyboardNavigationMode && !originalSearchValue && !searchInputVal && !selectedSuggestion) {
                return
            }

            pushAnalyticsData(
                originalSearchValue.trim(),
                searchType,
                suggestionType,
                isFastSubmit && originalSearchValue ? null : selectedSuggestion,
                undefined,
                undefined,
                enterPressed ? searchConstants.INTERACT_ENTER : searchConstants.INTERACT_CLICK,
                isTypeaheadUsed.current,
            )
        },
        [getSelectedSuggestion, keyboardNavigationMode, originalSearchValue, pushAnalyticsData, searchInputVal, topHit],
    )

    /**
     * handler for when search is triggered via enter. add term to history, do analytics stuff, and invoke searchCallBack to be taken to SRP
     * @param {boolean} enterPressed
     * @param {string} searchType
     */
    const triggerSearch = (enterPressed: boolean, searchType: string) => {
        const searchTerm = searchBarEl.current.value.trim()
        addToHistory(searchTerm)
        recordAnalytics(enterPressed, searchType)
        setShowSuggestionPanel(false)

        // if in keyboard navigation mode, direct user to the url corresponding to the selected category.
        if (keyboardNavigationMode) {
            const selectedSuggestion = getSelectedSuggestion()
            // for category, navigate to selected category url.
            if (selectedSuggestion && selectedSuggestion?.type === searchConstants.SUGGESTION_TYPE_CATEGORY) {
                navigateToUrl(`${searchPagePath}${selectedSuggestion.searchUrl}`)
                return
            }
        }

        if (searchTerm) {
            searchCallBack(searchTerm)
        }
    }

    /**
     * This function is called when enter key is pressed  for suggestion from keyboard
     * @return {void}
     */
    const keyboardEnterKeyPressed = (): void => {
        triggerSearch(true, searchConstants.USER_INPUTTED)
        setShowSuggestionPanel(false)
    }

    /**
     * handle key events
     * @param {KeyboardEvent} event - event
     */
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === stringKeyCodes.enter) {
            keyboardEnterKeyPressed()
        } else if (event.key === stringKeyCodes.downArrow) {
            handleArrowDown()
        } else if (event.key === stringKeyCodes.upArrow) {
            handleArrowUp()
        } else if (event.key === stringKeyCodes.esc) {
            setShowSuggestionPanel(false)
        } else if (event.key === stringKeyCodes.tab) {
            setShowSuggestionPanel(false)
            if (event.shiftKey) {
                setShowSuggestionPanel(false)
            }
        }
    }

    // add current value of search input to history
    const addToHistory = useCallback(
        (searchTerm: string, data?: Suggestion) => {
            const historyList = addSearchHistory(searchTerm.trim().toLowerCase(), data)
            setSearchHistoryList(historyList)
        },
        [addSearchHistory, setSearchHistoryList],
    )

    // clear history
    const clearHistory = useCallback(() => {
        clearSearchHistoryList()
        // this clears history from local storage
        setSearchHistoryList([])
    }, [clearSearchHistoryList, setSearchHistoryList])

    /**
     * navigate to url
     * @param {string} url - target url
     */
    const navigateToUrl = useCallback((url: string) => {
        const windowOrigin = window?.location?.origin
        window.location.href = windowOrigin + url
    }, [])

    /**
     * handle suggestion selection via click. record corresponding analytics information and navigate user to the page for the suggetion
     * @param {Suggestion} event - event
     */
    const handleSuggestionClick = useCallback(
        (suggestion: Suggestion) => {
            // isEnterPressed is false
            setCurrentSuggestionIndex(suggestion.index)
            addToHistory(suggestion.label, suggestion)
            recordAnalytics(false, searchConstants.SEARCH_SUGGESTION, suggestion.index)
            setShowSuggestionPanel(false)
            searchCallBack(suggestion.label)
        },
        [recordAnalytics, addToHistory, searchCallBack],
    )

    /**
     * handle Category selection via click. record corresponding analytics information and navigate user to the page for the Category
     * @param {Suggestion} event - event
     */
    const handleCategoryClick = useCallback(
        (category: Suggestion) => {
            // isEnterPressed is false
            setCurrentSuggestionIndex(category.index)
            addToHistory(category.label, category)
            recordAnalytics(false, searchConstants.SEARCH_SUGGESTION, category.index)
            setShowSuggestionPanel(false)
            navigateToUrl(`${searchPagePath}${category.searchUrl}`)
        },
        [addToHistory, recordAnalytics, navigateToUrl, searchPagePath],
    )

    /**
     * only added because eslint will complain about (visible, non-interactive elements with click handlers must have at least one keyboard listener)
     * @param {KeyboardEvent} event - event
     */
    const handleSuggestionEnter = useCallback(
        (event: React.KeyboardEvent<HTMLElement>) => {
            event.preventDefault()
            setShowSuggestionPanel(false)
        },
        [setShowSuggestionPanel],
    )

    /**
     * handle history click. similar to suggestion click, but just invoke searchCallback
     * @param {{ label: string; data?: Suggestion }} event - event
     */
    const handleHistoryClick = useCallback(
        (history: HistorySuggestion) => {
            setCurrentSuggestionIndex(history.index)
            recordAnalytics(false, searchConstants.SEARCH_SUGGESTION, history.index)
            searchCallBack(history.label)
            setShowSuggestionPanel(false)
        },
        [recordAnalytics, searchCallBack, setShowSuggestionPanel],
    )

    /**
     * only added because eslint will complain about (visible, non-interactive elements with click handlers must have at least one keyboard listener)
     * @param {KeyboardEvent} event - event
     */
    const handleHistoryEnter = useCallback(
        (event: React.KeyboardEvent<HTMLElement>) => {
            event.preventDefault()
            setShowSuggestionPanel(false)
        },
        [setShowSuggestionPanel],
    )

    /**
     * function to check if close icon present
     * @return {boolean}
     */
    const showCloseIcon = useCallback((): boolean => showSuggestionPanel || showModal, [showSuggestionPanel, showModal])

    const handleMobileFillTypeahead = useCallback(
        (value: string): void => {
            setIsTypeaheadUsed(true)
            setOriginalSearchValue(value)
            setSearchInputVal(value)
            setShowClearButton(true)
            searchBarEl.current.focus()
            // Below code is needed in order to make suggest api call, which is happening in production
            if (!delay) {
                triggerSuggest()
            } else {
                throttledTriggerSuggest()
            }
        },
        [setIsTypeaheadUsed, setSearchInputVal, delay, triggerSuggest, throttledTriggerSuggest],
    )

    /**
     * render wasPrice
     * @return {React.JSXElementConstructor} - markup
     * @param {Suggestion[]} list
     * @param {String} header
     * @param {Number} indexMargin
     * @param {String} type
     * @param {Boolean} showTypeahead
     * @param {Function | undefined} onClick
     * @param {Function | undefined} onKeyDown
     * @param {Function | undefined} clrHistory
     * @param {String} historyClearLabel
     */
    const renderSuggestions = (
        list: Suggestion[],
        header: string,
        indexMargin: number,
        type: string,
        showTypeahead = false,
        onClick?: (event: Suggestion) => void,
        onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void,
        clrHistory?: () => void,
        historyClearLabel?: string,
    ): React.ReactElement => (
        <Suggestions
            handleMobileFillTypeahead={handleMobileFillTypeahead}
            headerLabel={header}
            suggestedList={list}
            currentSuggestionIndex={currentSuggestionIndex}
            indexMargin={indexMargin}
            handleMouseover={() => {
                setIsTypeaheadUsed(false)
            }}
            handleSuggestionClick={onClick || handleSuggestionClick}
            handleSuggestionEnter={onKeyDown || handleSuggestionEnter}
            a11yUpdateQueryIconLabel={a11yUpdateQueryIconLabel}
            originalSearchValue={searchInputVal}
            clearHistory={clrHistory}
            searchHistoryClearLabel={historyClearLabel}
            type={type}
            showTypeahead={showTypeahead}
            setShowSuggestionPanel={setShowSuggestionPanel}
            a11yArrowButtonsAriaLabel={a11yArrowButtonsAriaLabel}
            currentSuggestionRef={currentSuggestionRef}
        />
    )

    const renderTassInner = () => {
        const keyword = renderSuggestions(
            suggestedKeywordList,
            didYouMeanLabel,
            0,
            searchConstants.SUGGESTION_TYPE_KEYWORD,
            true,
        )
        const category = renderSuggestions(
            suggestedCategoryList,
            categoriesLabel,
            suggestedKeywordList.length,
            searchConstants.SUGGESTION_TYPE_CATEGORY,
            false,
            handleCategoryClick,
        )
        const history = renderSuggestions(
            filteredHistoryList,
            searchHistoryTitle,
            suggestedKeywordList.length + suggestedCategoryList.length,
            searchConstants.SUGGESTION_TYPE_HISTORY,
            false,
            handleHistoryClick,
            handleHistoryEnter,
            clearHistory,
            searchHistoryClearLabel,
        )

        return (
            <>
                <div className={`${PREFIX}-suggestion-panel`}>
                    {keyword}
                    {category}
                    {history}
                </div>
            </>
        )
    }

    // event to close search modal on click outside the modal
    const useOnClickOutside = (ref: React.MutableRefObject<HTMLDivElement>, handler: (event: any) => void) => {
        useEffect(() => {
            const listener = (event: MouseEvent) => {
                // Do nothing if clicking ref's element or descendent elements

                if (!ref?.current || ref?.current?.contains(event.target as Node)) {
                    return
                }

                if ((event.target as HTMLDivElement).innerText != searchHistoryClearLabel && !showModal) {
                    handler(event)
                }
                if (suggestionPannel) {
                    setShowSuggestionPanel(false)
                }
            }
            document.addEventListener('mousedown', listener)

            return () => {
                document.removeEventListener('mousedown', listener)
            }
        }, [ref, handler])
    }

    /**
     * function to get clear button on the search bar
     * @return {JSX.Element}
     */
    const clearButton = (): JSX.Element => {
        if (showClearButton && (showModal || !getIsMobile())) {
            return (
                <div className={`${PREFIX}-search__button`}>
                    <Button type="tertiary" onClick={clearInput} data-testid="clear-btn">
                        {searchHistoryClearLabel}
                    </Button>
                </div>
            )
        }
    }

    useOnClickOutside(modalRef, () => {
        setShowSuggestionPanel(false)
    })

    /**
     * useEffect to set unique id to input field of SearchBar in Primary Naigation
     * Setting 'id' attribute 2 times one is for mobile and one is for desktop.
     * Setting 'for' attribute for lable, 'id' and 'for' attribute should be same.
     * Using setTimeout to setAttribute 'id' from this useEffect and should not take from inline id.
     *
     */

    useEffect(() => {
        setTimeout(() => {
            const searchInput = document
                ?.querySelector(`.nl-primary-navigation-bar__search-bar`)
                ?.getElementsByTagName('input')

            const searchInputLabel = document
                ?.querySelector(`.nl-primary-navigation-bar__search-bar`)
                ?.getElementsByTagName('label')

            const searchIconId = document.querySelectorAll('[data-testid="trigger-search-icon"]')

            if (searchIconId) {
                searchIconId.forEach((el, index) => {
                    el.setAttribute('id', `trigger-search-icon-${index}`)
                })
            }

            if (searchInput && searchInputLabel) {
                searchInput[magicNumber.ZERO]?.setAttribute('id', `search-input-${magicNumber.ZERO}`)
                searchInput[magicNumber.ONE]?.setAttribute('id', `search-input-${magicNumber.ONE}`)
                searchInputLabel[magicNumber.ZERO]?.setAttribute('for', `search-input-${magicNumber.ZERO}`)
                searchInputLabel[magicNumber.ONE]?.setAttribute('for', `search-input-${magicNumber.ONE}`)
            }
        }, magicNumber.HUNDRED)

        // For Mobile view only
        if (getIsMobile() && !showCloseIcon()) {
            searchBarEl.current.setAttribute('tabindex', `-${magicNumber.ONE}`)
            searchBarEl.current.setAttribute('aria-hidden', 'true')
            const searchIconBtn = document.querySelectorAll(
                '.nl-header__mobile-search [data-testid="trigger-search-icon"]',
            )
            searchIconBtn.forEach(el => {
                el.removeAttribute('aria-label')
                el.setAttribute('aria-hidden', 'true')
                el.setAttribute('tabindex', `-${magicNumber.ONE}`)
            })
        }
    }, [showCloseIcon])

    return (
        <div className={`${PREFIX}-search-bar`} data-testid="suggestion-panel" id={props.mobileSearchBarId}>
            <div className={`${PREFIX}-search ${PREFIX}-row`}>
                {showCloseIcon() && getIsMobile() && (
                    <div className={`${PREFIX}-search__searchbutton ${PREFIX}-search__searchbutton__close`}>
                        <Button
                            onClick={() => {
                                onCloseTassModal()
                                closeModal && closeModal() // for closing the mobile search model
                            }}
                            aria-label={allyCloseIconLabel}
                            type="icon_button"
                            id="close-btn">
                            <Icon type="ct-chevron-left" size="md" path={path} />
                        </Button>
                    </div>
                )}
                <label htmlFor={id} className="sr-only">
                    {searchLabel}
                </label>
                <input
                    id={id}
                    data-qm-allow="true"
                    ref={callbackRef}
                    enterKeyHint="search"
                    placeholder={searchBoxPlaceholder}
                    className={`${PREFIX}-search__input`}
                    value={searchInputVal}
                    data-iscodelookup={isCodeLookup}
                    onClick={e => {
                        e.preventDefault()

                        notFilterHistory && setNotFilterHistory(false)
                        setShowSuggestionPanel(true)
                    }}
                    onChange={e => handleChange(e)}
                    onKeyDown={handleKeyDown}
                    data-testid={id}
                    autoComplete="off"
                />
                {clearButton()}
                <div className={`${PREFIX}-search__searchbutton`}>
                    <Button
                        data-qm-allow="true"
                        type="icon_button"
                        onClick={() => triggerSearch(false, searchConstants.USER_INPUTTED)}
                        id="trigger-search-icon"
                        ariaLabel={a11ySearchIconLabel}
                        buttonType="submit"
                        quantumMetricAttribute={{ type: 'allow', value: 'true' }}>
                        <Icon type="ct-search" size={searchIconSize} path={path} />
                    </Button>
                </div>
            </div>
            <div className="sr-only" ref={ariaLiveRef} aria-live="polite" aria-atomic="true" role="status"></div>
            {showSuggestionPanel &&
            (suggestedKeywordList?.length || suggestedCategoryList?.length || filteredHistoryList?.length) ? (
                <div className={`${PREFIX}-tass-panel-row`} ref={modalRef} data-testid="suggestion-panel-row">
                    <div className={`${PREFIX}-tass-panel`} id="suggestion-panel">
                        {renderTassInner()}
                    </div>
                </div>
            ) : null}
        </div>
    )
}

SearchBar.defaultProps = {
    id: 'search-input',
}

SearchBar.propTypes = {
    searchIconSize: PropTypes.string,
    path: PropTypes.string,
    a11ySearchIconLabel: PropTypes.string,
    allyCloseIconLabel: PropTypes.string,
    a11yUpdateQueryIconLabel: PropTypes.string,
    id: PropTypes.string,
    searchBoxPlaceholder: PropTypes.string,
    searchLabel: PropTypes.string,
    categoriesLabel: PropTypes.string,
    didYouMeanLabel: PropTypes.string,
    searchHistoryTitle: PropTypes.string,
    isCodeLookup: PropTypes.bool,
    suggestedKeywordList: PropTypes.array,
    suggestedCategoryList: PropTypes.array,
    getSearchHistoryList: PropTypes.func,
    addSearchHistory: PropTypes.func,
    clearSearchHistoryList: PropTypes.func,
    searchCallBack: PropTypes.func,
    pushAnalyticsData: PropTypes.func,
    closeModal: PropTypes.func,
    searchHistoryClearLabel: PropTypes.string,
    showModal: PropTypes.bool,
    suggestionPannel: PropTypes.bool,
    delay: PropTypes.number,
    searchingString: PropTypes.string,
    pushQueue: PropTypes.func,
    topHit: PropTypes.arrayOf(
        PropTypes.exact({
            isRedirect: PropTypes.bool.isRequired,
            label: PropTypes.string.isRequired,
            searchUrl: PropTypes.string.isRequired,
            type: PropTypes.string,
            index: PropTypes.number,
        }),
    ).isRequired,
    a11yArrowButtonsAriaLabel: PropTypes.string,
    a11ySuggestionsAvailableLabel: PropTypes.string,
}

export default SearchBar
