import { createAction } from 'typesafe-actions'

import { VehicleFitType } from '../../globalConstants'
import {
    UserProfileData,
    SignOutResponseError,
    SoftRecallCTARequestPayload,
    UserProfileUpdateData,
} from '../models/user.profile.interface'
import { Vehicle, CriticalFitment } from '@nl/lib'
import {
    USER_PROFILE_SUCCESS,
    USER_PROFILE_ERROR,
    SIGN_OUT_SUCCESS,
    SIGN_OUT_FAILURE,
    FETCH_USER_PROFILE,
    CLEAR_USER_PROFILE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_CLEAR,
    UPDATE_PROFILE_ERROR,
    ADD_VEHICLE_LIST,
    UPDATE_VEHICLE_FIT_TYPE,
    ADD_VEHICLE,
    SET_DEFAULT_VEHICLE,
    REMOVE_VEHICLE_LIST_FROM_GARAGE,
    SET_URL_DEFAULT_VEHICLE,
    REMOVE_VEHICLE,
    SET_BASE_VEHICLE_ID,
    RESET_BASE_VEHICLE_ID,
    SHOW_NO_VEHICLE,
    SET_CRITICAL_FITMENT_DETAILS,
    SET_CRITICAL_FITMENT_ERROR,
    SET_SOFT_RECALL_CTA_CLICKED,
    UPDATE_VEHICLE,
    RESET_CRITICAL_FITMENT_DETAILS,
    NEW_VEHICLE_FORM_PROPS,
    CRITICAL_FITMENT_API_TRIGGERED,
    CRITICAL_FITMENT_API_DONE,
    USER_PROFILE_PENDING,
    SET_IS_VEHICLE_SYNCED_WITH_LS_ON_PAGE_LOAD,
    CLEAR_URL_DEFAULT_VEHICLE,
    REHYDRATE_USER_PROFILE,
    SET_SELECTED_VEHICLE,
    SET_SELECTED_VEHICLE_INDEX,
    SET_IS_VEHICLE_WITHOUT_BODY_OPTION,
    LITE_USER_PROFILE_CALL_SUCCESS,
    GIGYA_UPDATED,
    SET_IS_TIRE_OR_DEFAULT_VEHICLE_PRESENT,
} from '../types/profile/user.profile.actionTypes.constant'

export const fetchStartUserProfile = createAction(FETCH_USER_PROFILE)()

export const setVehicleWithoutBodyAndOption = createAction(SET_IS_VEHICLE_WITHOUT_BODY_OPTION)<boolean>()

export const userProfileSuccess = createAction(
    USER_PROFILE_SUCCESS,
    (payload: UserProfileData) => payload,
)<UserProfileData>()

export const liteUserProfileCallSuccess = createAction(
    LITE_USER_PROFILE_CALL_SUCCESS,
    (payload: UserProfileData) => payload,
)<UserProfileData>()

export const profileUpdateSuccessAction = createAction(
    UPDATE_PROFILE_SUCCESS,
    (payload: UserProfileUpdateData) => payload,
)<UserProfileUpdateData>()

export const resetProfileUpdateAction = createAction(UPDATE_PROFILE_CLEAR)()

export const profileUpdateErrorAction = createAction(UPDATE_PROFILE_ERROR)<SignOutResponseError>()

export const pendingProfileAction = createAction(USER_PROFILE_PENDING)()

export const userProfileFailure = createAction(USER_PROFILE_ERROR)()

export const signOutSuccessAction = createAction(SIGN_OUT_SUCCESS)<string>()

export const signOutErrorAction = createAction(SIGN_OUT_FAILURE)<SignOutResponseError>()
export const addVehicleListAction = createAction(ADD_VEHICLE_LIST)<Vehicle[]>()
export const setIsVehicleSyncedWithLSOnPageLoadAction = createAction(
    SET_IS_VEHICLE_SYNCED_WITH_LS_ON_PAGE_LOAD,
)<boolean>()
export const addVehicleAction = createAction(ADD_VEHICLE)<Vehicle>()
export const setVehicleIdAction = createAction(SET_BASE_VEHICLE_ID)<Record<string, string>>()
export const resetBaseVehicleIdAction = createAction(RESET_BASE_VEHICLE_ID)()
export const setDefaultVehicleAction = createAction(SET_DEFAULT_VEHICLE)<Vehicle | undefined>()
export const removeVehicleListFromGarage = createAction(REMOVE_VEHICLE_LIST_FROM_GARAGE)<void>()
export const setURLDefaultVehicleAction = createAction(SET_URL_DEFAULT_VEHICLE)<Vehicle | undefined>()
export const clearURLDefaultVehicleAction = createAction(CLEAR_URL_DEFAULT_VEHICLE)<void>()
export const setVehicleFitTypeAction = createAction(UPDATE_VEHICLE_FIT_TYPE)<VehicleFitType>()
export const removeVehicleAction = createAction(REMOVE_VEHICLE)<Vehicle>()
export const resetUserProfileAction = createAction(CLEAR_USER_PROFILE)()
export const showNoVehicleAction = createAction(SHOW_NO_VEHICLE)<Vehicle[]>()
export const setCriticalFitmentDetails = createAction(SET_CRITICAL_FITMENT_DETAILS)<CriticalFitment[]>()
export const triggeredCriticalFitmentAction = createAction(CRITICAL_FITMENT_API_TRIGGERED)()
export const criticalFitmentDoneAction = createAction(CRITICAL_FITMENT_API_DONE)()
export const setCriticalFitmentFailureAction = createAction(SET_CRITICAL_FITMENT_ERROR)()
export const setSoftRecallCTAClickedAction = createAction(
    SET_SOFT_RECALL_CTA_CLICKED,
    (payload: SoftRecallCTARequestPayload) => payload,
)<SoftRecallCTARequestPayload>()
export const updateVehicleAction = createAction(UPDATE_VEHICLE)<Vehicle[]>()
export const resetCriticalFitmentDetails = createAction(RESET_CRITICAL_FITMENT_DETAILS)()
export const newVehicleFormProps = createAction(NEW_VEHICLE_FORM_PROPS)<Record<string, unknown>>()
export const rehydrateUserProfileAction = createAction(REHYDRATE_USER_PROFILE)<boolean>()
export const setSelectedVehicleAction = createAction(SET_SELECTED_VEHICLE)<string>()
export const setSelectedVehicleIndexAction = createAction(SET_SELECTED_VEHICLE_INDEX)<number>()
export const gigyaUpdateAction = createAction(GIGYA_UPDATED, (payload: boolean) => payload)<boolean>()
export const setIsTireOrDefaultVehiclePresentAction = createAction(
    SET_IS_TIRE_OR_DEFAULT_VEHICLE_PRESENT,
    (payload: boolean) => payload,
)<boolean>()
