import React from 'react'
import { string, bool, number, func, any } from 'prop-types'

import Button from '../../Button'
import TextInput from '../../TextInput'
import Dropdown from '../../Dropdown'
import { checkDataLength } from '../../../utils/checkDataLength'
import { ActiveOwnershipPropsType } from './ActiveOwnership.types'
import SanitizeStringContentWrapper from '../../../utils/sanitizeStringContent'

/**
 * TriangleAccountCard component
 * @param {ActiveOwnershipPropsType} props
 * @return {JSX.Element} returns TriangleAccountCard
 */
const ActiveOwnership: React.FC<ActiveOwnershipPropsType> = ({
    activeOwnershipClass,
    title,
    subTitle,
    formSubmit,
    firstBtn,
    secondBtn,
    isBtnClicked,
    textInputId,
    textInputLabel,
    textInputVal,
    textInputChangeHandler,
    textError,
    textInputMaxLength,
    dropdownError,
    dropdownTitle,
    dropdownId,
    filterTitle,
    dropdownList,
    isFilter,
    selectedDropdownItem,
}): JSX.Element => {
    const sendError = (errorMsg?: string) => (isBtnClicked ? errorMsg : '')

    const renderFirstBtn = (): JSX.Element =>
        checkDataLength(firstBtn) && (
            <Button
                id={firstBtn?.id}
                type="primary"
                size="large"
                buttonType="submit"
                quantumMetricAttribute={{ type: 'allow', value: 'true' }}
                label={firstBtn?.label}
                showSpinner={firstBtn?.showSpinner}
            />
        )

    const renderSecondBtn = (): JSX.Element =>
        checkDataLength(secondBtn) && (
            <Button id={secondBtn?.id} type="tertiary" onClick={secondBtn?.clickHandler}>
                {secondBtn?.label}
            </Button>
        )

    return (
        <div className={`${activeOwnershipClass}`}>
            <SanitizeStringContentWrapper stringContent={title}>
                {memoizedStringContent => (
                    <div
                        className={`${activeOwnershipClass}__title`}
                        dangerouslySetInnerHTML={{ __html: memoizedStringContent }}
                    />
                )}
            </SanitizeStringContentWrapper>
            <div className={`${activeOwnershipClass}__subTitle`} dangerouslySetInnerHTML={{ __html: subTitle }} />
            <form className={`${activeOwnershipClass}__form`} onSubmit={formSubmit}>
                <TextInput
                    id={textInputId}
                    label={textInputLabel || ''}
                    value={textInputVal}
                    onChange={textInputChangeHandler}
                    error={sendError(textError)}
                    maxLength={textInputMaxLength}
                />
                <div className={`${activeOwnershipClass}__dropdown`}>
                    <Dropdown
                        dropdownTitle={dropdownTitle}
                        dropdownList={dropdownList}
                        dropdownIcon="ct-chevron-down"
                        size="default"
                        dropdownId={dropdownId}
                        selectedItem={selectedDropdownItem}
                        filterTitle={filterTitle}
                        type="primary"
                        isFilter={isFilter}
                        error={sendError(dropdownError)}
                    />
                </div>
                {renderFirstBtn()}
                {renderSecondBtn()}
            </form>
        </div>
    )
}
ActiveOwnership.propTypes = {
    activeOwnershipClass: string,
    title: string,
    subTitle: string,
    formSubmit: func,
    firstBtn: any,
    secondBtn: any,
    isBtnClicked: bool,
    textInputId: string,
    textInputLabel: string,
    textInputVal: string,
    textInputChangeHandler: func,
    textError: string,
    textInputMaxLength: number,
    dropdownError: string,
    dropdownTitle: string,
    dropdownId: string,
    filterTitle: string,
    dropdownList: any,
    isFilter: bool,
    selectedDropdownItem: func,
}
export default ActiveOwnership
