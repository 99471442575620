import { DOBFormatConstant } from './DOBFormatConstant.constant'
import getLanguage from './getLanguage'

/**
 * Get the date of birth in the format of the user's language
 * @param {string} DOBValue - The date of birth value
 * @param {string} monthFormat - The format of the month
 * @returns {string} The date of birth in the format of the user's language
 */
export const getDateOfBirth = (DOBValue: string, monthFormat = 'long'): string => {
    const language = getLanguage()
    const date = DOBValue.slice(0, DOBFormatConstant.dateLastIndex)
    const month = DOBValue.slice(DOBFormatConstant.dateLastIndex, DOBFormatConstant.monthLastIndex)
    const year = DOBValue.slice(DOBFormatConstant.yearFirstIndex)
    const formatedDOB = date + '/' + month + '/' + year
    const objDate = new Date(formatedDOB)
    return objDate.toLocaleDateString(language || 'en', {
        year: 'numeric',
        month: monthFormat as 'long' | 'short' | '2-digit' | 'numeric' | undefined,
        day: '2-digit',
    })
}
