/**
 * Base class to create new environment configuration
 */
export abstract class EnvironmentConfig {
    abstract API_BASE_URL: string
    abstract API_V1: string
    abstract API_MOCK_URL: string
    abstract AEM_URL: string
    abstract TMX_SESSION_VALUE: string
    abstract SIGNIFYD_SESSION_VALUE: string
    abstract API_ENDPOINTS: Record<string, string>
    abstract LOCAL_STORAGE_KEYS: Record<string, string>
    abstract SESSION_STORAGE_KEYS: Record<string, string>
    abstract serviceClient: string
    abstract serviceVersion: string
    abstract ocpApimSubscriptionKey: string
    abstract baseSiteId: string
    abstract SSO_BASE_URL: string
    abstract language: string
    abstract storeName: string
    abstract HYBRIS_BASE_URL: string
    abstract AUTO_MOTIVE_URL: string
    abstract JSON_RESPONSE_FORMAT: string
    abstract CANADA_POST_BASE_URL: string
    abstract CRITEO_CONFIG: Record<string, string>
    abstract banner: string
    abstract defaultProductImage: string
}
