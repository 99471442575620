export const ADD_TO_CART_GUEST_SUCCESS = 'ADD_TO_CART_GUEST_SUCCESS'
export const ADD_TO_CART_GUEST_FAILURE = 'ADD_TO_CART_GUEST_FAILURE'
export const RESET_CART_GUID_CREATED_FLAG = 'RESET_CART_GUID_CREATED_FLAG'
export const CLEAR_ADD_TO_CART_RESPONSE = 'CLEAR_ADD_TO_CART_RESPONSE'
export const CLEAR_ADD_TO_CART_ERROR_DATA = 'CLEAR_ADD_TO_CART_ERROR_DATA'
export const ADD_SERVICE_TO_CART_SUCCESS = 'ADD_SERVICE_TO_CART_SUCCESS'
export const ADD_SERVICE_TO_CART_FAILURE = 'ADD_SERVICE_TO_CART_FAILURE'
export const GET_CART_ITEMS_SUCCESS = 'GET_CART_ITEMS_SUCCESS'
export const GET_MINI_CART_ITEMS_SUCCESS = 'GET_MINI_CART_ITEMS_SUCCESS'
export const GET_CHECKOUT_CART_ITEMS_SUCCESS = 'GET_CHECKOUT_CART_ITEMS_SUCCESS'
export const GET_CART_ITEMS_FAILURE = 'GET_CART_ITEMS_FAILURE'
export const UPDATE_CART_DATA_SUCCESS_ACTION = 'UPDATE_CART_DATA_SUCCESS_ACTION'
export const UPDATE_CART_DATA_FAILURE_ACTION = 'UPDATE_CART_DATA_FAILURE_ACTION'
export const RESERVATION_REQUEST_IN_PROGRESS = 'RESERVATION_REQUEST_IN_PROGRESS'
export const RESERVATION_REQUEST_FINISHED = 'RESERVATION_REQUEST_FINISHED'
export const RESERVATION_REQUEST_RESET = 'RESERVATION_REQUEST_RESET'
export const RESET_INIT_CHECKOUT_ERROR = 'RESET_INIT_CHECKOUT_ERROR'
export const INIT_CHECKOUT_FAILURE = 'INIT_CHECKOUT_FAILURE'
export const SET_SERVICE = 'SET_SERVICE'
export const RESET_SERVICE = 'RESET_SERVICE'
export const REMOVE_CART_ITEM_SUCCESS = 'REMOVE_CART_ITEM_SUCCESS'
export const REMOVE_CART_BALLOON_PACKAGE_ITEM_SUCCESS = 'REMOVE_CART_BALLOON_PACKAGE_ITEM_SUCCESS'
export const RESET_REMOVE_SUCCESS = 'RESET_REMOVE_SUCCESS'
export const RESET_BALLOON_PACKAGE_ITEM_REMOVE_SUCCESS = 'RESET_BALLOON_PACKAGE_ITEM_REMOVE_SUCCESS'
export const REMOVE_CART_FAILURE = 'REMOVE_CART_FAILURE'
export const CART_DELIVERY_MODE_CHANGE_FAILURE = 'CART_DELIVERY_MODE_CHANGE_FAILURE'
export const CART_DELIVERY_MODE_CHANGE_RESET = 'CART_DELIVERY_MODE_CHANGE_RESET'
export const UPDATE_DELIVERY_METHOD_SUCCESS_ACTION = 'UPDATE_DELIVERY_METHOD_SUCCESS_ACTION'
export const UPDATE_DELIVERY_METHOD_FAILURE_ACTION = 'UPDATE_DELIVERY_METHOD_FAILURE_ACTION'
export const UPDATE_POSTAL_CODE_SUCCESS = 'UPDATE_POSTAL_CODE_SUCCESS'
export const UPDATE_POSTAL_CODE_PARTIAL_SUCCESS = 'UPDATE_POSTAL_CODE_PARTIAL_SUCCESS'
export const UPDATE_POSTAL_CODE_FAILURE = 'UPDATE_POSTAL_CODE_FAILURE'
export const SET_FULFILLMENT_OPTION_CLICK = 'SET_FULFILLMENT_OPTION_CLICK'
export const UPDATE_POSTAL_CODE_ERROR = 'UPDATE_POSTAL_CODE_ERROR'
export const GET_POSTAL_CODE_DATA_SUCCESS = 'GET_POSTAL_CODE_DATA_SUCCESS'
export const GET_POSTAL_CODE_DATA_ERROR = 'GET_POSTAL_CODE_DATA_ERROR'
export const RESET_POSTAL_CODE_CHANGE_SUCCESS = 'RESET_POSTAL_CODE_CHANGE_SUCCESS'
export const RESET_CART_VALIDATIONS = 'RESET_CART_VALIDATIONS'
export const UPDATE_ANALYTICS_INFO = 'UPDATE_ANALYTICS_INFO'
export const GET_MERGE_CART = 'GET_MERGE_CART'
export const SET_SELECTED_AUTHCART = 'SET_SELECTED_AUTHCART'
export const SET_XHR_INFO_GET_CART = 'SET_XHR_INFO_GET_CART'
export const SET_ERROR_INFO_CART = 'SET_ERROR_INFO_CART'
export const GET_CART_ITEMS_AUTH = 'GET_CART_ITEMS_AUTH'
export const MERGE_CART_SUCCESS = 'MERGE_CART_SUCCESS'
export const POSTAL_CODE_DISPATCH_STARTED = 'POSTAL_CODE_DISPATCH_STARTED'
export const SET_SFL_TOAST_VARIABLES = 'SET_SFL_TOAST_VARIABLES'

export const SHIPPING_ESTIMATION_IN_PROGRESS = 'SHIPPING_ESTIMATION_IN_PROGRESS'
export const SHIPPING_ESTIMATION_SUCCESS = 'SHIPPING_ESTIMATION_SUCCESS'
export const SHIPPING_ESTIMATION_ERROR = 'SHIPPING_ESTIMATION_ERROR'
export const RESET_SHIPPING_ESTIMATION = 'RESET_SHIPPING_ESTIMATION'

export const APPLY_PROMO_CODE_SUCCESS = 'APPLY_PROMO_CODE_SUCCESS'
export const APPLY_PROMO_CODE_ERROR = 'APPLY_PROMO_CODE_ERROR'
export const RESET_PROMO_CODE_DATA = 'RESET_PROMO_CODE_DATA'
export const CART_PROMO_CODE_ERROR_TOAST_MSG = 'CART_PROMO_CODE_ERROR_TOAST_MSG'
export const GET_CART_PROMO_MESSAGE = 'GET_CART_PROMO_MESSAGE'
export const RESET_SHOW_AVAILABILITIES_CHANGE_TOAST = 'RESET_SHOW_AVAILABILITIES_CHANGE_TOAST'
export const SET_EXPRESS_DELIVERY_SELECTION = 'SET_EXPRESS_DELIVERY_SELECTION'
export const SET_EXPRESS_DELIVERY_PO_OUT_OF_RANGE = 'SET_EXPRESS_DELIVERY_PO_OUT_OF_RANGE'
export const SET_SHOPPING_CART_LIMIT_ITEMS_FAILURE = 'SET_SHOPPING_CART_LIMIT_ITEMS_FAILURE'
export const CLEAR_SHOPPING_CART_LIMIT_ITEMS_FAILURE = 'CLEAR_SHOPPING_CART_LIMIT_ITEMS_FAILURE'
export const SHOW_ESTIMATE_SHIPPING_MODAL = 'SHOW_ESTIMATE_SHIPPING_MODAL'
export const SET_NO_POSTAL_CODE_ERROR = 'SET_NO_POSTAL_CODE_ERROR'
export const SET_CHECKOUT_AUTH_CART_LOADED = 'SET_CHECKOUT_AUTH_CART_LOADED'
export const ADD_TO_CART_BUNDLE_SUCCESS = 'ADD_TO_CART_BUNDLE_SUCCESS'
export const ADD_TO_CART_BUNDLE_FAILURE = 'ADD_TO_CART_BUNDLE_FAILURE'
export const RESET_ADD_TO_CART_BUNDLE_STATE = 'RESET_ADD_TO_CART_BUNDLE_STATE'
export const SET_CHECK_ERRORS_IN_CART = 'SET_CHECK_ERRORS_IN_CART'
