import { createAction } from 'typesafe-actions'

import {
    ProductDataPayload,
    InitDataPayload,
    SelectedVariantDataPayload,
    VariantsDataPayload,
} from '../models/miniPDPFlyout.interface'

import {
    CLOSE_MINI_PDP_FLYOUT,
    SET_MINI_PDP_FLYOUT_DETAILS,
    INIT_MINI_PDP_FLOW,
    SET_SELECTED_VARIANT_VALUE,
    SET_SELECTED_VARIANT_ID,
    UPDATE_SELECTED_PRODUCT_CODE,
    OPEN_MINI_PDP_FLYOUT,
    SET_MINI_PDP_AEM_PROPS,
    UPDATE_SCU_DATA_BY_STORE,
    FETCH_MINI_PDP_FLYOUT_DATA_ERROR,
    IS_MINI_PDP_ATC_BUTTON_CLICKED,
} from '../types/products/miniPDPFlyout.actionTypes.constant'
import { MiniPDPProps } from '../../components/MiniPDP/MiniPDP.type'
import { IFeatureFlag } from '../models/commonContent.interface'

export const initMiniPDPFlowAction = createAction(INIT_MINI_PDP_FLOW)<InitDataPayload>()
export const setMiniPDPFlyoutDetailsAction = createAction(SET_MINI_PDP_FLYOUT_DETAILS)<ProductDataPayload>()
export const openMiniPDPFlyoutAction = createAction(OPEN_MINI_PDP_FLYOUT)<InitDataPayload>()
export const closeMiniPDPFlyoutAction = createAction(CLOSE_MINI_PDP_FLYOUT)()
export const setSelectedVariantValueAction = createAction(SET_SELECTED_VARIANT_VALUE)<SelectedVariantDataPayload>()
export const setSelectedVariantIDAction = createAction(SET_SELECTED_VARIANT_ID)<SelectedVariantDataPayload>()
export const updateSelectedProductCodeAction = createAction(UPDATE_SELECTED_PRODUCT_CODE)<string>()
export const setMiniPDPAEMProps = createAction(SET_MINI_PDP_AEM_PROPS)<MiniPDPProps & IFeatureFlag>()
export const updateScuDataByStoreAction = createAction(UPDATE_SCU_DATA_BY_STORE)<VariantsDataPayload>()
export const fetchMiniPDPFlyoutDataErrorAction = createAction(FETCH_MINI_PDP_FLYOUT_DATA_ERROR)()
export const isMiniPDPAtcButtonClicked = createAction(IS_MINI_PDP_ATC_BUTTON_CLICKED)<boolean>()
