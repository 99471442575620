import { createAction } from 'typesafe-actions'

import { IGlobalProps, GlobalAPIProps } from '../models/commonContent.interface'
import {
    SET_COMMON_CONTENT_FETCH_SUCCESS,
    SET_BADGE_PRIORITIES_START,
    SET_COMMON_API_SUCCESS,
    SET_COMMON_API_START,
    SET_COMMON_CONTENT_LOAD_STATUS,
} from '../types/commonContent/commonContent.actionTypes.constant'

export const updateBadgePriorities = createAction(SET_BADGE_PRIORITIES_START)()
export const setCommonContentFetchSuccess = createAction(SET_COMMON_CONTENT_FETCH_SUCCESS)<IGlobalProps>()
export const setCommonContentLoadStatus = createAction(SET_COMMON_CONTENT_LOAD_STATUS)<string>()
export const setCommonAPIContentSuccess = createAction(SET_COMMON_API_SUCCESS)<GlobalAPIProps>()
export const updateCommonApiContent = createAction(SET_COMMON_API_START)()
