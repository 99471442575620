import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import Icon from '../Icon'
import { InlineToastProps, InlineToastType } from './InlineToast.type'
import Button from '../Button'
import { ButtonTypes } from '../Button/Button.types'

/**
 * InlineToast component
 * @param {InlineToastProps} props
 * @returns {JSX.Element} returns InlineToast component
 */
const InlineToast: React.FC<InlineToastProps> = ({ ...props }) => {
    const { cta } = props

    const cssClass = `${PREFIX}-inline-toast`
    const cssContainer = cta?.ctaLabel ? `${cssClass}__container` : ''

    const toastWithButtonCTA = (
        <div className={`${cssClass} ${cssClass}--${props.type}`} aria-atomic="true">
            <div className={`${cssClass}__text-container ${cssContainer}`}>
                <div className={`${PREFIX}-row`}>
                    <span className={`${cssClass}__icon`}>
                        <Icon type={props.icon} size="lg" />
                    </span>
                    <p className={`${cssClass}__label`} dangerouslySetInnerHTML={{ __html: props.label }} />
                </div>
                {cta?.ctaLabel && (
                    <Button type={cta?.ctaType as ButtonTypes} size="mini" onClick={cta?.ctaClickedCallBack}>
                        {cta?.ctaLabel}
                    </Button>
                )}
            </div>
        </div>
    )

    const toastWithLinkCTA = (
        <div className={`${cssClass} ${cssClass}--${props.type}`} aria-atomic="true">
            <div
                className={`${PREFIX}-row ${PREFIX}-xs-align-items-center ${cssClass}__text-container ${cssContainer}`}>
                <span className={`${cssClass}__icon`}>
                    <Icon type={props.icon} size="lg" />
                </span>
                <p className={`${cssClass}__label`} dangerouslySetInnerHTML={{ __html: props.label }} />
                {cta?.ctaLabel && (
                    <Button type="tertiary" modifierClass={` ${cssClass}__linkLabel`} onClick={cta?.ctaClickedCallBack}>
                        {cta?.ctaLabel}
                    </Button>
                )}
            </div>
        </div>
    )

    return cta?.ctaType ? toastWithButtonCTA : toastWithLinkCTA
}

InlineToast.propTypes = {
    icon: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
        InlineToastType.SUCCESS,
        InlineToastType.ERROR,
        InlineToastType.WARNING,
        InlineToastType.INFO,
    ]).isRequired,
    label: PropTypes.string.isRequired,
}

export default InlineToast
