import { ProductResponseData } from '../../redux/models/product.interface'
import { BREAKPOINTS, PREFIX } from '../../config'
import { isAutomotivePDP } from '../Vehicles/Vehicle.helper'
import { MagicNumber } from '../../analytics/analytics.type'
import { expandAccordion } from '@nl/lib/src/components/StickyTOC/StickyTOC'
import { DeviceMediaQuery, StickyHeaderElementsData } from './scrollToReview.type'
import { calculateHeaderHeight } from '../StickyFilters/StickyFilters.helper'
import { bazaarVoiceConst } from '../BazaarVoice/bazaarVoice.constant'

/**
 * function to return Height of hidden DOM element
 * @param {HTMLElement} element
 * @return {number} returns Height of hidden element in DOM
 */
export const getHiddenHeight = (element: HTMLElement): number => {
    const clonedElementStyles = 'opacity:0;visibility:hidden;display:block!important'
    if (!element?.cloneNode) {
        return MagicNumber.ZERO
    }
    const clone = element.cloneNode(true) as HTMLElement
    clone.style.cssText = clonedElementStyles

    element.after(clone)
    const height = clone.offsetHeight

    clone.remove()

    return height
}
export const deviceMediaQuery = (): DeviceMediaQuery => {
    return {
        desktop: window.innerWidth > BREAKPOINTS.tabletMaxWidth,
        tablet: window.innerWidth <= BREAKPOINTS.tabletMaxWidth,
        mobile: window.innerWidth <= BREAKPOINTS.mobileMaxWidth,
    }
}
/**
 * @return {number} Height of StickyTOC panel
 * Height of Sticky header
 */
const stickyTOCHeight = (): number => {
    const stickyTOCEl = document.querySelector(`.${PREFIX}-Scrollspy-nav`) as HTMLElement
    return getHiddenHeight(stickyTOCEl) || MagicNumber.ZERO
}
/**
 * @param {ProductResponseData} productData Product Data parameters for check if Automotive page
 * @return {boolean} Returns Boolean depends on Automotive page
 * Product Data parameters for check if Automotive page
 */
export const isAutomotive = (productData: ProductResponseData): boolean => {
    return isAutomotivePDP(productData?.fitmentTypeCode, productData?.productWheelType)
}
/**
 * @param {string} containerName - name of demanded container
 * @return {Element} Element of demanded BV Container
 * getBVContainer - get Proper BazaarVoice Container by demand
 */
export const getBVContainer = (containerName: string): Element | undefined => {
    const bazaarVoiceComponents = Array.from(document.querySelectorAll('span[data-component="BazaarVoice"]'))
    return bazaarVoiceComponents.find(node => {
        const props = JSON.parse((node as HTMLElement).dataset.props || '{}') as Record<string, unknown>
        return props.container === containerName
    })
}

/**
 * @param {boolean} isMobile for mobile view
 * @return {void}
 * function to put focus after scroll to BV container on click of rating stars.
 */
export const putFocusBVContainer = (isMobile?: boolean): void => {
    const bvrContainer = !isMobile
        ? document.getElementById(bazaarVoiceConst.reviews.container)
        : (document
              .getElementById(bazaarVoiceConst.reviews.mobileContainer)
              ?.querySelector(`#${bazaarVoiceConst.reviews.idMobileContainer}`) as HTMLElement)
    if (!isMobile) {
        bvrContainer?.setAttribute('tabindex', `${MagicNumber.MINUS_ONE}`)
    }
    bvrContainer && bvrContainer.focus()
}

/**
 * @param {ProductResponseData} productData Product Data parameters for check if Automotive page
 * @param {boolean} isMobile
 * Auto Scroll To Review Section if Certona Carousels without Skeleton
 */
export const autoScrollToReview = (productData: ProductResponseData, isMobile?: boolean): void => {
    const reviewContainer = getBVContainer(bazaarVoiceConst.reviews.container) as HTMLElement
    if (deviceMediaQuery().tablet) {
        scrollIntoView(false, productData)
        return
    }

    const offsetTopToReview = (): number => {
        if (isAutomotive(productData)) {
            return reviewContainer?.offsetTop + MagicNumber.ONE
        }
        return reviewContainer?.offsetTop - calculateHeaderHeight() + stickyTOCHeight()
    }

    window.scroll({
        top: offsetTopToReview(),
    })
    putFocusBVContainer(isMobile)
}
/**
 * @param {boolean} mobile mobile resolution
 * @return {StickyHeaderElementsData} HTMLElements and Height of Sticky header
 * Height of Sticky header
 */
export const stickyHeaderHeight = (mobile?: boolean): StickyHeaderElementsData => {
    const reviewContainer = getBVContainer(bazaarVoiceConst.reviews.container)
    const reviewsAccordion = reviewContainer?.querySelector(`.${PREFIX}-accordion--minimize`) as HTMLElement
    const primaryNavEl = document.querySelector(`.${PREFIX}-primary-navigation-bar`)
    const stickyHeaderEl = document.querySelector(`.${PREFIX}-sticky-buy-bar`)
    const primaryNavHeight = primaryNavEl?.clientHeight || MagicNumber.ZERO
    return {
        review: reviewContainer as HTMLElement,
        accordion: reviewsAccordion,
        height: stickyHeaderEl?.getBoundingClientRect().height || mobile ? primaryNavHeight : MagicNumber.ZERO,
    }
}
/**
 * @param {boolean} shouldApplyTimeout for scroll behavior
 * @param {ProductResponseData} productData for Product
 * Scroll into view section
 */
const scrollIntoView = (shouldApplyTimeout: boolean, productData: ProductResponseData) => {
    if (typeof stickyHeaderHeight().review?.scrollIntoView === 'function') {
        const pageOffset = window.pageYOffset
        const elementTop = stickyHeaderHeight().review?.getBoundingClientRect().top || MagicNumber.ZERO
        const scrollTopToReview = pageOffset + elementTop - stickyHeaderHeight(deviceMediaQuery().mobile).height
        const mobileScrollTop = scrollTopToReview + MagicNumber.TEN
        const stickyTOCScrollTop = scrollTopToReview - stickyTOCHeight() * MagicNumber.TWO
        const tabletScrollTop = scrollTopToReview - stickyTOCHeight() + MagicNumber.TEN
        const setScrollReviewTop = (): number => {
            if (deviceMediaQuery().mobile) {
                return mobileScrollTop
            }
            if (deviceMediaQuery().tablet) {
                return tabletScrollTop
            }
            if (isAutomotive(productData)) {
                return scrollTopToReview
            }
            return stickyTOCScrollTop
        }

        window.scroll({
            top: setScrollReviewTop(),
            behavior: shouldApplyTimeout ? 'smooth' : 'auto',
        })
    }
}
/**
 * @param {boolean} shouldApplyTimeout for scroll behavior
 * @param {ProductResponseData} productData for Product
 * @param {boolean} isMobile for mobile view
 * @param {boolean} timer for timer
 * Scroll to review section in mobile and tablet view
 */
export const scrollToReviews = (
    shouldApplyTimeout: boolean,
    productData: ProductResponseData,
    isMobile?: boolean,
    timer = true,
): void => {
    const timeOut = timer ? MagicNumber.FOURHUNDRED : MagicNumber.ZERO
    if (deviceMediaQuery().tablet && stickyHeaderHeight().accordion) {
        expandAccordion(stickyHeaderHeight().accordion)
    }
    putFocusBVContainer(isMobile)
    setTimeout(() => scrollIntoView(shouldApplyTimeout, productData), timeOut)
}
