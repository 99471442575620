import { iconSizes } from '../Icon/Icon.types'

export enum SpinnerStyle {
    DEFAULT = 'default',
    SPLIT = 'split',
    SPLIT_TRAILING = 'split-trailing',
}

export enum SpinnerTypes {
    TRIANGLE = 'triangle',
    DEFAULT = 'default',
}

export enum Animation {
    FLIP = 'flip',
}

/**
 * @typedef {Object} SpinnerProps
 * @property {string} color - spinner color. Default is soft black _colours.scss; otherwise, black from commonContent is rgb(0,0,0) via general.globalSpinnerColor
 * @property {string} a11y label
 * @property {string} value to add inside spinner
 * @property {SpinnerStyle} style of spinner. Default is regular circular indeterminate style
 */
export interface SpinnerProps {
    color?: string
    a11yLabel?: string
    value?: InnerValueTypes
    style?: SpinnerStyle
}

export type InnerValueTypes = {
    type?: string[]
    animation?: Animation
    size?: iconSizes
}
