import React from 'react'
import { PREFIX } from '@nl/app/src/config'
import { SizeValueType } from './SizeChart.type'

/**
 * Size Value component
 * @param {SizeValueType} props - table value
 * @returns {JSX.Element} returns Size Value component
 */
const SizeValue: React.FC<SizeValueType> = ({ tableValue }): JSX.Element | null => {
    const createInnerHTML = () => {
        return {
            __html: tableValue,
        }
    }
    return <td className={`${PREFIX}-size-chart__content`} dangerouslySetInnerHTML={createInnerHTML()} />
}

export default SizeValue
