import {
    CartItemsData,
    MiniCartData,
    MiniCartStorageData,
    MiniCartVehicleInformation,
    CartOrderEntries,
    MiniCartOrderEntries,
    VersionedMiniCartData,
    CheckoutCartItemsData,
    CheckoutOrderEntries,
} from '../redux/models/cart.interface'
import { versionForMiniCartData } from '../globalConstants/global.constant'
import appCacheService from './appCacheService'
import { getCurrentDateTime } from './getDateTime'

/**
 * Function to get mini cart expirt
 * @returns {number} returns mini cart expiresIn
 */
export const getMiniCartExpiry = (): number => {
    const ttl: string = appCacheService.ttlForCartAndWishlistCache.get()
    let ttlValue = 0

    if (ttl) {
        const numericTtl = Number(ttl)
        ttlValue = isNaN(numericTtl) ? 0 : numericTtl
    }

    return getCurrentDateTime().getTime() + ttlValue * 1000
}

/**
 * Function to convert CartItemsData to MiniCartData or VersionedMiniCartData
 * @param {CartItemsData} cartData CartItemsData for converting
 * @param {boolean} isSetToLocalStorage used for identify version for setting to LocalStorage
 * @returns {MiniCartData | VersionedMiniCartData | MiniCartStorageData} returns MiniCartData or VersionedMiniCartData or MiniCartStorageData
 */
export const convertToMiniCartData = (
    cartData: CartItemsData | CheckoutCartItemsData,
    isSetToLocalStorage: boolean,
): MiniCartData | VersionedMiniCartData | MiniCartStorageData => {
    const cartId = cartData?.cartId
    const numberOfItems = cartData?.numberOfItems
    const updatedCartNumberOfItems = (cartData as CartItemsData)?.updatedCart?.numberOfItems
    const deliveryAddressPostalCode = cartData?.deliveryAddress?.address?.postalCode
    const orderEntries = cartData?.orderEntries && convertToMiniCartOrderEntries(cartData.orderEntries)
    const expiresIn = getMiniCartExpiry()
    let version
    if (isSetToLocalStorage) {
        version = versionForMiniCartData
    }

    return {
        cartId,
        numberOfItems,
        updatedCart: {
            numberOfItems: updatedCartNumberOfItems,
        },
        orderEntries,
        deliveryAddress: {
            address: {
                postalCode: deliveryAddressPostalCode,
            },
        },
        version: version as string,
        expiresIn,
    }
}

/**
 * Function to convert MiniCartData to MiniCartStorageData
 * @param {MiniCartData} miniCart MiniCartData for converting
 * @returns {MiniCartStorageData} returns MiniCartStorageData
 */
export const convertToMiniCartStorageData = (miniCart: MiniCartData): MiniCartStorageData => {
    return {
        ...miniCart,
        version: versionForMiniCartData,
        expiresIn: getMiniCartExpiry(),
    }
}

/**
 * Function to convert VersionedMiniCartData to MiniCartData
 * @param {VersionedMiniCartData} versionedMiniCartData VersionedMiniCartData for converting
 * @returns {MiniCartData} returns MiniCartData
 */
export const convertToMiniCartDataWithoutVersion = (versionedMiniCartData: VersionedMiniCartData): MiniCartData => {
    if (versionedMiniCartData.version) {
        const miniCartData = {
            ...versionedMiniCartData,
            version: undefined,
        }

        return miniCartData as MiniCartData
    }
    return versionedMiniCartData
}

/**
 * Function to convert CartOrderEntries[] to MiniCartOrderEntries[]
 * @param {CartOrderEntries[]} orderEntries CartOrderEntries[] for converting
 * @returns {MiniCartOrderEntries[]} returns MiniCartOrderEntries[]
 */
const convertToMiniCartOrderEntries = (
    orderEntries: Array<CartOrderEntries | CheckoutOrderEntries>,
): MiniCartOrderEntries[] => {
    return orderEntries.map(entry => {
        const vehicleInformation = entry.vehicleInformation
            ? {
                  year: entry.vehicleInformation.year,
                  body: entry.vehicleInformation.body,
                  make: entry.vehicleInformation.make,
                  model: entry.vehicleInformation.model,
                  options: entry.vehicleInformation.options,
              }
            : null
        const deliveryMode = entry.fulfillment?.deliveryMode
        return {
            vehicleInformation: vehicleInformation as MiniCartVehicleInformation,
            entryType: entry.entryType,
            cartEntryReference: entry.cartEntryReference as string,
            fulfillment: { deliveryMode },
            code: entry.code,
            isBulk: entry.isBulk,
        }
    })
}
