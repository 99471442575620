import { enableDestructOnUndefinedData } from './PDP/enableDestructOnUndefinedData.utils'
import { SharedCartState } from '../redux/reducers/sharedCart.reducer'
import { UserProfileDataState } from '../redux/reducers/user.profile.reducer'

export const isOneTimeCartForAuthUser = (userProfile: UserProfileDataState, sharedCart: SharedCartState): boolean => {
    const { isStoreSharedCart } = enableDestructOnUndefinedData(sharedCart?.cartConsuming?.storeSharedCart)
    const { userProfileData } = enableDestructOnUndefinedData(userProfile)
    const isSignedInUser = userProfileData && Object.keys(userProfileData).length
    return isStoreSharedCart && !!isSignedInUser
}
