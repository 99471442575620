import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { CategoryCardProps } from './CategoryCard.type'
import Button from '../Button'
import SanitizeStringContentWrapper from '../../utils/sanitizeStringContent'

/**
 * CategoryCard component
 * @param {CategoryCardProps} props
 * @return {JSX.Element} returns CategoryCard Component
 */
const CategoryCard: React.FC<CategoryCardProps> = props => {
    const {
        href,
        linkTarget,
        image,
        label,
        cardClicked,
        linkClass,
        imageClass,
        labelClass,
        cardKeyDown,
        sectionTitle,
        isPdpCategoryCarousel,
        categoryId,
        categoryCardClicked,
        buttonClass,
        buttonImgClass,
        buttonLabelClass,
    } = props
    const tileRef = useRef<HTMLImageElement>()

    useEffect(() => {
        if (tileRef && props.imagePolicies) {
            props.imagePolicies(tileRef.current)
        }
    }, [tileRef, props])

    return isPdpCategoryCarousel ? (
        <Button
            modifierClass={buttonClass}
            ariaLabel={label}
            type="tertiary"
            onClick={() => categoryCardClicked(label, categoryId, href)}>
            <img
                ref={tileRef}
                data-component-name="dynamic-category-featured-list"
                src={image}
                alt={label}
                className={buttonImgClass}
            />
            <span className={buttonLabelClass}>{label}</span>
        </Button>
    ) : (
        <SanitizeStringContentWrapper stringContent={href}>
            {memoizedStringContent => (
                <a
                    target={linkTarget}
                    onClick={() => cardClicked(label)}
                    onKeyDown={event => cardKeyDown(event)}
                    className={linkClass}
                    data-testid="category-link"
                    href={memoizedStringContent}
                    dap-wac-link={'true'}
                    dap-wac-loc={sectionTitle}
                    dap-wac-value={label}>
                    <img
                        ref={tileRef}
                        data-component-name="dynamic-category-featured-list"
                        src={image}
                        alt={label}
                        className={imageClass}
                    />
                    <span className={labelClass}>{label}</span>
                </a>
            )}
        </SanitizeStringContentWrapper>
    )
}

CategoryCard.propTypes = {
    href: PropTypes.string,
    linkTarget: PropTypes.string,
    image: PropTypes.string,
    label: PropTypes.string,
    cardClicked: PropTypes.func,
    linkClass: PropTypes.string,
    imageClass: PropTypes.string,
    labelClass: PropTypes.string,
    imagePolicies: PropTypes.func,
}

export default CategoryCard
