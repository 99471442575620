import { vehicleParametersRegExp } from '../../config'

/**
 * Get category Id  and vehicle preselected attribute from the URL
 * @param {string} url - url to parse
 * @return {{categoryId: string, vehicleParameters: string[]}}
 */
export const categoryUrlParserForPreselectedVehicle = (url: string) => {
    const categoryURLParts = url.split(vehicleParametersRegExp)
    const isThereAnyVehicleParameters = categoryURLParts.length > 1
    const separator = vehicleParametersRegExp.exec(url)?.[0]

    const generalCategoryURL = categoryURLParts[0]
    const vehicleParametersString = categoryURLParts[1]
    let vehicleParameters

    if (vehicleParametersString) {
        vehicleParameters = vehicleParametersString.split('/')
        const lastIndex = vehicleParameters.length - 1
        vehicleParameters[lastIndex] = vehicleParameters[lastIndex].split('.html')[0]
    }

    const categoryIdStartIndex = generalCategoryURL.lastIndexOf('-') + 1
    const categoryParameters = generalCategoryURL.slice(categoryIdStartIndex).split('/')

    const categoryIdString = categoryParameters[0]

    const categoryId = isThereAnyVehicleParameters ? categoryIdString : categoryIdString.split('.')[0]

    return { categoryId, vehicleParameters, separator }
}
