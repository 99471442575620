import React from 'react'
import { ImageContainerProps } from '../ShopTheLookComponent.type'
import { PREFIX } from '../../../config'
import { componentClassName } from '../ShopTheLookComponent.constant'
import { stringKeyCodes } from '@nl/lib'
import { shopTheLookAnalytics } from '../../../analytics/components/shopTheLookAnalytics'

/**
 * ImageContainer
 * @param props : ImageContainerProps
 * @returns : JSX.Element
 */
const ImageContainer: React.FC<ImageContainerProps> = ({ ...props }): JSX.Element => {
    const {
        desktopTabletImage,
        mobileImage,
        imageLink,
        imageAltText,
        imageLinkTarget,
        imageAreaLabel,
        imageLinkAnalyticsTrackingId,
        componentWidthType,
        isMobileLayout,
        sectionTitle,
    } = props

    /**
     * @param {React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>} event event
     * @returns {void}
     */
    const imageClickHandler = (
        event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
    ): void => {
        event.preventDefault()
        event.stopPropagation()
        shopTheLookAnalytics(imageLinkAnalyticsTrackingId)
        window.open(imageLink, imageLinkTarget || '_self', 'noopener')
    }

    /**
     * @param {React.KeyboardEvent<HTMLElement>} event event
     * @returns {void}
     */
    const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === stringKeyCodes.enter) {
            shopTheLookAnalytics(imageLinkAnalyticsTrackingId)
            window.open(imageLink, imageLinkTarget || '_self', 'noopener')
        }
    }

    return (
        <div className={`${componentClassName} ${PREFIX}-banner-${componentWidthType}`}>
            {imageLink ? (
                <div
                    className={`${componentClassName}__image-wrapper`}
                    role="link"
                    aria-label={imageAreaLabel}
                    onClick={event => imageClickHandler(event)}
                    onKeyDown={event => handleKeyDown(event)}
                    tabIndex={0}
                    dap-wac-link-section={sectionTitle}
                    dap-wac-value={imageAltText || imageAreaLabel}>
                    <div className={`${componentClassName}__image`}>
                        <img src={isMobileLayout ? mobileImage : desktopTabletImage} alt={imageAltText} />
                    </div>
                </div>
            ) : (
                <div className={`${componentClassName}__image`}>
                    <img src={isMobileLayout ? mobileImage : desktopTabletImage} alt={imageAltText} />
                </div>
            )}
        </div>
    )
}

export default ImageContainer
