import { Dispatch } from 'redux'
import ctMoneyBalanceService from '../../services/ctMoneyBalance/ctMoneyBalanceService'
import {
    fetchCTMoneyBalanceErrorAction,
    fetchCTMoneyBalanceSuccessAction,
    resetCTMoneyBalanceAction,
} from '../actionCreators/ctMoneyBalance.actionCreators'
import { CTMoneyBalanceResponse, ErrorResponse } from '../models/ctMoneyBalance.interface'
import { AxiosError } from 'axios'
import { setShowSpinner } from '../actionCreators'

/**
 * Fetch CT Money Balance
 * @param { string } loyaltyCardNumber passed as query param
 * @return { Promise }
 */
export const fetchCTMoneyBalance =
    (loyaltyCardNumber: string) =>
    (dispatch: Dispatch): Promise<void> => {
        return ctMoneyBalanceService
            .fetchCTMoneyBalanceService(loyaltyCardNumber)
            .then((response: { data: CTMoneyBalanceResponse }) => {
                dispatch(fetchCTMoneyBalanceSuccessAction(response.data))
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                const errorResponse = error?.response ? error.response.data : error
                dispatch(fetchCTMoneyBalanceErrorAction(errorResponse as ErrorResponse))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * reset ctMoney balance value
 * @return {Dispatch}
 */
export const resetCTMoneyBalance =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetCTMoneyBalanceAction())
    }
