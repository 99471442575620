import { pageTypes } from '../../config'
import { ModifiedCriteoPlacement } from './criteo.interface'

export enum OriginalPlacementKey {
    SEARCH_IN_GRID = 'viewSearchResult_API_desktop-InGrid',
    SEARCH_CAROUSEL = 'viewSearchResult_API_desktop-Carousel',
    CATEGORY_IN_GRID = 'viewCategory_API_desktop-InGrid',
    CATEGORY_CAROUSEL = 'viewCategory_API_desktop-Carousel',
}

export enum CriteoEventType {
    VIEW_CATEGORY = 'viewCategory',
    VIEW_SEARCH_RESULT = 'viewSearchResult',
    VIEW_ITEM = 'viewItem',
}

export enum CriteoPageId {
    VIEW_CATEGORY = 'viewCategory',
    VIEW_MERCHANDISING = 'viewMerchandising',
    VIEW_SEARCH_RESULT = 'viewSearchResult',
    VIEW_NULL_SEARCH_RESULT = 'viewNullSearchResult',
    VIEW_ITEM = 'viewItem',
}

export enum ModifiedPlacementKey {
    IN_GRID = 'inGrid',
    CAROUSEL = 'carousel',
}

export const initialPlacementObj: ModifiedCriteoPlacement = {
    products: [],
    onClickBeacon: '',
    onLoadBeacon: '',
    onViewBeacon: '',
}

export const inGridEligiblePages = [pageTypes.searchPage, ...pageTypes.categoryPages]

export const carouselEligiblePages = [pageTypes.productPage]

export const IN_GRID_MAX_SPONSORED_ADS = 4

export const SPONSORED_AD_ON_VIEW_THRESHOLD = 0.5

export enum CriteoCustomParams {
    PAGE_ID = 'page-id',
    PAGE_NUMBER = 'page-number',
    KEYWORDS = 'keywords',
    CATEGORY = 'category',
    EVENT_TYPE = 'event-type',
    AVAILABILITY = 'availability',
    PRICE = 'price',
    PARENT_ITEM = 'parent-item',
}
