import React from 'react'
import PropTypes from 'prop-types'

import { NotificationBadgeProps } from './NotificationBadge.type'
import { PREFIX } from '../config'

/**
 * NotificationBadge value/Count
 * @param {NotificationBadgeProps} props
 * @return {JSX.Element} returns notification badge value
 */
const NotificationBadge: React.FC<NotificationBadgeProps> = ({ ...props }) => {
    const { value, notificationClass, a11yValue, type, size, radius } = props
    const notificationBadgeClass = notificationClass ? notificationClass : ''
    const componentClass = `${PREFIX}-notification-badge`
    return (
        <span
            role="status"
            aria-label={a11yValue}
            className={`
        ${componentClass}__value
        ${componentClass}-${size}
        ${componentClass}-${type}
        ${componentClass}-${radius}
        ${notificationBadgeClass}
      `}>
            <span className="sr-only">{a11yValue}</span>
            <span aria-hidden="true">{value}</span>
        </span>
    )
}

NotificationBadge.propTypes = {
    value: PropTypes.number,
    notificationClass: PropTypes.string,
    a11yValue: PropTypes.string,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    type: PropTypes.oneOf(['success', 'caution', 'error', 'information']),
    radius: PropTypes.oneOf([
        'none',
        'rounded-xs',
        'rounded-sm',
        'rounded-md',
        'rounded-lg',
        'rounded-xl',
        'circle',
        'rounded-right',
    ]),
}

export default NotificationBadge
