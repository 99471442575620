import { SkuData, SkuPriceDataResponse } from '../../services/skuPriceService/skuPriceService.type'
import { ProductSku } from '../models/product.interface'
import { ProductDataTypeObj } from '../models/productData.interface'

/**
 * Util function to merge SkuPrice API's data with product's skus
 * @param {SkuPriceDataResponse} skuPriceData data from SkuPrice API
 * @param {ProductDataTypeObj[]} products products to merge
 * @returns {ProductDataTypeObj[]} products with merged prices
 */
const mergeSkuPriceDataWithProducts = (
    skuPriceData: SkuPriceDataResponse,
    products: ProductDataTypeObj[],
): ProductDataTypeObj[] => {
    const skusPricesMap = new Map<string, SkuData>(
        Object.values(skuPriceData.productFamilies).flatMap(item =>
            item.flatMap(sku => sku.codes.map(code => [code, sku])),
        ),
    )

    return products.map(product => {
        const updatedProduct = {
            ...product,
            skus: product.skus.map(sku => {
                const updatedSku = skusPricesMap.get(sku.code) as ProductSku
                return {
                    ...sku,
                    ...updatedSku,
                }
            }),
        }

        const sku = updatedProduct.skus?.[0]
        if (!updatedProduct.isSponsored || !sku) return updatedProduct

        return {
            ...updatedProduct,
            currentPrice: sku.currentPrice || updatedProduct.currentPrice,
            originalPrice: sku.originalPrice || updatedProduct.originalPrice,
            isOnSale: sku.isOnSale,
            saleCut: sku.saleCut,
            saleEndDate: sku.saleEndDate,
            displayWasLabel: sku.displayWasLabel,
            priceMessage: sku.priceMessage,
            promoMessages: sku.promoMessages,
            restrictedPromoMessages: sku.restrictedPromoMessages,
            badges: (updatedProduct.badges || []).concat(sku.badges),
        }
    })
}

export default mergeSkuPriceDataWithProducts
