import React, { useState } from 'react'

import { PREFIX } from '../config'
import { isArrayNotEmpty } from '../../utils'
import AutomotiveProductCardsWrapper from './AutomotiveProductCardsWrapper'
import { AutoProductVehicleContextCartItemProps, ProductDataDTO } from '../ProductCartItem/ProductItemInfo.type'
import CommonWarningToast from '../ProductCartItem/CommonWarningToast'
import { isProductWheel } from './AutomotiveProductCards.helper'
import { renderVehicleInfo } from '../../utils/renderVehicleInfo'

const AutomotiveProductCardsVehicleContextWrapper = (props: AutoProductVehicleContextCartItemProps): JSX.Element => {
    const [availabilityWarning, setAvailabilityWarning] = useState({ display: false, label: '' })
    const productData = props.products[0][0]
    // Check render conditions
    const renderAvailabilityWarning = (): JSX.Element =>
        availabilityWarning.display && (
            <CommonWarningToast icon="ct-notification-caution" bgType="orange" label={availabilityWarning.label} />
        )

    /**
     * function is used to validate if the vehicle has product wheels
     * @param {CartOrderEntries} products
     * @return {boolean}
     */
    const hasWheels = (products: ProductDataDTO[]) => {
        const filteredWheels = products.filter(product => isProductWheel(product))
        return filteredWheels.length > 0
    }

    return (
        isArrayNotEmpty(props.products) && (
            <div className={`${PREFIX}-automotive`}>
                <div className={`${PREFIX}-vehicle-context`}>
                    {renderAvailabilityWarning()}
                    {renderVehicleInfo(productData) && (
                        <h2 className={`${PREFIX}-automotive-card__car-mark-header`}>
                            {renderVehicleInfo(productData)}
                        </h2>
                    )}
                    {props.products?.map((productsPerVehicleContext, index) => (
                        <AutomotiveProductCardsWrapper
                            key={index}
                            setAvailabilityWarning={setAvailabilityWarning}
                            hasWheels={hasWheels(productsPerVehicleContext)}
                            {...props}
                            quantitySelectorId={`${props.quantitySelectorId}-${index}`}
                            products={productsPerVehicleContext}
                            a11yClickToReadFootnote={props.a11yClickToReadFootnote}
                            a11yTooltipIcon={props.a11yTooltipIcon}
                            createPackageAndNavigate={props.createPackageAndNavigate}
                        />
                    ))}
                </div>
            </div>
        )
    )
}

export default AutomotiveProductCardsVehicleContextWrapper
