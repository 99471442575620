/**
 * Used to avoid arrow function complexity on React components.
 * @template Type - obj type
 * @param {Type} data
 * @return {Type}
 */
export const enableDestructOnUndefinedData = <Type>(data: Type): Type => {
    return data || ({} as Type)
}

/**
 * Used to avoid arrow function complexity on React components.
 * @param {T} data
 * @return {T | string}
 */
export const dataOrString = <T>(data: T): T | string => {
    return data || ''
}
