/**
 * Product Card clearance Badge
 * @param {string[]}  badges - string array of badges
 * @param {boolean}  enableBadges - true when badges should be displayed
 * @returns {sting[]} returns badge 'CLEARANCE' if 'enableBadges' is true and the item is on 'CLEARANCE'
 */
export const renderClearanceBadge = (badges: string[], enableBadges: boolean): string[] => {
    if (!enableBadges) {
        return []
    }
    const hasSaleClearanceBadge = badges?.filter(
        item => item.includes('CLEARANCE') || item.includes('PROMO') || item.includes('SALE'),
    )
    if (
        (hasSaleClearanceBadge?.includes('PROMO') || hasSaleClearanceBadge?.includes('SALE')) &&
        hasSaleClearanceBadge?.includes('CLEARANCE')
    ) {
        return [hasSaleClearanceBadge[0]]
    }
    return hasSaleClearanceBadge || []
}
