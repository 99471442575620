import { PayloadAction } from 'typesafe-actions'

import store from '../store'
import { setCommonContent, setCommonContentSuccess } from '../redux/actions'
import sessionStorageService from '../utils/sessionStorageService'
import { libUtils } from '@nl/lib'

const language = libUtils.getLanguage()

/**
 * Dispatches an action to fetch common content data
 */
const fetchCommonContent = (): void => {
    store.dispatch(setCommonContent() as unknown as PayloadAction<'SET_BADGE_PRIORITIES_START', null>)
}

/**
 * checks common content entry in local storage. If available, sets in redux else calls an api to fetch
 * If the parsing of local storage data fails, removes the key from local storage and calls an api again to fetch data
 */
const fetchCommonContentData = (): void => {
    const commonContent = sessionStorageService.getItem(`global_props_${language}`)
    if (commonContent) {
        try {
            store.dispatch(setCommonContentSuccess(JSON.parse(commonContent)) as unknown as PayloadAction<any, any>)
        } catch (error) {
            sessionStorageService.removeItem(`global_props_${language}`)
            fetchCommonContent()
        }
    } else {
        fetchCommonContent()
    }
}

export default fetchCommonContentData
