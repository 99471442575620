import { TireWheelFitmentNote } from '../globalConstants'

/**
 * Save check that longString has substring
 * @param {string} longString
 * @param {string} substring
 * @return {boolean}
 */
const caseInsensitiveInclude = (longString: string, substring: string): boolean => {
    return String(longString).toLowerCase().includes(String(substring).toLowerCase())
}

/**
 * function to check whether it is staggered front specification
 * @param {string} fitmentNote
 * @return {boolean}
 */
export const isStaggeredFront = (fitmentNote: string): boolean => {
    return caseInsensitiveInclude(fitmentNote, TireWheelFitmentNote.FRONT)
}

/**
 * function to check whether it is staggered rear specification
 * @param {string} fitmentNote
 * @return {boolean}
 */
export const isStaggeredRear = (fitmentNote: string): boolean => {
    return caseInsensitiveInclude(fitmentNote, TireWheelFitmentNote.REAR)
}
