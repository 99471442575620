import React, { useEffect, useRef, useState } from 'react'
import {
    Icon,
    checkDataLength,
    getPageType,
    useGlobalScrollEvent,
    disableFocusLock,
    enableFocusLock,
    Button,
} from '@nl/lib'
import { BREAKPOINTS, PREFIX, pageTypes } from '../../config'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { IGeneral } from '../../redux/models/commonContent.interface'
import { RootState } from '../../redux/reducers'
import appCacheService from '../../utils/appCacheService'
import { KeepShoppingWidgetType } from './KeepShoppingWidget.type'
import { string } from 'prop-types'
import { CertonaProductType } from '../../certona/certona.type'
import { useAppSelector } from '../../hooks/react-redux.hook'

/**
 * Focuses on the first interactive element on the page with matching the given selector such as <a> and <button> elements.
 * If no focusable elements are found, this function does nothing.
 * @returns {void}
 */
const focusOnFirstInteractiveEle = () => {
    const focusableElementsSelector = ['a[href]', 'button:not([disabled])'].join(',')
    const firstElement = document.querySelectorAll(focusableElementsSelector)[0] as HTMLElement
    firstElement.focus()
}

/**
 * Checks if the user has scrolled to the top of the page.
 * @returns {boolean} True if the user is at the top of the page, otherwise false.
 */
const userIsAtTop = (): boolean => {
    return window.scrollY <= 0
}

/**
 * KeepShoppingWidget component
 * @param {KeepShoppingWidgetType} props
 * @returns {JSX.Element | null} returns BackToTop component
 */
const KeepShoppingWidget: React.FC<KeepShoppingWidgetType> = ({ ...props }): JSX.Element | null => {
    const { title, ctaLabel, rvTitle, schemaId, schemaIdL3, schemaIdL4, schemaIdL5 } = props
    const componentClassName = `${PREFIX}-keep-shopping-widget`
    const { userProfileData } = useAppSelector((state: RootState) => state.userProfile)
    const { isStickyBannerVisible } = useAppSelector((state: RootState) => state.stickyBannerData)
    const productRecommendations = useAppSelector((state: RootState) => state.certona)
    const { breadcrumbList } = useAppSelector((state: RootState) => state.categoryIdData)
    const { commonContentAvailable } = useAppSelector(commonContentSelector)
    const { general = {} as IGeneral } = commonContentAvailable
    const { disableWidgetMaxAge } = general
    const [keepShoppingWidgetData, setKeepShoppingWidgetData] = useState<CertonaProductType>()
    const [showBackToTopButton, setShowBackToTopButton] = useState(false)
    const [oldScrollValue, setOldScrollValue] = useState(0)
    const currentTime = new Date().getTime()
    const getDisableWidgetMaxAge = appCacheService.disableWidgetMaxAgeCache.get()
    const isWidgetCacheNotPresent = getDisableWidgetMaxAge ? currentTime > parseInt(getDisableWidgetMaxAge) : true
    const [isWidgetVisible, setIsWidgetVisible] = useState(isWidgetCacheNotPresent)
    const KeepShoppingWidgetRef = useRef(null)
    const focusLockEventRef = useRef<EventListener>()
    const widgetContainerRef = useRef<null | HTMLDivElement>(null)

    const isDesktop = window.innerWidth >= BREAKPOINTS.desktopMinWidth
    const isBackToTopInView = showBackToTopButton && isDesktop
    const widgetAndBackToTopClassName = isBackToTopInView ? `${PREFIX}-widget-and-backToTop` : ''
    const isAuthUser = !!checkDataLength(userProfileData)
    const isPageValid = isAuthUser ? getPageType() === pageTypes.homePage : true
    const schemes = productRecommendations?.resonance?.schemes
    const widgetReenableTime = appCacheService.disableWidgetMaxAgeCache.get()
    const showWidget = currentTime > parseInt(widgetReenableTime)

    const showKeepShoppingWidget = isPageValid && !isStickyBannerVisible

    /**
     * returns the schema ID based on the page type and breadcrumb length.
     * @returns {string | undefined} The schema ID for the current page.
     */
    const getSchemaId = (): string | undefined => {
        if (getPageType() === pageTypes.plp) {
            const breadcrumbLength = breadcrumbList.length
            if (breadcrumbLength === 3) {
                return schemaIdL3
            } else if (breadcrumbLength === 4) {
                return schemaIdL4
            } else if (breadcrumbLength === 5) {
                return schemaIdL5
            }
        } else {
            return schemaId
        }
    }
    const schemeData = schemes?.find(item => item.scheme === getSchemaId())

    /**
     * useEffect hook that updates the keep shopping widget data when the explanation matches the title.
     */
    useEffect(() => {
        if (schemeData?.explanation === rvTitle && schemeData?.items.length) {
            setKeepShoppingWidgetData(schemeData.items[0])
        }
    }, [rvTitle, schemeData])

    /**
     * Handler function to temporary disable widget
     */
    const disableWidgetHandler = () => {
        setIsWidgetVisible(false)
        const disableWidgetTimeout = currentTime + disableWidgetMaxAge
        appCacheService.disableWidgetMaxAgeCache.set(String(disableWidgetTimeout))
    }

    // condition checks whether to show the widget based on a cached time value
    if (showWidget) {
        setIsWidgetVisible(true)
        appCacheService.disableWidgetMaxAgeCache.remove()
    }

    /**
     * Handles the scroll event
     * @returns {void}
     */
    const handleScroll = () => {
        const newScrollValue = window.pageYOffset
        if (oldScrollValue < newScrollValue || userIsAtTop()) {
            setShowBackToTopButton(false)
        } else if (oldScrollValue > newScrollValue) {
            setShowBackToTopButton(true)
        }
        setOldScrollValue(newScrollValue)
    }

    useGlobalScrollEvent(handleScroll)

    /**
     * useEffect for focusLock Event
     */
    useEffect(() => {
        if (isWidgetVisible) {
            focusLockEventRef.current = enableFocusLock(KeepShoppingWidgetRef)
        } else {
            disableFocusLock(focusLockEventRef.current)
        }
    }, [isWidgetVisible, keepShoppingWidgetData])

    /**
     * renders KeepShopping Widget
     * @returns {JSX.Element}
     */
    const renderKeepShoppingWidget = (): JSX.Element => {
        return (
            <>
                {isWidgetVisible && keepShoppingWidgetData && (
                    <div className={`${componentClassName} ${widgetAndBackToTopClassName}`} ref={widgetContainerRef}>
                        <div
                            className={`${componentClassName}__content`}
                            ref={KeepShoppingWidgetRef}
                            role="alert"
                            aria-label={title}>
                            <Button
                                data-testid="close-keep-shopping-widget-btn"
                                modifierClass={`${componentClassName}__content__close-btn`}
                                onClick={disableWidgetHandler}
                                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                                    if (e.key === ' ' || e.key === 'Spacebar' || e.key === 'Enter') {
                                        e.preventDefault()
                                        disableWidgetHandler()
                                        focusOnFirstInteractiveEle()
                                    }
                                }}
                                ariaLabel={'keep-shopping-widget-close-button'}>
                                <Icon type="ct-close" size="md" />
                            </Button>
                            {keepShoppingWidgetData.image && (
                                <img
                                    className={`${componentClassName}__content__img`}
                                    alt=""
                                    src={keepShoppingWidgetData.image}
                                />
                            )}
                            <div>
                                <h4 className={`${componentClassName}__content__header`}>{title}</h4>
                                <p className={`${componentClassName}__content__product-name`}>
                                    {keepShoppingWidgetData.name}
                                </p>
                                <a
                                    className={`${PREFIX}-button ${PREFIX}-button--primary ${PREFIX}-button--mini ${componentClassName}__content__go-to-product-btn`}
                                    href={keepShoppingWidgetData.pdp_url}
                                    aria-label={ctaLabel}>
                                    {ctaLabel}
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }

    return showKeepShoppingWidget ? renderKeepShoppingWidget() : null
}

KeepShoppingWidget.propTypes = {
    title: string,
    ctaLabel: string,
    rvTitle: string,
    schemaId: string,
    schemaIdL3: string,
    schemaIdL4: string,
    schemaIdL5: string,
}

export default KeepShoppingWidget
