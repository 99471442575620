import { useEffect, useMemo, useState } from 'react'
import { productSelector } from '../../redux/selectors/product.selectors'
import { enableMPNSearchPreselectionSelector } from '../../redux/selectors/commonContent.selectors'
import { ProductSku, ProductResponseData } from '../../redux/models/product.interface'
import { requestParameterRegExp } from '../../globalConstants'
import { MagicNumber } from '../../analytics/analytics.type'
import { NON_WORD_CHARACTERS } from '../Vehicles/Vehicles.constant'
import { isSingleSku } from './BuyBox.helper'
import extractPCodeFromUrl from '../../utils/PDP/extractPCodeFromUrl'
import { isAutomotivePDP } from '../Vehicles/Vehicle.helper'
import { useAppSelector } from '../../hooks/react-redux.hook'

/**
 * Function to find SKU code in product data skus that matched part number
 * @param {ProductResponseData} productData
 * @param {string} partNumber
 * @return  {string}
 */
const findSkuByPartNumber = (productData: ProductResponseData, partNumber: string): string => {
    return (
        productData?.skus?.find(
            (skuObj: ProductSku) =>
                replaceNotAWordCharacters(skuObj?.partNumber) === replaceNotAWordCharacters(partNumber),
        )?.code || ''
    )
}

/**
 * Function to replace not a word characters
 * @param {string} line
 * @return  {string}
 */
const replaceNotAWordCharacters = (line: string): string => {
    return (line && line.toLowerCase().replace(NON_WORD_CHARACTERS, '')) || ''
}

/**
 * Custom hook to extract SKU code from product skus that matched part number provided during the search,
selectedSkuCode exists
 * @return  {string} skuCode - SKU code that matched provided part number
 */
const usePredefinedSku = (): string => {
    const { productData } = useAppSelector(productSelector)
    const isMPNSearchPreselectionEnabled = useAppSelector(enableMPNSearchPreselectionSelector)
    const skuCodeFromUrl = useMemo(() => extractPCodeFromUrl()?.skuCode, [])
    const [skuCode, setSkuCode] = useState('')
    const partNumber = RegExp(requestParameterRegExp).exec(window.location.href)?.[MagicNumber.ONE]
    const urlSKUCode = Object.keys(productData).length
        ? isAutomotivePDP(productData?.fitmentTypeCode, productData?.productWheelType)
            ? ''
            : skuCodeFromUrl
        : ''
    useEffect(() => {
        const innerSkuCode =
            skuCode ||
            (isSingleSku(productData) && productData.skus[MagicNumber.ZERO].code) ||
            (isMPNSearchPreselectionEnabled && partNumber && findSkuByPartNumber(productData, partNumber)) ||
            urlSKUCode ||
            ''

        setSkuCode(innerSkuCode)
    }, [skuCode, isMPNSearchPreselectionEnabled, productData, partNumber, urlSKUCode])

    return skuCode
}

export default usePredefinedSku
