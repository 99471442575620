import React from 'react'

import { PREFIX } from '../config'
import { VehicleAvailabilitySectionProps } from './VehicleAvailabilitySection.type'
import Icon from '../Icon'
import { iconTypes } from '../VehicleProductCard/VehicleProduct.constant'
import { getAccessibilityAvailabilityId } from '../../utils/getAccessibilityId'
import { replaceStrWithDynamicVal } from '../../utils'

/**
 * Returns Stock Icon
 * @param {string} iconType
 * @return {JSX.Element}
 */
const returnStockIcon = (iconType?: string): JSX.Element => {
    return (
        <span className={`${PREFIX}-product-card__availability-icon`}>
            <Icon type={`ct-${iconType ? iconType : iconTypes.checkmark}`} size="sm" />
        </span>
    )
}

/**
 * @param {boolean} sellable
 * @param {boolean} orderable
 * @param {number} quantity
 * @param {number} corpQuantity
 * @param {boolean} isWheelOrTire
 * @return {boolean}
 */
const showInStockOnlineLabel = (
    sellable: boolean,
    orderable: boolean,
    quantity: number,
    corpQuantity: number,
    isWheelOrTire: boolean,
): boolean => {
    if (sellable && orderable && corpQuantity) {
        if (isWheelOrTire) {
            return quantity < 4
        } else {
            return quantity === 0
        }
    } else {
        return false
    }
}

/**
 * @param {number} quantity
 * @param {number} corpQuantity
 * @return {boolean}
 */
const showOutOfStockLabel = (quantity: number, corpQuantity: number): boolean => {
    return !quantity && !corpQuantity
}

/**
 * @param {boolean} sellable
 * @param {number} quantity
 * @param {boolean} isWheelOrTire
 * @return {boolean}
 */
const showInStockAisleLabel = (sellable: boolean, quantity: number, isWheelOrTire: boolean): boolean => {
    return sellable && (isWheelOrTire ? quantity >= 4 : quantity > 0)
}

/**
 * @description Vehicle availability section component
 * @param {VehicleAvailabilitySectionProps} props
 * @return {JSX.Element}
 */
function VehicleAvailabilitySection({
    skuData,
    checkAvailabilityLabel,
    inStockAisleLabel,
    inStockOnlineLabel,
    outOfStockLabel,
    isUrgentLowStockLabel,
    isAutoPartPlp,
    accessibilityId,
    isWheelOrTirePDP,
    isUrgentLowStock,
}: VehicleAvailabilitySectionProps): JSX.Element {
    if (!skuData?.fulfillment) {
        return <div className={`${PREFIX}-product-card__availability-message`}></div>
    }
    const getAvailabilityLabel = (quantityFlag: number) => {
        const limitedStockMessage = isUrgentLowStockLabel

        if (isUrgentLowStock) {
            const urgencyMsg = limitedStockMessage
                ? replaceStrWithDynamicVal(limitedStockMessage, quantityFlag?.toString())
                : ''
            return (
                <span>
                    <span className={`${PREFIX}-product-card__urgentLowStock`}>{urgencyMsg}</span>
                </span>
            )
        } else {
            return replaceStrWithDynamicVal(inStockAisleLabel, quantityFlag?.toString() ?? '')
        }
    }

    const { sellable, orderable, fulfillment } = skuData
    const availability = fulfillment?.availability
    const quantity = availability?.quantity
    const Corporate = availability?.Corporate
    const corpQuantity = Corporate?.Quantity

    const getTitle = (): string => {
        switch (true) {
            case showOutOfStockLabel(quantity, corpQuantity):
                return outOfStockLabel
            case showInStockOnlineLabel(sellable, orderable, quantity, corpQuantity, isWheelOrTirePDP):
                return inStockOnlineLabel
            case showInStockAisleLabel(sellable, quantity, isWheelOrTirePDP):
                return inStockAisleLabel

            default:
                return checkAvailabilityLabel
        }
    }

    const label = getTitle()
    return (
        <div
            className={`${PREFIX}-product-card__availability-message`}
            id={isAutoPartPlp ? getAccessibilityAvailabilityId(accessibilityId) : undefined}>
            {label === outOfStockLabel ? returnStockIcon(iconTypes.prohibited) : returnStockIcon()}
            {label === inStockAisleLabel ? getAvailabilityLabel(quantity) : label}
        </div>
    )
}

export default VehicleAvailabilitySection
