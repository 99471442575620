import { ActionType } from 'typesafe-actions'

import { PackageLandingInitialState } from '../../components/AutomotivePackage/PackageLanding/PackageLanding.type'
import {
    UPDATE_IS_PACKAGE_FLOW,
    UPDATE_AUTO_PACKAGES,
    UPDATE_CURRENT_PACKAGE_ID,
    RESET_AUTO_PACKAGES,
    SET_IS_CREATE_PACKAGE_MODAL_OPEN,
    SET_IS_CREATE_PACKAGE_CLICK,
} from '../types/automotive/automotiveVehicle.actionTypes.constant'
import * as actions from '../actionCreators/automotiveVehicle.actionCreators'

type Action = ActionType<typeof actions>

export const initialState: PackageLandingInitialState = {
    isPackageFlow: false,
    autoPackages: [],
    isCreatePackageModalOpen: false,
    isCreatePackageClick: false,
    isCancelPackageClick: false,
}

/**
 *
 * @param {PackageLandingInitialState} state Initial states
 * @param {Action} action Action will be for updating redux state data
 * @return {PackageLandingInitialState} updated state
 */
export const AutomotivePackage = (
    state: PackageLandingInitialState = initialState,
    action: Action,
): PackageLandingInitialState => {
    switch (action.type) {
        case UPDATE_IS_PACKAGE_FLOW: {
            return {
                ...state,
                isPackageFlow: !!action.payload,
            }
        }
        case UPDATE_CURRENT_PACKAGE_ID: {
            return {
                ...state,
                currentPackageId: action.payload,
            }
        }
        case UPDATE_AUTO_PACKAGES: {
            return {
                ...state,
                autoPackages: action.payload,
            }
        }
        case RESET_AUTO_PACKAGES: {
            return {
                ...state,
                isPackageFlow: false,
                autoPackages: [],
                isCancelPackageClick: true,
                currentPackageId: undefined,
            }
        }
        case SET_IS_CREATE_PACKAGE_MODAL_OPEN: {
            return { ...state, isCreatePackageModalOpen: action.payload }
        }
        case SET_IS_CREATE_PACKAGE_CLICK: {
            return { ...state, isCreatePackageClick: action.payload }
        }
        default:
            return state
    }
}
