import spaAnalytics from '../analytics/components/spaAnalytics'

/**
 * SSO login analytics
 * @param  {boolean} success - if user becomes authorized
 * @returns {void}
 */
export const ssoLoginAnalyticsEvent = (success: boolean): void => {
    spaAnalytics.setSSOLoginAnalytics(success)
}

/**
 * User Login From CLP analytics
 * @param  {boolean} success - if user becomes authorized
 * @returns {void}
 */
export const setLoginFromCLPAnalytics = (success: boolean): void => {
    spaAnalytics.setLoginFromCLPAnalytics(success)
}
