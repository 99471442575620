import React from 'react'
import { PREFIX } from '../config'
import Button from '../Button'
import Icon from '../Icon'

/**
 * function to render footer section in see all filters
 * @param { boolean } enableVerticalFilterToggle
 * @param { boolean } isMobileOrTablet
 * @param { boolean } enableSortByFacet
 * @param { string } mSortByCTALabel
 * @param { void } modifiedCloseFlyout
 * @param { string } ctaLabel
 * @returns {JSX.Element | null }
 */
const componentClassName = `${PREFIX}-facet-modal`
export const renderFlyoutModalFooter = (
    enableVerticalFilterToggle: boolean,
    isMobileOrTablet: boolean,
    enableSortByFacet: boolean,
    mSortByCTALabel: string,
    modifiedCloseFlyout: () => void,
    ctaLabel: string,
): JSX.Element | null => {
    return enableVerticalFilterToggle && !isMobileOrTablet ? null : (
        <div className={`${componentClassName}__footer`}>
            {enableSortByFacet ? (
                <Button id="add-to-cart" type="primary" size="large" onClick={modifiedCloseFlyout}>
                    {mSortByCTALabel}
                </Button>
            ) : (
                <Button size="large" onClick={modifiedCloseFlyout}>
                    {ctaLabel}
                </Button>
            )}
        </div>
    )
}

/**
 * function to render header section in see all filters
 * @param { boolean } enableVerticalFilterToggle
 * @param { boolean } isMobileOrTablet
 * @param { boolean } isTriangleOffer
 * @param { JSX.Element } renderTitleFacet
 * @param { void } modifiedCloseFlyout
 * @param { string } closeBtnLabel
 * @param { string } path
 * @returns {JSX.Element | null }
 */
export const renderFlyoutModalHeader = (
    enableVerticalFilterToggle: boolean,
    isMobileOrTablet: boolean,
    isTriangleOffer: boolean,
    renderTitleFacet: () => JSX.Element,
    modifiedCloseFlyout: () => void,
    closeBtnLabel: string,
    path: string,
): JSX.Element | null => {
    return enableVerticalFilterToggle && !isMobileOrTablet ? null : (
        <div className={`${componentClassName}__header`}>
            {!isTriangleOffer && renderTitleFacet()}
            <button
                className={`${componentClassName}__close-btn`}
                onClick={modifiedCloseFlyout}
                aria-label={closeBtnLabel}
                title={closeBtnLabel}>
                <Icon type="ct-close" size="lg" path={path} />
            </button>
        </div>
    )
}

/**
 * function to check vertical filters are enable
 * @param { boolean } enableVerticalFilterToggle
 * @param { boolean } isMobileOrTablet
 * @returns { boolean }
 */
export const isVerticalFilters = (enableVerticalFilterToggle: boolean, isMobileOrTablet: boolean): boolean => {
    return enableVerticalFilterToggle && !isMobileOrTablet
}
