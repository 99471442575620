import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators/addToSalesAlerts.actionCreators'
import { SignInForSalesAlerts } from '../models/addToSalesAlerts.interface'

type Action = ActionType<typeof actions>

export interface addToSalesAlertsState {
    signInForSalesAlerts: SignInForSalesAlerts
}

export const initialState: addToSalesAlertsState = {
    // sign in from sales alerts clicked state
    signInForSalesAlerts: { isClicked: false } as SignInForSalesAlerts,
}

export const addToSalesAlertsReducer = (
    state: addToSalesAlertsState = initialState,
    action: Action,
): addToSalesAlertsState => {
    switch (action.type) {
        case getType(actions.setSignInForSalesAlertsAction):
            return { ...state, signInForSalesAlerts: action.payload }
        case getType(actions.resetSignInForSalesAlertsAction):
            return { ...state, signInForSalesAlerts: action.payload }
        default:
            return state
    }
}
