import { useEffect } from 'react'
import { showPLACarousel } from '../components/Recommendations/RecommendationsComponent.helper'
import appCacheService from '../utils/appCacheService'

/**
 * Custom react hook that sets session storage on page upload for PLA carousel.
 * @returns {void} returns void
 */

export const usePLACarouselTracker = (): void => {
    useEffect(() => {
        const hasPLACarouselInSession = appCacheService.getIsPLACarouselShown()
        const setPlaSession = showPLACarousel() && !hasPLACarouselInSession

        /**
         * function sets session storage for PLA carousel. State will be true if PLA is visited already.
         * @returns {void} returns void
         */
        const handleBeforeUnload = (): void => {
            if (setPlaSession) {
                appCacheService.setIsPLACarouselShown(true)
            }
        }
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])
}
export default usePLACarouselTracker
