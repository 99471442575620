import { useCallback, useMemo } from 'react'
import { checkDataLength } from '../components/Accounts/Addresses/checkDataLength'
import { isAuthFlowExecutedSelector, userProfileDataSelector } from '../redux/selectors/userProfile.selectors'
import { useAuthProps } from './hook.type'
import { useAppSelector } from './react-redux.hook'

// Custom hook for check the auth conditions and running only once.
const useAuth = (
    ref?: useAuthProps,
): { authCondition: () => boolean; isAuthenticated: boolean; isLoggedIn: boolean } => {
    const userProfileData = useAppSelector(userProfileDataSelector)
    const isAuthFlowExecuted = useAppSelector(isAuthFlowExecutedSelector)

    const isAuthenticated = useMemo(
        () => isAuthFlowExecuted && checkDataLength(userProfileData),
        [isAuthFlowExecuted, userProfileData],
    )
    const isLoggedIn = useMemo(
        () => isAuthFlowExecuted && !!userProfileData?.signedIn,
        [isAuthFlowExecuted, userProfileData],
    )

    const runOnce = ref?.runOnce
    const authCondition = useCallback(() => {
        return checkDataLength(userProfileData) && runOnce?.current === 0
    }, [userProfileData, runOnce])

    return { authCondition, isAuthenticated, isLoggedIn }
}

export { useAuth }
export default useAuth
