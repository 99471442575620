import { countryCode, defaultCountryName, geocode } from '../../globalConstants/global.constant'
import {
    AutoCompletePredictionResponseType,
    GetPlaceDetailsParamType,
    GetPlaceDetailsRequestDTO,
    GetPlaceDetailsResponseType,
    GetPlaceDetailsResultType,
    PlaceServiceResponseType,
    SetStorageVariablesType,
} from './GooglePlaceService.type'

const googlePlaceServices = (window: Window & typeof globalThis): PlaceServiceResponseType => {
    let setPredictions: React.Dispatch<React.SetStateAction<AutoCompletePredictionResponseType[]>>
    let setGeometryDetails: React.Dispatch<React.SetStateAction<GetPlaceDetailsResponseType>>
    let sessionToken = ''
    let saveInput = ''

    /**
     * Generate a token for a single cycle. i.e. from search to click on one of the search results.
     * @return {string} Session token
     */
    const _generateSessionToken = (): string => {
        if (window.google === undefined) return
        return new window.google.maps.places.AutocompleteSessionToken() as string
    }

    /**
     * Function to generate new token when the one cycle completes.
     */
    const _updateSessionToken = (): void => {
        sessionToken = _generateSessionToken()
    }

    /**
     * Function will set up reserve recommendation if google autocomplete can't find the code
     * @param {GetPlaceDetailsResultType[]} placeDetails
     * @param {string} status
     */
    const _findInvalidCode = (placeDetails: GetPlaceDetailsResultType[], status: string) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const {
                formatted_address: description,
                place_id: placeId,
                address_components: {
                    [0]: { types },
                },
            } = placeDetails[0]
            if (description !== defaultCountryName) {
                setPredictions([
                    {
                        description,
                        place_id: placeId,
                        reference: placeId,
                        matched_substrings: [],
                        structured_formatting: {},
                        terms: [],
                        types,
                    },
                ])
                return
            }
        }
        setGeometryDetails({} as GetPlaceDetailsResponseType)
        setPredictions([]) // setting to empty when the status is other than "OK"
    }

    /**
     * Callback function once the autocomplete request is made.
     * @param {AutoCompletePredictionResponseType[]} predictions
     * @param {string} status
     */
    const _getPredictions = (predictions: AutoCompletePredictionResponseType[], status: string): void => {
        if (window.google === undefined) return
        if (status != window.google.maps.places.PlacesServiceStatus.OK) {
            if (!predictions?.length && saveInput) {
                _getPlaceDetails(
                    {
                        type: GetPlaceDetailsParamType.ADDRESS,
                        value: `${saveInput}, ${defaultCountryName}`,
                    },
                    _findInvalidCode,
                )
                saveInput = ''
            } else {
                setGeometryDetails({} as GetPlaceDetailsResponseType)
                setPredictions([]) // setting to empty when the status is other than "OK"
            }
            return
        }
        setPredictions(predictions)
    }

    /**
     * Callback function once the GeoService request is made.
     * @param {GetPlaceDetailsResultType[]} placeDetails
     * @param {string} status
     */
    const _getLatLong = (placeDetails: GetPlaceDetailsResultType[], status: string): void => {
        if (window.google === undefined) return
        if (status != window.google.maps.places.PlacesServiceStatus.OK) return
        const [result] = placeDetails // API will return a single object. left hand notation is for destructing the first item in the result.
        const { lat, lng } = result.geometry.location
        setGeometryDetails({
            lat: lat(),
            long: lng(),
        })
    }

    /**
     * Function to call geo service to fetch lat and long.
     * @param {GetPlaceDetailsRequestDTO} requestPayload - will hold either address or place_id
     * @param {function | null} customCallback - use custom callback if present
     * @param {string} region - specify a region if needed
     */
    const _getPlaceDetails = (
        requestPayload: GetPlaceDetailsRequestDTO,
        customCallback: typeof _getLatLong = null,
        region = '',
    ): void => {
        if (window.google === undefined) return
        const { type, value } = requestPayload
        const geoCoder = new window.google.maps.Geocoder()
        void geoCoder.geocode(
            {
                [type]: value,
                region,
            },
            customCallback || _getLatLong,
        )
    }

    /**
     * Function to call autocomplete service to get list of predictions.
     * @param {string} searchQuery
     */
    const _autoSuggestions = (searchQuery: string): void => {
        if (window.google === undefined) return
        const autoComplete = new window.google.maps.places.AutocompleteService()
        saveInput = searchQuery

        void autoComplete.getPlacePredictions(
            {
                input: searchQuery,
                sessionToken,
                componentRestrictions: {
                    country: countryCode.ca, // passing this param to get address only in canada.
                },
                types: [geocode],
            },
            _getPredictions,
        )
    }

    /**
     * Initialize getters to store the predictions and lat, long details.
     * @param {SetStorageVariablesType}{ storePredictions, storeLocationDetails }
     */
    const _setStorageVariables = async (
        { storePredictions, storeLocationDetails }: SetStorageVariablesType,
        setIsPlacesLoaded?: React.Dispatch<React.SetStateAction<boolean>>,
    ) => {
        try {
            await window.google.maps.importLibrary('places')
            setIsPlacesLoaded && setIsPlacesLoaded(true)
        } catch (error) {
            console.warn(error)
        }
        setPredictions = storePredictions
        setGeometryDetails = storeLocationDetails
        sessionToken = _generateSessionToken()
    }

    return {
        setStorageVariables: _setStorageVariables,
        autoSuggestion: _autoSuggestions,
        getPlaceDetails: _getPlaceDetails,
        updateSessionToken: _updateSessionToken,
    }
}

export default googlePlaceServices(window)
