import { RootState } from '../reducers'
import { OffersState } from '../reducers/offers.reducer'
import { EmailOfferActivationErrorResponse } from '../models/emailOfferActivation.interface'
import {
    CustomerOffers,
    CommunityOffers,
    OfferActivation,
    SwapOffer,
    TargetedOfferActivation,
    SwapableList,
} from '../models/offers.interface'
import { ErrorResponse } from '../models/commonContent.interface'

export const offersSelector = (state: RootState): OffersState => state.offers
export const targetedOfferActivatedDataSelector = (state: RootState): TargetedOfferActivation[] | null =>
    state.offers.targetedOfferActivatedData
export const offersActivatedDataSelector = (state: RootState): OfferActivation | null =>
    state.offers.offersActivatedData
export const offersActivatedErrorSelector = (state: RootState): EmailOfferActivationErrorResponse | null =>
    state.offers.offersActivatedError
export const offersDataSelector = (state: RootState): CustomerOffers | null => state.offers.offersData
export const communityOffersSelector = (state: RootState): CommunityOffers | null => state.offers.communityPoolOffers
export const swapOfferDataSelector = (state: RootState): SwapOffer | null => state.offers.swapOfferData
export const targetSwappableOffer = (state: RootState): SwapableList => state.offers.targetSwappableOffer
export const offerSwapErrorDataSelector = (state: RootState): ErrorResponse | null => state.offers.offerSwapErrorData
