import { OtpErrorType, componentType, otpErrorCodes } from '../globalConstants/global.constant'
import { MultiField } from '../types'
import { magicNumber } from './magicNumber'

/**
 * This function checks if the provided error code is related to OTP errors.
 * @param {string} errorCode - error code from response
 * @param {OtpErrorType} errorType - type of OTP error
 * @returns {boolean} true if errorCode is related to specified OTP error type, otherwise, false
 */
export const isOtpErrorCode = (errorCode = '', errorType = OtpErrorType.INIT_ERROR): boolean => {
    return Object.values(otpErrorCodes[errorType]).includes(errorCode)
}

/**
 * The function returns the value for a specific component from the passed array.
 * If there is no exact match, the value of the parent component will be passed.
 * If there is no specific parent component in the array 'default' value will be passed,
 * otherwise an empty string.
 * @param {MultiField[]} multiField
 * @param {string} component
 * @param {NestedObject} componentList
 * @returns {string} value for a specific component
 */
export const getValueByComponent = (
    multiField: MultiField[] | undefined,
    component: string | undefined,
    componentList: NestedObject,
): string => {
    if (!multiField || !component) {
        return ''
    }

    let result: MultiField | undefined
    const chainOfComponents = recursiveSearch(componentList, component)
    chainOfComponents?.push(componentType.default)

    chainOfComponents?.every(title => {
        result = multiField.find(field => title === field.component)
        return !result
    })

    return result ? result.value : ''
}

type NestedObject = {
    [key: string]: NestedObject | string
}

/**
 * Function returns all path to specific component as an array.
 * Ex. if component 'profile' then returns {'account.profile', 'account'}
 * @param {NestedObject} currentObj
 * @param {string} component
 * @param {string[]} path empty array by default
 * @returns {string[]} all path to specific component
 */
export const recursiveSearch = (
    currentObj: NestedObject,
    component: string,
    path = [] as string[],
): string[] | null => {
    for (const key in currentObj) {
        if (Object.prototype.hasOwnProperty.call(currentObj, key)) {
            const value = currentObj[key]
            const newPath = [key, ...path]
            if (key === component) {
                if (newPath.length > magicNumber.ONE) {
                    newPath[0] = newPath[1].concat(`.${newPath[0]}`)
                }
                return newPath
            }
            if (typeof value === 'object' && value !== null) {
                const result = recursiveSearch(value, component, newPath)
                if (result) {
                    return result
                }
            }
        }
    }
    return null
}
