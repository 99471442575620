import { colorRegex } from '../globalConstants/regexPatterns.constant'
import { checkNotNullAndUndefined } from './checkNotNullAndUndefined'

/**
 * Checks if the color is a string or rgba/hex/rgb/hsla
 * @param {string} value - string that needs to be tested for regex
 * @returns {boolean} true if color is string
 */
export const isColorStrVal = (value: string): boolean => {
    const updatedValue = checkNotNullAndUndefined(value) ? value : value.trim()
    return !colorRegex.test(updatedValue)
}
