import React, { useEffect, useMemo, useState } from 'react'

import { VerificationScreenProps } from './VerificationScreen.type'
import TextInput from './../TextInput'
import { magicNumber } from '../../utils'
import { setFocusToElement } from '../../utils/setFocusToElement'
import { PREFIX } from '../config'
import { verificationCodeRegex } from '../../globalConstants/regexPatterns.constant'
import { Variation } from '../../types'
import ButtonV2 from '../ButtonV2'
import { ButtonColors } from '../ButtonV2/Button.types'
import ButtonGroup from '../ButtonGroup'
import { Direction } from '../../types'
import InlineToast from '../InlineToast'
import { InlineToastType } from '../InlineToast/InlineToast.type'

/**
 * VerificationScreen component
 * @param {VerificationScreenProps} props
 * @returns {JSX.Element}
 */
const VerificationScreen: React.FC<VerificationScreenProps> = ({ ...props }: VerificationScreenProps): JSX.Element => {
    const verificationSreenCodeId = 'verification-screen-code'
    const componentClassName = `${PREFIX}-verification-screen`
    const {
        logo,
        title,
        subTitle,
        codeFieldLabel,
        ctaList,
        input: { onChange, error },
        email: email,
        toast,
    } = props

    const [code, setCode] = useState('')

    /**
     * useEffect hook to set focus to the element with the verification screen code ID
     * after the component has rendered.
     */
    useEffect(() => {
        setFocusToElement(verificationSreenCodeId)
    })

    useEffect(() => {
        if (!code || verificationCodeRegex.exec(code)) {
            onChange(false)
        }
    }, [code, onChange])

    /**
     * Verifies the entered code.
     * If the code matches the regular expression, handle the verification process.
     * Otherwise, sets an error message.
     */
    const verifyCodeHandler = () => {
        if (verificationCodeRegex.exec(code)) {
            ctaList[0].onClick(code)
        } else {
            onChange(true)
        }
    }

    const toastMessageProps = useMemo(() => {
        return {
            icon: 'ct-notification-caution',
            type: InlineToastType.WARNING,
            label: toast?.msg,
        }
    }, [toast?.msg])

    /**
     * Render the inline toast message
     * @returns InlineToast component if show is true
     */
    const renderToastMessage = (): JSX.Element => {
        return toast?.show ? <InlineToast {...toastMessageProps} /> : null
    }

    /**
     * Renders the header section of the Verification Screen.
     * @returns {JSX.Element} The header section of the Verification Screen.
     */
    const renderVerificationScreenHeader = (): JSX.Element => {
        return (
            <section
                className={`${PREFIX}-row ${PREFIX}-xs-align-items-center ${PREFIX}-xs-col-row ${componentClassName}__header`}>
                <div className={`${componentClassName}__header--logo`}>
                    <img src={logo} alt="" />
                </div>
                <p className={`${componentClassName}__header--title`}>{title}</p>
            </section>
        )
    }

    /**
     * Renders the footer section of the Verification Screen.
     * @returns {JSX.Element} The footer section of the Verification Screen.
     */
    const renderVerificationScreenFooter = (): JSX.Element => {
        return (
            <ButtonGroup
                wrap={false}
                direction={Direction.VERTICAL}
                customClass={`${PREFIX}-row ${PREFIX}-xs-align-items-center ${componentClassName}__footer`}>
                <ButtonV2
                    variant={ctaList[0]?.variant || Variation.PRIMARY}
                    onClick={verifyCodeHandler}
                    color={ctaList[0]?.color || ButtonColors.DARK}>
                    {ctaList[0].children}
                </ButtonV2>
                <ButtonV2
                    variant={ctaList[1]?.variant || Variation.TERTIARY}
                    onClick={ctaList[1].onClick}
                    color={ctaList[1]?.color || ButtonColors.DEFAULT}
                    disabled={ctaList[1]?.disabled}
                    aria-disabled={ctaList[1]?.a11y?.disabled}>
                    {ctaList[1].children}
                </ButtonV2>
            </ButtonGroup>
        )
    }

    /**
     * Renders the body section of the Verification Screen.
     * @returns {JSX.Element} The body section of the Verification Screen.
     */
    const renderVerificationScreenBody = (): JSX.Element => {
        return (
            <section
                className={`${PREFIX}-row ${PREFIX}-xs-align-items-center ${PREFIX}-xs-col-row ${componentClassName}__body`}>
                <p className={`${componentClassName}__body--text`}>{subTitle}</p>
                <p className={`${componentClassName}__body--email`}>{email}</p>
                <div className={`${PREFIX}-xs-justify-center ${componentClassName}__code-field`}>
                    <p className={`${componentClassName}__code-field-label`}>{codeFieldLabel}</p>
                    <TextInput
                        id={verificationSreenCodeId}
                        error={error}
                        errorIcon="ct-warning-triangle-sm"
                        maxLength={magicNumber.SIX}
                        onChange={setCode}
                    />
                </div>
                {renderToastMessage()}
            </section>
        )
    }

    return (
        <div className={componentClassName}>
            {renderVerificationScreenHeader()}
            {renderVerificationScreenBody()}
            {renderVerificationScreenFooter()}
        </div>
    )
}

export default VerificationScreen
