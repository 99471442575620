import { getEnvironment } from '../../environments'
import BaseService from '../base.service'
import { AppointmentAvailabilityDateTimeSlotsData } from '../../redux/models/appointmentAvailability.interface'
import { calendarConstants } from '../../components/AOABookingForm/AOADateTimeStep/AOADateTimeStep.constants'
import { inflationData } from '../../globalConstants'
import { CheckoutAppointmentInfo } from '../../redux/models/checkout.interface'
import { AxiosResponse } from 'axios'

const environment = getEnvironment()

/**
 * Service To fetch the available date time slots for a selected store
 */
class AppointmentAvailabilityService extends BaseService {
    /**
     * Sends the req payload and loads DateTime slots
     * @param { number } storeId - id of the current selected store
     * @param { string } startDate - start day date
     * @param { string } endDate -  end day date
     * @returns {AppointmentAvailabilityDateTimeSlotsData} API call
     */
    getBalloonsAppointmentAvailability(
        storeId: number,
        startDate: Date,
        endDate: Date,
        cartId?: string,
    ): Promise<{ data: AppointmentAvailabilityDateTimeSlotsData }> {
        const url = this.createGetBalloonsAppointmentAvailabilityUrl(storeId, cartId)
        const requestBody = {
            startDate: startDate.toLocaleDateString(calendarConstants.enCaFormat),
            endDate: endDate.toLocaleDateString(calendarConstants.enCaFormat),
            serviceCode: inflationData.inflationType,
        }

        return AppointmentAvailabilityService.apiMethod('post', url, requestBody)
    }

    /**
     * Create DataTime Slots url based on mock or cds
     * @param { number } storeId - id of the current selected store
     * @param { string } cartId - id of the cart
     * @returns {string} - Built URL
     */
    createGetBalloonsAppointmentAvailabilityUrl(storeId: number, cartId?: string): string {
        const {
            API_BASE_URL,
            API_ENDPOINTS: { getAppointmentAvailability },
        } = environment

        let url = `${API_BASE_URL}${getAppointmentAvailability}?storeId=${storeId}`
        if (cartId) {
            url += `&cartId=${cartId}`
        }

        return url
    }

    /**
     * Sends the req payload and loads DateTime slots
     * @param { string } cartId - id of the current cart
     * @param { CheckoutAppointmentInfo } appointmentInfo - info about appointment
     * @returns {{ data }} API response
     */
    saveBalloonsAppointmentAvailability(
        cartId: string,
        appointmentInfo: CheckoutAppointmentInfo,
    ): Promise<AxiosResponse> {
        const url = this.createSaveBalloonsAppointmentAvailabilityUrl(cartId)
        const requestBody: Record<string, unknown> = { bookedAppointments: [appointmentInfo] }

        return AppointmentAvailabilityService.apiMethod('post', url, requestBody)
    }

    /**
     * Creates url to save balloons appointment availability
     * @param { string } cartId - id of the current cart
     * @returns {string} - Built URL
     */
    createSaveBalloonsAppointmentAvailabilityUrl(cartId: string): string {
        const {
            API_BASE_URL,
            API_ENDPOINTS: { saveAppointmentAvailability },
        } = environment

        return `${API_BASE_URL}${saveAppointmentAvailability}?cartId=${cartId}`
    }
}

const appointmentAvailabilityService = new AppointmentAvailabilityService()

export { appointmentAvailabilityService }
export default appointmentAvailabilityService
