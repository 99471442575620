import React from 'react'
import PropTypes from 'prop-types'
import { HeaderBlockType } from './HeaderBlock.type'
import Button from '../Button'
import { PREFIX } from '../config'
import { headerBlockClassName } from './HeaderBlock.constant'
import SanitizeStringContentWrapper from '../../utils/sanitizeStringContent'
import { stringKeyCodes } from '../../utils'
import Heading from '../Heading'

const HeaderBlock: React.FC<HeaderBlockType> = ({ ...props }): JSX.Element => {
    const { title, subtitle, cta, toggleView, isCTAlink, ctaRedirectLink, openInfoModal } = props

    const onViewAllClick = (link: string) => {
        window.location.href = encodeURI(link)
    }

    const ctaRedirectButton = (isCtaLink: boolean, subtitleLinkLabel?: string): JSX.Element => {
        const buttonLabel = subtitleLinkLabel ? subtitleLinkLabel : cta?.ctaLabel
        const linkClass = subtitleLinkLabel
            ? `${PREFIX}-button--tertiary ${headerBlockClassName}__subtitleLink`
            : `${headerBlockClassName}__linkbutton ${PREFIX}-button ${PREFIX}-button--secondary ${PREFIX}-button--small`

        return (
            <>
                {isCtaLink ? (
                    <a
                        role="button"
                        tabIndex={0}
                        data-link-value={buttonLabel}
                        {...(ctaRedirectLink
                            ? {
                                  onClick: () => {
                                      openInfoModal()
                                  },
                                  onKeyUp: (event: React.KeyboardEvent<HTMLElement>) => {
                                      if (event.key === stringKeyCodes.enter) {
                                          openInfoModal()
                                      }
                                  },
                              }
                            : { href: cta?.ctaLink })}
                        className={linkClass}>
                        {buttonLabel}
                    </a>
                ) : (
                    <Button
                        type="secondary_reverse"
                        size="small"
                        label={cta?.ctaLabel}
                        onClick={() => onViewAllClick(cta?.ctaLink)}
                    />
                )}
            </>
        )
    }

    const dashboardViewButton = () => {
        return cta?.ctaLabel ? (
            <div className={`${title ? `${PREFIX}-xs-none ${PREFIX}-sm-none ${PREFIX}-md-block` : ''}`}>
                <div className={`${PREFIX}-md-block ${PREFIX}-xs-none`}>{ctaRedirectButton(isCTAlink)}</div>
                <div className={`${PREFIX}-md-none ${PREFIX}-xs-block`}>{ctaRedirectButton(isCTAlink)}</div>
            </div>
        ) : null
    }

    return title || toggleView ? (
        <div className={`${headerBlockClassName} ${PREFIX}-md-flex`}>
            {title && (
                <div className={`${headerBlockClassName}__title-view`}>
                    <SanitizeStringContentWrapper stringContent={title || ''}>
                        {memoizedStringContent => (
                            <Heading
                                variant={'h2'}
                                componentClass={`${
                                    toggleView
                                        ? `${headerBlockClassName}__title ${PREFIX}-body-xl`
                                        : `${headerBlockClassName}__header`
                                }`}>
                                {memoizedStringContent}
                            </Heading>
                        )}
                    </SanitizeStringContentWrapper>
                    {subtitle && (
                        <>
                            <p
                                className={`${headerBlockClassName}__subtitle`}
                                dangerouslySetInnerHTML={{ __html: subtitle || '' }}
                            />
                            {ctaRedirectLink && ctaRedirectButton(true, ctaRedirectLink)}
                        </>
                    )}
                </div>
            )}
            {toggleView && dashboardViewButton()}
        </div>
    ) : (
        <></>
    )
}

HeaderBlock.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
}
export default HeaderBlock
