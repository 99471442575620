import { onLoadEvent } from '../../components/Accounts/OrderHistory/GlobalPageLoadAnalytics.constant'
import { AnalyticsRecordInterface, OtpAnalyticsParams, BaseProvider } from './provider.type'

/**
 * GTM provider implementation
 */
export class Gtm implements BaseProvider {
    /**
     * Checking and initalizing analytics layer
     */
    constructor() {
        // check for layer object
        window.analyticsLayer = window.analyticsLayer || []
    }
    /**
     * Push data to analytics server
     * @param {AnalyticsRecordInterface} data - data to populate on analyticsLayer
     * @param {boolean} updateCLPObject - default false
     * @returns {void}
     */
    push(data: AnalyticsRecordInterface | OtpAnalyticsParams, updateCLPObject = false): void {
        const CLPObject = window.analyticsLayer.find(e => e?.event === onLoadEvent)
        if (updateCLPObject && !!CLPObject) {
            Object.assign(CLPObject, data)
        } else {
            window.analyticsLayer.push(data as unknown as Record<string, unknown>)
        }
    }
}
