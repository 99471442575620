import { magicNumber } from '@nl/lib'
import { getEnvironment } from '../../environments'
import { ProductDataTypeObj } from '../../redux/models/productData.interface'
import appCacheService from '../../utils/appCacheService'
import { RequestBody, RequestBodyItem, SkuPriceDataResponse } from './skuPriceService.type'
import getHttpClient from '../../httpClient'

const httpClient = getHttpClient()
const environment = getEnvironment()
/**
 *
 */
class SkuPriceService {
    /**
     * Creates SKU Price API URL
     * @param {string} preferredStoreId - preferred store id.
     * @returns {URL} url Pricing API URL
     */
    private createUrl(preferredStoreId: string): URL {
        const storeId = preferredStoreId || appCacheService.preferredStoreId.get()
        const {
            language,
            API_BASE_URL,
            API_ENDPOINTS: { skuPrice },
        } = environment

        const url = new URL(`${API_BASE_URL}${skuPrice}`)
        url.searchParams.set('lang', language)
        url.searchParams.set('storeId', storeId)

        return url
    }

    /**
     * Extracts product's brand label
     * @param {ProductDataTypeObj} product Product object
     * @returns {string} Brand label
     */
    private extractBrandLabel(product: ProductDataTypeObj): string {
        return product?.brand?.label || ''
    }

    /**
     * Creates request body item
     * @param {string} code product code
     * @param {string} brand brand label
     * @param {string[][]} skuSets array of product options skus
     * @returns {RequestBodyItem} Formatted request payload item
     */
    private createRequestBodyItem(code: string, brand: string, skuSets: string[][]): RequestBodyItem {
        return {
            code,
            brand,
            skuSets,
        }
    }

    /**
     * Creates request payload from products
     * @param {ProductDataTypeObj[]} products products to process
     * @returns {string} generated request payload
     */
    private createRequestBody(products: ProductDataTypeObj[]): RequestBody {
        return {
            productFamilies: products.map(product => {
                const brand = this.extractBrandLabel(product)
                if (product.skus.length === magicNumber.ONE) {
                    return this.createRequestBodyItem(product.code, brand, [[product.skus[0].code]])
                }
                const optionsSkus: string[][] = product.options.flatMap(
                    option => option.values?.map(value => value.skuCodes) || [],
                )

                return this.createRequestBodyItem(product.code, brand, optionsSkus)
            }),
        }
    }

    /**
     * This function used to fetch price and availability data for specified products
     * @param {ProductDataTypeObj[]} products products to request price for
     * @param {string} preferredStoreId - preferred store id.
     * @returns {Promise<SkuPriceDataResponse>} SkuPrice API response
     */
    fetchSkuPriceData(products: ProductDataTypeObj[], preferredStoreId: string): Promise<SkuPriceDataResponse> {
        const url = this.createUrl(preferredStoreId)
        const requestBody = JSON.stringify(this.createRequestBody(products))

        return new Promise<SkuPriceDataResponse>((resolve, reject) => {
            httpClient
                .apiPost<SkuPriceDataResponse>(url, requestBody)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

export const skuPriceService = new SkuPriceService()
