import { fetchInterceptorErrorAction } from '../../../actionCreators/fetchInterceptorError.actionCreators'
import interceptorConfig from '../../../../services/ResponseInterceptors/interceptorConfig.service'
import injectStoreInInterceptor from '../injectStoreInInterceptor'
import { excludeHttpStatusCodes } from './../../../../globalConstants/global.constant'
import { AxiosResponse } from 'axios'

export const httpResponseInterceptor = (
    response: AxiosResponse<{ errorCode?: number }>,
    resolve: (value: AxiosResponse<unknown> | PromiseLike<AxiosResponse<unknown>>) => void,
): void => {
    const store = injectStoreInInterceptor.getStoreValue()
    // TODO: This condition may be changed once backend configuration sub task is done
    if (!excludeHttpStatusCodes.includes(response?.status) && response?.data?.errorCode) {
        store.dispatch(fetchInterceptorErrorAction(response as unknown as Record<string, unknown>))
        interceptorConfig.setInterceptorTimeoutList({ resolveFunc: resolve })
    } else resolve(response)
}
