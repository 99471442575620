import { isArrayNotEmpty } from '@nl/lib'
import { Dispatch } from 'redux'
import { AxiosError, AxiosResponse } from 'axios'
import { loyaltyType } from '../models/user.profile.interface'
import {
    TriangleTransactionPayload,
    ErrorResponse,
    TriangleTransaction,
    TriangleTransactionResponse,
} from '../models/triangleTransactionHistory.interface'
import {
    fetchTriangleTransactionSuccessAction,
    fetchTriangleTransactionErrorAction,
} from '../actionCreators/triangleTransactionHistory.actionCreators'
import transactionHistoryService from '../../services/transactionHistory/transactionHistory.service'
import appCacheService from '../../utils/appCacheService'

/**
 * Fetch Triangle Transaction History
 * @param { FetchTriangleTransactionHistory } requestPayload pass data as part of requestPayload
 * @param { loyaltyType } linkLoyaltyCardType when user has a loyalty card, get cardNumber as enrollmentId
 * @param { TriangleTransaction[] } transactions previous Transactions
 * @return { Promise }
 */
export const fetchTriangleTransactionHistory =
    (
        requestPayload: TriangleTransactionPayload,
        linkLoyaltyCardType: loyaltyType | undefined,
        transactions: TriangleTransaction[] = [],
    ) =>
    (dispatch: Dispatch): Promise<void> => {
        return transactionHistoryService
            .fetchTransactionHistoryData(requestPayload, linkLoyaltyCardType)
            .then((data: AxiosResponse<TriangleTransactionResponse>) => {
                data.data.transactions =
                    isArrayNotEmpty(transactions) && isArrayNotEmpty(data.data.transactions)
                        ? ([...transactions, ...data.data.transactions] as TriangleTransaction[])
                        : data.data.transactions
                appCacheService.totalTraingleTransaction.set(data.data.totalResults)
                dispatch(fetchTriangleTransactionSuccessAction(data.data))
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                const errorResponse = error.response ? error.response.data : error
                dispatch(fetchTriangleTransactionErrorAction(errorResponse as ErrorResponse))
            })
    }
