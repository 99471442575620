import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/extraHardware.actionCreators'
import { HardwareList } from '../models/extraHardware.interface'
import {
    fetchExtraHardwareAction,
    extraHardwareFailureAction,
    setExtraHardwarePriceAction,
} from '../actionCreators/extraHardware.actionCreators'
import { extraHardwareService } from '../../services/extraHardwareService'
import { EmptyActionType } from '../models/globalData.interface'

type Action = ActionType<typeof actions> | EmptyActionType

export interface extraHardwareState {
    hardwareList: HardwareList[]
}

export const initialState: extraHardwareState = {
    hardwareList: [],
}

export const extraHardwareReducer = (state: extraHardwareState = initialState, action: Action): extraHardwareState => {
    switch (action.type) {
        case getType(fetchExtraHardwareAction): {
            return {
                ...state,
                hardwareList: action.payload ? state.hardwareList.concat(action.payload) : state.hardwareList,
            }
        }
        case getType(extraHardwareFailureAction): {
            return state
        }
        case getType(setExtraHardwarePriceAction): {
            return {
                ...state,
                hardwareList: extraHardwareService.setPriceAndAvailability(action.payload, state.hardwareList),
            }
        }
        default:
            return state
    }
}
