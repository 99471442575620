import React from 'react'
import PropTypes from 'prop-types'

import { AutoCompleteItemPropType } from './Autocomplete.types'
import { PREFIX } from '../config'

/**
 * AutocompleteItem component
 * @param {AutoCompleteItemPropType} props
 * @returns {JSX.Element} returns autocomplete item component
 */
const AutoCompleteItem: React.FC<AutoCompleteItemPropType> = ({
    item,
    onSelectItem,
    isHighlighted,
    renderItem,
}): JSX.Element => {
    return (
        <li
            className={`${PREFIX}-autocomplete-container__list-item ${
                isHighlighted ? `${PREFIX}-autocomplete-container__list-item__active` : ''
            }`}>
            <button
                className={`${PREFIX}-autocomplete-container__list-button `}
                data-testid="autocomplete-button"
                onClick={onSelectItem}>
                {renderItem?.({ ...item })}
            </button>
        </li>
    )
}

AutoCompleteItem.propTypes = {
    item: PropTypes.any,
    onSelectItem: PropTypes.func,
    isHighlighted: PropTypes.bool,
    renderItem: PropTypes.func,
}

export default AutoCompleteItem
