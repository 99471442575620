import React, { useRef, useEffect, useState, MouseEvent as ReactMouseEvent, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS, PREFIX } from '../config'
import { tooltipProps, TooltipPosition } from './Tooltip.types'
import { useClickOutsideClose } from './useClickOutside'
import Icon from '../Icon'
import Backdrop from './backdrop'
import BodyEnd from './TooltipBody'
import { useSetCoords } from './useSetCoords'
import { viewPortConstants } from './tooltip.constant'
import { disableFocusLock, enableFocusLock } from '../../utils/focusLock'
import { keyCodes } from '../../utils'
import { isPrerenderOrNot } from '../../utils/isPrerender'

/**
 * Tooltip component
 * @param {tooltipProps} props - outsideClick, headerText, bodyText, visibility, iconID, setVisibility, path, coords
 * @returns {JSX.Element} Tooltip
 */
const Tooltip: React.FC<tooltipProps> = ({
    outsideClick = true,
    headerText,
    bodyText,
    visibility,
    iconID,
    setVisibility,
    path,
    coords,
    a11yCloseIconLabel,
    isTooltipOnModal,
    isModalPresent,
    isPositionSticky,
    tooltipModifiedStyles = '',
}): JSX.Element => {
    const tooltipRef = useRef<HTMLDivElement>(null)
    const focusLockEventRef = useRef<EventListener>(null)
    const [readyToRender, setReadyToRender] = useState(false)

    const hideTooltip = (): void => {
        setVisibility(false)
    }

    const { divisionByTwo, halfOfwidth, oneThirdOfWidth, halfOfWidthTablet } = viewPortConstants

    const fetchCoords = useCallback((): TooltipPosition => {
        const rect = coords.getBoundingClientRect()
        const halfWidthForResolution = window.innerWidth > BREAKPOINTS.desktopMin ? halfOfwidth : halfOfWidthTablet
        const isOutOfleftViewPort = rect.left - halfWidthForResolution >= 0
        const isOutOfRightViewPort =
            rect.right + halfWidthForResolution <= (window.innerWidth || document.documentElement.clientWidth)
        const isInViewport = isOutOfleftViewPort && isOutOfRightViewPort
        const position = {
            visibleInViewport: isInViewport,
            visibleInViewportRight: isOutOfRightViewPort,
            visibleInViewportLeft: isOutOfleftViewPort,
            left: 0,
            top: isPositionSticky ? 'unset' : rect.top + (window.scrollY ? window.scrollY : 0) + rect.height,
        }
        if (!isInViewport) {
            if (!isOutOfRightViewPort) {
                position.left = rect.left + rect.width / divisionByTwo - oneThirdOfWidth
            } else if (!isOutOfleftViewPort) {
                position.left = rect.right - rect.width / divisionByTwo + oneThirdOfWidth // add half the width of the button for centering
            }
        } else {
            position.left = rect.left + rect.width / divisionByTwo // add half the width of the button for centering
        }

        return position
    }, [coords, divisionByTwo, halfOfWidthTablet, halfOfwidth, oneThirdOfWidth, isPositionSticky])

    const modifierClass = isModalPresent ? `${PREFIX}-tooltip__inside--modal` : ``
    const initialState = {
        left: 0,
        top: 0,
        visibleInViewport: true,
        visibleInViewportRight: true,
        visibleInViewportLeft: true,
    }
    const [leftTopCoords, setcoords] = useState<TooltipPosition>(initialState)

    /**
     * Adding the focus for previous element and closing the pop-up
     * @param { ReactMouseEvent | React.KeyboardEvent<HTMLButtonElement> } e - click event on submit
     */
    const closeHandler = (e: ReactMouseEvent | React.KeyboardEvent<HTMLButtonElement>): void => {
        e.stopPropagation()
        e.preventDefault()
        e.nativeEvent?.stopImmediatePropagation()
        disableFocusLock(focusLockEventRef.current)
        hideTooltip()
        coords.focus() // Highlight the initiated button
    }

    useClickOutsideClose(tooltipRef, closeHandler as unknown as (e: MouseEvent) => void, visibility, outsideClick)

    /**
     * closeHandler when keyboard event triggers
     */
    const closeModalEsc = useCallback(
        (e: KeyboardEvent): void => {
            if (e.keyCode === keyCodes.esc) {
                disableFocusLock(focusLockEventRef.current)
                setVisibility(false)
                coords.focus() // Highlight the initiated button
            }
        },
        [coords, setVisibility],
    )

    useEffect(() => {
        if (visibility) {
            document.body.addEventListener('keydown', closeModalEsc)
        } else {
            document.body.removeEventListener('keydown', closeModalEsc)
        }
    }, [visibility, closeModalEsc])

    /**
     * Setting the coordinates on  resize
     */
    const handleResize = (): void => {
        if (
            tooltipRef.current &&
            window.getComputedStyle(tooltipRef.current).getPropertyValue('position') === 'absolute'
        ) {
            setcoords(fetchCoords())
        } else {
            setcoords(initialState)
        }
    }

    useSetCoords(tooltipRef, setcoords, fetchCoords, handleResize, coords)

    const setTooltipUpArrow = useCallback(() => {
        const container = document.getElementsByClassName('nl-tooltip')[0] as HTMLElement
        if (!leftTopCoords.visibleInViewport && !leftTopCoords.visibleInViewportRight) {
            container.classList.add('nl-tooltip--right')
        } else if (!leftTopCoords.visibleInViewport && !leftTopCoords.visibleInViewportLeft) {
            container.classList.add('nl-tooltip--left')
        }
    }, [leftTopCoords])

    const setFocusOnTooltip = () => {
        focusLockEventRef.current && disableFocusLock(focusLockEventRef.current)
        focusLockEventRef.current = enableFocusLock(tooltipRef)
    }

    const visibleClass = useMemo(() => (readyToRender ? '' : `${PREFIX}-tooltip__hide`), [readyToRender])

    /**
     * useEffect to set:
     * - coordinates for tooltip (if needed)
     * - flag readyToRender
     */
    useEffect(() => {
        if (tooltipRef.current) {
            if (
                !isTooltipOnModal &&
                window.getComputedStyle(tooltipRef.current).getPropertyValue('position') === 'absolute'
            ) {
                tooltipRef.current.style.cssText =
                    'left:' + `${leftTopCoords.left}px;` + 'top:' + `${leftTopCoords.top}px;`
                setReadyToRender(!!leftTopCoords.left || !!leftTopCoords.top)
            } else {
                setReadyToRender(true)
            }
        }
    }, [isTooltipOnModal, leftTopCoords.left, leftTopCoords.top])

    /**
     * useEffect to configure tooltip if it is ready to render
     */
    useEffect(() => {
        if (!!visibility && coords && readyToRender) {
            !isTooltipOnModal &&
                window.getComputedStyle(tooltipRef.current).getPropertyValue('position') === 'absolute' &&
                setTooltipUpArrow()
            setFocusOnTooltip()
        }
    }, [coords, isTooltipOnModal, readyToRender, setTooltipUpArrow, visibility])

    return (
        <div>
            {!!visibility && coords && (
                <BodyEnd>
                    {!!visibility && <Backdrop tooltipModifiedStyles={tooltipModifiedStyles} />}
                    {!!visibility && (
                        <div
                            className={`${PREFIX}-tooltip ${modifierClass} ${tooltipModifiedStyles} ${visibleClass} ${
                                isPrerenderOrNot() ? `${PREFIX}-sm-none ${PREFIX}-xs-none` : ``
                            }`}
                            ref={tooltipRef}
                            id="tooltipRef"
                            data-testid="tooltipRef">
                            <div className="sr-only" role="alert" aria-live="assertive">
                                {headerText} {bodyText}
                            </div>
                            <div className={`${PREFIX}-tooltip__header ${PREFIX}-pb-sm`}>
                                <span className={`${PREFIX}-tooltip__header--text`}>{headerText}</span>
                                {!!iconID && !!iconID.length && (
                                    <button
                                        onClick={closeHandler}
                                        aria-label={a11yCloseIconLabel}
                                        data-testid="tooltip-close-button">
                                        <Icon type={iconID} title={`${a11yCloseIconLabel}`} size={'md'} path={path} />
                                    </button>
                                )}
                            </div>
                            <div className={`${PREFIX}-tooltip__body ${PREFIX}-body-xs`} data-testid="tooltip-body">
                                {bodyText}
                            </div>
                        </div>
                    )}
                </BodyEnd>
            )}
        </div>
    )
}
Tooltip.propTypes = {
    headerText: PropTypes.string,
    bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    visibility: PropTypes.bool,
    iconID: PropTypes.string,
    setVisibility: PropTypes.func,
    path: PropTypes.string,
    coords: PropTypes.any,
    outsideClick: PropTypes.any,
    a11yCloseIconLabel: PropTypes.string,
    isTooltipOnModal: PropTypes.bool,
    isPositionSticky: PropTypes.bool,
}

Tooltip.displayName = 'lib/Tooltip'

export default Tooltip
