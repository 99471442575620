// constant for bazaarVoice
export const bazaarVoiceConst: Record<string, Record<string, string>> = {
    rating_summary: {
        container: 'BVRRSummaryContainer',
        name: 'rating_summary',
    },
    review_highlights: {
        name: 'review_highlights',
    },
    reviews: {
        type: 'rr',
        view: 'show_reviews',
        container: 'BVRRContainer',
        name: 'reviews',
        // BV component for mobile device has different ID.
        mobileContainer: 'BVRRContainer-mobile',
        idMobileContainer: 'BVContainer',
    },
    questions: {
        type: 'qa',
        view: 'show_questions',
        container: 'BVQAContainer',
        name: 'questions',
    },
    componentStatus: {
        hiddenStatus: 'hidden',
        visibleStatus: 'visible',
    },
}
