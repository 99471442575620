import React, { LegacyRef, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { storeDetailsDataSelector } from '../../redux/selectors/storeDetails.selectors'
import { ShoppableComponentProps } from './ShoppableComponent.type'
import { fetchRecommendationsData } from '../../redux/actions/recommendationsData.action'
import { PREFIX } from '../../config'
import { constantValues } from './ShoppableComponent.constant'
import FixedHeightBanner from './FixedHeightBanner/FixedHeightBanner'
import ProductCardsSkeleton from './ProductCardsContainer/ProductCardsSkeleton'
import { useIntersect } from '../../hooks/layout.hook'
import { RootState } from '../../redux/reducers'
import ProductCardsContainer from './ProductCardsContainer/ProductCardsContainer'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'

const componentName = `${PREFIX}-shoppable-component`

/**
 * ShoppableComponent
 * @param props : ShoppableComponentProps
 * @returns : JSX.Element | null
 */
const ShoppableComponent: React.FC<ShoppableComponentProps> = ({ ...props }): JSX.Element | null => {
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(true)
    const { recommendationsData } = useAppSelector((state: RootState) => state.recommendationsData)
    const [isInViewport, setIsInViewport] = useState(false)
    const { preferredStoreDetails } = useAppSelector(storeDetailsDataSelector)
    const prevStoreInfoIdRef = useRef(preferredStoreDetails.id)
    const productQuantity = props.productCodeList.length
    const componentWidthType =
        productQuantity < constantValues.maximumProductQuantity
            ? constantValues.twoProductsClassName
            : constantValues.fourProductsClassName
    const productList =
        productQuantity >= constantValues.maximumProductQuantity
            ? props.productCodeList.slice(0, constantValues.maximumProductQuantity)
            : productQuantity >= constantValues.minimumProductQuantity
            ? props.productCodeList.slice(0, constantValues.minimumProductQuantity)
            : props.productCodeList
    const { triggerRender } = props
    const intersectCallback = (entry?: IntersectionObserverEntry) => {
        if (entry && entry.isIntersecting) {
            setIsInViewport(true)
        }
    }

    const options: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
    }

    const ShoppableComponentWrapper = useIntersect(intersectCallback, options)

    useEffect(() => {
        if (isInViewport) {
            if (prevStoreInfoIdRef.current !== preferredStoreDetails.id || triggerRender) {
                dispatch(fetchRecommendationsData(productList, recommendationsData, props.title))
                prevStoreInfoIdRef.current = preferredStoreDetails.id
                setLoading(false)
            }
            setIsInViewport(false)
        }
    }, [preferredStoreDetails.id, productList, isInViewport, recommendationsData, props.title, dispatch, triggerRender])

    return props.showComponent ? (
        <div
            ref={ShoppableComponentWrapper as LegacyRef<HTMLDivElement>}
            className={`${componentName} ${PREFIX}-${componentWidthType}-shoppable-component-height`}
            style={{ backgroundColor: props.bannerBackgroundColor }}>
            <FixedHeightBanner
                fontColor={props.bannerFontColour}
                title={props.title}
                bannerDescription={props.bannerDescription}
                imageLink={props.imageLink}
                imageUrl={props.imageUrl}
                imageUrlMobileAndTablet={props.imageUrlMobileAndTablet}
                imageAltText={props.imageAltText}
                componentWidthType={componentWidthType}></FixedHeightBanner>
            {loading ? (
                <ProductCardsSkeleton
                    productCardCount={productList.length}
                    componentWidthType={`${componentWidthType}`}></ProductCardsSkeleton>
            ) : (
                <ProductCardsContainer
                    componentWidthType={componentWidthType}
                    productCardCount={productList.length}
                    enableMiniPdpFlyoutSupport={props.enableQuicklookATC}
                    title={props.title}></ProductCardsContainer>
            )}
        </div>
    ) : null
}

ShoppableComponent.propTypes = {
    title: PropTypes.string.isRequired,
    bannerDescription: PropTypes.string,
    imageLink: PropTypes.string,
    imageUrlMobileAndTablet: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    imageAltText: PropTypes.string.isRequired,
    showComponent: PropTypes.bool.isRequired,
    productCodeList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    bannerBackgroundColor: PropTypes.string.isRequired,
    bannerFontColour: PropTypes.string.isRequired,
}

export default ShoppableComponent
