import { useEffect } from 'react'
import { MagicNumber } from '../../../analytics/analytics.type'
import { fetchNearbyStoreList } from '../../../redux/actions'
import { storeListRequestType } from '../../../redux/models/storeDetails.interface'
import { commonContentSelector } from '../../../redux/selectors/commonContent.selectors'
import getLocationUsingNavigator from '../../../utils/StoreLocatorUtil/getLocationUsingNavigator'
import { isPrerenderOrNot } from '@nl/lib'
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux.hook'

/**
 * Hook to fetch store list on page load when user opens website first time.
 * @param {string} preferredStoreId - preferred store id.
 * @return {void}.
 */
export const useFetchInitialStoreListOnLoad = (preferredStoreId: string): void => {
    const dispatch = useAppDispatch()
    const { isCommonContentAvailableInit, commonContentAvailable } = useAppSelector(commonContentSelector)
    const maxNumberOfStores = commonContentAvailable.storeLocator?.initialNumberOfStores

    useEffect(() => {
        if (isNaN(parseInt(preferredStoreId)) && isCommonContentAvailableInit && !isPrerenderOrNot()) {
            const requestPayload = {
                maxCount: maxNumberOfStores || MagicNumber.ONE,
            } as storeListRequestType

            // Fetch store list by IP
            dispatch(fetchNearbyStoreList(requestPayload, undefined, true))

            // If location is enabled fetch store list by location
            getLocationUsingNavigator({
                dispatch,
                requestPayload,
            }).setPreferredStoreOnClick()
        }
    }, [dispatch, preferredStoreId, isCommonContentAvailableInit, maxNumberOfStores])
}
