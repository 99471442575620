import { ActionType, getType } from 'typesafe-actions'

import { DeletePaymentSuccess, PaymentUnitType } from '../models/profile.payment.interface'
import * as actions from '../actionCreators/profile.payment.actionCreators'
import { ErrorResponse } from '../models/loyaltyRegisterForm.interface'
import { EmptyActionType } from '../models/globalData.interface'

type Action = ActionType<typeof actions> | EmptyActionType

export interface profilePaymentState {
    profilePaymentData: PaymentUnitType[] | null
    profilePaymentError: ErrorResponse
    deleteProfileData: DeletePaymentSuccess | null
    deleteProfileDataError: ErrorResponse | null
    hideRecommendation: boolean
}

export const initialState: profilePaymentState = {
    profilePaymentData: null,
    profilePaymentError: {} as unknown as ErrorResponse,
    deleteProfileData: {} as unknown as DeletePaymentSuccess,
    deleteProfileDataError: {} as unknown as ErrorResponse,
    hideRecommendation: false,
}

export const profilePaymentReducer = (
    state: profilePaymentState = initialState,
    action: Action,
): profilePaymentState => {
    switch (action.type) {
        case getType(actions.fetchPaymentSuccessAction): {
            return { ...state, profilePaymentData: action.payload }
        }
        case getType(actions.fetchPaymentErrorAction): {
            return { ...state, profilePaymentError: action.payload }
        }
        case getType(actions.resetPaymentAction): {
            return { ...state, profilePaymentData: null }
        }
        case getType(actions.deletePaymentInfoAction): {
            return { ...state, deleteProfileData: action.payload }
        }
        case getType(actions.deletePaymentInfoErrorAction): {
            return { ...state, deleteProfileDataError: action.payload }
        }
        case getType(actions.resetdeletePaymentInfoStates): {
            return { ...state, deleteProfileDataError: null, deleteProfileData: null }
        }
        case getType(actions.hideRecommendation): {
            return { ...state, hideRecommendation: action.payload }
        }
        default:
            return state
    }
}
