import { RootState } from '../reducers'
import { TireType } from '../models/tireVehicle.interface'
import { ProductDataType } from '../models/productData.interface'
export const tiresDataSelector = (state: RootState): TireType | null => state.vehicleTires.tiresData
export const isTiresDataSyncedWithLSONPageLoadSelector = (state: RootState): boolean =>
    state.vehicleTires.isTiresDataSyncedWithLSOnPageLoad
export const packageTiresDataSelector = (state: RootState): ProductDataType => state.vehicleTires.packageTiresData
export const packageWheelsDataSelector = (state: RootState): ProductDataType => state.vehicleTires.packageWheelsData
export const selectedTabSelector = (state: RootState): string | undefined => state.vehicleTires.selectedTab
export const vehicleSelectionModeSelector = (state: RootState): string => state.vehicleTires.vehicleSelectionMode
