import React from 'react'
import ReactCalendar from 'react-calendar'
import { CalendarProps } from './Calendar.types'

/**
 * This function return calendar component
 * @param { CalendarProps } props
 * @return { JSX.Element }
 */
export const Calendar: React.FC<CalendarProps> = (props: CalendarProps): JSX.Element => {
    const {
        clickDayHandler,
        showNeighboringMonth,
        activeStartDate,
        activeStartDateChangeHandler,
        formatShortWeekday,
        calendarType,
        locale,
        higherNextLabel,
        higherPrevLabel,
        nextLabel,
        prevLabel,
        maxDetail,
        minDetail,
        tileContentHandler,
        tileClassNameHandler,
        minDate,
        maxDate,
        tileDisabledHandler,
        value,
    } = props
    return (
        <ReactCalendar
            onClickDay={clickDayHandler}
            showNeighboringMonth={showNeighboringMonth}
            activeStartDate={activeStartDate}
            onActiveStartDateChange={activeStartDateChangeHandler}
            formatShortWeekday={formatShortWeekday}
            calendarType={calendarType}
            locale={locale}
            next2Label={higherNextLabel}
            prev2Label={higherPrevLabel}
            nextLabel={nextLabel}
            prevLabel={prevLabel}
            maxDetail={maxDetail}
            minDetail={minDetail}
            tileContent={tileContentHandler}
            tileClassName={tileClassNameHandler}
            minDate={minDate}
            maxDate={maxDate}
            tileDisabled={tileDisabledHandler}
            value={value}
        />
    )
}

Calendar.displayName = 'lib/Calendar'
