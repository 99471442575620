import React from 'react'
import { IBaseIcons } from './Icon.types'
import { Size, Shapes, Position, Animation } from '../../types'
import { PREFIX } from '../config'

/**
 * Standard Core BaseIcon Icon Component
 * @param { IBaseIcons } props prop value for BaseIcon core component
 * @returns { JSX.Element } returns icon and a link attached to icon as a constant named showcase
 */
const BaseIcon: React.FC<IBaseIcons> = ({ ...props }): JSX.Element => {
    const {
        shape = Shapes.CIRCLE,
        size = Size.SMALL,
        animation: { type = Animation.NONE, infinite = true },
        outline = false,
        position = Position.CENTER,
    } = props
    const iconClassBase = `${PREFIX}-icon`
    const animateClassBase = `${PREFIX}-animate`

    const iconShape = `${iconClassBase}--${shape}`
    const iconSize = `${iconClassBase}--${size}`
    const iconPosition = `${iconClassBase}--position-${position}`
    const iconStyle = `${iconClassBase}--${outline ? 'outline' : 'solid'}`
    const animationType = type !== Animation.NONE ? ` ${animateClassBase}--${type}` : ''
    const isAnimationInfinite = infinite ? ` ${animateClassBase}--infinite` : ''

    return (
        <span
            className={`${iconPosition} ${iconShape} ${iconSize} ${iconStyle}${animationType}${isAnimationInfinite}`}
        />
    )
}

export default BaseIcon
