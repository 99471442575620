import React from 'react'
import PropTypes from 'prop-types'
import { SideNavIconProps } from './SideNavIcon.types'
import Icon from '../Icon/Icon'
import SanitizeStringContentWrapper from '../../utils/sanitizeStringContent'

/**
 * SideNavIcon component
 * @param {SideNavIconProps} props
 * @returns {JSX.Element} returns side nav icon
 */
const SideNavIcon: React.FC<SideNavIconProps> = ({ name, url, iconSize, iconType }): JSX.Element => {
    return (
        <SanitizeStringContentWrapper stringContent={url}>
            {memoizedStringContent => (
                <a href={memoizedStringContent}>
                    <Icon size={iconSize} type={iconType} />
                    {name}
                </a>
            )}
        </SanitizeStringContentWrapper>
    )
}

SideNavIcon.propTypes = {
    name: PropTypes.string,
    url: PropTypes.string,
    iconSize: PropTypes.string,
    iconType: PropTypes.string,
}

export default SideNavIcon
