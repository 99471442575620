import { ActionType, getType } from 'typesafe-actions'
import { CertonaResponseType, CertonaSchemaType } from '../../certona/certona.type'

import * as actions from '../actionCreators'
import { isArrayNotEmpty } from '@nl/lib'

type Action = ActionType<typeof actions>

const mergeShemesAndItems = (state: CertonaResponseType, payload: CertonaResponseType) => {
    const filteredOld = state.resonance?.schemes?.filter(schemeItem => {
        return !payload.resonance.schemes.some(newSchemeItem => newSchemeItem.scheme === schemeItem.scheme)
    })

    const newSchemas = payload.resonance.schemes.map(payloadScheme => {
        const stateScheme = state.resonance?.schemes?.find(oldScheme => payloadScheme.scheme === oldScheme.scheme)

        if (stateScheme) {
            const oldItems = stateScheme.items.filter(stateItem => {
                return !payloadScheme.items.some(payloadItem => payloadItem.prod_id === stateItem.prod_id)
            })

            if (isArrayNotEmpty(payloadScheme.items)) {
                payloadScheme.items = [...oldItems, ...payloadScheme.items]
            }

            return payloadScheme
        }

        return payloadScheme
    })

    return [...(filteredOld || []), ...newSchemas]
}

/**
 * function to remove specific schema id from certona redux state.
 * This is needed to refresh certona data. (ex:-CartAccessoriesFlyout scenario where we are calling same schema id for different products and getting different pcodes.)
 * @param {CertonaResponseType} state
 * @param {string} payload
 * @returns {CertonaSchemaType[]}
 */
const deleteSpecificSchemesAndItems = (state: CertonaResponseType, payload: string): CertonaSchemaType[] => {
    return state.resonance?.schemes?.filter(schemeItem => {
        return !(payload === schemeItem.scheme)
    })
}

export const getCertonaData = (state = {} as CertonaResponseType, action: Action): CertonaResponseType => {
    if (action.type === getType(actions.storeCertonaProductRecommendationData)) {
        return {
            ...state,
            resonance: {
                schemes: mergeShemesAndItems(state, action.payload),
            },
        }
    } else if (action.type === getType(actions.removeSpecificSchemaFromCertonaData)) {
        return {
            ...state,
            resonance: {
                schemes: deleteSpecificSchemesAndItems(state, action.payload),
            },
        }
    } else {
        return state
    }
}
