import { ActionType, getType } from 'typesafe-actions'
import { searchBarAction, getProductSearchAction, getProductSearchError } from '../actionCreators/search.actionCreators'
import * as actions from '../actionCreators/search.actionCreators'
import { SearchErrorResponse } from '../models/search.interface'
import { ProductDataTypeObj } from '../models/productData.interface'
import { EmptyActionType } from '../models/globalData.interface'

type Action = ActionType<typeof actions> | EmptyActionType

export interface SearchState {
    searchKeyword?: string
    redirectURL?: string
    errorResponse?: SearchErrorResponse
    products?: ProductDataTypeObj[]
}

export const initialState: SearchState = {
    searchKeyword: '',
    redirectURL: '',
    errorResponse: {} as SearchErrorResponse,
    products: [],
}

export const searchReducer = (state: SearchState = initialState, action: Action): SearchState => {
    switch (action.type) {
        case getType(searchBarAction): {
            return { ...state, searchKeyword: action.payload }
        }
        case getType(getProductSearchAction): {
            return typeof action.payload === 'string'
                ? { ...state, redirectURL: action.payload }
                : {
                      ...state,
                      redirectURL: action.payload?.redirectURL ?? '',
                      products: action.payload?.products ?? [],
                  }
        }
        case getType(getProductSearchError): {
            return { ...state, errorResponse: action.payload }
        }
        default:
            return state
    }
}
