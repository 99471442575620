import React from 'react'
import { PREFIX } from '../config'
import { SkipLinkProps } from './SkipLink.type'

/**
 * skip to link component
 * @param {SkipLinkProps} props - props for the component
 * @returns {JSX.Element}
 */

const SkipLink: React.FC<SkipLinkProps> = ({ ...props }): JSX.Element => {
    const { label, customClass, skipTo } = props
    return (
        <div className={`${PREFIX}-accessibility-links ${PREFIX}-accessibility-links-align`}>
            <a
                className={`${PREFIX}-skip-link ${PREFIX}-skip-link--vertical-filters ${customClass}`}
                href={`#${skipTo}`}>
                {label}
            </a>
        </div>
    )
}

export default SkipLink
