import { useRef, useEffect } from 'react'

/**
 * Custom react hook that returns true if value was changed.
 * - ignore first undefined\null values - till value is defined at first time.
 * @param {T} value
 * @return {T} return true if value was changed
 */
export default function useIsChanged<T>(value: T): boolean {
    const ref = useRef<T>(value)
    const flag = useRef<boolean>(false)

    useEffect(() => {
        ref.current = value
        flag.current = flag.current && Boolean(value)
    }, [value])

    return flag && ref.current != value
}
