import { createAction } from 'typesafe-actions'

import { InitOtpFlowData } from '../models/otp.interface'
import {
    INIT_OTP_FLOW,
    SET_OTP_ERR_CODE,
    SUCCESS_OTP_CODE,
    CLEAR_OTP_DATA,
    RESET_OTP_FLOW,
    FAIL_OTP_FLOW,
} from '../types/otp/otp.actionTypes.constant'

export const initOtpFlow = createAction(INIT_OTP_FLOW)<InitOtpFlowData>()
export const setOtpErrCode = createAction(SET_OTP_ERR_CODE)<string>()
export const successOtpCode = createAction(SUCCESS_OTP_CODE)()
export const clearOtpData = createAction(CLEAR_OTP_DATA)()
export const resetOtpFlow = createAction(RESET_OTP_FLOW)()
export const failOtpFlow = createAction(FAIL_OTP_FLOW)()
