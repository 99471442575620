import React, { Validator } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import Icon from '../Icon'
import Button from '../Button'
import { stringKeyCodes } from '../../utils'
import { ProductCardType, ProductCardWishlistProps } from '../ProductGridView/ProductGrid.types'

/**
 * Wishlist component for plp/srp
 * @param {ProductCardWishlistProps} props - Wishlist props
 * @returns {JSX.Element} returns wishlist button component
 */
const ProductCardWishlist: React.FC<ProductCardWishlistProps> = (props: ProductCardWishlistProps): JSX.Element => {
    const {
        isWishlistEligible,
        a11yWishlistIcon,
        showWishlistBtnSpinner,
        product,
        isWishlistItemActive,
        idx,
        addProductItemToWishlist,
        isWishlistToShow,
    } = props

    /**
     * Function to call on Wishlist button keyboard event
     * @param {React.KeyboardEvent<HTMLElement>} event keyboard event
     * @returns {void}
     */
    const handleWishlistKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
        if (event.key === stringKeyCodes.enter || event.key === stringKeyCodes.spacing) {
            addProductItemToWishlist(event, product, isWishlistItemActive, idx)
        }
    }

    return (
        isWishlistToShow && (
            <div className={`${PREFIX}-product-card__wishlist`}>
                {isWishlistEligible && (
                    <Button
                        modifierClass={`${PREFIX}-product-card__wishlist--icon`}
                        ariaLabel={a11yWishlistIcon}
                        showSpinner={showWishlistBtnSpinner}
                        onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => handleWishlistKeyDown(event)}
                        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                            addProductItemToWishlist(event, product, isWishlistItemActive, idx)
                        }>
                        {!showWishlistBtnSpinner && (
                            <Icon
                                type={isWishlistItemActive ? 'ct-favorite-active' : 'ct-favorite-inactive'}
                                size="lg"
                            />
                        )}
                    </Button>
                )}
            </div>
        )
    )
}

ProductCardWishlist.propTypes = {
    isWishlistEligible: PropTypes.bool,
    a11yWishlistIcon: PropTypes.string,
    showWishlistBtnSpinner: PropTypes.bool,
    product: PropTypes.object.isRequired as Validator<ProductCardType>,
    isWishlistItemActive: PropTypes.bool,
    idx: PropTypes.number,
    addProductItemToWishlist: PropTypes.func,
}

ProductCardWishlist.displayName = 'ProductCardWishlist'

export default ProductCardWishlist
