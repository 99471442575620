import { createAction } from 'typesafe-actions'

import {
    FETCH_COUNTRIES,
    FETCH_COUNTRIES_FAILURE,
    FETCH_REGIONS,
    FETCH_REGIONS_FAILURE,
    SET_REGISTER_FORM,
} from '../types/loyaltyCard/loyaltyRegisterForm.actionTypes.constant'
import { RegisterFormType, ErrorResponse, CountriesResponse } from '../models/loyaltyRegisterForm.interface'
import { LinkLoyaltyCardRequest } from '../models/linkLoyaltyCard.interface'
import { AxiosResponse } from 'axios'

export const fetchRegions = createAction(FETCH_REGIONS, (payload: RegisterFormType) => payload)<RegisterFormType>()
export const fetchRegionsErrorAction = createAction(
    FETCH_REGIONS_FAILURE,
    (payload: AxiosResponse<ErrorResponse>) => payload,
)<AxiosResponse<ErrorResponse>>()

export const fetchCountries = createAction(
    FETCH_COUNTRIES,
    (payload: CountriesResponse) => payload,
)<CountriesResponse>()
export const fetchCountriesErrorAction = createAction(
    FETCH_COUNTRIES_FAILURE,
    (payload: AxiosResponse<ErrorResponse>) => payload,
)<AxiosResponse<ErrorResponse>>()

export const setRegisterFormAction = createAction(
    SET_REGISTER_FORM,
    (payload: LinkLoyaltyCardRequest) => payload,
)<LinkLoyaltyCardRequest>()
