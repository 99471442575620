import { AnyAction, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { isArrayNotEmpty } from '@nl/lib'
import {
    fetchStoreDetailsSuccessAction,
    fetchStoreDetailsErrorAction,
    fetchNearbyStoreSuccessAction,
    fetchNearbyStoreErrorAction,
    setSelectedStoreNameSuccessAction,
    resetSelectedStoreToastSuccessAction,
    fetchStoreFiltersSuccessAction,
    fetchStoreFiltersErrorAction,
    setPreferredStoreIdSuccessAction,
    setPreferredStoreDetailsAction,
    setStoreChangeAction,
    setPreferredStoreDetailsErrorAction,
    setNearByStoreListForSelectedStore,
    setShowSpinner,
    setNearByStoreListForSelectedSku,
    setNearByStoreListForPostalCode,
    setNearByStoreListForStoreLocator,
    setOneTimeCartStore,
    setOneTimeCartStoreErrorAction,
    setIsNearByStoreListApiDoneAction,
    setStoreAPITriangulationFailAction,
    setIsStoreDetailsFetched,
    fetchNearbyStoresErrorCode,
    setIsNearByStoreListApiSuccessAction,
} from '../actionCreators'
import { storesService } from '../../services/storesService'
import {
    NearbyStoreList,
    StoreWithAvailability,
    storeListRequestType,
    StoresFilters,
    NearByOptions,
    StoreResponseErrorData,
} from '../models/storeDetails.interface'
import appCacheService from '../../utils/appCacheService'
import { setContextHubStoreData } from '../../utils/StoreLocatorUtil/setContextHubStoreData'
import {
    updateHashInURL,
    checkStoreHashInURL,
    checkStoreParamInURL,
    updateParameterInURL,
    queryParameters,
} from '@nl/lib'
import { maxMindErrorCode, maxMindErrorCodeCityNotFound } from '../../globalConstants/cdsErrorCodes'
import { returnInAppQueryParam } from '../../components/AOABookingForm/AOABookingForm.helper'

/**
 * function to fetch store filters
 * @returns {Promise}
 */
export const fetchStoreFilters =
    () =>
    (dispatch: Dispatch): Promise<void> => {
        return storesService
            .getStoreFilters()
            .then((data: { data: StoresFilters }) => {
                const filteredList = data.data.filters?.filter(option => !!option.id) || []
                dispatch(fetchStoreFiltersSuccessAction(filteredList))
            })
            .catch(() => {
                dispatch(fetchStoreFiltersErrorAction())
            })
    }

/**
 * function to fetch store details based on store Id
 * @param {string} storeId
 * @param {Function} resolve
 * @param {boolean} showSpinner
 * @returns {Promise}
 */
export const fetchStoreDetails =
    (storeId: string, resolve?: () => void, showSpinner = false) =>
    (dispatch: Dispatch): Promise<void> => {
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        return storesService
            .getStoreDetails(storeId)
            .then((data: { data: StoreWithAvailability }) => {
                dispatch(fetchStoreDetailsSuccessAction(data.data))
                resolve?.()
            })
            .catch((error: AxiosError<StoreResponseErrorData>) => {
                if (error.response) dispatch(fetchStoreDetailsErrorAction(error.response))
                resolve?.()
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * Function used to fetch near by list based on the current store id.
 * @param {storeListRequestType} requestPayload
 * @returns {void}
 */
export const fetchNearByStoreDetailsForSelectedStore =
    (requestPayload: storeListRequestType) =>
    (dispatch: Dispatch): void => {
        storesService
            .getStoreList(requestPayload)
            .then((data: { data: NearbyStoreList }) => {
                dispatch(setNearByStoreListForSelectedStore(data.data))
            })
            .catch(() => {
                dispatch(fetchNearbyStoreErrorAction())
            })
    }

/**
 * function to fetch store details based on store Id
 * @param {string} storeId
 * @param {Function} resolve
 * @param {boolean} hasSpinner
 * @param {boolean} storeChangeSource
 * @returns {Promise}
 */
export const fetchPreferredStoreDetails =
    (storeId: string, resolve?: (value?: unknown) => void, hasSpinner = false, storeChangeSource?: string) =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch(setIsStoreDetailsFetched(false))
        return storesService
            .getStoreDetails(storeId, true)
            .then((data: { data: StoreWithAvailability }) => {
                setContextHubStoreData(data.data)
                dispatch(setPreferredStoreDetailsAction(data.data))
                dispatch(setStoreChangeAction(storeChangeSource))
                resolve?.(data.data)
                appCacheService.storeTimeZone.set(String(data.data?.storeTimezone))
            })
            .catch(() => {
                dispatch(setPreferredStoreDetailsErrorAction())
                resolve?.()
                hasSpinner && dispatch(setShowSpinner(false))
            })
            .finally(() => {
                dispatch(setIsStoreDetailsFetched(true))
            })
    }

/**
 * function to fetch store list
 * @param {storeListRequestType} requestPayload
 * @param {NearByOptions} option
 * @param {boolean} setSelectedStoreData
 * @returns {Promise}
 */
export const fetchNearbyStoreList =
    (requestPayload: storeListRequestType, option?: NearByOptions, setSelectedStoreData?: boolean) =>
    (dispatch: Dispatch): Promise<void> => {
        return storesService
            .getStoreList(requestPayload)
            .then((data: { data: NearbyStoreList }) => {
                switch (option) {
                    case NearByOptions.SKU:
                        dispatch(setNearByStoreListForSelectedSku(data.data))
                        dispatch(setIsNearByStoreListApiSuccessAction(true))
                        break
                    case NearByOptions.POSTALCODE:
                        dispatch(setNearByStoreListForPostalCode(data.data))
                        break
                    case NearByOptions.STORELOCATOR:
                        dispatch(setNearByStoreListForStoreLocator(data.data))
                        break
                    default:
                        dispatch(fetchNearbyStoreSuccessAction(data.data))
                        break
                }

                dispatch(setIsNearByStoreListApiDoneAction(true))

                if (setSelectedStoreData && isArrayNotEmpty(data.data.stores)) {
                    dispatch(setSelectedPreferredStoreId(String(data.data.stores[0]?.id)) as unknown as AnyAction)
                    dispatch(setSelectedStoreNameSuccessAction(data.data.stores[0]?.displayName))
                }
            })
            .catch((error: AxiosError<StoreResponseErrorData>) => {
                const errorResponse = error.response
                const { errCode } = errorResponse?.data || {}
                // reset nearByStoreListForStoreLocator redux state if api fails
                switch (option) {
                    case NearByOptions.SKU:
                        dispatch(setNearByStoreListForSelectedSku({ stores: [] }))
                        dispatch(setIsNearByStoreListApiSuccessAction(false))
                        break
                    case NearByOptions.POSTALCODE:
                        dispatch(setNearByStoreListForPostalCode({ stores: [] }))
                        break
                    case NearByOptions.STORELOCATOR:
                        dispatch(setNearByStoreListForStoreLocator({ stores: [] }))
                        break
                    default:
                        dispatch(fetchNearbyStoreErrorAction())
                        break
                }
                dispatch(setIsNearByStoreListApiDoneAction(true))

                if (errorResponse) dispatch(fetchNearbyStoresErrorCode(errorResponse))

                // Update redux state when there is no preferred store id in local storage & stores apis fails with error code '00114' or '00115'
                if (
                    (errCode === maxMindErrorCode || errCode === maxMindErrorCodeCityNotFound) &&
                    !appCacheService.preferredStoreId.get()
                ) {
                    dispatch(setStoreAPITriangulationFailAction(true))
                }
            })
    }

/**
 * function to set selected store name
 * @param {string} selectedStore - selected store name
 * @returns {Dispatch}
 */
export const setSelectedStoreName =
    (selectedStore: string) =>
    (dispatch: Dispatch): void => {
        dispatch(setSelectedStoreNameSuccessAction(selectedStore))
    }

/**
 * function to reset toast message toast
 * @returns {Dispatch}
 */
export const resetSelectedStoreMessageToast =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetSelectedStoreToastSuccessAction())
    }

/**
 * function to update preferred store id
 * @param {string} storeId - preferred store id
 * @returns {Dispatch}
 */
export const setSelectedPreferredStoreId =
    (storeId: string): ((dispatch: Dispatch) => void) =>
    (dispatch: Dispatch): void => {
        const storeStr = String(parseInt(storeId))
        if (parseInt(storeStr) > 0) {
            if (appCacheService.preferredStoreId.get() !== storeStr) {
                if (checkStoreHashInURL()) {
                    updateHashInURL(queryParameters.store, storeStr)
                }
                // checkStoreParamInURL is to check store parameter in URL and returnInAppQueryParam is to check for inAPP flow in URL.
                if (checkStoreParamInURL() && !returnInAppQueryParam()) {
                    updateParameterInURL(queryParameters.store, storeStr)
                }
            }
            appCacheService.preferredStoreId.set(storeStr)
            dispatch(setPreferredStoreIdSuccessAction(storeStr))
        }
    }

export const fetchOneTimeCartStoreDetails =
    (storeId: string, resolve?: (value: unknown) => void) =>
    (dispatch: Dispatch): Promise<void> => {
        return storesService
            .getStoreDetails(storeId)
            .then((data: { data: StoreWithAvailability }) => {
                dispatch(setOneTimeCartStore(data.data))
                resolve?.(data.data)
            })
            .catch(() => {
                dispatch(setOneTimeCartStoreErrorAction())
            })
    }

/**
 * function to reset IsNearByStoreListApiDone
 * @param {boolean} isApiCalled
 * @returns {Dispatch}
 */
export const setIsNearByStoreListApiDone =
    (isApiCalled: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsNearByStoreListApiDoneAction(isApiCalled))
    }

/**
 * function to set IsNearByStoreListSuccess
 * @param {boolean} status
 * @returns {Dispatch}
 */
export const setIsNearByStoreListSuccess =
    (status: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsNearByStoreListApiSuccessAction(status))
    }
