/**
 * @typedef { Object } IValueType
 * @property { string } type
 * @property { string } value
 */
export interface IValueType {
    type: string
    value: string
}

/**
 * Enum for sizes
 */
export enum Size {
    MINI = 'mini',
    XS = 'xs',
    SMALL = 'sm',
    MEDIUM = 'md',
    LARGE = 'lg',
    XL = 'xl',
    XXL = 'xxl',
}

/**
 * Enum for reverse Sizes used in charts
 */
export enum SizeReverse {
    MINI = 'minir',
    XS = 'xsr',
    SMALL = 'smr',
    MEDIUM = 'mdr',
    LARGE = 'lgr',
    XL = 'xlr',
    XXL = 'xxlr',
}

/**
 * Enum for variations
 */
export enum Variation {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
}

/**
 * Enum for directions
 */
export enum Direction {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical',
}

/**
 * Enum for position
 */
export enum Position {
    LEFT = 'left',
    RIGHT = 'right',
    TOP = 'top',
    BOTTOM = 'bottom',
    ALL = 'all',
    CENTER = 'center',
}

/**
 * Enum for directions
 */
export enum Status {
    PROGRESS = 'In Progress',
    DONE = 'Done',
    FAILED = 'failed',
    SUCCESS = 'success',
    ERROR = 'error',
    START = 'start',
    RESET = 'reset',
}

/**
 * Enum for state
 */
export enum State {
    DEFAULT = 'default',
    ACTIVE = 'active',
}

/**
 * Enum for animation type
 */
export enum Animation {
    PULSE = 'pulse',
    BOUNCE = 'bounce',
    PING = 'ping',
    BLINK = 'blink',
    NONE = 'none',
}

/**
 * Enum for shapes
 */
export enum Shapes {
    CIRCLE = 'circle',
}

/**
 * Swiper carousel breakpoint keys and settings
 */
export type BreakpointKeys = Size.XS | Size.SMALL | Size.MEDIUM | Size.LARGE
export interface BreakpointSettings {
    slidesToShow: number
    slidesPerGroup: number
    spacing?: number
}

/**
 * @typedef { Object } IAnimation
 * @property { boolean } infinite
 * @property { number } timer - in ms if infinite is false
 * @property { Animation } animation - type of animation
 */
export interface IAnimation {
    type: Animation
    infinite?: boolean
}

export interface ITitleDesc {
    title?: string
    description: string | string[] | Record<string, string> | React.ReactNode
}

export interface MultiField {
    component: string
    value: string
}

export interface EnvironmentConfig {
    serviceClient: string
    serviceVersion: string
    ocpApimSubscriptionKey: string
    baseSiteId: string
}
