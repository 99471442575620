import { isAutomotivePDP } from '../../components/Vehicles/Vehicle.helper'
import { ProductResponseData } from '../../redux/models/product.interface'
import { ProductSchema } from './Seo.helper.types'

/**
 * This function is need to collect and inject manufacturer part number (mpn) into schema
 * due to on toggles and curent product type.
 * @param {ProductSchema} schemaObj
 * @param {ProductResponseData} productDetails
 * @param {boolean} enableMPNForAll
 * @param {boolean}enableMPNForAutomotive
 */
export const checkAndPopulateMpn = (
    schemaObj: ProductSchema,
    productDetails: ProductResponseData,
    enableMPNForAll: boolean,
    enableMPNForAutomotive: boolean,
): void => {
    if (
        enableMPNForAll ||
        (enableMPNForAutomotive && isAutomotivePDP(productDetails?.fitmentTypeCode, productDetails?.productWheelType))
    ) {
        const mpn = productDetails.skus.map(item => item.partNumber).filter(Boolean)
        if (mpn.length) {
            schemaObj.mpn = mpn
        }
    }
}
