import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import getPageType from '../../utils/getPageType'
import { getProvider } from '../providers/index'
import analyticsLayer from '../../utils/analyticsService'

/**
 * This Function used to fire toast/modal/inline error data layer analytics event.
 * @param {string} errorMessage error message
 */

export const errorAnalytics = (errorMessage?: string, systemFailureMessage?: string): void => {
    const { event, eventParameters } = analyticsAttributes

    getProvider().push({
        event: event.error,
        eventParameters: {
            action: eventParameters.action.viewErrorMessage,
            category: getPageType() || undefined,
            label: errorMessage || '',
            value: undefined,
            systemFailure: systemFailureMessage || undefined,
        },
    })
}

export const giftCardErrorAnalytics = (id: string, giftCardError: string) => {
    analyticsLayer.pushData({
        event: 'gift_card_error',
        eventParameters: {
            cartId: id,
            errorDescription: giftCardError,
        },
    })
}
