import React, { FC, FormEventHandler, useState } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import TextInput from '../TextInput'
import { NewCardFormProps } from './GiftCard.types'
import { PREFIX } from '../config'
import { CARD_NUMBER_MAX_DIGITS, CARD_NUMBER_GROUP_SIZE, PIN_MAX_DIGITS, PIN_GROUP_SIZE } from './GiftCard.constants'
import { addDividers, getLengthWithDividers } from './GiftCard.helpers'
import { TextInputModes } from '../../globalConstants/global.constant'
import { checkoutRegexPatterns } from '../../globalConstants/regexPatterns.constant'
import { useMobileLayoutState } from '../../hooks/layout.hook'

/**
 * New Gift Card Form component
 * @param {NewCardFormProps} props - Props for the NewCardForm component.
 * @returns {JSX.Element} The rendered NewCardForm component.
 */
const NewCardForm: FC<NewCardFormProps> = ({
    gcApplyCTALabel,
    gcCardNumberInputLabel,
    gcPinInputLabel,
    gcNumberError,
    gcPinError,
    validateGCFieldsEmpty,
}) => {
    const [number, setNumber] = useState('')
    const [pin, setPin] = useState('')

    const componentClassName = `${PREFIX}-giftcard`
    const [isMobileLayout] = useMobileLayoutState() as [boolean]

    const handleNumberChange = (value: string) => {
        setNumber(addDividers(value, CARD_NUMBER_GROUP_SIZE))
    }

    const handlePinChange = (value: string) => {
        setPin(addDividers(value, PIN_GROUP_SIZE))
    }

    /**
     * Handles form submission for gift card validation.
     * @param {React.FormEvent<HTMLFormElement>} e - The form event.
     */
    const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault()
        validateGCFieldsEmpty(number, pin)
    }

    return (
        <form className={`${componentClassName}__form`} onSubmit={handleSubmit}>
            <div className={`${componentClassName}__form-fields`}>
                <TextInput
                    quantumMetricAttribute={{ type: 'encrypt', value: 'true' }}
                    label={gcCardNumberInputLabel}
                    id="card-number"
                    value={number}
                    error={gcNumberError}
                    maxLength={getLengthWithDividers(CARD_NUMBER_MAX_DIGITS, CARD_NUMBER_GROUP_SIZE)}
                    onChange={handleNumberChange}
                    size={isMobileLayout ? 'default' : 'mini'}
                    inputMode={TextInputModes.NUMERIC}
                    pattern={checkoutRegexPatterns.giftCardNumberAndPin}
                />
                <TextInput
                    quantumMetricAttribute={{ type: 'encrypt', value: 'true' }}
                    label={gcPinInputLabel}
                    id="pin"
                    value={pin}
                    error={gcPinError}
                    maxLength={getLengthWithDividers(PIN_MAX_DIGITS, PIN_GROUP_SIZE)}
                    onChange={handlePinChange}
                    size={isMobileLayout ? 'default' : 'mini'}
                    inputMode={TextInputModes.NUMERIC}
                    pattern={checkoutRegexPatterns.giftCardNumberAndPin}
                />
                <Button
                    type="secondary"
                    buttonType="submit"
                    quantumMetricAttribute={{ type: 'allow', value: 'true' }}
                    size="small"
                    label={gcApplyCTALabel}
                    id="gift-card-apply"
                />
            </div>
        </form>
    )
}
NewCardForm.propTypes = {
    gcApplyCTALabel: PropTypes.string.isRequired,
    gcCardNumberInputLabel: PropTypes.string.isRequired,
    gcPinInputLabel: PropTypes.string.isRequired,
    gcNumberError: PropTypes.string.isRequired,
    gcPinError: PropTypes.string.isRequired,
    validateGCFieldsEmpty: PropTypes.func.isRequired,
}

export default NewCardForm
