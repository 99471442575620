import classnames from 'classnames'
import { PREFIX } from '../config'
import React from 'react'
import { NavExpansionPanelProps } from './NavExpansionPanel.types'
import { SubCategories } from '../MegaNavigationDesktop/MegaNavigationDesktop.type'

/**
 * NavExpansionPanel is a React functional component that displays a navigation panel with sub-categories.
 * @param {NavExpansionPanelProps} props - The props for the NavExpansionPanel component.
 * @returns {JSX.Element} The JSX representation of the NavExpansionPanel component.
 */

const NavExpansionPanel: React.FC<NavExpansionPanelProps> = ({ catalog }) => {
    const formatList = (list: SubCategories[]): SubCategories[] => {
        const count = Number(catalog.subNavItemLimit)
        if (list && list.length > count) {
            const resultList = list.slice(0, count)
            resultList.push({
                subNavLabel: catalog.viewMoreLabel,
                subNavLink: encodeURI(typeof catalog?.url === 'string' ? catalog?.url : catalog?.url?.url),
                subNavLinkTarget: '',
            })
            return resultList
        } else {
            return list
        }
    }

    return (
        <div className={classnames(`${PREFIX}-row`, `${PREFIX}-sub-categories`)}>
            <div className={classnames(`${PREFIX}-sub-categories__right-spacing`)}>
                <ul>
                    {formatList(catalog?.subSectionTab)?.map((subNavItem, columnIndex) => (
                        <li
                            key={columnIndex}
                            className={classnames(
                                `${PREFIX}-sub-categories__category${
                                    columnIndex > catalog.subNavItemLimit - 1 ? '--underline' : ''
                                }`,
                            )}>
                            <a data-link-value={subNavItem?.subNavLabel} href={subNavItem?.subNavLink}>
                                {subNavItem?.subNavLabel}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default NavExpansionPanel
