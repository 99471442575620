import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/triangleRewardsUpsell.actionCreators'

import {
    earnCtMoneyAction,
    resetEarnCreditCardAction,
    earnCtMoneyErrorAction,
} from '../actionCreators/triangleRewardsUpsell.actionCreators'
import { ErrorResponse } from '../models/upsellingRewards.interface'
import { EmptyActionType } from '../models/globalData.interface'

type Action = ActionType<typeof actions> | EmptyActionType

export interface TriangleRewardsUpsellState {
    earnedCTMoneySuccess: number | null
    earnedCTMoneyError: ErrorResponse | null
}

export const initialState: TriangleRewardsUpsellState = {
    earnedCTMoneySuccess: null,
    earnedCTMoneyError: {} as ErrorResponse,
}

export const triangleRewardsUpsellReducer = (
    state: TriangleRewardsUpsellState = initialState,
    action: Action,
): TriangleRewardsUpsellState => {
    switch (action.type) {
        case getType(resetEarnCreditCardAction): {
            return { ...state, earnedCTMoneySuccess: null, earnedCTMoneyError: null }
        }
        case getType(earnCtMoneyAction): {
            return { ...state, earnedCTMoneySuccess: action.payload }
        }
        case getType(earnCtMoneyErrorAction): {
            return { ...state, earnedCTMoneyError: action.payload }
        }
        default:
            return state
    }
}
