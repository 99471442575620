import { createAction } from 'typesafe-actions'

import {
    SET_CRITICAL_FITMENT_DATA_FOR_AUTOMOTIVE_ACCORDION,
    NEED_TO_OPEN_ACCORDION,
    SET_SKU_ON_LOAD,
    RESET_AUTOMOTIVE_ACCORDION,
} from '../types/automotiveAccordion/automotiveAccordion.actionTypes.constant'
import { CriticalFitment } from '@nl/lib'

export const setCriticalFitmentListForAutomotiveAccordion = createAction(
    SET_CRITICAL_FITMENT_DATA_FOR_AUTOMOTIVE_ACCORDION,
    (payload: CriticalFitment[]) => payload,
)<CriticalFitment[]>()

export const setAccordionOpenState = createAction(NEED_TO_OPEN_ACCORDION, (payload: boolean) => payload)<boolean>()

export const setSkuOnLoad = createAction(SET_SKU_ON_LOAD, (payload: string) => payload)<string>()

export const resetAutomotiveAccordion = createAction(RESET_AUTOMOTIVE_ACCORDION)()
