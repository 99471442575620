import { getComponentProps } from '../../utils/getComponentProps'
import { featuredListGridColumnGenerator } from '../FeaturedListBrand/featuredListBrand'
import { embedFeaturedListNavigationSection } from '../FeaturedListNavigationSection/featuredListNavigationSection'

/**
 * Feature List Component
 * @param {string | Element} element
 */
const featuredList = (element: string | Element): void => {
    // Reference to the element where the data prop is added.
    const elementRef = typeof element === 'string' ? document.querySelectorAll(element) : [element]

    elementRef.forEach((el: Element) => {
        const props = getComponentProps(el)

        featuredListGridColumnGenerator(el, props)
        Boolean(props.enableNavigationSection) && embedFeaturedListNavigationSection(el, props)
    })
}

export { featuredList }
