import React from 'react'

import { BreadcrumbProps } from './Breadcrumb.type'
import { breadcrumbClassName, breadcrumbGradient, listClassName, listScroll } from './Breadcrumb.constant'
import { BreadcrumbLink } from './BreadcrumbLink'
import { getBreadcrumbName, isLastLinkAndNotInteractive } from './Breadcrumb.helpers'

/**
 * Breadcrumb component
 * @param {BreadcrumbProps} props - links
 * @returns {JSX.Element} returns Breadcrumb Links
 */
const Breadcrumb: React.FC<BreadcrumbProps> = ({
    links,
    showPipeIcon,
    isLastElementInteractive,
    breadCrumbLabel,
}: BreadcrumbProps): JSX.Element => (
    <nav className={`${breadcrumbClassName} ${breadcrumbGradient}`} aria-label={breadCrumbLabel}>
        <ol className={`${listClassName} ${listScroll}`}>
            {links.map(({ link, name, pName }, index) => {
                const isLastAndNotInteractive = isLastLinkAndNotInteractive(
                    links.length,
                    index,
                    isLastElementInteractive,
                )

                return (
                    <BreadcrumbLink
                        key={link}
                        link={link}
                        ariaLabel={name}
                        name={name ? getBreadcrumbName(name) : name}
                        isLastLinkAndNotInteractive={isLastAndNotInteractive}
                        pName={pName}
                        showPipeIcon={showPipeIcon}
                        index={index}
                        breadCrumbLabel={breadCrumbLabel}
                    />
                )
            })}
        </ol>
    </nav>
)

export default Breadcrumb
