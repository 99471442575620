import { createAction } from 'typesafe-actions'

import { SuccessResponse, ErrorResponse } from '../models/deliveryOptions.interface'
import {
    GET_DELIVERY_OPTIONS_DATA_ERROR,
    GET_DELIVERY_OPTIONS_DATA_SUCCESS,
    RESET_DELIVERY_OPTIONS,
    SET_IS_DELIVERY_OPTIONS_API_DONE,
} from '../types/deliveryOptions/deliveryOptions.actionTypes.constant'

export const fetchDeliveryOptionsAction = createAction(GET_DELIVERY_OPTIONS_DATA_SUCCESS)<SuccessResponse>()

export const deliveryOptionsFailureAction = createAction(GET_DELIVERY_OPTIONS_DATA_ERROR)<ErrorResponse>()

export const resetDeliveryOptionsAction = createAction(RESET_DELIVERY_OPTIONS)()

export const setIsDeliveryOptionsApiDone = createAction(SET_IS_DELIVERY_OPTIONS_API_DONE)<boolean>()
