import { brazeCustomEvents, getPageType, getSourceParam, getStoreParams, pageTypes } from '@nl/lib'
import { PREFIX, BREAKPOINTS } from '../../../config'
import { queryParameters } from '../../../globalConstants/global.constant'
import {
    getStickyStoreLocatorHeight,
    isInformationBannerVisible,
} from '../../SideNavigation/SideNavigation/SideNavigation.helper'
import brazeService from '../../../services/brazeService/braze.service'
import { getOfferBannerId } from '../../../utils/getCurrentBannerId'
import { StoreWithAvailability } from '../../../redux/models/storeDetails.interface'
/**
 * Below functional will return 'q' param value.
 * @returns { string } return value of query param form search url.
 **/
export const getSearchingString = (): string => {
    const decodedSearchParams = decodeURIComponent(window.location.search)
    const urlParams = new URLSearchParams(decodedSearchParams.replace(/;/g, queryParameters.plpCDSDivider))
    const queryString = urlParams.get('q')
    return !queryString || queryString === 'undefined' ? '' : queryString
}

let lastScrollTop = 0
const header = document.querySelector(`.${PREFIX}-primary-navigation.${PREFIX}-full-width-container`)
const storeLocatorHeight = document.querySelector('.store-locator-pencil-banner')?.clientHeight || 0
const infoBannerHeight =
    isInformationBannerVisible() || !getStickyStoreLocatorHeight()
        ? Number(document.getElementsByClassName(`${PREFIX}-information-banner`)[0]?.clientHeight || 0)
        : 0

const isPDPPage = getPageType() === pageTypes?.pdpPage
const isCarOrCheckoutPage = getPageType() === pageTypes?.shoppingCart || getPageType() === pageTypes?.checkoutPage
const { mobileMaxWidth } = BREAKPOINTS

/**
 * Adding position fixed class when user is scrolling up
 * @param { boolean } stickyTOCId
 * @param { number } scrollTop
 * @returns { void }
 */
const addFixedClass = (stickyTOCId: boolean, scrollTop: number): void => {
    const buyBoxCompEL = document.getElementById(`${PREFIX}-buy-box`) || null
    const buyBoxCompELHeight = buyBoxCompEL?.clientHeight || 0
    const buyBoxCompELTop = buyBoxCompEL?.offsetTop || 0
    const buyBoxContentHeight = buyBoxCompELHeight + buyBoxCompELTop
    if (isPDPPage) {
        if (!stickyTOCId && scrollTop < buyBoxContentHeight) {
            header?.classList.add(`${PREFIX}-primary-navigation__fixed`)
        }
    } else {
        header?.classList.add(`${PREFIX}-primary-navigation__fixed`)
    }
}

/**
 * Adding position not fixed class when user is scrolling up
 * @param { number } scrollTop
 * @returns { void }
 */
const addFixedNoneClass = (scrollTop: number) => {
    if (scrollTop > Number(header?.clientHeight) + Number(storeLocatorHeight) + infoBannerHeight) {
        header?.classList.add(`${PREFIX}-primary-navigation__fixed-none`)
    }
}

/**
 * This function will create a sticky search bar in mobile view on up scrolling
 * @returns { void }
 */
export const onScrollEvent = (): void => {
    const stickyTOCId = document.getElementById(`${PREFIX}-nav`)?.classList?.contains(`.${PREFIX}-Scrollspy-sticky`)
    const scrollTop = window.scrollY || document.documentElement.scrollTop
    const isMobile = window.innerWidth <= mobileMaxWidth

    if (!isCarOrCheckoutPage && isMobile) {
        if (scrollTop <= storeLocatorHeight + infoBannerHeight || scrollTop > lastScrollTop || !scrollTop) {
            addFixedNoneClass(scrollTop)
            if (header?.classList.contains(`${PREFIX}-primary-navigation__fixed`)) {
                header?.classList.remove(`${PREFIX}-primary-navigation__fixed`)
            }
        } else if (!header?.classList.contains(`${PREFIX}-primary-navigation__fixed`)) {
            header?.classList.remove(`${PREFIX}-primary-navigation__fixed-none`)
            addFixedClass(stickyTOCId, scrollTop)
        }
    }

    lastScrollTop = scrollTop
}

/**
 * Trigger braze custom event on change of preferred store
 * @param {StoreWithAvailability} store selected store
 * @returns {void} braze custom event on change of preferred store
 */
export const triggerBrazeCustomEvent = (store: StoreWithAvailability): void => {
    brazeService.logCustomEvent(brazeCustomEvents.event.webSetStore, {
        ...getSourceParam(getOfferBannerId()),
        ...getStoreParams(store),
    })
}
