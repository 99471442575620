import { MutableRefObject, useEffect, useRef } from 'react'
import { learnMoreModalData } from '../components/OfferCard/OfferCard.constant'
import { stringKeyCodes } from '../utils'

/**
 * It makes a focus on element from reference when it is rendered
 * @param {MutableRefObject} ref - It is a reference to the element
 * @param {boolean} enabled - It is a flag to enable focus
 * @returns {void} - It returns nothing
 */
export function useFocusOnce(ref: MutableRefObject<HTMLElement | null>, enabled: boolean): void {
    useEffect(() => {
        if (enabled && ref.current) {
            ref.current.focus()
        }
    }, [enabled, ref])
}

/**
 * It returns a ref what will case to one time focus on element where it will be attached.
 * @param {boolean} enabled - It is a flag to enable focus
 * @returns {MutableRefObject} - It returns a reference to the element
 */
export const useFocusOnceRef = (enabled = false): MutableRefObject<HTMLElement | null> => {
    const ref = useRef<HTMLElement>(null)
    useFocusOnce(ref, enabled)
    return ref
}

/**
 * useHandleFocusTrap: apply focus trap for example master card and view rewards modals
 * @param {KeyboardEvent} e - It is a keyboard event
 * @returns {void} - It returns nothing
 */
export const useHandleFocusTrap = (e: KeyboardEvent): void => {
    /**
     * Set focus trap for mastercard and reward example modals
     */
    useEffect(() => {
        const mastercardExampleModal = document.getElementById('modal-triangle-mastercard')
        const rewardsExampleModal = document.getElementById('modal-triangle-reward')
        const learnMoreModal = document.getElementById('learnMoreModal')

        if (mastercardExampleModal || rewardsExampleModal || learnMoreModal) {
            document.addEventListener('keydown', useHandleFocusTrap)
        }

        return () => {
            document.removeEventListener('keydown', useHandleFocusTrap)
        }
    }, [])

    const modal = document.querySelector(`.${learnMoreModalData?.trianglePopupOpenedClass}`)
    const isTabPressed = e.key === stringKeyCodes.tab
    const focusableContent = modal && modal.querySelectorAll('button')[0]

    if (!isTabPressed || !modal) {
        return
    }

    focusableContent.focus()
    e.preventDefault()
}
