import { cardTypes } from '../components/CardInput/CardInput.constant'
import { getCapitalizeString } from './getCapitalizeString'
import { magicNumber } from './magicNumber'
import { replaceMultipleStrWithDynamicVal } from './replaceStrWithDynamicVal'
import { CreditCardType } from '../components/CardInput/CardInput.type'
import { CardType } from '../components/config'

/**
 * Replaces dynamic string with card name and number
 *
 * @param {PaymentUnitType} propText - AEM prop value to replace with dynamic string(s)
 * @param {PaymentUnitType} card - credit card info
 * @return {string} - returns the string with card name and number
 */

export const getCardNameAndNumber = (propText: string, card: Record<string, string>): string => {
    return `${replaceMultipleStrWithDynamicVal(propText, [
        getCapitalizeString(card.type),
        card.maskedCardNumber.slice(magicNumber.MINUS_FOUR),
    ])}`
}

/**
 * function to get date from credit card in format MM/YY
 * @param {number} validToMonth
 * @param {number} validToYear
 * @return {string}
 */
export const getDateFromCreditCard = (validToMonth: number, validToYear: number): string => {
    const month = String(validToMonth).padStart(magicNumber.TWO, `${magicNumber.ZERO}`)
    const year = String(validToYear).substr(magicNumber.MINUS_TWO)
    return `${month}/${year}`
}

/**
 * returns object {logo: '', type: ''} from array [{logo: '', type: ''},] based on cardType 'type'
 * @param {CreditCardType[]} cardsData - array of card data
 * @param {string} cardType - card type
 * @returns {CreditCardType | undefined} - returns card type
 */
export const getCardLogo = (cardsData: CreditCardType[], cardType = ''): CreditCardType | undefined =>
    cardsData?.find(item => ~item.type.trim().toLowerCase().split(',')?.indexOf(cardType.trim().toLowerCase()))

/**
 * Method to return card number with prefix and remove spaces
 * @param {string} cardNumber
 * @returns {string} card number with prefix
 */
export const getCardNumber = (cardNumber: string): string => {
    return cardTypes[0].readOnlyValue + cardNumber.replace(/ /g, '')
}

/**
 * This util is used to return the card type for payment done through
 * @param {string} card - payment mode
 * @returns {string}
 */
export const getCardType = (card: string): string => {
    if (!card) return ''
    const cardLowerCase = card.toLowerCase()
    const cardsType = [CardType.amex, CardType.master, CardType.triangle, CardType.paypal, CardType.visa]
    const cardMatched = cardsType.find(type => cardLowerCase.includes(type))
    return cardMatched || ''
}
