import { ColumnsType } from '../components/OfferCard/OfferCard.type'
import { offerCodesRegEx } from '../globalConstants/regexPatterns.constant'
import { magicNumber } from '../utils'

/**
 * To return offer code param from email activate page url
 * @param {string} offerCodeParameterName url param
 * @returns {string} returns offer code param from email activate page url (p2 parameter)
 */
export const getOfferCodesParam = (offerCodeParameterName: string): string => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(offerCodeParameterName)?.split(offerCodesRegEx, 1)[0] || ''
}

/**
 * To return columns based on different viewport
 * @param {number} rewardsPerRowDesktop desktop rewards count
 * @param {number} rewardsPerRowTablet tablet rewards count
 * @returns {ColumnsType} returns columns
 */
export const getColumns = (rewardsPerRowDesktop: number, rewardsPerRowTablet: number): ColumnsType => {
    return {
        md: rewardsPerRowDesktop ? magicNumber.TWELVE / rewardsPerRowDesktop : magicNumber.TWELVE / magicNumber.THREE,
        sm: rewardsPerRowTablet ? magicNumber.TWELVE / rewardsPerRowTablet : magicNumber.TWELVE / magicNumber.TWO,
        xs: magicNumber.THREE,
    }
}
