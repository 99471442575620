import { IAnimation, Size, Shapes, Position } from '../../types'

/**
 * Interface for iconProp
 * @interface
 */
export interface iconProptypes {
    type?: string
    title?: string
    size?: string
    showcase?: boolean
    path?: string
    decorative?: boolean
    iconSpritePath?: string
}

export enum iconSizes {
    Small = 'sm',
    Medium = 'md',
    Large = 'lg',
    xl = 'xl',
    xxl = 'xxl',
}

/**
 * Interface for IBaseIcons
 * @interface
 */
export interface IBaseIcons {
    position?: Position.LEFT | Position.RIGHT | Position.TOP | Position.BOTTOM | Position.CENTER | 'custom'
    shape?: Shapes
    animation?: IAnimation
    size?: Size
    outline?: boolean
}
