import React from 'react'

import { PREFIX } from '../config'
import { FullPageSpinnerProps } from './FullPageSpinner.types'
import { SpinnerStyle } from '../Spinner/Spinner.types'
import Spinner from '../Spinner'

const FullPageSpinner: React.FC<FullPageSpinnerProps> = ({ ...props }): JSX.Element | null => {
    const { showSpinner, spinnerCounter, globalSpinnerColor, style = SpinnerStyle.DEFAULT, value } = props
    return showSpinner || spinnerCounter > 0 ? (
        <div role="alert" className={`${PREFIX}-spinner__full`}>
            <div className={`${PREFIX}-spinner__backdrop`} />
            <div className={`${PREFIX}-spinner__content`}>
                <span className="sr-only">{props.a11yLoadingSpinnerLabel}</span>
                <Spinner color={globalSpinnerColor} style={style} value={value} />
            </div>
        </div>
    ) : null
}

FullPageSpinner.displayName = 'lib/FullPageSpinner'

export default FullPageSpinner
