import { ActionType, getType } from 'typesafe-actions'

import {
    linkMasterCardSuccessAction,
    linkMasterCardErrorAction,
    saveMasterCardEntryAction,
    clearLinkMasterCardResponseAction,
    clearLinkMasterCardErrorAction,
} from '../actionCreators'
import * as actions from '../actionCreators/linkMasterCard.actionCreators'
import {
    LinkMasterCardSuccessResponse,
    LinkMasterCardErrorResponse,
    LinkMasterCardRequest,
} from '../models/linkMasterCard.interface'
import { EmptyActionType } from '../models/globalData.interface'

type Action = ActionType<typeof actions> | EmptyActionType

export interface LinkMasterCardState {
    linkMasterEntry: LinkMasterCardRequest
    linkMasterCardResponse: LinkMasterCardSuccessResponse | null
    linkMasterCardError: LinkMasterCardErrorResponse | null
    loyaltyCardForMasterCard: string
}

export const initialState: LinkMasterCardState = {
    linkMasterEntry: {} as LinkMasterCardRequest,
    linkMasterCardResponse: null,
    linkMasterCardError: null,
    loyaltyCardForMasterCard: '',
}

export const linkMasterCardReducer = (
    state: LinkMasterCardState = initialState,
    action: Action,
): LinkMasterCardState => {
    switch (action.type) {
        case getType(linkMasterCardSuccessAction): {
            return {
                ...state,
                linkMasterCardResponse: action.payload,
                loyaltyCardForMasterCard: action.payload.loyaltyCardNumber,
            }
        }
        case getType(linkMasterCardErrorAction): {
            return { ...state, linkMasterCardError: action.payload }
        }
        case getType(saveMasterCardEntryAction): {
            return { ...state, linkMasterEntry: action.payload }
        }
        case getType(clearLinkMasterCardResponseAction): {
            return { ...state, linkMasterCardResponse: null }
        }
        case getType(clearLinkMasterCardErrorAction): {
            return { ...state, linkMasterCardError: null }
        }
        default:
            return state
    }
}
