import React from 'react'

/**
 * Replaces "[0]" in the string with dynamic value provided
 * @param {string} message - message to display
 * @param {string | number} dynamicVal - replacing value with [0]
 * @returns {string} - return string with replaced value
 */
export const replaceStrWithDynamicVal = (message: string | undefined, dynamicVal: string | number): string =>
    message ? message.replace('[0]', `${dynamicVal}`) : ''

/**
 * Replaces "[0]" in the string with dynamic value what provided as the second parameter.
 * @param {string} message - message to display
 * @param {JSX.Element} dynamicElem - replacing value with [0]
 * @returns {JSX.Element} - return string with replaced value
 */
export const replaceStrWithDynamicElement = (message: string, dynamicElem: JSX.Element): JSX.Element => (
    <>
        {(message || '')
            .split('[0]')
            .reduce(
                (arr: JSX.Element[], part: string, i: number, { length }: string[]) =>
                    arr.concat(<>{part}</>).concat(i + 1 < length ? dynamicElem : <></>),
                [] as JSX.Element[],
            )}
    </>
)

/**
 * Replaces  multiple "[0]" in the string with dynamic value provided using replaceStrWithDynamicVal
 * @param {string} message - message to display
 * @param {string[]} values - array of replacing value with [0]
 * @returns {string} - return string with replaced value
 */
export const replaceMultipleStrWithDynamicVal = (message: string, values: (string | number)[]): string => {
    let finalString = message
    values.forEach(val => {
        finalString = replaceStrWithDynamicVal(finalString, val)
    })
    return finalString
}

/**
 * Replaces the value provided with "[0]"
 * @param {string} message - message to display
 * @param {string} value - value replacing with [0]
 * @returns {string} - return string with replaced value
 */
export const addDynamicToken = (message: string | undefined, value: string): string =>
    message ? message.replace(value, '[0]') : ''
