export const SET_MINI_PDP_FLYOUT_DETAILS = 'SET_MINI_PDP_FLYOUT_DETAILS'
export const CLOSE_MINI_PDP_FLYOUT = 'CLOSE_MINI_PDP_FLYOUT'
export const OPEN_MINI_PDP_FLYOUT = 'OPEN_MINI_PDP_FLYOUT'
export const INIT_MINI_PDP_FLOW = 'INIT_MINI_PDP_FLOW'
export const SET_SELECTED_VARIANT_VALUE = 'SET_SELECTED_VARIANT_VALUE'
export const SET_SELECTED_VARIANT_ID = 'SET_SELECTED_VARIANTID'
export const UPDATE_SELECTED_PRODUCT_CODE = 'UPDATE_SELECTED_PRODUCT_CODE'
export const SET_MINI_PDP_AEM_PROPS = 'SET_MINI_PDP_AEM_PROPS'
export const UPDATE_SCU_DATA_BY_STORE = 'UPDATE_SCU_DATA_BY_STORE'
export const FETCH_MINI_PDP_FLYOUT_DATA_ERROR = 'FETCH_MINI_PDP_FLYOUT_DATA_ERROR'
export const IS_MINI_PDP_ATC_BUTTON_CLICKED = 'IS_MINI_PDP_ATC_BUTTON_CLICKED'
