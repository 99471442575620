import React, { useMemo } from 'react'
import { LineSummaryProps } from './LineSummary.type'
import SummaryTooltip from '../SummaryTooltip'
import { checkDataLength } from '../../utils/checkDataLength'
import { magicNumber } from '../../utils/magicNumber'
import { componentClassName } from './LineSummary.constants'
import { Types } from '../../globalConstants/global.constant'

const LineSummary: React.FC<LineSummaryProps> = ({ ...props }): JSX.Element => {
    const {
        title,
        value,
        isHeading = false,
        tooltip,
        showTooltipWithTitle,
        className = componentClassName,
        isSubLineItem,
    } = props

    // Generate unique Id for title element. This ID is needed only if tooltip button is to be shown.
    const titleId = useMemo(() => {
        return tooltip && checkDataLength(tooltip)
            ? `id${Math.random().toString(magicNumber.SIXTEEN).slice(magicNumber.TWO)}`
            : ''
    }, [tooltip])

    /**
     * function to display tooltip
     * @return {JSX.Element}
     */
    const displayTooltip = (): JSX.Element => {
        return tooltip && checkDataLength(tooltip) && <SummaryTooltip {...tooltip} ariaDescribedbyId={`${titleId}`} />
    }

    // Generate css class names conditionally
    const subSummaryItemClass = isSubLineItem ? `${className}__sub-item` : '' // line item which displayed inside another line item section
    const headingClass = isHeading ? `${className}__item--heading` : ''

    return (
        <>
            {isHeading && <hr />}
            <div className={`${className} ${subSummaryItemClass}`}>
                {title && (
                    <div id={`${titleId}`} className={`${className}__item ${headingClass}`}>
                        {title}
                        {showTooltipWithTitle && displayTooltip()}
                    </div>
                )}

                <div className={`${className}__value`}>
                    {value && typeof value === Types.String ? <label>{value}</label> : value}
                    {!showTooltipWithTitle && displayTooltip()}
                </div>
            </div>
        </>
    )
}

export default LineSummary
