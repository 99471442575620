import { createAction } from 'typesafe-actions'

import { HardwareList, HardwareSkuData } from '../models/extraHardware.interface'
import {
    GET_EXTRA_HARDWARE_DATA_ERROR,
    GET_EXTRA_HARDWARE_DATA_SUCCESS,
    SET_EXTRA_HARDWARE_PRICE_SUCCESS,
} from '../types/extraHardware/extraHardware.actionTypes.constant'

export const fetchExtraHardwareAction = createAction(GET_EXTRA_HARDWARE_DATA_SUCCESS)<HardwareList[]>()

export const extraHardwareFailureAction = createAction(GET_EXTRA_HARDWARE_DATA_ERROR)()

export const setExtraHardwarePriceAction = createAction(SET_EXTRA_HARDWARE_PRICE_SUCCESS)<HardwareSkuData>()
