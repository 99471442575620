import getPageTitle from '../../utils/getPageTitle'
import { onLoadEvent } from '../../components/Accounts/OrderHistory/GlobalPageLoadAnalytics.constant'
import { getProvider } from '../providers'
import { SpaPageAnalytics, SpaPageEventAnalytics } from '../analytics.type'
import { AnalyticsRecordInterface } from '../providers/provider.type'
import { analyticsAttributes } from '../../globalConstants'

const provider = getProvider()

const spaAnalytics = (() => {
    const {
        eventParameters: { location },
    } = analyticsAttributes

    /**
     * Prepare analytics data for onload page
     * @param {string} pageName
     * @returns {SpaPageAnalytics}
     */
    const prepareOnloadPageData = (pageName?: string): SpaPageAnalytics => {
        return {
            name: pageName ? pageName : getPageTitle(),
            url: window.location.href,
        }
    }

    /**
     * This function is to trigger analytics for single page
     * @param { string } pageName
     * @returns { SpaPageEventAnalytics }
     */
    const _spaAnalyticsData = (pageName?: string): SpaPageEventAnalytics => {
        return {
            event: onLoadEvent,
            page: prepareOnloadPageData(pageName),
        }
    }

    const _spaLoginAnalyticsData = (successLogin: boolean, eventType: string): AnalyticsRecordInterface => {
        return {
            event: onLoadEvent,
            centralLogin: [
                {
                    eventType,
                    successLogin,
                },
            ],
        }
    }

    return {
        setAnalytics: (pageName?: string): void => {
            provider.push(_spaAnalyticsData(pageName))
        },
        setSSOLoginAnalytics: (successLogin: boolean): void => {
            provider.push(_spaLoginAnalyticsData(successLogin, location.ssoLogin))
        },
        setLoginFromCLPAnalytics: (successLogin: boolean): void => {
            provider.push(_spaLoginAnalyticsData(successLogin, location.finishLoginFromCLP))
        },
    }
})()

export default spaAnalytics
