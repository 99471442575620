import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Icon from '../Icon'
import { PREFIX } from '../config'
import { MegaNavigationProps } from './MegaNavigation.type'
import MegaNavigationList from './MegaNavigationList'
import MegaNavigationTile from './MegaNavigationTile'
import { getAriaCurrentValue } from './MegaNavigation.helper'
import { ariaType } from '../../globalConstants/global.constant'
import { getInteractiveElements } from '../../helpers/getInteractiveElements.helper'
import categoryShopAllLink from './ShopAllUrl.helper'

/* MegaNavigation component
 * @param {MegaNavigationProps} props
 * @return {JSX.Element} returns MediaGalleryViewer component
 */

const MegaNavigation: React.FC<MegaNavigationProps> = ({ ...props }): JSX.Element => {
    const {
        categoryData,
        path,
        onCategoryClicked,
        onBackLabelClicked,
        currentView,
        megaNavClicked,
        enableShopAllLabel,
        shopAllPlaceholderUrl,
        shopAllLinkLabel,
        shopAllExclusionIds,
        ariaLabelShopAll,
    } = props
    const listRef = useRef(null)
    const currentIndexRef = useRef({})
    const backActionRef = useRef(false)
    const forwardActionRef = useRef(false)
    const backButtonRef = useRef<HTMLDivElement>(null)

    // Below useEffect focus previous level selection
    useEffect(() => {
        if (
            backActionRef.current &&
            listRef.current &&
            currentIndexRef.current &&
            currentIndexRef.current[currentView] > -1
        ) {
            const interactiveElements = getInteractiveElements(listRef.current)
            interactiveElements[currentIndexRef.current[currentView] as number]?.focus()
        }
        backActionRef.current = false
    }, [currentView])

    useEffect(() => {
        if (backButtonRef.current && forwardActionRef.current) {
            const firstInteractiveElement = getInteractiveElements(backButtonRef.current)[0]
            firstInteractiveElement?.focus()
        }
        forwardActionRef.current = false
    }, [currentView])

    const renderListOrShopList = (): JSX.Element => {
        if (categoryData && Array.isArray(categoryData)) {
            return (
                <MegaNavigationList
                    path={path}
                    categoryMapData={categoryData}
                    onCategoryClicked={onCategoryClicked}
                    currentView={currentView}
                    style="bold"
                    listRef={listRef}
                    currentIndexRef={currentIndexRef}
                    forwardActionRef={forwardActionRef}
                />
            )
        } else {
            return (
                <div className={`${PREFIX}-mega-navigation--mobile`} ref={backButtonRef}>
                    <div className={`${PREFIX}-mega-navigation__back-section`}>
                        <Icon type={'ct-chevron-left'} size="md" path={path} />
                        <Button
                            type="tertiary"
                            onClick={() => {
                                onBackLabelClicked()
                                backActionRef.current = true
                            }}
                            id={`mega-navigation-list-${currentView}-back`}>
                            {categoryData.backLinkLabel}
                        </Button>
                    </div>
                    <div className={`${PREFIX}-mega-navigation__shopall-section`}>
                        <a
                            aria-current={getAriaCurrentValue(categoryData) ? ariaType : false}
                            href={`${
                                typeof categoryData.url === 'string'
                                    ? encodeURI(categoryData.url)
                                    : encodeURI(categoryData.url?.url)
                            }`}>
                            {categoryData.name}
                        </a>
                        {categoryShopAllLink(
                            categoryData?.id,
                            categoryData.url,
                            shopAllPlaceholderUrl,
                            enableShopAllLabel,
                            shopAllExclusionIds,
                            shopAllLinkLabel,
                            ariaLabelShopAll?.concat(' ', categoryData.name),
                            `${PREFIX}-mega-navigation__shopall-section__link`,
                        )}
                    </div>
                    <MegaNavigationList
                        style={'normal'}
                        path={path}
                        categoryMapData={[...categoryData.categories]}
                        onCategoryClicked={onCategoryClicked}
                        currentView={currentView}
                        megaNavClicked={megaNavClicked}
                        listRef={listRef}
                        currentIndexRef={currentIndexRef}
                        forwardActionRef={forwardActionRef}
                    />

                    {categoryData.saleCategories && currentView === 'L2' ? (
                        <MegaNavigationTile tileData={categoryData.saleCategories} />
                    ) : null}
                </div>
            )
        }
    }

    return <>{renderListOrShopList()}</>
}

MegaNavigation.propTypes = {
    categoryData: PropTypes.any,
    path: PropTypes.string,
    onCategoryClicked: PropTypes.func,
    onBackLabelClicked: PropTypes.func,
    currentView: PropTypes.string,
    backToLabel: PropTypes.string,
    shopAllLabel: PropTypes.string,
    megaNavClicked: PropTypes.func,
    autoServiceButton: PropTypes.func,
}

export default MegaNavigation
