import { getCookieValue } from '@nl/lib'

/**
 * Extract the criteo retailer visitor id from the cookie
 * @returns {string} The criteo retailer visitor id
 */
export const getRetailerVisitorId = (): string => {
    const gaCookie = getCookieValue('_ga') as string

    try {
        const gaCookieArray = gaCookie && gaCookie.split('.')
        if (gaCookieArray && gaCookieArray.length >= 4) {
            return `${gaCookieArray[2]}.${gaCookieArray[3]}`
        }
    } catch (e) {
        console.error('Error:', e)
    }
    return ''
}
