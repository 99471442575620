import { RootState } from '../reducers'
import { CategoryResponseById, CategoryBreadCrumb } from '../models/category.interface'

export const categoryIdDataSelector = (state: RootState): CategoryResponseById => state.categoryIdData
export const productWheelTypeSelector = (state: RootState): string | undefined => state.categoryIdData.productWheelType
export const breadcrumbListSelector = (state: RootState): CategoryBreadCrumb[] => state.categoryIdData.breadcrumbList
export const isFitmentRequiredSelector = (state: RootState): boolean | undefined =>
    state.categoryIdData.isFitmentRequired
export const nameSelector = (state: RootState): string => state.categoryIdData.name
export const seoHeaderTitleSelector = (state: RootState): string => state.categoryIdData.seoHeaderTitle
export const categoryApiCompletedSelector = (state: RootState): boolean => state.categoryIdData.categoryApiCompleted
export const defaultViewSelector = (state: RootState): string => state.categoryIdData.defaultView
export const idSelector = (state: RootState): string => state.categoryIdData?.id || ''
export const seoCopySelector = (state: RootState): string => state.categoryIdData.seoCopy
export const categoryLevelSelector = (state: RootState): string => state.categoryIdData?.level || ''
export const promotionStrategySelector = (state: RootState): string => state.categoryIdData?.promotionStrategy || ''
