import React from 'react'
import { string, func } from 'prop-types'

import Button from '../Button'
import { PREFIX } from '../config'
import { RewardTermsConditionsProps } from './TermsAndConditions.types'

/**
 * TriangleAccountCard component
 * @param {LinkOptionsCardPropTypes} props
 * @return {JSX.Element} returns TriangleAccountCard
 */
const TermsAndConditions: React.FC<RewardTermsConditionsProps> = ({
    tncTitle,
    tncAgreeLabel,
    tncAgreeLinkUrl,
    tncAgreeLinkLabel,
    tncCancelBtnLabel,
    tncRegisterBtnLabel,
    rtContent,
    mapCard,
    goToPreviousScreen,
}): JSX.Element => {
    return (
        <div className={`${PREFIX}-terms-conditions testterms`}>
            <h3 className={`${PREFIX}-terms-conditions-heading`}>{tncTitle}</h3>
            <div
                className={`${PREFIX}-terms-conditions-content`}
                dangerouslySetInnerHTML={{ __html: rtContent }}
                data-testid="rtContent"
            />
            <div className={`${PREFIX}-terms-conditions-register`}>
                <div className={`${PREFIX}-terms-conditions-register__label`}>{tncAgreeLabel}</div>
                <a
                    className={`${PREFIX}-terms-conditions-register__link`}
                    rel="noopener noreferrer"
                    href={tncAgreeLinkUrl}
                    target="_blank"
                    data-link-value={tncAgreeLabel}>
                    {tncAgreeLinkLabel}
                </a>
                <Button type="primary" onClick={mapCard} size="large">
                    {tncRegisterBtnLabel}
                </Button>
                <Button type="tertiary" onClick={goToPreviousScreen}>
                    {tncCancelBtnLabel}
                </Button>
            </div>
        </div>
    )
}
TermsAndConditions.propTypes = {
    tncTitle: string,
    tncAgreeLabel: string,
    tncAgreeLinkUrl: string,
    tncAgreeLinkLabel: string,
    tncCancelBtnLabel: string,
    tncRegisterBtnLabel: string,
    rtContent: string,
    goToPreviousScreen: func,
    mapCard: func,
}
export default TermsAndConditions
