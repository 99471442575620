import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/mergeLoyaltyCard.actionCreators'
import {
    validateLoyaltySuccessAction,
    validateLoyaltyErrorAction,
    validateLoyaltyExtendedSuccessAction,
    validateLoyaltyExtendedErrorAction,
    clearValidateLoyaltyError,
    clearValidateLoyaltyExtended,
    clearMergeLoyaltyValidateAction,
} from '../actionCreators/mergeLoyaltyCard.actionCreators'
import { ErrorResponse, LoyaltyCardBodyResponse, ExtendedBodyResponse } from '../models/mergeLoyaltyCard.interface'
import { EmptyActionType } from '../models/globalData.interface'

type Action = ActionType<typeof actions> | EmptyActionType

export interface MergeLoyaltyValidateState {
    validateLoyaltyCard: LoyaltyCardBodyResponse | null
    validateLoyaltyCardError: ErrorResponse | null
    validateLoyaltyCardExtended: ExtendedBodyResponse | null
    validateLoyaltyCardExtendedError: ErrorResponse | null
}

export const initialState: MergeLoyaltyValidateState = {
    validateLoyaltyCard: {} as LoyaltyCardBodyResponse,
    validateLoyaltyCardError: {} as ErrorResponse,
    validateLoyaltyCardExtended: {} as ExtendedBodyResponse,
    validateLoyaltyCardExtendedError: {} as ErrorResponse,
}

export const mergeLoyaltyValidateReducer = (
    state: MergeLoyaltyValidateState = initialState,
    action: Action,
): MergeLoyaltyValidateState => {
    switch (action.type) {
        case getType(validateLoyaltySuccessAction): {
            return { ...state, validateLoyaltyCard: action.payload }
        }
        case getType(validateLoyaltyErrorAction): {
            return {
                ...state,
                validateLoyaltyCard: null,
                validateLoyaltyCardError: action.payload,
            }
        }
        case getType(clearValidateLoyaltyError): {
            return { ...state, validateLoyaltyCard: null, validateLoyaltyCardError: null }
        }
        case getType(validateLoyaltyExtendedSuccessAction): {
            return { ...state, validateLoyaltyCardExtended: action.payload }
        }
        case getType(validateLoyaltyExtendedErrorAction): {
            return {
                ...state,
                validateLoyaltyCardExtended: null,
                validateLoyaltyCardExtendedError: action.payload,
            }
        }
        case getType(clearValidateLoyaltyExtended): {
            return { ...state, validateLoyaltyCardExtended: null, validateLoyaltyCardExtendedError: null }
        }
        case getType(clearMergeLoyaltyValidateAction): {
            return { ...state, validateLoyaltyCard: null, validateLoyaltyCardExtended: null }
        }
        default:
            return state
    }
}
