import { ActionType, getType } from 'typesafe-actions'

import { analyticsClearInteractionCreator, analyticsInteractionCreator } from '../actionCreators'
import * as actions from '../actionCreators/pageAnalytics.actionCreators'
import { AnalyticsRecordInterface } from '../../analytics/providers/provider.type'

type Action = ActionType<typeof actions>

export interface PageAnalyticsState {
    commonPageEvents: AnalyticsRecordInterface[]
}

export const initialState: PageAnalyticsState = {
    commonPageEvents: [],
}

export const pageAnalyticsReducer = (state: PageAnalyticsState = initialState, action: Action): PageAnalyticsState => {
    switch (action.type) {
        case getType(analyticsInteractionCreator):
            return { ...state, commonPageEvents: [action.payload].concat(state.commonPageEvents) }
        case getType(analyticsClearInteractionCreator):
            state.commonPageEvents = []
            return { ...state }
        default:
            return state
    }
}
