import { createAction } from 'typesafe-actions'
import { CertonaResponseType } from '../../certona/certona.type'

import {
    CERTONA_PRODUCT_RECOMMENDATION_DATA,
    REMOVE_SPECIFIC_SCHEMA_FROM_CERTONA,
} from '../types/products/certona.actionTypes.constant'

export const storeCertonaProductRecommendationData = createAction(
    CERTONA_PRODUCT_RECOMMENDATION_DATA,
    (payload: CertonaResponseType) => payload,
)()

export const removeSpecificSchemaFromCertonaData = createAction(
    REMOVE_SPECIFIC_SCHEMA_FROM_CERTONA,
    (payload: string) => payload,
)()
