import { RootState } from '../reducers'
import { ProductResponseData, ProductSku, ProductSkusData, ProductState } from '../models/product.interface'
import { BreadcrumbLinkData } from '../models/productData.interface'

export const productSelector = (state: RootState): ProductState => state.product
export const productDataSelector = (state: RootState): ProductResponseData => state.product.productData
export const productDataSkusSelector = (state: RootState): ProductSku[] => state.product.productData.skus
export const productDataBreadcrumbListSelector = (state: RootState): BreadcrumbLinkData[] =>
    state.product.productData.breadcrumbList
export const productDataSkusFitmentTypeCodeSelector = (state: RootState): string | undefined =>
    state.product.productData.fitmentTypeCode
export const selectedProductCodeSelector = (state: RootState): string => state.product.selectedProductCode
export const productSkuDataSelector = (state: RootState): ProductSkusData => state.product.productSkuData
export const isAllVariantSelectedSelector = (state: RootState): boolean => state.product.isAllVariantSelected
export const fbtProductSkuDataSelector = (state: RootState): ProductSkusData => state.product.fbtProductSkuData
export const isSKUPresentInUrlSelector = (state: RootState): boolean | undefined => state.product.isSKUPresentInUrl
export const isOOSCurrentStoreSelector = (state: RootState): boolean | null | undefined =>
    state.product.isOOSCurrentStore
export const pdpBreadcrumbListSelector = (state: RootState): BreadcrumbLinkData[] =>
    state.product.productData.breadcrumbList
export const selectedPartNumberSelector = (state: RootState): string => state.product.selectedPartNumber || ''
export const enableBalloonsHeliumInflationSelector = (state: RootState): boolean | undefined =>
    state.commonContent?.commonContentAvailable?.featureFlag?.enableBalloonsHeliumInflation
export const enableAppointmentAvailabilitySectionSelector = (state: RootState): boolean | undefined =>
    state.commonContent?.commonContentAvailable?.featureFlag?.isAppointmentAvailabilitySectionShow
