import {
    AddProductToCartGuestRequestDTO,
    CartOrderEntries,
    OrderEntryDTO,
    StaggeredFitmentProduct,
    FilteredCartData,
    MiniCartData,
    eEntryType,
    VehicleInformation,
    ServiceDTO,
    CartItemFulFillmentType,
    IHeliumInflationStoreServiceType,
    Package,
    CartOrderEntriesWithFilterVal,
    DeliveryStatusBopis,
    CartOrderDeliveryModes,
} from '../../redux/models/cart.interface'
import { EntryGroups, EntryGroup } from '../../redux/models/orderConfirmation.interface'
import { saveForLaterParam, staggeredGroup } from './ShoppingCart.constants'
import appCacheService from '../../utils/appCacheService'
import { CartErrorService } from './CartError.service'
import { FulfillmentMethods, ItemInlineErrorType, SelectedDeliveryMode } from './ShoppingCart.type'
import { MagicNumber } from '../../analytics/analytics.type'
import { pageTypes } from '../../config'
import getPageType from '../../utils/getPageType'
import { isShoppingCartPage } from '../../utils/checkPageType'
import { isArrayEmpty, isArrayNotEmpty, updateUrlHistory } from '@nl/lib'
import { AddToWishListPayload } from '../../redux/models/wishlist.interface'
import { HardwareList } from '../../redux/models/extraHardware.interface'
import { extraHardwareService } from '../../services/extraHardwareService'
import { balloonAddonsService } from '../../services/balloonAddonsService'
import { ProductSku, ProductSkusData } from '../../redux/models/product.interface'
import { areAllParamsValid } from '../../utils/getFilteredCartItems'
import { gigyaParams } from '../GigyaScreen/gigya.constants'
import { balloonEntryType, configurableEntryType, emptySpace, inflationData } from '../../globalConstants'
import { isHeliumAddonServiceIncluded } from '../CartFlyout/CartFlyoutComp.helper'
import { checkNotNullAndUndefined } from '../../utils/checkNotNullAndUndefined'

/**
 * Function used to generate vehicle information based on input parameters
 * @param { VehicleInformation } VehicleInformation - Vehicle Information
 * @returns { string } group number
 */
export const createVehicleInfo = ({
    year,
    make,
    model,
    body,
    options,
}: {
    year: string
    make: string
    model: string
    body: string
    options: string
}): string => {
    return `${year}${make}${model}${body}${options}`.toLowerCase()
}

/**
 * NOTE - We are defining a new function as we do not want to maintain body and options as null/undefined in vehicle info string
 * used to generate vehicle information based on input parameters
 * @param { VehicleInformation } VehicleInformation - Vehicle Information
 * @returns {string} vehicle information
 */
export const createWishListVehicleInfo = ({
    year,
    make,
    model,
    body,
    options,
}: {
    year: string
    make: string
    model: string
    body?: string
    options?: string
}): string => {
    return `${year}${make}${model}${body ? body : ''}${options ? options : ''}`.toLowerCase()
}

/**
 * NOTE - We are defining a new function as there is difference between namings of option property in vehicle info with respect to default vehicle and wishlist vehicle
 * used to generate vehicle information based on input parameters
 * @param { VehicleInformation } VehicleInformation - Vehicle Information
 * @returns {string} vehicle information
 */
export const createDefaultVehicleInfo = ({
    year,
    make,
    model,
    body,
    option,
}: {
    year?: string
    make?: string
    model?: string
    body?: string
    option?: string
}): string => {
    return `${year ? year : ''}${make ? make : ''}${model ? model : ''}${body ? body : ''}${
        option ? option : ''
    }`.toLowerCase()
}
/**
 * Function to get group and priority value from product staggeredGroup field
 * @param {CartOrderEntries} product - product data
 * @returns {string | []} group number and priority
 */
const getValues = (product: CartOrderEntries) => {
    return product.staggeredGroup?.split('-') || []
}

/**
 * Function is used to group the product based vehicle info
 * @param {CartOrderEntries} products - product data
 * @param {Record<string, unknown>} automotiveProducts - automotive Products
 * @returns {CartOrderEntries[]} - grouped products
 */
export const groupVehicle = (
    products: CartOrderEntries,
    automotiveProducts: Record<string, unknown[]>,
): Record<string, unknown[]> => {
    const vehicleInfo = products.vehicleInformation
        ? createVehicleInfo(products.vehicleInformation as Required<VehicleInformation>)
        : null
    if (vehicleInfo) {
        automotiveProducts[vehicleInfo] = automotiveProducts[vehicleInfo] || []
        automotiveProducts[vehicleInfo].push(products)
    }
    return automotiveProducts
}

/**
 * Function to sort products by staggeredGroup
 * @param {CartOrderEntries[]} products - product data
 * @param {EntryGroups[]} entryGroups - entry groups
 * @returns {StaggeredFitmentProduct[]} - sorted products
 */
export const sortStaggeredFitment = (
    products: CartOrderEntries[],
    entryGroups: EntryGroups[],
): StaggeredFitmentProduct[] => {
    const sortedByStaggeredGroup = [...products]
    sortedByStaggeredGroup.sort((a: CartOrderEntries, b: CartOrderEntries) => {
        const [groupA, priorityA] = getValues(a)
        const [groupB, priorityB] = getValues(b)

        const sortByGroup = parseInt(groupA) - parseInt(groupB)
        const sortByPriority = parseInt(priorityA) - parseInt(priorityB)

        return sortByGroup === 0 ? sortByPriority : sortByGroup
    })

    return sortedByStaggeredGroup.reduce((sortedProducts: CartOrderEntries[], product: CartOrderEntries) => {
        const [groupNumber, priority] = getValues(product)
        const entryGroup = entryGroups?.find((group: EntryGroups) => group.groupNumber.toString() === groupNumber)
        const productMatches = entryGroup?.entryGroup?.some(
            (entry: EntryGroup) => entry.priority.toString() === priority,
        )
        const isFront = priority === staggeredGroup.priorityZero

        return productMatches ? [...sortedProducts, { ...product, isFront }] : sortedProducts
    }, [])
}

/**
 * Function to sort products - products with staggeredGroup on top
 * @param {CartOrderEntries[]} products - product data
 * @param {EntryGroups[]} entryGroups - entry groups
 * @returns {StaggeredFitmentProduct[]} - sorted products
 */
export const sortProductsByStaggeredGroup = (
    products: StaggeredFitmentProduct[],
    entryGroups: EntryGroups[] | undefined,
): StaggeredFitmentProduct[] => {
    const productsWithStaggeredFitment = products.filter(product => {
        const [groupNumber, priority] = getValues(product)
        return !!(groupNumber && priority)
    })
    const productsWithoutStaggeredFitment = products.filter(product => {
        const [groupNumber, priority] = getValues(product)
        return !(groupNumber && priority)
    })

    const sortedProductsWithStaggeredFitment = sortStaggeredFitment(
        productsWithStaggeredFitment,
        entryGroups as EntryGroups[],
    )
    return [...sortedProductsWithStaggeredFitment, ...productsWithoutStaggeredFitment]
}

/**
 * Payload for the add item to cart.
 * @param {string} cartGUID - cart guid - anonymous/ authenticated user.
 * @param {CartOrderEntries} services - the add ons which needs to be added to the cart.
 * @param {CartOrderEntries} productData - the product which needs to be added to the cart.
 * @param {boolean} isSFL - save for later flag.
 * @param {string} sflGUID - save for later guid.
 * @returns {AddProductToCartGuestRequestDTO} - payload for the add item to cart.
 */
// TODO: this code should be part of service
export const cartDataRequestPayload = (
    cartGUID: string,
    services: CartOrderEntries[] | ServiceDTO[],
    productData?: CartOrderEntries,
    isSFL = false,
    sflGUID?: string,
): AddProductToCartGuestRequestDTO => {
    const entryNumberArray: number[] = []
    entryNumberArray.push(Number(productData?.entryNumber))

    const vehicleInformation = productData?.vehicleInformation
        ? createVehiclePayload(productData.vehicleInformation)
        : undefined
    const cartData: AddProductToCartGuestRequestDTO = {
        guid: cartGUID,
        saveForLaterId: sflGUID,
        entries: {
            orderEntries: [],
        },
        entryNumber: entryNumberArray,
    }

    if (isSFL) {
        const productEntry: OrderEntryDTO = {
            ...constructOrderEntries(productData as CartOrderEntries),
            ...{ vehicleInformation: vehicleInformation },
        }
        cartData.entries.orderEntries.push(productEntry)
    }
    Array.isArray(services) &&
        services.forEach(service => {
            const entry: OrderEntryDTO = {
                ...constructOrderEntries(service, productData),
                cartEntryReference: productData?.code || '',
            }
            cartData.entries.orderEntries.push(entry)
        })

    return cartData
}

/**
 * Convert values to Order Entries
 * @param {CartOrderEntries} productData - product data
 * @param {CartOrderEntries} parentProductData - parent product data
 * @returns {OrderEntryDTO} - order entry
 */
const constructOrderEntries = (
    productData: CartOrderEntries | ServiceDTO,
    parentProductData?: CartOrderEntries,
): OrderEntryDTO => {
    const deliveryModeCode: FulfillmentMethods | CartOrderDeliveryModes | undefined =
        productData?.entryType === eEntryType.SERVICE_STANDALONE
            ? FulfillmentMethods.BOPIS
            : productData?.fulfillment?.deliveryMode || parentProductData?.fulfillment.deliveryMode
    return {
        deliveryMode: {
            code: deliveryModeCode as string,
        },
        quantity: Number((productData as CartOrderEntries)?.quantity || parentProductData?.quantity),
        deliveryPointOfService: {
            name: appCacheService.preferredStoreId.get(),
        },
        product: {
            code: productData?.code || productData?.sku,
        },
    }
}

/**
 * This function is used to check the sellable property and based on that return error response
 * @param {boolean} isSelleble - is product sellable
 * @param {string} error - error message
 * @param {string} type - type of error
 * @returns {ItemInlineErrorType | undefined } - error response
 */
export const isProductSellable = (
    isSelleble: boolean,
    error: string,
    type: string,
): ItemInlineErrorType | undefined => {
    if (!isSelleble) {
        /**
         * Frame the error response.
         * showInlineError - true, if the item has error.
         * errorMessage - toast error message.
         * grayOutImage - if true, will apply opacity of 0.4 on the image.
         * optionsToBeDisplayed - items to be displayed when the item as errors.
         * fulfillmentDeliverError -if deliver mode  not available during checkout but initially was available
         * hardStopError - if true, user should not be allowed to proceed to checkout.
         */
        return CartErrorService.constructErrorResponse(true, error, true, [type], '', true)
    }
}

/**
 * Function to return array of entry number for removing multiple items
 * @param {CartOrderEntries} productData - product data
 * @param {EntryGroups[]} cartEntryGroups - cart entry groups
 * @param {CartOrderEntries[]} bopis - bopis data
 * @param {CartOrderEntries[]} cartSelectedServiceList - cart selected service list
 * @param {unknown[]} entryNumber - entry number
 * @param {boolean} isBalloonBouquet - is balloon bouquet
 * @param {boolean} removeFromCart - remove from cart
 * @returns {void} - void
 */
export const removeAllItemsEntryNumber = (
    productData: CartOrderEntries,
    cartEntryGroups: EntryGroups[],
    bopis: CartOrderEntries[],
    cartSelectedServiceList: CartOrderEntries[],
    entryNumber: unknown[],
    isBalloonBouquet = false,
    removeFromCart = true,
): void => {
    const packageId = productData.packageId
    const staggeredId = productData.staggeredGroup?.split('-')[0]
    if (packageId) {
        entryNumber.push(
            ...bopis
                ?.filter(item => item.packageId === packageId)
                .map(item => (removeFromCart ? item.entryNumber : item.code)),
        )
    } else if (staggeredId) {
        if (isBalloonBouquet) {
            const entryGroups = cartEntryGroups
                .find(item => item.groupNumber === Number(staggeredId))
                ?.entryGroup.map(item => item.groupNumber)
            entryGroups?.forEach(searchVal => {
                const productEntryNumber = bopis.find(item =>
                    item.entryGroupNumbers?.some(i => i === searchVal),
                )?.entryNumber
                !checkNotNullAndUndefined(productEntryNumber) && entryNumber.push(productEntryNumber)
            })
        } else {
            const entryGroups = cartEntryGroups
                .find(item => item.groupNumber === Number(staggeredId))
                ?.entryGroup.map(item => item.label.split('.')[MagicNumber.ONE])
            entryGroups?.forEach(searchVal => {
                entryNumber.push(
                    removeFromCart
                        ? bopis.find(item => item.code === searchVal)?.entryNumber
                        : bopis.find(item => item.code === searchVal)?.code,
                )
            })
        }
    } else {
        entryNumber.push(removeFromCart ? productData.entryNumber : productData.code)
        cartSelectedServiceList &&
            entryNumber.push(
                ...cartSelectedServiceList
                    .filter(
                        item =>
                            item.cartEntryReference === productData.code &&
                            item.fulfillment?.deliveryMode?.toUpperCase() ===
                                productData.fulfillment?.deliveryMode?.toUpperCase(),
                    )
                    .map(item => (removeFromCart ? item.entryNumber : item.code)),
            )
    }
}

/**
 * Function to check if current page is of cart page type
 * @returns {boolean} - is cart page
 */
export const isCartPage = (): boolean => {
    return getPageType() === pageTypes.cart
}

/**
 * Function to get orderEntries of singleProduct
 * @param { MiniCartData | FilteredCartData } cartItems - cart items
 * @returns { string[]|undefined } - order entries
 */
export const fetchSingleProductSku = (cartItems: FilteredCartData | MiniCartData): string | undefined => {
    if (isShoppingCartPage()) {
        return cartItems && (cartItems as FilteredCartData)?.cart?.orderEntries?.[0]?.code
    } else {
        return cartItems && (cartItems as MiniCartData)?.orderEntries?.[0]?.code
    }
}
/**
 * Function to navigate to any link selected
 * @param {string} url url of the product
 * @param {string} anchor when url has an anchor
 */
export const navigateToLink = (url: string, anchor?: string): void => {
    const anchorTag = `#${String(anchor)}`
    const isAnchorInURL = url.includes(anchorTag)
    const showAnchorTag = anchor || !isAnchorInURL ? anchorTag : ''
    const modifiedURl = `${url}${showAnchorTag}`
    window.location.href = modifiedURl
}

/**
 * Function to scroll to top
 * @returns {void} - void
 */
export const scrollToTop = (): void => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    })
}

/**
 * Function to extract cart items SKUs
 * @param {FilteredCartData} cartItems - cart items
 * @returns {string[]|undefined} - cart SKUs
 */
export const extractCartSkus = (cartItems: FilteredCartData): string[] | undefined => {
    return cartItems?.cart?.orderEntries?.map((entry: CartOrderEntries) => entry.code)
}

/**
 * Function to check if service addon is added
 * @param {FilteredCartData} cartItemsData - cart items data
 * @returns {boolean} - is service addon added
 */
export const isServiceAddonAdded = (cartItemsData: FilteredCartData): boolean => {
    const isAnyAddOnService = cartItemsData.selectedServiceList?.filter(
        itemData => itemData.entryType === eEntryType.SERVICE_ADDON,
    )
    return isArrayNotEmpty(isAnyAddOnService) || isArrayNotEmpty(cartItemsData.services)
}

/**
 * Function to Create VehicleInformation Payload
 * @param {VehicleInformation} vehicle - vehicle information
 * @returns {VehicleInformation} - vehicle information
 */
export const createVehiclePayload = (vehicle: VehicleInformation): VehicleInformation => {
    const engineConfigId = vehicle?.engineConfigId?.toString()
    let vehiclePayload = {
        baseVehicleId: vehicle?.baseVehicleId,
        type: vehicle?.type,
        make: vehicle?.make,
        model: vehicle?.model,
        body: vehicle?.body || undefined,
        options: vehicle?.options || undefined,
        year: vehicle?.year,
    } as unknown as VehicleInformation
    if (engineConfigId) {
        vehiclePayload = { ...vehiclePayload, engineConfigId }
    }
    return vehiclePayload
}

/**
 * Returns Wishlist Payload without vehicle information
 * @param {string[]} productCodeArray - product code array
 * @returns {AddToWishListPayload} - wishlist payload
 */
export const wishlistEntriesWithoutVehicleInfo = (productCodeArray: string[]): AddToWishListPayload => {
    return {
        wishListEntries: productCodeArray.map(code => {
            return {
                product: {
                    code,
                },
            }
        }),
    } as AddToWishListPayload
}

/**
 * Returns AddToWishListPayload
 * @param {string[]} productCodeArray - product code array
 * @param {VehicleInformation | null} vehicleContext - vehicle context
 * @param {boolean} enableVehicleInformationOnWishlist - enable vehicle information on wishlist
 * @returns {AddToWishListPayload} - wishlist payload
 */
export const returnAddToWishListPayload = (
    productCodeArray: string[],
    vehicleContext: VehicleInformation | null,
    enableVehicleInformationOnWishlist: boolean,
): AddToWishListPayload => {
    if (enableVehicleInformationOnWishlist) {
        if (vehicleContext) {
            const vehicleInformation: VehicleInformation = {
                baseVehicleId: vehicleContext.baseVehicleId,
                body: vehicleContext.body,
                model: vehicleContext.model,
                options: vehicleContext.options,
                type: vehicleContext.type,
                year: vehicleContext.year,
                make: vehicleContext.make,
            }
            if (vehicleContext.engineConfigId) {
                vehicleInformation.engineConfigId = vehicleContext.engineConfigId.toString()
            }
            if (vehicleContext.boltPattern) {
                vehicleInformation.boltPattern = vehicleContext.boltPattern
            }
            return {
                wishListEntries: productCodeArray.map(code => {
                    return {
                        product: {
                            code,
                        },
                        vehicleInformation,
                    }
                }),
            } as AddToWishListPayload
        } else {
            return wishlistEntriesWithoutVehicleInfo(productCodeArray)
        }
    } else {
        return wishlistEntriesWithoutVehicleInfo(productCodeArray)
    }
}

/**
 * Function to get extra hardware addons
 * @param {HardwareList[]} addonsList - addons list
 * @returns {HardwareList[]} - extra hardware addons
 */
export const getExtraHardwareAddonsSku = (addonsList: HardwareList[]): HardwareList[] => {
    return extraHardwareService.getProductSkus(addonsList)
}

/**
 * Function to get balloon addons addons
 * @param {ProductSkusData} productSkuData - product sku data
 * @param {HardwareList} addons - addons
 * @returns {ProductSku | undefined} - product sku
 */
export const getProductSkuData = (productSkuData: ProductSkusData, addons: HardwareList): ProductSku | undefined => {
    return productSkuData.skus.find((skuData: ProductSku) => skuData.code === addons.sku)
}

/**
 * Function to get balloon addons addons
 * @param {HardwareList[]} addonsList - addons list
 * @returns {HardwareList[]} - balloon addons sku
 */
export const getBalloonAddonsSku = (addonsList: HardwareList[]): HardwareList[] => {
    return balloonAddonsService.getProductSkus(addonsList)
}

/**
 * Function to get balloon addons sku list
 * @param {HardwareList[]} addonsList - addons list
 * @param {boolean} isHardwareAddon - is hardware addon
 * @returns {Record<string, string>[]} - balloon addons sku list
 */
export const getAddonsSkuList = (addonsList: HardwareList[], isHardwareAddon: boolean): Record<string, string>[] => {
    const filteredAddonsList = isHardwareAddon ? getExtraHardwareAddonsSku(addonsList) : getBalloonAddonsSku(addonsList)
    return filteredAddonsList.map((addons: HardwareList) => {
        return { code: addons.sku }
    })
}

/**
 * Function to get balloon addons sku list
 * @param {CartOrderEntries[]} previousOrderEntries - previous order entries
 * @param {CartOrderEntries} currentCartItems - current cart items
 * @returns {boolean} - balloon addons sku list
 */
export const hasCartItemsBecomeUnavailableForSTH = (
    previousOrderEntries: CartOrderEntries[] = [],
    currentCartItems: CartOrderEntries[] = [],
): boolean => {
    return currentCartItems?.some(currentItem => {
        const previousItem = previousOrderEntries?.find(itemData => itemData.code === currentItem.code)
        const isPreviousItemEligibleForSTH = previousItem?.fulfillment?.isEligibleToShipHome
        return isPreviousItemEligibleForSTH && !currentItem?.fulfillment?.isEligibleToShipHome
    })
}
/**
 * Function to check if cart items become unavailable for BOPIS
 * @param {CartOrderEntries[]} previousOrderEntries - previous order entries
 * @param {CartOrderEntries} currentCartItems - current cart items
 * @returns {boolean} - has cart items become unavailable for BOPIS
 */
export const hasCartItemsBecomeUnavailableForBOPIS = (
    previousOrderEntries: CartOrderEntries[] = [],
    currentCartItems: CartOrderEntries[] = [],
): boolean => {
    return currentCartItems?.some(currentItem => {
        const previousItem = previousOrderEntries?.find(itemData => itemData.code === currentItem.code)
        const isPreviousItemEligibleForBOPIS = previousItem?.fulfillment?.isEligibleToPickupFromStore
        return isPreviousItemEligibleForBOPIS && !currentItem?.fulfillment?.isEligibleToPickupFromStore
    })
}

/**
 * Function to check if cart items become unavailable for DC Quantity
 * @param {CartOrderEntries[]} previousOrderEntries - previous order entries
 * @param {CartOrderEntries[]} currentCartItems - current cart items
 * @returns {boolean} - has cart items become unavailable for DC Quantity
 */
export const hasCartItemsBecomeUnavailableByDCQty = (
    previousOrderEntries: CartOrderEntries[] = [],
    currentCartItems: CartOrderEntries[] = [],
): boolean => {
    return currentCartItems?.some(currentItem => {
        const previousItem = previousOrderEntries?.find(itemData => itemData.code === currentItem.code)
        const previousDCQuantity = previousItem?.fulfillment?.stockItemAvailability?.dcQuantity
        return previousDCQuantity && !currentItem?.fulfillment?.stockItemAvailability?.dcQuantity
    })
}

/**
 * Function to check if cart items become unavailable for Store Quantity
 * @param {CartOrderEntries[]} previousOrderEntries - previous order entries
 * @param {CartOrderEntries[]} currentCartItems - current cart items
 * @returns {boolean} - has cart items become unavailable for Store Quantity
 */
export const hasCartItemsBecomeUnavailableByStoreQty = (
    previousOrderEntries: CartOrderEntries[] = [],
    currentCartItems: CartOrderEntries[] = [],
): boolean => {
    return currentCartItems?.some(currentItem => {
        const previousItem = previousOrderEntries?.find(itemData => itemData.code === currentItem.code)
        const previousStoreQuantity = previousItem?.fulfillment?.stockItemAvailability?.storeQuantity
        return previousStoreQuantity && !currentItem?.fulfillment?.stockItemAvailability?.storeQuantity
    })
}

/**
 * Function to check if cart items become unavailable
 * @param {CartOrderEntries[]} previousOrderEntries - previous order entries
 * @param {CartOrderEntries[]} currentCartItems - current cart items
 * @returns {boolean} - has cart items become unavailable
 */
export const hasCartItemsBecomeUnavailable = (
    previousOrderEntries: CartOrderEntries[] = [],
    currentCartItems: CartOrderEntries[] = [],
): boolean => {
    return (
        hasCartItemsBecomeUnavailableForSTH(previousOrderEntries, currentCartItems) ||
        hasCartItemsBecomeUnavailableForBOPIS(previousOrderEntries, currentCartItems) ||
        hasCartItemsBecomeUnavailableByDCQty(previousOrderEntries, currentCartItems) ||
        hasCartItemsBecomeUnavailableByStoreQty(previousOrderEntries, currentCartItems)
    )
}

/**
 * Function for checking ship to home error that depends on delivery mode
 * @param {SelectedDeliveryMode} selectedDeliveryMode - selected delivery mode
 * @param {FulfillmentMethods} itemType - item type
 * @param {CartItemFulFillmentType} fulfillment - fulfillment
 * @returns {boolean} - ship to home error
 */
export const checkShipToHomeError = (
    selectedDeliveryMode: SelectedDeliveryMode,
    itemType: FulfillmentMethods,
    fulfillment: CartItemFulFillmentType,
): boolean => {
    if (!selectedDeliveryMode?.isExpress && !selectedDeliveryMode?.isSTH) {
        return false
    }

    return Boolean(
        selectedDeliveryMode?.isExpress
            ? areAllParamsValid(itemType === FulfillmentMethods.STH, !fulfillment?.isEligibleForExpressDelivery)
            : areAllParamsValid(itemType === FulfillmentMethods.STH, !fulfillment?.isEligibleToShipHome),
    )
}

/**
 * Function to check that page is returning from login redirect
 * @returns {boolean} - is redirected
 */
export const addToCartisRedirected = (): boolean => {
    const redirectURLParams = new URLSearchParams(window.location.search)
    const moveToSFL = redirectURLParams.get(saveForLaterParam)
    const gigParam = window.gigya?.getUrlParam(gigyaParams.sequence)
    if ((moveToSFL === 'true' || gigParam === 'moveToSFL') && appCacheService.saveForLater.get()) {
        redirectURLParams.delete(saveForLaterParam) // Delete the moveToSFL parameter.
        window.history.replaceState(null, 'null', window?.ODP?.globalLinks?.cartPageLink)
        updateUrlHistory(undefined, true)
        return true
    } else {
        return false
    }
}

/**
 * Method to check if product is express eligible and store has inventory for ED
 * @param {boolean} isEligibleForExpressDelivery express eligible flag
 * @param {number} storeQuantity stor inventory quantity
 * @returns {boolean} show ed not eligible error or store inventory error
 */
export const showNoInventoryErrorForED = (isEligibleForExpressDelivery: boolean, storeQuantity: number): boolean => {
    return !isEligibleForExpressDelivery || (isEligibleForExpressDelivery && !storeQuantity)
}

/**
 * Function to check if helium inflation/uninflation text shown
 * @param {boolean} enableBalloonsHeliumInflation - enable balloons helium inflation
 * @param {boolean} isHeliumInflationOptionEnabled - is helium inflation option enabled
 * @param {boolean} heliumInflationAddToCartEnabled - helium inflation add to cart enabled
 * @param {IHeliumInflationStoreServiceType[]} supportedStoreServices - supported store services
 * @param {boolean} showInflationLabelsOnOrderConfirmationPage - show inflation labels on order confirmation page
 * @returns {boolean} - is render helium inflated/uninflated
 */
export const isRenderHeliumInflatedUnInflated = (
    enableBalloonsHeliumInflation: boolean,
    isHeliumInflationOptionEnabled: boolean,
    heliumInflationAddToCartEnabled: boolean,
    supportedStoreServices?: IHeliumInflationStoreServiceType[],
    showInflationLabelsOnOrderConfirmationPage?: boolean,
): boolean => {
    const isHeliumAddonSupported = isHeliumAddonServiceIncluded(supportedStoreServices)
    return areAllParamsValid(
        enableBalloonsHeliumInflation,
        isHeliumInflationOptionEnabled,
        heliumInflationAddToCartEnabled,
        isHeliumAddonSupported,
        !showInflationLabelsOnOrderConfirmationPage,
    )
}

/**
 * Function return  correct text for helium inflation/uninflation
 * @param {IHeliumInflationStoreServiceType[]} selectedStoreServices - selected store services
 * @param {string} heliumFilledButtonLabel - helium filled button label
 * @param {string} uninflatedButtonLabel - uninflated button label
 * @param {string} inflationButtonLabel - inflation button label
 * @param {string[]} badges - badges
 * @param {boolean} showInflationLabelsOnOrderConfirmationPage - show inflation labels on order confirmation page
 * @returns {string} - helium inflation/uninflation text
 */
export const getHeliumInflationUnInflationText = (
    selectedStoreServices: IHeliumInflationStoreServiceType[],
    heliumFilledButtonLabel: string,
    uninflatedButtonLabel: string,
    inflationButtonLabel: string,
    badges?: string[],
    showInflationLabelsOnOrderConfirmationPage?: boolean,
): string => {
    const isHeliumIncluded = badges?.includes(inflationData.heliumIncluded)
    const heliumDecoupledLabel =
        isHeliumIncluded || showInflationLabelsOnOrderConfirmationPage
            ? heliumFilledButtonLabel
            : `${heliumFilledButtonLabel} ($)`
    const isEnabled = isArrayNotEmpty(selectedStoreServices) && isHeliumAddonServiceIncluded(selectedStoreServices)
    const inflationUninflationText = isEnabled ? heliumDecoupledLabel : uninflatedButtonLabel
    return `${inflationButtonLabel}: ${inflationUninflationText}`
}

/**
 * Function to check if package of Balloon type
 * @param { Package } cartPackage - package data
 * @returns { boolean } true if it is balloon package
 */
export const isBalloonPackage = (cartPackage: Package): boolean => {
    return cartPackage.items.some(isBalloonOrBalloonBouquetProduct)
}

/**
 * Function to check if product is Balloon/Balloon bouquet
 * @param { CartOrderEntries } orderEntries - product data
 * @returns { boolean } true if it is balloon package
 */
export const isBalloonOrBalloonBouquetProduct = (orderEntries: CartOrderEntries): boolean => {
    return (
        orderEntries?.balloonEntryType === balloonEntryType.prePackagedBalloonSku ||
        orderEntries?.balloonEntryType === balloonEntryType.preDesignedBalloonBouquet
    )
}

/**
 * Function to check if package does not have balloons bag entry
 * @param { Package } cartPackage - package data
 * @returns { boolean } true if no balloons bag in package
 */
export const isBalloonPackageWithoutBag = (cartPackage: Package): boolean => {
    return (
        isBalloonPackage(cartPackage) &&
        cartPackage.items.every(item => item.balloonEntryType !== balloonEntryType.bagSku)
    )
}

/**
 * Function to check if package does not have balloons weight entry
 * @param { Package } cartPackage - package data
 * @returns { boolean } true if no balloons weight item in package
 */
export const isBalloonPackageWithoutWeight = (cartPackage: Package): boolean => {
    return (
        isBalloonPackage(cartPackage) &&
        cartPackage.items.every(item => item.balloonEntryType !== balloonEntryType.weightSku)
    )
}

/**
 * Function to get entry group number of balloon bag in package
 * @param { EntryGroups[] } entryGroups - all cart packages data
 * @param { number } groupNumber - group number of package to search for
 * @returns { number | undefined } entry group number if found
 */
export const findBalloonBagEntryNumberInGroup = (entryGroups: EntryGroups[], groupNumber: number): number | unknown => {
    const group = entryGroups?.find(entryGroup => entryGroup.groupNumber === groupNumber)
    const groupEntry = group?.entryGroup?.find(
        entry => entry.groupType === configurableEntryType.CONFIGURABLEBUNDLE_BALLOONBAG,
    )

    return groupEntry?.groupNumber
}

/**
 * Function to check if bouquet is present in cancelled consignment
 * @param {Record} groupedData bopisGrouped
 * @param {Package} packageObject packageObject
 * @returns {boolean} isCancelled
 */
const isBouquetCancelled = (groupedData: Record<string, unknown> = {}, packageObject: Package): boolean => {
    const cancelledBouquet = [] as CartOrderEntries[]
    let isCancelled = false
    Object.keys(groupedData).forEach((key: string) => {
        const groupData = groupedData[key] as CartOrderEntriesWithFilterVal[]
        groupData?.forEach(groupedItem => {
            if (isBalloonOrBalloonBouquetProduct(groupedItem) && key.startsWith(DeliveryStatusBopis.CANCELLED)) {
                cancelledBouquet.push(groupedItem)
            }
        })
    })

    packageObject.items.forEach(item => {
        if (cancelledBouquet.some(bouquet => bouquet.entryNumber === item.entryNumber)) {
            isCancelled = true
            return true
        }
    })

    return isCancelled
}

/**
 * Function to check if item should be included in package
 * @param {string} entryType - entry type
 * @param {string} key - key
 * @param {Record} bopisGrouped - bopisGrouped
 * @param {Package} packageObject - packageObject
 * @returns {boolean} shouldIncludeItemInPackage
 */
const shouldIncludeItemInPackage = (
    entryType: string,
    key: string,
    bopisGrouped: Record<string, unknown> = {},
    packageObject: Package,
): boolean => {
    return (
        (entryType === balloonEntryType.weightSku || entryType === balloonEntryType.bagSku) &&
        key.startsWith(DeliveryStatusBopis.CANCELLED) &&
        !isBouquetCancelled(bopisGrouped, packageObject)
    )
}

/**
 * Adds status and replaces package items with grouped items (extended) to save contract for Order Details
 * @param { Package[] } packages - packages data
 * @param { Record } bopisGrouped - grouped items data
 * @returns {CartOrderEntries[]} packages with remapped items
 */
export const getPackagesWithReplacedItemsByGroupedItems = (
    packages: Package[] = [],
    bopisGrouped: Record<string, unknown> = {},
): Package[] => {
    return (
        packages.map(packageObject => {
            const groupedItems: CartOrderEntries[] = []

            packageObject.items.forEach(packageItem => {
                Object.keys(bopisGrouped).forEach((key: string) => {
                    const groupData = bopisGrouped[key] as CartOrderEntriesWithFilterVal[]
                    groupData?.forEach(groupedItem => {
                        if (
                            groupedItem.entryNumber === packageItem.entryNumber &&
                            !shouldIncludeItemInPackage(
                                groupedItem.balloonEntryType ?? emptySpace,
                                key,
                                bopisGrouped,
                                packageObject,
                            )
                        ) {
                            groupedItems.push(groupedItem)
                        }
                    })
                })
            })

            // Remove items with different status
            const status = groupedItems[MagicNumber.ZERO]?.status
            const itemsWithSameStatus = groupedItems?.filter(items => items.status === status)

            return {
                ...packageObject,
                status: status,
                items: itemsWithSameStatus,
            }
        }) || []
    )
}

/**
 * Reduce items which are present in package object
 * @param {Record} bopisGrouped bopisGrouped
 * @param {Package[]} packagesWithGroupedItems packagesWithGroupedItems
 * @returns {Record} groupedItemsWithoutPackageItems
 */
export const getGroupedItemsWithoutPackageItems = (
    bopisGrouped: Record<string, unknown> = {},
    packagesWithGroupedItems: Package[],
): Record<string, unknown> => {
    if (isArrayEmpty(packagesWithGroupedItems)) {
        return bopisGrouped
    }

    const groupedItemsWithoutPackageItems: Record<string, unknown> = {}

    Object.keys(bopisGrouped)?.forEach((key: string) => {
        const filtered = [] as CartOrderEntries[]
        const groupData = bopisGrouped[key] as CartOrderEntries[]
        groupData?.forEach((entry: CartOrderEntries) => {
            let isFound = false
            packagesWithGroupedItems?.forEach(data => {
                if (data.items.some(item => item.entryNumber === entry.entryNumber && item.status === entry.status)) {
                    isFound = true
                }
            })
            if (!isFound) {
                filtered.push(entry)
            }
        })
        if (isArrayNotEmpty(filtered)) {
            groupedItemsWithoutPackageItems[key] = filtered
        }
    })

    return groupedItemsWithoutPackageItems
}
