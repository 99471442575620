import { replaceStrWithDynamicVal } from '../../utils'

/**
 * Generates time text based on page type
 * @param {boolean} isPDPPage
 * @param {boolean} closed
 * @param {string} opensLabel
 * @param {string} closesLabel
 * @param {string} openingTime
 * @param {string} closingTime
 * @returns {string} The generated time text.
 */
export const getStoreTimeLabel = (
    isPDPPage: boolean,
    closed: boolean,
    opensLabel: string,
    closesLabel: string,
    openingTime: string,
    closingTime: string,
): string => {
    return isPDPPage
        ? closed
            ? replaceStrWithDynamicVal(opensLabel, openingTime)
            : replaceStrWithDynamicVal(closesLabel, closingTime)
        : closed
        ? `${opensLabel} ${openingTime}`
        : `${closesLabel} ${closingTime}`
}
