import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/swiper-react'
import { Navigation, Pagination, Keyboard, A11y } from 'swiper/modules'
import { CarouselListProps } from '../Carousel/Carousel.type'
import { BREAKPOINTS, PREFIX } from '../config'
import CarouselNavigationArrow from '../Carousel/CarouselNavigationArrow'
import { carouselSettings } from '../Carousel/Carousel.constant'
import { BreakpointKeys, Size } from '../../types'

/**
 * CarouselSwiper component
 * @param {CarouselListProps} props props required for CarouselSwiper component
 * @returns {JSX.Element} returns CarouselSwiper component
 */
const CarouselSwiper: React.FC<CarouselListProps> = props => {
    const {
        carouselList,
        path,
        a11yCarouselNextLabel,
        a11yCarouselPreviousLabel,
        breakpointSettings,
        carouselClassName = '',
        sliderRef,
        dynamicBullet,
        arrowBottom,
        infiniteScrolling,
        centerInsufficientSlides,
        carouselCallbackHandler,
    } = props

    const { mobileMinWidth, tabletPortrait, tabletMaxWidth, desktopLarg } = BREAKPOINTS

    /**
     * Unique id for carousel navigation arrows.
     * This is required when not using the built-in navigation arrows.
     * Since we have custom nav arrows, by providing unique id in nextEl and preEl classes,
     * Swiper is able to control only the carousel with matching id.
     * This prevents multiple carousel instances on the same page from controlling each other
     */
    const uniqueId = (Math.random() + 1).toString(36).substring(7)

    /**
     * Spacing value based on breakpoint
     * @param {BreakpointKeys} breakpointKey - breakpoint keys i.e. xs, sm, md, lg
     * @returns {number} returns spacing value if provided or default card spacing
     */
    const breakpointSpacing = (breakpointKey: BreakpointKeys): number => {
        let spacing: number

        if (breakpointSettings[breakpointKey].spacing) {
            spacing = breakpointSettings[breakpointKey].spacing
        }
        return spacing || carouselSettings.cardSpacing
    }

    // carousel settings
    const settings = {
        slidesPerView: breakpointSettings.xs.slidesToShow,
        slidesPerGroup: breakpointSettings.xs.slidesPerGroup,
        centerInsufficientSlides: centerInsufficientSlides ?? true,
        spaceBetween: breakpointSpacing(Size.XS),
        pagination: {
            clickable: true,
            dynamicBullets: dynamicBullet.show,
            dynamicMainBullets: dynamicBullet.count,
        },
        navigation: {
            nextEl: `.${PREFIX}-carousel-swiper--next--${uniqueId}`,
            prevEl: `.${PREFIX}-carousel-swiper--prev--${uniqueId}`,
            disabledClass: carouselSettings.disabledClass,
        },
        keyboard: {
            enabled: true,
        },
        loop: infiniteScrolling || false,
        breakpoints: {
            // 375 - 767
            [mobileMinWidth]: {
                slidesPerView: breakpointSettings.xs.slidesToShow,
                slidesPerGroup: breakpointSettings.xs.slidesPerGroup,
                spaceBetween: breakpointSpacing(Size.XS),
            },
            // 768 - 1279
            [tabletPortrait]: {
                slidesPerView: breakpointSettings.sm.slidesToShow,
                slidesPerGroup: breakpointSettings.sm.slidesPerGroup,
                spaceBetween: breakpointSpacing(Size.SMALL),
            },
            // 1280 - 2559
            [tabletMaxWidth + 1]: {
                slidesPerView: breakpointSettings.md.slidesToShow,
                slidesPerGroup: breakpointSettings.md.slidesPerGroup,
                spaceBetween: breakpointSpacing(Size.MEDIUM),
            },
            // 2560 and above
            [desktopLarg]: {
                slidesPerView: breakpointSettings.lg.slidesToShow,
                slidesPerGroup: breakpointSettings.lg.slidesPerGroup,
                spaceBetween: breakpointSpacing(Size.LARGE),
            },
        },
        /**
         * callback function after swiper initialization
         */
        onAfterInit: () => {
            carouselCallbackHandler && setTimeout(carouselCallbackHandler)
        },
        modules: [Pagination, Navigation, Keyboard, A11y],
    }
    const classChevronArrow = arrowBottom ? `${PREFIX}-carousel-swiper__arrowBottom` : ''

    return (
        <div className={`${carouselClassName} ${PREFIX}-carousel-swiper ${classChevronArrow}`}>
            <Swiper {...settings} ref={sliderRef}>
                {carouselList.map((item: JSX.Element, index: number) => (
                    <SwiperSlide key={index}>{item}</SwiperSlide>
                ))}
            </Swiper>
            {/* eslint-disable @typescript-eslint/no-unsafe-member-access */}
            <CarouselNavigationArrow
                ariaLabel={a11yCarouselNextLabel}
                className={`${PREFIX}-carousel-swiper--next ${PREFIX}-carousel-swiper--next--${uniqueId} ${
                    sliderRef?.current?.swiper?.isEnd ? carouselSettings.disabledClass : ''
                }`}
                type="ct-chevron-right"
                size="md"
                path={path}
            />
            <CarouselNavigationArrow
                ariaLabel={a11yCarouselPreviousLabel}
                className={`${PREFIX}-carousel-swiper--prev ${PREFIX}-carousel-swiper--prev--${uniqueId} ${
                    sliderRef?.current?.swiper?.isBeginning ? carouselSettings.disabledClass : ''
                }`}
                type="ct-chevron-left"
                size="md"
                path={path}
            />
        </div>
    )
}

export default CarouselSwiper
