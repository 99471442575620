/**
 * WAI-ARIA states and properties in types
 * See {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes|WAI-ARIA}
 * @typedef { Object } IA11y
 * @property { string } role - name of a role in the ARIA specification
 * @property { string } label - defines a string value that labels an interactive item
 * @property { string } labelledBy - item that labels the item it is applied to
 * @property { string } describedBy - describes the item on which the attribute is set
 * @property { string } controls - identifies the item whose contents or presence are controlled by the item on which this attribute is set
 * @property { string } description - definition of a string value that describes or annotates the current item
 * @property { boolean } readonly - item is not editable, but is otherwise operable
 * @property { boolean } checked - "checked" state of checkboxes, radio, buttons, etc...
 * @property { boolean } expanded - if a component is expanded/collapsed. Also includes a check if elements are displayed or hidden (ex: show/hide menu)
 * @property { boolean } hidden - item is exposed to an accessibility API
 * @property { boolean } disabled - item is perceivable but disabled, so it is not editable or otherwise operable
 * @property { boolean } selected - item is selected or not
 */
export interface IA11y {
    role?: string
    label?: string
    labelledBy?: string
    describedBy?: string
    controls?: string
    description?: string
    readonly?: boolean
    checked?: boolean
    expanded?: boolean
    hidden?: boolean
    disabled?: boolean
    selected?: boolean
}

/**
 * Enum for roles
 * See List {@link https://www.w3.org/TR/wai-aria-1.0/roles|roles}
 */
export enum Role {
    BUTTON = 'button',
    ALERT = 'alert',
    CHECKBOX = 'checkbox',
    DIALOG = 'dialog',
    LINK = 'link',
    MENUITEM = 'menuitem',
    MENUCHECKBOX = 'menuitemcheckbox',
    MENURADIO = 'menuitemradio',
    OPTION = 'option',
    PROGRESSBAR = 'progressbar',
    RADIO = 'radio',
    SCROLLBAR = 'scrollbar',
    SPINBUTTON = 'spinbutton',
    STATUS = 'status',
    TAB = 'tab',
    TABPANEL = 'tabpanel',
    TABLIST = 'tablist',
    TEXTBOX = 'textbox',
    TOOLTIP = 'tooltip',
}

/**
 * Enum for Events
 */
export enum Events {
    CLICK = 'click',
    KEYDOWN = 'keydown',
}
