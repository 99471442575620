import { AxiosPromise } from 'axios'

import { BaseService } from '../base.service'
import { getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import { CategoryResponseById } from '../../redux/models/category.interface'

const environment = getEnvironment()
const httpClient = getHttpClient()

/**
 * Function to trigger header navigation.
 * @class
 * @extends BaseService
 */
class HeaderNavigationService extends BaseService {
    /**
     * Function to fetch categories.
     * TODO: remove the optional type once actual api is implemented.
     * @param {string} categoryId
     * @param {boolean} scope
     * @param {string} level - hierarchy level of the desired category structure
     * @param {string} relatedCategoriesParam - related categories param
     * @returns {AxiosPromise} promise object for category API in APIM
     */
    static getCategories(
        categoryId?: string,
        scope?: string,
        level?: string,
        relatedCategoriesParam?: string,
    ): AxiosPromise<CategoryResponseById> {
        return httpClient.apiGet(
            this.createCategoryURL(categoryId || '', scope || '', level || '', relatedCategoriesParam || '').toString(),
        )
    }

    /**
     * Create url for the category api.
     * @param {string} categoryId - category
     * @param {string} sitemap - category
     * @param {string} level - hierarchy level of the desired category structure
     * @param {string} relatedCategoriesParam - related categories param
     * @returns {URL} category API URL in APIM
     */
    static createCategoryURL(categoryId: string, sitemap: string, level: string, relatedCategoriesParam: string): URL {
        const locale = HeaderNavigationService.language

        const {
            API_BASE_URL,
            API_ENDPOINTS: { getCategories },
        } = environment
        const categoryUrl = categoryId ? `/${categoryId}` : ''
        const sitemapParam = sitemap ? `&scope=${sitemap}` : ''
        const levelParam = level ? `&level=${level}` : ''
        const includeRelatedCategoriesParam = relatedCategoriesParam ? `&${relatedCategoriesParam}=true` : ''

        return new URL(
            `${API_BASE_URL}${getCategories}${categoryUrl}?lang=${locale}${sitemapParam}${levelParam}${includeRelatedCategoriesParam}`,
        )
    }
}

export { HeaderNavigationService }
export default HeaderNavigationService
