import { ActionType, getType } from 'typesafe-actions'

import { setOgImageTag } from '../actionCreators/ogImageTag.actionCreators'
import * as actions from '../actionCreators/ogImageTag.actionCreators'
import { RenderedComponentData } from '../models/commonContent.interface'
import { GlobalPropsHelper } from '../../analytics/helpers/globalProps'
import { EmptyActionType } from '../models/globalData.interface'

type Action = ActionType<typeof actions> | EmptyActionType

export interface OgImageTagState {
    renderedComponents: RenderedComponentData
}
const initialState: OgImageTagState = {
    renderedComponents: {} as RenderedComponentData,
}

export const ogImageTagReducer = (state: OgImageTagState = initialState, action: Action): OgImageTagState => {
    if (action.type === getType(setOgImageTag) && Object.keys(state.renderedComponents).length === 0) {
        const globalProps = new GlobalPropsHelper()
        const seoImageData = globalProps.readSeoImageConfig()
        const configuredComponents = seoImageData && seoImageData.split(';')
        if (configuredComponents.includes(action.payload.component)) {
            return { ...state, renderedComponents: action.payload }
        }
    }
    return state
}
