// External Integrations
import ReactCMSComp from 'react-cms-components/index'
import { PayloadAction } from 'typesafe-actions'

import { unregister } from './serviceWorker'
import { setCommonAPIContent, setProductListABTest } from './redux/actions'
import store from './store'
import { setCommonAPIContentSuccess } from './redux/actionCreators'
import handleAppInit from './appInit/index'
import { Component } from './global.type'
import { renderAEMComponents, renderDynamicReactComponent } from './helpers/cms.helper'
import injectStoreInInterceptor from './redux/utils/interceptors/injectStoreInInterceptor'
import handleRedirectionWithSessionVerification from './appInit/handleRedirectionWithSessionVerification'
import { ComponentType } from './helpers/cms.helper.type'
import sessionStorageService from './utils/sessionStorageService'

injectStoreInInterceptor.setStoreValue(store)

const componentArray = ReactCMSComp.return({
    componentList: [
        ...(document.querySelectorAll('[data-component-prioritized]') as unknown as Array<Element>),
        ...(document.querySelectorAll(
            '[data-component]:not([data-component-prioritized])',
        ) as unknown as Array<Element>),
    ] as unknown as NodeListOf<Element>,
})

// For rendering AEM components
renderAEMComponents(document)
const fetchCommonAPIContentData = () => {
    const commonAPIContent = sessionStorageService.getItem('common_api_props')
    if (!commonAPIContent) {
        store.dispatch(setCommonAPIContent() as unknown as PayloadAction<'SET_COMMON_API_START', null>)
    } else {
        store.dispatch(setCommonAPIContentSuccess(JSON.parse(commonAPIContent)))
    }
}

/**
 * function to fetch product list for A/B Test on PDP's
 */
const fetchProductListForABTest = () => {
    store.dispatch(setProductListABTest() as unknown as PayloadAction<'SET_PRODUCT_LIST_AB_TEST', never>)
}

/*
    This function execution will hide all content on the page and redirect user to CRP or CLP page
    in case of user is in choose loyalty registration option -> registration flow.
    or in case of user is in suceeaafull password reset -> login flow.
    This function need to be executed before any logic on the page to do a quick redirect to CRP.
*/
handleRedirectionWithSessionVerification()

if (componentArray.length > 0) {
    // TODO: this if statement need to be more specific to component rendering only
    // Fetch common content
    fetchCommonAPIContentData()
    fetchProductListForABTest()
    document.addEventListener('DOMContentLoaded', handleAppInit)
    componentArray.forEach((component: Component) => {
        renderDynamicReactComponent(component as ComponentType)
    })
}

unregister()
