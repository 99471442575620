import { ActionType, getType } from 'typesafe-actions'
import { vehicleSelectorTabsAction, vehicleSelectorAction } from '../actionCreators/automotiveBuyBox.actionCreators'

import * as actions from '../actionCreators/automotiveBuyBox.actionCreators'

type Action = ActionType<typeof actions>

export interface VehicleSelectorTabs {
    needToRenderTabs: boolean
    selectedTabLabel: string
}

export interface VehicleSelector {
    tabs?: VehicleSelectorTabs
    sendSkuCodeInApiCall?: boolean
}

export interface AutomotiveBuyBoxState {
    vehicleSelector: VehicleSelector
}

export const initialState: AutomotiveBuyBoxState = {
    vehicleSelector: {
        tabs: {
            needToRenderTabs: false,
            selectedTabLabel: '',
        },
        sendSkuCodeInApiCall: false,
    },
}

export const automotiveBuyBoxReducer = (
    state: AutomotiveBuyBoxState = initialState,
    action: Action,
): AutomotiveBuyBoxState => {
    switch (action.type) {
        case getType(vehicleSelectorTabsAction):
            return { ...state, vehicleSelector: { ...state.vehicleSelector, tabs: action.payload } }
        case getType(vehicleSelectorAction):
            return {
                ...state,
                vehicleSelector: {
                    ...state.vehicleSelector,
                    ...action.payload,
                },
            }
        default:
            return state
    }
}
