import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { ShowLessMoreButtonProps } from './ShowLessMoreButton.type'
import Button from '../Button'
import Icon from '../Icon'

/**
 * ShowMoreLessButton component
 * @return {JSX.Element} returns Button with Icon
 */

const ShowMoreLessButton: React.FC<ShowLessMoreButtonProps> = props => {
    const { totalResults, setShowAllRows, showAllRows, listData, showMoreThreshold, buttonRef, showLessLabel } = props

    const componentClassName = `${PREFIX}-show-more-btn`

    return (
        totalResults.length > showMoreThreshold && (
            <div className={`${componentClassName}__view-link`}>
                <Button
                    ariaExpanded={listData.link === showLessLabel}
                    ref={buttonRef}
                    type="tertiary"
                    onClick={() => setShowAllRows(!showAllRows)}>
                    <span className={`${componentClassName}__view-label`}>{listData.link}</span>
                    <Icon size="md" type={listData.icon} />
                </Button>
            </div>
        )
    )
}

ShowMoreLessButton.propTypes = {
    totalResults: PropTypes.array,
    setShowAllRows: PropTypes.func,
    showAllRows: PropTypes.bool,
    listData: PropTypes.shape({
        link: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
    }).isRequired,
    showMoreThreshold: PropTypes.number,
}

export default ShowMoreLessButton
