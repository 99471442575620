import { createAction } from 'typesafe-actions'

import {
    START_SPINNER_WITH_COUNTER,
    SET_SHOW_SPINNER,
    STOP_SPINNER_WITH_COUNTER,
} from '../types/spinner/spinner.actionTypes.constant'
import { ShowSpinner } from '../reducers/spinner.reducer'
import { SpinnerTypes } from '@nl/lib'

export const setShowSpinner = createAction(SET_SHOW_SPINNER, (show: boolean, type: string = SpinnerTypes.DEFAULT) => {
    return { show, type }
})<ShowSpinner>()
export const startSpinnerWithCounter = createAction(START_SPINNER_WITH_COUNTER)<void>()
export const stopSpinnerWithCounter = createAction(STOP_SPINNER_WITH_COUNTER)<void>()
