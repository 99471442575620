import { ComponentPolicy } from './akamaiPolicy.type'

export const componentPolicies: Record<string, ComponentPolicy> = {
    'edge-to-edge-banner': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 375,
                hei: 176,
                qlt: 100,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 960,
                hei: 450,
            },
        },
    },
    'hero-banner-product': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 375,
                hei: 368,
                qlt: 100,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 902,
                hei: 520,
            },
        },
    },
    'hero-banner-lifestyle': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 375,
                hei: 368,
                qlt: 100,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 1440,
                hei: 520,
            },
        },
    },
    'broad-banner': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 343,
                hei: 197,
                qlt: 100,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 732,
                hei: 420,
            },
        },
    },
    'slim-banner': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 343,
                hei: 146,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 586,
                hei: 250,
            },
        },
    },
    'up-tile-banner': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 342,
                hei: 342,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 708,
                hei: 708,
            },
        },
    },
    'stacked-banner': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 342,
                hei: 180,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 952,
                hei: 500,
            },
        },
    },
    'broad-promo-banner': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 342,
                hei: 150,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 294,
                hei: 420,
            },
        },
    },
    'featured-list-three-tile': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 142,
                hei: 142,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 200,
                hei: 200,
            },
        },
    },

    'product-card': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 142,
                hei: 142,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 200,
                hei: 200,
            },
        },
    },
    'product-card-tall': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 142,
                hei: 170,
            },
        },
        sm: {
            name: 'whresize',
            dimension: {
                wid: 216,
                hei: 260,
            },
        },
        mmd: {
            name: 'whresize',
            dimension: {
                wid: 260,
                hei: 312,
            },
        },
        smd: {
            name: 'whresize',
            dimension: {
                wid: 300,
                hei: 360,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 318,
                hei: 382,
            },
        },
        lg: {
            name: 'whresize',
            dimension: {
                wid: 340,
                hei: 408,
            },
        },
    },
    'product-card-tall2': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 150,
                hei: 150,
            },
        },
        sm: {
            name: 'whresize',
            dimension: {
                wid: 216,
                hei: 216,
            },
        },
        mmd: {
            name: 'whresize',
            dimension: {
                wid: 260,
                hei: 260,
            },
        },
        smd: {
            name: 'whresize',
            dimension: {
                wid: 300,
                hei: 300,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 318,
                hei: 318,
            },
        },
        lg: {
            name: 'whresize',
            dimension: {
                wid: 340,
                hei: 340,
            },
        },
    },

    'cart-item': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 135,
                hei: 135,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 185,
                hei: 185,
            },
        },
    },

    'offer-details': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 342,
                hei: 200,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 532,
                hei: 239,
            },
        },
    },

    'featured-list-four-tile': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 342,
                hei: 200,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 342,
                hei: 200,
            },
        },
    },
    'brand-stack-view': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 164,
                hei: 96,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 220,
                hei: 120,
            },
        },
    },
    'category-featured-list': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 134,
                hei: 134,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 180,
                hei: 180,
            },
        },
    },
    'sticky-buy-box': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 294,
                hei: 294,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 294,
                hei: 294,
            },
        },
    },
    'package-landing': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 100,
                hei: 100,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 144,
                hei: 144,
            },
        },
    },
    'link-master-card': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 91,
                hei: 67,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 91,
                hei: 67,
            },
        },
    },
    'transaction-history': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 60,
                hei: 60,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 60,
                hei: 60,
            },
        },
    },
    'my-account-rewards': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 344,
                hei: 155,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 300,
                hei: 135,
            },
        },
    },
    'dynamic-category-featured-list': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 164,
                hei: 122,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 268,
                hei: 200,
            },
        },
    },
    'offers-card': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 256,
                hei: 115,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 320,
                hei: 144,
            },
        },
    },
    'banner-logo': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 64,
                hei: 24,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 96,
                hei: 36,
            },
        },
    },
    'post-purchase-triangle-dual-card': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 131,
                hei: 89,
            },
        },
        sm: {
            name: 'whresize',
            dimension: {
                wid: 226,
                hei: 161,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 284,
                hei: 193,
            },
        },
    },

    'post-purchase-triangle-card': {
        xs: {
            name: 'whresize',
            dimension: {
                wid: 143,
                hei: 90,
            },
        },
        sm: {
            name: 'whresize',
            dimension: {
                wid: 210,
                hei: 133,
            },
        },
        md: {
            name: 'whresize',
            dimension: {
                wid: 234,
                hei: 148,
            },
        },
    },
}

export enum imageBreakpoints {
    xs = 'xs',
    sm = 'sm',
    smd = 'smd',
    mmd = 'mmd',
    md = 'md',
    lg = 'lg',
}
