import React, { ReactElement, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { calculateAndSetPercentage, getStepPosition, getAllStepPositions } from '../../helpers/ProgressBar.helper'
import { getSafePercent, magicNumber } from '../../utils'
import { PREFIX } from '../config'
import { ProgressBarProps } from './ProgressBar.type'
import { defaultFilledColor, defaultUnfilledColor } from './ProgressBar.constant'

/**
 * Progress Bar component shows a progress bar consisting of various steps.
 * @param {ProgressBarProps} props - children, unfilledBackground, filledBackground, height, text, sidePadding, currentStep
 * unfilledBackground - background color of complete bar
 * filledBackground - background color till completed steps
 * thickness - hight or width of the progress bar depending on the direction
 * children - Children of component
 * sidePadding - Padding around before first and after last steps
 * currentStep - index of the currently active step
 * vertical - change ProgressBar direction to vertical
 * nextStepProgress - display progress for next step
 * nextStepStyleOverride - styles for overriding default styles of the next step progress
 * @returns {JSX.Element} returns Progress component
 */
const ProgressBar: React.FC<ProgressBarProps> = props => {
    const {
        children,
        unfilledBackground = defaultUnfilledColor,
        filledBackground = defaultFilledColor,
        thickness = 'auto',
        currentStep = 0,
        sidePadding = 0,
        vertical,
        nextStepProgress,
        nextStepStyleOverride = {},
    } = props

    const [stepPercentage, setStepPercentage] = useState(0)

    // Gets the positions of all the Step components in Progress Bar
    const stepPositions = getAllStepPositions(children.length, sidePadding)

    useEffect(() => {
        calculateAndSetPercentage(children.length, currentStep, sidePadding, setStepPercentage)
    }, [currentStep, sidePadding, children.length])

    const safePercent = getSafePercent(stepPercentage)

    const lastStep = children.length - magicNumber.ONE === currentStep

    const verticalClass = vertical ? `${PREFIX}-progress-bar--vertical` : ''
    const sizeProp = vertical ? 'width' : 'height'
    const progressionSizeProp = vertical ? 'height' : 'width'
    const progressionPositionProp = vertical ? 'top' : 'left'
    const singleStepSize = stepPositions[magicNumber.ONE]

    return (
        <div
            className={`${PREFIX}-progress-bar ${verticalClass}`}
            data-testid={`${PREFIX}-progress-bar`}
            style={{ background: unfilledBackground, [sizeProp]: thickness }}>
            {React.Children.map(children, (step, index) => {
                const position =
                    stepPositions.length > 0
                        ? stepPositions[index]
                        : getStepPosition(React.Children.count(children), index)

                return React.cloneElement(step as ReactElement, {
                    completed: position < safePercent,
                    position,
                    index: index,
                    vertical,
                })
            })}

            <div
                className={`${PREFIX}-progress-bar__progression`}
                data-testid={`${PREFIX}-progress-bar__progression`}
                style={{
                    background: filledBackground,
                    [sizeProp]: thickness,
                    [progressionSizeProp]: `${safePercent}%`,
                }}
            />
            {nextStepProgress && !lastStep && (
                <div
                    className={`${PREFIX}-progress-bar__progression`}
                    data-testid={`${PREFIX}-progress-bar__progression`}
                    style={{
                        background: filledBackground,
                        [sizeProp]: thickness,
                        [progressionSizeProp]: `${singleStepSize}%`,
                        [progressionPositionProp]: `${safePercent}%`,
                        ...nextStepStyleOverride,
                    }}
                />
            )}
        </div>
    )
}

ProgressBar.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
    unfilledBackground: PropTypes.string,
    filledBackground: PropTypes.string,
    thickness: PropTypes.string,
    sidePadding: PropTypes.number,
    currentStep: PropTypes.number,
    vertical: PropTypes.bool,
    nextStepProgress: PropTypes.bool,
    nextStepStyleOverride: PropTypes.object,
}

ProgressBar.displayName = 'ProgressBar'

export { ProgressBar }
