import { Dispatch } from 'redux'
import { AxiosResponse } from 'axios'
import { isOtpErrorCode, OtpErrorType } from '@nl/lib'

import {
    setCheckoutContact,
    setCheckoutPickup,
    setCheckoutShipping,
    setCheckoutPayment,
    setShowSpinner,
    setPaymentFailureError,
    setCheckoutContactUpdateStatus,
    resetCheckoutValidationsAction,
    setCheckoutShippingStatus,
    updateCartDataSuccessAction,
    setPaymentSuccess,
    setCheckoutLoyaltyInfo,
    setCheckoutAppointment,
    setPaymentDetailsError,
} from '../actionCreators'
import {
    successStatus,
    checkoutContactUrl,
    checkoutDeliveryUrl,
    checkoutPickupUrl,
    checkoutPaymentUrl,
    PaymentDetailsErrorCodes,
} from '../../components/Checkout/Checkout.constant'
import {
    CheckoutShippingInfo,
    CheckoutPickupInfo,
    CheckoutContactInfo,
    CheckoutPaymentInfo,
    CheckoutActionCallbacks,
    CheckoutLoyaltyInfo,
    CheckoutAppointmentInfo,
} from '../models/checkout.interface'
import { drawerInteractionService } from '../../services/checkoutService/drawerInteraction.service'
import { RootState } from '../reducers'
import appCacheService from '../../utils/appCacheService'
import { enableDestructOnUndefinedData } from '../../utils/PDP/enableDestructOnUndefinedData.utils'
import { isOneTimeCartForAuthUser } from '../../utils/isOneTimeCartForAuthUser.utils'
import { InitPaymentPayload } from '../../components/Checkout/PaymentInformation/MasterPassPayment.type'
import { CheckoutService } from '../../services/checkoutService'
import { AxiosCartResponseErrorDTO, CartResponseErrorData, CartResponseErrorDTO } from '../models/cart.interface'
import getFilteredCartItems from '../../utils/getFilteredCartItems'
import { getCartItemsData } from './cart.action'
import { componentList } from '../../globalConstants'
import { initOtpFlow, setOtpErrCode } from '../actionCreators/otp.actionCreators'

/**
 * function to set redux state after user enters data in contact information
 * @param {checkoutContactInfo}datapost
 * @param {string}guid
 * @return {Promise<void>}
 * @param {boolean} showSpinner
 */
export const checkoutContact =
    (datapost: CheckoutContactInfo, guid: string, showSpinner = false, actionCallbacks?: CheckoutActionCallbacks) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const isOneTimeCartFlag = isOneTimeCartForAuthUser(
            enableDestructOnUndefinedData(getState().userProfile),
            enableDestructOnUndefinedData(getState().sharedCart),
        )
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        return drawerInteractionService
            .drawerApiSave(
                { ...datapost, phone: datapost.phone.replace(/-/g, '') },
                guid,
                checkoutContactUrl,
                undefined,
                isOneTimeCartFlag,
            )
            .then((response: AxiosResponse) => {
                if (response.status === successStatus) {
                    dispatch(setCheckoutContact(datapost))
                    dispatch(setCheckoutContactUpdateStatus({ errCode: 'No Error' } as CartResponseErrorData))
                }
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                dispatch(setCheckoutContactUpdateStatus(err.response?.data as CartResponseErrorData))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
                actionCallbacks?.finallyCallback && actionCallbacks.finallyCallback()
            })
    }

/**
 * function to set redux state after user enters data in shipping information
 * @param {checkoutShippingInfo} datapost checkout shipping info
 * @param {string} guid cartid
 * @returns {Promise<void>} void
 */
export const checkoutShipping =
    (datapost: CheckoutShippingInfo, guid: string) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const isOneTimeCartFlag = isOneTimeCartForAuthUser(
            enableDestructOnUndefinedData(getState().userProfile),
            enableDestructOnUndefinedData(getState().sharedCart),
        )
        const canadaPostCountry = getState().commonContent.commonAPIContentAvailable.canadapost?.Country
        const updatedDataPost = { ...datapost, updateMyProfile: false, country: canadaPostCountry }
        !updatedDataPost.addressLineTwo && delete updatedDataPost.addressLineTwo
        delete updatedDataPost.Country
        delete updatedDataPost.next
        delete updatedDataPost.id
        const { isStoreSharedCart, oneTimeCartStore } = enableDestructOnUndefinedData(
            getState()?.sharedCart?.cartConsuming?.storeSharedCart,
        )
        const selectedPreferredStoreId =
            getState().storeDetails.selectedPreferredStoreId || appCacheService.preferredStoreId.get()
        const storeId = isStoreSharedCart ? oneTimeCartStore?.id : selectedPreferredStoreId

        drawerInteractionService
            .drawerApiSave(updatedDataPost, guid, checkoutDeliveryUrl, storeId as string, isOneTimeCartFlag)
            .then(response => {
                dispatch(setCheckoutShipping(datapost))
                dispatch(
                    setCheckoutShippingStatus({
                        isUpdateSuccess: true,
                        errorResponse: {} as unknown as CartResponseErrorDTO,
                    }),
                )
                const cartFilteredData = getFilteredCartItems(response.data)
                dispatch(updateCartDataSuccessAction(cartFilteredData))
            })
            // TODO have to handle the error handling
            .catch((err: AxiosCartResponseErrorDTO) => {
                dispatch(
                    setCheckoutShippingStatus({
                        isUpdateSuccess: false,
                        errorResponse: err?.response as unknown as CartResponseErrorDTO,
                    }),
                )
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * function to reset checkout validation error messages
 * @return {Dispatch}
 */
export const resetCheckoutValidations =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetCheckoutValidationsAction())
    }

/**
 * function to set redux state after user enters data in pickup information
 * @param {checkoutPickupInfo}datapost
 * @param {string}guid
 * @return {Promise<void>}
 * @param {boolean} showSpinner
 * @param {CheckoutAppointmentInfo} checkoutAppointmentInfo
 */
export const checkoutPickup =
    (
        datapost: CheckoutPickupInfo,
        guid: string,
        showSpinner = false,
        checkoutAppointmentInfo?: CheckoutAppointmentInfo,
    ) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const isOneTimeCartFlag = isOneTimeCartForAuthUser(
            enableDestructOnUndefinedData(getState().userProfile),
            enableDestructOnUndefinedData(getState().sharedCart),
        )
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        drawerInteractionService
            .drawerApiSave({ ...datapost }, guid, checkoutPickupUrl, undefined, isOneTimeCartFlag)
            .then((response: AxiosResponse) => {
                if (response.status === successStatus) {
                    dispatch(setCheckoutPickup(datapost))
                    if (checkoutAppointmentInfo?.isPickupAllItemsTogether) {
                        const dataSet = {
                            ...checkoutAppointmentInfo,
                            appointmentContact: datapost?.orderPickupInPerson,
                        } as CheckoutAppointmentInfo
                        dispatch(setCheckoutAppointment(dataSet))
                    }
                }
            })
            // TODO have to handle the error handling
            .catch(err => console.warn(err))
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * function to set redux state after user enters data in payment information
 * @param {checkoutPaymentInfo} datapost
 * @param {string} guid
 * @param {boolean} showSpinner
 * @param {InitPaymentPayload | null} initPaymentPayload
 * @param {number} initPaymentVersion
 * @param {boolean} isAuthenticatedUser
 * @param {boolean} isHideSpinnerAtExit
 * @return {Promise<void>}
 */
export const checkoutPayment =
    (
        datapost: CheckoutPaymentInfo,
        guid: string,
        showSpinner = false,
        initPaymentPayload: InitPaymentPayload | null = null,
        initPaymentVersion: number,
        isAuthenticatedUser = false,
        isHideSpinnerAtExit = true,
    ) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        !datapost.billingAddress?.line2 && delete datapost.billingAddress?.line2
        const isOneTimeCartFlag = isOneTimeCartForAuthUser(
            enableDestructOnUndefinedData(getState().userProfile),
            enableDestructOnUndefinedData(getState().sharedCart),
        )
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        drawerInteractionService
            .drawerApiSave({ ...datapost }, guid, checkoutPaymentUrl, undefined, isOneTimeCartFlag)
            .then((response: AxiosResponse) => {
                if (response.status === successStatus) {
                    dispatch(setCheckoutPayment(datapost))
                    dispatch(setPaymentSuccess(true))
                    // OCCP 21565 - design solution proposed to call init payment post payment details success
                    initPaymentPayload &&
                        initPaymentPayload.cartId &&
                        CheckoutService.hppToken(
                            initPaymentPayload.cartId,
                            initPaymentPayload.storeId,
                            initPaymentVersion,
                            false,
                            '',
                            initPaymentPayload.semafoneEnabled ? 'Y' : '',
                            isOneTimeCartFlag,
                        )
                    getCartItemsData(
                        guid,
                        '',
                        false,
                        isOneTimeCartFlag,
                        isAuthenticatedUser,
                        true,
                        isHideSpinnerAtExit,
                    )(dispatch, getState)
                }
            })
            // TODO have to handle the error handling
            .catch((err: AxiosCartResponseErrorDTO) => {
                if (isOtpErrorCode(err?.response?.data?.errCode)) {
                    dispatch(
                        initOtpFlow({
                            vToken: err.response?.data.vToken as string,
                            component: componentList.checkout.paymentInfo,
                            email: err.response?.data.otpEmail as string,
                        }),
                    )
                } else if (isOtpErrorCode(err?.response?.data?.errCode, OtpErrorType.ACTION_ERROR)) {
                    dispatch(setOtpErrCode(err.response?.data.errCode as string))
                } else {
                    window.newrelic?.addPageAction?.('JSTRACE:checkoutPaymentFailed', {
                        err,
                    })
                    const errCode = err.response?.data?.errCode as string
                    dispatch(
                        setPaymentDetailsError(
                            Object.values(PaymentDetailsErrorCodes).includes(errCode)
                                ? errCode
                                : PaymentDetailsErrorCodes.paymentDetailsGenericError,
                        ),
                    )
                    dispatch(setPaymentSuccess(false))
                    dispatch(setShowSpinner(false))
                }
            })
    }

/**
 * function to set checkout payment error occurred during place order
 * @param {string} value
 * @return {void}
 */
export const setPaymentError =
    (value: string) =>
    (dispatch: Dispatch): void => {
        dispatch(setPaymentFailureError(value))
    }

/**
 * function to set checkout loyalty card number
 * @param {string} cardNumber
 * @returns {void}
 */
export const checkoutLoyalty =
    (cardNumber: CheckoutLoyaltyInfo) =>
    (dispatch: Dispatch): void => {
        dispatch(setCheckoutLoyaltyInfo(cardNumber))
    }
