import { BreadcrumbLinkData } from '../components/Breadcrumb/Breadcrumb.type'
import { CartModificationsDTO } from '../components/CartFlyout/CartFlyout.type'
import { Offers, SwapOffers } from '../components/OfferCard/OfferCard.type'
import { CurrentPrice } from '../components/ProductGridView/ProductGrid.types'
import { StoreWithAvailability } from '../components/StoreSelectorModal/StoreSelectorModal.type'
import { currencyFormat } from '../globalConstants/global.constant'
import { getCurrentDate, isArrayNotEmpty } from '../utils'

/**
 * get offers param for braze custom events
 * @param {Offers | SwapOffers} selectedOffer selected offer
 * @returns {Offers[]} return offers param for braze custom events
 */
export const getOfferParams = (selectedOffer: Offers | SwapOffers): Record<string, unknown> => {
    return {
        date: getCurrentDate(),
        loyalty_offers_code: selectedOffer?.offerCode,
        loyalty_offers_description: selectedOffer?.details?.bonusDetailedDescription,
        loyalty_offers_name: selectedOffer?.details?.offerShortDesc,
    }
}

/**
 * get braze event source
 * @param {string} bannerId current banner Id
 * @returns {Record<string, string>} return source param
 */
export const getSourceParam = (bannerId: string): Record<string, unknown> => {
    return {
        source: bannerId,
    }
}

/**
 * get category param for braze custom events
 * @param {BreadcrumbLinkData[]} breadcrumbList breadcrumb list
 * @param {string} categoryList selected product
 * @returns {Record<string, unknown>} return product details
 */
export const getCategoryParams = (
    breadcrumbList: BreadcrumbLinkData[],
    categoryList: string,
): Record<string, unknown> => {
    const [categoryL1, categoryL2, categoryL3, categoryL4] = breadcrumbList || []
    const currentBreadcrumbVal = isArrayNotEmpty(breadcrumbList) && breadcrumbList[breadcrumbList.length - 1]

    return {
        category_breadcrumb: categoryList,
        category_id: currentBreadcrumbVal?.categoryId,
        category_name: currentBreadcrumbVal?.label,
        category_l1_id: categoryL1?.categoryId,
        category_l1_name: categoryL1?.label,
        category_l2_id: categoryL2?.categoryId,
        category_l2_name: categoryL2?.label,
        category_l3_id: categoryL3?.categoryId,
        category_l3_name: categoryL3?.label,
        category_l4_id: categoryL4?.categoryId,
        category_l4_name: categoryL4?.label,
    }
}

/**
 * get product param for braze custom events
 * @param {CartModificationsDTO} cartItemData product added to cart
 * @param {CurrentPrice} productPrice product current price
 * @returns {Record<string, unknown>} return product details
 */
export const getBaseProductParams = (
    cartItemData: CartModificationsDTO,
    productPrice?: CurrentPrice,
): Record<string, unknown> => {
    const { code, name, currentPrice } = cartItemData?.entry || {}
    return {
        item_id: code,
        item_name: name,
        price: productPrice?.value ? productPrice?.value : currentPrice?.value,
        currency: currencyFormat,
    }
}

/**
 * get product param for braze custom events
 * @param {CartModificationsDTO} cartItemData product added to cart
 * @param {CurrentPrice} productPrice current price
 * @returns {Record<string, unknown>} return product details
 */
export const getProductParams = (
    cartItemData: CartModificationsDTO,
    productPrice?: CurrentPrice,
): Record<string, unknown> => {
    const { brand } = cartItemData?.entry || {}
    return {
        item_brand: brand?.label,
        quantity: cartItemData?.numberOfItems,
        ...getBaseProductParams(cartItemData, productPrice),
    }
}

/**
 * get store param for braze custom events
 * @param {StoreWithAvailability} store selected store
 * @returns {Record<string, unknown>} return store details
 */
export const getStoreParams = (store: StoreWithAvailability): Record<string, unknown> => {
    return {
        store_name: store?.name,
        store_number: store?.id,
    }
}
