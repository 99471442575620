import { ErrorTypes } from '../config'
import { CartResponseErrorDTO } from '../redux/models/cart.interface'
import { getEstimatedTimeOfSendingProduct, magicNumber } from '@nl/lib'

/**
 * check express delivery error codes if UnavailableExpressEntry or MixedHomeDeliveryModes errors are present
 * @param {CartResponseErrorDTO} placeOrderError - place order error object
 * @returns {boolean} - true if error codes are present
 */
export const checkExpressDeliveryErrCodes = (placeOrderError: CartResponseErrorDTO): boolean => {
    const errCodes = placeOrderError?.data?.errors
    return (
        errCodes?.includes(
            ErrorTypes.HybrisErrorTypes.UnavailableExpressEntry as unknown as { message: string; type: string },
        ) ||
        errCodes?.includes(
            ErrorTypes.HybrisErrorTypes.MixedHomeDeliveryModes as unknown as { message: string; type: string },
        )
    )
}

/**
 * function for getting actual date, if date is overdue, function returns date + 1 day
 * @param {boolean} isOrderInOverdue - is order in overdue
 * @param {string} date - date
 * @returns {string} - date
 */
export const getExpressDeliveryDate = (isOrderInOverdue: boolean, date: string): string => {
    const deliveryDate = new Date(date)
    return isOrderInOverdue
        ? new Date(
              deliveryDate.getTime() +
                  magicNumber.TWENTYFOUR * magicNumber.SIXTY * magicNumber.SIXTY * magicNumber.THOUSAND,
          ).toLocaleString()
        : date
}

/**
 * function for getting estimated time of sending a product if order in time is overdue function returns 24h 0m
 * @param {boolean} isOrderInOverdue - is order in overdue
 * @param {string} estimatedTimeOfSending - estimated time of sending a product
 * @param {string} storeTimeZone - store time zone
 * @returns {number[]} - estimated time of sending a product
 */
export const getOrderInTime = (
    isOrderInOverdue: boolean,
    estimatedTimeOfSending: string,
    storeTimeZone: string,
): number[] => {
    return isOrderInOverdue
        ? [magicNumber.TWENTYFOUR, magicNumber.ZERO]
        : getEstimatedTimeOfSendingProduct(estimatedTimeOfSending, storeTimeZone)
}
