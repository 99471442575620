import { AxiosPromise } from 'axios'
import BaseService from '../base.service'
import { EnvironmentConfig, getEnvironment } from '../../environments'
import { apiMethods } from '../service.constants'

const environment: EnvironmentConfig = getEnvironment()

/**
 * CTMoneyBalanceService Class
 */
class CTMoneyBalanceService extends BaseService {
    /**
     * Creates url for CTMoneyBalance endpoint
     * @param {string} loyaltyCardNumber
     * @return {URL}
     */
    createCTMoneyBalanceURL(loyaltyCardNumber: string): URL {
        const path = `${environment.API_ENDPOINTS.ctMoneyBalance}?loyaltyCardNumber=${loyaltyCardNumber}`
        return CTMoneyBalanceService.createUri(path)
    }

    /**
     * API call to fetch ctMoneyBalance info
     * @param {string} loyaltyCardNumber
     * @return {AxiosPromise}
     */
    fetchCTMoneyBalanceService(loyaltyCardNumber: string): AxiosPromise {
        const url = this.createCTMoneyBalanceURL(loyaltyCardNumber)

        return CTMoneyBalanceService.apiMethod(apiMethods.GET, url.toString())
    }
}

// Create a singleton instance of the service
const ctMoneyBalanceService = new CTMoneyBalanceService()

export { ctMoneyBalanceService }
export default ctMoneyBalanceService
