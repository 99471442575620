import BaseService from '../base.service'
import { getEnvironment } from '../../environments'
import { AxiosPromise } from 'axios'
import { apiMethods } from '../service.constants'
import { InitAddUpdateCardRequestType } from '../../redux/models/profile.payment.interface'

const environment = getEnvironment()

/**
 * Profile payment service.
 */
class ProfilePaymentService extends BaseService {
    /**
     * Creates URL for payment info API
     * @return {string} returns URL for API
     */
    createFetchProfilePaymentDataURL(): string {
        const {
            API_BASE_URL,
            API_ENDPOINTS: { cdsUserProfilePayment },
        } = environment

        return `${API_BASE_URL}${cdsUserProfilePayment}`
    }

    /**
     * API call service for fetch profile payment info
     * @return {AxiosPromise} returns API request for fetch payment info
     */
    fetchProfilePaymentData(): AxiosPromise {
        const url = this.createFetchProfilePaymentDataURL()
        return ProfilePaymentService.apiMethod(
            apiMethods.GET,
            url,
            { lang: ProfilePaymentService.language },
            undefined,
            true,
        )
    }

    /**
     * API call service for deleting saved profile payment info
     * @param {string} maskedCardNumber
     * @return { AxiosPromise }
     */
    deleteProfilePaymentData(maskedCardNumber: string): AxiosPromise {
        const url = this.createFetchProfilePaymentDataURL()
        const requestPayload = {
            maskedCardNumber,
        }
        return ProfilePaymentService.apiMethod(apiMethods.DELETE, url, requestPayload)
    }

    /**
     * @param {InitAddUpdateCardRequestType} payload
     * @return {AxiosPromise}
     */
    hppToken(payload: InitAddUpdateCardRequestType): AxiosPromise {
        const {
            API_ENDPOINTS: { initAddUpdateCard },
        } = environment
        const baseEndPoint = `${environment.API_BASE_URL}${initAddUpdateCard}`
        return ProfilePaymentService.apiMethod(
            apiMethods.POST,
            baseEndPoint.toString(),
            payload as Record<string, unknown>,
        )
    }

    /**
     * API call service for adding saved profile payment info
     * @param {string} transactionId
     * @return { AxiosPromise }
     */
    addCreditCard(transactionId: string): AxiosPromise {
        const {
            API_ENDPOINTS: { addUpdateCard },
        } = environment
        const baseEndPoint = `${environment.API_BASE_URL}${addUpdateCard}`
        const requestPayload = {
            transactionId,
        }
        return ProfilePaymentService.apiMethod(apiMethods.POST, baseEndPoint.toString(), requestPayload)
    }
}

// Creating a singleton instance of service
const profilePaymentService = new ProfilePaymentService()

export { profilePaymentService }
export default profilePaymentService
