export enum alertSizes {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
}

export enum alertTypes {
    SUCCESS = 'success',
    CAUTION = 'caution',
    ERROR = 'error',
    INFO = 'information',
}
export enum alertRadius {
    NONE = 'none',
    ROUNDED_XS = 'rounded-xs',
    ROUNDED_SM = 'rounded-sm',
    ROUNDED_MD = 'rounded-md',
    ROUNDED_LG = 'rounded-lg',
    ROUNDED_XL = 'rounded-xl',
    ROUNDED_RIGHT = 'rounded-right',
    CIRCLE = 'circle',
}

export interface NotificationBadgeProps {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | alertSizes
    type?: 'success' | 'caution' | 'error' | 'information' | alertTypes
    value: number | string
    notificationClass?: string
    a11yValue?: string
    radius?:
        | 'none'
        | 'rounded-xs'
        | 'rounded-sm'
        | 'rounded-md'
        | 'rounded-lg'
        | 'rounded-xl'
        | 'circle'
        | 'rounded-right'
        | alertRadius
}
