// TODO: clean this up
export const carouselSettings = {
    carouselDotsDefault: true, // value to show or hide carousel dots
    carouselInfiniteScrollingDefault: true, // value to enable or disable carousel's infinite scrolling
    desktopThresholdDefault: 4, // value to display number of carousel items on desktop
    mDefaultCarouselSettings: {
        initialSlide: 0,
        className: 'center',
        centerMode: true,
        centerPadding: 50,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
    }, // value to display number of carousel items on mobile portrait mode
    mobileLandscapeThresholdDefault: 2, // value to display number of carousel items on mobile landscape mode
    tabletPortraitThresholdDefault: 3, // value to display number of carousel items on tablet portrait mode
    variableWidthClassName: 'variable-width',
    mobilePortraitThreshold: 1.5,
    tabletPortraitThreshold: 2.5,
    cardSpacing: 16,
    disabledClass: 'swiper-button-disabled',
}
