import React, { useCallback, useEffect, useRef, useState } from 'react'
import RangeSlider from '../RangeSlider/RangeSlider'
import TextInput from '../TextInput'
import { PREFIX } from '../config'
import Button from '../Button'
import { RangeFilterPropsInterface } from './RangeFilter.type'
import {
    getRangeFacetUom,
    getRangeFacetValues,
    getTextWidth,
    inputValGreaterThanMaxVal,
    inputValLessThanMinVal,
    isInputValid,
} from './RangeFilter.helper'
import {
    rangeErrorIcon,
    canvasTagName,
    pixelText,
    rangeFilterInputId,
    suffixUomTestId,
    uomFont,
} from './RangeFilter.constants'
import { replaceStrWithDynamicVal } from '../../utils/replaceStrWithDynamicVal'
import { scrollToElement } from '../../utils/scrollToFooter'

/**
 * Range input component
 * @param {RangeFilterPropsInterface} props
 * @returns {JSX.Element} returns Range input element
 */
const RangeFilter: React.FC<RangeFilterPropsInterface> = ({ ...props }): JSX.Element => {
    const {
        facet,
        applyCTALabel,
        minLabel,
        maxLabel,
        onFilterChange,
        rangeFilterInputLabelId,
        ariaLabelApplyRangeFilterCTA,
        minValGreaterThanMaxValError,
        invalidMinAndMaxError,
    } = props
    const { min, max, urlFormat, minParameter, maxParameter, label } = facet
    const rangeFacetValues = getRangeFacetValues(facet)
    const { startVal, endVal } = rangeFacetValues
    const range = {
        min,
        max,
    }
    const inputId = rangeFilterInputId
    const [value, setValue] = useState({ minValue: startVal, maxValue: endVal })
    const { minValue, maxValue } = value
    const { prefix = '', suffix = '' } = getRangeFacetUom(facet)
    const valueWithUom = `${prefix}${minValue} - ${maxValue} ${suffix}`
    const minValueNumber = Number(minValue)
    const maxValueNumber = Number(maxValue)
    const [error, setError] = useState('')
    const [isErrorChecked, setIsErrorChecked] = useState(false)
    const minInputRef = useRef<HTMLInputElement>(null)
    const maxInputRef = useRef<HTMLInputElement>(null)
    const minInputUomSuffixRef = useRef<HTMLInputElement>(null)
    const maxInputUomSuffixRef = useRef<HTMLInputElement>(null)
    const canvasRef = useRef(document.createElement(canvasTagName))
    const rangeFilterInputcontainerRef = useRef<HTMLDivElement>(null)

    /**
     * function for placing prefix UOM in textInput field
     * @param {React.RefObject<HTMLInputElement>} input
     * @returns { void }
     */
    const updatePrefix = useCallback(
        (input: React.RefObject<HTMLInputElement>): void => {
            if (!isInputValid(input.current.value)) {
                return
            }
            const width = getTextWidth(prefix, uomFont, canvasRef)
            input.current.style.left = String(width) + pixelText
        },
        [prefix],
    )

    /**
     * function for placing suffix UOM in textInput field
     * @param {React.RefObject<HTMLInputElement>} input
     * @param {React.RefObject<HTMLInputElement>} suffixRef
     * @returns { void }
     */
    const updateSuffix = (
        input: React.RefObject<HTMLInputElement>,
        suffixRef: React.RefObject<HTMLInputElement>,
    ): void => {
        if (!isInputValid(input.current.value)) {
            return
        }
        const width = getTextWidth(input.current.value, uomFont, canvasRef)
        if (suffixRef.current) suffixRef.current.style.left = String(width) + pixelText
    }

    /**
     * function for placement of suffix/prefix UOM in textInput field according to the value
     * @param {React.RefObject<HTMLInputElement>} minInput
     * @param {React.RefObject<HTMLInputElement>} maxInput
     * @returns { void }
     */
    const setUOMPositionOnValueChange = useCallback(
        (minInput: React.RefObject<HTMLInputElement>, maxInput: React.RefObject<HTMLInputElement>) => {
            if (prefix) {
                const updateMinPrefix = () => {
                    updatePrefix(minInput)
                }
                const updateMaxPrefix = () => {
                    updatePrefix(maxInput)
                }
                updateMinPrefix()
                updateMaxPrefix()
                minInput.current.addEventListener('input', updateMinPrefix)
                maxInput.current.addEventListener('input', updateMaxPrefix)
                return () => {
                    minInput.current.removeEventListener('input', updateMinPrefix)
                    maxInput.current.removeEventListener('input', updateMaxPrefix)
                }
            } else {
                const updateMinSuffix = () => {
                    updateSuffix(minInput, minInputUomSuffixRef)
                }
                const updateMaxSuffix = () => {
                    updateSuffix(maxInput, maxInputUomSuffixRef)
                }
                updateMinSuffix()
                updateMaxSuffix()
                minInput.current.addEventListener('input', updateMinSuffix)
                maxInput.current.addEventListener('input', updateMaxSuffix)
                return () => {
                    minInput.current.removeEventListener('input', updateMinSuffix)
                    maxInput.current.removeEventListener('input', updateMaxSuffix)
                }
            }
        },
        [prefix, updatePrefix],
    )

    /**
     * useEffect for placement of suffix/prefix UOM in textInput field
     */
    useEffect(() => {
        setUOMPositionOnValueChange(minInputRef, maxInputRef)
    }, [setUOMPositionOnValueChange])

    /**
     * function to render the prefix UOM
     * @returns { JSX.Element | null }
     */
    const renderPrefixUOM = (): JSX.Element | null => {
        return prefix ? (
            <div className={`${PREFIX}-range-input__input-with-uom-wrapper--prefix-uom`}>{prefix}</div>
        ) : null
    }

    /**
     * function to render the suffix UOM
     * @param {React.RefObject<HTMLInputElement>} inputRef
     * @param {string} testId
     * @returns { JSX.Element | null }
     */
    const renderSuffixUOM = (inputRef: React.RefObject<HTMLInputElement>, testId: string): JSX.Element | null => {
        return suffix ? (
            <div
                ref={inputRef}
                data-testid={testId}
                className={`${PREFIX}-range-input__input-with-uom-wrapper--suffix-uom`}>
                {suffix}
            </div>
        ) : null
    }

    /**
     * Below useEffect is responsible to bind minValue and maxValue with minSelected and maxSelected
     */
    useEffect(() => {
        setValue({ minValue: startVal, maxValue: endVal })
    }, [startVal, endVal])

    /**
     * Below function returns search request url
     * @returns { string }
     */
    const getSearchReqUrl = (): string => {
        let searchReqUrl = urlFormat
        if (min <= minValueNumber && minValueNumber <= max) {
            searchReqUrl = searchReqUrl.replace(`${minParameter}`, `${minValue}`)
        } else {
            searchReqUrl = searchReqUrl.replace(`${minParameter}`, `${min}`)
        }
        if (min <= maxValueNumber && maxValueNumber <= max) {
            searchReqUrl = searchReqUrl.replace(`${maxParameter}`, `${maxValue}`)
        } else {
            searchReqUrl = searchReqUrl.replace(`${maxParameter}`, `${max}`)
        }
        return searchReqUrl
    }

    /**
     * Below function sends api request to search with respect to filter change
     * @returns { void }
     */
    const applyFilter = (): void => {
        if (error) {
            const firstErrorElement = rangeFilterInputcontainerRef.current?.querySelector(
                `.${PREFIX}-accessibility-field--error`,
            ) as unknown as HTMLElement

            firstErrorElement && firstErrorElement.focus()
        } else {
            onFilterChange(getSearchReqUrl(), valueWithUom, label, true)
            const element = document.getElementById(`${PREFIX}-filters`)
            scrollToElement(element)
        }
    }

    /**
     * function to update minimum and maximum value for TextInput
     * @param {React.ChangeEvent<HTMLInputElement>} event
     * @returns {void}
     */
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const inputValue = event.target.value
        if (!isInputValid(inputValue)) {
            return
        }
        setError('')
        setIsErrorChecked(false)
        const textInputId = event.target.id
        if (textInputId === `${inputId}-minValue`) {
            setValue(prevValue => {
                return { ...prevValue, minValue: inputValue }
            })
        } else {
            setValue(prevValue => {
                return { ...prevValue, maxValue: inputValue }
            })
        }
    }

    // Below useEffect is responsible for error handling
    useEffect(() => {
        if (minValue === '' || minValue === '.' || maxValue === '' || maxValue === '.') {
            setIsErrorChecked(true)
            return
        }
        if ((min <= minValueNumber && minValueNumber <= max) || (min <= maxValueNumber && maxValueNumber <= max)) {
            if (minValueNumber > maxValueNumber) {
                setError(minValGreaterThanMaxValError)
            }
        } else {
            setError(invalidMinAndMaxError)
        }
        setIsErrorChecked(true)
    }, [
        min,
        max,
        minValueNumber,
        maxValueNumber,
        minValGreaterThanMaxValError,
        invalidMinAndMaxError,
        minValue,
        maxValue,
    ])

    /**
     * function to update range slider values
     * @param {string} rangeValue
     * @param {string} id
     * @returns {void}
     */
    const updateRangeSlider = (rangeValue: string, id: string): void => {
        if (id === 'min-range') {
            if (inputValGreaterThanMaxVal(rangeValue, value.maxValue)) {
                return
            }
            setError('')
            setIsErrorChecked(false)
            setValue(prevValue => {
                return { ...prevValue, minValue: rangeValue }
            })
        } else {
            if (inputValLessThanMinVal(rangeValue, value.minValue)) {
                return
            }
            setError('')
            setIsErrorChecked(false)
            setValue(prevValue => {
                return { ...prevValue, maxValue: rangeValue }
            })
        }
    }

    return (
        <div className={`${PREFIX}-range-slider-container`}>
            <RangeSlider
                range={range}
                values={{
                    startValue: value.minValue,
                    endValue: value.maxValue,
                }}
                uom={getRangeFacetUom(facet)}
                onChange={updateRangeSlider}
                error={error}
                isErrorChecked={isErrorChecked}
                updateUOMPosition={setUOMPositionOnValueChange}
            />
            <div className={`${PREFIX}-range-input`}>
                <div className={`${PREFIX}-range-input__container`} ref={rangeFilterInputcontainerRef}>
                    <span id={`${rangeFilterInputLabelId}-min`} className="sr-only">
                        {facet.label}
                    </span>
                    <div className={`${PREFIX}-range-input__input-with-uom-wrapper`}>
                        {renderPrefixUOM()}
                        <TextInput
                            id={`${inputId}-minValue`}
                            label={minLabel}
                            value={`${value.minValue}`}
                            onChange={handleInputChange}
                            customOnChange={true}
                            maxLength={7}
                            size="small"
                            textInputRef={minInputRef}
                            ariaLabelledBy={`${rangeFilterInputLabelId}-min`}
                            error={error}
                            errorIcon={rangeErrorIcon}
                        />
                        {renderSuffixUOM(minInputUomSuffixRef, suffixUomTestId.minSuffixUomId)}
                    </div>
                    <div className={`${PREFIX}-range-input__separator`}>-</div>
                    <span id={`${rangeFilterInputLabelId}-max`} className="sr-only">
                        {facet.label}
                    </span>
                    <div className={`${PREFIX}-range-input__input-with-uom-wrapper`}>
                        {renderPrefixUOM()}
                        <TextInput
                            id={`${inputId}-maxValue`}
                            label={maxLabel}
                            value={`${value.maxValue}`}
                            onChange={handleInputChange}
                            customOnChange={true}
                            maxLength={7}
                            size="small"
                            textInputRef={maxInputRef}
                            ariaLabelledBy={`${rangeFilterInputLabelId}-max`}
                            error={error}
                            errorIcon={rangeErrorIcon}
                            isErrorForSrOnly={true}
                        />
                        {renderSuffixUOM(maxInputUomSuffixRef, suffixUomTestId.maxSuffixUomId)}
                    </div>
                </div>
                <Button
                    type="secondary"
                    size="small"
                    onClick={applyFilter}
                    ariaLabel={replaceStrWithDynamicVal(ariaLabelApplyRangeFilterCTA, facet.label)}>
                    {applyCTALabel}
                </Button>
            </div>
        </div>
    )
}

export default RangeFilter
