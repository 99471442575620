import { AxiosResponse } from 'axios'
import BaseService from '../base.service'
import { getHttpClient } from '../../httpClient'
import { getEnvironment } from '../../environments'
import { apiMethods } from '../service.constants'
import appCacheService from '../../utils/appCacheService'
import { AddressRequest, RetrieveAddressRequest } from '../../redux/models/checkout.interface'
import { RequestBodyType } from '../../httpClient/client.type'
import { TMXSessionId } from '../tmxService/tmx.type'
import { SignifydSessionId } from '../signifydService/signifyd.type'
import { IFeatureFlag } from '../../redux/models/commonContent.interface'
import { RemainingGiftCardInterface } from '@nl/lib/src/components/GiftCard/GiftCard.types'

const httpClient = getHttpClient()
const environment = getEnvironment()

/**
 * service for actions of checkout component
 */
export class CheckoutService extends BaseService {
    /**
     * @param {string} cartId
     * @param {string} storeNumber
     * @param {number} version
     * @param {boolean} isClickToPayCart
     * @param {string} userId
     * @param {string} semafoneEnabled
     * @param {boolean} isOneTimeCart - is one time cart
     * @param {string} paymentType - payment type
     * @param {string} redirectSuccessLink - PayPal specific redirect link after success PayPal submission
     * @param {string} redirectErrorLink - PayPal specific redirect link after PayPal submission with error
     * @param {string} redirectCancelLink - PayPal specific redirect link after canceled PayPal submission
     * @returns {Promise<AxiosResponse>}
     */
    static hppToken(
        cartId: string,
        storeNumber: string | number,
        version: number,
        isClickToPayCart = false,
        userId?: string,
        semafoneEnabled?: string,
        isOneTimeCart = false,
        paymentType?: string,
        redirectSuccessLink?: string,
        redirectErrorLink?: string,
        redirectCancelLink?: string,
    ): Promise<AxiosResponse> {
        const isOneTimeCartToPass = isOneTimeCart ? `&isOneTimeCart=${String(isOneTimeCart)}` : ''
        const paymentTypeToPass = paymentType ? `&paymentType=${paymentType}` : ''
        const redirectSuccessLinkToPass = redirectSuccessLink
            ? `&redirectSuccess=${encodeURIComponent(redirectSuccessLink)}`
            : ''
        const redirectErrorLinkToPass = redirectErrorLink
            ? `&redirectError=${encodeURIComponent(redirectErrorLink)}`
            : ''
        const redirectCancelLinkToPass = redirectCancelLink
            ? `&redirectCancel=${encodeURIComponent(redirectCancelLink)}`
            : ''
        const clickToPayParam = isClickToPayCart ? `&isClickToPay=${String(isClickToPayCart)}` : ''
        const baseEndPoint = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.initPayment}?cartId=${cartId}&storeId=${storeNumber}&lang=${CheckoutService.language}${isOneTimeCartToPass}${paymentTypeToPass}${redirectSuccessLinkToPass}${redirectCancelLinkToPass}${redirectErrorLinkToPass}${clickToPayParam}`
        return httpClient.apiPost(
            baseEndPoint,
            semafoneEnabled === 'Y'
                ? { semafoneEnabled: semafoneEnabled, storeNumber: String(storeNumber), version: version }
                : { version: version },
        )
    }

    /**
     * Place order api call
     * @param {string} cartId - card id
     * @param {boolean} isNewCard - is new card
     * @param {string} fraudSessionID - fraudSessionID
     * @param {boolean} isOneTimeCart - is one time cart
     * @param {string} cardNumber - card number
     * @returns {Promise<AxiosResponse>}
     */
    static placeOrder(
        cartId: string,
        isNewCard: boolean,
        fraudSessionID?: string,
        isOneTimeCart = false,
        cardNumber = '',
    ): Promise<AxiosResponse> {
        const endPoint = environment.API_ENDPOINTS.placeOrder
        const fraudSessionIDToSend = !!fraudSessionID ? `&fraudSessionID=${fraudSessionID}` : ''
        const isOneTimeCartToPass = isOneTimeCart ? `&isOneTimeCart=${String(isOneTimeCart)}` : ''
        const loyaltyCardNumber = Boolean(cardNumber) ? `&loyaltyCardNumber=${cardNumber}` : ''
        const baseEndPoint = new URL(
            `${environment.API_BASE_URL}${endPoint}?cartId=${cartId}&lang=${
                CheckoutService.language
            }&isNewCard=${String(isNewCard)}${fraudSessionIDToSend}${isOneTimeCartToPass}${loyaltyCardNumber}`,
        )
        return CheckoutService.apiMethod('POST', baseEndPoint.toString(), {})
    }

    /**
     *
     * @param {boolean} redeemCTMoney whether redeeming CT money or not
     * @param {number} redeemAmount Amount of CT Money to redeem
     * @param {number} storeId storeId associated with the cart to be used by PandA (OCCP-15803)
     * @param {boolean} isOneTimeCart - is one time cart
     * @return {Promise<AxiosResponse>}
     */
    static updateRedeemCTMoneyToCart(
        redeemCTMoney: boolean,
        redeemAmount: number | null,
        storeId: string | number,
        isOneTimeCart = false,
    ): Promise<AxiosResponse> {
        const endPoint = environment.API_ENDPOINTS.redeemCTMoney
        const isOneTimeCartToPass = isOneTimeCart ? `&isOneTimeCart=${String(isOneTimeCart)}` : ''
        // TODO: redeemCTMoney boolean passing need to be revisited once API contract is updated
        const baseEndPoint = new URL(
            `${environment.API_BASE_URL}${endPoint}?redeemAmount=${String(redeemAmount)}&redeemCTMoney=${String(
                redeemCTMoney,
            )}&storeId=${storeId}&lang=${CheckoutService.language}${isOneTimeCartToPass}`,
        )
        return CheckoutService.apiMethod(apiMethods.POST, baseEndPoint.toString(), {})
    }

    /**
     *
     * @param {number} giftCardAmount Amount of Gift Cards to redeem
     * @param {string} tenderId Gift Card unique id
     * @param {string} cartId Id associated with the cart
     * @param {string} storeId - preferred store id
     * @returns {Promise<AxiosResponse>}
     */
    static applyGiftCard(
        giftCardAmount: number,
        tenderId: string,
        cartId: string,
        storeId: string,
    ): Promise<AxiosResponse> {
        const baseEndPoint = new URL(
            `${environment.API_BASE_URL}${environment.API_ENDPOINTS.applyGiftCard}?cartId=${cartId}&storeId=${storeId}&lang=${CheckoutService.language}`,
        )
        return CheckoutService.apiMethod(apiMethods.POST, baseEndPoint.toString(), {
            tenderId,
            type: 'GIFT',
            amount: giftCardAmount,
        })
    }

    /**
     * @param {string} tenderId Gift Card unique id
     * @param {string} cartId Id associated with the cart
     * @param {RemainingGiftCardInterface[]} appliedGiftCards remaining Gift Cards in returned from CTFS and formatted
     * @param {string} storeId - preferred store id
     * @returns {Promise<AxiosResponse>}
     */
    static deleteGiftCard(
        tenderId: string,
        cartId: string,
        appliedGiftCards: RemainingGiftCardInterface[],
        storeId: string,
    ): Promise<AxiosResponse> {
        const baseEndPoint = new URL(
            `${environment.API_BASE_URL}${environment.API_ENDPOINTS.deleteGiftCard}?storeId=${storeId}&lang=${CheckoutService.language}`,
        )
        return CheckoutService.apiMethod(apiMethods.DELETE, baseEndPoint.toString(), {
            tenderId,
            cartId,
            appliedGiftCards,
        })
    }

    /**
     * @param {string} cartId Id associated with the cart
     * @param {boolean} shouldRemoveTransaction Id associated with the cart
     * @returns {Promise<AxiosResponse>}
     */
    static clearTenders(cartId: string, shouldRemoveTransaction?: boolean): Promise<AxiosResponse> {
        const cartIdToPass = cartId ? `?cartId=${cartId}` : ''
        const removeTransaction = shouldRemoveTransaction
            ? cartId
                ? '&removeTransaction=true'
                : '?removeTransaction=true'
            : ''

        const url = new URL(
            `${environment.API_BASE_URL}${environment.API_ENDPOINTS.clearTenders}${cartIdToPass}${removeTransaction}`,
        )
        return httpClient.apiGet(url.toString(), {}, {}, true)
    }

    /**
     * Function returns fraud session id from tmx session id from local-storage
     * @param {Partial<IFeatureFlag>} featureFlag to get feature flag value from AEM to check which fraud module is enabled and disabled
     * @returns {string | undefined} fraud session id
     */
    static getFraudSessionID(featureFlag: Partial<IFeatureFlag>): string | undefined {
        const fraudSessionIDObjTmx = appCacheService._getTMXSessionId(environment.TMX_SESSION_VALUE)
        const fraudSessionIDObjSignifyd = appCacheService._getSIGNIFYDSessionId(environment.SIGNIFYD_SESSION_VALUE)

        const deconstructTmxObj = (JSON.parse(fraudSessionIDObjTmx) as TMXSessionId).sessionId
        const deconstructSignifydObj = (JSON.parse(fraudSessionIDObjSignifyd) as SignifydSessionId).sessionId

        const fraudFeatureFlag = featureFlag

        let fraudSessionID: string | undefined
        try {
            fraudSessionID =
                fraudFeatureFlag.enableTmx && fraudFeatureFlag.enableSignifyd
                    ? `${deconstructTmxObj}:${deconstructSignifydObj}`
                    : !fraudFeatureFlag.enableTmx && fraudFeatureFlag.enableSignifyd
                    ? deconstructSignifydObj
                    : deconstructTmxObj
        } catch (error) {
            fraudSessionID = undefined
        }
        return fraudSessionID
    }

    /**
     * Function to get auto complete address suggestions for entered search term using canada post
     * @param {AddressRequest} requestPayload - contains the request payload along with search term
     * @returns {Promise<AxiosResponse>}
     */
    static getCanadaPostAddressSuggestions(requestPayload: AddressRequest): Promise<AxiosResponse> {
        const {
            CANADA_POST_BASE_URL,
            API_ENDPOINTS: { canadaPostFind },
        } = environment
        const url = `${CANADA_POST_BASE_URL}${canadaPostFind}`
        return httpClient.apiGet(url, requestPayload as unknown as RequestBodyType)
    }

    /**
     * Function to validate selected address using canada post auto suggestions
     * @param {RetrieveAddressRequest} requestPayload - contains the request payload to retrieve address
     * @returns {Promise<AxiosResponse>}
     */
    static validateSelectedCanadaPostAddress(requestPayload: RetrieveAddressRequest): Promise<AxiosResponse> {
        const {
            CANADA_POST_BASE_URL,
            API_ENDPOINTS: { canadaPostRetrieve },
        } = environment
        const url = `${CANADA_POST_BASE_URL}${canadaPostRetrieve}`
        return httpClient.apiGet(url, requestPayload as unknown as RequestBodyType)
    }

    /**
     * @param {string} cartId - cart ID
     * @param {string} fields - defines, how full a response will be (e.g. BASIC, DEFAULT, FULL)
     * @returns {Promise<AxiosResponse>}
     */
    static getPaymentDetails(cartId: string, fields?: string): Promise<AxiosResponse> {
        const fieldsToPass = fields ? `&fields=${fields}` : ''
        const baseEndPoint = new URL(
            `${environment.API_BASE_URL}${environment.API_ENDPOINTS.getPaymentDetails}?cartId=${cartId}${fieldsToPass}`,
        )
        return CheckoutService.apiMethod(apiMethods.GET, baseEndPoint.toString(), {})
    }

    /**
     * Method to invoke clickToPaySTHValidation endpoint
     * @param {string} cartId - cart ID
     * @param {string} fields - defines, how full a response will be (e.g. BASIC, DEFAULT, FULL)
     * @returns {Promise<AxiosResponse>}
     */
    static validateClickToPaySTHAddress(cartId: string): Promise<AxiosResponse> {
        const endPoint = environment.API_ENDPOINTS.clickToPaySTHValidation
        const baseEndPoint = new URL(`${environment.API_BASE_URL}${endPoint}?cartId=${cartId}`)
        return CheckoutService.apiMethod(apiMethods.POST, baseEndPoint.toString(), {})
    }
}

export default CheckoutService
