import { eEntryType } from '../../redux/models/cart.interface'

export enum CartServicePayloadType {
    addItem = 'addItem',
}
export const cartguidParam = 'cart-guid'

// TODO: Below default hardcoded values to be removed later. This is to enable product to be visible on cart
export const getCartDefaults = {
    orderEntry: {
        sellable: true,
        isShipHome: false,
        entryType: eEntryType.PRODUCT,
    },
    orderEntryEmptyArrayOverrides: {
        options: [
            {
                descriptor: 'Color',
                display: 'Color',
                value: 'Blue',
            },
            {
                descriptor: 'Size',
                display: 'Size',
                value: '7',
            },
            {
                descriptor: 'Scent',
                display: 'Scent',
                value: 'Wood',
            },
        ],
        images: [
            {
                altText: '',
                mediaType: '',
                isListingThumbnailImage: '',
                url: 'https://canadiantire.scene7.com/is/image/CanadianTire/0431418_1?defaultImage=image_na_EN&fmt=jpg&fit=constrain,1&wid=573&hei=499',
            },
        ],
    },
    restCartItems: {
        storeData: {
            id: '00100',
        },
    },
}

export const getMiniCartDefault = {
    cartId: '',
    numberOfItems: 0,
    orderEntries: null,
    updatedCart: {
        numberOfItems: 0,
    },
    deliveryAddress: null,
}
