import { allNotLetterNotNumber, allWhitespace } from '../../globalConstants/regexPatterns.constant'
import { includesRankKey } from './JumpList.constant'
import { JumpListItem, JumpListSearchRank } from './JumpList.type'

/**
 * @description helper to sort number in ascending order
 * @param {number} a
 * @param {number} b
 * @return {number}
 */
const sortNumbersInAscendingOrder = (a: number, b: number) => a - b

/**
 * @description filter helper that returns the search result in the order in which the word of the label matches searchTerm
 * @param {JumpListItem[]} list
 * @param {string} searchTerm
 * @return {JumpListItem[]}
 */
const filterByRank = (list: JumpListItem[], searchTerm: string): JumpListItem[] => {
    const searchResultByRankMap = new Map<number | typeof includesRankKey, Map<number, JumpListItem[]>>()
    const ranks = new Set<number>()

    const queryWithoutExtraSpaces = searchTerm.toLowerCase().replace(allWhitespace, ' ').trim()
    const queryByChunks = queryWithoutExtraSpaces.split(allNotLetterNotNumber).filter(Boolean)
    const searchTerms = [queryWithoutExtraSpaces, queryByChunks.join(''), ...queryByChunks]

    list.forEach(item => {
        const { label } = item
        const words = label.toLowerCase().split(allNotLetterNotNumber)

        for (let wordNumber = 0; wordNumber < words.length; wordNumber++) {
            const word = words[wordNumber]

            const primaryRankingMatch = searchWithDividers(word, searchTerms, 'startsWith')

            if (primaryRankingMatch.match) {
                ranks.add(wordNumber)
                const rankingMap = searchResultByRankMap.get(wordNumber) ?? new Map<number, JumpListItem[]>()
                const rankList = rankingMap.get(primaryRankingMatch.rank) ?? []

                searchResultByRankMap.set(wordNumber, rankingMap.set(primaryRankingMatch.rank, [...rankList, item]))
                break
            }
            const isLastWord = wordNumber === words.length - 1
            if (isLastWord) {
                const secondaryRankingMatch = searchWithDividers(label.toLowerCase(), searchTerms, 'includes')
                if (secondaryRankingMatch.match) {
                    const includesRankingMap =
                        searchResultByRankMap.get(includesRankKey) ?? new Map<number, JumpListItem[]>()
                    const includesRankList = includesRankingMap.get(secondaryRankingMatch.rank) ?? []

                    searchResultByRankMap.set(
                        includesRankKey,
                        includesRankingMap.set(secondaryRankingMatch.rank, [...includesRankList, item]),
                    )
                }
            }
        }
    })

    const searchResultByRank: JumpListItem[] = Array.from(ranks)
        .sort(sortNumbersInAscendingOrder)
        .reduce((prev, rank) => {
            const primaryRankMap = searchResultByRankMap.get(rank)

            const rankList = Array.from(primaryRankMap.keys())
                .sort(sortNumbersInAscendingOrder)
                .flatMap(searchRank => primaryRankMap.get(searchRank) ?? [])

            return [...prev, ...rankList]
        }, [] as JumpListItem[])

    const includesRankMap = searchResultByRankMap.get(includesRankKey)

    const includesRankList = Array.from(includesRankMap?.keys() ?? [])
        .sort(sortNumbersInAscendingOrder)
        .flatMap(searchRank => includesRankMap.get(searchRank) ?? [])

    return [...searchResultByRank, ...includesRankList]
}

/**
 * @description search logic to support any dividers
 * @param {string} label
 * @param {string[]} searchTerms
 * @param {'startsWith' | 'includes'} matchMethod
 * @return {boolean}
 */
const searchWithDividers = (
    label: string,
    searchTerms: string[],
    matchMethod: 'startsWith' | 'includes',
): JumpListSearchRank => {
    const labelWithoutDividers = label.replace(allNotLetterNotNumber, '')

    for (let searchTermNumber = 0; searchTermNumber < searchTerms.length; searchTermNumber++) {
        const searchTerm = searchTerms[searchTermNumber]
        if (label[matchMethod](searchTerm) || labelWithoutDividers[matchMethod](searchTerm)) {
            return { rank: searchTermNumber, match: true }
        }
    }
    return { match: false }
}

export { filterByRank }
