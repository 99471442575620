/* eslint-disable complexity */
import React, { useEffect, useRef, useState } from 'react'

import appCacheService from '../../utils/appCacheService'
import {
    getWishListItems,
    getWishListProductCodesItems,
    getWishListDetailsItems,
    mergeWishListItems,
} from '../../redux/actions/wishlist.action'
import {
    wishlistSuccessAction,
    wishlistDetailsSuccessAction,
    wishlistProductCodesSuccessAction,
    setSignInForSalesAlertsAction,
} from '../../redux/actionCreators'
import { WishlistProduct, WishlistResponse } from '../../redux/models/wishlist.interface'
import { useAuth } from '../../hooks'
import { areAllParamsValid, isAtleastOneParamValid } from '../../utils/getFilteredCartItems'
import { storageData } from '../../globalConstants'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { pageTypes } from '../../config'
import getPageType from '../../utils/getPageType'
import {
    isAuthFlowExecutedSelector,
    signOutSuccessSelector,
    userProfileDataSelector,
    isUserDataFetchedSelector,
} from '../../redux/selectors/userProfile.selectors'
import { selectedPreferredStoreIdSelector } from '../../redux/selectors/storeDetails.selectors'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'

const WishlistInit: React.FC = () => {
    const dispatch = useAppDispatch()
    const isAuthFlowExecuted = useAppSelector(isAuthFlowExecutedSelector)
    const signOutSuccess = useAppSelector(signOutSuccessSelector)
    const userProfileData = useAppSelector(userProfileDataSelector)
    const isUserDataFetched = useAppSelector(isUserDataFetchedSelector)
    const selectedPreferredStoreId = useAppSelector(selectedPreferredStoreIdSelector)
    const { commonContentAvailable } = useAppSelector(commonContentSelector)

    const runAuthenticatedOnce = useRef(0)

    const { authCondition } = useAuth({
        runOnce: runAuthenticatedOnce,
    })

    const commonContentIsFetched = checkDataLength(commonContentAvailable)

    const [wishlist, setWishlist] = useState(
        commonContentAvailable?.wishlist || ({} as typeof commonContentAvailable.wishlist),
    )
    useEffect(() => {
        commonContentAvailable?.wishlist && setWishlist(commonContentAvailable.wishlist)
    }, [commonContentAvailable])

    /**
     * dispatch sign in for sales alerts action if user clicked on 'sign in for sales alerts' btn on pdp
     * this allows toast to be displayed on pdp when user is redirected back
     */
    useEffect(() => {
        const signInForSalesAlertsClicked = appCacheService.isSignInForSalesAlerts.get()
        signInForSalesAlertsClicked && dispatch(setSignInForSalesAlertsAction({ isClicked: true }))
    }, [dispatch])

    useEffect(() => {
        const guid = appCacheService.wishlistGuid.get()
        const prefStoreId = appCacheService.preferredStoreId.get()
        const _checkPageForInitWishlist = (guidParam: string): void => {
            const currentPageType = getPageType()
            const { wishlist: wishlistPageType, cart, pdpPage } = pageTypes

            const isUserLoggedIn = (userProfileData && checkDataLength(userProfileData)) || false
            let expired
            let catchedWishlist
            let products: WishlistProduct[]

            if (isUserLoggedIn) {
                catchedWishlist = appCacheService.wishlistCache.getWithExpiry()
                const value = catchedWishlist.value
                products = value && value.products
                expired = catchedWishlist.expired
            } else {
                catchedWishlist = appCacheService.wishlistCache.get()
                products = catchedWishlist ? (JSON.parse(catchedWishlist) as WishlistResponse).products : []
            }

            switch (currentPageType) {
                case wishlistPageType:
                    dispatch(getWishListItems(guidParam))
                    break
                case cart:
                case pdpPage:
                    if (isUserLoggedIn) {
                        if (!expired) {
                            dispatch(wishlistDetailsSuccessAction({ products }))
                        } else {
                            dispatch(getWishListDetailsItems(guidParam))
                        }
                    } else if (checkDataLength(catchedWishlist)) {
                        dispatch(wishlistDetailsSuccessAction({ products }))
                    } else {
                        dispatch(getWishListDetailsItems(guidParam))
                    }
                    break
                default:
                    if (isUserLoggedIn) {
                        if (!expired) {
                            dispatch(wishlistProductCodesSuccessAction({ products }))
                        } else {
                            isUserDataFetched
                                ? dispatch(getWishListProductCodesItems(guidParam, selectedPreferredStoreId))
                                : dispatch(getWishListDetailsItems(guidParam))
                        }
                    } else if (checkDataLength(catchedWishlist)) {
                        dispatch(wishlistProductCodesSuccessAction({ products }))
                    } else {
                        dispatch(getWishListDetailsItems(guidParam))
                    }
            }
        }
        if (
            !!areAllParamsValid(
                isAtleastOneParamValid(selectedPreferredStoreId, prefStoreId),
                isAuthFlowExecuted,
                commonContentIsFetched,
            )
        ) {
            if (checkDataLength(userProfileData)) {
                if (!!guid && !guid.includes('@') && authCondition()) {
                    dispatch(mergeWishListItems(guid))
                    !!appCacheService.wishlistCache.get() && appCacheService.wishlistCache.delete()
                    runAuthenticatedOnce.current = 1
                } else {
                    _checkPageForInitWishlist('')
                }
            } else {
                if (!!guid && !appCacheService.gigyaJWTToken.get()) {
                    _checkPageForInitWishlist(guid)
                }
            }
        }
    }, [
        dispatch,
        isAuthFlowExecuted,
        userProfileData,
        selectedPreferredStoreId,
        authCondition,
        isUserDataFetched,
        commonContentIsFetched,
        wishlist,
    ])

    useEffect(() => {
        if (signOutSuccess) {
            runAuthenticatedOnce.current = 0
            appCacheService.wishlistGuid.delete(storageData.wishlistGuid)
        }
        if (signOutSuccess && !appCacheService.wishlistGuid.get()) {
            dispatch(
                wishlistSuccessAction({
                    products: [],
                } as WishlistResponse),
            )
        }
    }, [signOutSuccess, dispatch])

    return null
}

export default WishlistInit
export { WishlistInit }
