import React from 'react'

import { addDynamicToken, isArrayNotEmpty, magicNumber, replaceStrWithDynamicVal } from '../../utils'
import { checkDataLength } from '../../utils/checkDataLength'
import Icon from '../Icon'
import { FeeMessage, Price } from '../Price/Price.types'
import CommonWarningToast from '../ProductCartItem/CommonWarningToast'
import { getFormattedPriceValue } from '../ProductReusableCard/instanceCheckingUtil'
import { Specification } from '../ProductReusableCard/product.types'
import { prePackagedBallonBouquet } from './ProductItemInfo.constant'

import {
    CartOrderDeliveryModes,
    FulfillmentErrorType,
    FulfillmentMethods,
    ItemInlineErrorType,
    OptionsOnCartItem,
    ProductDataDTO,
    WarningToastProps,
    addOrRemoveWishlistProps,
    SelectedDeliveryMode,
    eEntryType,
} from './ProductItemInfo.type'
import { badgesTypes } from './ProductCartItem.constant'
import { areAllParamsValid, isAtleastOneParamValid } from '../../utils/validParams'
import { IHeliumInflationStoreServiceType, Package } from '../CartFlyout/CartFlyout.type'
import { balloonEntryType, inflationData } from '../../globalConstants/global.constant'
import { CartItemFulFillmentType, CartOrderEntries } from './ProductCartItem.type'
import { WishlistProduct } from '../../types'

export const isOrderSTH = (orderEntry: CartOrderEntries | ProductDataDTO): boolean => {
    return (
        orderEntry.fulfillment?.deliveryMode === CartOrderDeliveryModes.STH ||
        orderEntry.fulfillment?.deliveryMode === CartOrderDeliveryModes.EXPRESS
    )
}

export const isOrderBOPIS = (orderEntry: CartOrderEntries | ProductDataDTO): boolean => {
    return (
        orderEntry.fulfillment?.deliveryMode === CartOrderDeliveryModes.BOPIS ||
        orderEntry.fulfillment?.deliveryMode === CartOrderDeliveryModes.CURBSIDE
    )
}

const isSthOrExpressHasError = (props: WarningToastProps, isBulk: boolean) => {
    const errorMessage = { expressErrMsg: '', sthErrMsg: '', qtyExceedMsg: '' }
    if (!props?.isSelectedDeliveryAvailable) {
        if (props?.selectedDeliveryMode?.isExpress) {
            errorMessage.expressErrMsg = isBulk
                ? props?.expressDeliveryDoesNotSupportHeavyOversizedMsg
                : props?.itemNotEligibleForExpressDeliveryMsg
        } else if (props?.selectedDeliveryMode?.isSTH) {
            errorMessage.sthErrMsg = props?.itemNotEligibleForStandardDeliveryMsg
        }
    }
    return errorMessage
}

const getExpressDeliveryRelatedErrorMsg = (props: WarningToastProps, isBulk: boolean) => {
    const { expressErrMsg, sthErrMsg, qtyExceedMsg } = isSthOrExpressHasError(props, isBulk)
    return expressErrMsg || sthErrMsg || qtyExceedMsg
}

/**
 * This function render I &A error for cart
 * @param {Record<string, unknown>}
 * @return {JSX.Element}
 */

export const renderWarningToast = (
    props: WarningToastProps,
    itemAvailabilityInfo: ItemInlineErrorType,
    productData: ProductDataDTO | CartOrderEntries,
): JSX.Element => {
    const fulfillmentDeliverError = itemAvailabilityInfo?.fulfillmentDeliverError
    const isNotApplicableForSelectedDeliveryMethod = getExpressDeliveryRelatedErrorMsg(props, productData?.isBulk)
    const errorMsg = Boolean(isNotApplicableForSelectedDeliveryMethod)
        ? isNotApplicableForSelectedDeliveryMethod
        : itemAvailabilityInfo?.showInlineError
        ? itemAvailabilityInfo?.errorMessage
        : replaceStrWithDynamicVal(props?.exceededMsgBar, props?.checkErrorMessage().exceededValue)
    const isNearBy = itemAvailabilityInfo?.optionsToBeDisplayed?.includes(OptionsOnCartItem.CHECK_NEAR_BY)
    const nearByCTA = itemAvailabilityInfo?.showInlineError && isNearBy ? props.nearbyAvailabilityCTALabel : ''
    const deliveryChangeCTA =
        !!fulfillmentDeliverError && !isNearBy
            ? fulfillmentDeliverError === FulfillmentErrorType.FULFILLMENT_BOPIS_ERROR
                ? props?.changeShipToHomeCTALabel
                : props?.pickAtStoreCTALabel
            : null
    const { entryNumber } = productData
    const isShipHome = isOrderSTH(productData)

    /**
     * function to return fulfillment action when product is not applicable for either of the fulfillment option
     */
    const isFulfillmentChange = (): void => {
        !!fulfillmentDeliverError &&
            props.onChangeFulfillmentOption(isShipHome ? FulfillmentMethods.BOPIS : FulfillmentMethods.STH, entryNumber)
    }
    /**
     * function to trigger toast button click
     * @param {boolean} isClicked
     * @return {void}
     */
    const toastCTAHandler = (isClicked?: boolean): void => {
        if (isClicked && nearByCTA) {
            const isBouquet =
                productData?.balloonEntryType === balloonEntryType.preDesignedBalloonBouquet ||
                productData?.balloonEntryType === balloonEntryType.prePackagedBalloonSku
            props?.checkNearByCTAHandler(productData?.code, isBouquet)
        } else if (isClicked && !nearByCTA) {
            isFulfillmentChange()
        }
    }
    return (
        <CommonWarningToast
            icon="ct-notification-caution"
            bgType="orange"
            label={errorMsg}
            checkNearByText={deliveryChangeCTA || nearByCTA}
            toastCTAHandler={toastCTAHandler}
        />
    )
}

/**
 * Show wishlist icon when error is present.
 * @param {boolean} error - if error presents, the value is true.
 * @param {string[]} optionToDisplay - options to show when there is error.
 *
 * @return {boolean} - show wish list based on the params.
 */
export const showWishListIcon = (error: boolean, optionToDisplay: string[]): boolean => {
    if (error) {
        return optionToDisplay.includes(OptionsOnCartItem.WISHLIST)
    }
    return true
}

/**
 * This function is used to finds the specification based on size, style and offest
 * @param {Specification} specification
 * @param {string} specificationsFromProps
 * @return {Specification[]}
 */
export const filterSpecification = (
    specification: Specification[],
    specificationsFromProps: string,
): Specification[] => {
    const specificationFromPropsArr = specificationsFromProps?.split(',')
    const specificationFromPropsCaps = isArrayNotEmpty(specificationFromPropsArr)
        ? specificationFromPropsArr?.map((spec: string) => spec?.toUpperCase())
        : []
    return specification?.filter((eachSpec: Specification) =>
        specificationFromPropsCaps?.includes(eachSpec?.code?.toUpperCase()),
    )
}

declare const window: Window & typeof globalThis
if (typeof window !== 'undefined') {
    window.analyticsLayer = window.analyticsLayer || []
}

export const cancellationAnalytics = (
    event: string,
    cancelType: string,
    cancelLocation: string,
    cancellationReason?: string,
): void => {
    window.analyticsLayer = window.analyticsLayer || []
    !!cancellationReason
        ? window.analyticsLayer.push({
              event: event,
              cancelType: cancelType,
              cancelLocation: cancelLocation,
              cancellationReason: cancellationReason,
          })
        : window.analyticsLayer.push({
              event: event,
              cancelType: cancelType,
              cancelLocation: cancelLocation,
          })
}
/**
 * function to show or hide was label
 * to hide label on order details page only
 * @param {boolean} isOrderDetails
 * @param {boolean} displayWasLabel
 * @return {boolean}
 */

export const showHideWasLabel = (isOrderDetails: boolean, displayWasLabel: boolean): boolean => {
    return !isOrderDetails && displayWasLabel
}

/**
 * Function to show or hide badges
 * hide badges in order details page when the product is under discount or any type of sale
 * @param {Price} originalPrice
 * @param {boolean} isDetailsPage
 * @return {boolean}
 */
export const showHideSaleInfo = (originalPrice: Price, isDetailsPage: boolean): boolean => {
    return originalPrice && !!originalPrice.value && isDetailsPage ? false : true
}

/**
 * Function to get fee title
 * @param {number} feeMessageValue
 * @param {FeeMessage} feeMessage
 * @param {string} language
 * @return {string}
 */
export const getFeeTitle = (feeMessageValue: number, feeMessage: FeeMessage, language: string): string => {
    return feeMessageValue
        ? replaceStrWithDynamicVal(
              addDynamicToken(feeMessage?.feeTitle, '$x'),
              getFormattedPriceValue(language, feeMessageValue),
          )
        : ''
}

/**
 * Check if product is pre package ballon product or not
 * @param {CartOrderEntries | ProductDataDTO} product
 * @return {boolean}
 */
export const isBalloonProduct = (product: CartOrderEntries | ProductDataDTO): boolean => {
    if (!!product?.balloonData && checkDataLength(product?.balloonData)) {
        return product?.balloonData?.balloonType?.toLowerCase() === prePackagedBallonBouquet.toLowerCase()
    }
    return false
}

/**
 * Check in stock in three stores.
 * @param {number} currentStoreQuantity - if > 0, there is some quantity in current store.
 * @param {number} dcQuantity - if > 0, there is some quantity in dc.
 * @param {number} orderableFromDC - is orderable from dc
 * @return {Record<string, unknown>} returns method to check different conditions.
 */
export const checkIsStockInAllStore = (
    currentStoreQuantity: number,
    dcQuantity: number,
    orderableFromDC: boolean,
): Record<string, () => boolean> => {
    /**
     * Check whether current store is empty but not dc.
     * @return {boolean}
     */
    const _discontinuedAndOOSInStore = (): boolean => Boolean(!currentStoreQuantity)

    /**
     * Case where in stock in DC but not orderableFromDC.
     * @return {boolean}
     */
    const _isStockinDCNotOrderable = (): boolean =>
        Boolean(_discontinuedAndOOSInStore() && dcQuantity && !orderableFromDC)

    /**
     * Case where not in stock in DC.
     * @return {boolean}
     */
    const _isOOSInDC = (): boolean => Boolean(_discontinuedAndOOSInStore() && !dcQuantity)

    /**
     * Case where out of stock in store and out of stock in DC.
     * @return {boolean}
     */
    const _isOutOfStock = (): boolean => Boolean(_isStockinDCNotOrderable() || _isOOSInDC())
    return {
        _isOutOfStock,
    }
}

// Product is eligible for EDD (has inventory in the Selected Store, is a Parcel Product, and is NOT Online Only)
/**
 * function to get availability of rendering Estimated Delivery Date label
 * @param {string[]} badges
 * @param {string} productSourceType
 * @param {boolean} isBulk
 * @param {CartItemFulFillmentType} fulfillment
 * @param {boolean} isShowedEstimatedDeliveryDateBetween
 * @param {boolean} estimatedDeliveryDate
 * @return {string}
 */
export const getEstimatedDeliveryDateLabelAvailability = (
    badges: string[],
    productSourceType: string,
    isBulk: boolean,
    fulfillment: CartItemFulFillmentType,
    isShowedEstimatedDeliveryDateBetween: boolean,
    estimatedDeliveryDate: boolean,
): boolean => {
    const isOnlineOnly = badges?.includes(badgesTypes.onlineOnly)

    const isStoreAvailabelQuantity = !!fulfillment?.stockItemAvailability?.storeQuantity

    const isSourceAvailabelQuantity =
        fulfillment?.sourceTypes?.[0]?.productSourceType == productSourceType &&
        !!fulfillment?.sourceTypes?.[0]?.quantity

    const isAvailabelQuantity = isStoreAvailabelQuantity ? isStoreAvailabelQuantity : isSourceAvailabelQuantity

    return (
        isShowedEstimatedDeliveryDateBetween && estimatedDeliveryDate && !isBulk && isAvailabelQuantity && !isOnlineOnly
    )
}

/**
 * Add or Remove item from wishlist
 * @param {addOrRemoveWishlistProps} props
 * @param {boolean} isInWishList
 * @param {ProductDataDTO} productData
 */
export const addOrRemoveWishlist = (
    props: addOrRemoveWishlistProps,
    isInWishList: boolean,
    productData: ProductDataDTO,
) => {
    isInWishList ? props.removeitemFromWishlist(productData) : props.addItemToWishlist(productData)
}

/**
 * Get Wishlist Icon React component.
 * @param {boolean} isInWishList
 * @param {boolean} showWishListSpinner
 * @return {JSX.Element}
 */
export const renderWishListIconComponent = (isInWishList: boolean): JSX.Element => {
    return <Icon type={isInWishList ? 'ct-favorite-active' : 'ct-favorite-inactive'} size="lg" />
}
/**
 * Function to check is present this product in wishlist
 * @param {array | undefined} wishlistProducts
 * @param {string} code
 * @return {boolean}
 */
export const checkIsInWishList = (wishlistProducts: WishlistProduct[] | undefined, code: string): boolean => {
    return !!wishlistProducts?.some(item => item.code === code)
}
/**
 * Function to check the page is wishlist or ConfirmationPage
 * @param {boolean} wishList
 * @param {boolean} confirmationPage
 * @return {boolean}
 */
export const validation = (wishList: boolean, confirmationPage: boolean): boolean => {
    return wishList || confirmationPage ? true : false
}

/**
 * Function to check, set quantity exceed error on product and cart level
 * @param {boolean} isQuantityExceeded
 * @param {boolean} qtyExceededFlag
 * @param {function} setQtyExceededErr
 * @param {SelectedDeliveryMode} selectedDeliveryMode
 * @param {boolean} isSelectedDeliveryAvailable
 * @param {boolean} isAllExpressProducts
 * @param {function} updateQtyExceed
 * @param {function} setInlineToastError
 * @return {void}
 */

export const setQuantityExceedErrForExpressDeliveryProduct = (
    isQuantityExceedThanStoreQty: boolean,
    qtyExceededFlag: boolean,
    setQtyExceededErr: (flag: boolean) => void,
    selectedDeliveryMode: SelectedDeliveryMode,
    isSelectedDeliveryAvailable: boolean,
    isAllExpressProducts: boolean,
    updateQtyExceed: (flag: boolean) => void,
    setInlineToastError: (flag: boolean) => void,
): void => {
    if (isQuantityExceedThanStoreQty) {
        !qtyExceededFlag && setQtyExceededErr(isQuantityExceedThanStoreQty)
        if (isSelectedDeliveryAvailable) {
            setInlineToastError(true)
            isAllExpressProducts && updateQtyExceed && updateQtyExceed(true)
        }
    } else if (isAtleastOneParamValid(selectedDeliveryMode?.isSTH, !isQuantityExceedThanStoreQty)) {
        setQtyExceededErr(isQuantityExceedThanStoreQty)
        setInlineToastError(false)
        isAllExpressProducts && updateQtyExceed && updateQtyExceed(false)
    }
}

/**
 * Function build Estemated Delivary Date Label with Quantity for Order from InStore and DC
 * @param {string} quantity
 * @param {string} estimatedDeliveryDateBetween
 * @return {string}
 */
export const buildEstematedDeliveryDateSplitQty = (quantity: string, estimatedDeliveryDateBetween: string): string => {
    return `${estimatedDeliveryDateBetween.slice(
        magicNumber.ZERO,
        magicNumber.THREE,
    )}${quantity} ${estimatedDeliveryDateBetween.slice(magicNumber.THREE)}`
}

/**
 * Function check and return delivery method type
 * @param {boolean} isShipToHome
 * @param {boolean} isExpressDeliverySelected
 * @param {string} deliveryMode
 * @return {string}
 */
export const checkDeliveryMethodType = (
    isShipToHome: boolean,
    isExpressDeliverySelected: boolean,
    deliveryMode: string,
): string => {
    return !isShipToHome
        ? isExpressDeliverySelected || deliveryMode === CartOrderDeliveryModes.EXPRESS
            ? CartOrderDeliveryModes.EXPRESS
            : FulfillmentMethods.STH
        : FulfillmentMethods.BOPIS
}

/**
 * Function to get max quantity
 * @param {number} maxQuanity is max quantity
 * @param {number} storeQuantity is store quantity
 * @returns {number} is max quanitity info
 */
export const storeMaxQuantityInfo = (maxQuanity: number, storeQuantity: number): number => {
    return maxQuanity || storeQuantity
}

export const renderHeliumInflationUnInflationText = (
    isHeliumInflatedUnInflatedShown: boolean,
    heliumInflationUnInflationText: string,
): JSX.Element => {
    return isHeliumInflatedUnInflatedShown ? <p>{heliumInflationUnInflationText}</p> : null
}

/**
 * Function to render helium appointment info message
 * @param {string} infoClassName parent class name
 * @param {boolean} isHeliumInflationOptionSelected flag to check helium selection
 * @param {string} heliumAppointmentInfoMessage appointment info message
 * @param {boolean} isHeliumInflatedUnInflatedShown feature flag
 * @returns {JSX.Element} HTML element with info message
 */
export const renderHeliumAppointmentInfoMessage = (
    infoClassName: string,
    isHeliumInflationOptionSelected?: boolean,
    heliumAppointmentInfoMessage?: string,
    isHeliumInflatedUnInflatedShown?: boolean,
): JSX.Element => {
    return isHeliumInflationOptionSelected && isHeliumInflatedUnInflatedShown ? (
        <p className={`${infoClassName}__service-disclaimer`}>
            <Icon type="ct-information-grey" size="sm" />
            <span data-testid="heliumAppointmentInfoMessage">{heliumAppointmentInfoMessage}</span>
        </p>
    ) : null
}

/**
 * Function to find out if a product belongs to some package
 * @param { Package[] } packages - array of packages
 * @param { CartOrderEntries | ProductDataDTO } product data
 * @returns { boolean } true if product belongs to some package
 */
export const isProductFromPackage = (packages: Package[] = [], product: CartOrderEntries | ProductDataDTO): boolean => {
    return packages.some(cartPackage => cartPackage.items.includes(product as unknown as CartOrderEntries))
}

/**
 * Function to find out if a product is main (first) in some package
 * @param { Package[] } packages - array of packages
 * @param { CartOrderEntries | ProductDataDTO } product data
 * @returns { boolean } true if product at first position in one of packages
 */
export const isPackageMainProduct = (packages: Package[] = [], product: CartOrderEntries | ProductDataDTO): boolean => {
    return packages.some(cartPackage => cartPackage.items[0] === (product as unknown as CartOrderEntries))
}

/**
 * Flag to show Inflation Labels On Order Confirmation Page
 * @param {IHeliumInflationStoreServiceType[]} supportedStoreServices supportedStoreServices
 * @param {boolean} showInflationLabels showInflationLabels flag
 * @returns {boolean} flag value
 */
export const showInflationLabelsOnOrderConfirmationPage = (
    supportedStoreServices?: IHeliumInflationStoreServiceType[],
    showInflationLabels?: boolean,
): boolean => {
    const isHeliumAddonSupported = supportedStoreServices?.some(service => service.value === inflationData.heliumAddOn)
    return areAllParamsValid(isHeliumAddonSupported, showInflationLabels)
}

/**
 * Function to render true when product you may also need btn is visible.
 * @param {string} productEntryType
 * @param {boolean} isProductService - true when we want the ui to a product service.
 * @param {boolean} isService
 * @param {boolean} enableCartAccessoriesFlyout
 * @returns {boolean} Product you may also need Button.
 */
export const showProductYouMayAlsoLikeBtn = (
    productEntryType: string,
    isProductService: boolean,
    isService: boolean,
    enableCartAccessoriesFlyout: boolean,
): boolean => {
    return productEntryType === eEntryType.PRODUCT && !isProductService && !isService && enableCartAccessoriesFlyout
}
