/**
 * Below functional will return min round-off value.
 * @param { number } min
 * @returns { number} return min round off value min = 5.2 or 5.9 it will return 5.
 */
export const getMinRoundOff = (min: number): number => {
    return Math.floor(min)
}

/**
 * Below functional will return max round-off value.
 * @param { number } max
 * @returns { number} return max round off value max = 5.2 or 5.9 it will return 6.
 */
export const getMaxRoundOff = (max: number): number => {
    return Math.ceil(max)
}
