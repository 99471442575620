/**
 ** Interface for MegaNavigation Component
 * @interface
 */
export interface CategoryDataProps {
    id?: string
    name: string
    description: string
    url: Url | string
    megaNavTitle: string
    style?: string
    backLinkLabel: string
    isActive?: string
    categories?: CategoryDataProps[]
    saleCategories?: SaleCategoriesType[]
    needServiceButton?: boolean
}

/**
 ** Interface for Category Url
 * @interface
 */
export interface Url {
    url: string
    hreflang: string // Notation for language: 'en' or 'fr'
}

export interface MegaNavigationProps {
    categoryData: CategoryDataProps
    path?: string
    onCategoryClicked?: (data: CategoryDataProps, currentView: string) => void
    onBackLabelClicked?: () => void
    currentView?: string
    backToLabel?: string
    shopAllLabel?: string
    megaNavClicked?: (label: string) => void
    autoServiceButton?: () => JSX.Element
    enableShopAllLabel?: boolean
    shopAllLinkLabel?: string
    shopAllPlaceholderUrl?: string
    shopAllExclusionIds?: string[]
    ariaLabelShopAll?: string
}

export interface MegaNavigationListProps {
    categoryMapData: CategoryDataProps[]
    path?: string
    onCategoryClicked?: (data: CategoryDataProps, currentView: string) => void
    currentView?: string
    style: 'normal' | 'bold'
    megaNavClicked?: (label: string) => void
    listRef?: React.Ref<HTMLUListElement>
    currentIndexRef?: React.RefObject<Record<string, unknown>>
    forwardActionRef?: React.MutableRefObject<boolean>
}

export interface MegaNavigationTileProps {
    tileData: SaleCategoriesType[]
}

export enum ItemColorType {
    highlight = 'highlight',
    standard = 'standard',
}
export interface SaleCategoriesType extends CategoryDataProps {
    buttonCtaText: string
    type: string
}
