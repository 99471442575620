/* eslint-disable complexity */
import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/checkoutDrawer.actionCreators'
import { CheckoutAppointmentInfo, CheckoutDrawerState } from '../../redux/models/checkout.interface'
import { defaultCountry, defaultCountryName } from '../../components/Accounts/Addresses/Addresses.constant'
import { CartResponseErrorData, CartResponseErrorDTO } from '../models/cart.interface'
import {
    resetProfileBillingStatus,
    setCheckoutContactUpdateStatus,
    setCheckoutShippingStatus,
    setPaymentDetailsError,
    setPaymentFailureError,
    setPaymentSuccess,
    setProfileBillingSuccess,
} from '../actionCreators/checkoutDrawer.actionCreators'
import isActionPartOfList from '../utils/isActionPartOfList'

type Action = ActionType<typeof actions>

const initialState: CheckoutDrawerState = {
    checkoutContactInfo: { email: '', phone: '' },
    checkoutShippingInfo: {
        firstName: '',
        lastName: '',
        addressLineOne: '',
        addressLineTwo: '',
        city: '',
        province: '',
        country: '',
        postalCode: '',
    },
    checkoutPickupInfo: {
        orderPickupInPerson: null,
        pickupLocation: '',
    },
    checkoutAppointmentInfo: {
        orderPickupInPerson: null,
        appointmentDate: '',
    } as unknown as CheckoutAppointmentInfo,
    checkoutPaymentInfo: {
        newCreditCard: true,
        billingAddress: {
            town: '',
            country: {
                isocode: defaultCountry,
                name: defaultCountryName,
            },
            line1: '',
            line2: '',
            postalCode: '',
            region: {
                countryIso: '',
                isocode: '',
                isocodeShort: '',
                name: '',
            },
            firstName: '',
            lastName: '',
            shippingAddress: true,
        },
        eligibleForFinancing: false,
        financingPlanId: 0,
        maskedCardNumber: '',
        cardType: {
            code: '',
            name: '',
        },
        expiryMonth: '',
        expiryYear: '',
        saved: false,
        saveToMyProfile: false,
        defaultPayment: false,
    },
    isSaveBillingAddress: false,
    checkoutPaymentError: '',
    checkoutDrawerFailure: {
        checkoutContactUpdateStatus: {} as CartResponseErrorData,
        checkoutShippingFailure: {} as unknown as CartResponseErrorDTO,
        checkoutPaymentDetailsError: '',
    },
    checkoutShippingSuccess: false,
    checkoutShippingError: {} as CartResponseErrorData,
    checkoutDrawerSuccess: {
        isPaymentSuccess: false,
        isProfileBillingSuccess: '',
    },
    checkoutLoyaltyInfo: {
        cardNumber: '',
    },
    isInitiatePlaceOrderSequence: false,
}

const checkoutStatusActionsList = [
    setPaymentFailureError,
    setCheckoutContactUpdateStatus,
    setCheckoutShippingStatus,
    setPaymentSuccess,
    setProfileBillingSuccess,
    resetProfileBillingStatus,
    setPaymentDetailsError,
]

/**
 * Function to perform actions with respect to updating status of drawer events
 * @param {CartState} state
 * @param {Action} action
 * @return {CartState}
 */
const checkoutStatusActions = (state: CheckoutDrawerState = initialState, action: Action): CheckoutDrawerState => {
    if (action.type === getType(setPaymentFailureError)) {
        return {
            ...state,
            checkoutPaymentError: action.payload,
        }
    } else if (action.type === getType(setCheckoutContactUpdateStatus)) {
        return {
            ...state,
            checkoutDrawerFailure: {
                ...state.checkoutDrawerFailure,
                checkoutContactUpdateStatus: action.payload,
            },
        }
    } else if (action.type === getType(setCheckoutShippingStatus)) {
        return {
            ...state,
            checkoutShippingSuccess: action.payload.isUpdateSuccess,
            checkoutShippingError: action.payload.errorResponse?.data,
            checkoutDrawerFailure: {
                ...state.checkoutDrawerFailure,
                checkoutShippingFailure: action.payload.errorResponse,
            },
        }
    } else if (action.type === getType(setPaymentSuccess)) {
        return {
            ...state,
            checkoutDrawerSuccess: {
                ...state.checkoutDrawerSuccess,
                isPaymentSuccess: action.payload,
            },
        }
    } else if (action.type === getType(setProfileBillingSuccess)) {
        return {
            ...state,
            checkoutDrawerSuccess: {
                ...state.checkoutDrawerSuccess,
                isProfileBillingSuccess: action.payload as unknown as string,
            },
        }
    } else if (action.type === getType(resetProfileBillingStatus)) {
        return {
            ...state,
            checkoutDrawerSuccess: {
                ...state.checkoutDrawerSuccess,
                isProfileBillingSuccess: '',
            },
        }
    } else if (action.type === getType(setPaymentDetailsError)) {
        return {
            ...state,
            checkoutDrawerFailure: {
                ...state.checkoutDrawerFailure,
                checkoutPaymentDetailsError: action.payload,
            },
        }
    }
    return state
}

export const checkoutDrawerReducer = (
    state: CheckoutDrawerState = initialState,
    action: Action,
): CheckoutDrawerState => {
    if (isActionPartOfList(checkoutStatusActionsList, action.type)) {
        return checkoutStatusActions(state, action)
    }
    switch (action.type) {
        case getType(actions.setCheckoutContact): {
            return { ...state, checkoutContactInfo: action.payload }
        }
        case getType(actions.setCheckoutPickup): {
            return { ...state, checkoutPickupInfo: action.payload }
        }
        case getType(actions.setCheckoutAppointment): {
            return { ...state, checkoutAppointmentInfo: action.payload }
        }
        case getType(actions.setCheckoutShipping): {
            return { ...state, checkoutShippingInfo: action.payload }
        }
        case getType(actions.setCheckoutPayment): {
            return {
                ...state,
                checkoutPaymentInfo: action.payload,
            }
        }
        case getType(actions.resetCheckoutValidationsAction): {
            return {
                ...state,
                checkoutShippingSuccess: false,
                checkoutDrawerFailure: {
                    ...state.checkoutDrawerFailure,
                    checkoutShippingFailure: {} as unknown as CartResponseErrorDTO,
                },
            }
        }
        case getType(actions.setCheckoutLoyaltyInfo): {
            return {
                ...state,
                checkoutLoyaltyInfo: action.payload,
            }
        }
        case getType(actions.setIsSaveBillingAddress): {
            return { ...state, isSaveBillingAddress: action.payload }
        }
        case getType(actions.setInitiatePlaceOrderSequence): {
            return { ...state, isInitiatePlaceOrderSequence: action.payload }
        }
        default:
            return state
    }
}
