import { magicNumber } from './magicNumber'

/**
 * Converts amount to full decimal format
 * @param {number | null | undefined | string} amount
 * @return {number} return decimal implied format number
 */
export const convertDecimalImpliedFormat = (amount: number | null | undefined | string): number => {
    const multipliedAmt = Number(amount) * magicNumber.HUNDRED || 0
    return parseInt(multipliedAmt.toString())
}

/**
 * Rounds Off the value to given dividend
 * @param {number} value
 * @param {number} dividend
 * @return {number}
 */
export const roundOffValue = (value: number, dividend: number): number => Math.round(value / dividend) * dividend

/**
 * Check argument for nullish type and always return number
 * @param {number} value
 * @return {number}
 */
export const getNumberOrDefault = (value: number | undefined | null): number => Number(value) || 0
