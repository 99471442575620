export const FETCH_STORE_DETAILS = 'FETCH_STORE_DETAILS'
export const FETCH_STORE_DETAILS_SUCCESS = 'FETCH_STORE_DETAILS_SUCCESS'
export const FETCH_STORE_DETAILS_FAILURE = 'FETCH_STORE_DETAILS_FAILURE'
export const FETCH_NEARBY_STORES_REQUEST = 'FETCH_NEARBY_STORES_REQUEST'
export const FETCH_NEARBY_STORES_SUCCESS = 'FETCH_NEARBY_STORES_SUCCESS'
export const FETCH_NEARBY_STORES_FAILURE = 'FETCH_NEARBY_STORES_FAILURE'
export const SET_SELECTED_STORE_NAME_SUCCESS = 'SET_SELECTED_STORE_NAME_SUCCESS'
export const RESET_SELECTED_STORE_TOAST_SUCCESS = 'RESET_SELECTED_STORE_TOAST_SUCCESS'
export const FETCH_STORE_FILTERS_SUCCESS = 'FETCH_STORE_FILTERS_SUCCESS'
export const FETCH_STORE_FILTERS_FAILURE = 'FETCH_STORE_FILTERS_ERROR'
export const SET_PREFERRED_STORE_ID_SUCCESS = 'SET_PREFERRED_STORE_ID_SUCCESS'
export const SET_PREFERRED_STORE_DETAILS = 'SET_PREFERRED_STORE_DETAILS'
export const SET_PREFERRED_STORE_DETAILS_ERROR = 'SET_PREFERRED_STORE_DETAILS_ERROR'
export const STORE_GEO_DETAILS = 'STORE_GEO_DETAILS'
export const SET_NEARBY_FOR_A_STORE = 'SET_NEARBY_FOR_A_STORE'
export const SET_NEARBY_FOR_SKU = 'SET_NEARBY_FOR_SKU'
export const SET_NEARBY_FOR_POSTAL_CODE = 'SET_NEARBY_FOR_POSTAL_CODE'
export const SET_NEARBY_FOR_STORE_LOCATOR = 'SET_NEARBY_FOR_STORE_LOCATOR'
export const SET_IS_NEARBY_STORE_LIST_API_DONE = 'SET_IS_NEARBY_STORE_LIST_API_DONE'
export const SET_IS_NEARBY_STORE_LIST_API_SUCCESS = 'SET_IS_NEARBY_STORE_LIST_API_SUCCESS'
export const SET_IS_STORE_DETAILS_FETCHED = 'SET_IS_STORE_DETAILS_FETCHED'
export const SET_STORE_API_TRIANGULATION_FAILS = 'SET_STORE_API_TRIANGULATION_FAILS'
export const FETCH_NEARBY_STORES_ERROR_CODE = 'FETCH_NEARBY_STORES_ERROR_CODE'
export const SET_STORE_CHANGE = 'SET_STORE_CHANGE'
export const SET_IS_GEOLOCATION_ALLOWED = 'SET_IS_GEOLOCATION_ALLOWED'
export const SET_HELIUM_INFLATION_STORE_TOGGLE = 'SET_HELIUM_INFLATION_STORE_TOGGLE'
