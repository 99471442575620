import { createAction } from 'typesafe-actions'

import { CartResponseErrorDTO, ClickToPaySTHValidationDTO, FilteredCartData } from '../models/cart.interface'
import { OrderNotificationSubscriptionResponse } from '../models/orderConfirmation.interface'
import {
    GET_ORDER_CONFIRMATION_DATA_SUCCESS,
    GET_ORDER_CONFIRMATION_DATA_FAILURE,
    TOGGLE_NOTIFICATION_SUBSCRIPTION,
    SET_ORDER_NOTIFICATION_ERROR,
    PLACE_ORDER_CONFIRMATION_DATA_SUCCESS,
    PLACE_ORDER_CONFIRMATION_DATA_FAILURE,
    CLICK_TO_PAY_STH_ADDRESS_VALIDATION_SUCCESS,
    CLICK_TO_PAY_STH_ADDRESS_VALIDATION_ERROR,
} from '../types/orderConfirmation/orderConfirmation.actionTypes.contant'

export const orderConfirmationSuccess = createAction(
    GET_ORDER_CONFIRMATION_DATA_SUCCESS,
    (payload: FilteredCartData) => payload,
)<FilteredCartData>()

export const orderConfirmationFailure = createAction(GET_ORDER_CONFIRMATION_DATA_FAILURE)<CartResponseErrorDTO>()

export const toggleNotificationSubscription = createAction(
    TOGGLE_NOTIFICATION_SUBSCRIPTION,
)<OrderNotificationSubscriptionResponse>()

export const setOrderNotificationError = createAction(SET_ORDER_NOTIFICATION_ERROR)<boolean>()

export const placeOrderConfirmationSuccess = createAction(
    PLACE_ORDER_CONFIRMATION_DATA_SUCCESS,
    (payload: FilteredCartData) => payload,
)<FilteredCartData>()

export const placeOrderConfirmationFailure = createAction(PLACE_ORDER_CONFIRMATION_DATA_FAILURE)<CartResponseErrorDTO>()

export const clickToPayShippingAddressValidationSuccess = createAction(
    CLICK_TO_PAY_STH_ADDRESS_VALIDATION_SUCCESS,
)<ClickToPaySTHValidationDTO>()

export const clickToPayShippingAddressValidationError = createAction(
    CLICK_TO_PAY_STH_ADDRESS_VALIDATION_ERROR,
)<CartResponseErrorDTO>()
