import { MutableRefObject, useCallback } from 'react'
import classnames from 'classnames'
import { PREFIX } from '../../config'
import { STICKY_TO_BOTTOM } from '../../globalConstants'
import sessionStorageService from '../../utils/sessionStorageService'
import { MagicNumber } from '../../analytics/analytics.type'
import { offerConstants } from '../Accounts/Rewards/Offers/OffersCard.constants'
import { Offers } from '../../redux/models/offers.interface'
import { isArrayNotEmpty } from '@nl/lib'

const SCROLL_STEP = 500
const WHEEL_EVENT_DELAY = 150
const stylePrefix = `${PREFIX}-loyalty-offers`
const YES = 'Y'
const clickCounterSessionKey = 'clickCounterSessionKey'

export const STICK_TO_FOOTER = 'on-footer'
export const STICK_TO_BOTTOM = 'on-bottom'
export const EXPANDED = 'expanded'
export const HAS_OFFERS = 'has-offers'
export const TRIANGLE_SELECT = ``

export const defineContainerClasses = (isCollapsed: boolean, isLoggedIn: boolean, isMobileLayout: boolean): string => {
    return classnames(
        `${stylePrefix}_container`,
        !isCollapsed && EXPANDED,
        isLoggedIn && HAS_OFFERS,
        !isCollapsed && isFooterVisible(isMobileLayout) && 'expanded-footer-vis',
    )
}

export const definePanelClasses = (isCollapsed: boolean, isTriangleSelectUser: boolean): string => {
    return classnames(
        `${stylePrefix}_hidden-container`,
        !isCollapsed && EXPANDED,
        isTriangleSelectUser && `${PREFIX}-triangle-select`,
    )
}

export const getElementByClassName = (className: string): HTMLDivElement => {
    return document.getElementsByClassName(className)[0] as HTMLDivElement
}

export const useMouseWheelScrollHandler = () =>
    useCallback((): void => {
        const scrollableContainer = getElementByClassName(`${stylePrefix}_scrollable-wrapper`)
        const offersContainer = getElementByClassName(`${stylePrefix}_offers-container`)

        const wheelEventHandler = (event: WheelEvent) => {
            const scrollValue = event.deltaY > 0 ? SCROLL_STEP : -SCROLL_STEP
            const scrollOptions: ScrollToOptions = {
                left: scrollableContainer.scrollLeft + scrollValue,
                top: 0,
                behavior: 'smooth',
            }
            scrollableContainer.scroll(scrollOptions)
        }

        const debounceWheelEvent = (wheelEventListener: (e: WheelEvent) => void, delay: number) => {
            let lastFunc: NodeJS.Timeout
            let isExecuted = false
            return function (event: WheelEvent) {
                if (!isExecuted) {
                    wheelEventListener(event)
                    isExecuted = true
                }
                clearTimeout(lastFunc)
                lastFunc = setTimeout(() => {
                    isExecuted = false
                }, delay)
            }
        }

        offersContainer.addEventListener('wheel', debounceWheelEvent(wheelEventHandler, WHEEL_EVENT_DELAY), {
            passive: true,
        })
    }, [])

export const useDragScrollHandler = (isClickForDraggingRef: MutableRefObject<boolean>) =>
    useCallback((): void => {
        let isDown = false
        let startX: number
        let scrollLeft: number
        const dragStyle = `${stylePrefix}_draggable`
        const scrollableContainer = getElementByClassName(`${stylePrefix}_scrollable-wrapper`)

        const cancelDragScrolling = () => {
            isDown = false
            scrollableContainer.classList.remove(dragStyle)
        }

        scrollableContainer.addEventListener('mousedown', (event: MouseEvent) => {
            isDown = true
            scrollableContainer.classList.add(dragStyle)
            startX = event.pageX - scrollableContainer.offsetLeft
            scrollLeft = scrollableContainer.scrollLeft
        })

        scrollableContainer.addEventListener('mouseup', () => {
            cancelDragScrolling()
        })

        scrollableContainer.addEventListener('mouseleave', () => {
            cancelDragScrolling()
        })

        scrollableContainer.addEventListener('mousemove', event => {
            if (!isDown) {
                isClickForDraggingRef.current = false
                return
            }
            event.preventDefault()
            isClickForDraggingRef.current = true
            const movedDistance = event.pageX - startX
            scrollableContainer.scrollLeft = scrollLeft - movedDistance
        })
    }, [isClickForDraggingRef])

export const calculateHighestElementTopPosition = (isMobileLayout: boolean): number => {
    const allTopPositions = []
    if (isMobileLayout) {
        const stickyToBottomElems = Array.from(document.getElementsByClassName(STICKY_TO_BOTTOM))
        stickyToBottomElems.forEach(elem => allTopPositions.push(elem.getBoundingClientRect().top))
    }
    const pageBottom = window.innerHeight

    allTopPositions.push(pageBottom)

    return Math.min(...allTopPositions)
}

export const isFooterVisible = (isMobileLayout: boolean): boolean => {
    const footer = document.querySelector('footer')
    if (!footer) {
        return false
    }

    const pageBottom = window.innerHeight

    if (isMobileLayout) {
        const highestStickyElementTopPosition = calculateHighestElementTopPosition(true)
        return footer.getBoundingClientRect().top <= highestStickyElementTopPosition
    } else {
        return footer.getBoundingClientRect().top <= pageBottom
    }
}

export const calculateComponentPosition = (isMobileLayout: boolean) => {
    const toggleButton = getElementByClassName(`${stylePrefix}_container`)
    if (!toggleButton || isFooterVisible(isMobileLayout)) {
        return
    }
    const allTopPositions = []
    if (isMobileLayout) {
        const stickyToBottomElems = Array.from(document.getElementsByClassName(STICKY_TO_BOTTOM))
        stickyToBottomElems.forEach(elem => allTopPositions.push(elem.getBoundingClientRect().top))
    }
    const pageBottom = window.innerHeight

    const footer = document.querySelector('footer')
    const positionBeforeFooter = footer ? footer.getBoundingClientRect().top : pageBottom

    allTopPositions.push(pageBottom)
    allTopPositions.push(positionBeforeFooter)

    const highestStickyElementTopPosition = Math.min(...allTopPositions)
    const distanceFromHighestElemTopToPageBottom = Math.ceil(pageBottom - highestStickyElementTopPosition)
    toggleButton.style.bottom = `${distanceFromHighestElemTopToPageBottom}px`
}

export const getPanelAppearancesCount = (): number => {
    return Number(sessionStorageService.getItem(offerConstants.loyaltyOffersAppearedTimes))
}

export const incrementPanelAppearancesCount = (): void => {
    const counter = getPanelAppearancesCount()
    const incrementedValue = String(counter + MagicNumber.ONE)
    sessionStorageService.setItem(offerConstants.loyaltyOffersAppearedTimes, incrementedValue)
}

export const isPanelWasDismissed = (): boolean => {
    return Boolean(sessionStorageService.getItem(offerConstants.loyaltyOffersPanelDismiss))
}

export const dismissLoyaltyOffersPanel = (cName: string, dateToExpire: string): void => {
    if (document) document.cookie = cName + `=true; expires= ${dateToExpire}; path=/`
}

export const getCookie = (cName: string): boolean => {
    const name = cName + '='
    if (document) {
        const cDecoded = decodeURIComponent(document.cookie)
        const cArr = cDecoded.split('; ')
        let res = ''
        cArr.forEach(val => {
            if (val.indexOf(name) === 0) res = val.substring(name.length)
        })
        if (res) return true
        return false
    }
    return false
}

export const markPanelWasExpanded = (): void => {
    sessionStorageService.setItem(offerConstants.loyaltyOffersWereExpanded, YES)
}

export const isPanelWasExpanded = (): boolean => {
    return Boolean(sessionStorageService.getItem(offerConstants.loyaltyOffersWereExpanded))
}

export const isPanelBlockedForCurrentPage = (blockListPagePaths: string[]): boolean => {
    const url = new URL(window.location.href)
    const isPageMatches = blockListPagePaths.some(pagePath => url.pathname.endsWith(pagePath))
    const isPathMatches = blockListPagePaths.some(pagePath => url.pathname.includes(pagePath))
    return isPageMatches || isPathMatches
}

export const initializeClickCount = (): void => {
    sessionStorageService.setItem(clickCounterSessionKey, String(MagicNumber.ZERO))
}

export const getPageClickCount = (): number => {
    if (sessionStorageService.getItem(clickCounterSessionKey) === null) {
        initializeClickCount()
    }
    return Number(sessionStorageService.getItem(clickCounterSessionKey))
}

export const incrementClickCount = (): void => {
    const currentCount = getPageClickCount()
    const incrementedCount = String(currentCount + MagicNumber.ONE)
    sessionStorageService.setItem(clickCounterSessionKey, incrementedCount)
}

/**
 * function calls to filter combo exclusion offer from offerlist
 * @param { Offers[] } offerList
 * @param { string[][] } comboExclude
 * @returns {Offers[]}
 */
export const getComboExclusionsList = (offerList?: Offers[], comboExclude?: string[][]): Offers[] => {
    return offerList?.filter(offer => excludeCombo(offer, comboExclude)) || []
}

/**
 * function calls to check offer include combo exclusion banner
 * @param { Offers } offer
 * @param { string[][] } comboExclude
 * @returns {boolean}
 */
const excludeCombo = (offer?: Offers, comboExclude?: string[][]): boolean => {
    const filterBanner =
        isArrayNotEmpty(comboExclude) && comboExclude?.map(combo => combo.every(el => offer?.banner.includes(el)))
    return !Boolean(filterBanner && filterBanner?.includes(true))
}
