import React from 'react'
import PropTypes from 'prop-types'
import { FixedHeightBannerProps } from './../ShoppableComponent.type'
import { BREAKPOINTS, PREFIX } from '../../../config'

const componentClassName = `${PREFIX}-fixed-height-banner`

/**
 * FixedHeightBanner
 * @param props : FixedHeightBannerProps
 * @returns : JSX.Element
 */
const FixedHeightBanner: React.FC<FixedHeightBannerProps> = ({ ...props }): JSX.Element => {
    /**
     * This function would render the image based on the available image urls
     * @returns {JSX.Element} returns the image element
     */
    const renderImage = () => {
        if (props.imageUrlMobileAndTablet) {
            return (
                <picture>
                    <source media={`(min-width:${BREAKPOINTS.desktopMinWidth}px)`} srcSet={props.imageUrl} />
                    <img src={props.imageUrlMobileAndTablet} alt={`${props.imageAltText}`} />
                </picture>
            )
        } else {
            return <img src={props.imageUrl} alt={`${props.imageAltText}`} />
        }
    }
    return (
        <div className={`${componentClassName} ${PREFIX}-banner-${props.componentWidthType}`}>
            {props.imageLink ? (
                <a href={`${props.imageLink}`}>
                    <div className={`${componentClassName}__image`}>{renderImage()}</div>
                </a>
            ) : (
                <div className={`${componentClassName}__image`}>{renderImage()}</div>
            )}
            <div className={`${props.fontColor} ${componentClassName}__text-content`}>
                <div className={`${componentClassName}__title`}>
                    <h2>{props.title}</h2>
                </div>
                <div
                    className={`${componentClassName}__description`}
                    dangerouslySetInnerHTML={{ __html: props.bannerDescription || '' }}></div>
            </div>
        </div>
    )
}

FixedHeightBanner.propTypes = {
    title: PropTypes.string.isRequired,
    bannerDescription: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    imageAltText: PropTypes.string.isRequired,
    componentWidthType: PropTypes.string.isRequired,
}

export default FixedHeightBanner
