import React from 'react'
import { PREFIX } from '../config'
import ButtonV2 from '../ButtonV2'
import { Animation, Size, Variation } from '../../types'
import { applyAkamaiPolicy } from '../../utils'
import { ButtonColors } from '../ButtonV2/Button.types'
import { statusLabel } from '../../globalConstants/global.constant'
import { BaseIcon } from '../Icon'
import { CoachMarkOfferCard, ITemplateCard } from './TemplateCard.type'

/**
 * Display Template card
 * @param {ITemplateCard} props Template card component props
 * @returns { JSX.Element } returning the template card
 */
const TemplateCard: React.FC<ITemplateCard> = ({ ...props }): JSX.Element => {
    const { columns, swapOfferCTALabel, dummyOffer, akamaiPolicy, isSwapList, customClass } = props
    const { md, sm, xs } = columns
    const { offersCardImgComponentName, returnPolicy } = akamaiPolicy
    const componentClassName = `${PREFIX}-template-card`
    const customModifyClass = customClass ? `${PREFIX}-${customClass}` : ''

    /**
     * render Body for template card
     * @param {CoachMarkOfferCard} offer offer card
     * @returns { JSX.Element }
     */
    const renderBody = (offer: CoachMarkOfferCard): JSX.Element => (
        <div className={`${componentClassName}__container`}>
            <div className={`${componentClassName}__banner`}>
                <img
                    className={`${componentClassName}__logo`}
                    alt=""
                    {...applyAkamaiPolicy(offer?.bannerLogo, offersCardImgComponentName, false, returnPolicy)}
                />
            </div>
            <div className={`${componentClassName}__img-container`}>
                <picture>
                    <source
                        type="image/png"
                        {...applyAkamaiPolicy(
                            offer?.swapOffersTourBannerImg,
                            offersCardImgComponentName,
                            false,
                            returnPolicy,
                        )}
                    />
                    <img
                        className={`${componentClassName}__img`}
                        alt={offer?.swapOffersTourBannerImgAltText || ''}
                        {...applyAkamaiPolicy(
                            offer?.swapOffersTourBannerImg,
                            offersCardImgComponentName,
                            false,
                            returnPolicy,
                        )}
                    />
                </picture>
            </div>
            <div className={`${componentClassName}__wrapper-align`}>
                <div className={`${componentClassName}__wrapper--subtitle`}>
                    <p
                        title={offer?.swapOffersTourBannerHeading || ''}
                        className={`${componentClassName}__wrapper--subtitle-description`}>
                        {offer?.swapOffersTourBannerHeading || ''}
                    </p>
                </div>
                <div className={`${componentClassName}__wrapper-end`}>
                    <span
                        className={`${componentClassName}__wrapper--dates`}
                        title={offer?.swapOffersTourOfferExpiryLabel || ''}>
                        {offer?.swapOffersTourOfferExpiryLabel || ''}
                    </span>
                </div>
            </div>
        </div>
    )

    /**
     * render footer for template card
     * @returns { JSX.Element }
     */
    const renderFooter = (): JSX.Element => (
        <div className={`${componentClassName}__footer`}>
            <ButtonV2
                size={Size.MINI}
                variant={Variation.PRIMARY}
                color={isSwapList ? ButtonColors.DARK : ButtonColors.LIGHT}
                customClass={!isSwapList ? `${componentClassName}__cta-icon` : ''}
                icon={{ size: Size.LARGE, type: isSwapList ? 'ct-swap' : 'ct-confirm' }}
                a11y={{ disabled: true }}
                disabled>
                {isSwapList && <BaseIcon animation={{ type: Animation.PULSE }} size={Size.XL} outline={true} />}
                {isSwapList ? swapOfferCTALabel || '' : statusLabel?.activatedStatus}
            </ButtonV2>
        </div>
    )

    return (
        <section
            className={`${PREFIX}-col-md-${md} ${PREFIX}-col-sm-${sm} ${PREFIX}-col-xs-${xs} ${componentClassName}__tile ${customModifyClass}`}>
            <div className={`${componentClassName}__wrapper`}>
                {renderBody(dummyOffer)}
                {renderFooter()}
            </div>
        </section>
    )
}

export default TemplateCard
