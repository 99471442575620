import React from 'react'
import PropTypes from 'prop-types'
import { componentType } from '../../globalConstants/global.constant'
import { userGreetingClassName } from './UserGreeting.constant'
import { UserGreetingType } from './UserGreeting.type'
import { PREFIX } from '../config'
import Heading from '../Heading'

const UserGreeting: React.FC<UserGreetingType> = ({ ...props }): JSX.Element => {
    const {
        greetingTitle,
        greetingSubtitle,
        userName,
        logo,
        hasLoyalty,
        isGreetingSummary,
        componentMode,
        isReverseStyle,
    } = props

    const getTriangleSelectTemplate = (): JSX.Element => {
        return (
            <div className={`${userGreetingClassName}`}>
                {logo && (
                    <div className={`${userGreetingClassName}__image`}>
                        <img src={logo} alt="" />
                        <hr className={`${userGreetingClassName}__border`} />
                    </div>
                )}
                {greetingTitle && (
                    <h3
                        className={`${userGreetingClassName}__title`}
                        dangerouslySetInnerHTML={{ __html: greetingTitle }}
                    />
                )}
                {greetingSubtitle && (
                    <div
                        className={`${userGreetingClassName}__subtitle`}
                        dangerouslySetInnerHTML={{ __html: greetingSubtitle }}
                    />
                )}
            </div>
        )
    }

    const getDefaultTemplate = (): JSX.Element => {
        return (
            <div className={`${userGreetingClassName} ${logo && greetingTitle ? `${PREFIX}-row` : ''}`}>
                {greetingTitle && getDefaultGreetingContent()}
                {logo && (
                    <div className={`${userGreetingClassName}__image`}>
                        <img src={logo} alt="" />
                    </div>
                )}
            </div>
        )
    }

    const getDefaultGreetingContent = () => {
        return (
            <div
                className={`${
                    isGreetingSummary ? `${userGreetingClassName}__wrapper` : `${userGreetingClassName}__content`
                } ${isReverseStyle ? `${PREFIX}-xs-none ${PREFIX}-sm-none ${PREFIX}-md-block` : ''}`}>
                <Heading
                    variant={isGreetingSummary ? 'h1' : 'h2'}
                    componentClass={`${
                        isGreetingSummary
                            ? `${userGreetingClassName}__header`
                            : `${userGreetingClassName}__title ${PREFIX}-body-xl`
                    } `}>
                    {greetingTitle}
                </Heading>
                {userName && (
                    <Heading variant={'h2'} componentClass={`${userGreetingClassName}__user ${PREFIX}-body-xl`}>
                        {userName}
                    </Heading>
                )}
                {hasLoyalty && greetingSubtitle && (
                    <p
                        className={`${
                            isGreetingSummary ? `${userGreetingClassName}__desc` : `${userGreetingClassName}__subtitle`
                        }`}
                        dangerouslySetInnerHTML={{ __html: greetingSubtitle }}
                    />
                )}
            </div>
        )
    }

    return componentMode === componentType.triangleSelect ? getTriangleSelectTemplate() : getDefaultTemplate()
}

UserGreeting.propTypes = {
    greetingTitle: PropTypes.string,
    greetingSubtitle: PropTypes.string,
    componentMode: PropTypes.string,
}

export default UserGreeting
