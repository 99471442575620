import {
    SwiperOptions,
    SwiperMethods,
} from './../../src/components/HeadbandBannerComponent/HeadbandBannerComponent.type'

/**
 * Creates a new Swiper instance.
 * @param {string} selector - The selector for the Swiper container.
 * @param {SwiperOptions} options - The options for the Swiper instance.
 * @returns {SwiperMethods} The new Swiper instance.
 */
export const createSwiper = (selector: string, options: SwiperOptions): SwiperMethods => {
    // This disabled typescript error will be fixed at https://canadian-tire.atlassian.net/browse/SITEPOD-16818
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return new Swiper(selector, options) as SwiperMethods
}
