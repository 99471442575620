import React from 'react'

import { ButtonV2, Variation } from '@nl/lib'

import { FlyoutHeaderProps } from './FlyoutHeader.type'

/**
 *
 * @param props
 */
export const FlyoutHeader: React.FC<FlyoutHeaderProps> = props => {
    const { label, showCloseBtn, closeIconLabel, closeFlyout } = props

    return (
        <div className="flyout-header-container">
            <h1>{label}</h1>
            {showCloseBtn && (
                <ButtonV2
                    data-testid="close-flyout-btn"
                    onClick={closeFlyout}
                    variant={Variation.TERTIARY}
                    a11y={{
                        label: closeIconLabel,
                    }}
                    icon={{ type: 'ct-close', size: 'lg' }}
                    customClass="flyout-header-container__close-btn"
                />
            )}
        </div>
    )
}
