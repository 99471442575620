import { regexPatterns } from '../globalConstants/regexPatterns.constant'
import { magicNumber } from '../utils'

/**
 * returns a certain number of last characters of a string
 * @param {string} string "12345"
 * @param {number} amount 2
 * @returns {string} "45"
 */
export const getLastCharacters = (string = '', amount: number): string => {
    return string.slice(-amount)
}
/**
 * @description an object to match boolean values with string equivalents(from AEM, API responses etc)
 */
export const stringToBooleanMap = {
    T: true,
    F: false,

    Y: true,
    N: false,

    true: true,
    false: false,

    on: true,
    off: false,

    undefined: false,
} as const

/**
 * returns true if string is in the list
 * @param {string} list "item 1, item 2, item 3,..."
 * @param {string} string "item 2"
 * @param {string} delimiter
 * @returns {boolean}
 */
export const isStringInList = (list: string, string: string, delimiter = ','): boolean => {
    return !!list.split(delimiter)?.find(item => item.trim().toLowerCase() === string.trim().toLowerCase())
}

/**
 * function to change casing from all caps to sentence case
 * @param {string} string 'my test strIng'
 * @param {boolean} shouldLowercaseLetters
 * @returns {string} 'My Test String'
 */
export const capitalizeWords = (string = '', shouldLowercaseLetters?: boolean): string => {
    string = shouldLowercaseLetters ? string.toLowerCase() : string

    return string.replace(regexPatterns.firstLetters, letter => letter.toUpperCase())
}

/**
 * function to replace all the occurances of given character
 * @param {string} string '01P3P56P'
 * @param {string} searchValue 'P'
 * @param {string} replaceValue 'R'
 * @returns {string} '01R3R56R'
 */
export const replaceStringCharacter = (string: string, searchValue: string | RegExp, replaceValue: string): string => {
    return string.split(searchValue).join(replaceValue)
}

/**
 * Check argument for nullish type and always return number
 * @param {string} value
 * @returns {string}
 */
export const getStringOrDefault = (value: string | undefined | null): string => (typeof value === 'string' ? value : '')

/**
 * Generates a unique identifier string.
 * @returns {string} A unique identifier string.
 */
export const generateUniqueId = (): string => {
    return Math.random().toString(magicNumber.THIRTYSIX).slice(magicNumber.TWO, magicNumber.ELEVEN)
}
