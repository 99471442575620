import { ActionType, getType } from 'typesafe-actions'

import { Suggest } from '@nl/lib'
import {
    fetchSuggestionsSuccessAction,
    fetchSuggestionsErrorAction,
    fetchSuggestedProductsSuccessAction,
    fetchSuggestedProductsErrorAction,
} from '../actionCreators'
import * as actions from '../actionCreators'
import { EmptyActionType } from '../models/globalData.interface'

type Action = ActionType<typeof actions> | EmptyActionType

export interface SuggestState {
    suggest: Suggest
}

const emptySuggest: Suggest = {
    isCodeLookup: false,
    topHit: [
        {
            isRedirect: false,
            label: '',
            searchUrl: '',
            type: '',
        },
    ],
    products: [],
    categories: [],
    suggestions: [],
}

export const initialState: SuggestState = {
    suggest: emptySuggest,
}

export const suggestReducer = (state: SuggestState = initialState, action: Action): SuggestState => {
    switch (action.type) {
        // when suggestion call is successful, update all fields of suggestion data
        case getType(fetchSuggestionsSuccessAction): {
            return { ...state, suggest: action.payload }
        }
        // on suggestion call fail, return no data at all so 'no result' is shown
        case getType(fetchSuggestionsErrorAction): {
            return { ...state, suggest: emptySuggest }
        }
        // on product fetch success, only update product state. this is because there is no separate API call to fetch products only and we're forced to use suggest call
        // to fetch product. however, if the whole state is updated, the suggestions loaded will be overwritten, which we don't want. to get around this, only update
        // products when fetch product is called
        case getType(fetchSuggestedProductsSuccessAction): {
            return {
                ...state,
                suggest: {
                    ...state.suggest,
                    isCodeLookup: action.payload.isCodeLookup,
                    products: action.payload.products,
                },
            }
        }
        // in case product fetch errors, return empty list. not sure if any error messages need to be relayed to the client
        case getType(fetchSuggestedProductsErrorAction): {
            return {
                ...state,
                suggest: {
                    ...state.suggest,
                    products: [],
                },
            }
        }

        default:
            return state
    }
}
