export enum ProgressSizes {
    SMALL = 'small',
    LARGE = 'large',
}

export enum ProgressType {
    SPINNER = 'spinner',
    PROGRESS = 'progress',
}

export enum ProgressValuePosition {
    INSIDE = 'inside',
    BELOW = 'below',
}

/**
 * Interface for CheckboxProps
 * @interface
 */
export interface CircularProgressProps {
    value?: number
    size?: ProgressSizes
    children?: React.ReactNode
    type?: ProgressType
    displayValue?: boolean
    position?: ProgressValuePosition
    strokeColor?: string
}
