export const cardType = {
    card1Logo: 'visa',
    card2Logo: 'master card',
    card3Logo: 'american express',
    card4Logo: 'triangle rewards',
}

export enum cardErrorField {
    cardNumber = 'card_number',
    CVV = 'cvd',
    submit = 'submit',
    cvvError = 'cvvError',
    cardNumberError = 'cardNumberError',
}

export const paymentInformationConst = {
    errorIcon: 'ct-warning',
    maxDisplayCount: 5,
    yearSlice: -2,
    cardNumberSlice: -8,
    cardNumberDigitSlice: -4,
    cardMasking: '****',
    masterPass: 'masterPass selected',
    masterpassOption: 'masterpass',
    masterPassError475: '475',
    masterPassError476: '476',
    thirdPartyPaymentErrorKey: 'rspCode',
    clickToPaySTHValidationErrorKey: 'clickToPayValError',
    ctpInitPaymentError: 'clickToPayPaymentErrorMsg',
    payPalError365: '365',
    payPalError410: '410',
    payPalError461: '461',
    payPalError465: '465',
    payPalError466: '466',
    payPalError467: '467',
    payPalError468: '468',
    payPalErrorGeneral: '500',
    payPalShippingErrorPOBox: '501',
    payPalShippingErrorRestricted: '502',
    payPal: 'payPal selected',
    payPalOption: 'payPal',
    payPalRedirectParam: 'txn_id',
    paypalGeneralErrorMsg: 'genericPayPalError',
    payPalCartValidationError: 'PPCARTERR',
    payPalInitPaymentError: 'payPalPaymentErrorMsg',
    payPalMerchantDisabledError: 'PPMERCHERR',
    ctfsError100: '100',
    ctfsError190: '190',
    ctfsError400: '400',
    ctfsError410: '410',
    ctfsError800: '800',
    ctfsError810: '810',
    ctfsError510: '510',
    triangleCard: 'triangle',
    ctMoneyErrorCode: 400,
    ctMoney: 'CT Money',
    differentCard: 'Different card selected',
    clickToPaySTHCanadaPostSuggestionError: '901',
    clickToPaySTHPostalCodeOutOfRangeError: '902',
    clickToPaySTHDeliveryFeeUpdated: '903',
    clickToPaySTHOtherValidationError: ['904', '905', '906', '907'],
    clickToPayCanadaPostAction: 'canadaPostAction',
    clickToPayCanadaPostId: 'canadaPostId',
}

/**
 * Object representing credit card number related error codes
 */
export const invalidCardNumberErrCodes = {
    error30: 30,
    error31: 31,
    error32: 32,
}
/**
 * Object representing credid card cvv related error codes
 */
export const invalidCvdErrCodes = {
    error30: 30,
    error40: 40,
    error41: 41,
}
