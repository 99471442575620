import React from 'react'
import PropTypes from 'prop-types'
import { PREFIX } from '../config'
import { magicNumber, getSafePercent } from '../../utils'
import { StepProps } from './Step.type'

/**
 * Step component is used as a child to Progress Bar component and it is used to display
 * individual steps in a progress bar component.
 * @param {StepProps} props - completed, position, index, children, transitionDuration
 * completed - Determines the state of the Step whether it is completed or not
 * position - Determines the position of the step inside its parent (ProgressBar)
 * index - Index of the step inside its parent (ProgressBar)
 * children - Children of component
 * transitionDuration - Animation duration when Step component gets resized based on its state
 * @return {JSX.Element} returns Step component
 */
const Step: React.FC<StepProps> = ({ ...props }) => {
    const {
        completed,
        position,
        index,
        children,
        transitionDuration = magicNumber.THREEHUNDRED,
        vertical,
        customClass,
    } = props

    const safePosition = getSafePercent(position)

    const progressionPositionProp = vertical ? 'top' : 'left'

    const style = {
        [progressionPositionProp]: `${safePosition}%`,
        transitionDuration: `${transitionDuration}ms`,
    }

    const verticalClass = vertical ? `${PREFIX}-progress-bar__step--vertical` : ''

    return (
        <div
            className={`${PREFIX}-progress-bar__step ${verticalClass} ${customClass}`}
            data-testid={`${PREFIX}-progress-bar__step`}
            style={style}>
            {children
                ? children({
                      index,
                      completed,
                      position: safePosition,
                  })
                : ''}
        </div>
    )
}

Step.propTypes = {
    completed: PropTypes.bool,
    position: PropTypes.number,
    index: PropTypes.number,
    transitionDuration: PropTypes.number,
    children: PropTypes.func,
}

Step.displayName = 'Step'

export { Step }
