export interface BadgePriority {
    maxNumberToDisplay: number
    priorityAndColour: BadgePriorityDetails[]
    exclude: string[]
}

export interface BadgePriorityDetails {
    badgeId: string
    priority: number
    badgeLabel: string
    colorId: string
    disclaimer?: string | null
}

export interface SaleMessagesRule {
    minPercent: number
    absoluteValueThreshold: number
    maxPercent: number
    upToSaveMessage: string
    absoluteCutType: string
    saveMessage: string
}

export interface IBreadcrumb {
    displayProductName: boolean
    backToResults: string
    backToLabel: string
    backToCartLabel: string
    backToHomeLabel: string
    maxLinks: number
    homeLink: string
    backToSearchResults: string
    homeLabel: string
    backLabel: string
    searchResultsLabel: string
}

export interface IAccessibility {
    a11yRequiredLabel: string
    a11yBackToTopLabel: string
    a11yBreadcrumbLabel: string
    a11yCarouselNextLabel: string
    a11yCarouselPreviousLabel: string
    a11yCarouselSelectedLabel: string
    a11yCarouselUnselectedLabel: string
    a11yChangePickupStoreLabel: string
    a11yChangeShippingPostalCodeLabel: string
    a11yCloseIconLabel: string
    a11yCollapseText: string
    a11yDropdownMonthLabel: string
    a11yDropdownYearLabel: string
    a11yExpandText: string
    a11yHamburgerIcon: string
    a11yHeaderStoreAt: string
    a11yHeaderStoreCurrently: string
    a11yHeaderStorePreferred: string
    a11yMaxQuantityReached: string
    a11yMinQuantityReached: string
    a11yPasswordVisibilityToggleIcon: string
    a11yQuantityDecrementLabel: string
    a11yQuantityIncrementLabel: string
    a11yQuantityInputAriaLabel: string
    a11yQuantityLabel: string
    a11yRemoveLabel: string
    a11yReviewRating: string
    a11yServiceInstallation: string
    a11yStrikeOutPrice: string
    a11yStrikeOutPriceRange: string
    a11yTooltipIcon: string
    a11yVariantSelected: string
    a11yVariantUnSelected: string
    a11yVideoPlayLabel: string
    globalSpinnerLabel: string
    closeIconLabel: string
    a11yTelephoneAriaLabel: string
    a11yDropDownNotSelected: string
    a11yClickToReadFootnote: string
    a11ySuggestionResultAvailable: string
    a11yMapLocateMeButtonLabel: string
    a11yCheckExpressDeliveryEligibilityLabel?: string
    a11yOptionalLabel: string
    a11yLoadingSpinnerLabel: string
    a11yUpdatedQuantityText: string
    a11ySelectedLabel: string
    allyUnselectedLabel: string
    a11yViewTypeLabel: string
}

export interface IStoreLocator {
    a11yCurrentLocationCTAAriaLabel: string
    a11ySearchIconAriaLabel: string
    closeLabel: string
    closedLabel: string
    closesAtLabel: string
    closesInLabel: string
    closesLabel: string
    filterByLabel: string
    getItTodayLabel: string
    inStockLabel: string
    listViewLabel: string
    locateMeLabel: string
    mapViewLabel: string
    maxNumberOfStores: number
    maxNumberOfStoresHeader: number
    maxRadiusToSearchStore: number
    noResultDescription: string
    noResultFoundTitle: string
    onlineOrdersNotAcceptedMsg: string
    openLabel: string
    openNowLabel: string
    openingAtLabel: string
    opensLabel: string
    outOfStockLabel: string
    preferredStoreLabel: string
    saveLabel: string
    searchInputErrorMsg: string
    searchInputPH: string
    seeMoreCTALabel: string
    seeMoreCTAURL: string
    seeMoreStoresMsg: string
    setPreferredStoreCTALabel: string
    storeDetailsCTALabel: string
    storeSuccessfullySelectedMsg: string
    storeType: string
    title: string
    maxRadiusErrorCode: string
    a11yCloseStoreLocatorAriaLabel: string
    googleMapsMarkersColor: string
    showInStockFilterLabel?: string
    headerOnlyTitle: string
    storeChangeToastTimeOut: string
    initialNumberOfStores: number
    wishlistCartOnlyTitle: string
    a11yMapViewAriaLabel: string
    a11yListViewAriaLabel: string
    enableInStockOnlineInFlyout: boolean
    a11yShowStoreCount: string
    a11yStoresAvailableLabel: string
    noResultForCartInStoreInventoryDescription?: string
    noResultForCartInStoreInventoryTitle?: string
    noResultForCartTitle?: string
    noResultForCartDescription?: string
    enableCheckNearbyFlyoutForCart?: boolean
    storeRadius?: number
    pdpOnlyTitle: string
    comingSoonStockLabel: string
}

export interface IProduct {
    nowFromLabel: string
    saveFromLabel: string
    wasFromLabel: string
    fromLabel: string
    unitPriceLabel: string
    promotionalPriceLabel: string
    clearancePriceLabel: string
    thresholdValue: number
    coreChargesTooltipTitle: string
    inclCoreChargesLbl: string
    plusCoreChargesLbl: string
    coreChargesTooltipParagraph: string
    quantityCoreFeeLabel: string
    saveLabel: string
    envFeeTooltipTitle: string
    inclEnvFeeLbl: string
    plusEnvFeeLbl: string
    envFeeTooltipParagraph: string
    qtyAbbrLabel: string
    saleEndDaySoonMessage: string
    limitedStockMessage?: string
    threshold1: number
    threshold2: number
    threshold3: number
    generalExpressDeliveryDateLabel: string
    todayExpressDeliveryDateLabel: string
    tomorrowExpressDeliveryDateLabel: string
    postalCodeNotEligibleExpressDeliveryLabel?: string
    notEligibleExpressDeliveryTooltipHeaderText?: string
    notEligibleExpressDeliveryTooltipBodyText?: string
    notEligibleStandardDeliveryTooltipBodyText?: string
    notEligibleStandardDeliveryTooltipHeaderText?: string
    noExpressDeliveryToPostalBoxMsg?: string
    getItByLabel?: string
    fullCorporateAssortmentAvailableForOnlinePurchaseLabel: string
    onlineAssortmentAvailableForOnlinePurchaseLabel: string
    estimatedDeliveryDateBetween: string
    productColorLabel: string
    productSizeLabel: string
    sizeChartButtonTitle: string
    homeServicesTitle: string
    serviceLandingPage: string
    readyToDeliverLabel?: string
    orderNotEligibleForExpressDeliveryLabel?: string
    eachLabel: string
    bannerMaxPurchasableQuantity: string
    maxPurchasableQuantity: string
    defaultProductImage: string
    setOf4Label: string
    setOf4NowLabel: string
    selectAStoreLabel: string
    howToMeasureLabel: string
    bulkItemLabel: string
    bulkProductSTHFulfillmentMSG: string
    defaultProductImageAltText: string
    serviceMerchandiseImage: string
    wasLabel: string
    nowLabel: string
    perTireLabel: string
    perWheelsLabel: string
    fitmentCriticalNotesLabel: string
    fitmentUniversalLabel: string
    fitmentVehicleSpecificLabel: string
    inStockLabel: string
    addToCartLabel: string
    primarySellingBannerDomain: {
        CTR: string
        PTY: string
    }
    heliumFilledButtonLabel: string
    uninflatedButtonLabel: string
    inflationButtonLabel: string
    quickLookLabel: string
    notFoundOnAbsentSKuEnabled: boolean
}

export interface IGlobalLinks {
    orderDetailsLink: string
    loginPageLink: string
    weeklyOffersLink: string
    dashboardPageLink: string
    rewardsPageLink: string
    homePageLink: string
    cartPageLink: string
    orderConfirmationLink: string
    generalErrorPath: string
    linkRewardOptionsLink: string
    checkoutPageLink: string
    wishlistPageLink: string
    registrationPageLink: string
    passwordResetPageLink: string
    contactUsPageLink: string
    myaccountPreferenceLink: string
    productDetailPageLink: string
    linkExisingCard: string
    pageNotFoundPath: string
    linkMasterCard: string
    emailActivationPageLink?: string
    requestNewCard: string
    regularPayPalSuccessRedirectLink?: string
    regularPayPalErrorRedirectLink?: string
    regularPayPalCancelRedirectLink?: string
    expressPayPalSuccessRedirectLink?: string
    expressPayPalErrorRedirectLink?: string
    expressPayPalCancelRedirectLink?: string
}

export interface IMyAccountFormValidation {
    orderNumberEmptyMessage: string
    invalidOrderNumberMessage: string
    emailEmptyMessage: string
    emailInvalidMessage: string
    firstNameInvalidMessage: string
    firstNameEmptyMessage: string
    lastNameEmptyMessage: string
    lastNameInvalidMessage: string
    optionEmptyMessage1: string
    optionEmptyMessage2: string
    optionEmptyMessage3: string
    phoneNumberEmptyMessage: string
    phoneNumberInvalidMessage: string
    cityEmptyMessage: string
    cityInvalidMessage: string
    postalCodeEmptyMessage: string
    postalCodeInvalidMessage: string
    provinceEmptyMessage: string
    addressEmptyMessage: string
    addressInvalidMessage: string
    additionalAddressInvalidMessage: string
    countryEmptyMessage: string
    yearOfBirthEmptyMessage?: string
    accountHolderFirstNameInvalidMessage: string
    accountHolderLastNameInvalidMessage: string
}

export interface ICart {
    eachLabel: string
    continueLabel: string
    cancelLabel: string
    tryAgainLabel: string
    tabChangeStoreMessage: string
    changeLabel: string
    addServiceToCartLabel: string
    showDiscountedPrice: boolean
    highlightDiscountedPrice: boolean
    continueToCartLabel: string
    viewCartLabel: string
}

export interface IFedErrors {
    error00070: string
    error00076: string
    error00078: string
    error100: string
    error190: string
    error400: string
    error410: string
    error465: string
    error466: string
    error467: string
    error468: string
    error510: string
    error800: string
    error810: string
    errorC001: string
    errorC002: string
    errorC003: string
    errorOC001: string
    errorPDP001: string
    errorPopupCloseButtonDefaultText: string
    errorPopupDefaultSubtitle: string
    errorPopupDefaultTitle: string
    errorSkuIsNotSelectedFindStoresMsg: string
    interruptFlowOtpToastMessage: string
    expiredCancellationMessage: string
    selectErrorMessage: string
    clpTfaToastMessage: string
    error00271: string
    error03000: string
    error03037: string
    error03054: string
    [key: string]: string
    error30: string
    error31: string
    error32: string
    error40: string
    error41: string
    errorCvd30: string
    error00260: string
    error00263: string
    error00106: string
    paymentDetailsGenericError: string
}

export interface IAppointmentAvailability {
    [key: string]: string
    viewAppointmentAvailabilityButtonLabel: string
    viewAppointmentAvailabilityNote: string
    appointmentAvailabilityFlyoutTitle: string
    appointmentAvailabilityFlyoutSubtitle: string
    pleaseContactStoreLabel: string
    appointmentAvailableLabel: string
    timesAvailableLabel: string
    timeSlotHoverText: string
    morningLabel: string
    afternoonLabel: string
    eveningLabel: string
    previousMonthHoverText: string
    nextMonthHoverText: string
    selectDateHoverText: string
    selectedDateHoverText: string
    earliestDateHoverText: string
    requestErrorToastMsg: string
    timeSlotNotSelectedErrorMsg: string
    firstNameLabel: string
    lastNameLabel: string
    emailLabel: string
    emptyEmailCaution: string
    saveBtnLabel: string
    backToPDPBtnLabel: string
    backToCheckoutBtnLabel: string
    checkoutAppointmentInformationTitleLabel: string
    checkoutAppointmentInformationHeaderLabel: string
    checkoutAppointmentInformationSubHeaderLabel: string
    checkoutAppointmentInformationCurrentPickupLabel: string
    checkoutAppointmentInformationItemsToPickupLabel: string
    checkoutCheckboxSomeoneElseLabel: string
    checkoutCheckboxPickUpTogetherLabel: string
    checkoutPickUpTogetherInfoText: string
    checkoutPickUpTogetherInflatedBalloonsLabel: string
    checkoutPickupDateLabel: string
    checkoutInflationAppointmentErrorToastMessage: string
    checkoutPickUpTogetherInflatedBalloonsErrorNoteText: string
    checkoutPickUpTogetherInflatedBalloonsErrorToastText: string
    checkoutInflationAppointmentPleaseContactStoreLabel: string
}

export interface IAutomotive {
    [key: string]: string | string[]
    roadRatedLabel: string
    offRoadRatedLabel: string
    year: string
    make: string
    model: string
    vehicleSelectorFallbackImage: string
    vehicleSelectorFallbackImageAltText: string
    emptyFieldInlineError: string
    body: string
    option: string
    lifted: string
    subModel: string
    bodyStyleConfigId: string
    engineConfigId: string
    transmissionId: string
    brakeConfigId: string
    driveTypeId: string
    springTypeConfigId: string
    steeringConfigId: string
    bedConfigId: string
    attributeFrontLabel: string
    attributeRearLabel: string
    attributeBothLabel: string
    partLabel: string
    attributeUsageLabel: string
    attributePositionLabel: string
    suggestedQuantityLabel: string
    a11yRecycleFeesTriangleIcon: string
    bodyDuplicates: string[]
    optionDuplicates: string[]
    vehicleConfigIds: string
    vehicleType: string
    shopByVehicleTitle: string
    shopBySizeTitle: string
}

export interface IAutoAppointment {
    [key: string]: string
    autoAppointmentPagePath: string
    aoaTireInstallationMsg: string
    nonAoaTireInstallationMsg: string
    aoaTireInstallationTitle: string
    nonAoaTireInstallationTitle: string
}

export interface IPackages {
    [key: string]: string
    packagedItemLabel: string
    packagesTiresPath: string
    packagesWheelsPath: string
    balloonPackageLabel: string
    packageCancelDescription: string
}

export interface IPostalCodeModal {
    [key: string]: string
    outOfRangeExpressDeliveryMsg: string
    invalidCodeMsg: string
    outOfRangeErrorCode: string
    updatePCTitle: string
    updatePCDesc: string
    updatePCInputLabel: string
    outOfRangeMsg: string
}

export interface IErrors {
    [key: string]: string
}

export interface IWishlist {
    [key: string]: string
}

export interface IGlobalProps {
    [key: string]: unknown
    accessibility: IAccessibility
    accountDashboard: IAccountDashboard
    appointmentAvailability: IAppointmentAvailability
    aoaServices: IAOAServiceView[]
    authentication: IAuthentication
    autoAppointment: IAutoAppointment
    automotive: IAutomotive
    backToHomeLabel: string
    backToLabel: string
    badges: BadgePriority
    breadcrumb: IBreadcrumb
    cart: ICart
    errors: IErrors
    facetsAndFilters?: Record<string, unknown>
    featureFlag: IFeatureFlag
    fedErrors: IFedErrors
    general: IGeneral
    globalLinks: IGlobalLinks
    instantRebate: IInstantRebate
    loyalty: Loyalty
    myAccountFormValidation: IMyAccountFormValidation
    packages: IPackages
    payment: Record<string, string>
    postalCodeModal: IPostalCodeModal
    product: IProduct
    saleMessagesRules: SaleMessagesRule[]
    searchConfiguration: ISearchConfiguration
    siteConfiguration: ISiteConfiguration
    storeLocator: IStoreLocator
    truefitConfig: ITruefitConfig
    wishlist: IWishlist
}

export interface ISearchConfiguration {
    categoriesForPriceAvailabilityLazyLoad: string[]
}

export interface ITruefitConfig {
    truefitSizeDescriptors: string[] | null
}

export interface Loyalty {
    offersSortBy?: string[]
    checkLinkingHostPartnerPopupMessage?: string
    fallbackOfferImageUrl?: string
    linkingHostPartnerPopupMessage?: string
    moreDetailsLabel: string
    fallbackImageForBrandLogo?: string
    fallbackImageForBrandLogoAltText?: string
    loyaltyLabel?: string
    comboExclusions?: string
}

export interface IAccountDashboard {
    offersBadgeCountThreshold?: number
    a11yAlertBadgeValue?: string
    triangleIdLogo?: string
    triangleIdLogoAltText?: string
    triangleRewardsIcon?: string
    triangleRewardsIconAltText?: string
    signOutLabel?: string
    triangleRewardsLabel?: string
    rewardsBalanceLabel?: string
    cardNumberLabel?: string
    cardNumberLabelIcon?: string
    rewardsBalanceLabelIcon?: string
    welcomeBackLabel?: string
    registerNowLabel?: string
    registerNowLink?: string
    rewardsUpsellingMessage?: string
    signInLabel?: string
    loginTimeOutValue?: string
    registerLabel?: string
    authenticatedUserGreetingText?: string
    orText?: string
    rewardsRegisterRichText?: string
    backToMainLabel?: string
    signInDescription?: string
    pagesToDisplaySigninTooltip?: string[]
    tooltipDisplayTime?: number
    expiryTime?: number
    joinTriangleProgramLabel?: string
    joinNowLabel?: string
}

export interface IGeneral {
    disableWidgetMaxAge: number
    signinLabel: string
    changeLabel: string
    cancelLabel: string
    updateLabel: string
    continueLabel: string
    totalLabel: string
    yesLabel: string
    noLabel: string
    closeLabel: string
    standardDeliveryLabel?: string
    expressDeliveryLabel?: string
    generalExpressDeliveryDateLabel: string
    todayExpressDeliveryDateLabel: string
    tomorrowExpressDeliveryDateLabel: string
    rewardsComponentsTriangleTopImageUrl: string
    rewardsComponentsTriangleTopImageAltText: string
    rewardsComponentsBottomImageUrl: string
    rewardsComponentsBottomImageAltText: string
    noOfProductTilesInCertonaReco: number
    dropOffInstructionsLabel?: string
    specialInstructionsLabel?: string
    deliveryToHand?: string
    deliveryToDoor?: string
    searchInputPlaceholderText?: string
    deliveryToHandDescription?: string
    deliveryToDoorDescription?: string
    dropOffDeliveryEtaLabel?: string
    dropOffTitle: string
    deliveryToHandTooltip: string
    deliveryToDoorTooltip: string
    deliveryToHandPopupTitle: string
    deliveryToDoorPopupTitle: string
    deliveryToHandPopupDescription: string
    deliveryToDoorPopupDescription: string
    deliveryOptionOpenPopupCTALabel: string
    deliveryOptionClosePopupCTALabel: string
    deliveryMethodNotSelectedErrorMessage: string
    commentForAdditionalWishesField: string
    reachedLimitCharacterErrorMessage: string
    clearLabel?: string
    enterLabel?: string
    noSearchResults?: string
    singleSearchResult?: string
    manySearchResults?: string
    plusMinusSymbol?: string
    viewLessLabel?: string
    viewMoreLabel?: string
    saveLabel?: string
    editLabel?: string
    backToTopLabel?: string
    enableRangeToggle: boolean
    endOfPaginationMsg?: string
    errorImage?: string
    errorImageAltText?: string
    globalSpinnerColor: string
    showLabel?: string
    hideLabel?: string
    CTCLogoURL: string
    shopAllLabel?: string
    shopAllLabelAlternateLang?: string
    addCTA?: string
    optionsCTA?: string
    sponsoredLabel?: string
    enablePaginationInCanonicalUrl?: boolean
    cancelConfirmationLabel?: string
    keepLabel?: string
    triangleRewardsImage: string
    addedToCart?: string
}

export interface IFeatureFlag {
    isColourSwatchesActive: boolean
    disableAdaptingTimeZoneForSaleEndDate: boolean
    enableAOAFlowMessaging?: boolean
    enableNewHeaderStoreLocator?: boolean
    enableSignifyd?: boolean
    enableTmx?: boolean
    enableCLP?: boolean
    enableCRP?: boolean
    enableLoyaltyFlowCRP?: boolean
    enableSSSO?: boolean
    enableShowInStockFilter?: boolean
    enableTruefit?: boolean
    enableSignifydIframeTag: boolean
    disableStoreNumberInStandardSearchCall?: boolean
    enableSelfServeCancellation?: boolean
    enableOffersBrandFiltering?: boolean
    enableMPNSearchPreselection?: boolean
    enableNewVehicleApiEndpoint?: boolean
    enableCertonaLazyLoadOffset?: boolean
    enableBoldedBrandName?: boolean
    enablePriceAvailabilityLazyLoadForSearchResults?: boolean
    enableLightPdpLoading?: boolean
    enableXRingFence?: boolean
    enableFilterFacetsEnhancement: boolean
    fullSizeRequired?: boolean
    enableVehicleInformationOnWishlist: boolean
    enablePdpPricingEnhancement: boolean
    disableSelectAStoreButton: boolean
    enableGiftRegistry: boolean
    enableWishlistOnPDP: boolean
    billingAddressSuggestions: boolean
    enableEmailAcquisitionOnCheckout: boolean
    shippingAddressValidation: boolean
    enablePDPZoomEnhancement: boolean
    enablePartialOrderCancellation: boolean
    enableSwapOffers: boolean
    enableCacheCartToLocalStorageForAnonymous: boolean
    enableRomanceCopyWithWasLabel: boolean
    enableSaveForLater: boolean
    enableMasterpassOnCartPage: boolean
    enableMasterpassOnCartPageForBopis: boolean
    enableMasterpassOnCartPageForSTHMixedCart: boolean
    enableMasterpassOnCartPageForExpressDeliveryMixedCart: boolean
    enableStickBuyBar: boolean
    useLegacyLogicOfVehicleCheckAvailabilityForSearchResults?: boolean
    enableStoreFlyer: boolean
    allowComboExclusions?: boolean
    showSignInTooltip: boolean
    enableCoconutIframeRemoval?: boolean
    supportOfCustomDisplayOrderForProductVariantsEnabled?: boolean
    enableMergingOrderDetailsConsignmentAndUnconsignmentEntries?: boolean
    enableBalloonsHeliumInflation?: boolean
    isAppointmentAvailabilitySectionShow?: boolean
    enableNewSizeChart?: boolean
    enableVisualBannerFilters?: boolean
    enableAddOptions?: boolean
    enableCriteoDirectServer?: boolean
    enableCriteoDirectServerOnCDS?: boolean
    enableCacheWishlistToLocalStorageForAnonymous: boolean
    enableVerticalFilterToggle: boolean
    enableSingleSignOn: boolean
    enableCoachMarksSwapOffersTour: boolean
    enableAtcForCertonaProductCarouselsMiniPdp?: boolean
    enableATCForSingleSkuMiniPdp?: boolean
    enableWishlistOnPlpsrp?: boolean
    enableComparisonTool?: boolean
}

export interface GlobalAPIProps {
    [key: string]: unknown
    canadapost: CanadaPost
    checkout: Checkout
    payPal: {
        initPaymentVersion: number
        payPalMerchantID: string
    }
}

export interface CanadaPost {
    Country: string
    Key: string
    SearchFor: string
}

export interface Checkout {
    initPaymentVersion: number
}

export interface RenderedComponentData {
    component: string
    imageLink: string
}

export interface ErrorResponse {
    errCode: string
    error: string
    message: string
    requestId: string
    statusCode: number
}
export interface ISiteConfiguration {
    expiryYearCount: number
}

export interface IInstantRebate {
    afterRebateLabel: string
    ctaLabel: string
    ctaNewWin: boolean
    instantRebateHeader: string
    mailInRebateHeader: string
    noVariantLabel: string
    noVehicleLabel: string
}

export interface IAuthentication {
    loginScreenId: string
    partiallyAuthScreenId: string
    loginSuccessMessage: string
}

export enum commonContentMessages {
    LoadFailed = 'Common content load failed.',
}

// enum to capture the pass fail status from CTFS
export enum SuccessStatus {
    PASS = 'P',
    FAIL = 'F',
}

// interface to capture the paypal success params
export interface PayPalSuccessParams {
    payment: {
        payPalToken: string
        hppToken: string
        configuration: {
            redirectSuccess: string
        }
        isExpressCheckout: boolean
    }
    passFail: SuccessStatus
    responseCodes: number[]
}

// interface to capture the paypal token data
export interface PayPalTokenData {
    paypalToken: string
    hppToken: string
}

export interface IAOAServiceView {
    aoaServiceId: string
    aoaServiceTitle?: string
    aoaServiceSubTitle?: string
    aoaServiceCheckboxLabel?: string
    imagePath?: string
    sendAsAdditionalInfo?: boolean
    priority: number
}
