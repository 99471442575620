const DELAY = 15000

class PrerenderReadySetter {
    private _staticPrerenderState = false
    private _delay: number | undefined
    private _timerId: ReturnType<typeof setTimeout> | undefined

    private _verifyIsPrerenderReady() {
        if (this._delay) {
            this._timerId = setTimeout(() => {
                if (this._staticPrerenderState) window.prerenderReady = true
            }, this._delay)
        }
    }

    public set setConditionsToVerify(staticPrerender: boolean) {
        this._staticPrerenderState = staticPrerender
        this._delay = DELAY
        if (this._timerId) clearTimeout(this._timerId)
        this._verifyIsPrerenderReady()
    }
}

export default PrerenderReadySetter
