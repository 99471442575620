import { Offers } from '../components/OfferCard/OfferCard.type'
import { offerConstants } from '../components/OfferCard/OfferCard.constant'
import { checkToggle, offerTypes } from '../globalConstants/global.constant'

const offerZeroMultiplierFilter = (offer: Offers) => offer.details.awardValue === offerConstants.zeroMultiplier

const percentsSavingOffersFilter = (offer: Offers) => offer.details.bonusDescription?.includes(offerConstants.percent)

const remainingOffersFilter = (offer: Offers) => !offer.details.bonusDescription?.includes(offerConstants.percent)

const compareByAwardValue = (offer1: Offers, offer2: Offers): number => {
    return parseInt(offer2.details.awardValue) - parseInt(offer1.details.awardValue)
}

const compareByDaysLeft = (offer1: Offers, offer2: Offers): number => {
    return parseInt(offer1.daysLeft) - parseInt(offer2.daysLeft)
}

const compareByAwardValueAndDaysLeft = (offer1: Offers, offer2: Offers): number => {
    const awardComparison = compareByAwardValue(offer1, offer2)
    if (awardComparison === 0) {
        return compareByDaysLeft(offer1, offer2)
    }
    return awardComparison
}

export const sortOffers = (offers: Offers[]): Offers[] => {
    const redeemedOffers = offers.filter(
        offer => offer?.offerStatus === offerConstants.redeemed && offer?.redeemed === checkToggle.yes,
    )
    const redeemedSpentAndGetOffers = redeemedOffers.filter(
        offer => offer.details.bonusType === offerConstants.flatrate,
    )
    const redeemedmultiplierOffers = redeemedOffers.filter(offer => !redeemedSpentAndGetOffers.includes(offer))
    const allOffersExceptRedeemed = offers.filter(offer => !redeemedOffers.includes(offer))
    const spentAndGetOffers = allOffersExceptRedeemed.filter(
        offer => offer.details.bonusType === offerConstants.flatrate,
    )
    const nonSpentAndGetOffers = allOffersExceptRedeemed.filter(offer => !spentAndGetOffers.includes(offer))

    const notActivatedOffers = nonSpentAndGetOffers.filter(
        offer => offer.offerActivated !== checkToggle.yes && offer.offerActivationRequired !== checkToggle.no,
    )
    const zeroMultiplierNotActivatedOffers = notActivatedOffers.filter(offerZeroMultiplierFilter)
    const multiplierNotActivatedOffers = notActivatedOffers.filter(
        offer => !zeroMultiplierNotActivatedOffers.includes(offer),
    )
    const percentsSavingNotActivatedOffers = zeroMultiplierNotActivatedOffers.filter(percentsSavingOffersFilter)
    const remainingNotActivatedOffers = zeroMultiplierNotActivatedOffers.filter(remainingOffersFilter)

    const activatedOffers = nonSpentAndGetOffers.filter(offer => !notActivatedOffers.includes(offer))
    const zeroMultiplierActiveOffers = activatedOffers.filter(offerZeroMultiplierFilter)
    const multiplierActiveOffers = activatedOffers.filter(offer => !zeroMultiplierActiveOffers.includes(offer))
    const percentsSavingOffers = zeroMultiplierActiveOffers.filter(percentsSavingOffersFilter)
    const remainingOffers = zeroMultiplierActiveOffers.filter(remainingOffersFilter)

    const spentAndGetNotActivatedOffers = spentAndGetOffers.filter(
        offer => offer.offerActivated !== checkToggle.yes && offer.offerActivationRequired !== checkToggle.no,
    )
    const spentAndGetActivatedOffers = spentAndGetOffers.filter(offer => !spentAndGetNotActivatedOffers.includes(offer))

    spentAndGetNotActivatedOffers.sort(compareByAwardValueAndDaysLeft)
    spentAndGetActivatedOffers.sort(compareByAwardValueAndDaysLeft)
    multiplierNotActivatedOffers.sort(compareByAwardValueAndDaysLeft)
    percentsSavingNotActivatedOffers.sort(compareByDaysLeft)
    multiplierActiveOffers.sort(compareByAwardValueAndDaysLeft)
    percentsSavingOffers.sort(compareByDaysLeft)
    redeemedSpentAndGetOffers.sort(compareByAwardValueAndDaysLeft)
    redeemedmultiplierOffers.sort(compareByAwardValueAndDaysLeft)

    return [
        ...spentAndGetNotActivatedOffers,
        ...multiplierNotActivatedOffers,
        ...percentsSavingNotActivatedOffers,
        ...remainingNotActivatedOffers,
        ...spentAndGetActivatedOffers,
        ...multiplierActiveOffers,
        ...percentsSavingOffers,
        ...remainingOffers,
        ...redeemedSpentAndGetOffers,
        ...redeemedmultiplierOffers,
    ]
}

/** to get whether it is a multiplier offer
 * @param {string} offerType offerType either flatrate or multiplier
 * @returns {boolean}
 */
export const isMultiplierOffer = (offerType: string): boolean => {
    return Boolean(offerType === offerTypes.MULTIPLIER)
}

/**
 * function to compare multiplier pffer in decending
 * @param {string} offer1 first offer from offer list
 * @param {string} offer2 second offer from offer list
 * @returns {number}
 */
export const stringToDescending = (offer1: string, offer2: string): number => {
    return parseInt(offer2) - parseInt(offer1)
}

/**
 * function to filter Multiplier Label
 * @param {Offers[]} OfferType offerlist data
 * @returns {string[]}
 */
export const getMultiplierLabel = (OfferType: Offers[]): string[] => {
    return OfferType?.map(offer => {
        if (isMultiplierOffer(offer?.details?.bonusType)) {
            return offer.details?.multiplierValue
        }
    })
}

/**
 * function to filter Spend and Get Label
 * @param {Offers[]} OfferType offerlist data
 * @returns {string[]}
 */
export const getSpendAndGetLabel = (OfferType: Offers[]): string[] => {
    return OfferType?.map(offer => {
        if (!isMultiplierOffer(offer?.details?.bonusType)) {
            return offer.details?.bonusType
        }
    })
}

/**
 * function to get disable status of offerType
 * @param {Offers} offer offerlist offer
 * @param {string} offerType offertype label from filter
 * @returns {boolean}
 */
export const getOfferTypeStatus = (offer: Offers, offerType: string): boolean => {
    const bonusType = offer?.details?.bonusType
    return isMultiplierOffer(bonusType)
        ? Boolean(offer.details?.multiplierValue !== offerType)
        : Boolean(bonusType !== offerType)
}

/**
 * function to get offerType data
 * @param {Offers} offerValue offer data from offer list
 * @param {string} offerType offertype label from filter
 * @returns {boolean}
 */
export const getOfferTypeData = (offerValue: Offers, offerType: string): boolean => {
    const bonusType = offerValue?.details?.bonusType
    return isMultiplierOffer(bonusType) ? offerValue.details?.multiplierValue === offerType : bonusType === offerType
}
