import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ProductCardsContainerProps } from '../ShopTheLookComponent.type'
import { AkamaiImagePolicies } from '../../../akamaiPolicy/akamaiPolicy.service'
import { ProductCard, libUtils, appendQueryParamsToUrl, getAccessibilityId, ProductBrand } from '@nl/lib'
import { commonContentSelector, commonContentAvailableSelector } from '../../../redux/selectors/commonContent.selectors'
import {
    IAccessibility,
    ICart,
    IFeatureFlag,
    IGeneral,
    IGlobalLinks,
    IProduct,
} from '../../../redux/models/commonContent.interface'
import { ProductCardsShoppableData } from '../../../redux/models/recommendations.interface'
import { PREFIX } from '../../../config'
import { RecommendationResponseDataDTO, ProductCardType } from '../../../redux/models/recommendations.interface'
import { constantValues, shopTheLook } from '../ShopTheLookComponent.constant'
import { RootState } from '../../../redux/reducers'
import ProductCardsSkeleton from './ProductCardsSkeleton'
import { ProductDataTypeObj } from '../../../redux/models/productData.interface'
import { initMiniPDPFlyout } from '../../../redux/actions/miniPDPFlyout.action'
import { addProductToCartGuest, clearAddToCartErrorData } from '../../../redux/actions'
import { constructCartRequestObj } from '../../ProductGridView/ProductCard.helper'
import { ProductResponseData } from '../../../redux/models/product.interface'
import {
    clearAddToCartResponse,
    setRecommendShopTheLookAtcClicked,
    isMiniPDPAtcButtonClicked,
} from '../../../redux/actionCreators'
import appCacheService from '../../../utils/appCacheService'
import { selectedPreferredStoreIdSelector } from '../../../redux/selectors/storeDetails.selectors'
import { userProfileVehicleSelector } from '../../../redux/selectors/userProfile.selectors'
import { isObjectNotEmpty } from '../../../utils/isObjectNotEmpty'
import { cartModificationsSelector } from '../../../redux/selectors/cart.selectors'
import { miniPDPFlyoutDetailsSelector } from '../../../redux/selectors/miniPDPFlyoutData.selectors'
import { dispatchToast } from '../../ToastMessage/ToastMessage.helper'
import { addToCartFailureCode, successStatusCode } from '../../BuyBox/BuyBox.constant'
import { ToastComponentNames } from '../../../redux/models/toastMessage.interface'
import { toastMessageDataSelector } from '../../../redux/selectors/toastMessageData.selectors'
import { addToCartSuccessMsg } from '../../ProductGridView/ProductCard.constant'
import { recommendShopTheLookAtcStatus } from '../../../redux/selectors/recommendationsData.selectors'
import { ProductImage } from '@nl/lib/src/components/ProductReusableCard/product.types'
import { ProductCardType as ProductGridCardType } from '@nl/lib/src'
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux.hook'

const componentClassName = `${PREFIX}-product-cards-container`

/**
 * ProductCardsContainer
 * @param props : ProductCardsContainerProps
 * @returns : JSX.Element
 */
const ProductCardsContainer: React.FC<ProductCardsContainerProps> = ({ ...props }): JSX.Element => {
    const {
        productListCode,
        productsList,
        productCardClickHandler,
        productCardCount,
        sectionTitle,
        enableMiniPdpFlyoutSupport,
    } = props
    const dispatch = useAppDispatch()

    const [showAtcBtnSpinner, setShowAtcBtnSpinner] = useState<boolean>(false)
    const [clickedATCCardIdx, setClickedATCCardIdx] = useState<number | null>(null)
    const [identifierATCClicked, setIdentifierATCClicked] = useState<string>('')

    const { recommendationsData: recommendationRootState } = useAppSelector(
        (state: RootState) => state.recommendationsData,
    )

    const productCardAllData =
        recommendationRootState.find((productData: RecommendationResponseDataDTO) => productData.scheme === props.title)
            ?.productData || []
    const productCardData: ProductCardType[] = []
    productListCode.forEach(product => {
        const filteredProductData = productCardAllData.find(productData => productData.code === product.toUpperCase())
        filteredProductData && productCardData.push(filteredProductData)
    })

    const language = libUtils.getLanguage()
    const { commonContentAvailable } = useAppSelector(commonContentSelector)
    const {
        accessibility = {} as IAccessibility,
        product: commonContentProduct = {} as IProduct,
        globalLinks = {} as IGlobalLinks,
        featureFlag = {} as IFeatureFlag,
        general = {} as IGeneral,
        cart = {} as ICart,
    } = useAppSelector(commonContentAvailableSelector)
    const vehicle = useAppSelector(userProfileVehicleSelector)
    const cartModifications = useAppSelector(cartModificationsSelector)
    const selectedPreferredStoreId = useAppSelector(selectedPreferredStoreIdSelector)
    const { addToCartErrorData } = useAppSelector((state: RootState) => state.cart)
    const { isFlyoutVisible, isFetchingData, hasApiError, isAtcClicked } = useAppSelector(miniPDPFlyoutDetailsSelector)
    const toastMessageData = useAppSelector(toastMessageDataSelector)
    const isRecommendShopTheLookAtcClicked = useAppSelector(recommendShopTheLookAtcStatus)

    const preferredStoreIdValue = useMemo(() => {
        return selectedPreferredStoreId || appCacheService.preferredStoreId.get()
    }, [selectedPreferredStoreId])

    const {
        a11yClickToReadFootnote,
        a11yTooltipIcon,
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        a11yCloseIconLabel,
    } = accessibility

    const {
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
        unitPriceLabel,
        promotionalPriceLabel,
        clearancePriceLabel,
        thresholdValue,
        addToCartLabel,
        quickLookLabel,
    } = commonContentProduct

    const { enableATCForSingleSkuMiniPdp } = featureFlag

    const productProps = {
        ratingsAndReview: true,
        featureHeaderLabel: '',
        variantAvailableMsg: '',
        moreLabel: '',
        unitPriceLabel,
        clearancePriceLabel,
        promotionalPriceLabel,
        thresholdValue: thresholdValue,
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
    }

    /**
     * On Product Card Click open pdp page for passed product Code
     * @param {React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>} event event
     * @param {ProductCardType|ProductDataTypeObj} productData productData
     * @param {number} idx product index to pass to parent
     * @returns {void}
     */
    const cardClickHandler = (
        event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
        productData: ProductCardType | ProductDataTypeObj,
    ): void => {
        const product = productsList.find(item => item.productCode.toUpperCase() === productData.code)
        const type = product?.analyticsTrackingId || ''
        productCardClickHandler(productData, type)
    }

    /**
     * function to check whether add to cart api failure
     * @returns { boolean }
     */
    const isAddToCartApiFailed = useCallback((): boolean => {
        return isObjectNotEmpty(addToCartErrorData) && addToCartErrorData.status !== successStatusCode
    }, [addToCartErrorData])

    /**
     *  useEffect to reset spinner card index state what add to card api is finished.
     */
    useEffect(() => {
        setShowAtcBtnSpinner(false)
        setClickedATCCardIdx(null)
    }, [addToCartErrorData, cartModifications, isFlyoutVisible])

    /**
     * function to dispatch add to cart api on ATC btn click
     * @param {React.MouseEvent<Element> | React.KeyboardEvent<HTMLElement>} event
     * @param {ProductResponseData} atcBtnClickedData
     * @param {idx} number
     * @param {isTireWheel} boolean
     * @param {selectedSchemaID} string
     * @param {selectedColourVariant} boolean
     * @returns {void}
     */
    const addToCartBtnClick = (
        event: React.MouseEvent<Element> | React.KeyboardEvent<HTMLElement>,
        atcBtnClickedData: ProductCardType | ProductDataTypeObj,
        idx: number,
        isTireWheel = false,
        selectedSchemaID?: string,
        selectedColourVariant?: string,
    ): void => {
        const { code, isMultiSku } = atcBtnClickedData
        event.preventDefault()
        event.stopPropagation()

        if (enableMiniPdpFlyoutSupport && (isMultiSku || enableATCForSingleSkuMiniPdp)) {
            dispatch(initMiniPDPFlyout(code, preferredStoreIdValue, selectedColourVariant, isMultiSku))
        } else {
            dispatch(
                addProductToCartGuest(
                    constructCartRequestObj(
                        atcBtnClickedData as unknown as ProductResponseData,
                        false,
                        vehicle,
                        isTireWheel,
                        enableMiniPdpFlyoutSupport,
                    ),
                    false,
                    false,
                    false,
                    '',
                    undefined,
                    false,
                    false,
                    undefined,
                    false,
                    false,
                    false,
                    true,
                ),
            )
            setShowAtcBtnSpinner(true)
            dispatch(setRecommendShopTheLookAtcClicked(true))
        }
        setClickedATCCardIdx(idx)
        setIdentifierATCClicked(`${idx}_${code}`)
    }

    /**
     *  ToastProps Error ATC
     */
    const atcErrorToastProps = useMemo(
        () => ({
            options: {
                toastErrorMessage: toastMessageData[addToCartFailureCode],
                toastCloseLabel: general?.closeLabel,
                toastErrorIcon: 'ct-toast-error',
            },
            resetPageVariables: () => {
                dispatch(setRecommendShopTheLookAtcClicked(false))
                dispatch(isMiniPDPAtcButtonClicked(false))
                dispatch(clearAddToCartErrorData())
                dispatchToast(false, {}, ToastComponentNames.NONE, dispatch)
            },
            success: false,
            hideCTA: false,
            failed: true,
        }),
        [dispatch, toastMessageData, general],
    )

    /**
     *  ToastProps success ATC
     */
    const atcSuccessToastProps = useMemo(
        () => ({
            options: {
                toastSuccessMessage: toastMessageData[addToCartSuccessMsg],
                toastSuccessIcon: 'ct-notification-success-green',
                toastATCLabel: cart?.viewCartLabel,
                toastATCLabelUrl: globalLinks?.cartPageLink,
                toastUndoLabel: general?.closeLabel,
            },
            resetPageVariables: () => {
                dispatch(setRecommendShopTheLookAtcClicked(false))
                dispatch(isMiniPDPAtcButtonClicked(false))
                dispatch(clearAddToCartResponse())
                dispatchToast(false, {}, ToastComponentNames.NONE, dispatch)
            },
            success: true,
            hideCTA: false,
        }),
        [dispatch, toastMessageData, general],
    )

    /**
     * function to call success/fail toast once cart api is called
     */
    const showToast = useCallback(() => {
        const isCartApiFailed = isAddToCartApiFailed()
        if (isCartApiFailed) {
            dispatchToast(true, atcErrorToastProps, shopTheLook, dispatch)
        } else {
            dispatchToast(true, atcSuccessToastProps, shopTheLook, dispatch)
        }
    }, [atcErrorToastProps, atcSuccessToastProps, dispatch, isAddToCartApiFailed])

    /**
     * useEffect to call showToast
     */
    useEffect(() => {
        if (
            (isObjectNotEmpty(cartModifications) || isObjectNotEmpty(addToCartErrorData)) &&
            !showAtcBtnSpinner &&
            (isRecommendShopTheLookAtcClicked || isAtcClicked) &&
            !!identifierATCClicked
        ) {
            showToast()
            setIdentifierATCClicked('')
        }
    }, [
        addToCartErrorData,
        showAtcBtnSpinner,
        cartModifications,
        showToast,
        isRecommendShopTheLookAtcClicked,
        isAtcClicked,
        identifierATCClicked,
    ])

    useEffect(() => {
        if (isFetchingData && !isFlyoutVisible) {
            setShowAtcBtnSpinner(true)
        } else if ((!isFetchingData && isFlyoutVisible) || hasApiError) {
            setShowAtcBtnSpinner(false)
        }
    }, [isFlyoutVisible, isFetchingData, hasApiError])

    const productList = (productItem: ProductCardsShoppableData, index: number) => {
        const imageDataComponentName = 'product-card'

        const analyticsUrlParams = {
            loc: constantValues.analyticsLocValueForShopTheLookComponent,
        }

        const filteredBadges =
            productItem.isOnSale && productItem.badges
                ? productItem.badges.filter(badge => (constantValues.acceptedBadges as string[]).includes(badge))
                : []

        /**
         * Function returns true when we need to show spinner on ATC/Option btn
         * @param { number } idx index of product card
         * @returns {boolean} returns boolean
         */
        const showUpdatedATCSpinner = (idx: number): boolean => {
            return clickedATCCardIdx === idx ? showAtcBtnSpinner : false
        }

        return (
            <ProductCard
                code={undefined as unknown as string}
                product={productItem as unknown as ProductGridCardType}
                url={appendQueryParamsToUrl(productItem.url, analyticsUrlParams)}
                key={index}
                productProps={productProps}
                idx={index}
                cardType="grid"
                title={productItem.title}
                type={productItem.type}
                images={productItem.images as unknown as ProductImage[]}
                a11yStrikeOutPrice={a11yStrikeOutPrice}
                a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                showRatingSection
                badges={filteredBadges}
                badgePriorities={commonContentAvailable.badges}
                language={language}
                currentPrice={productItem.currentPrice}
                imageDataComponentName={imageDataComponentName}
                returnPolicy={AkamaiImagePolicies.returnPolicy}
                thresholdValue={thresholdValue}
                productDataId={productItem.code}
                a11yClickToReadFootnote={a11yClickToReadFootnote}
                a11yCloseIconLabel={a11yCloseIconLabel}
                a11yTooltipIcon={a11yTooltipIcon}
                enableBadges={true}
                showSaleClearanceBadge={true}
                rating={productItem.rating}
                ratingsCount={productItem.ratingsCount}
                brand={productItem.brand as ProductBrand}
                isOnSale={productItem.isOnSale}
                productCardClick={(event, product) => cardClickHandler(event, product)}
                accessibilityId={getAccessibilityId(props.title, productItem?.skus?.[0]?.code || productItem.code)}
                sectionTitle={sectionTitle}
                addToCartBtnClick={(event, productdata, idx, isTireWheel, selectedSchemaID) =>
                    addToCartBtnClick(
                        event,
                        productdata as unknown as ProductDataTypeObj,
                        idx,
                        isTireWheel,
                        selectedSchemaID,
                    )
                }
                showAtcBtnSpinner={showUpdatedATCSpinner(index)}
                addToCartLabel={addToCartLabel}
                quickLookLabel={quickLookLabel}
                enableATCForSingleSkuMiniPdp={enableATCForSingleSkuMiniPdp}
                enableMiniPdpFlyoutSupport={enableMiniPdpFlyoutSupport}
            />
        )
    }

    return productCardData?.length > 0 ? (
        <div className={`${PREFIX}-product__grid-view`}>
            <div className={`${componentClassName} ${PREFIX}-${props.componentWidthType}`}>
                {productCardData.map((product, index) => (
                    <div
                        className={`${PREFIX}-product-card`}
                        data-testid="product-card-component"
                        key={product.code}
                        aria-label={`${index} of ${productCardCount}`}>
                        {productList(product as ProductCardsShoppableData, index)}
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <ProductCardsSkeleton
            productCardCount={props.productCardCount}
            componentWidthType={`${props.componentWidthType}`}></ProductCardsSkeleton>
    )
}

ProductCardsContainer.displayName = 'ProductCardsContainer'

export default ProductCardsContainer
