import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators/otp.actionCreators'
import { OtpFlowDataState } from '../models/otp.interface'
import { Status } from '../../globalConstants'
import {
    initOtpFlow,
    setOtpErrCode,
    successOtpCode,
    clearOtpData,
    resetOtpFlow,
    failOtpFlow,
} from '../actionCreators/otp.actionCreators'

type Action = ActionType<typeof actions>

export const initialState: OtpFlowDataState = {
    vToken: '',
    component: '',
    status: '',
    errCode: '',
    email: '',
}

export const otpReducer = (state: OtpFlowDataState = initialState, action: Action): OtpFlowDataState => {
    switch (action.type) {
        case getType(initOtpFlow): {
            return {
                ...state,
                vToken: action.payload.vToken,
                component: action.payload.component,
                status: Status.start,
                email: action.payload.email,
            }
        }
        case getType(setOtpErrCode): {
            return {
                ...state,
                errCode: action.payload,
                status: Status.start,
            }
        }
        case getType(successOtpCode): {
            return {
                ...state,
                status: Status.success,
                errCode: '',
            }
        }
        case getType(clearOtpData): {
            return {
                ...state,
                vToken: '',
                component: '',
                status: '',
                errCode: '',
                email: '',
            }
        }
        case getType(resetOtpFlow): {
            return {
                ...state,
                vToken: '',
                errCode: '',
                status: Status.reset,
            }
        }
        case getType(failOtpFlow): {
            return {
                ...state,
                status: Status.failed,
                errCode: '',
            }
        }
        default:
            return state
    }
}
