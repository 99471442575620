import { statusLabel } from '../../globalConstants/global.constant'
import { ButtonStatusClassType } from '../../utils/ButtonStatus/getButtonStatus.type'
import { ButtonColors } from '../ButtonV2/Button.types'

/**
 * return the state color class
 * @param {ButtonStatusClassType} className
 * @returns {  ButtonColors }
 */
export const getColorClass = (className: ButtonStatusClassType): ButtonColors => {
    return (
        (className == statusLabel.activateStatus?.toLocaleLowerCase() && ButtonColors.ACTIVATE) ||
        (className == statusLabel.activatedStatus?.toLocaleLowerCase() && ButtonColors.LIGHT) ||
        (className == statusLabel.swapOfferStatus?.toLocaleLowerCase() && ButtonColors.DARK) ||
        (className == statusLabel.redeemedStatus?.toLocaleLowerCase() && ButtonColors.LIGHT)
    )
}
