import React, { useMemo } from 'react'
import { Role, State } from '../../types'
import { ITab } from './Tabs.type'
import { TabButton } from '../ButtonV2'

/**
 * TabV2 component
 * @return {JSX.Element} returns TabV2 component
 */
const TabV2: React.FC<ITab> = ({ ...props }): JSX.Element => {
    const { index, children, active, label } = props

    /**
     * To check add class based on button tab state
     */
    const isActive = useMemo(() => {
        return active ? State.ACTIVE : State.DEFAULT
    }, [active])

    const a11yTabs = {
        controls: `${Role.TABPANEL}-${index}`,
        selected: active,
    }

    return (
        <TabButton
            tabIndex={active ? 0 : -1}
            id={`tab-${index}`}
            a11y={a11yTabs}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                props.onSwitchTab(e, index, label)
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                props.onSwitchTab(e, index, label)
            }}
            state={isActive}>
            {children}
        </TabButton>
    )
}

export default TabV2
