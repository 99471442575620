import { createAction } from 'typesafe-actions'

export const IS_STICKY_BANNER_VISIBLE = 'IS_STICKY_BANNER_VISIBLE'

/**
 * Action creator for setting the visibility of the sticky banner.
 * @param {boolean} payload - The visibility status of the sticky banner.
 * @returns {boolean} An action indicating the visibility of the sticky banner.
 */
export const setIsStickyBannerVisibleAction = createAction(
    IS_STICKY_BANNER_VISIBLE,
    (payload: boolean) => payload,
)<boolean>()
