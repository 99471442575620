import React from 'react'

import { Rebate, RoadRatingBadge, getAccessibilityId, stringToBooleanMap } from '@nl/lib'

import { hallOFameProductTile } from './HallOfFame.classnames'
import { HallOfFameProductTileProps } from './HallOfFame.types'
import { HOFAccessabilityId } from './HallOfFame.constants'
import { CommonProductDetails } from './HallOfFameProductTile'

/**
 * @description representation of single product
 * @param {Object} product - product details
 * @returns {JSX.Element} - product tile
 */
function PrerenderHallOfFameProductTile({
    title,
    aggregateValue,
    roadRatedLabel,
    eachLabel,
    fromLabel,
    pdpUrl,
    badgesConfig,
    currentPrice,
    originalPrice,
    priceMessage,
    displayWasLabel,
    unitPriceLabel,
    promotionalPriceLabel,
    clearancePriceLabel,
    discount,
    a11yStrikeOutPrice,
    a11yStrikeOutPriceRange,
    a11yCloseIconLabel,
    a11yTooltipIcon,
    plusMinusSymbol,
    wasFromLabel,
    nowFromLabel,
    saveFromLabel,
    language,
    tireSize,
    sliSkuNumber,
    thumbImgUrl,
    rebate,
    prodName,
    recordType,
    wheelOffset,
    wheelRimWidth,
    wheelRimDiameter,
    fulfillment,
    isUrgentLowStock,
    badges,
    prodId,
    skuId,
    tireSizeLabel,
    wheelOffsetLabel,
    wheelRimWidthLabel,
    wheelRimDiameterLabel,
    checkAvailabilityLabel,
    inStockAisleLabel,
    inStockOnlineLabel,
    outOfStockLabel,
    isUrgentLowStockLabel,
    IsOnline,
    originallyInstalled,
    // rear
    rearSliSkuNumber,
    rearDisplayWasLabel,
    rearRebate,
    rearBadges,
    rearIsUrgentLowStock,
    rearDiscount,
    rearPriceMessage,
    rearSkuId,
    rearWheelOffset,
    rearWheelRimWidth,
    rearWheelRimDiameter,
    rearTireSize,
    rearCurrentPrice,
    rearOriginalPrice,
    rearFulfillment,
    rearIsOnline,
    rearOriginallyInstalled,
    attributeFrontLabel,
    attributeRearLabel,
    checkOtherStoresLabel,
}: HallOfFameProductTileProps): JSX.Element {
    const isStaggered = Boolean(rearSliSkuNumber)
    const rebateToDisplay = isStaggered ? rearRebate : rebate
    const isWheelOrTirePDP = true
    return (
        <li>
            <div className={hallOFameProductTile}>
                <div className="product-tile__header-wrapper">
                    <span className="product-tile__ranking-rule">{title}</span>
                    <img className="product-tile__image" src={thumbImgUrl} alt="" />
                </div>
                <div className="product-tile__product-details">
                    <a className="product-details__header" href={pdpUrl} tabIndex={0}>
                        <span className="product-details__product-name">{prodName}</span>
                        {aggregateValue && (
                            <RoadRatingBadge roadRatedLabel={roadRatedLabel} aggregateValue={aggregateValue} />
                        )}
                        <div
                            className="product-details__rating"
                            data-bv-show="inline_rating"
                            data-bv-product-id={prodId}
                            data-bv-seo="false"
                            aria-hidden={true}
                        />
                    </a>
                    <CommonProductDetails
                        isStaggered={isStaggered}
                        recordType={recordType}
                        language={language}
                        eachLabel={eachLabel}
                        nowFromLabel={nowFromLabel}
                        saveFromLabel={saveFromLabel}
                        wasFromLabel={wasFromLabel}
                        fromLabel={fromLabel}
                        promotionalPriceLabel={promotionalPriceLabel}
                        unitPriceLabel={unitPriceLabel}
                        clearancePriceLabel={clearancePriceLabel}
                        a11yStrikeOutPrice={a11yStrikeOutPrice}
                        a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                        a11yTooltipIcon={a11yTooltipIcon}
                        a11yCloseIconLabel={a11yCloseIconLabel}
                        plusMinusSymbol={plusMinusSymbol}
                        badgesConfig={badgesConfig}
                        isUrgentLowStockLabel={isUrgentLowStockLabel}
                        outOfStockLabel={outOfStockLabel}
                        checkAvailabilityLabel={checkAvailabilityLabel}
                        inStockAisleLabel={inStockAisleLabel}
                        inStockOnlineLabel={inStockOnlineLabel}
                        checkOtherStoresLabel={checkOtherStoresLabel}
                        wheelOffsetLabel={wheelOffsetLabel}
                        wheelRimDiameterLabel={wheelRimDiameterLabel}
                        wheelRimWidthLabel={wheelRimWidthLabel}
                        //
                        sliSkuNumber={sliSkuNumber}
                        isUrgentLowStock={isUrgentLowStock}
                        fulfillment={fulfillment}
                        priceMessage={priceMessage}
                        displayWasLabel={displayWasLabel}
                        currentPrice={currentPrice}
                        originalPrice={originalPrice}
                        badges={badges}
                        wheelOffset={wheelOffset}
                        wheelRimWidth={wheelRimWidth}
                        wheelRimDiameter={wheelRimDiameter}
                        tireSize={tireSize}
                        tireSizeLabel={tireSizeLabel}
                        isOrderable={stringToBooleanMap[IsOnline?.Orderable]}
                        isSellable={stringToBooleanMap[IsOnline?.Sellable]}
                        discount={discount}
                        accessibilityId={getAccessibilityId(
                            HOFAccessabilityId,
                            recordType === 'product' ? prodId : skuId,
                        )}
                        staggeredPriceLabel={attributeFrontLabel}
                        originallyInstalled={stringToBooleanMap[originallyInstalled]}
                        isWheelOrTirePDP={isWheelOrTirePDP}
                    />
                    {isStaggered && (
                        <CommonProductDetails
                            isStaggered={isStaggered}
                            recordType={recordType}
                            language={language}
                            eachLabel={eachLabel}
                            nowFromLabel={nowFromLabel}
                            saveFromLabel={saveFromLabel}
                            wasFromLabel={wasFromLabel}
                            fromLabel={fromLabel}
                            promotionalPriceLabel={promotionalPriceLabel}
                            unitPriceLabel={unitPriceLabel}
                            clearancePriceLabel={clearancePriceLabel}
                            a11yStrikeOutPrice={a11yStrikeOutPrice}
                            a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                            a11yTooltipIcon={a11yTooltipIcon}
                            a11yCloseIconLabel={a11yCloseIconLabel}
                            plusMinusSymbol={plusMinusSymbol}
                            badgesConfig={badgesConfig}
                            isUrgentLowStockLabel={isUrgentLowStockLabel}
                            outOfStockLabel={outOfStockLabel}
                            checkAvailabilityLabel={checkAvailabilityLabel}
                            inStockAisleLabel={inStockAisleLabel}
                            inStockOnlineLabel={inStockOnlineLabel}
                            wheelOffsetLabel={wheelOffsetLabel}
                            wheelRimDiameterLabel={wheelRimDiameterLabel}
                            wheelRimWidthLabel={wheelRimWidthLabel}
                            tireSizeLabel={tireSizeLabel}
                            //
                            sliSkuNumber={rearSliSkuNumber}
                            isUrgentLowStock={rearIsUrgentLowStock}
                            fulfillment={rearFulfillment}
                            priceMessage={rearPriceMessage}
                            displayWasLabel={rearDisplayWasLabel}
                            currentPrice={rearCurrentPrice}
                            originalPrice={rearOriginalPrice}
                            badges={rearBadges}
                            wheelOffset={rearWheelOffset}
                            wheelRimWidth={rearWheelRimWidth}
                            wheelRimDiameter={rearWheelRimDiameter}
                            tireSize={rearTireSize}
                            isOrderable={stringToBooleanMap[rearIsOnline?.Orderable]}
                            isSellable={stringToBooleanMap[rearIsOnline?.Sellable]}
                            discount={rearDiscount}
                            accessibilityId={getAccessibilityId(
                                HOFAccessabilityId,
                                recordType === 'product' ? prodId : rearSkuId,
                            )}
                            staggeredPriceLabel={attributeRearLabel}
                            originallyInstalled={stringToBooleanMap[rearOriginallyInstalled]}
                            isWheelOrTirePDP={isWheelOrTirePDP}
                            checkOtherStoresLabel={checkOtherStoresLabel}
                        />
                    )}

                    {rebateToDisplay && (
                        <div className="product-details__rebate">
                            <Rebate
                                title={rebateToDisplay.message || ''}
                                greyCopyMsg={rebateToDisplay.greyCopyMsg}
                                pdfLink={rebateToDisplay.pdfLink}
                                tooltipMessage={rebateToDisplay.tooltipMessage}
                                ariaHidden={true}
                                a11yTooltipIcon={a11yTooltipIcon}
                                rebateIcon="ct-offer"
                            />
                        </div>
                    )}
                </div>
            </div>
        </li>
    )
}

export default PrerenderHallOfFameProductTile
