import { createAction } from 'typesafe-actions'
import {
    VEHICLE_SELECTOR,
    VEHICLE_SELECTOR_TABS,
} from '../types/automotiveBuyBox/automotiveBuyBoxSelector.actionTypes.constants'
import { VehicleSelector, VehicleSelectorTabs } from '../reducers/automotiveBuyBox.reducer'

export const vehicleSelectorTabsAction = createAction(
    VEHICLE_SELECTOR_TABS,
    (payload: VehicleSelectorTabs) => payload,
)<VehicleSelectorTabs>()

export const vehicleSelectorAction = createAction(
    VEHICLE_SELECTOR,
    (payload: VehicleSelector) => payload,
)<VehicleSelector>()
