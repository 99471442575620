import { isArrayNotEmpty } from '@nl/lib'
import { useState, useEffect, useCallback, useRef, Dispatch, SetStateAction } from 'react'
import { getEnvironment } from '../../../environments'
import { fetchPreferredStoreDetails, setSelectedPreferredStoreId } from '../../../redux/actions'
import { StoreWithAvailability } from '../../../redux/models/storeDetails.interface'
import appCacheService from '../../../utils/appCacheService'
import sessionStorageService from '../../../utils/sessionStorageService'
import { storeDetailsDataSelector } from '../../../redux/selectors/storeDetails.selectors'
import { BREAKPOINTS } from '../../../config'
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux.hook'

/**
 * Hook to fetch preferred store details on page load.
 * @return {Dispatch<SetStateAction<boolean>>} function to updated isPreferredStoreIdChanged state.
 */
export const useFetchPreferredStoreDetails = (): Dispatch<SetStateAction<boolean>> => {
    const config = getEnvironment()
    const dispatch = useAppDispatch()
    const preferredStoreId = appCacheService.preferredStoreId.get()
    const { nearbyStoreList, selectedPreferredStoreId, preferredStoreDetails } =
        useAppSelector(storeDetailsDataSelector)

    const isInitialLoad = useRef(true)
    const [isPencilBannerMode, setIsPencilBannerMode] = useState(false)
    const [isLocationChoiceMade, setIsLocationChoiceMade] = useState(false)
    const [isPreferredStoreIdChanged, setIsPreferredStoreIdChanged] = useState(false)

    const geoLocationAuthorized = sessionStorageService.getItem(config.SESSION_STORAGE_KEYS.geoLocationAuthorized)

    const isDesktop = window.innerWidth > BREAKPOINTS.tabletMaxWidth
    const currentPreferredStoreId = preferredStoreDetails.id ? String(preferredStoreDetails.id) : ''

    useEffect(() => {
        const pencilBannerHeight = document.getElementsByClassName('store-locator-pencil-banner')[0]?.clientHeight || 0
        setIsPencilBannerMode(!!pencilBannerHeight)
    }, [])

    useEffect(() => {
        setIsLocationChoiceMade(!!JSON.parse(geoLocationAuthorized as string))
    }, [geoLocationAuthorized])

    /**
     * Function to return the store with matching id with default or id received in query param
     * @param {string} storeId
     * @return {StoreWithAvailability}
     */
    const getStoreDetailsFromNearbyStoreList = useCallback(
        (storeId: string): StoreWithAvailability | undefined => {
            return nearbyStoreList?.find(
                (store: StoreWithAvailability) => store.id === Number(storeId || selectedPreferredStoreId),
            )
        },
        [nearbyStoreList, selectedPreferredStoreId],
    )

    /**
     * Function to check if preferred Store Data Available
     * @param {StoreWithAvailability | undefined} setPreferredStoreDetails
     * @return {boolean}
     */
    const isPreferredStoreDataAvailable = useCallback(
        (storeDetails: StoreWithAvailability | undefined): boolean => {
            return (storeDetails && String(storeDetails?.id) !== currentPreferredStoreId) || !currentPreferredStoreId
        },
        [currentPreferredStoreId],
    )

    const isStoreIDNotEqual = preferredStoreId && preferredStoreId !== currentPreferredStoreId

    /**
     * Effect to update preferred store details
     */
    useEffect(() => {
        const shouldNotFetch = (selectedStoreId: boolean) => {
            return !selectedStoreId || (!isPencilBannerMode && !isDesktop) || (isPencilBannerMode && isDesktop)
        }
        const checkStoreIdAndInitialLoadingState = () =>
            isStoreIDNotEqual && (isInitialLoad.current || isPreferredStoreIdChanged)

        if (shouldNotFetch(Boolean(selectedPreferredStoreId)) || isNaN(parseInt(selectedPreferredStoreId))) {
            return
        }

        if (isArrayNotEmpty(nearbyStoreList)) {
            const storeDetails = getStoreDetailsFromNearbyStoreList(preferredStoreId)

            if (isPreferredStoreDataAvailable(storeDetails)) {
                dispatch(fetchPreferredStoreDetails(selectedPreferredStoreId))
            } else {
                const isIdEqual = selectedPreferredStoreId === preferredStoreId

                if (!isLocationChoiceMade) {
                    if (!isIdEqual) {
                        const newPreferredStoreId = String(nearbyStoreList[0].id)

                        dispatch(fetchPreferredStoreDetails(newPreferredStoreId))
                        dispatch(setSelectedPreferredStoreId(newPreferredStoreId))
                    }
                } else if (storeDetails && !isIdEqual) {
                    dispatch(fetchPreferredStoreDetails(selectedPreferredStoreId))
                }
            }
        } else if (checkStoreIdAndInitialLoadingState()) {
            isInitialLoad.current = false
            setIsPreferredStoreIdChanged(false)
            dispatch(fetchPreferredStoreDetails(selectedPreferredStoreId))
        }
    }, [
        dispatch,
        preferredStoreId,
        getStoreDetailsFromNearbyStoreList,
        selectedPreferredStoreId,
        nearbyStoreList,
        isLocationChoiceMade,
        isStoreIDNotEqual,
        isPreferredStoreDataAvailable,
        isDesktop,
        isPencilBannerMode,
        isPreferredStoreIdChanged,
    ])

    return setIsPreferredStoreIdChanged
}
