import { createAction } from 'typesafe-actions'

import {
    SET_IS_INITIAL_DROP_DOWNS_FETCH_IN_PROGRESS,
    SET_IS_INITIAL_DROP_DOWNS_RESPONSE_DATA_FETCHED,
    SET_CLEAR_TO_VEHICLE_ATTRIBUTE,
    SET_VEHICLE_BASE_ATTRIBUTES_ORDER,
    SET_VEHICLE_KNOWN_VALUES,
    SET_VEHICLE_RESPONSE,
    SET_VEHICLE_LAZY_LOAD_RESPONSE,
    SET_IS_VEHICLE_FETCH_IN_PROGRESS,
    SET_SAVED_VEHICLE_CHECKED,
    SET_VEHICLE_DROPDOWN_LOCALIZATION,
    SET_REMOVED_VEHICLE,
    SET_VEHICLE_KNOWN_VALUES_AND_CLEAR_OTHER,
    RECALL_VEHICLE_API,
    API_ERROR,
    SET_CLEAR_TO_VEHICLE_ATTRIBUTE_FOR_SAVED_VEHICLE,
    SET_IS_INITIAL_DROP_DOWNS_FETCH_IN_PROGRESS_FOR_SAVED_VEHICLE,
    SET_IS_INITIAL_DROP_DOWNS_RESPONSE_DATA_FETCHED_FOR_SAVED_VEHICLE,
    SET_IS_VEHICLE_FETCH_IN_PROGRESS_FOR_SAVED_VEHICLE,
    SET_VEHICLE_BASE_ATTRIBUTES_ORDER_FOR_SAVED_VEHICLE,
    SET_VEHICLE_DROPDOWN_LOCALIZATION_FOR_SAVED_VEHICLE,
    SET_VEHICLE_KNOWN_VALUES_AND_CLEAR_OTHER_FOR_SAVED_VEHICLE,
    SET_VEHICLE_KNOWN_VALUES_FOR_SAVED_VEHICLE,
    SET_VEHICLE_LAZY_LOAD_RESPONSE_FOR_SAVED_VEHICLE,
    SET_VEHICLE_RESPONSE_FOR_SAVED_VEHICLE,
    SET_IS_IGNORE_ATTRIBUTES_THRESHOLD,
} from '../types/vehiclesData/vehiclesData.actionTypes.constant'
import { AddVehicleDataResponse } from '../../components/BuyBox/BuyBox.type'
import { VehicleDropdownLocalization } from '../types/vehiclesData/vehicleActions.types'
import { DropdownOptionsState } from '../models/vehicleDropdowns.types'
import { IAutomotive } from '../models/commonContent.interface'

export const setIsInitialDropDownsFetchInProgress = createAction(
    SET_IS_INITIAL_DROP_DOWNS_FETCH_IN_PROGRESS,
    (payload: boolean) => payload,
)<boolean>()
export const setIsFetchInProgress = createAction(
    SET_IS_VEHICLE_FETCH_IN_PROGRESS,
    (payload: boolean) => payload,
)<boolean>()

export const setIsInitialDropDownsResponseDataFetched = createAction(
    SET_IS_INITIAL_DROP_DOWNS_RESPONSE_DATA_FETCHED,
    (payload: boolean) => payload,
)<boolean>()

export const setVehicleKnownValues = createAction(
    SET_VEHICLE_KNOWN_VALUES,
    (payload: Record<string, { label: string; value: string }>) => payload,
)<Record<string, { label: string; value: string }>>()

export const setVehicleKnownValuesAndClearOther = createAction(
    SET_VEHICLE_KNOWN_VALUES_AND_CLEAR_OTHER,
    (payload: Record<string, { label: string; value: string }>) => payload,
)<Record<string, { label: string; value: string }>>()

export const setSavedVehicleChecked = createAction(SET_SAVED_VEHICLE_CHECKED)<void>()

export const setVehicleBaseAttributesOrder = createAction(
    SET_VEHICLE_BASE_ATTRIBUTES_ORDER,
    (payload: string[]) => payload,
)<string[]>()

export const setClearToVehicleAttribute = createAction(
    SET_CLEAR_TO_VEHICLE_ATTRIBUTE,
    (payload: string) => payload,
)<string>()

export const setVehicleChooseResponse = createAction(
    SET_VEHICLE_RESPONSE,
    (payload: { response: AddVehicleDataResponse; labels: IAutomotive }) => payload,
)<{ response: AddVehicleDataResponse; labels: IAutomotive }>()

export const setVehicleLazyLoadResponse = createAction(
    SET_VEHICLE_LAZY_LOAD_RESPONSE,
    (payload: AddVehicleDataResponse) => payload,
)<AddVehicleDataResponse>()

export const setDropdownAttributeLocalization = createAction(
    SET_VEHICLE_DROPDOWN_LOCALIZATION,
    (payload: VehicleDropdownLocalization) => payload,
)<VehicleDropdownLocalization>()

export const setRemovedVehicle = createAction(SET_REMOVED_VEHICLE, (payload: string | undefined) => payload)<string>()

export const recallVehicleAPI = createAction(RECALL_VEHICLE_API)()

export const setAPIErrorStatus = createAction(
    API_ERROR,
    (payload: { status: DropdownOptionsState; fieldName: string }) => payload,
)<{
    status: DropdownOptionsState
    fieldName: string
}>()

export const setIsInitialDropDownsFetchInProgressForSavedVehicle = createAction(
    SET_IS_INITIAL_DROP_DOWNS_FETCH_IN_PROGRESS_FOR_SAVED_VEHICLE,
    (payload: boolean) => payload,
)<boolean>()
export const setIsFetchInProgressForSavedVehicle = createAction(
    SET_IS_VEHICLE_FETCH_IN_PROGRESS_FOR_SAVED_VEHICLE,
    (payload: boolean) => payload,
)<boolean>()

export const setIsInitialDropDownsResponseDataFetchedForSavedVehicle = createAction(
    SET_IS_INITIAL_DROP_DOWNS_RESPONSE_DATA_FETCHED_FOR_SAVED_VEHICLE,
    (payload: boolean) => payload,
)<boolean>()

export const setVehicleKnownValuesForSavedVehicle = createAction(
    SET_VEHICLE_KNOWN_VALUES_FOR_SAVED_VEHICLE,
    (payload: Record<string, { label: string; value: string }>) => payload,
)<Record<string, { label: string; value: string }>>()

export const setVehicleKnownValuesAndClearOtherForSavedVehicle = createAction(
    SET_VEHICLE_KNOWN_VALUES_AND_CLEAR_OTHER_FOR_SAVED_VEHICLE,
    (payload: Record<string, { label: string; value: string }>) => payload,
)<Record<string, { label: string; value: string }>>()

export const setSavedVehicleCheckedForSavedVehicle = createAction(SET_SAVED_VEHICLE_CHECKED)<void>()

export const setVehicleBaseAttributesOrderForSavedVehicle = createAction(
    SET_VEHICLE_BASE_ATTRIBUTES_ORDER_FOR_SAVED_VEHICLE,
    (payload: string[]) => payload,
)<string[]>()

export const setClearToVehicleAttributeForSavedVehicle = createAction(
    SET_CLEAR_TO_VEHICLE_ATTRIBUTE_FOR_SAVED_VEHICLE,
    (payload: string) => payload,
)<string>()

export const setVehicleChooseResponseForSavedVehicle = createAction(
    SET_VEHICLE_RESPONSE_FOR_SAVED_VEHICLE,
    (payload: { response: AddVehicleDataResponse; labels: IAutomotive }) => payload,
)<{ response: AddVehicleDataResponse; labels: IAutomotive }>()

export const setVehicleLazyLoadResponseForSavedVehicle = createAction(
    SET_VEHICLE_LAZY_LOAD_RESPONSE_FOR_SAVED_VEHICLE,
    (payload: AddVehicleDataResponse) => payload,
)<AddVehicleDataResponse>()

export const setDropdownAttributeLocalizationForSavedVehicle = createAction(
    SET_VEHICLE_DROPDOWN_LOCALIZATION_FOR_SAVED_VEHICLE,
    (payload: VehicleDropdownLocalization) => payload,
)<VehicleDropdownLocalization>()

export const setIsIgnoreAttributesThreshold = createAction(SET_IS_IGNORE_ATTRIBUTES_THRESHOLD, Boolean)<boolean>()
