import React, { useState, useEffect } from 'react'
import PromoMessage from './PromoMessage'
import RestrictedPromoMessage from './RestrictedPromoMessage'
import { PromoMessageType, RestrictedPromoMessageType, PromoType } from './PromoMessage.type'
import AppliedPromo from './AppliedPromo'
import { PREFIX } from '../config'

/**
 * PromoMessages component
 * @param {PromoType} props isProductLevel, productPromoMessages, productRestrictedPromoMessages,
 * skuPromoMessages, skuRestrictedPromoMessages, appliedPromoMessages, isStaggered, a11yTooltipIcon, a11yCloseIconLabel
 * @return {JSX.Element} returns PromoMessages component
 */
const PromoMessages: React.FC<PromoType> = ({
    isProductLevel,
    productPromoMessages,
    productRestrictedPromoMessages,
    skuPromoMessages,
    skuRestrictedPromoMessages,
    appliedPromoMessages,
    appliedPromoPrefix,
    isStaggered,
    a11yTooltipIcon,
    a11yCloseIconLabel,
    ariaHidden,
    isModalPresent,
}): JSX.Element => {
    const [promoMessages, setPromoMessages] = useState(productPromoMessages)
    const [restrictedPromoMessages, setRestrictedPromoMessages] = useState(productRestrictedPromoMessages)
    const [appliedPromos, setAppliedPromos] = useState(appliedPromoMessages)

    useEffect(() => {
        if (isProductLevel) {
            setPromoMessages(productPromoMessages)
            setRestrictedPromoMessages(productRestrictedPromoMessages)
            setAppliedPromos(appliedPromoMessages)
        } else {
            setPromoMessages(skuPromoMessages)
            setRestrictedPromoMessages(skuRestrictedPromoMessages)
        }
    }, [
        isProductLevel,
        productPromoMessages,
        productRestrictedPromoMessages,
        skuPromoMessages,
        skuRestrictedPromoMessages,
        appliedPromoMessages,
    ])

    /**
     * function to render promo message
     * @param {PromoMessageType[] | undefined} promos
     * @return {JSX.Element[]}
     */
    const isPromoMessagesNotEmpty = (promos: PromoMessageType[] | undefined): boolean => {
        return !!promos?.length
    }

    const renderPromoMessage = (): JSX.Element[] | undefined => {
        return promoMessages?.map((promoMessage: PromoMessageType) => {
            return (
                <div
                    key={`${promoMessage.label}-${promoMessage.tooltip}`}
                    aria-hidden={ariaHidden ? ariaHidden : false}>
                    <PromoMessage
                        promoMessage={promoMessage}
                        a11yTooltipIcon={a11yTooltipIcon}
                        a11yCloseIconLabel={a11yCloseIconLabel}
                        isModalPresent={isModalPresent}
                    />
                </div>
            )
        })
    }

    const renderRestrictedPromoMessages = (): JSX.Element[] | undefined => {
        return restrictedPromoMessages?.map((restrictedPromoMessage: RestrictedPromoMessageType) => {
            return (
                <div
                    key={`${restrictedPromoMessage.label}-${restrictedPromoMessage.tooltip}`}
                    aria-hidden={ariaHidden ? ariaHidden : false}>
                    <RestrictedPromoMessage
                        restrictedPromoMessage={restrictedPromoMessage}
                        a11yTooltipIcon={a11yTooltipIcon}
                        a11yCloseIconLabel={a11yCloseIconLabel}
                        isModalPresent={isModalPresent}
                    />
                </div>
            )
        })
    }

    const renderAppliedPromoMessages = (): JSX.Element[] | undefined => {
        return appliedPromos?.map((appliedPromoMessage: PromoMessageType) => {
            return (
                <div
                    key={`${appliedPromoMessage.label}-${appliedPromoMessage.tooltip}`}
                    aria-hidden={ariaHidden ? ariaHidden : false}>
                    <AppliedPromo
                        appliedPromoPrefix={appliedPromoPrefix}
                        appliedPromoLabel={appliedPromoMessage.label}
                    />
                </div>
            )
        })
    }

    return (
        <>
            {!isStaggered ? (
                <>
                    {isPromoMessagesNotEmpty(promoMessages) ? renderPromoMessage() : null}
                    {isPromoMessagesNotEmpty(restrictedPromoMessages) ? renderRestrictedPromoMessages() : null}
                </>
            ) : null}
            {isPromoMessagesNotEmpty(appliedPromos) ? (
                <div className={`${PREFIX}-applied-promo-messages-wrapper`}>{renderAppliedPromoMessages()}</div>
            ) : null}
        </>
    )
}

export default PromoMessages
