import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../../config'
import { DropdownItemProps } from '../Dropdown.type'
import Icon from '../../Icon'

/**
 * DropdownItem component
 * @param {DropdownItemProps} props -isFilter,item,isMobile,currentItem,
 * index,itemSelect,itemSelectKeyBoardEvent,path,dropdownId
 * @returns {JSX.Element} returns DropdownItem component
 */
const DropdownItem: React.FC<DropdownItemProps> = ({ ...props }) => {
    const {
        isFilter,
        item,
        isMobile,
        currentItem,
        index,
        itemSelect,
        itemSelectKeyBoardEvent,
        path,
        dropdownId,
        buttonContent,
    } = props

    const currentClass = () => {
        if (currentItem === index) {
            if (!isMobile) {
                return `${PREFIX}-dropdown__content--active`
            }
        } else if (item.isUnavailable) {
            return `${PREFIX}-dropdown__content--not-selected`
        } else return `${PREFIX}-dropdown__content--default`
    }

    return (
        <li key={`${dropdownId}${index}`}>
            {isFilter ? (
                buttonContent ? (
                    <button
                        onClick={itemSelect}
                        onKeyDown={itemSelectKeyBoardEvent}
                        id={`${dropdownId}${index}`}
                        data-testid={`drpItem${index}`}
                        role="option"
                        data-qm-allow="true"
                        aria-selected={item.selected}
                        className={`${PREFIX}-dropdown__content-button ${currentClass()}`}
                        tabIndex={-1}>
                        <div className={`${PREFIX}-dropdown__content-button--colour-swatch`} aria-hidden="true">
                            <>
                                {buttonContent(item.hexNumber)}
                                {item.label}
                            </>
                        </div>
                    </button>
                ) : (
                    <button
                        onClick={itemSelect}
                        onKeyDown={itemSelectKeyBoardEvent}
                        data-testid={`drpItem${index}`}
                        id={`${dropdownId}${index}`}
                        type="button"
                        data-qm-allow="true"
                        className={`${
                            !isMobile ? `${PREFIX}-dropdown__content-button` : `${PREFIX}-dropdown__filter-button`
                        } ${currentClass()}`}
                        role="option"
                        aria-selected={item.selected}
                        tabIndex={-1}>
                        {item.selected && currentItem === index && isMobile ? (
                            <Icon size="sm" type="ct-confirm" path={path} />
                        ) : null}
                        {item.label}
                    </button>
                )
            ) : (
                <button
                    onClick={itemSelect}
                    onKeyDown={itemSelectKeyBoardEvent}
                    id={`${dropdownId}${index}`}
                    data-testid={`drpItem${index}`}
                    type="button"
                    data-qm-allow="true"
                    className={`${PREFIX}-dropdown__content-button ${currentClass()}`}
                    role="option"
                    aria-selected={item.selected}>
                    {item.label}
                </button>
            )}
        </li>
    )
}

DropdownItem.propTypes = {
    index: PropTypes.number,
    item: PropTypes.any,
    itemSelect: PropTypes.any,
    currentItem: PropTypes.any,
    isFilter: PropTypes.bool,
    isMobile: PropTypes.bool,
    path: PropTypes.string,
    itemSelectKeyBoardEvent: PropTypes.any,
    dropdownId: PropTypes.string,
    buttonContent: PropTypes.func,
}

export default DropdownItem
