import React from 'react'
import { HeaderContainerProps } from '../ShopTheLookComponent.type'
import { componentClassName } from '../ShopTheLookComponent.constant'

/**
 * ImageContainer
 * @param props : FixedHeightBannerProps
 * @returns : JSX.Element
 */
const HeaderContainer: React.FC<HeaderContainerProps> = ({ ...props }): JSX.Element => {
    const fontColorClassName = props.fontColour || ''
    const notShowTextContent = !props.title && !props.description ? 'not-show__text-content' : ''
    const notShowTitle = !props.title ? 'not-show__title' : ''
    return (
        <div className={`${fontColorClassName} ${componentClassName}__text-content ${notShowTextContent}`}>
            <div className={`${componentClassName}__title ${notShowTitle}`}>
                <div>{props.title}</div>
            </div>
            <div
                className={`${componentClassName}__description`}
                dangerouslySetInnerHTML={{ __html: props.description || '' }}
            />
        </div>
    )
}

export default HeaderContainer
