import { storageData } from '../../globalConstants'
import localStorageService from '../../utils/localStorageService'
import { StoreGeoPoint, StoreResponse, StoreWithAvailability } from '../models/storeDetails.interface'
import { RootState } from '../reducers'

export const storeDetailsDataSelector = (state: RootState): StoreResponse => state.storeDetails

export const preferredStoreDetailsSelector = (state: RootState): StoreWithAvailability =>
    storeDetailsDataSelector(state).preferredStoreDetails

export const geoPointSelector = (state: RootState): StoreGeoPoint => preferredStoreDetailsSelector(state).geoPoint

export const onlineOrderingSelector = (state: RootState): boolean => preferredStoreDetailsSelector(state).onlineOrdering

export const bopisEligibilitySelector = (state: RootState): boolean | undefined =>
    preferredStoreDetailsSelector(state).bopisEligibility

export const bopisAvailablilitySelector = (state: RootState): boolean | undefined =>
    preferredStoreDetailsSelector(state).bopisAvailable

export const isAOAFlowEnabledSelector = (state: RootState): boolean =>
    !!preferredStoreDetailsSelector(state)?.isAOAFlowEnabled

export const selectedPreferredStoreIdSelector = (state: RootState): string =>
    storeDetailsDataSelector(state).selectedPreferredStoreId

export const currentUserLocationSelector = (state: RootState): StoreGeoPoint =>
    storeDetailsDataSelector(state).currentUserLocation

export const storeIdSelector = (state: RootState): string | null =>
    preferredStoreDetailsSelector(state)?.id?.toString() || localStorageService.getItem(storageData.preferredStoreId)

export const isStoreDetailsFetchedSelector = (state: RootState): boolean | undefined =>
    storeDetailsDataSelector(state).isStoreDetailsFetched

export const storeChangeSourceSelector = (state: RootState): string | undefined =>
    storeDetailsDataSelector(state).storeChangeSource

export const isNearbyStoreListApiDoneSelector = (state: RootState): boolean | undefined =>
    storeDetailsDataSelector(state).isNearbyStoreListApiDone

export const storeDetailsSelector = (state: RootState): StoreWithAvailability =>
    storeDetailsDataSelector(state).storeDetails

export const displayNameSelector = (state: RootState): string => storeDetailsSelector(state).displayName

export const nearbyStoreListSelector = (state: RootState): StoreWithAvailability[] =>
    storeDetailsDataSelector(state).nearbyStoreList

export const nearByStoreListForSelectedSkuSelector = (state: RootState): StoreWithAvailability[] =>
    storeDetailsDataSelector(state).nearByStoreListForSelectedSku

export const nearByStoreListForAStoreSelector = (state: RootState): StoreWithAvailability[] =>
    storeDetailsDataSelector(state).nearByStoreListForAStore

export const storeAPITriangulationFailSelector = (state: RootState): boolean =>
    storeDetailsDataSelector(state).storeAPITriangulationFail
