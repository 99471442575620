import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react'
import { Inventory, StoreWithAvailability } from '../../../redux/models/storeDetails.interface'
import { FulfillmentInfo, VariantsInfo } from '../../../redux/models/miniPDPFlyout.interface'
import {
    checkMaxQuantity,
    getSelectedVariantSKU,
    isInStockAtDC,
    setQuanitySelectorErrorMsg,
} from '../../BuyBox/BuyBox.helper'
import { checkDataLength } from '../../Accounts/Addresses/checkDataLength'
import { MagicNumber } from '../../../analytics/analytics.type'
import { libUtils } from '@nl/lib'
import { setQuantitySelected } from '../../../redux/actions'
import { storeDetailsDataSelector } from '../../../redux/selectors/storeDetails.selectors'
import { MiniPDPProps } from '../MiniPDP.type'
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux.hook'

interface UseStoreData {
    limitMsgValue: MutableRefObject<number>
    inStockAtDC: boolean
    isStoreSelected: boolean
    quantityExceeded: boolean
    availableForATC: boolean
    maxQuantityAvailable?: number
    nearByStoreLabel?: string
}

/**
 *
 * @param props
 * @param fulfillmentInfo
 * @param variantsInfo
 * @param inStockAtNearbyStores
 * @param isShipToHome
 * @param bannerMaxPurchasableQuantity
 * @param setQtySelectorErrorMsg
 * @param qtySelectorErrorMsg
 * @param qtySelectorInput
 * @param hasOnlyBOPISEnabled
 */
export const useStoreData = (
    props: MiniPDPProps,
    fulfillmentInfo: FulfillmentInfo,
    variantsInfo: VariantsInfo,
    inStockAtNearbyStores: boolean,
    isShipToHome: boolean,
    bannerMaxPurchasableQuantity: string,
    setQtySelectorErrorMsg: (value: string) => void,
    qtySelectorErrorMsg: string,
    hasOnlyBOPISEnabled: boolean,
    qtySelectorInput?: number,
): UseStoreData => {
    const {
        isSeparatedErrorHandling,
        maxQuantity,
        exceedMaximumSellQuantityMessage,
        maximumQuantityError,
        isDenyToUseStoreInventory,
        selectNearByStoreLabel,
        exceedMaximumPurchableMessage,
        freeShippingEnabled,
        availableAtNearbyStores,
        needItTodayLabel,
    } = props
    const dispatch = useAppDispatch()
    const { selectedStoreName, storeDetails, nearByStoreListForSelectedSku } = useAppSelector(storeDetailsDataSelector)
    const limitMsgValue = useRef(0)
    const selectStoreMsg = useRef('')
    const {
        inStockInSelectedStore,
        quantityAtStore,
        quantityAtStoreDC,
        isAvailableInDC,
        availability,
        selectedStoreQty,
        orderable,
    } = fulfillmentInfo
    const { selectedProductCode, productData, productSkuData } = variantsInfo

    const nearbyStoreLabelByAvailability = useMemo(
        () => (selectedStoreQty === MagicNumber.ZERO && orderable ? selectNearByStoreLabel : availableAtNearbyStores),
        [selectedStoreQty, orderable, selectNearByStoreLabel, availableAtNearbyStores],
    )
    const bopisNearbystoresLabel = useMemo(
        () => (isAvailableInDC ? needItTodayLabel : availableAtNearbyStores),
        [isAvailableInDC, needItTodayLabel, availableAtNearbyStores],
    )
    if (selectStoreMsg.current === '') {
        selectStoreMsg.current = bopisNearbystoresLabel
    }
    const nearByStoreLabel = useMemo(
        () => (isShipToHome ? nearbyStoreLabelByAvailability : selectStoreMsg.current),
        [nearbyStoreLabelByAvailability, isShipToHome, selectStoreMsg],
    )

    const isStoreSelected = useMemo(() => {
        if (!inStockInSelectedStore && inStockAtNearbyStores) {
            return !!selectedStoreName
        } else {
            return true
        }
    }, [inStockInSelectedStore, inStockAtNearbyStores, selectedStoreName])

    const inStockAtDC = useMemo(
        (): boolean =>
            isInStockAtDC(
                storeDetails,
                () => quantityAtStoreDC,
                () => hasOnlyBOPISEnabled,
                isSeparatedErrorHandling,
            ),
        [storeDetails, quantityAtStoreDC, isSeparatedErrorHandling, hasOnlyBOPISEnabled],
    )

    const getNearByStoreLabel = useCallback(() => {
        return freeShippingEnabled ? selectNearByStoreLabel : nearByStoreLabel
    }, [freeShippingEnabled, selectNearByStoreLabel, nearByStoreLabel])

    const getStoreInventoryQtyFromSKU = useCallback(
        (store: StoreWithAvailability): number => {
            const storeProductData: Inventory[] = store?.inventory?.filter(p => p.sku === selectedProductCode) || []
            return storeProductData.length ? storeProductData[0].Quantity : 0
        },
        [selectedProductCode],
    )

    const getSumOfQuantityOfProductNearbyStores = useCallback((): number => {
        return nearByStoreListForSelectedSku
            ?.map(store => getStoreInventoryQtyFromSKU(store))
            .reduce((previousQuantity, currentQuantity) => previousQuantity + currentQuantity)
    }, [nearByStoreListForSelectedSku, getStoreInventoryQtyFromSKU])

    const currentMaxQuantity = useMemo((): number => {
        const quantityAtCurrentStoreAndNearby =
            nearByStoreListForSelectedSku.length && isSeparatedErrorHandling
                ? getSumOfQuantityOfProductNearbyStores()
                : quantityAtStore || MagicNumber.ZERO
        const skuObject = getSelectedVariantSKU(productData, selectedProductCode, productSkuData?.skus?.[0])
        const skuPcodePurchasableQty = checkDataLength(skuObject as unknown as Record<string, unknown>)
            ? skuObject?.maxPurchasableQty || productData.maxPurchasableQty
            : MagicNumber.ZERO
        limitMsgValue.current = libUtils.getNumberOrDefault(skuPcodePurchasableQty)
        const inventoryQuantity = isDenyToUseStoreInventory
            ? quantityAtStoreDC
            : quantityAtStoreDC + quantityAtCurrentStoreAndNearby

        const availableQuantity = !isShipToHome && isSeparatedErrorHandling ? quantityAtStore : inventoryQuantity

        return checkMaxQuantity(
            libUtils.getNumberOrDefault(skuPcodePurchasableQty),
            availableQuantity,
            bannerMaxPurchasableQuantity ? Number(bannerMaxPurchasableQuantity) : 0,
        )
    }, [
        productData,
        bannerMaxPurchasableQuantity,
        selectedProductCode,
        quantityAtStore,
        quantityAtStoreDC,
        productSkuData,
        isShipToHome,
        isSeparatedErrorHandling,
        getSumOfQuantityOfProductNearbyStores,
        nearByStoreListForSelectedSku,
        isDenyToUseStoreInventory,
    ])

    const maxQuantityAvailable = useMemo(() => {
        let maximumQuantity = 0
        const quantityFromAvailability = availability?.quantity || MagicNumber.ZERO
        if (
            inStockInSelectedStore &&
            inStockAtDC &&
            !isAvailableInDC &&
            libUtils.getNumberOrDefault(qtySelectorInput) > quantityFromAvailability
        ) {
            limitMsgValue.current = libUtils.getNumberOrDefault(qtySelectorInput)
            maximumQuantity = quantityFromAvailability
        } else {
            maximumQuantity = currentMaxQuantity
        }
        return maximumQuantity > maxQuantity ? maxQuantity : maximumQuantity
    }, [
        maxQuantity,
        currentMaxQuantity,
        inStockInSelectedStore,
        qtySelectorInput,
        availability,
        inStockAtDC,
        isAvailableInDC,
    ])

    const quantityExceeded = useMemo(
        () => libUtils.getNumberOrDefault(qtySelectorInput) > maxQuantityAvailable,
        [maxQuantityAvailable, qtySelectorInput],
    )

    const inStockAtDCOnly = useMemo(
        () => !inStockInSelectedStore && !inStockAtNearbyStores && inStockAtDC,
        [inStockInSelectedStore, !inStockAtNearbyStores, inStockAtDC],
    )

    const availableForATC = !(freeShippingEnabled && !isShipToHome && inStockAtDCOnly)

    useEffect(() => {
        dispatch(setQuantitySelected(qtySelectorInput || MagicNumber.ONE))
    }, [qtySelectorInput, dispatch])

    /**
     * useEffect to set quantity selector error message
     */
    useEffect(() => {
        if (quantityExceeded) {
            setQuanitySelectorErrorMsg(
                libUtils.getNumberOrDefault(qtySelectorInput),
                setQtySelectorErrorMsg,
                quantityAtStoreDC,
                quantityAtStore,
                productData,
                selectedProductCode,
                libUtils.getStringOrDefault(exceedMaximumSellQuantityMessage),
                libUtils.getStringOrDefault(exceedMaximumPurchableMessage),
                libUtils.getStringOrDefault(maximumQuantityError),
                productSkuData?.skus?.[0],
                qtySelectorErrorMsg,
                Boolean(isSeparatedErrorHandling),
                isShipToHome,
                Boolean(isDenyToUseStoreInventory),
            )
        }
    }, [
        qtySelectorInput,
        bannerMaxPurchasableQuantity,
        exceedMaximumPurchableMessage,
        exceedMaximumSellQuantityMessage,
        maximumQuantityError,
        productData,
        quantityAtStore,
        quantityAtStoreDC,
        selectedProductCode,
        productSkuData,
        qtySelectorErrorMsg,
        quantityExceeded,
        isSeparatedErrorHandling,
        isShipToHome,
        isDenyToUseStoreInventory,
        setQtySelectorErrorMsg,
    ])

    return {
        limitMsgValue,
        inStockAtDC,
        isStoreSelected,
        maxQuantityAvailable,
        quantityExceeded,
        availableForATC,
        nearByStoreLabel: getNearByStoreLabel(),
    }
}
