import { MagicNumber } from '../analytics/analytics.type'
import { IStorageProvider } from '../providers/storage/storage.interface'
import { Vehicle, AutoAttributes } from '@nl/lib'

/**
 * @class {StorageService}
 */
export class StorageService {
    provider: IStorageProvider

    /**
     * @constructor
     * @param {IStorageProvider} provider
     */
    constructor(provider: IStorageProvider) {
        this.provider = provider
    }

    /**
     * This function used to add preferred vehicle to local storage
     * @param {string} storageKey
     * @param {Vehicle | Vehicle[]} vehicleInfo
     * @return {Promise} promise
     */
    addVehicle(storageKey: string, vehicleInfo: Vehicle | Vehicle[]) {
        return new Promise((resolve, reject) => {
            this.provider
                .get(storageKey)
                .then((data: Vehicle[]) => {
                    data ?? (data = [])
                    const storageUpdate =
                        Array.isArray(vehicleInfo) && vehicleInfo.length ? [...vehicleInfo] : [vehicleInfo, ...data]
                    this.provider.set(storageKey, storageUpdate).catch(err => console.error(err))
                })
                .then(data => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * This function used to update preferred vehicle in local storage
     * @param {string} storageKey
     * @param {Vehicle | Vehicle[]} vehicleInfo
     * @param {number} vehicleId
     * @param {string} vehicleConfigIds
     * @return {Promise} promise
     */
    updateVehicle(storageKey: string, vehicleInfo: Vehicle, vehicleId: number, vehicleConfigIds: string) {
        return new Promise((resolve, reject) => {
            this.provider
                .get(storageKey)
                .then((data: Vehicle[]) => {
                    data ?? (data = [])
                    const vehicleIndex = data.findIndex(vehicleObj => vehicleObj.id === vehicleId)
                    if (vehicleIndex !== MagicNumber.MINUS_ONE) {
                        data[vehicleIndex].autoAttributes = vehicleInfo.autoAttributes
                        const vehicleConfig: unknown[] = []
                        vehicleConfigIds?.split(',').forEach(ele => {
                            const attribute = Object.keys(vehicleInfo.autoAttributes as AutoAttributes).find(
                                item => item === ele,
                            )
                            attribute && vehicleConfig.push(attribute)
                        })
                        if (vehicleConfig.length > 0) {
                            vehicleConfig.forEach(ele => {
                                data[vehicleIndex][`displayName${String(ele)}` as keyof Vehicle] =
                                    vehicleInfo[`displayName${String(ele)}` as keyof Vehicle]
                            })
                        }
                        // OCCP-23556: also need to update lang and vehicleType
                        data[vehicleIndex].lang = vehicleInfo.lang
                        data[vehicleIndex].vehicleType = vehicleInfo.vehicleType
                    }
                    this.provider.set(storageKey, [...data]).catch(err => console.error(err))
                })
                .then(data => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * This function used to remove preferred vehicle from local storage
     * @param {string} storageKey
     * @param {Vehicle | Vehicle[]} vehicleInfo
     * @return {Promise} promise
     */
    removeVehicle(storageKey: string, vehicleInfo: Vehicle) {
        return new Promise((resolve, reject) => {
            this.provider
                .get(storageKey)
                .then((data: Vehicle[]) => {
                    data ?? (data = [])
                    const updatedData = data.filter(vehicle => vehicle.id !== (vehicleInfo.id as number))
                    this.provider.set(storageKey, updatedData).catch(err => console.error(err))
                })
                .then(data => {
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * This function used to get vehicle list from local storage
     * @param {string} storageKey
     * @return {Promise<T>} promise
     */
    getVehicleList<T>(storageKey: string): Promise<T> {
        return this.provider.get(storageKey) as Promise<T>
    }

    /**
     * This function used to set vehicle list in local storage
     * @param {string} storageKey
     * @param {Vehicle[]} vehicleList
     * @return {Promise} promise
     */
    setVehicleList(storageKey: string, vehicleList: Vehicle[]) {
        return this.provider.set(storageKey, vehicleList)
    }

    /**
     * This function used to set visited PDP in local storage
     * @param {string} storageKey
     * @param {any} visitedPLP
     * @return {Promise} promise
     */
    setVisitedPLP(storageKey: string, visitedPLP: any) {
        return this.provider.set(storageKey, visitedPLP)
    }
}
export default StorageService
