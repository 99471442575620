import React from 'react'
import { PREFIX } from '@nl/app/src/config'
import { SizeTitleType } from './SizeChart.type'

/**
 * Size Title component
 * @param {SizeTitleType} props - header
 * @returns {JSX.Element} returns SizeTitle component
 */
const SizeTitle: React.FC<SizeTitleType> = ({ sizeTitle, isRowTitleAvailable }): JSX.Element | null => {
    const createInnerHTML = () => {
        return {
            __html: sizeTitle,
        }
    }
    return (
        <th
            scope="col"
            key={`${sizeTitle}`}
            className={`${PREFIX}-size-chart__${isRowTitleAvailable ? 'table-header' : 'table-header-th'}`}
            dangerouslySetInnerHTML={createInnerHTML()}
        />
    )
}

export default SizeTitle
