import { Vehicle } from '@nl/lib'
import { VehicleFitType } from '../../globalConstants'
import { SignOutResponseError, SoftRecallCTARequestPayload, UserProfileData } from '../models/user.profile.interface'
import { RootState } from '../reducers'
import { VehicleState } from '../reducers/user.profile.reducer'

export const userProfileVehicleSelector = (state: RootState): VehicleState => state.userProfile.vehicle
export const vehicleListSelector = (state: RootState): Vehicle[] => state.userProfile.vehicle.list
export const isVehicleSyncedWithLSOnPageLoadSelector = (state: RootState): boolean =>
    state.userProfile.vehicle.isVehicleSyncedWithLSOnPageLoad
export const defaultVehicleSelector = (state: RootState): Vehicle | undefined =>
    state.userProfile.vehicle.defaultVehicle
export const vehicleFitTypeSelector = (state: RootState): VehicleFitType => state.userProfile.vehicle.vehicleFitType
export const vehicleFormPropsSelector = (state: RootState): Record<string, unknown> | undefined =>
    state.userProfile.vehicle.vehicleFormProps
export const vehicleIdSelector = (state: RootState): Record<string, string> => state.userProfile.vehicle.vehicleId

export const userProfileDataSelector = (state: RootState): UserProfileData | null => state.userProfile.userProfileData
export const profileUpdateSelector = (state: RootState): boolean => state.userProfile.profileUpdate
export const updateErrorDataSelector = (state: RootState): SignOutResponseError | null =>
    state.userProfile.updateErrorData
export const signOutSuccessSelector = (state: RootState): boolean => state.userProfile.signOutSuccess
export const userProfileLocationSelector = (state: RootState): string => state.userProfile.location
export const isAuthFlowExecutedSelector = (state: RootState): boolean => state.userProfile.isAuthFlowExecuted
export const isSoftRecallCTAClickedSelector = (state: RootState): SoftRecallCTARequestPayload =>
    state.userProfile.isSoftRecallCTAClicked
export const profilePendingSelector = (state: RootState) => state.userProfile.profilePending
export const isUserDataFetchedSelector = (state: RootState): boolean => state.userProfile.isUserDataFetched
export const userProfileUID = (state: RootState): string | undefined => state.userProfile.userProfileData?.UID
export const isUserRehydratedProfileSelector = (state: RootState): boolean => state.userProfile.rehydrateUserProfile
export const selectedVehicleSelector = (state: RootState): string | undefined =>
    state.userProfile.vehicle.selectedVehicle
export const selectedVehicleIndexSelector = (state: RootState): number => state.userProfile.vehicle.selectedVehicleIndex
export const isVehicleWithoutBodyAndOptionSelector = (state: RootState): boolean | null =>
    state.userProfile.vehicle.withoutBodyAndOption
export const gigyaUpdateDataSelector = (state: RootState): boolean => state.userProfile.isGigyaActionsUpdated
