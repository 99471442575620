export const pageTypes = {
    searchPage: 'search',
    pdpPage: 'product',
    productPage: 'product',
    brandPage: 'brandlisting',
    brandCategoryPage: 'brandcategorylisting',
    plp: 'categorylevelN',
    categoryPages: ['categorylevel1', 'categorylevel2', 'categorylevelN'], // dlp, clp, cplp
    wishlist: 'wishlist',
    shoppingCart: 'cart',
    flyer: 'flyer',
    allBrandsPage: 'allbrands',
    errorPage: 'error',
    account: 'account',
    weeklyOffer: 'offers',
    webOrderHistory: 'orderhistory',
    accountSetting: 'accountsettings',
    accountDasboard: 'accountdashboard',
    emailOfferActivation: 'emailofferactive',
    checkoutPage: 'checkout',
    promoListing: 'promolisting',
}

export const pageNumberConstants = {
    pageNumber: 1,
}

export const isAccountPageType = (pagetype: string): boolean => {
    const accountPageList = [
        pageTypes.account,
        pageTypes.weeklyOffer,
        pageTypes.webOrderHistory,
        pageTypes.accountSetting,
        pageTypes.accountDasboard,
    ]
    return Boolean(accountPageList?.find(account => account === pagetype))
}
