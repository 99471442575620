import { SortType, DescriptorSortType } from './BuyBox.type'
import { MagicNumber } from '../../analytics/analytics.type'
/**
 * Custom sort order
 * @param {DescriptorSortType} data unsorted data array
 * @param {string[]} sortBy desired sort order array
 * @param {string} sortField the field to sort by
 * @returns { Array } sorted data array
 */
export const customSort = ({ data, sortBy, sortField }: SortType): DescriptorSortType[] => {
    const sortByObject: Record<string, number> = sortBy.reduce((obj, item, index) => {
        return {
            ...obj,
            [item]: index,
        }
    }, {})
    return data.sort(
        (a, b) =>
            sortByObject[a[sortField] as keyof DescriptorSortType] -
            sortByObject[b[sortField] as keyof DescriptorSortType],
    )
}

/**
 * Normal sort fn by descriptor field
 * @param { Object } x current item
 * @param { Object } y the next item
 * @returns { Array } sorted array
 */
export const descriptorSort = (x: DescriptorSortType, y: DescriptorSortType): number => {
    const a = x.descriptor
    const b = y.descriptor
    return a === b ? 0 : a > b ? MagicNumber.ONE : MagicNumber.MINUS_ONE
}
