import React, { useCallback, useEffect, useRef } from 'react'

import SizeTitle from './SizeTitle'
import Table from './Table'
import MeasureDescription from './MeasureDescription'

import { PREFIX, previousElementName } from '../../../config'
import { ReactModal, Icon, modalAccessibilityHandler, isArrayNotEmpty } from '@nl/lib'
import { SizeChartProps, MeasureDescriptionType } from './SizeChart.type'
import { HEADER_ELEMENT_ID, FOOTER_ELEMENT_ID, FOOTER_SHADOW_CLASS } from './SizeChart.constant'
import { isObjectNotEmpty } from '../../../utils/isObjectNotEmpty'
import { useClickOutsideClose } from '@nl/lib/src/components/Tooltip/useClickOutside'

/**
 * Size Chart modal component
 * @param {SizeChartProps} props - props for Size Chart component
 * @returns {JSX.Element} returns Size Chart component
 */
const SizeChart: React.FC<SizeChartProps> = ({
    isSizeChartOpen,
    setShowSizeChart,
    path,
    sizeChartData,
    howToMeasureLabel,
    isInMiniPDPFlyout,
    backBtnLabel,
}): JSX.Element => {
    const componentClassName = `${PREFIX}-size-chart`
    const contentElement = useRef(null)

    useEffect(() => {
        if (isSizeChartOpen && contentElement && contentElement.current) {
            const {
                current: { scrollHeight, clientHeight },
            } = contentElement
            if (scrollHeight > clientHeight) {
                const footerEle = document.getElementById(FOOTER_ELEMENT_ID)
                footerEle?.classList.add(FOOTER_SHADOW_CLASS)
            }
        }
    }, [isSizeChartOpen])

    // Below code is for handling the accessibility when clicked on close button
    const modalClassName = `${PREFIX}-react-modal`

    const previousElement: HTMLElement | null = document.querySelector(`button[${previousElementName}]`)

    const contentRef = useRef(null)

    /**
     * To handle accessibility on keyboard tab. Enables the modal accessibility when clicked on the trigger element.
     * @param {boolean} isContinueClicked - true or false based on whether clicked on continue or close/cancel button.
     * @returns {void} returns void
     */
    const onCloseSafetyModal = (isContinueClicked: boolean): void => {
        modalAccessibilityHandler({ modalOpen: false, modalClassName })
        setShowSizeChart(isContinueClicked)
        if (!isContinueClicked && previousElement) {
            previousElement.focus() // Highlight the initiated button
            previousElement.removeAttribute(previousElementName) // Removing it when user close it.
        }
    }

    const tables = sizeChartData?.tables
    const headerLabel = sizeChartData?.title
    const howToMeasureData = sizeChartData?.measure
    const closeHandler = useCallback(
        (event?: MouseEvent | KeyboardEvent) => {
            if ((event?.target as HTMLElement).contains(contentRef.current)) {
                onCloseSafetyModal(false)
            }
        },
        [onCloseSafetyModal],
    )
    useClickOutsideClose(contentRef, closeHandler, isSizeChartOpen, true, true)

    return (
        <>
            {isObjectNotEmpty(sizeChartData as never) && (
                <ReactModal isOpen={isSizeChartOpen} isHeightFix={true} notScroll={false}>
                    <div className={`${componentClassName}`} ref={contentRef}>
                        <div
                            id={HEADER_ELEMENT_ID}
                            className={`${componentClassName}-wrapper`}
                            data-testid={HEADER_ELEMENT_ID}>
                            {isInMiniPDPFlyout ? (
                                <div className={`${componentClassName}__back-btn-container`}>
                                    <button
                                        className={`${componentClassName}__back-btn`}
                                        onClick={() => onCloseSafetyModal(false)}
                                        aria-label="back">
                                        <Icon type="ct-chevron-left" size="lg" />
                                        {backBtnLabel}
                                    </button>
                                </div>
                            ) : (
                                <div className={`${componentClassName}__close-container`}>
                                    <button
                                        data-testid="sizeChart-close-modal"
                                        className={`${componentClassName}__close-btn`}
                                        onClick={() => onCloseSafetyModal(false)}
                                        aria-label="close">
                                        <Icon type="ct-close" size="lg" path={path} />
                                    </button>
                                </div>
                            )}
                            <div className={`${componentClassName}__header-text-container`}>
                                <h3 className={`${componentClassName}__header-text`}>{headerLabel}</h3>
                            </div>
                            {tables?.map((table, index) => {
                                const tableData = table.rows
                                const tableTitle = !!table.title ? <caption>{table.title}</caption> : null
                                const tableKeyBase = table.title as string
                                return (
                                    <table
                                        key={`tableKey-${tableKeyBase}&${index}`}
                                        className={`${componentClassName}`}>
                                        {tableTitle}
                                        <thead>
                                            <tr>
                                                {table?.header?.map((sizeTitle: string) => {
                                                    return (
                                                        <SizeTitle
                                                            sizeTitle={sizeTitle}
                                                            isRowTitleAvailable={table.rowTitleAvailable}
                                                            key={`sizeTitleKey-${sizeTitle}-${index}`}
                                                        />
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className={`${componentClassName}__content__table`}>
                                            {tableData?.map((sizeData: string[]) => {
                                                return (
                                                    <Table
                                                        sizeData={sizeData}
                                                        isRowTitleAvailable={table.rowTitleAvailable}
                                                        key={`sizeDataKey-${sizeData?.toString()}`}
                                                    />
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                )
                            })}
                            {isArrayNotEmpty(howToMeasureData) && (
                                <div>
                                    <h3 className={`${componentClassName}__measure-header`}>{howToMeasureLabel}</h3>
                                    <ol>
                                        {howToMeasureData?.map((point: MeasureDescriptionType) => {
                                            return (
                                                <MeasureDescription
                                                    title={point?.title}
                                                    description={point.description}
                                                    key={`pointKey-${point.description}`}
                                                />
                                            )
                                        })}
                                    </ol>
                                </div>
                            )}
                        </div>
                    </div>
                </ReactModal>
            )}
        </>
    )
}

export default SizeChart
