import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import { PREFIX } from '../config'
import { AutomotiveErrorCardProps } from './AutomotiveErrorCard.types'

/**
 * AutomotiveErrorCard component
 * @param {AutomotiveErrorCardProps} props
 * @return {JSX.Element} returns AutomotiveErrorCard
 */
const AutomotiveErrorCard: React.FC<AutomotiveErrorCardProps> = props => {
    const { image, imageAltText, title, description, question, ctaLabel, ctaClickHandler, linkLabel, linkUrl } = props

    return (
        <div className={`${PREFIX}-automotive-error-card`}>
            <div className={`${PREFIX}-automotive-error-card__error-img`}>
                <img src={`${image}`} alt={`${imageAltText}`} />
            </div>
            <h3 className={`${PREFIX}-automotive-error-card__title`}>{title}</h3>
            <p className={`${PREFIX}-automotive-error-card__content`}>{description}</p>
            {!!question && <p className={`${PREFIX}-automotive-error-card__content`}>{question}</p>}
            <Button
                id="automotive-error"
                type="primary"
                size="medium"
                onClick={() => ctaClickHandler(ctaLabel)}
                label={ctaLabel}></Button>
            {!!linkUrl && !!linkLabel && (
                <a href={linkUrl} rel="noopener noreferrer" className={`${PREFIX}-automotive-error-card__link`}>
                    {linkLabel}
                </a>
            )}
        </div>
    )
}

AutomotiveErrorCard.propTypes = {
    image: PropTypes.string,
    imageAltText: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    question: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaClickHandler: PropTypes.func,
    linkLabel: PropTypes.string,
    linkUrl: PropTypes.string,
}

AutomotiveErrorCard.displayName = 'AutomotiveErrorCard'

export default AutomotiveErrorCard
