import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import Button from '../Button'
import { PREFIX, CommonClass } from '../config'
import { ctaListPropsType } from './SignInDropDown.type'
import { externalNavigation } from '../../utils/getExternalLinks'

/**
 * SignInDropDownItem component
 *
 * @param {ctaListPropsType} props - item,type,onClickHandler
 * @return {JSX.Element} returns SignInDropDownItem component
 */
const SignInDropDownItem: React.FC<ctaListPropsType> = (props: ctaListPropsType): JSX.Element => {
    const { item, type, onClickHandler, onFlyout = false } = props

    /**
     * Function to render signin option component
     * @return {JSX.Element}
     */
    const renderSignInOptionsComp = (): JSX.Element => {
        return (
            <>
                {!onFlyout && (
                    <img className={`${PREFIX}-right-dropdown__image`} src={item.ctaLogo} alt={item.ctaLogoAltText} />
                )}
                <span className={`${PREFIX}-right-dropdown__label`}>{item.ctaLabel}</span>
                {externalNavigation(item.ctaUrl) && <Icon type="ct-external" size="md" />}
            </>
        )
    }

    const itemRender = (): JSX.Element => {
        return type ? (
            <Button type="tertiary" size="mini" id="sign-in-button" ariaLabel={item.ctaLabel} onClick={onClickHandler}>
                {renderSignInOptionsComp()}
            </Button>
        ) : (
            <a
                href={item.ctaUrl}
                target={item.ctaLinkTarget}
                className={`${PREFIX}-right-dropdown__link ${CommonClass.link} ${CommonClass.link}--md ${CommonClass.link}--primary`}>
                {renderSignInOptionsComp()}
            </a>
        )
    }

    return itemRender()
}

SignInDropDownItem.propTypes = {
    item: PropTypes.object,
    type: PropTypes.bool,
    onClickHandler: PropTypes.func,
    onFlyout: PropTypes.bool,
}

export default SignInDropDownItem
