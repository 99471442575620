import React, { useState, useEffect, useRef, useCallback } from 'react'

import { PREFIX } from '../config'
import ProductItemInfo from './ProductItemInfo'
import QuantitySelector from '../QuantitySelector'
import Icon from '../Icon'
import {
    CartItemComponentPropsType,
    ProductCartItemProps,
    eEntryType,
    OptionsOnCartItem,
    ProductSourceTypes,
    ItemInlineErrorType,
    DeliveryStatusSTH,
    ProductDataDTO,
    CartOrderDeliveryModes,
} from './ProductItemInfo.type'
import {
    checkIsStockInAllStore,
    checkIsInWishList,
    checkDeliveryMethodType,
    storeMaxQuantityInfo,
    renderHeliumInflationUnInflationText,
    renderHeliumAppointmentInfoMessage,
    isProductFromPackage,
    showProductYouMayAlsoLikeBtn,
} from './ProductCartItem.helper'
import { productCartItems, saveForLaterParam } from './ProductCartItem.constant'
import { Price as PriceType } from '../Price/Price.types'
import {
    replaceStrWithDynamicVal,
    replaceMultipleStrWithDynamicVal,
    magicNumber,
    getSourceTypeData,
    CartItemFulfillmentSource,
} from '../../utils'
import { addModalAttribute } from '../../utils/modalAccessibility'
import { maxInputVal as maxInputDefaultVal } from '../QuantitySelector/quantitySelectorConstant'
import CommonCard from '../CommonCard/CommonCard'
import Badges from '../Badges'
import CartServicesComponent from '../CartServices'
import { extractServiceBasedOnSku, getPriceValue } from './ProductCartItem.util'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { customDebounce } from '../../utils/customDebounce'
import ProductImageComponent from './ProductImageComponent'
import Rebate from '../Rebate'
import Button from '../Button'
import Price from '../Price'
import localization from '../../helpers'
import {
    cancellationAnalytics,
    isOrderSTH,
    renderWarningToast,
    showWishListIcon,
    showHideSaleInfo,
    showHideWasLabel,
    isBalloonProduct,
    getFeeTitle,
    validation,
    addOrRemoveWishlist,
    renderWishListIconComponent,
    setQuantityExceedErrForExpressDeliveryProduct,
} from './ProductCartItem.helper'
import CommonWarningToast from './CommonWarningToast'
import Tag from '../Tag'
import { AvailabilityBullet } from './ProductItemInfo.constant'
import { getETATimeOptions } from '../../utils/getETATimeOptions'
import AutomotiveAddonsListWrapper from '../AutomotiveProductCards/AutomotiveAddonsListWrapper'
import { useGenerateSaveMessage } from '../Price/Price.helper'
import usePrevious from '../../hooks/usePrevious.hook'
import { wishlistPrefixId } from '../../utils/getAccessibilityId'
import { urlRedirection } from '../../utils/isUrl'
import SanitizeStringContentWrapper from '../../utils/sanitizeStringContent'
import { inflationData } from '../../globalConstants/global.constant'
import STHDeliveryLabels from './STHDeliveryLabels'
import PromoMessages from '../PromoMessages'
import { isAtleastOneParamValid } from '../../utils/validParams'
import { CartOrderEntries } from './ProductCartItem.type'

/**
 * function to display add to cart cta
 * @param { ProductDataDTO } product
 * @param { boolean } enableAddTireWheelsByFourFromWishlist
 * @param { isWheelOrTire } isWheelOrTire
 * @return { boolean }
 */
export const checkForQuantityAvailability = (
    product: ProductDataDTO,
    enableAddTireWheelsByFourFromWishlist: boolean,
    isWheelOrTire: boolean,
): boolean => {
    // AEM toggle to hide add to cart button
    if (enableAddTireWheelsByFourFromWishlist) {
        if (isWheelOrTire) {
            // if AEM toggle is on check for quantity and staggered product
            //  product.availableActions?.isAddToCartEnabled - false - staggered product
            return (
                product.availableActions?.isAddToCartEnabled &&
                product?.fulfillment?.availability.quantity >= magicNumber.FOUR
            )
        } else {
            // display ATC for auto part
            return true
        }
    } else {
        // if AEM toggle is off hide Add to cart button for tire and wheel
        return !isWheelOrTire
    }
}

/**
 * function to display check product data availability
 * @param { ProductDataDTO } productData
 * @param { CartOrderEntries[] } extractSelectedServiceList
 * @return { boolean }
 */
export const checkProductDataServicesListAvailability = (
    productData: ProductDataDTO,
    extractSelectedServiceList: CartOrderEntries[],
): boolean => {
    return productData.servicesList && productData.servicesList.length && !extractSelectedServiceList?.length
}

/**
 * function to check service list
 * @param { ProductCartItemProps } productData
 * @param { CartOrderEntries[] } addOnServices
 * @return { boolean }
 */
export const checkServiceList = (productData: ProductCartItemProps, addOnServices: CartOrderEntries[]): boolean => {
    return (
        productData.itemAvailabilityInfo &&
        !productData.itemAvailabilityInfo.hardStopError &&
        isArrayNotEmpty(addOnServices)
    )
}

/**
 * function to check ordering from store and DC
 * @param { CartItemFulfillmentSource } storePickupDC
 * @param { CartItemFulfillmentSource } storePickupInStore
 * @return { boolean }
 */
export const checkIsOrderFromInStoreAndDC = (
    storePickupDC: CartItemFulfillmentSource,
    storePickupInStore: CartItemFulfillmentSource,
): boolean => {
    return Boolean(Object.keys(storePickupDC).length) && Boolean(Object.keys(storePickupInStore).length)
}

/**
 * function to check is estimated delivery date available and quantity is not splitted
 * @param { boolean } isEstimatedDeliveryDateAvailable
 * @param { boolean } isOrderFromInStoreAndDC
 * @return { boolean }
 */
export const checkIsEstimatedDeliveryDateAvailableNotSplitQty = (
    isEstimatedDeliveryDateAvailable: boolean,
    isOrderFromInStoreAndDC: boolean,
): boolean => {
    return isEstimatedDeliveryDateAvailable && !isOrderFromInStoreAndDC
}

/**
 * function to check is estimated delivery date available and quantity is splitted
 * @param { boolean } isEstimatedDeliveryDateAvailable
 * @param { boolean } isOrderFromInStoreAndDC
 * @return { boolean }
 */
export const checkIsEstimatedDeliveryDateAvailableSplitQty = (
    isEstimatedDeliveryDateAvailable: boolean,
    isOrderFromInStoreAndDC: boolean,
): boolean => {
    return isEstimatedDeliveryDateAvailable && isOrderFromInStoreAndDC
}

/**
 * function to check is estimated delivery date will be rendered
 * @param { boolean } isShoppingCartPage
 * @param { boolean } isOrderConfirmationPage
 * @param { boolean } isSTH
 * @return { boolean }
 */
export const isShowEstimatedDeliveryDateLabel = (
    isShoppingCartPage: boolean,
    isOrderConfirmationPage: boolean,
    isSTH: boolean,
): boolean => {
    return (isShoppingCartPage && isSTH) || isOrderConfirmationPage
}

/**
 * Product Cart Item component
 * @param {ProductCartItemProps} props - props
 * @returns {JSX.Element} - Product Cart Item component
 */
const ProductCartItem: React.FC<ProductCartItemProps> = ({ ...props }: ProductCartItemProps): JSX.Element => {
    const {
        cart,
        availableLabel,
        changeShipToHomeCTALabel,
        pickAtStoreCTALabel,
        betweenLabel,
        inStorePurchaseOnlyMsg,
        isFreePickUpAtStore,
        isShipToHome,
        isServices,
        servicesDisclaimer,
        homePageLink,
        productData,
        addItemToWishlist,
        a11yWishlistIcon,
        a11yRemoveLabel,
        onChangeFulfillmentOption,
        onRemoveCartItem,
        exceededTotalAvailableMsg,
        updateCartData,
        eachLabel,
        limitOfLabel,
        exceededMsg,
        receiveBetweenLabel,
        availableByLabel,
        availableBetweenLabel,
        checkoutButtonClick,
        warningToastShow,
        exceededMsgBar,
        cartItemAnalytics,
        badgePriorities,
        inImageBadgePriorities,
        a11yServiceInstallation,
        serviceAddToCartHandler,
        selectedServiceList,
        quantitySelectorId,
        accessibilityGlobalProps,
        itemAvailabilityInfo,
        bulkLabel,
        updatedLabel,
        isWishList,
        PDPLink,
        rebateIcon,
        productColorLabel,
        inStockLabel,
        wishlistInStockLabel,
        addToCartLabel,
        addToCart,
        reviewsContainerId,
        onRemoveWishlistItem,
        onlineOrdering,
        language,
        saveLabel,
        thresholdValue,
        hideQuantitySelector,
        productDisclaimer,
        qtyAbbrLabel,
        dpBuyAgainLabel,
        dpLearnMoreLabel,
        discScheduleDelivery,
        discServiceInstallation,
        discCannotTrack,
        isDetailPage,
        changeBrowserBackUrl,
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        scrollToFooter,
        feeDisclaimerType,
        cancelProductLabel,
        getEntriesInfo,
        customerSelfServiceCancelEnabled,
        enablePartialOrderCancellation,
        disableAvailabilityMessages,
        urgentLowStock,
        saveForLaterTitle,
        addItemToSaveForLater,
        saveForLaterFlagEnabled,
        betweenLabelOrderConf,
        isPostPOSOrderDetails,
        betweenLabelSTH,
        deliveryMode,
        singleShippingMethodOption,
        shipsIn,
        shipsInForEA,
        pickUpIn,
        availableForPickUpIn,
        unavailableForBOPIS,
        unavailableAtStoreForBOPIS,
        saleEndDisableShift,
        a11yCloseIconLabel,
        unavailableForSTH,
        bannerMaxPurchasableQuantity: maxInputVal = maxInputDefaultVal,
        estimatedDeliveryDateBetween,
        removeitemFromWishlist,
        isShowedEstimatedDeliveryDateBetween,
        saleMessagesRules,
        showDiscountedPrice,
        highlightDiscountedPrice,
        isSelectedDeliveryAvailable,
        selectedDeliveryMode,
        itemNotEligibleForExpressDeliveryMsg,
        itemNotEligibleForStandardDeliveryMsg,
        expressDeliveryDoesNotSupportHeavyOversizedMsg,
        inStockOnlineLabel,
        isAvailableOnlyInDC,
        insufficientQuantityOfProductForExpressDeliveryMsg,
        setIsQtyExceed,
        isAllExpressProducts,
        a11yClickToReadFootnote,
        readyToDeliverLabel,
        wishlistProducts,
        etaTimeOfDelivery,
        isAuthUser,
        loginPageLink,
        cartPageLink,
        storeData,
        expressDeliveryAvailableNotification,
        expressDeliveryUnavailableNotification,
        isExpressDeliverySelected,
        plusMinusSymbol,
        displayRomanceCopyWithWasLabel,
        ssoHandler,
        enableSingleSignOn,
        isHeliumInflatedUnInflatedShown,
        heliumInflationUnInflationText,
        heliumAppointmentInfoMessage,
        showInflationLabels,
        isBouquetPackage,
        productYouMayNeedBtnClick,
        enableCartAccessoriesFlyout = false,
        prodYouMayAlsoNeedLabel,
    } = props

    const {
        images,
        quantity: itemQuantity,
        maxPurchaseQty: maxQuantity,
        totalPrice,
        name,
        fulfillment,
        sellable,
        rebate,
        originalPrice,
        currentPrice,
        discount,
        feeMessages,
        entryNumber,
        isBulk,
        priceMessage,
        url,
        saleCut,
    } = productData

    const isShipHome = isOrderSTH(productData)
    const { a11yTooltipIcon } = accessibilityGlobalProps

    const { oneProductQuantity } = productCartItems

    const [qtySelectorInput, setQtySelectorInput] = useState(itemQuantity)
    const [fromInput, setFromInput] = useState<boolean | undefined>(undefined)
    const [inputElementRef, setInputElementRef] = useState<React.MutableRefObject<unknown> | null>(null)

    const [prevQuantity, setPrevQuantity] = useState(itemQuantity)
    const cardClass = `${PREFIX}-common-card`
    const cartClass = `${PREFIX}-shopping-cart__item`
    const infoClass = `${PREFIX}-shopping-cart__product`
    const balloonProductClass = isBalloonProduct(props.productData) ? `${PREFIX}-product-cart-item` : ''
    const promoMessagesStyleClassMobile = `${PREFIX}-md-none ${PREFIX}-xs-block ${PREFIX}-sm-block ${PREFIX}-promo-messages-wrapper`

    const [globalBannerMaxVal, setGlobalBannerMax] = useState(false)
    const [inlineToastError, setInlineToastError] = useState(false)
    const [debounceTimeOut, setDebounceTimeOut] = useState<NodeJS.Timeout>(null)
    const showHideDiscountInfo = showHideSaleInfo(productData.originalPrice, isPostPOSOrderDetails)
    const storeMaxQuantity = Math.min(
        fulfillment?.stockItemAvailability?.storeQuantity,
        maxQuantity || fulfillment?.stockItemAvailability?.storeQuantity,
    )
    const isQuantityExceedThanStoreQty = Boolean(qtySelectorInput > storeMaxQuantity)
    const quantitySelectorMaxInput = selectedDeliveryMode?.isExpress ? storeMaxQuantity : maxQuantity
    const isExpressDeliveryModeForOrder = productData?.fulfillment?.deliveryMode === CartOrderDeliveryModes.EXPRESS

    const [extractSelectedServiceList, setExtractSelectedServiceList] = useState<CartOrderEntries[]>(
        extractServiceBasedOnSku(
            productData.code,
            selectedServiceList,
            isDetailPage,
            productData.status,
            productData.fulfillment?.deliveryMode,
        ),
    )
    const { cartDebounceTimeOut } = productCartItems
    const counterRef = useRef<number>(0)
    const enableBalloonAddons = props.enableRecommendedBalloonProducts === true
    const previousSelectedServiceList = usePrevious(selectedServiceList)
    const runErrorAnalyticsFlowOnlyOnce = useRef(0)
    const [qtyExceededErr, setQtyExceededErr] = useState(false)
    const isInWishList = checkIsInWishList(wishlistProducts, productData.code)

    useEffect(() => {
        if (selectedServiceList !== previousSelectedServiceList) {
            const addOnServices = extractServiceBasedOnSku(
                productData.code,
                selectedServiceList,
                isDetailPage,
                productData.status,
                productData.fulfillment?.deliveryMode,
            )
            setExtractSelectedServiceList(addOnServices)
            if (checkServiceList(props, addOnServices)) {
                addOnServices.forEach(service => {
                    if (!service.sellable) {
                        props.setErrorInfoHandler?.(true, false)
                    }
                })
            }
        }
    }, [
        isDetailPage,
        productData.code,
        productData.fulfillment,
        productData.status,
        props,
        selectedServiceList,
        previousSelectedServiceList,
    ])

    const optionsToBeDisplayed = itemAvailabilityInfo?.optionsToBeDisplayed
    /**
     * use effect to trigger error analytics on load when there is an inline errormessage.
     */
    useEffect(() => {
        if (runErrorAnalyticsFlowOnlyOnce.current === 0 && props.itemAvailabilityInfo) {
            runErrorAnalyticsFlowOnlyOnce.current = 1
            const { errorMessage, showInlineError, hardStopError, isShowETAToast } = props.itemAvailabilityInfo
            showInlineError && !!props.errorAnalyticsForInlineToast && props.errorAnalyticsForInlineToast(errorMessage)
            !!hardStopError && props.setErrorInfoHandler?.(showInlineError, false) // run this only when the error is hard stop error. so that user cant be allowed to proceed to checkout.
            isShowETAToast &&
                !!optionsToBeDisplayed?.includes(OptionsOnCartItem.UPDATED_TEXT) &&
                props.setErrorInfoHandler?.(false, true, true) // run this when eta changed third parameter is flag for eta change
        }
    }, [optionsToBeDisplayed, props])

    /**
     * Updates the cart data when quantity selector value is changed.
     *
     * @param {number} incrementValue - quantity selector input
     * @param {boolean} fromInputUpdate - check if updated from input
     * @param {boolen} inputRef
     * @return {void}
     */
    const priceIncrementHandler = (
        incrementValue: number,
        fromInputUpdate?: boolean,
        inputRef?: React.MutableRefObject<any>,
    ): void => {
        // OCCP-22010 logic changes for update cart - issue resolved when increment/decrement was not working during second update and remove cart
        setQtySelectorInput(incrementValue)
        setFromInput(fromInputUpdate)
        setInputElementRef(inputRef)
        !!qtySelectorInput && setPrevQuantity(qtySelectorInput)
        counterRef.current = magicNumber.ONE
    }

    /**
     * @method informationErrorMessage  : Forms information Message
     *  @param {string} errorMsg :Error Message String
     *  @param {boolean} errorMessageShow : value to check weather to show error Message or not
     * @returns {{errorMsg: string; quantityExceeded: boolean ; exceededValue:string}} returns information object
     *
     */

    const informationErrorMessage = (
        errorMessage: string,
        errorMessageShow: boolean,
        exceededValue: string,
    ): { errorMsg: string; quantityExceeded: boolean; exceededValue: string } => {
        return {
            errorMsg: errorMessage,
            quantityExceeded: errorMessageShow,
            exceededValue: exceededValue,
        }
    }

    /**
     * @method checkAvailableQuantity  : check the total availaible quantity
     * @returns {number} returns availaible quantity
     *
     */

    const checkAvailableQuantity = (): number => {
        const { dcQuantity, storeQuantity } = fulfillment?.stockItemAvailability || {}

        if (singleShippingMethodOption) {
            return deliveryMode === 'BOPIS' ? storeQuantity : dcQuantity
        }

        return fulfillment?.orderableFromDC && dcQuantity ? dcQuantity + storeQuantity : storeQuantity
    }

    // Method used to check the maxQuantity condition of information Message
    const checkMaxQuantityError = (): boolean => {
        const isExpressDeliveryHasQtyExceedErr = Boolean(
            storeData?.isExpressDeliveryEligible && selectedDeliveryMode?.isExpress && isQuantityExceedThanStoreQty,
        )
        return (
            (maxQuantity && qtySelectorInput > maxQuantity && maxQuantity <= checkAvailableQuantity()) ||
            isExpressDeliveryHasQtyExceedErr
        )
    }

    // Method used to check the if total availaible quantity is greter than quantity selected
    const validateQuantityRequest = (): boolean => {
        return qtySelectorInput > checkAvailableQuantity() && checkAvailableQuantity() < maxInputVal
    }

    // Method used to check if globalBanner Max Value when availabe quantity is greater than globalBanner Max value
    const checkGlobalBannerMax = (): boolean => {
        return qtySelectorInput > maxInputVal && checkAvailableQuantity() > maxInputVal
    }

    /**
     * Render updated text when there is some stock in dc.
     * @return {JSX.Element}
     */
    const renderUpdatedText = (): JSX.Element => {
        return (
            itemAvailabilityInfo?.optionsToBeDisplayed?.includes(OptionsOnCartItem.UPDATED_TEXT) && (
                <p className={`${infoClass}__updated-text`}>{updatedLabel}</p>
            )
        )
    }

    /**
     * function to set between label for STH DC for order confirmation and cart page
     * @return {string}
     */
    const getBetweenSTHLabel = (): string => {
        return isExpressDeliveryModeForOrder
            ? readyToDeliverLabel
            : props.isShoppingCartPage
            ? betweenLabel
            : betweenLabelOrderConf
    }

    /**
     * Prepares shipsIn message
     * @return {string} returns shipsInForEA message for EA product otherwise returns shipsIn message
     */
    const prepareShipsInMessage = (): string => {
        return fulfillment?.isEndlessAisle && !!shipsInForEA ? shipsInForEA : shipsIn
    }

    const renderExpressDeliveryNotification = (): JSX.Element | null => {
        const expressDeliveryAvailability =
            productData.fulfillment?.isEligibleForExpressDelivery &&
            productData.fulfillment?.stockItemAvailability?.storeQuantity
                ? expressDeliveryAvailableNotification
                : expressDeliveryUnavailableNotification

        return storeData?.isExpressDeliveryEligible ? <li>{expressDeliveryAvailability}</li> : null
    }

    // TODO read errorCode instead of isEligibleTo
    const renderAllAvailabilityInfo = (): JSX.Element => {
        const { isEligibleToPickupFromStore, isEligibleToShipHome, stockItemAvailability } = fulfillment || {}
        const isBopisCart = deliveryMode === 'BOPIS'
        const bopisAvailability =
            !isEligibleToPickupFromStore || !sellable
                ? unavailableForBOPIS
                : !stockItemAvailability?.storeQuantity
                ? replaceStrWithDynamicVal(unavailableAtStoreForBOPIS, cart?.storeData?.displayName)
                : isBopisCart
                ? pickUpIn
                : availableForPickUpIn
        const sthAvailability =
            isEligibleToShipHome && sellable && stockItemAvailability?.dcQuantity
                ? prepareShipsInMessage()
                : unavailableForSTH
        return (
            <>
                <li>{bopisAvailability}</li>
                <li>{sthAvailability}</li>
                {renderExpressDeliveryNotification()}
            </>
        )
    }

    // Method used for creating multiple labels for STH when product is delivered in intervals
    const shipToHomeLabel = (): JSX.Element | null => {
        const { dcQuantity, storeQuantity: currentSelectedStoreQuantity } = fulfillment?.stockItemAvailability || {}
        const { sourceTypes = [] } = fulfillment || {}
        const storePickupDC = getSourceTypeData(sourceTypes, ProductSourceTypes.DC)
        const storePickupInStore = getSourceTypeData(sourceTypes, ProductSourceTypes.INSTORE)
        const isLimitedStockAndAvailableInDc = limitedStockAndAvailableInDc(
            dcQuantity,
            currentSelectedStoreQuantity,
            storePickupDC,
            storePickupInStore,
        )

        return (
            <STHDeliveryLabels
                cart={cart}
                productData={productData}
                isBulk={isBulk}
                selectedDeliveryMode={selectedDeliveryMode}
                isShowedEstimatedDeliveryDateBetween={isShowedEstimatedDeliveryDateBetween}
                singleShippingMethodOption={singleShippingMethodOption}
                isShoppingCartPage={props.isShoppingCartPage}
                isOrderConfirmationPage={props.isOrderConfirmationPage}
                receiveBetweenLabel={receiveBetweenLabel}
                betweenLabelSTH={betweenLabelSTH}
                etaTimeOfDelivery={etaTimeOfDelivery}
                renderAllAvailabilityInfo={renderAllAvailabilityInfo}
                isLimitedStockAndAvailableInDc={isLimitedStockAndAvailableInDc}
                getETAForRange={getETAForRange}
                getBetweenSTHLabel={getBetweenSTHLabel}
                estimatedDeliveryDateBetween={estimatedDeliveryDateBetween}
            />
        )
    }

    /**
     * Method used to check if fulfillment via DC stocks only
     * @param {number} dcQuantity - distribution center quantity.
     * @param {number} inStoreQuantity - current store quantity.
     * @param {CartItemFulfillmentSource} sourceDC - if fulfilled only through DC
     * @param {CartItemFulfillmentSource} sourceStore - if fulfilled through store
     * @return {boolean}
     */
    const checkOnlyDCStock = (
        dcQuantity: number,
        inStoreQuantity: number,
        sourceDC: CartItemFulfillmentSource,
        sourceStore: CartItemFulfillmentSource,
    ): boolean => {
        return props.isShoppingCartPage
            ? Boolean(dcQuantity && !inStoreQuantity)
            : Boolean(!Object.keys(sourceStore).length && Object.keys(sourceDC).length)
    }

    /**
     * Method used to check if fulfillment via DC stocks + in store stocks
     * @param {number} dcQuantity - distribution center quantity.
     * @param {number} inStoreQuantity - current store quantity.
     * @param {CartItemFulfillmentSource} sourceDC - if fulfilled through DC
     * @param {CartItemFulfillmentSource} sourceStore - if fulfilled through store
     * @return {boolean}
     */
    const limitedStockAndAvailableInDc = (
        dcQuantity: number,
        inStoreQuantity: number,
        sourceDC: CartItemFulfillmentSource,
        sourceStore: CartItemFulfillmentSource,
    ): boolean => {
        const actualQty =
            isExpressDeliveryModeForOrder && itemQuantity > inStoreQuantity ? inStoreQuantity : itemQuantity
        return props.isShoppingCartPage
            ? Boolean(dcQuantity && inStoreQuantity < actualQty)
            : Boolean(Object.keys(sourceDC).length && Object.keys(sourceStore).length)
    }

    /**
     * function to check if fulfillment is only through store
     * @param {number} storeQty - store quantity
     * @param {CartItemFulfillmentSource} sourceStore - if fulfilled through store
     * @return {boolean}
     */
    const checkOnlyStore = (storeQty: number, sourceStore: CartItemFulfillmentSource): boolean => {
        return props.isShoppingCartPage ? Boolean(storeQty) : Boolean(Object.keys(sourceStore).length)
    }

    /**
     * Method used to extract date and time from datetime stamp
     * @param {string} date date time stamp
     * @return {string} returns formatted date and time
     */
    const getETATime = (date?: string): string => {
        if (!date) return ''
        const options = getETATimeOptions(productCartItems.etaTimeOptions)
        const intlDate = localization.formattedDate(options, date, language)
        return intlDate
            .split(',')
            .reverse()
            .map(item => item.trim())
            .join(', ')
            .replace(/\./g, '')
            .replace('  ', '')
    }

    /**
     * Method used to extract date in MMM-dd format from datetime stamp
     * @param {string} date date time stamp
     * @return {string} returns formatted date
     */
    const getETAForRange = (date?: string): string => {
        if (!date) return ''
        const options = productCartItems.etaDateOptions
        const intlDate = localization.formattedDate(options, date, language)
        return intlDate.replace(/\./g, '')
    }

    /**
     * Method used for conditionally rendering the labels based on stock fulfillment
     * @return {JSX.Element | null}
     */
    const inStorePickup = (): JSX.Element | null => {
        const isPackageItem = isProductFromPackage(cart?.bopisPackages, productData)

        if (isPackageItem) {
            return null
        }

        const { dcQuantity, storeQuantity: inStore } = fulfillment?.stockItemAvailability || {}
        const { sourceTypes = [] } = fulfillment || {}
        const storePickupDC =
            sourceTypes.find(source => source.productSourceType === ProductSourceTypes.DC) ||
            ({} as CartItemFulfillmentSource)
        const storePickupInStore =
            sourceTypes.find(source => source.productSourceType === ProductSourceTypes.INSTORE) ||
            ({} as CartItemFulfillmentSource)

        if (singleShippingMethodOption) {
            return renderAllAvailabilityInfo()
        } else if (checkOnlyDCStock(dcQuantity, inStore, storePickupDC, storePickupInStore)) {
            return (
                <li>
                    {replaceMultipleStrWithDynamicVal(availableBetweenLabel, [
                        getETAForRange(storePickupDC.etaEarliest),
                        getETAForRange(storePickupDC.etaLatest),
                    ])}
                </li>
            )
        } else if (limitedStockAndAvailableInDc(dcQuantity, inStore, storePickupDC, storePickupInStore)) {
            return (
                <>
                    <li>{`${storePickupInStore.quantity} ${availableLabel} ${getETATime(
                        storePickupInStore.etaEarliest,
                    )}`}</li>
                    <li>
                        {replaceMultipleStrWithDynamicVal(availableByLabel, [
                            storePickupDC.quantity?.toString(),
                            getETAForRange(storePickupDC.etaEarliest),
                            getETAForRange(storePickupDC.etaLatest),
                        ])}
                    </li>
                </>
            )
        } else if (checkOnlyStore(inStore, storePickupInStore)) {
            return <li> {`${availableLabel} ${getETATime(storePickupInStore.etaEarliest)}`}</li>
        }
    }

    // Method used to from the lable for shpping or Pickup

    const availableShipLabel = (): JSX.Element => {
        const isHeliumInflationOptionSelected = productData?.selectedStoreServices?.some(
            service => service.value === inflationData.heliumAddOn,
        )
        return (
            <>
                {renderHeliumInflationUnInflationText(isHeliumInflatedUnInflatedShown, heliumInflationUnInflationText)}
                {!hideQuantitySelector && !singleShippingMethodOption && renderUpdatedText()}
                {onlineOrdering ? isShipHome ? shipToHomeLabel() : inStorePickup() : <li>{inStorePurchaseOnlyMsg}</li>}
                {renderHeliumAppointmentInfoMessage(
                    infoClass,
                    isHeliumInflationOptionSelected,
                    heliumAppointmentInfoMessage,
                    isHeliumInflatedUnInflatedShown,
                )}
            </>
        )
    }

    /**
     * @method checkErrorMessage  : Used to check all the error scenarios
     * @returns {{errorMsg: string; quantityExceeded: boolean}} returns information object
     *
     */

    const checkErrorMessage = (): { errorMsg: string; quantityExceeded: boolean; exceededValue: string } => {
        const isLimitQtyToast = itemAvailabilityInfo?.hardStopError ? false : true
        if (isServices) {
            return informationErrorMessage('', false, '')
        }
        // checking if maxQuantity exist
        else if (checkMaxQuantityError()) {
            props.setErrorInfoHandler?.(true, false, false, isLimitQtyToast)
            return informationErrorMessage(
                replaceStrWithDynamicVal(exceededMsg, storeMaxQuantity),
                true,
                `${storeMaxQuantityInfo(maxQuantity, storeMaxQuantity)} `,
            )
            // checking if quantity selected is greater than availaible stocks  when availaible stocks is less than Global Banner Max value
        } else if (validateQuantityRequest()) {
            props.setErrorInfoHandler?.(true, false, false, isLimitQtyToast)
            return informationErrorMessage(
                replaceStrWithDynamicVal(exceededTotalAvailableMsg, checkAvailableQuantity()),
                true,
                `${checkAvailableQuantity()} `,
            )
            // checking if quantity selected is greater than Global Banner Max value  when availaible stocks is greater than Global Banner Max value
        } else if (checkGlobalBannerMax() || globalBannerMaxVal) {
            props.setErrorInfoHandler?.(true, false, false, isLimitQtyToast)
            return informationErrorMessage(
                replaceStrWithDynamicVal(exceededMsg, maxInputVal),
                true,
                maxInputVal.toString(),
            )
        }
        return informationErrorMessage('', false, '')
    }

    const isQuantityExceeded = checkErrorMessage()?.quantityExceeded
    const previousQtySelectorInput = usePrevious(qtySelectorInput)
    const previousFromInput = usePrevious(fromInput)
    const previousInputElementRef = usePrevious(inputElementRef)
    useEffect(() => {
        setQuantityExceedErrForExpressDeliveryProduct(
            isQuantityExceedThanStoreQty,
            qtyExceededErr,
            setQtyExceededErr,
            selectedDeliveryMode,
            isSelectedDeliveryAvailable,
            isAllExpressProducts,
            setIsQtyExceed,
            setInlineToastError,
        )
    }, [
        isAllExpressProducts,
        qtyExceededErr,
        isQuantityExceeded,
        isSelectedDeliveryAvailable,
        selectedDeliveryMode,
        setQtyExceededErr,
        setIsQtyExceed,
        fulfillment,
        isQuantityExceedThanStoreQty,
    ])

    useEffect(() => {
        if (
            (previousQtySelectorInput !== qtySelectorInput ||
                previousFromInput !== fromInput ||
                previousInputElementRef !== inputElementRef) &&
            !!qtySelectorInput &&
            !isQuantityExceeded &&
            prevQuantity !== qtySelectorInput
        ) {
            const isAdd = Boolean(prevQuantity < qtySelectorInput)
            updateCartData(qtySelectorInput, isAdd, entryNumber, fromInput, inputElementRef)
            counterRef.current = 0
        }
    }, [
        qtySelectorInput,
        fromInput,
        inputElementRef,
        isQuantityExceeded,
        prevQuantity,
        updateCartData,
        entryNumber,
        previousQtySelectorInput,
        previousFromInput,
        previousInputElementRef,
        isQuantityExceedThanStoreQty,
    ])

    /**
     * @method limitLabelCheck  : Used to check weather to display limit Label or not
     * @returns {{limitLabel: string; limitLabelShow: boolean}} returns object that consist of the limit label along with boolean
     *
     */

    const limitLabelCheck = (isProductService?: boolean): { limitLabel: string; limitLabelShow: boolean } => {
        if (maxQuantity && !isProductService) {
            return {
                limitLabel: replaceStrWithDynamicVal(limitOfLabel, maxQuantity),
                limitLabelShow: true,
            }
        }
        return {
            limitLabel: '',
            limitLabelShow: false,
        }
    }

    useEffect(() => {
        if (counterRef.current === 0) {
            setQtySelectorInput(itemQuantity)
            setPrevQuantity(itemQuantity)
        }
    }, [itemQuantity])

    const globalBannerMaxErrorCheck = (value: boolean) => {
        setGlobalBannerMax(value)
    }

    /**
     * Function for rebate on Cart Item.
     * @return {JSX.Element} Rebate.
     */
    const renderRebateSection = (): JSX.Element | null => {
        if (rebate && Object.keys(rebate).length) {
            const { message, tooltipMessage, greyCopyMsg, pdfLink } = rebate

            return (
                <div className={`${infoClass} __rebate-info ${PREFIX}-col-sm-12`}>
                    <Rebate
                        title={message || ''}
                        tooltipMessage={tooltipMessage}
                        greyCopyMsg={greyCopyMsg}
                        a11yTooltipIcon={a11yTooltipIcon}
                        rebateIcon={rebateIcon}
                        pdfLink={pdfLink}
                    />
                </div>
            )
        }
    }

    /**
     * Function for RemoveButton from Cart Item.
     * @param {boolean} removeService - flag used to identify whether the service is removed or t he entire product item is removed.
     * @param {boolean} isProductService - true when we want the ui to a product service.
     * @param {ServiceDTO} selectedServiceData - selected service Data.
     * @return {JSX.Element} RemoveButton.
     */
    const renderRemoveButton = (
        removeService: boolean,
        isProductService: boolean,
        selectedServiceData: CartOrderEntries,
    ): JSX.Element => {
        return (
            <button
                className={`${cartClass}__icon--close`}
                data-testid="cartCloseIcon"
                aria-label={a11yRemoveLabel}
                aria-describedby={wishlistPrefixId(productData?.code)}
                onClick={e =>
                    cartCloseHandler(
                        e,
                        removeService,
                        isProductService,
                        selectedServiceData,
                        extractSelectedServiceList,
                    )
                }>
                <Icon type="ct-close" size="lg" />

                {saveForLaterFlagEnabled ? (
                    <span
                        className={`${infoClass}__title-link ${infoClass}__title-link--space ${infoClass}__title-link--align`}>
                        {a11yRemoveLabel}
                    </span>
                ) : null}
            </button>
        )
    }

    const onClickForSfl = (isProductService: boolean, redirectToClp?: boolean) => {
        if (redirectToClp) {
            ssoHandler(true)
        }
        addItemToSaveForLater(productData, extractSelectedServiceList, true, isProductService)
    }

    const renderSaveForLaterButtonForURL = (isProductService: boolean): JSX.Element => {
        return (
            <SanitizeStringContentWrapper
                stringContent={urlRedirection(
                    urlRedirection(loginPageLink, 'returnURL', cartPageLink),
                    saveForLaterParam,
                    'true',
                )}>
                {memoizedStringContent => (
                    <a
                        className={`${cartClass}__icon--sfl`}
                        data-testid="saveForLaterIcon"
                        href={memoizedStringContent}
                        onClick={() => onClickForSfl(isProductService, false)}
                        data-link-value={saveForLaterTitle}
                        aria-label={saveForLaterTitle}>
                        <Icon type="ct-save-for-later" size="lg" />
                        {renderSaveForLaterTitle()}
                    </a>
                )}
            </SanitizeStringContentWrapper>
        )
    }

    /**
     * Function to render the Save For Later button title.
     * @return {JSX.Element} Save For Later button title.
     */
    const renderSaveForLaterTitle = (): JSX.Element => {
        return (
            <span
                className={`${infoClass}__title-link ${infoClass}__title-link--space ${infoClass}__title-link--align`}>
                {saveForLaterTitle}
            </span>
        )
    }

    const renderSflButton = (isProductService: boolean, useSingleSignOn: boolean): JSX.Element => {
        return (
            <button
                className={`${cartClass}__icon--sfl`}
                data-testid="saveForLaterIcon"
                aria-label={saveForLaterTitle}
                onClick={() => onClickForSfl(isProductService, useSingleSignOn)}>
                <Icon type="ct-save-for-later" size="lg" />
                {renderSaveForLaterTitle()}
            </button>
        )
    }

    /**
     * Function for SaveLater Item.
     * @param {boolean} isProductService - true when we want the ui to a product service.
     * @return {JSX.Element} Save For Later Button.
     */
    const renderSaveForLaterButton = (isProductService: boolean): JSX.Element => {
        return (
            <>
                {isAuthUser && renderSflButton(isProductService, false)}
                {!isAuthUser && enableSingleSignOn && renderSflButton(isProductService, true)}
                {!isAuthUser && !enableSingleSignOn && renderSaveForLaterButtonForURL(isProductService)}
            </>
        )
    }

    /**
     * Function to render product you may also need link.
     * @param {boolean} isProductService - true when we want the ui to a product service.
     * @param {boolean} isService
     * @returns {JSX.Element} Product you may also need Button.
     */
    const renderProductYouMayAlsoNeed = (isProductService: boolean, isService: boolean): JSX.Element => {
        return (
            <>
                {showProductYouMayAlsoLikeBtn(
                    productData.entryType,
                    isProductService,
                    isService,
                    enableCartAccessoriesFlyout,
                ) && (
                    <button
                        className={`${cartClass}__icon--product-you-may-need`}
                        onClick={(
                            e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
                        ) => {
                            addModalAttribute(e)
                            productYouMayNeedBtnClick(productData?.code, productData?.fulfillment?.deliveryMode)
                        }}>
                        <Icon type="ct-product-you-may-like" size="lg" />
                        <span
                            className={`${infoClass}__title-link ${infoClass}__title-link--space ${infoClass}__title-link--align`}>
                            {prodYouMayAlsoNeedLabel}
                        </span>
                    </button>
                )}
            </>
        )
    }
    const renderButtons = (
        isService: boolean,
        removeService: boolean,
        isProductService: boolean,
        selectedServiceData: CartOrderEntries,
        isWishlistSection = true,
    ): JSX.Element => {
        return isWishlistSection ? (
            <div className={`${cartClass}__icon`}>
                {showWishListIcon(itemAvailabilityInfo?.showInlineError, itemAvailabilityInfo?.optionsToBeDisplayed) &&
                    !isWishList &&
                    !isService && (
                        <button
                            className={`${cartClass}__icon--wishlist`}
                            aria-label={a11yWishlistIcon}
                            data-testid="cartWishlistIcon"
                            onClick={() => addOrRemoveWishlist(addOrRemoveWishlistProps, isInWishList, productData)}>
                            {renderWishListIconComponent(isInWishList)}
                        </button>
                    )}
                {!saveForLaterFlagEnabled && renderRemoveButton(removeService, isProductService, selectedServiceData)}
            </div>
        ) : (
            <div
                className={`${cartClass}__icon ${cartClass}__icon--bottom ${cartClass}__icon--bottom-container ${cartClass}__icon--sfl-${
                    saveForLaterFlagEnabled ? `enabled` : `disabled`
                } ${
                    showProductYouMayAlsoLikeBtn(
                        productData.entryType,
                        isProductService,
                        isService,
                        enableCartAccessoriesFlyout,
                    )
                        ? `${cartClass}__icon--cart-accessories-flyout`
                        : ``
                }`}
                data-testid="productCartItemBottomIcon">
                {renderProductYouMayAlsoNeed(isProductService, isService)}
                {saveForLaterFlagEnabled ? (
                    <>
                        {renderSaveForLaterButton(isProductService)}
                        {renderRemoveButton(removeService, isProductService, selectedServiceData)}
                    </>
                ) : null}
            </div>
        )
    }

    /**
     * Determine if available ship label and button to swith between STH and pick up should be displayed
     * @method displyFullfilment : Function to determine if ship label and button should be displayed
     * @param {boolean} isProductService - true - don't show when the item is product service.
     * @return {boolean}
     */

    const displyFullfilment = (isProductService?: boolean): boolean => {
        return (isFreePickUpAtStore || isShipToHome) && !isProductService
    }

    const getChangeFulfillmentLabel = (): string | undefined => {
        const isBOPISPackageItem = isProductFromPackage(cart?.bopisPackages, productData)
        const isSTHPackageItem = isProductFromPackage(cart?.sthPackages, productData)

        if (isShipHome) return fulfillment?.isEligibleToPickupFromStore && !isSTHPackageItem && pickAtStoreCTALabel
        return fulfillment?.isEligibleToShipHome && !isBOPISPackageItem && changeShipToHomeCTALabel
    }

    /**
     * function to check item availability
     * @return {boolean}
     */
    const checkIfAvailable = (): boolean => {
        return !(
            itemAvailabilityInfo?.showInlineError &&
            !itemAvailabilityInfo.optionsToBeDisplayed.includes(OptionsOnCartItem.AVAILABILITY_INFO)
        )
    }

    /**
     * This function return service disclaimer and rebate
     * @param {boolean} isService - is the item a service.
     * @return {JSX.Element}
     */
    const renderDisclaimerAndRebate = (isService: boolean): JSX.Element => {
        return (
            <>
                {renderRebateSection()}
                {/* TO-DO: modifying rebate UI as per working implementation */}
                {renderDisclaimer(isService)}
            </>
        )
    }

    /**
     * This function return disclaimer
     * @param {boolean} isService
     * @return {JSX.Element}
     */
    const renderDisclaimer = (isService: boolean): JSX.Element => {
        return (
            <>
                {isService ? (
                    <p className={`${infoClass}__service-disclaimer`}>{servicesDisclaimer}</p>
                ) : (
                    isShipHome &&
                    isBulk && (
                        <p className={`${infoClass}__service-disclaimer`}>
                            <Icon type="ct-information-grey" size="sm" />
                            {productDisclaimer}
                        </p>
                    )
                )}
            </>
        )
    }

    /**
     * function to render Change Fulfillment option button
     * @return {JSX.Element}
     */
    const renderChangeFulfillmentButton = (): JSX.Element => {
        const showChangeFulfillmentOption = getChangeFulfillmentLabel()
        return (
            <Button
                id="show-fulfillment"
                type="tertiary"
                size="mini"
                onClick={() =>
                    onChangeFulfillmentOption(
                        checkDeliveryMethodType(isShipToHome, isExpressDeliverySelected, deliveryMode),
                        productData?.entryNumber,
                    )
                }>
                {showChangeFulfillmentOption}
            </Button>
        )
    }

    /**
     * Render an urgency limited stock if it is necessary
     * @param {string} isTablet
     * @return {JSX.Element}
     */
    const renderUrgencyLimitedStock = (isTablet?: string): JSX.Element | null => {
        const productQty = productData?.fulfillment?.stockItemAvailability?.storeQuantity
        return (
            <>
                {props.limitedStockMessage && productData?.isUrgentLowStock && !!productQty && (
                    <div className={`${infoClass}__limitedStock ${infoClass}__limitedStock${isTablet}`}>
                        {replaceStrWithDynamicVal(props.limitedStockMessage, productQty)}
                    </div>
                )}
            </>
        )
    }

    const isChangeFulfillmentAllowed = (): boolean => {
        return !singleShippingMethodOption && getChangeFulfillmentLabel() && onlineOrdering
    }

    /**
     * function to render availability info of the product
     * @return {JSX.Element}
     */
    const renderAvailability = (): JSX.Element => {
        return (
            <>
                <div className={`${infoClass} ${PREFIX}-md-none ${PREFIX}-xs-none ${PREFIX}-sm-block`}>
                    <div>{renderUrgencyLimitedStock('--tabletStock')}</div>
                    {!isDetailPage && <ul className={`${infoClass}__availability`}>{availableShipLabel()}</ul>}
                    {renderDisclaimerAndRebate(isServices)}
                    {!singleShippingMethodOption && isChangeFulfillmentAllowed() && renderChangeFulfillmentButton()}
                </div>
            </>
        )
    }

    /**
     * This function return product product availability Info
     * @param {boolean} isService - is the item a service.
     * @param {boolean} isProductService - if the item is product service card.
     *
     * @return {JSX.Element}
     */

    const isProductAvailabilityInfo = (isService: boolean, isProductService?: boolean): JSX.Element => {
        const showChangeFulfillmentOption = getChangeFulfillmentLabel()

        return (
            checkIfAvailable() && (
                <>
                    <div className={`${infoClass} ${PREFIX}-md-none ${PREFIX}-xs-block ${PREFIX}-sm-none`}>
                        <div>{renderUrgencyLimitedStock()}</div>
                        {displyFullfilment(isProductService) && (
                            <>
                                {!isDetailPage && (
                                    <ul className={`${infoClass}__availability`}>{availableShipLabel()}</ul>
                                )}
                                {renderDisclaimerAndRebate(isService)}
                                {!hideQuantitySelector &&
                                    !singleShippingMethodOption &&
                                    showChangeFulfillmentOption &&
                                    renderChangeFulfillmentButton()}
                            </>
                        )}
                    </div>
                    {!displyFullfilment(isProductService) && renderDisclaimer(isService)}
                </>
            )
        )
    }

    useEffect(() => {
        if (isQuantityExceeded && checkoutButtonClick) {
            setInlineToastError(true)
            warningToastShow(true)
        } else if (!!itemAvailabilityInfo?.errorMessage && checkoutButtonClick) {
            setInlineToastError(true)
        } else if (!isSelectedDeliveryAvailable && !!itemAvailabilityInfo?.errorMessage) {
            setInlineToastError(true)
            props.setErrorInfoHandler?.(true, false)
        } else if (!isQuantityExceeded && !itemAvailabilityInfo?.errorMessage) {
            setInlineToastError(false)
        }
    }, [
        isQuantityExceeded,
        checkoutButtonClick,
        itemAvailabilityInfo,
        isSelectedDeliveryAvailable,
        warningToastShow,
        props.setErrorInfoHandler,
        props,
    ])

    /**
     * @method checkQunatityReduced  : Method used to check when the quantity decreased wrt to base quantity
     * @param {boolean} removedAnItem - set to true when the entire item to be removed.
     * @returns {number}
     *
     */

    const checkQunatityReduced = useCallback(
        (removedAnItem?: boolean): number => {
            if (removedAnItem) return qtySelectorInput
            return qtySelectorInput < itemQuantity ? itemQuantity - qtySelectorInput : qtySelectorInput - itemQuantity
        },
        [itemQuantity, qtySelectorInput],
    )

    /**
     * display badge as per priority
     * @param {boolean} isProductService - don't show when the service is a added service.
     * @return {JSX.Element}
     */
    const getBadgeComponent = (isProductService?: boolean): JSX.Element => {
        return (
            productData.badges &&
            productData.badges.length > 0 &&
            !isProductService && (
                <div className={`${cartClass}__cart-badges`}>
                    <Badges
                        badges={productData.badges}
                        badgesAndPriorities={badgePriorities}
                        hideDisclaimer={true}
                        saleEndDaySoonMessage={props.saleEndDaySoonMessage}
                        salePriceEndDate={productData?.salePriceEndDate}
                        isShoppingCart={props.isShoppingCartPage}
                        saleEndDisableShift={saleEndDisableShift}
                    />
                </div>
            )
        )
    }

    /**
     * Function to check to remove product with service add on or only product
     * @param {CartOrderEntries[]} serviceAddonList - true if the product contains associated service addons
     * @param {boolean} isServiceRemoved  -true if only service add on is removed
     * @return {boolean}
     */
    const isProductRemoved = (serviceAddonList?: CartOrderEntries[], isServiceRemoved?: boolean): boolean => {
        return isServiceRemoved ? isServiceRemoved : isArrayNotEmpty(serviceAddonList)
    }

    /**
     * Method fired when close icon on the cartCard is clicked
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent} event - event.
     * @param {boolean} isServiceRemoved - true if only standalone service is removed.
     * @param {boolean} isProductService - true if the service addon clicked in an associated product.
     * @param {CartOrderEntries} removedServiceData - service data which is about to be removed.
     * @param {CartOrderEntries[]} addedServiceAddons - to check if the parent product has service add ons to it.
     *
     */
    const cartCloseHandler = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent,
        isServiceRemoved?: boolean,
        isProductService?: boolean,
        removedServiceData?: CartOrderEntries,
        addedServiceAddons?: CartOrderEntries[],
    ): void => {
        if (isWishList) {
            onRemoveWishlistItem(productData)
        } else if (!isProductService) {
            const removedItemData = productData
            cartItemAnalytics(removedItemData, checkQunatityReduced(true))
            onRemoveCartItem(
                isProductRemoved(addedServiceAddons, isProductService),
                productData,
                extractSelectedServiceList,
                qtySelectorInput,
            )
        } else {
            addModalAttribute(event)
            onRemoveCartItem(!isServiceRemoved, removedServiceData)
        }
    }

    /**
     * Method is fired when qtySelectorInput is updated and cart analytics needs to be pushed
     */
    const callAnalytics = useCallback((): void => {
        const maxQtyReached = maxQuantity && qtySelectorInput > maxQuantity
        if (qtySelectorInput > itemQuantity) {
            cartItemAnalytics(props.productData, checkQunatityReduced(), 'addToCart', maxQtyReached)
        } else if (qtySelectorInput < itemQuantity) {
            cartItemAnalytics(props.productData, checkQunatityReduced())
        }
    }, [cartItemAnalytics, checkQunatityReduced, itemQuantity, maxQuantity, props.productData, qtySelectorInput])

    useEffect(() => {
        if (qtySelectorInput !== previousQtySelectorInput) {
            customDebounce(callAnalytics, cartDebounceTimeOut, debounceTimeOut, setDebounceTimeOut)()
        }
    }, [callAnalytics, cartDebounceTimeOut, debounceTimeOut, qtySelectorInput, previousQtySelectorInput])

    /**
     * Service Installation Component which gets renders if the there is any service list on a single item.
     * @return {JSX.Element | null}
     */
    const serviceInstallationComponent = (): JSX.Element | null => {
        if (checkProductDataServicesListAvailability(productData, extractSelectedServiceList)) {
            return (
                <div className={`${cartClass}__service-installation`}>
                    <CartServicesComponent
                        cartServices={productData.servicesList}
                        serviceAddOnLabel={props.addonTitle}
                        addServiceToCartLabel={props.addServicesCTALabel}
                        serviceAddOnIcon="ct-tools"
                        serviceAddOnNextSteps={servicesDisclaimer}
                        serviceAddToCartHandler={serviceAddToCartHandler}
                        serviceDescription={props.serviceAvailableMsg}
                        parentProduct={productData}
                        onFlyout={false}
                    />
                </div>
            )
        }
        return null
    }

    /**
     * Function to toggle between the product Service card and the cart service installation ui.
     * @param {CartOrderEntries} selectedService - single added service.
     * @return {JSX.Element} product service card
     */
    const productServiceComponent = (selectedService?: CartOrderEntries): JSX.Element => {
        return cartItemComponent({
            isService: true,
            isProductService: true,
            productServiceTitle: props.addonTitle,
            selectedServiceData: selectedService,
            disableQuantitySelector: true,
            removeService: true,
            showBorder: false,
        })
    }

    /**
     * UI for quantity selector component.
     * @param {boolean} isProductService - if true, show service data instead of product data.
     * @param {CartOrderEntries} selectedServiceData - service data.
     * @param {boolean} disableQuantitySelector - if true, can't edit the quantity selector.
     *
     * @return {JSX.Element | null}
     */
    const quantitySelectorComponent = (
        isProductService?: boolean,
        selectedServiceData?: CartOrderEntries,
        disableQuantitySelector?: boolean,
    ): JSX.Element | null => {
        return !isWishList && onlineOrdering && !hideQuantitySelector ? (
            <div className={`${cartClass}__quantity ${PREFIX}-col-md-4 ${PREFIX}-col-sm-3`}>
                <QuantitySelector
                    setQtySelectorInput={priceIncrementHandler}
                    qtySelectorInput={isProductService ? selectedServiceData.quantity : qtySelectorInput}
                    defaultQty={isProductService ? selectedServiceData.quantity : itemQuantity}
                    maxQty={isProductService ? selectedServiceData.maxQuantity : quantitySelectorMaxInput}
                    maximumQuantityError={checkErrorMessage().errorMsg}
                    quantityExceeded={checkErrorMessage().quantityExceeded}
                    limitLabel={limitLabelCheck(isProductService).limitLabel}
                    limitLabelShow={limitLabelCheck(isProductService).limitLabelShow}
                    checkBannerMaxError={globalBannerMaxErrorCheck}
                    disabled={disableQuantitySelector}
                    a11yQuantityLabel={accessibilityGlobalProps.a11yQuantityLabel}
                    a11yQuantityDecrementLabel={accessibilityGlobalProps.a11yQuantityDecrementLabel}
                    a11yQuantityIncrementLabel={accessibilityGlobalProps.a11yQuantityIncrementLabel}
                    a11yQuantityInputAriaLabel={accessibilityGlobalProps.a11yQuantityInputAriaLabel}
                    a11yMinQuantityReached={accessibilityGlobalProps.a11yMinQuantityReached}
                    id={isProductService ? `quantity - selector - ${selectedServiceData.code} ` : quantitySelectorId}
                    a11yUpdatedQuantityText={accessibilityGlobalProps.a11yUpdatedQuantityText}
                />
            </div>
        ) : null
    }

    const saveMessage = useGenerateSaveMessage(saleCut, originalPrice, saleMessagesRules, currentPrice)

    /**
     * Show wishlist icon when error is present.
     * @param {boolean} isService - if it is a service add on
     * @param {CartOrderEntries} serviceData - selected service data
     * @return {JSX.Element} - return price component
     * TO-DO: to be refactored
     */
    const renderProductServicePrice = (isService: boolean, serviceData: CartOrderEntries): JSX.Element => {
        const priceValue = getPriceValue(
            serviceData,
            currentPrice,
            totalPrice,
            oneProductQuantity,
            itemQuantity,
            props.isWheelOrTire,
        )
        const checkDisplayWasLabel = showHideWasLabel(isPostPOSOrderDetails, productData.displayWasLabel)
        const hideSaveLabel = checkDisplayWasLabel && !displayRomanceCopyWithWasLabel
        const feeMessage = isArrayNotEmpty(feeMessages) ? feeMessages?.[0] : {}
        const feeMessageValue =
            isWishList && props.isWheelOrTire ? feeMessage?.value * magicNumber.FOUR : feeMessage?.value

        return isService ? (
            <Price
                scrollToFooter={scrollToFooter}
                priceValue={priceValue as unknown as PriceType}
                currentPrice={serviceData.currentPrice as unknown as PriceType}
                language={language}
                eachLabel={eachLabel}
                isMoreQuantityCartPage={serviceData.quantity > oneProductQuantity}
                a11yStrikeOutPrice={a11yStrikeOutPrice}
                a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                nowFromLabel={props.nowFromLabel}
                saveFromLabel={props.saveFromLabel}
                wasFromLabel={props.wasFromLabel}
                fromLabel={props.fromLabel}
                feeDisclaimerType={feeDisclaimerType}
                saveMessage={saveMessage}
                isAppliedPromoExist={!!props.productData?.appliedPromoMessages?.length}
                a11yClickToReadFootnote={a11yClickToReadFootnote}
                a11yCloseIconLabel={a11yCloseIconLabel}
                a11yTooltipIcon={a11yTooltipIcon}
            />
        ) : (
            <Price
                scrollToFooter={scrollToFooter}
                priceValue={priceValue as unknown as PriceType}
                originalPrice={!isPostPOSOrderDetails && originalPrice}
                currentPrice={currentPrice}
                displayWasLabel={checkDisplayWasLabel}
                hideSaveLabel={hideSaveLabel}
                discountValue={discount}
                language={language}
                eachLabel={eachLabel}
                isMoreQuantityCartPage={props.isWheelOrTire || itemQuantity > oneProductQuantity}
                promotionalPriceLabel={saveLabel}
                clearancePriceLabel={props.clearancePriceLabel}
                unitPriceLabel={props.unitPriceLabel}
                thresholdValue={thresholdValue}
                a11yStrikeOutPrice={a11yStrikeOutPrice}
                a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                feeTitle={getFeeTitle(feeMessageValue, feeMessage, language)}
                feeDisclaimerTitle={feeMessage?.feeDisclaimerTitle}
                feeDisclaimerMessage={feeMessage?.feeDisclaimerMessage}
                nowFromLabel={props.nowFromLabel}
                saveFromLabel={props.saveFromLabel}
                wasFromLabel={props.wasFromLabel}
                fromLabel={props.fromLabel}
                feeDisclaimerType={feeMessage?.type}
                priceMessage={!isPostPOSOrderDetails && priceMessage}
                setOfFourLabel={props.setOfFourLabel}
                isWishList={props.isWishList}
                isWheelOrTire={props.isWheelOrTire}
                isAutomotiveEachLabel={props.isWheelOrTire}
                isShoppingCartPage={props.isShoppingCartPage}
                showDiscountedPrice={showDiscountedPrice}
                highlightDiscountedPrice={highlightDiscountedPrice}
                totalPrice={totalPrice}
                saveMessage={saveMessage}
                isAppliedPromoExist={!!props.productData?.appliedPromoMessages?.length}
                a11yClickToReadFootnote={a11yClickToReadFootnote}
                a11yCloseIconLabel={a11yCloseIconLabel}
                a11yTooltipIcon={a11yTooltipIcon}
                plusMinusSymbol={plusMinusSymbol}
            />
        )
    }

    /**
     * Created a function to reduce the complexity\
     * eachPriceComponent has been removed. Going forward we will need only one price component.
     * @param {ItemInlineErrorType} itemAvailabilityInfoForPrice - Item availability info.
     * @param {boolean} isProductService - true when we want the ui to a product service.
     * @param {ServiceDTO} selectedServiceData - selected service Data.
     * @param {boolean} disableQuantitySelector - to disable the entire quantity selector section.
     *
     * @return {JSX.Element}
     */
    const overAllPriceComponent = (
        itemAvailabilityInfoForPrice: ItemInlineErrorType,
        isProductService: boolean,
        selectedServiceData: CartOrderEntries,
        disableQuantitySelector: boolean,
    ): JSX.Element => {
        return (
            (!itemAvailabilityInfoForPrice?.showInlineError ||
                itemAvailabilityInfoForPrice.optionsToBeDisplayed.includes(OptionsOnCartItem.PRICE_AND_QUANTITY)) && (
                <div
                    className={`${cartClass}__price-quantity ${
                        validation(isWishList, hideQuantitySelector) ? `${cartClass}__price-quantity--wishlist` : ''
                    } ${PREFIX}-col-md-4 ${PREFIX}-col-sm-12`}>
                    {quantitySelectorComponent(isProductService, selectedServiceData, disableQuantitySelector)}

                    <div
                        className={`${cartClass}__price  ${PREFIX}-col-md-7 ${PREFIX}-col-sm-8 ${
                            isWishList ? `${cartClass}__price--wishlist` : ''
                        } `}>
                        {renderProductServicePrice(isProductService, selectedServiceData)}
                        {isRenderPromoMessages() ? (
                            <div className={promoMessagesStyleClassMobile}>{renderPromoMessages()}</div>
                        ) : null}
                        {showHideDiscountInfo && getBadgeComponent(isProductService)}
                    </div>
                </div>
            )
        )
    }

    const renderPromoMessages = (): JSX.Element => {
        return (
            <PromoMessages
                isProductLevel={true}
                productPromoMessages={productData?.promoMessages}
                productRestrictedPromoMessages={productData?.restrictedPromoMessages}
                appliedPromoPrefix={props.appliedPromoPrefix}
                appliedPromoMessages={productData?.appliedPromoMessages}
                a11yTooltipIcon={props.a11yTooltipIcon}
                a11yCloseIconLabel={a11yCloseIconLabel}
            />
        )
    }

    const isRenderPromoMessages = useCallback((): boolean => {
        const isMessagePresent = isAtleastOneParamValid(
            isArrayNotEmpty(productData?.promoMessages),
            isArrayNotEmpty(productData?.restrictedPromoMessages),
            isArrayNotEmpty(productData?.appliedPromoMessages),
        )
        return isMessagePresent && (!isWishList || props.enableShowPromotionsAndRomanceCopy)
    }, [productData, isWishList, props.enableShowPromotionsAndRomanceCopy])

    const showBuyAgainText = (isService: boolean): string => {
        return isService ? dpLearnMoreLabel : dpBuyAgainLabel
    }

    const showBuyAgainTextAriaLabel = (
        isService: boolean,
        isProductService: boolean,
        selectedServiceData?: CartOrderEntries,
    ): string => {
        const productName = isProductService ? selectedServiceData?.name : name
        return isService ? dpLearnMoreLabel + ': ' + productName : dpBuyAgainLabel + ': ' + productName
    }

    /**
     * Opens modal for cancellation, distinguish between a Service or a single product
     * @param {boolean} isProductService
     * @param {selectedServiceData} selectedServiceData
     */
    const handleCancelProductClick = (isProductService: boolean, selectedServiceData: CartOrderEntries): void => {
        const { entryNumber: cancelEntryNumber, name: cancelName } = isProductService
            ? selectedServiceData
            : productData
        getEntriesInfo(
            true,
            'GM',
            cancelEntryNumber,
            itemQuantity,
            cancelName,
            productData?.balloonEntryType,
            isBouquetPackage,
        )
        cancellationAnalytics('cancel_order', 'partial', 'Order Details')
    }

    const cancellationClass = customerSelfServiceCancelEnabled ? `${cartClass}__cancellation` : ''

    /**
     * Returns availability bullet subtype
     * @return {string}
     */

    const availabilityBulletSubtype = (): string => {
        const { availability } = fulfillment || {}
        const checkInStock = checkIsStockInAllStore(
            availability?.quantity,
            availability?.Corporate?.Quantity,
            availability?.Corporate?.orderableFromDC,
        )
        if (
            checkInStock._isOutOfStock() ||
            itemAvailabilityInfo.optionsToBeDisplayed.includes(OptionsOnCartItem.COMING_SOON)
        ) {
            return AvailabilityBullet.NoStock
        }

        return AvailabilityBullet.InStock
    }

    /**
     * Returns stock availability message
     * @return {string}
     */
    const getMessageForStockInfo = () => {
        return isWishList && isAvailableOnlyInDC
            ? inStockOnlineLabel
            : itemAvailabilityInfo.optionsToBeDisplayed.includes(OptionsOnCartItem.OUT_OF_STOCK)
            ? props.outOfStockLabel
            : itemAvailabilityInfo.optionsToBeDisplayed.includes(OptionsOnCartItem.COMING_SOON)
            ? props.comingSoonStockLabel
            : itemAvailabilityInfo.optionsToBeDisplayed.includes(OptionsOnCartItem.IN_STOCK_FOR_PICK_UP)
            ? props.wishlistInStockPickUpLabel
            : itemAvailabilityInfo.optionsToBeDisplayed.includes(OptionsOnCartItem.IN_STOCK_FOR_PURCHASE)
            ? props.wishlistInStockPurchaseLabel
            : props.discontinuedLabel
    }

    /**
     * Returns stock availability message
     * @return {string}
     */
    const getStockInfoMessage = (): JSX.Element | string => {
        if (itemAvailabilityInfo.optionsToBeDisplayed.includes(OptionsOnCartItem.STOCK_INFO)) {
            return getMessageForStockInfo()
        }
        const storeShelfLocation = fulfillment?.availability?.storeShelfLocation || ''
        const modifiedInStockLabel = fulfillment?.availability?.storeShelfLocation
            ? inStockLabel
            : inStockLabel?.split('-')[magicNumber.ZERO]
        const preferredInStockLabel =
            wishlistInStockLabel ||
            replaceMultipleStrWithDynamicVal(modifiedInStockLabel, [
                fulfillment?.availability?.quantity?.toString(),
                storeShelfLocation,
            ])
        return <span>{preferredInStockLabel}</span>
    }

    /**
     * function to display  disclaimer text if order details page
     *
     * @return {JSX.Element} returns service disclaimer text
     **/

    const showOrderDetailDisclaimer = (isService: boolean): JSX.Element | null => {
        const cannotTrackCondition = (): boolean => {
            return isShipToHome && props.productData?.status === DeliveryStatusSTH.SHIPPED && !productData.trackingID
        }
        return (
            <>
                {isService ? (
                    <p className={`${infoClass}__service-disclaimer`}>{discServiceInstallation}</p>
                ) : isShipToHome && isBulk && props.productData?.status === DeliveryStatusSTH.IN_PROGRESS_STH ? (
                    <p className={`${infoClass}__service-disclaimer`}>
                        <Icon type="ct-information-grey" size="sm" />
                        {discScheduleDelivery}
                    </p>
                ) : cannotTrackCondition() ? (
                    <p className={`${infoClass}__service-disclaimer`}>
                        <Icon type="ct-information-grey" size="sm" />
                        {discCannotTrack}
                    </p>
                ) : null}
            </>
        )
    }

    /**
     *
     * @param {boolean} isProductService - true when we want the ui to a product service.
     * @param {CartOrderEntries} selectedServiceData - selected service Data.
     *
     * @return {JSX.Element | null} cancel button.
     */

    const renderCancelButton = (isProductService, selectedServiceData: CartOrderEntries): JSX.Element | null => {
        const isCancelButtonDisabled = isProductService ? !selectedServiceData.cancellable : !productData.cancellable

        return isShipToHome && isCancelButtonDisabled ? null : customerSelfServiceCancelEnabled &&
          enablePartialOrderCancellation ? (
            <div className={`${cartClass}__cta-cancel`}>
                <Button
                    id="get-info-button"
                    type="tertiary"
                    size="mini"
                    disabled={isCancelButtonDisabled}
                    onClick={() => {
                        handleCancelProductClick(isProductService, selectedServiceData)
                    }}>
                    {cancelProductLabel}
                </Button>
            </div>
        ) : null
    }

    /**
     * UI for product Cart Item.
     * UI will get toggled based on the props.
     *
     * @param {boolean} isService - This prop is used for standalone service. true means its a standalone service.
     * @param {boolean} isProductService - true when we want the ui to a product service.
     * @param {string} productServiceTitle - Only used when isProductService is true. It is the title of the product service card.
     * @param {ServiceDTO} selectedServiceData - selected service Data.
     * @param {boolean} disableQuantitySelector - to disable the entire quantity selector section.
     * @param {boolean} removeService - flag used to identify whether the service is removed or t he entire product item is removed.
     *
     * @return {JSX.Element} Cart UI.
     */
    // eslint-disable-next-line complexity
    const cartItemComponent = ({
        isService,
        isProductService,
        productServiceTitle,
        selectedServiceData,
        disableQuantitySelector,
        removeService,
        showBorder,
    }: CartItemComponentPropsType): JSX.Element => {
        const showInlineError = !itemAvailabilityInfo?.showInlineError
        const isAddOnNotSellable = isProductService && !selectedServiceData.sellable && !hideQuantitySelector

        let cartItemAvailabilityInfo = itemAvailabilityInfo
        if (isAddOnNotSellable) {
            cartItemAvailabilityInfo = {
                showInlineError: true,
                errorMessage: props.serviceAddOnNotAvailableLabel,
                grayOutImage: true,
                optionsToBeDisplayed: [],
                fulfillmentDeliverError: '',
                hardStopError: true,
            }
        } else if (isProductService && selectedServiceData.sellable) {
            cartItemAvailabilityInfo = {
                showInlineError: false,
                errorMessage: '',
                grayOutImage: false,
                optionsToBeDisplayed: [],
                fulfillmentDeliverError: '',
                hardStopError: false,
            }
        }
        const priorityAndName = inImageBadgePriorities?.priorityAndName
        const priorityAndColourWithIconLabel = priorityAndName?.map(badge => ({
            ...badge,
            badgeLabel: <Icon type={`ct-default-${badge?.iconName.toLowerCase()}`} />,
            colorId: '',
        }))

        return (
            <>
                <div className={`${cancellationClass} ${PREFIX}-row`}>
                    {isAddOnNotSellable && (
                        <CommonWarningToast
                            icon="ct-notification-caution"
                            bgType="orange"
                            label={cartItemAvailabilityInfo.errorMessage}
                            checkNearByText={''}
                            isAddOn
                        />
                    )}
                    <div
                        className={`${cartClass} ${PREFIX}-row ${
                            isProductService ? `${cartClass}__product-service` : ''
                        } ${showBorder && showInlineError ? `${cartClass}--add-padding` : ''} ${
                            isAddOnNotSellable ? `${cartClass}--add-on` : ''
                        } `}>
                        <div className={`${PREFIX}-in-image-badges`}>
                            <Badges
                                badges={productData.badges}
                                badgesAndPriorities={{
                                    ...inImageBadgePriorities,
                                    priorityAndColour: priorityAndColourWithIconLabel,
                                }}
                                hideDisclaimer={true}
                            />
                        </div>
                        {renderCancelButton(isProductService, selectedServiceData)}
                        <ProductImageComponent
                            itemAvailabilityInfo={cartItemAvailabilityInfo}
                            isService={isService}
                            a11yServiceInstallation={a11yServiceInstallation}
                            servicesIcon="ct-tools"
                            images={images}
                            name={name}
                            changeBrowserBackUrl={changeBrowserBackUrl}
                            hideQuantitySelector={hideQuantitySelector}
                            url={url}
                        />
                        <div className={`${cartClass}__text-container ${PREFIX}-col-md-8 ${PREFIX}-col-sm-8`}>
                            <div
                                className={`${cartClass}__product-item-wrapper  ${PREFIX}-col-md-12 ${PREFIX}-col-sm-12`}>
                                <ProductItemInfo
                                    changeBrowserBackUrl={changeBrowserBackUrl}
                                    hideQuantitySelector={hideQuantitySelector}
                                    changeShipToHomeCTALabel={changeShipToHomeCTALabel}
                                    pickAtStoreCTALabel={pickAtStoreCTALabel}
                                    availableLabel={availableLabel}
                                    betweenLabel={betweenLabel}
                                    isFreePickUpAtStore={isFreePickUpAtStore}
                                    isShipToHome={isShipToHome}
                                    servicesDisclaimer={servicesDisclaimer}
                                    isServices={isService}
                                    discScheduleDelivery={discScheduleDelivery}
                                    discServiceInstallation={discServiceInstallation}
                                    discCannotTrack={discCannotTrack}
                                    isDetailPage={isDetailPage}
                                    homePageLink={homePageLink}
                                    productData={productData}
                                    serviceData={selectedServiceData}
                                    onChangeFulfillmentOption={onChangeFulfillmentOption}
                                    receiveBetweenLabel={receiveBetweenLabel}
                                    availableByLabel={availableByLabel}
                                    availableShipLabel={availableShipLabel}
                                    getChangeFulfillmentOption={getChangeFulfillmentLabel}
                                    isProductService={isProductService}
                                    productServiceTitle={productServiceTitle}
                                    itemAvailabilityInfo={cartItemAvailabilityInfo}
                                    bulkLabel={bulkLabel}
                                    updatedLabel={updatedLabel}
                                    accessibilityGlobalProps={accessibilityGlobalProps}
                                    isWishList={isWishList}
                                    PDPLink={PDPLink}
                                    renderRebate={renderRebateSection}
                                    productColorLabel={productColorLabel}
                                    inStockLabel={inStockLabel}
                                    reviewsContainerId={reviewsContainerId}
                                    onlineOrdering={onlineOrdering}
                                    productDisclaimer={productDisclaimer}
                                    qtyAbbrLabel={qtyAbbrLabel}
                                    availabilityBulletSubtype={availabilityBulletSubtype}
                                    getStockInfoMessage={getStockInfoMessage}
                                    urgentLowStock={urgentLowStock}
                                    limitedStockMessage={props.limitedStockMessage}
                                    showOrderDetailDisclaimer={service => showOrderDetailDisclaimer(service)}
                                    a11yCloseIconLabel={a11yCloseIconLabel}
                                    a11yTooltipIcon={a11yTooltipIcon}
                                    saleMessagesRules={saleMessagesRules}
                                    heliumInflationUnInflationText={heliumInflationUnInflationText}
                                    showInflationLabels={showInflationLabels}
                                    {...props}
                                />
                                {overAllPriceComponent(
                                    cartItemAvailabilityInfo,
                                    isProductService,
                                    selectedServiceData,
                                    disableQuantitySelector,
                                )}
                            </div>
                            <div className={`${cartClass}__availability-container ${PREFIX}-xs-block ${PREFIX}-row`}>
                                <div
                                    className={`${infoClass} ${PREFIX}-xs-block  ${PREFIX}-sm-none  ${PREFIX}-md-none`}>
                                    {isWishList &&
                                    itemAvailabilityInfo.optionsToBeDisplayed.includes(
                                        OptionsOnCartItem.IN_STOCK_TAG,
                                    ) ? (
                                        <Tag
                                            type="availability-bullet"
                                            subType={`${availabilityBulletSubtype()} `}
                                            isLarge>
                                            {getStockInfoMessage()}
                                        </Tag>
                                    ) : null}
                                </div>
                                {isWishList ? (
                                    <>
                                        <div
                                            className={`${infoClass} ${PREFIX}-xs-block  ${PREFIX}-sm-none  ${PREFIX}-md-none`}>
                                            {renderRebateSection()}
                                        </div>
                                    </>
                                ) : (
                                    // render availaibility for mobile view
                                    ((!isAddOnNotSellable && productData.entryType !== eEntryType.SERVICE_STANDALONE) ||
                                        hideQuantitySelector) &&
                                    isProductAvailabilityInfo(isService, isProductService)
                                )}
                                {/* render availaibility tablet view */}
                                {!isProductService &&
                                    productData.entryType !== eEntryType.SERVICE_STANDALONE &&
                                    checkIfAvailable() &&
                                    renderAvailability()}
                                {props.isShoppingCartPage &&
                                    !isProductService &&
                                    productData.entryType === eEntryType.SERVICE_STANDALONE &&
                                    renderDisclaimer(isServices)}
                            </div>
                        </div>
                        {isDetailPage && (
                            <div className={`${cartClass}__buy-again-button ${PREFIX}-row`}>
                                <Button
                                    id="learn-more"
                                    type="secondary"
                                    size="mini"
                                    ariaLabel={showBuyAgainTextAriaLabel(
                                        isProductService,
                                        isService,
                                        selectedServiceData,
                                    )}
                                    onClick={() => {
                                        window.location.href = encodeURI(url)
                                    }}>
                                    {showBuyAgainText(isService)}
                                </Button>
                            </div>
                        )}
                        {!hideQuantitySelector &&
                            renderButtons(isService, removeService, isProductService, selectedServiceData)}
                        {isWishList &&
                            onlineOrdering &&
                            itemAvailabilityInfo.optionsToBeDisplayed.includes(OptionsOnCartItem.ADD_TO_CART) &&
                            checkForQuantityAvailability(
                                productData,
                                props.enableAddTireWheelsByFourFromWishlist,
                                props.isWheelOrTire,
                            ) && (
                                <div className={`${cartClass}__add-to-cart-button ${PREFIX}-row`}>
                                    <Button
                                        test-id="checkButton"
                                        id="checkButton"
                                        type="primary"
                                        size="mini"
                                        onClick={() => addToCart(productData)}>
                                        {addToCartLabel}
                                    </Button>
                                </div>
                            )}
                        {renderButtons(isService, removeService, isProductService, selectedServiceData, false)}
                    </div>
                </div>
            </>
        )
    }

    /**
     * Determine if warning toast should be displayed
     * @method showWarningToast : Function to determine if warning toast should be displayed
     * @return {boolean}
     */

    const showWarningToast = (): boolean => {
        return (
            (inlineToastError || !!itemAvailabilityInfo?.showInlineError) &&
            !hideQuantitySelector &&
            !disableAvailabilityMessages
        )
    }

    const isProductDataExist = () => productData && Object.keys(productData).length

    const warningToastProps = {
        changeShipToHomeCTALabel,
        pickAtStoreCTALabel,
        exceededMsgBar,
        nearbyAvailabilityCTALabel: props.nearbyAvailabilityCTALabel,
        onChangeFulfillmentOption,
        checkNearByCTAHandler: props.checkNearByCTAHandler,
        checkErrorMessage,
        isSelectedDeliveryAvailable,
        selectedDeliveryMode,
        itemNotEligibleForExpressDeliveryMsg,
        itemNotEligibleForStandardDeliveryMsg,
        expressDeliveryDoesNotSupportHeavyOversizedMsg,
        insufficientQuantityOfProductForExpressDeliveryMsg,
        isQtyExceeded: qtyExceededErr,
    }

    const addOrRemoveWishlistProps = {
        addItemToWishlist,
        removeitemFromWishlist,
    }

    /**
     * Render recommnedation addons on cart for ballon product
     * @return {JSX.Element}
     */

    const renderBalloonAddons = (): JSX.Element => {
        return (
            isBalloonProduct(props.productData) && (
                <div className={`${PREFIX}-automotive`}>
                    <div className={`${PREFIX}-automotive-card`}>
                        <div className={`${PREFIX}-automotive-card-wrapper`}>
                            <AutomotiveAddonsListWrapper {...props} isCollapsed={true} />
                        </div>
                    </div>
                </div>
            )
        )
    }

    /**
     * Render product cart item info component
     * @return {JSX.Element}
     */

    const renderProductCartItemComp = (): JSX.Element => {
        return (
            isProductDataExist && (
                <>
                    {cartItemComponent({
                        isService: isServices,
                        isProductService: false,
                        removeService: !isArrayNotEmpty(extractSelectedServiceList),
                        showBorder:
                            isArrayNotEmpty(productData.servicesList) || isArrayNotEmpty(extractSelectedServiceList),
                    })}
                    {/* Below is for rendering UI of already selected Service. */}
                    {isArrayNotEmpty(extractSelectedServiceList) && (
                        <div className={`${cartClass}__existing-services`}>
                            {extractSelectedServiceList.map(singleService => productServiceComponent(singleService))}
                        </div>
                    )}
                    {/* Below is for rendering UI for choosing a service. */}
                    {productData.entryType !== eEntryType.SERVICE_STANDALONE &&
                        isArrayNotEmpty(productData.servicesList) &&
                        serviceInstallationComponent()}
                </>
            )
        )
    }

    return (
        <>
            <CommonCard showWarningToast={inlineToastError && itemAvailabilityInfo?.hardStopError}>
                <div className={`${balloonProductClass} ${cardClass}__content`}>
                    {showWarningToast() && renderWarningToast(warningToastProps, itemAvailabilityInfo, productData)}
                    {renderProductCartItemComp()}
                </div>
                {enableBalloonAddons && renderBalloonAddons()}
            </CommonCard>
        </>
    )
}

ProductCartItem.displayName = 'ProductCartItem'

export default ProductCartItem
