import { Store, StoreWithAvailability } from '../components/HeaderStoreLocator/HeaderStoreLocator.type'
import { AUTO_SERVICE_INSTALLATION_ID } from '../globalConstants/global.constant'

/**
 * Check if store has auto service.
 *
 * @param {Store} store - store to check
 * @return {boolean}
 */
export const isAutoServiceAvailableInStore = (store: Store | StoreWithAvailability): boolean => {
    return !!store?.storeServices?.find(service => service.name === AUTO_SERVICE_INSTALLATION_ID)
}
