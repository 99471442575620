export const FETCH_CUSTOMER_OFFERS_IN_PROGRESS = 'FETCH_CUSTOMER_OFFERS_IN_PROGRESS'
export const FETCH_CUSTOMER_OFFERS_SUCCESS = 'FETCH_CUSTOMER_OFFERS_SUCCESS'
export const FETCH_CUSTOMER_OFFERS_FAILURE = 'FETCH_CUSTOMER_OFFERS_FAILURE'
export const ACTIVATE_CUSTOMER_OFFERS_SUCCESS = 'ACTIVATE_CUSTOMER_OFFERS_SUCCESS'
export const ACTIVATE_CUSTOMER_OFFERS_FAILURE = 'ACTIVATE_CUSTOMER_OFFERS_FAILURE'
export const CLEAR_ACTIVATION_SUCCESS = 'CLEAR_ACTIVATION_SUCCESS'
export const CLEAR_ACTIVATION_ERROR = 'CLEAR_ACTIVATION_ERROR'
export const ACTIVATE_TARGETED_OFFER_SUCCESS = 'ACTIVATE_TARGETED_OFFER_SUCCESS'
export const FETCH_FILTERED_OFFERS_SUCCESS = 'FETCH_FILTERED_OFFERS_SUCCESS'
export const FETCH_FILTERED_OFFERS_FAILURE = 'FETCH_FILTERED_OFFERS_FAILURE'
export const UPDATE_FILTERED_OFFERS_SORTING = 'UPDATE_FILTERED_OFFERS_SORTING'
export const UPDATE_FILTERED_OFFERS = 'UPDATE_FILTERED_OFFERS'
export const SET_LOYALTY_OFFERS_FETCH_SUCCESS = 'SET_LOYALTY_OFFERS_FETCH_SUCCESS'
export const SET_PTE_PROPS = 'SET_PTE_PROPS'
export const FETCH_COMMUNITY_OFFERS_SUCCESS = 'FETCH_COMMUNITY_OFFERS_SUCCESS'
export const FETCH_COMMUNITY_OFFERS_FAILURE = 'FETCH_COMMUNITY_OFFERS_FAILURE'
export const SWAP_COMMUNITY_OFFER_SUCCESS = 'SWAP_COMMUNITY_OFFER_SUCCESS'
export const SWAP_COMMUNITY_OFFER_FAILURE = 'SWAP_COMMUNITY_OFFER_FAILURE'
export const COMMUNITY_SWAPPABLE_OFFER = 'COMMUNITY_SWAPPABLE_OFFER'
export const CLEAR_SWAP = 'CLEAR_SWAP'
export const CLEAR_SWAP_OFFER_ERROR_DATA = 'CLEAR_SWAP_OFFER_ERROR_DATA'
export const SET_ACTIVATE_OFFER_COMPLETED = 'SET_ACTIVATE_OFFER_COMPLETED'
export const UPDATE_SELECTED_FILTERED_OFFERS = 'UPDATE_SELECTED_FILTERED_OFFERS'
export const UPDATE_SELECTED_FACET = 'UPDATE_SELECTED_FACET'
export const FETCH_COMMUNITY_FILTERED_OFFERS_SUCCESS = 'FETCH_COMMUNITY_FILTERED_OFFERS_SUCCESS'
export const FETCH_COMMUNITY_FILTERED_OFFERS_FAILURE = 'FETCH_COMMUNITY_FILTERED_OFFERS_FAILURE'
export const UPDATE_COMMUNITY_FILTERED_OFFERS = 'UPDATE_COMMUNITY_FILTERED_OFFERS'
export const UPDATE_COMMUNITY_SELECTED_FACET = 'UPDATE_COMMUNITY_SELECTED_FACET'
export const UPDATE_COMMUNITY_SELECTED_FILTERED_OFFERS = 'UPDATE_COMMUNITY_SELECTED_FILTERED_OFFERS'
export const UPDATE_COMMUNITY_FILTERED_OFFERS_SORTING = 'UPDATE_COMMUNITY_FILTERED_OFFERS_SORTING'
export const SET_SORT_DATA_COMMUNITY = 'SET_SORT_DATA_COMMUNITY'
export const SET_SORT_DATA_OFFER = 'SET_SORT_DATA_OFFER'
export const SET_SORT_DATA_SWAPPABLE_OFFER = 'SET_SORT_DATA_SWAPPABLE_OFFER'
export const FETCH_SWAPPABLE_FILTERED_OFFERS_SUCCESS = 'FETCH_SWAPPABLE_FILTERED_OFFERS_SUCCESS'
export const FETCH_SWAPPABLE_FILTERED_OFFERS_FAILURE = 'FETCH_SWAPPABLE_FILTERED_OFFERS_FAILURE'
export const SET_COMMUNITY_SELECTED = 'SET_COMMUNITY_SELECTED'
export const UPDATE_SWAPPABLE_FILTERED_OFFERS = 'UPDATE_SWAPPABLE_FILTERED_OFFERS'
export const UPDATE_SWAPPABLE_SELECTED_FACET = 'UPDATE_SWAPPABLE_SELECTED_FACET'
export const UPDATE_SWAPPABLE_SELECTED_FILTERED_OFFERS = 'UPDATE_SWAPPABLE_SELECTED_FILTERED_OFFERS'
export const UPDATE_SWAPPABLE_FILTERED_OFFERS_SORTING = 'UPDATE_SWAPPABLE_FILTERED_OFFERS_SORTING'
export const CLEAR_FILTER_OFFER = 'CLEAR_FILTER_OFFER'
export const HIDE_EMAIL_ACTIVATION_COMPONENT = 'HIDE_EMAIL_ACTIVATION_COMPONENT'
