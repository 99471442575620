export const minimumBreadcrumbLength = 1
export const goto = 'goto'
export const productLength = 28
export const srpURL = '#srp'
export const plpURL = '?loc=plp'
export const plpURLRegExp = /\?loc=plp/
export const toGetLastBreadcrumb = 1
export const defaultCheckValue = 1
export const toGetPrevCategory = 2
export const wheel = 'wheel'
export const loc = 'loc'
export const plpURLParam = 'plp'
export const htmlSuffix = '.html'
export const pathSeparator = '/'
