import React, { useCallback, useEffect, useRef, useState, Validator } from 'react'
import PropTypes from 'prop-types'

import { MapProps, MapLatLng, MapPosition, StoreGeoPoint } from './Map.type'
import {
    markerProps,
    locationProps,
    mapControls,
    locateMeControlStyles,
    currentLocationButtonClass,
} from './Map.constant'
import { PREFIX } from '../config'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { magicNumber } from '../../utils'
import SkeletonComponent from '../Skeleton'

/**
 * Map component
 * @param {MapProps} props
 * @return {JSX.Element} returns Map component
 */
const Map: React.FC<MapProps> = props => {
    const {
        locations,
        windowRef,
        mapId,
        mapZoom,
        setMapZoom,
        setMarkerClickHandler,
        markerClickHandler,
        setPreferredStoreOnMapIconClicked,
        userGeoLocation,
        isWindowGoogleLoaded,
        center,
        a11yMapLocateMeButtonLabel,
    } = props
    const {
        markerWidth,
        markerHeight,
        initialMarkerIndex,
        markerLabelFontSize,
        markerLabelFontWeight,
        markerLabelXPos,
        markerLabelYPos,
        shape,
    } = markerProps
    const { lat, lng } = locationProps
    const { mapType, mapZoomControl, mapTypeControl, mapFullscreenControl } = mapControls
    const runOnce = useRef(0)
    const [mapLoaded, setMapLoaded] = useState(false)

    /**
     * function to get selected store's coordinates
     * @return {MapLatLng}
     */
    const getStoreCords = (): StoreGeoPoint => {
        const firstMarkerIndex = initialMarkerIndex
        if (markerClickHandler?.geoPoint || (markerClickHandler?.latitude && markerClickHandler?.longitude)) {
            return markerClickHandler.geoPoint ? markerClickHandler.geoPoint : markerClickHandler
        } else {
            return locations[firstMarkerIndex]
        }
    }

    /**
     * render getFirstMarkerPosition
     * @return {MapLatLng}
     */
    const getFirstMarkerPosition = (): MapLatLng => {
        let latLongPosition = { lat, lng }
        if (isArrayNotEmpty(locations)) {
            const { latitude, longitude } = getStoreCords()
            latLongPosition = { lat: latitude, lng: longitude }
        }
        return latLongPosition
    }

    const latLong: MapLatLng = getFirstMarkerPosition()

    /**
     * Function to check if Map loading condition are met
     * @returns {boolean}
     */
    const isMapLoadingConditionsMet = useCallback((): boolean => {
        return isWindowGoogleLoaded && mapLoaded && isArrayNotEmpty(locations) && !!windowRef?.google?.maps
    }, [isWindowGoogleLoaded, locations, mapLoaded, windowRef])

    const initMap = useCallback(async () => {
        try {
            await windowRef.google.maps.importLibrary('maps')
            setMapLoaded(true)
        } catch (error) {
            console.warn('error', error)
        }
    }, [windowRef])
    /**
     * The locateMeButton adds a control to the map that recenters the map on users current location.
     * @param {controlDiv} controlDiv element
     * @param {map} map object
     */

    useEffect(() => {
        void initMap()
    }, [initMap])

    useEffect(() => {
        if (isMapLoadingConditionsMet()) {
            const centerControlDiv = document.createElement('div')
            const userCurrentLocation =
                userGeoLocation && Object.values(userGeoLocation).includes(undefined) ? latLong : userGeoLocation
            const map = new windowRef.google.maps.Map(document.getElementById(mapId), {
                disableDefaultUI: true,
                center: center ?? (userCurrentLocation || latLong),
                zoom: mapZoom,
                mapTypeId: mapType,
                zoomControl: mapZoomControl,
                mapTypeControl: mapTypeControl,
                fullscreenControl: mapFullscreenControl,
            })
            const locateMeButton = (controlDiv: Element, googleMap: googleMaps.Map) => {
                const locationButton = document.createElement('button')
                locationButton.style.backgroundColor = locateMeControlStyles.locationButtonstylebackgroundColor
                locationButton.style.border = locateMeControlStyles.locationButtonstyleborder
                locationButton.style.outline = locateMeControlStyles.locationButtonstyleoutline
                locationButton.style.width = locateMeControlStyles.locationButtonstylewidth
                locationButton.style.height = locateMeControlStyles.locationButtonstyleheight
                locationButton.style.borderRadius = locateMeControlStyles.locationButtonstyleborderRadius
                locationButton.style.boxShadow = locateMeControlStyles.locationButtonstyleboxShadow
                locationButton.style.cursor = locateMeControlStyles.locationButtonstylecursor
                locationButton.style.marginRight = locateMeControlStyles.locationButtonstylemarginRight
                locationButton.style.padding = locateMeControlStyles.locationButtonstylepadding
                controlDiv.appendChild(locationButton)

                const locationButtonInner = document.createElement('div')
                locationButtonInner.style.margin = locateMeControlStyles.locationButtonInnerstylemargin
                locationButtonInner.style.width = locateMeControlStyles.locationButtonInnerstylewidth
                locationButtonInner.style.height = locateMeControlStyles.locationButtonInnerstyleheight
                locationButtonInner.style.backgroundImage =
                    locateMeControlStyles.locationButtonInnerstylebackgroundImage
                locationButtonInner.style.backgroundSize = locateMeControlStyles.locationButtonInnerstylebackgroundSize
                locationButtonInner.style.backgroundPosition =
                    locateMeControlStyles.locationButtonInnerstylebackgroundPosition
                locationButtonInner.style.backgroundRepeat =
                    locateMeControlStyles.locationButtonInnerstylebackgroundRepeat
                locationButtonInner.id = locateMeControlStyles.locationButtonInnerid
                locationButton.setAttribute('aria-label', a11yMapLocateMeButtonLabel)
                locationButton.setAttribute('class', currentLocationButtonClass)
                locationButton.appendChild(locationButtonInner)

                // Setup the click event listeners: set the map to locate user current geo location
                locationButton.addEventListener('click', () => {
                    setPreferredStoreOnMapIconClicked?.()
                })

                googleMap.controls[windowRef?.google?.maps?.ControlPosition?.RIGHT_BOTTOM].push(controlDiv)
            }

            /* if near By Store List For StoreLocator is empty then 'Store Pins on Map' will not to be show on map */
            if (locations[initialMarkerIndex].name !== '') {
                const bounds = new windowRef.google.maps.LatLngBounds()
                locateMeButton(centerControlDiv, map)
                const infoWindow = new windowRef.google.maps.InfoWindow()
                locations.forEach((marker: MapPosition) => {
                    const { storeMarker, storeMarkerLabel, latitude, longitude, name, markerColor } = marker
                    const position = { lat: latitude, lng: longitude }

                    const markerNew = new windowRef.google.maps.Marker({
                        position: position,
                        map,
                        setMyLocationButtonEnabled: true,
                        title: name,
                        icon: {
                            url: storeMarker,
                            scaledSize: new windowRef.google.maps.Size(markerWidth, markerHeight),
                            labelOrigin: { x: markerLabelXPos, y: markerLabelYPos },
                        },
                        label: {
                            color: markerColor,
                            fontSize: markerLabelFontSize,
                            fontWeight: markerLabelFontWeight,
                            text: `${storeMarkerLabel}`,
                        },
                        shape: shape,
                        zIndex: `${storeMarkerLabel}` === ' ' ? magicNumber.ONE : magicNumber.ZERO,
                    })

                    markerNew.addListener('click', () => {
                        marker.isSelected = true
                        setMarkerClickHandler?.(marker)
                        infoWindow.close()
                        infoWindow.setContent(marker.address)
                        infoWindow.open(map, markerNew)
                    })

                    bounds.extend(new windowRef.google.maps.LatLng(latitude, longitude))
                })

                if (runOnce.current === 0) {
                    map.fitBounds(bounds)
                    runOnce.current = magicNumber.ONE
                }
                map.addListener('zoom_changed', () => {
                    setMapZoom?.(map?.getZoom())
                })
            }
        }
    }, [
        locations,
        mapLoaded,
        isWindowGoogleLoaded,
        center,
        initialMarkerIndex,
        windowRef,
        userGeoLocation,
        latLong,
        mapId,
        mapZoom,
        mapType,
        mapZoomControl,
        mapTypeControl,
        mapFullscreenControl,
        markerWidth,
        markerHeight,
        markerLabelXPos,
        markerLabelYPos,
        markerLabelFontSize,
        markerLabelFontWeight,
        shape,
        setMarkerClickHandler,
        setMapZoom,
        setPreferredStoreOnMapIconClicked,
        a11yMapLocateMeButtonLabel,
        isMapLoadingConditionsMet,
    ])

    return isWindowGoogleLoaded ? (
        <div id={mapId} className={`${PREFIX}-map`} />
    ) : (
        <SkeletonComponent skeletonClass={`${PREFIX}-map__skeleton-wrapper`} />
    )
}
Map.propTypes = {
    mapId: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf<MapPosition>(PropTypes.object.isRequired as Validator<MapPosition>),
    windowRef: PropTypes.any,
    setMapZoom: PropTypes.func,
    mapZoom: PropTypes.number,
    setMarkerClickHandler: PropTypes.func,
    markerClickHandler: PropTypes.object,
    setPreferredStoreOnMapIconClicked: PropTypes.func,
    userGeoLocation: PropTypes.exact({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }),
    isWindowGoogleLoaded: PropTypes.bool,
    center: PropTypes.exact({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }),
    a11yMapLocateMeButtonLabel: PropTypes.string,
}

Map.displayName = 'GoogleMap'

export default Map
