import React, { useRef, useState, useEffect, useCallback } from 'react'
import { string, bool, func, shape, number } from 'prop-types'
import { IVariant } from '@nl/lib/src'
import { ErrorScreenData } from '@nl/lib/src/components/ErrorScreen/ErrorScreen.type'
import { BalanceCheckerModalScreenProps, ScreenIDType } from './BalanceChecker.type'
import {
    ReactModal,
    Icon,
    useClickOutsideClose,
    balanceCheckerScreen,
    SuccessScreen,
    ErrorScreen,
    BalanceScreen,
    checkDataLength,
    getCardErrorCheck,
    replaceStrWithDynamicVal,
    getBalance,
    validateCardInput,
    errorCodes,
    getErrorCodeMessage,
} from '@nl/lib'
import { PREFIX } from '../../config'
import { commonContentAvailableSelector } from '../../redux/selectors/commonContent.selectors'
import { userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'
import { fetchCTMoneyBalance, resetCTMoneyBalance } from '../../redux/actions/ctMoneyBalance.action'
import { ctMoneyBalanceDataSelector, ctMoneyBalanceErrorSelector } from '../../redux/selectors/ctMoneyBalance.selectors'
import { loyaltyCardMaxLength, loyaltyCardPrefix, loyaltyCardRegex } from '../LinkRewards/LinkRewards.constants'
import { showSpinner } from '../../redux/actions'
import { IGeneral } from '../../redux/models/commonContent.interface'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'

const BalanceCheckerModalScreen: React.FC<BalanceCheckerModalScreenProps> = ({ ...props }): JSX.Element => {
    const { screenId, openPopup, closeHandler, data } = props
    const { balanceScreen, successScreen, errorScreen } = balanceCheckerScreen as Record<string, string>
    const initialRenderComponent = { screenID: screenId }
    const {
        balanceCheckerImage,
        balanceCheckerImageAltText,
        balanceCheckerTitle,
        balanceCheckerDescription,
        cardNumberLabel,
        cardImage,
        cardImageAltText,
        checkBalanceCtaLabel,
        checkBalanceCtaAllyLabel,
        cancelCtaLabel,
        cancelCtaAllyLabel,
        ctMoneyLabel,
        linkCardText,
        ctaLabelGuestUser,
        ctaUrlGuestUser,
        ctaLinkTargetGuestUser,
        ctaAllyLabelGuestUser,
        ctaUrlLoggedInUser,
        ctaLinkTargetLoggedInUser,
        ctaAllyLabelLoggedInUser,
        checkBalanceOnAnotherCardCtaLabel,
        checkBalanceOnAnotherCardCtaAllyLabel,
        multipleAttemptFailureTitle,
        multipleAttemptFailureDescription,
        tryAgainCtaLabel,
        tryAgainCtaAllyLabel,
        emptyCardErrorMsg,
        invalidCardErrorMsg,
        partialCardErrorMsg,
        cardLinkedErrorMsg,
        ineligibleForBalanceCheckTitle,
        ineligibleForBalanceCheckDescription,
        cardMergeProcessTitle,
        cardMergeProcessDescription,
    } = data

    const commonContentAvailable = useAppSelector(commonContentAvailableSelector)
    const userProfileData = useAppSelector(userProfileDataSelector)
    const ctMoneyBalanceData = useAppSelector(ctMoneyBalanceDataSelector)
    const ctMoneyErrorResponse = useAppSelector(ctMoneyBalanceErrorSelector)
    const balanceCheckerModalRef = useRef<HTMLDivElement>(null)
    const [errorMessage, setErrorMessage] = useState<string | undefined>('')
    const [selectedCardNumber, setSelectedCardNumber] = useState('')
    const [isValidationCheck, setIsValidationCheck] = useState(false)
    const [balanceCheckScreenType, setBalanceCheckScreenType] = useState(initialRenderComponent)
    const [errorScreenProps, setErrorScreenProps] = useState({
        errorImage: '',
        errorImgAltText: '',
        errorRichText: '',
        btnType: '',
    } as ErrorScreenData)

    const isSignedInUser = Boolean(userProfileData && Object.keys(userProfileData).length)

    const { accessibility, general = {} as IGeneral } = commonContentAvailable
    const { errorImage, errorImageAltText } = general

    const dispatch = useAppDispatch()
    const headingVariant = 'h3' as IVariant

    /**
     * Function to reset the input field and states associated with it
     * @returns {void} return nothing
     */
    const resetInputFieldValues = useCallback(() => {
        setIsValidationCheck(false)
        setErrorMessage('')
        setBalanceCheckScreenType({ screenID: balanceScreen })
    }, [setErrorMessage, setIsValidationCheck, balanceScreen])

    /**
     * Try Again button handler to change modal to balance screen
     * @returns {void} return nothing
     */
    const tryAgainHandler = useCallback(() => {
        dispatch(resetCTMoneyBalance())
        resetInputFieldValues()
    }, [dispatch, resetInputFieldValues])

    const errorBCProps = useCallback(
        (title: string, desc: string) => {
            return {
                errorImage: errorImage ?? '',
                errorImgAltText: errorImageAltText ?? '',
                errorRichText: `${title ? title : ''}${desc}`,
                btnType: 'primary',
            }
        },
        [errorImage, errorImageAltText],
    )

    /**
     * Based on the error codes, display inline or modal screen errors
     * @returns {void} return nothing
     */
    const setErrorRedirect = useCallback(
        (message: string) => {
            if (message === errorCodes.error00055 || message === errorCodes.error00030) {
                const newErrorProps = {
                    ...errorBCProps(multipleAttemptFailureTitle, multipleAttemptFailureDescription),
                    tryAgainCTALabelText: tryAgainCtaLabel,
                    tryAgainCTAAllyText: tryAgainCtaAllyLabel,
                    onClickTryAgain: tryAgainHandler,
                }
                setBalanceCheckScreenType({ screenID: errorScreen })
                setErrorScreenProps(newErrorProps)
            } else if (message === errorCodes.error00130) {
                setBalanceCheckScreenType({ screenID: errorScreen })
                setErrorScreenProps(errorBCProps(multipleAttemptFailureTitle, multipleAttemptFailureDescription))
            } else if (message === errorCodes.error00140) {
                setBalanceCheckScreenType({ screenID: errorScreen })
                setErrorScreenProps(errorBCProps(ineligibleForBalanceCheckTitle, ineligibleForBalanceCheckDescription))
            } else if (message === errorCodes.error00049) {
                setBalanceCheckScreenType({ screenID: errorScreen })
                setErrorScreenProps(errorBCProps(cardMergeProcessTitle, cardMergeProcessDescription))
            } else {
                setErrorMessage(message)
            }
        },
        [
            errorScreen,
            multipleAttemptFailureTitle,
            multipleAttemptFailureDescription,
            tryAgainCtaAllyLabel,
            tryAgainCtaLabel,
            tryAgainHandler,
            ineligibleForBalanceCheckTitle,
            ineligibleForBalanceCheckDescription,
            cardMergeProcessTitle,
            cardMergeProcessDescription,
            errorBCProps,
        ],
    )

    /**
     * useEffect to change modal to success screen or display an error message based on api response
     */
    useEffect(() => {
        if (checkDataLength(ctMoneyBalanceData)) {
            setBalanceCheckScreenType({ screenID: successScreen })
        } else if (checkDataLength(ctMoneyErrorResponse)) {
            const getMessage = getErrorCodeMessage(ctMoneyErrorResponse?.errCode, {
                errorMsg: invalidCardErrorMsg,
                redirectError: cardLinkedErrorMsg,
            })
            setErrorRedirect(getMessage)
        }
    }, [
        ctMoneyBalanceData,
        ctMoneyErrorResponse,
        successScreen,
        invalidCardErrorMsg,
        cardLinkedErrorMsg,
        setErrorRedirect,
    ])

    /**
     * Checking empty field and length validations in balance input field
     * @returns {void} return nothing
     */
    const cardErrorCheck = useCallback(() => {
        const validateErrorMessage = validateCardInput(
            selectedCardNumber,
            partialCardErrorMsg,
            emptyCardErrorMsg,
            loyaltyCardMaxLength,
            loyaltyCardRegex,
        )
        setErrorMessage(validateErrorMessage)
    }, [partialCardErrorMsg, emptyCardErrorMsg, selectedCardNumber])

    useEffect(() => {
        cardErrorCheck()
    }, [cardErrorCheck])

    /**
     * Closing the modal should also reset the input field states and rootstate associated with balance checker
     * @returns {void} return nothing
     */
    const closeModalHandler = useCallback(() => {
        dispatch(resetCTMoneyBalance())
        resetInputFieldValues()
        setSelectedCardNumber('')
        closeHandler()
    }, [dispatch, resetInputFieldValues, closeHandler])

    /**
     * Get Balance Handler to fetch ctMoneyBalance from api
     * @returns {void} return nothing
     */
    const getCTMoneyBalanceHandler = (): void => {
        const cardNumber = (loyaltyCardPrefix + selectedCardNumber).replace(/\s/g, '')
        setIsValidationCheck(true)
        dispatch(resetCTMoneyBalance())
        if (selectedCardNumber && !getCardErrorCheck(selectedCardNumber, loyaltyCardMaxLength, loyaltyCardRegex)) {
            dispatch(showSpinner(true))
            dispatch(fetchCTMoneyBalance(cardNumber))
        } else if (!selectedCardNumber) {
            setErrorMessage(emptyCardErrorMsg)
        }
    }

    /**
     * Clicking link rewards button should redirect the user to an url based on auth status
     * @returns {void} return nothing
     */
    const linkRewardsHandler = (): void => {
        window.location.href = isSignedInUser ? ctaUrlLoggedInUser : ctaUrlGuestUser
    }

    /**
     * Check Balance on another card handler switches modal to balance screen and resets input field
     * @returns {void} return nothing
     */
    const checkBalanceOnAnotherCardHandler = (): void => {
        dispatch(resetCTMoneyBalance())
        resetInputFieldValues()
        setSelectedCardNumber('')
    }

    useClickOutsideClose(balanceCheckerModalRef, closeModalHandler, true, true)

    const balanceScreenProps = {
        balanceScreenImg: {
            image: balanceCheckerImage,
            imageAlt: balanceCheckerImageAltText,
        },
        balanceCheckerTitle,
        balanceCheckerDescription,
        cardNumberLabel,
        cardImage,
        cardImageAltText,
        ctaList: [
            {
                a11yLabel: checkBalanceCtaAllyLabel,
                label: checkBalanceCtaLabel,
                type: 'primary',
                btnFunc: getCTMoneyBalanceHandler,
            },
            {
                a11yLabel: cancelCtaAllyLabel,
                label: cancelCtaLabel,
                type: 'tertiary',
                btnFunc: closeModalHandler,
            },
        ],
        cardNumberError: errorMessage,
        selectedCardNumber,
        setSelectedCardNumber,
        isValidationCheck,
        headingVariant,
    }

    const successCTAList = {
        a11yLabel: isSignedInUser ? ctaAllyLabelLoggedInUser : ctaAllyLabelGuestUser,
        label: ctaLabelGuestUser,
        target: isSignedInUser ? ctaLinkTargetLoggedInUser : ctaLinkTargetGuestUser,
    }

    const successBCProps = {
        successLogo: {
            image: balanceCheckerImage,
            imageAlt: balanceCheckerImageAltText,
        },
        successMessageHeader: balanceCheckerTitle,
        labelValueArray: [
            {
                label: replaceStrWithDynamicVal(ctMoneyLabel, getBalance(ctMoneyBalanceData?.ctMoneyBalance)),
                value: '',
            },
        ],
        successScreenContent: linkCardText,
        ctaList: [
            {
                a11yLabel: successCTAList.a11yLabel,
                label: successCTAList.label,
                target: successCTAList.target,
                btnFunc: linkRewardsHandler,
                type: 'primary',
            },
            {
                a11yLabel: checkBalanceOnAnotherCardCtaAllyLabel,
                label: checkBalanceOnAnotherCardCtaLabel,
                btnFunc: checkBalanceOnAnotherCardHandler,
                type: 'tertiary',
            },
        ],
        headingVariant,
    }

    /**
     * Returns the JSX Element based on screen id
     *  @param {ScreenIDType} activeScreen - screen id
     *  @returns {JSX.Element} - returns JSX Element
     */
    const activeScreenSelection = (activeScreen: ScreenIDType): JSX.Element => {
        switch (activeScreen.screenID) {
            case balanceScreen: {
                return <BalanceScreen data={balanceScreenProps} />
            }
            case successScreen: {
                return <SuccessScreen data={successBCProps} />
            }
            case errorScreen: {
                return <ErrorScreen data={errorScreenProps} />
            }
            default:
                return <></>
        }
    }
    return (
        <ReactModal closeHandler={closeModalHandler} isOpen={openPopup} ariaModal={true}>
            <div className={`${PREFIX}-balance-checker`} ref={balanceCheckerModalRef}>
                <div className={`${PREFIX}-confirmation-modal`}>
                    <div className={`${PREFIX}-confirmation-modal__close-container`}>
                        <button
                            className={`${PREFIX}-confirmation-modal__close-btn`}
                            aria-label={accessibility?.a11yCloseIconLabel}
                            onClick={closeModalHandler}>
                            <Icon type="ct-close" size="lg" />
                        </button>
                    </div>
                </div>
                <div className={`${PREFIX}-balance-checker-screen`}>
                    {activeScreenSelection(balanceCheckScreenType)}
                </div>
            </div>
        </ReactModal>
    )
}

BalanceCheckerModalScreen.propTypes = {
    data: shape({
        balanceCheckerImage: string.isRequired,
        balanceCheckerImageAltText: string.isRequired,
        balanceCheckerTitle: string.isRequired,
        balanceCheckerDescription: string.isRequired,
        ctMoneyBalanceCheckLabel: string.isRequired,
        cardNumberLabel: string.isRequired,
        cardImage: string.isRequired,
        cardImageAltText: string.isRequired,
        checkBalanceCtaLabel: string.isRequired,
        checkBalanceCtaAllyLabel: string.isRequired,
        cancelCtaLabel: string.isRequired,
        cancelCtaAllyLabel: string.isRequired,
        ctMoneyLabel: string.isRequired,
        linkCardText: string.isRequired,
        ctaLabelGuestUser: string.isRequired,
        ctaUrlGuestUser: string.isRequired,
        ctaLinkTargetGuestUser: string.isRequired,
        ctaAllyLabelGuestUser: string.isRequired,
        ctaUrlLoggedInUser: string.isRequired,
        ctaLinkTargetLoggedInUser: string.isRequired,
        ctaAllyLabelLoggedInUser: string.isRequired,
        checkBalanceOnAnotherCardCtaLabel: string.isRequired,
        checkBalanceOnAnotherCardCtaAllyLabel: string.isRequired,
        multipleAttemptFailureTitle: string.isRequired,
        multipleAttemptFailureDescription: string.isRequired,
        tryAgainCtaLabel: string.isRequired,
        tryAgainCtaAllyLabel: string.isRequired,
        emptyCardErrorMsg: string.isRequired,
        partialCardErrorMsg: string.isRequired,
        invalidCardErrorMsg: string.isRequired,
        numberOfFailedAttempts: number.isRequired,
        cardLinkedErrorMsg: string.isRequired,
        ineligibleForBalanceCheckTitle: string.isRequired,
        ineligibleForBalanceCheckDescription: string.isRequired,
        cardMergeProcessTitle: string.isRequired,
        cardMergeProcessDescription: string.isRequired,
        tryAgainTitle: string.isRequired,
        tryAgainDescription: string.isRequired,
    }).isRequired,
    screenId: string.isRequired,
    openPopup: bool.isRequired,
    closeHandler: func.isRequired,
}

export default BalanceCheckerModalScreen
