import { breadcrumbLength, minimumBreadcrumbLength } from './Breadcrumb.constant'
import { BreadcrumbAutomotive, BreadcrumbParentPageLink } from './Breadcrumb.type'
import { getPageType } from '../../utils/getPageType'
import { pageTypes } from '../../utils/pageTypes'

export const isLastLinkAndNotInteractive = (
    linksCount: number,
    index: number,
    isLastElementInteractive: boolean,
): boolean => index === linksCount - minimumBreadcrumbLength && !isLastElementInteractive

export const normalizeLinkArray = (
    links: BreadcrumbParentPageLink[],
    isMobile: boolean,
    vehicleBreadCrumb?: BreadcrumbAutomotive,
): BreadcrumbParentPageLink[] =>
    links.reduce<BreadcrumbParentPageLink[]>((normalizedLinks, linkItem, index) => {
        const isTheLastOne = index === links.length - minimumBreadcrumbLength

        if (vehicleBreadCrumb && !isMobile && isTheLastOne) {
            const { name, link, pName } = vehicleBreadCrumb

            const vehicleToLinkMapLink: BreadcrumbParentPageLink = {
                name,
                link,
                pName,
            }

            return [...normalizedLinks, vehicleToLinkMapLink]
        }

        if (!linkItem.link && !isTheLastOne) {
            return normalizedLinks
        }

        return [...normalizedLinks, linkItem]
    }, [])

const isPdpOrPlpPage = getPageType() === pageTypes.pdpPage || getPageType() === pageTypes.plp

/**
 * Function to get breadcrumb name if name is greater than 28 char will add '...'
 * @param {string} name category name
 * @returns {string} will return the category name
 */
export const getBreadcrumbName = (name: string): string => {
    return name.length > breadcrumbLength && isPdpOrPlpPage ? `${name.substring(0, breadcrumbLength)}...` : name
}
