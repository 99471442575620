import { FulfillmentOptionAvailable, YesNoNotApplicable } from '../../analytics/providers/provider.type'
import { GlobalAnalyticsVehicle } from '../../components/Accounts/OrderHistory/GlobalPageLoadAnalytics.type'
import { HttpRequestState } from '../../httpClient/client.type'
import {
    CheckoutContactInfo,
    CheckoutShippingInfo,
    BillingAddress,
    CheckoutAppointmentInfo,
} from './checkout.interface'
import { iPrice } from './productData.interface'
import { StoreWithAvailability } from './storeDetails.interface'
import { productWheelTypes, FitmentTypeEnum } from '../../globalConstants/global.constant'
import { Consignment, EntryGroups, PointOfService } from './orderConfirmation.interface'
import { ItemInlineErrorType } from '../../components/ShoppingCart/ShoppingCart.type'
import { PromoMessageType, ProductSpecifications, TriangleSelectBenefitsType } from './product.interface'
import { SaleCut, SaleCutDTO } from '../../components/BuyBox/BuyBox.type'
import { AxiosError, AxiosResponse } from 'axios'
import { AutoPackageModalData } from '../../components/ProductGridView/ProductCard.types'

export interface Price {
    value?: number | null
    maxPrice?: number | null
    minPrice?: number | null
}

export interface PriceMessage {
    label?: string | null
    tooltip?: string | null
}

export interface OrderEntryDTO {
    product: {
        code: string
    }
    quantity: number
    deliveryPointOfService?: {
        name: string
    }
    deliveryMode: {
        code: string
    }
    isPackagedItem?: boolean
    packageId?: string
    vehicleInformation?: VehicleInformation
    cartEntryReference?: string
    isGrouped?: boolean
    mrRegistryId?: number
    templateId?: string
    selectedStoreServices?: IHeliumInflationStoreServiceType[]
    entryGroupNumber?: number
}

export interface AddProductToCartGuestRequestDTO {
    guid: string
    saveForLaterId?: string
    clickedSFL?: boolean
    fields?: string
    entries: {
        orderEntries: OrderEntryDTO[]
    }
    isPackagedItem?: boolean
    packageId?: string
    vehicleInformation?: VehicleInformation
    isGrouped?: boolean
    entryNumber?: number[]
}

export interface AddProductToSFLGuestRequestDTO {
    productName: string
    isBulk: boolean
    productData: AddProductToCartGuestRequestDTO
}

export interface CartModificationsDTO {
    entry: AutoPackageModalData
    quantityAdded: number
    errorCode: string
    errorMessage: string
    numberOfItems?: number
}

export interface ServiceDTO {
    serviceType?: string
    maxQuantity?: number
    name: string
    price: number
    shortDescription?: string
    sku: string
    url: string
    entryType?: string
    fulfillment?: CartItemFulFillmentType
    code?: string
}
export interface ServiceData {
    serviceType?: string
    maxQuantity: number
    url?: string
    name?: string
    shortDescription?: string
    sku?: string
    price: number
}
export enum ServiceType {
    ADD_ON = 'ADD',
    SERVICE = 'SER',
}

export interface AddProductToCartGuestResponseDTO {
    cartModifications: CartModificationsDTO[]
    entryGroups: EntryGroups[]
    serviceList: ServiceDTO[]
    guid: string
    numberOfItems: number
    heliumInflationAddToCartEnabled?: boolean
}

export interface DeliveryOptionsCost {
    code: STHDeliveryModes.STANDARD | STHDeliveryModes.EXPRESS
    eligible: boolean
    shippingFee: DeliveryCostType
}

export interface DeliveryCosts {
    deliveryCosts: DeliveryOptionsCost[]
}

export interface CartResponseErrorData {
    errors: [
        {
            message: string
            type: string
        },
    ]
    statusCode: number
    details?: CartResponseErrorDetails
    message: string
    inventory?: number
    maxSFLCount: string
    errorMessage: string
    errorCode: string
    data: {
        inventory: number
        message: string
        errors: [
            {
                message: string
            },
        ]
    }
    errCode: string
    errOrigin: string
    error?: string
    cartItemLimit?: number
    vToken: string
    otpEmail: string
}

export type CartResponseErrorDTO = AxiosResponse<CartResponseErrorData> & {
    statusCode?: number
}

export type AxiosCartResponseErrorDTO = AxiosError<CartResponseErrorData>

export interface CartResponseErrorDetails {
    'address.firstName'?: {
        message: string
        value: unknown | null
    }
    'address.lastName'?: {
        message: string
        value: unknown | null
    }
    'address.addressLineOne'?: {
        message: string
        value: unknown | null
    }
    'address.city'?: {
        message: string
        value: unknown | null
    }
    'address.province'?: {
        message: string
        value: unknown | null
    }
    'address.country'?: {
        message: string
        value: unknown | null
    }
    'address.postalCode'?: {
        message: string
        value: unknown | null
    }
    'address.specialInstructions'?: {
        message: string
        value: unknown | null
    }
}

export interface OrderEntries {
    configurationInfos: []
    deliveryPointOfService: {
        name: string
    }
    entryNumber: number
    quantity: number
    statusSummaryList: []
}

export enum DeliveryStatusBopis {
    READY_FOR_PICK_UP = 'READY_FOR_PICKUP',
    IN_PROGRESS = 'IN_PROGRESS',
    ORDER_RECEIVED = 'ORDER_RECEIVED',
    COMPLETED = 'COMPLETED',
    CANCELLATION_PENDING = 'CANCELLATION_PENDING',
    CANCELLED = 'CANCELLED',
    UNCONSIGNED = 'UNCONSIGNED',
    RETURNED = 'RETURNED',
}

export enum DeliveryStatusSTH {
    ORDER_RECEIVED = 'ORDER_RECEIVED',
    SHIPPED = 'SHIPPED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELLATION_PENDING = 'CANCELLATION_PENDING',
    CANCELLED = 'CANCELLED',
    RETURN_REQUESTED = 'RETURN_REQUESTED',
    UNCONSIGNED = 'UNCONSIGNED',
    RETURNED = 'RETURNED',
    EN_COURS = 'EN COURS',
}

export enum eEntryType {
    PRODUCT = 'PRODUCT (PRO)',
    SERVICE_ADDON = 'SERVICE_ADDON (SER_ADD)',
    SERVICE_STANDALONE = 'STANDALONE (SER_STA)',
}

export enum ProductSourceTypes {
    INSTORE = 'INSTORE',
    DC = 'DC',
    SHIPFROMINSTORE = 'Ship from store',
    SHIPFROMDC = 'Ship from DC',
}

export enum STHDeliveryModes {
    STH = 'STH',
    STANDARD = 'STANDARD',
    EXPRESS = 'EXPRESS',
}

export enum DeliveryModeCodes {
    STH = 'STH',
    EXPRESS = 'EXPRESS',
    BOPIS = 'BOPIS',
}

export enum ExpressRejectReasons {
    TOGGLE_OFF = 'TOGGLED_OFF',
    DISTANCE_EXCEEDED = 'DISTANCE_EXCEEDED',
    WEIGHT_EXCEEDED = 'WEIGHT_EXCEEDED',
    SIZE_EXCEEDED = 'SIZE_EXCEEDED',
    NOT_ELIGIBLE = 'PRODUCT_NOT_ELIGIBLE',
    TOTAL_EXCEEDED = 'TOTAL_EXCEEDED',
}

export interface ProductOption {
    descriptor: string
    display: string
    value: string
    variantOrder?: number
}

export interface ProductOptionDTO {
    descriptor?: string
    display?: string
    value: string
}

export interface VehicleInformation {
    baseVehicleId?: string
    engineConfigId?: string
    body?: string
    make: string
    model: string
    options?: string
    type: string
    year: string
    vin?: string
    boltPattern?: string
}

export interface VehicleInformationDTO {
    body: string
    make: string
    model: string
    options: string
    type: string
    year: string
    vin: string
}

export interface DeliveryCostType {
    currencyIso?: string
    formattedValue?: string
    maxQuantity?: number
    minQuantity?: number
    priceType?: string
    value: number
}

export interface AvailableDeliveryModesType {
    code: STHDeliveryModes.STH | STHDeliveryModes.EXPRESS
    isApplicable: boolean
    name?: string
    deliveryCost?: DeliveryCostType
    description?: string
    orderIn?: string
    etaEarliest?: string | null
    etaLatest?: string | null
    rejectionReason?:
        | ExpressRejectReasons.TOGGLE_OFF
        | ExpressRejectReasons.DISTANCE_EXCEEDED
        | ExpressRejectReasons.SIZE_EXCEEDED
        | ExpressRejectReasons.WEIGHT_EXCEEDED
        | ExpressRejectReasons.NOT_ELIGIBLE
        | ExpressRejectReasons.TOTAL_EXCEEDED
    shippingFees?: Price
}

export interface OrderEntriesData {
    badges: string[]
    brand: BrandDTO
    images: ImageDTO[]
    name: string
    code: string
    baseProduct: string
    color?: string
    options: ProductOption[]
    isTireOrWheel: boolean
    cancellable: boolean
    cancellableQuantity: number
    cancelledItemsPrice: Price
    cartEntryReference: string
    entryNumber: number
    entryType: string
    repairOrderRequired: boolean
    returnableQuantity: number
    returnedItemsPrice: Price
    rating: number
    status: string
    currentPrice: Price
    priceMessage: PriceMessage[]
    originalPrice: Price
    displayWasLabel: boolean
    feeMessages: FeeMessage[]
    fulfillment: OrderFulFillmentType
    feeTitle: string
    specifications: ProductSpecifications[]
    staggeredGroup: string
    isBulk: boolean
    quantity: number
    totalPrice: Price
    updateable: boolean
    merchCategories: string[]
    url: string
    rebate: Rebate
    isPackageItem: boolean
    packageId: string
    vehicleInformation: VehicleInformation | null
    productWheelType?: productWheelTypes
    fitmentTypeCode: string
    formattedCode: string
    eddMax?: string
    eddMin?: string
    saleCut?: SaleCut
    appliedPromoMessages: PriceMessage[]
    triangleSelectBenefits?: TriangleSelectBenefitsType
}

export interface TiresOrWheelRequirePackageVal {
    vehicleInformation: VehicleInformation | null
    code: string
}

export interface CheckoutAvailableDeliveryModesType {
    code: DeliveryModeCodes
    etaEarliest?: string | null
}

export interface CartOrderEntries {
    vehicleInformation: VehicleInformation | null
    entryGroupNumbers?: number[]
    entryNumber: number
    quantity: number
    errorCode?: string
    statusSummaryList?: []
    entryType: string
    cartEntryReference?: string
    code: string
    cancellable: boolean
    cancellableQuantity: number
    isFreeStorePickup?: boolean
    availability?: string
    images: ImageDTO[]
    brand: BrandDTO
    name: string
    color?: string
    options?: ProductOption[]
    pickupDateTime?: PickupDateTimeDTO
    deliveryDates?: DeliveryDatesDTO[]
    deliveryAddress?: AddressDTO
    availableInDC?: boolean
    availableDCQuantity?: number
    isShipToHome?: boolean
    isShipHome: boolean
    isEligibleToShipHome?: boolean
    isTireOrWheel: boolean
    maxQuantity: number
    availableQuantity?: number
    fullFillmentDaysAway?: string[]
    curbPickupInfo?: string
    isCurbside?: boolean
    timeTestedPrice?: iPrice
    price: iPrice
    discount?: Price
    originalPrice: Price
    currentPrice: Price
    displayWasLabel: boolean
    totalPrice: Price
    shippingFees?: PriceDTO
    servicesList: ServiceDTO[]
    packagedItem: boolean
    ecoFee?: iPrice
    coreCharge?: iPrice
    ecoMessage?: string
    ecoFeeIncluded?: boolean
    sku: string
    isBulk: boolean
    isServiceProduct?: boolean
    badges?: string[]
    limitedQuantity?: number
    installationEligible?: boolean
    // Analytics key
    fulfillmentOptionAvailable?: FulfillmentOptionAvailable
    corporateStatus?: string
    badge?: string[]
    fulfillmentStatus?: string
    category?: string
    level1?: string
    level2?: string
    level3?: string
    level4?: string
    level5?: string
    // TODO: Need to remove the below key. For now unable to find where it is used so keeping it. The above key has a new interface.
    // fulfillment?: DeliveryMethod
    fitted?: string
    package?: string
    fulfillmentDaysAway?: string
    fee?: string
    someoneElsePickingUp?: boolean
    pickupEmailProvided?: boolean
    pcode?: string
    instantRebateIncluded?: boolean
    iumapp?: boolean
    rebate?: Rebate
    repairOrderRequired: boolean
    ror?: boolean
    variant1?: string
    variant2?: string
    variant3?: string
    vehicle?: {
        staggeredFitment?: boolean
        type?: string
        make?: string
        model?: string
        year?: string
        body?: string
        option?: string
        vin?: string
    }
    prodCategory: string
    division?: string
    lob?: string
    businessCategory?: string
    businessSubcategory?: string
    fineLine?: string
    feeType?: string
    variant?: {
        type: string
        value: string
    }[]
    numberOfReviews?: number
    rating?: number
    shipFrom?: string
    fulfillment: CartItemFulFillmentType
    productStatus: ProductStatus
    errorResponse?: {
        errCode: string
    }
    sellable: boolean
    isBopisOnly: boolean
    isSellableStarted?: boolean
    isSellableFinished?: boolean
    isRorProduct?: boolean
    productWheelType?: productWheelTypes
    fitmentTypeCode?: FitmentTypeEnum
    feeMessages?: FeeMessage[]
    maxPurchaseQty?: number
    staggeredGroup?: string
    merchCategories?: string[]
    hasStoreAutoServiceCenter?: boolean
    isPackagedItem?: boolean
    packageId?: string
    priceMessage?: PriceMessage[]
    url?: string
    selected?: boolean
    itemAvailabilityInfo?: ItemInlineErrorType
    status?: string
    baseProduct: string
    Rating?: number
    // TODO: Need to revisit this once api response format finalized, for now adding this attribut to check whether product is balloon product or not.
    balloonData?: BalloonData
    bulkDeliveryOptions?: BulkDeliveryOptionDTO[]
    specifications?: ProductSpecifications[]
    isInWishList?: boolean
    saleCut?: SaleCut
    estimatedDeliveryDate?: EstimatedDeliveryDate
    eddMax?: string
    eddMin?: string
    triangleSelectBenefits?: TriangleSelectBenefitsType
    isReusableBag?: boolean
    products?: [] | CartOrderEntries[]
    minETA?: string
    maxETA?: string
    statusDate?: string
    balloonEntryType?: string
    selectedStoreServices?: IHeliumInflationStoreServiceType[]
    supportedStoreServices?: IHeliumInflationStoreServiceType[]
    appliedPromoMessages: PriceMessage[]
    trackingUrl?: string
    returnRequestDate?: string
}

export interface CartOrderEntriesRecord extends CartOrderEntries {
    fulfillmentOptionAvailable: any
    fulfillment: any
}

export interface SpecificationsDTO {
    size?: string
    dimension?: string
}
export interface CartOrderEntriesWithGroupingVal extends CartOrderEntries {
    trackingID: string
    maxETA: string
    minETA: string
    trackingUrl?: string
}

export interface BalloonData {
    balloonType: string
    heliumIncluded: boolean
    isInflated: boolean
}
export interface CartOrderVehicleInfo {
    body?: string
    make?: string
    model?: string
    options?: string
    year?: string
}

export interface CartOrderEntriesWithFilterVal extends CartOrderEntries {
    filterVal: string
}

export interface FeeMessage {
    feeDisclaimerTitle?: string
    feeDisclaimerMessage?: string
    feeTitle?: string
    type?: string
    value?: number
}

export interface StaggeredFitmentProduct extends CartOrderEntries {
    isFront?: boolean
}

export interface StockItemAvailability {
    dcQuantity: number
    storeQuantity: number
}
export interface StockItemAvailabilityDTO {
    dcQuantity?: number
    storeQuantity?: number
}

export enum CartOrderDeliveryModes {
    BOPIS = 'BOPIS',
    STH = 'STH',
    CURBSIDE = 'CURBSIDE',
    EXPRESS = 'EXPRESS',
}

export interface CartItemFulFillmentType {
    orderableFromDC: boolean
    isEligibleToShipHome: boolean
    isEligibleToPickupFromStore: boolean
    isEligibleForExpressDelivery: boolean
    isStorePickup: boolean
    isEndlessAisle?: boolean
    deliveryMode: CartOrderDeliveryModes
    sourceTypes: CartItemFulfillmentSource[]
    stockItemAvailability: StockItemAvailability
    availability?: {
        Corporate: {
            Quantity: number
            MinOrderQty: number
            MaxETA: string
            MinETA: string
            orderableFromDC: boolean
        }
        quantity: number
        storeShelfLocation: string
    }
}

export interface OrderFulFillmentType {
    deliveryMode: CartOrderDeliveryModes
    sourceTypes: CartItemFulfillmentSource[]
}

export interface CartItemFulfillmentSource {
    quantity: number
    productSourceType: string // INSTORE or DC
    etaEarliest: string
    etaLatest: string
    NextOrderCutOffDate: string
    MinOrderQty: number
    storeShelfLocation: string
}

export interface CartItemFulfillmentSourceDTO {
    quantity?: number
    productSourceType?: string // INSTORE or DC
    etaEarliest?: string
    etaLatest?: string
    NextOrderCutOffDate?: string
    MinOrderQty?: number
    storeShelfLocation?: string
}
export interface SingleCartItemDetailsType {
    enabled: boolean
    source: StoreDataDTO | null
    availability: ItemAvailability
    etaEarliest: string
}
export interface StorePickUp extends SingleCartItemDetailsType {
    etaTime: string
}

export interface ShipToHome extends SingleCartItemDetailsType {
    etaLatest: string
}

// TODO: Need to revisit the below enum
export enum ProductStatus {
    ACTIVE = 'ACTIVE(ACT)',
    INACTIVE = 'INACTIVE',
    FULLY_DISCONTINUED = 'FULLY_DISCONTINUED(FD)',
    DISCONTINUED_WHEN_OUT = 'DISCONTINUED_WHEN_OUT(DW)',
    SEASONALLY_DISCONTINUED = 'SEASONALLY_DISCONTINUED(SD)',
    TEMPORARY_DISCONTINUED = 'TEMPORARY_DISCONTINUED(TD)',
}

export interface ItemAvailability {
    Corporate: {
        Quantity: number
        MinOrderQty: number
        MinETA: string
        MaxETA: string
        NextOrderCutOffDate: string
    }
    quantity: number
    storeShelfLocation: string | null
}

export interface SiteWidePromotionalCode {
    code: string
    title: string
    description: string
    startDate: string
    endDate: string
}

export interface PromotionalCode {
    code?: string
    description?: string
    name?: string
    promoCode?: string
    promoDisclaimer?: string
    promotionType?: PromotionType
    promoMessage?: PromoMessageType
}

export enum PromotionType {
    PRICE_BASED = 'PRICE_BASED',
    FREE_SHIPPING = 'FREE_SHIPPING',
}

export interface AppliedVoucher {
    code: string
    applied: boolean | null
    redeemingErrorCode: string
}
export interface CommonCartOrderResponse {
    appliedVouchersData?: AppliedVoucher[]
    appliedPromotions?: PromotionalCode[]
    contactInfo?: CheckoutContactInfo
    customerCallbackRequested?: boolean
    vin?: string
    entryGroups: EntryGroups[]
    errorCode?: string
    orderPickupInPerson?: PickupSomeoneElse
    pickupLocation?: string
    handlingFee: number
    orderThreshold: number
    dropOffInstructions?: {
        signatureRequired?: boolean
        specialInstructions?: string
    }
}

export interface UpdatedCartDataDTO extends CommonCartOrderResponse {
    cartId: string
    cartSummary: CartSummaryData
    bulkDeliveryOptions: BulkDeliveryOptionDTO[]
    deliveryAddress: DeliveryAddressDTO
    deliveryMode?: string
    errorCode?: string
    numberOfItems?: string
    orderEntries?: OrderEntriesDTO
    orderPickupInPerson?: PickupSomeoneElse
    paymentInfo: CartPaymentInfo
    store?: StoreWithAvailability
    isValidExpressDeliveryAddress?: boolean
    flatRateActive: boolean
}
export interface OrderEntriesDTO {
    appliedPromoMessages?: PriceMessage[]
    badges?: string[]
    OfferDesc: string
    brand?: BrandDTO
    cartEntryReference?: string
    code?: string
    color: string
    currentPrice?: Price
    discount?: Price
    displayWasLabel?: boolean
    energyRebateAmt?: Price
    energyRebateIncluded?: boolean
    entryNumber?: number
    entryType?: string
    errorCode?: string
    fulfillment?: CartItemFulFillmentDTO
    isBulk?: boolean
    images?: ImageType[]
    installationEligible?: boolean
    installationTermConditionAccepted?: boolean
    isPackageItem: boolean
    isShipHome?: boolean
    rebate?: RebateDTO
    maxPurchaseQty?: number
    name?: string
    originalPrice?: Price
    options?: ProductOptionDTO[]
    packagedItem?: boolean
    packageId: string
    vehicleInformation: VehicleInformationDTO
    postTaxRebateIncluded?: boolean
    postTaxRebateAmt?: Price
    preTaxRebateIncluded?: boolean
    priceMessage?: PriceMessage[]
    productStatus?: string
    quantity?: number
    salePriceEndDate?: string
    sellable?: boolean
    servicesList?: ServiceData[]
    specifications?: SpecificationsDTO[]
    staggeredGroup: string
    totalPrice?: Price
    url?: string
    merchCategories: string[]
    someoneElsePickingUp?: string
    hasStoreAutoServiceCenter?: boolean
    isRorProduct?: boolean
    fulfillmentOptionAvailable?: string
    fulFillmentStatus: string
    guaranteedFitment?: string
    Fitted?: boolean
    numberOfReviews?: number
    Rating?: number
    shipFrom?: string
    productWheelType?: string
    feeMessages?: FeeMessageDTO[]
    reservedQuantity?: number
    fitmentTypeCode: string
    formattedCode: string
    eddMax?: string
    eddMin?: string
    triangleSelectBenefits?: TriangleSelectBenefitsType
    saleCut?: SaleCutDTO
}
export interface CartSummaryData {
    shippingFees?: Price
    subTotalAmt?: Price
    taxLines: TaxLineDTO[]
    totalAmt?: Price
    totalRebateAmt?: Price | null
    totalSavingAmt: Price | null
    totalDiscount?: Price | null
    totalTaxAmt?: Price | null
    totalWithTaxesAmt?: Price
    absoluteTotal: number
}

export interface CartItemFulFillmentDTO {
    orderableFromDC?: boolean
    isEligibleToPickupFromStore?: boolean
    isEligibleToShipHome?: boolean
    isStorePickup?: boolean
    deliveryMode?: string
    sourceTypes?: CartItemFulfillmentSourceDTO[]
    stockItemAvailability?: StockItemAvailabilityDTO
    quantity?: number
    productSourceType?: string
    etaEarliest?: string
    etaLatest?: string
    MinOrderQty?: number
    storeShelfLocation?: string
    dcQuantity?: number
    storeQuantity?: number
    isEligibleForExpressDelivery?: boolean
}
export interface PlaceOrderData extends CommonCartOrderResponse {
    cancellable: boolean
    code: string
    created: string
    bulkDeliveryOption: BulkDeliveryOptionDTO
    deliveryAddress: DeliveryAddressDTO
    deliveryPointOfService: PointOfService
    entries: OrderEntriesData[]
    guid?: string
    productDiscounts: Price
    returnable: boolean
    status: string
    isTireOrWheel: boolean
    statusDisplay: string
    store: string
    totalItems: number
    entryGroups: EntryGroups[]
    orderSummary?: CartSummaryDTO
    customerCallbackRequested?: boolean
    orderPickupInPerson: PickupSomeoneElse
    pickupLocation?: string
    paymentInfo: OrderPaymentResponse
    handlingFee: number
    heliumInflationAddToCartEnabled?: boolean
}

export interface PlaceOrderDTO {
    order: PlaceOrderData
}

export interface GetOrderDTO {
    order: OrderConfimationData
}
export interface OrderConfimationData extends PlaceOrderData {
    consignments: Consignment[]
    unconsignedEntries: OrderEntriesData[]
    dropOffInstructions: {
        signatureRequired: boolean
        specialInstructions: string
    }
}
export interface CartItemsData {
    availableDeliveryModes: AvailableDeliveryModesType[]
    status?: string
    cancellable: boolean
    cancellableQuantity: number
    cartId: string
    code: string
    consignments: Consignment[]
    guid?: string
    created?: string
    statusDisplay?: string
    orderEntries: CartOrderEntries[]
    entries?: CartOrderEntries[]
    errorCode?: string
    errorMessage: string
    successFlag: boolean
    orderThreshold: number
    handlingFee: number
    numberOfItems: number
    totalItems?: number
    storeEligibility: boolean
    store: StoreWithAvailability
    updatedCart: UpdatedCart
    cartSummary: CartSummaryDTO
    orderSummary?: CartSummaryDTO
    isBulk: boolean
    bulkDeliveryOptions: BulkDeliveryOptionDTO[]
    bulkDeliveryOption: BulkDeliveryOptionDTO
    deliveryAddress: DeliveryAddressDTO
    deliveryPointOfService?: PointOfService
    deliveryMode: string
    serviceList: ServiceDTO[] // For storing the services added on a particular item. TODO: Need to remove this.
    bopis?: CartOrderEntries[]
    sth?: CartOrderEntries[]
    contactInfo?: CheckoutContactInfo
    shippingInfo?: CheckoutShippingInfo
    orderPickupInPerson?: PickupSomeoneElse
    pickupLocation?: string
    pickupMethod?: string
    paymentInfo: CartPaymentResponse
    entryGroups: EntryGroups[]
    productWheelType?: string
    vin?: string
    requestCallback?: boolean
    customerCallbackRequested?: boolean
    saveForLaterId?: string
    isSflCartExists?: boolean
    flatRateActive?: boolean
    appliedPromotions?: PromotionalCode[]
    appliedVouchersData?: AppliedVoucher[]
    maxPromoCodeCount?: number
    successMessage?: string
    returnRequestedData?: Array<ReturnEntry>
    unconsignedEntries?: Array<Record<string, unknown>>
    isConsignmentPacked?: boolean
    potentialCTMoney?: number
    dropOffInstructions?: DropOffInstructions
    isEligibleForReusableBags?: boolean
    reusableBagOptIn?: boolean
    reusableBagInfo?: {
        name: string
        price: number
        images: ImageDTO[]
    }
    isModificationEnabled?: {
        contactEmail?: boolean
        contactPhone?: boolean
        deliveryAddress?: boolean
        pickupContact?: boolean
        pickupMethod?: boolean
        appointmentInformation?: boolean
    }
    deliveryCostStatus: DeliveryCostStatus
    estimatedDeliveryDate?: EstimatedDeliveryDate
    heliumInflationAddToCartEnabled?: boolean
    pickupAllItemsTogetherEligibleState?: string
    configurableBundleBagSku?: string
    isShareCartEligible?: boolean
    bookedAppointments?: CheckoutAppointmentInfo[]
}

export interface EstimatedDeliveryDate {
    startDate: string
    endDate: string
}

export interface MiniCartData {
    cartId: string
    numberOfItems: number
    updatedCart: {
        numberOfItems: number
    }
    orderEntries: MiniCartOrderEntries[] | null
    deliveryAddress: MiniCartDeliveryAddress | null
}

export interface MiniCartOrderEntries {
    entryType: string
    cartEntryReference: string
    fulfillment: {
        deliveryMode: string
    }
    vehicleInformation: MiniCartVehicleInformation
    code: string
    isBulk: boolean
}

export interface MiniCartVehicleInformation {
    year: string
    make: string
    model: string
    body: string
    options: string
}

export interface MiniCartDeliveryAddress {
    address: {
        postalCode?: string
    }
}

export interface VersionedMiniCartData extends MiniCartData {
    version: string
}

export interface MiniCartStorageData extends MiniCartData {
    version: string
    expiresIn: number
}

export interface CheckoutCartItemsData {
    availableDeliveryModes: CheckoutAvailableDeliveryModesType[]
    cartId: string
    store: CheckoutStoreId
    deliveryAddress: CheckoutAddress
    paymentInfo: CheckoutPaymentInfo
    pickupLocation: string
    vin: string
    orderPickupInPerson: PickupSomeoneElse
    cartSummary: CheckoutCartSummary
    contactInfo: CheckoutContactInfo
    numberOfItems: number
    customerCallbackRequested?: boolean
    orderEntries: CheckoutOrderEntries[]
    bulkDeliveryOptions?: CheckoutBulkDeliveryOptionDTO[]
    deliveryCostStatus: string
    dropOffInstructions?: DropOffInstructions
    deliveryMode: string
    appliedVouchersData: AppliedVoucher[]
    maxPromoCodeCount?: number
    appliedPromotions: CheckoutPromotionalCode[]
    flatRateActive: boolean
    orderThreshold: number
    handlingFee: number
    totalItems: number
    shippingProgress?: FreeShippingProgress
    xhrInfo?: {
        getCartInfo?: HttpRequestState
    }
}
interface CheckoutPromotionalCode {
    code: string
    promotionType: string
    promoMessage: PromoMessageType
}

interface DropOffInstructions {
    signatureRequired?: boolean
    specialInstructions?: string
}

interface CheckoutStoreId {
    id: string
}

interface CheckoutAddress {
    firstName?: string
    lastName?: string
    address?: AddressDTO
}

interface CheckoutPaymentInfo {
    maxAllowableCTMoneyToRedeem: CTMoney
    redeemCTAmount: CTMoney
    redeemCTMoney: boolean
    isLoyaltyRedemptionEnabled: boolean
    giftCards?: GiftCheckoutCard[]
    totalGiftCardAmount: TotalGiftCardAmount
    remainingTotalCost: RemainingTotalCost
}

interface CTMoney {
    value: number
}

interface CheckoutCartSummary {
    taxLines: CheckoutTaxLineDTO[]
    totalWithTaxesAmt: CTMoney
    totalAmt: CTMoney
    shippingFees: CTMoney
    totalSavingAmt: CTMoney
    subTotalAmt: CTMoney
    totalRebateAmt: CTMoney
    totalDiscount: CTMoney
}

interface CheckoutPrice {
    value: number
    maxPrice: number
    minPrice: number
}

export interface CheckoutOrderEntries {
    code: string
    name: string
    entryGroupNumbers?: number[]
    entryType: string
    productWheelType: string
    images: CheckoutImage[]
    brand: CheckoutBrand
    isBulk: boolean
    Rating: number
    rebate: CheckoutRebate
    quantity: number
    totalPrice: CheckoutPrice
    url: string
    entryNumber: number
    isUrgentLowStock: boolean
    fulfillment: CheckoutFulfilment
    cartEntryReference?: string
    currentPrice: CheckoutPrice
    feeMessages: CheckoutFeeMessages[]
    vehicleInformation: CheckoutVehicleInformation
    fitmentTypeCode: string
    productStatus: string
    merchCategories: string[]
    staggeredGroup: string
    hasStoreAutoServiceCenter: boolean
    installationEligible: boolean
    balloonEntryType?: string
}

interface CheckoutImage {
    url: string
    altText: string
}

interface CheckoutBrand {
    label: string
}

interface CheckoutRebate {
    message: string
}

export interface CheckoutFulfilment {
    stockItemAvailability: CheckoutStockItemAvailability
    orderableFromDC: boolean
    deliveryMode: string
    isEligibleToShipHome: boolean
    isEligibleToPickupFromStore: boolean
    sourceTypes: CheckoutSourceTypes[]
}

interface CheckoutStockItemAvailability {
    storeQuantity: number
}

interface CheckoutBulkDeliveryOptionDTO {
    isSelected?: boolean
    deliveryOptionType: string
}

interface CheckoutSourceTypes {
    quantity: number
    etaEarliest: string
}

interface CheckoutFeeMessages {
    type: string
    value: number
}

interface CheckoutVehicleInformation {
    type: string
    make: string
    model: string
    year: string
    body: string
    options: string
}

interface CheckoutStockItemAvailability {
    storeQuantity: number
}

interface ReturnEntry {
    returnEntries: Array<Record<string, unknown>>
    entries: Array<Record<string, unknown>>
    [key: string]: unknown
}

export interface UpdateDeliveryRequest {
    bannerid?: string
    cartId?: string
    entryNumber?: number
    userID?: string
    guid?: string
    deliveryMethodId?: string
    bulkDeliveryOptionType?: string
    postalCode?: postalCodeRequest
    storeId: number
}

interface postalCodeRequest {
    storeZoneId?: string
    postalCode?: string
}

export interface PickupSomeoneElse {
    emailAddress: string
    firstName: string
    lastName: string
}

export interface BulkDeliveryOptionDTO {
    deliveryOptionType?: DeliveryOptionType
    wgPrice?: Price
    isSelected?: boolean
}

export interface CartSummaryDTO {
    subTotalAmt: Price
    shippingFees: Price
    taxLines: TaxLineDTO[]
    totalTaxAmt: Price
    totalAmt: Price
    totalWithTaxesAmt: Price
    totalSavingAmt: Price
    totalRebateAmt: Price
    totalDiscount?: Price
    ePPValue?: number
    absoluteTotal: number
}

export interface TaxLineDTO {
    taxName: TaxName
    taxValue: Price
}

export interface CheckoutTaxLineDTO {
    taxName: TaxName
    taxValue: CheckoutIPrice
}

export interface CheckoutIPrice {
    value: number
}

export enum DeliveryOptionType {
    STANDARD = 'STANDARD (STAN)',
    IN_HOME_DELIVERY = 'IN_HOME_DELIVERY (IHDE)',
    IN_HOME_DELIVERY_UNPACK = 'IN_HOME_DELIVERY_UNPACK (IHDU)',
}

export enum TaxName {
    GST = 'GST',
    HST = 'HST',
    QST = 'QST',
    PST = 'PST',
}

export interface PickupDateTimeDTO {
    etaTime: string
    etaDate: string
    quantity?: number
}

export interface DeliveryDatesDTO {
    etaEarliest: string
    etaLatest: string
    quantity: number
}

export interface StoreDataDTO {
    storeId: string
    name: string
    address: AddressDTO
    storeIntersection: string
    url: string
    openTime: string
    closeTime: string
    latitude: number
    longitude: number
    isCurbside: boolean
    curbSideFee: number
    curbPickupInfo: string
}

export interface ProductAvailabityDTO {
    name: string
    url: string
}

export interface AddressDTO {
    address1?: string
    address2?: string
    city?: string
    province?: string
    postalCode?: string
}

export interface ImageDTO {
    altText: string
    mediaType: string
    isListingThumbnailImage: string
    url: string
}

export interface ImageType {
    altText?: string
    mediaType?: string
    isListingThumbnailImage?: string
    url?: string
}

export interface BrandDTO {
    label: string
    url: string
}

export interface PriceDTO {
    formattedValue: string | null
    value: number | null
    each: boolean
}

export interface DeliveryAddressDTO {
    firstName?: string
    lastName?: string
    address?: AddressDTO
}

export interface Package {
    groupNumber: number
    status?: string
    items: Array<CartOrderEntries>
}

export interface FilteredCartData {
    // TODO: state hierarchy need to be improved
    cart: CartItemsData
    fulfillmentCost: string | undefined
    financingEligible: boolean | undefined
    financingShown: boolean | undefined
    financingSelected: boolean | undefined
    sflAddAndMoveCart?: sflAddAndMoveCart
    installationAvailable: YesNoNotApplicable
    installationHelp: YesNoNotApplicable
    vinEntered: YesNoNotApplicable
    bopis: CartOrderEntries[]
    sth: CartOrderEntries[]
    services: CartOrderEntries[]
    storeData: StoreWithAvailability
    deliveryAddress: DeliveryAddressDTO
    selectedServiceList: CartOrderEntries[]
    successFlag?: boolean
    xhrInfo?: {
        getCartInfo?: HttpRequestState
    }
    bopisGrouped: Record<string, unknown>
    sthGrouped: Record<string, unknown>
    servicesGrouped: Record<string, unknown>
    isPostalCodePartialSuccess?: boolean
    freeShippingProgressData?: FreeShippingProgress
    freeShippingProgressModal?: FreeShippingProgressModal
    isUpdateQuantity?: boolean
    availableDeliveryModes: AvailableDeliveryModesType[]
    bopisPackages: Package[]
    sthPackages: Package[]
}

export interface RedeemCTMoneyResponse {
    isSuccess: boolean
    error?: Record<string, unknown>
}

export interface CartModificationDTO {
    updatedCart?: UpdatedCart
    errorCode?: string
    errorMessage?: string
    successFlag: boolean
    successMessage?: string
    deliveryMethod?: DeliveryMethodIdDTO
    // TODO: need to check
    storeData?: StoreDataDTO
    deliveryAddress?: CheckoutShippingInfo
    saveForLaterId?: string
}
export interface AddServiceToCart {
    success: boolean
    showErrorToast: boolean
}

export interface AddServiceSuccessToCart {
    success: boolean
    showErrorToast: boolean
    selectedService: ServiceDTO
}

export interface DeliveryMethodIdDTO {
    deliveryMethodId: string
    deliveryMethod: DeliveryMethodDTO
}

export enum DeliveryMethodDTO {
    FREE_STORE_PICKUP = 'FREE_STORE_PICKUP',
    SHIP_TO_HOME = 'SHIP_TO_HOME',
}

export interface CartAnalytics {
    availability?: string
    corporateStatus?: string
    badge: string[] | undefined
    brand: string | undefined
    fulfillmentStatus?: string
    category: string | undefined
    level1: string | undefined
    level2: string | undefined
    level3: string | undefined
    level4: string | undefined
    level5: string | undefined
    fulfillment?: string
    fitted: boolean | undefined
    package: string | undefined
    fullfillmentDaysAway?: string
    fee: string | undefined
    financingEligible?: string
    financingShown?: string
    installationAvailable?: string
    inventory?: string
    list?: string
    name: string
    pcode?: string
    price: string | null | undefined
    rebate: string | undefined
    quantity: number | undefined
    rating: string | undefined
    ror?: boolean
    numberOfReviews: string | undefined
    shipFrom: string | undefined
    sku: string | number
    variant1: string | undefined
    variant2: string | undefined
    variant3: string | undefined
    vehicle: GlobalAnalyticsVehicle | undefined
    fulfillmentOptionAvailable?: string
    bulk: boolean | undefined
    guaranteedFitment?: string
    pdpType?: string
    fulfillmentType?: string
    shippingMethod?: string
    isExpress?: string
    sthProducts?: CartOrderEntries[]
    fireCheckoutStep?: (step: number, option: string | undefined) => void | undefined
    fulfillmentDaysAway?: string
}

export interface VehicleAnalytics {
    type: string | undefined
    make: string | undefined
    model: string | undefined
    year: string | undefined
    body: string | undefined
    option: string | undefined
}

export interface RemoveCartItem {
    errorFlag: boolean
    errorMessage: string
}

/**
 * interface to get the list of postal code
 * TO-DO: to be removed when actual api integration is done.
 */
export interface PostalCodeData {
    invalidPC: string[]
    validPC: string[]
    outOfRangePC: string[]
}

export interface Rebate {
    iumapp?: boolean
    value?: number
    percentage?: boolean
    message?: string
    tooltipMessage?: string
    greyCopyMsg?: string
    pdfLink?: string
    priceAfterRebate?: number
}

export interface RebateDTO {
    iumapp: boolean
    value?: number
    percentage: boolean
    message?: string
    tooltipMessage?: string
    greyCopyMsg?: string
    pdfLink: string
    priceAfterRebate?: number
}
export interface SwitchFulfillmentType {
    mode: string
    entryNumber: number
}

export interface BasicVehicleData {
    sku: string
    vehicleInformation: VehicleInformation
}

export interface FeeMessage {
    feeDisclaimerTitle?: string
    feeDisclaimerMessage?: string
    feeTitle?: string
    type?: string
    value?: number
}

export interface FeeMessageDTO {
    feeDisclaimerTitle: string
    feeDisclaimerMessage: string
    feeTitle: string
    type: string
    value: number
}
export interface OrderPaymentResponse {
    transactionId: string
    maskedCardNumber: string
    cardType: {
        code: string
        name: string
    }
    expiryMonth?: string
    expiryYear?: string
    saved: boolean
    saveToMyProfile: boolean
    isDigitalCard?: boolean
    redeemCTMoney: boolean
    redeemCTAmount: iPrice
    billingAddress?: BillingAddress
    financingSelected?: string
    loyaltyCardNumber: string
    paymentType?: string
    giftCards?: GiftCard[]
    payPalInfo?: PayPalInfo
    tmcCtMoneyBalance?: number
    isPostPurchaseCTMRedemption?: boolean
}
export interface CartPaymentResponse {
    transactionId: string
    saveToMyProfile: boolean
    maxAllowableCTMoneyToRedeem: iPrice
    newCreditCard: boolean
    maskedCardNumber: string
    loyaltyCardNumber: string
    cardType: {
        code: string
        name: string
    }
    expiryMonth: string
    expiryYear: string
    saved: boolean
    isDigitalCard: boolean
    redeemCTMoney: boolean
    redeemCTAmount: iPrice
    eligibleForFinancing: boolean
    financingPlanId: number
    financingSelected?: string
    billingAddress: BillingAddress
    isLoyaltyRedemptionEnabled?: boolean
    giftCards?: GiftCard[]
    payPalInfo?: PayPalInfo
    remainingTotalCost?: RemainingTotalCost
    totalGiftCardAmount?: TotalGiftCardAmount
    tmcCtMoneyBalance?: number
    isPostPurchaseCTMRedemption?: boolean
    paymentType?: string | null
}

export interface CartPaymentInfo {
    transactionId: string
    cardReferenceNumber?: string
    newCreditCard: boolean
    maskedCardNumber?: string
    loyaltyCardNumber: string
    cardType?: {
        code?: string
        name?: string
    }
    expiryMonth?: string
    expiryYear?: string
    saved?: boolean
    saveToMyProfile: boolean
    isDigitalCard: boolean
    redeemCTMoney?: boolean
    redeemCTAmount?: Price
    maxAllowableCTMoneyToRedeem: Price
    eligibleForFinancing: boolean
    financingPlanId: number
    billingAddress?: BillingAddress | null
    isLoyaltyRedemptionEnabled?: boolean
}
export interface RemainingTotalCost {
    value?: number
}

export interface TotalGiftCardAmount {
    value?: number
}

export interface ETAData {
    etaEarliest: string
    etaLatest: string
}

export interface SortedAutomotiveType {
    sortedAutoList: CartOrderEntries[]
    groupedYMMETA: ETAData | undefined
}

export interface sflAddAndMoveCart {
    addedToSflSuccess?: boolean
    addedToSflFailure?: boolean
    moveToSflSuccess?: boolean
    moveToSflFailure?: boolean
    removeSflFailure?: boolean
    getSflFailure?: boolean
}

export interface ShippingEstimation {
    isFetching: boolean
    result: DeliveryCosts | null
    error: CartResponseErrorDTO | null
}

export interface FreeShippingProgress {
    type: string
    threshold: number
    awayFromFreeShipping: number
    percent: number
}

export interface FreeShippingProgressModal {
    longMessage: string
    shortMessage: string
}

export enum FreeShippingWidgetType {
    ELIGIBLE = 'ELIGIBLE',
    ZERO_THRESHOLD = 'ZERO_THRESHOLD',
    NOT_ELIGIBLE = 'NOT_ELIGIBLE',
    PROMO_APPLIED = 'PROMO_APPLIED',
}

export interface GiftCard {
    amount: number
    cardNumber: string
    number: string
}

export interface GiftCheckoutCard {
    amount: number
    cardNumber: string
}

export interface PayPalInfo {
    amount: number
}

export interface AddedServiceToCart {
    success: boolean
    showErrorToast: boolean
    selectedService?: ServiceDTO
}

export interface XhrInfo {
    getCartInfo?: HttpRequestState
}

/**
 * Enum for differntiating the various delivery cost status
 */
export enum DeliveryCostStatus {
    UNCHANGED = 'UNCHANGED',
    APPLIED = 'APPLIED',
    UPDATED = 'UPDATED',
}

export interface CurrentPrice {
    value?: number
}

export interface CartData {
    orderEntries?: CartOrderEntries[] | CheckoutOrderEntries[]
    updatedCart?: UpdatedCart
    entries?: CartOrderEntries[]
    deliveryPointOfService?: PointOfService
    store?: StoreWithAvailability | CheckoutStoreId
    deliveryAddress?: DeliveryAddressDTO
    shippingProgress?: FreeShippingProgress
    entryGroups?: EntryGroups[]
}

export interface ProductCodeData {
    baseProduct?: string
    code: string
}

export interface DeliveryModesData extends CartData {
    updatedCart?: UpdatedCart
    unconsignedEntries?: CartOrderEntries[]
    consignments?: Consignment[]
    returnRequestedData?: Array<ReturnEntry>
    orderEntries?: CartOrderEntries[]
    store?: StoreWithAvailability
}

export interface ClickToPayAddressValidationError {
    type: string
    reason: string
    message: string
    subjectType: string
    subject: string
    errorCode: string
}

export interface ShippingFeeCostType {
    currencyIso: string
    value: number
    priceType: string
    formattedValue: string
    minQuantity: number
    maxQuantity: number
}

export interface ClickToPaySTHValidationAddressDetails {
    firstName: string
    lastName: string
    line1: string
    postalCode: string
    town: string
    country: {
        isocode: string
    }
    shippingAddress: boolean
    billingAddress: boolean
    defaultAddress: boolean
    visibleInAddressBook: boolean
    province: string
}

export interface ClickToPaySTHValidationDTO {
    shippingFee: {
        shippingFeeCode: string
        shippingFee: ShippingFeeCostType
    } | null
    shippingAddress: ClickToPaySTHValidationAddressDetails
    billingAddress: ClickToPaySTHValidationAddressDetails
    canadaPostAddress: {
        id: string
        nextAction: string
    }
    eligibleForDelivery: boolean
    error: ClickToPayAddressValidationError | null
    deliveryCostStatus: DeliveryCostStatus
}

export interface SthEntries {
    entryType: eEntryType | string
    cartEntryReference?: string
    fulfillment: {
        deliveryMode: string
    }
}

export interface IHeliumInflationStoreServiceType {
    type: string
    value: string
}

export interface IAddToCartBundleRequest {
    entryNumber: number
    entryGroupNumber: number
    guid: string
}

export interface IAddToCartBundleRequestError {
    data: string | null
    status: number | null
}

export interface CartInitProps {
    cartMergeFlag: boolean
}

export interface UpdatedCart {
    orderEntries: CartOrderEntries[]
    store?: StoreWithAvailability
    deliveryAddress?: DeliveryAddressDTO
    numberOfItems: number
}

export interface IMinMaxEtaEntry {
    fulfillment?: {
        sourceTypes: {
            etaEarliest: string
            etaLatest: string
        }[]
    }
}

export interface IEntryWithBulkItem {
    orderEntries: {
        isBulk: boolean
        fulfillment: {
            deliveryMode: CartOrderDeliveryModes
        }
    }[]
}
