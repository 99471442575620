import { AnyAction, Dispatch, Store } from 'redux'
import { RootState } from '../reducers'
import { FETCH_PRODUCT_SUCCESS } from '../types/products/productData.actionTypes.constant'
import { ProductDataType } from '../models/productData.interface'
import mergeSponsoredProducts from '../utils/mergeSponsoredProducts'
import { setSponsoredAds } from '../actionCreators/sponsoredAds.actionCreators'
import { ProductSku } from '../models/product.interface'

interface IMergeSponsoredFromSearchMiddleware {
    (store: Store<RootState, AnyAction>): (next: Dispatch<AnyAction>) => (action: AnyAction) => void
}

/**
 * Middleware that merges sponsored products from the search response.
 * This logic is executed only when the `enableCriteoDirectServerOnCDS` feature flag is enabled.
 * Both `enableCriteoDirectServer` and `enableCriteoDirectServerOnCDS` cannot be true simultaneously.
 * If this scenario occurs, the merging logic will be bypassed.
 * @param {object} store - The Redux store instance.
 * @returns {Function} - The next middleware function.
 */
export const mergeSponsoredFromSearchMiddleware: IMergeSponsoredFromSearchMiddleware = store => next => action => {
    const { commonContent } = store.getState()

    const {
        commonContentAvailable: {
            featureFlag: { enableCriteoDirectServer = false, enableCriteoDirectServerOnCDS = false } = {},
        } = {},
    } = commonContent

    const productData = action.payload as ProductDataType

    // Validate the feature flag configuration
    if ((enableCriteoDirectServer && enableCriteoDirectServerOnCDS) || !enableCriteoDirectServerOnCDS) {
        return next(action)
    }

    if (action.type === FETCH_PRODUCT_SUCCESS && productData.sponsoredProducts) {
        const { sponsoredProducts, products } = productData
        const { inGrid, onLoadBeacon } = sponsoredProducts

        const sponsoredProductsWithSkus = inGrid?.products.map(product => ({
            ...product,
            skus: [{ code: product.skuId } as ProductSku],
        }))

        // merge sponsored ads to grid
        const productCardsWthInGridAds = {
            ...productData,
            products: mergeSponsoredProducts(sponsoredProductsWithSkus, products),
            // To avoid duplicating data across different locations,
            // sponsored products are removed here and managed separately in a dedicated reducer.
            sponsoredProducts: {},
        } as ProductDataType

        const modifiedAction = {
            ...action,
            payload: productCardsWthInGridAds,
        }

        // Trigger the root level OnLoadBeacon
        navigator.sendBeacon(onLoadBeacon)

        // store sponsored ads to the dedicated reducer
        store.dispatch(
            setSponsoredAds({
                onLoadBeacon,
                placements: sponsoredProducts,
            }),
        )

        return next(modifiedAction)
    }

    return next(action)
}
