import React from 'react'
import PropTypes from 'prop-types'
import { ProductCardsContainerProps } from '../ShoppableComponent.type'
import { PREFIX } from '../../../config'
import { SkeletonComponent } from '@nl/lib'

/**
 * ProductCardsSkeleton
 * @param props : ProductCardsSkeletonProps
 * @returns : JSX.Element
 */
const ProductCardsSkeleton: React.FC<ProductCardsContainerProps> = ({ ...props }): JSX.Element => {
    const productCardsContainerClassName = `${PREFIX}-product-cards-container`

    const renderedElements = () => {
        const skeletonElements = []
        for (let index = 0; index < props.productCardCount; index++) {
            skeletonElements.push(
                <div className={`${PREFIX}-product-card`} key={index} data-testid="product-card">
                    <SkeletonComponent
                        className={`${productCardsContainerClassName}__skeleton`}
                        width="100%"
                        height="100%"
                        dataTestId="product-card-skeleton"
                    />
                </div>,
            )
        }
        return skeletonElements
    }

    return (
        <div className={`${productCardsContainerClassName} ${PREFIX}-${props.componentWidthType}`}>
            {renderedElements()}
        </div>
    )
}

ProductCardsSkeleton.propTypes = {
    productCardCount: PropTypes.number.isRequired,
    componentWidthType: PropTypes.string.isRequired,
}

export default ProductCardsSkeleton
