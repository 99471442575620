import DOMPurify from 'dompurify'
import React, { useMemo } from 'react'

/**
 * Uses sanitizer library to sanitize URLs, html and other strings with potential XSS
 * @param {string} stringContent
 * @return {string} sanitized string
 */
export const sanitizeStringContent = (stringContent: string): string => {
    return DOMPurify.sanitize(stringContent)
}

/**
 * Component to use instead of html tags with dangerouslySetInnerHTML or href property
 * to leverage sanitizer library
 * @param {string} htmlContent
 * @param {React.ReactNode} child
 * @return {string} sanitized string
 */
export const SanitizeStringContentWrapper: React.FC<{
    stringContent?: string
    children: (content: string) => JSX.Element
}> = ({ stringContent, children }): JSX.Element => {
    const memoizedStringContent = useMemo(() => {
        return sanitizeStringContent(stringContent)
    }, [stringContent])
    return children(memoizedStringContent)
}

export default SanitizeStringContentWrapper
