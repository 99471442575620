import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { ErrorCardProps } from './ErrorCard.type'

/**
 * ErrorCard component
 * @param {ErrorCardProps} props
 * @return {JSX.Element}
 */
const ErrorCard: React.FC<ErrorCardProps> = ({ ...props }) => {
    const { title, description, linkUrl, linkLabel } = props
    const componentClassName = `${PREFIX}-package-error-card`

    return (
        <div className={componentClassName}>
            <h3 className={`${componentClassName}__title`}>{title}</h3>
            <p className={`${componentClassName}__content ${PREFIX}-col-md-6`}>{description}</p>
            <a
                href={linkUrl}
                className={`${componentClassName}__button ${PREFIX}-button ${PREFIX}-button--primary ${PREFIX}-button--small`}
                aria-label={linkLabel}>
                {linkLabel}
            </a>
        </div>
    )
}

ErrorCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    linkUrl: PropTypes.string,
    linkLabel: PropTypes.string,
}

export default ErrorCard
