import { addProductToWishList, removeWishListItems } from '../../redux/actions'
import { returnAddToWishListPayload } from './BuyBox.helper'
import { Vehicle } from '@nl/lib'
import { CriticalFitmentProduct } from '../AutomotivePDP/AutomotiveBuyBox/AutomotiveBuyBox.type'
import { addRemovePayLoad } from '../../redux/models/wishlist.interface'
import appCacheService from '../../utils/appCacheService'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { Dispatch } from 'redux'

/**
 * Adds or removes a product from wishlist
 * @param {CriticalFitmentProduct} criticalFitmentGrouped - critical fitment grouped
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsAddToWishlistClicked - set state variable on wishlist icon clicked
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setWishLoadToggle - toggle state variable on wishlist icon clicked
 * @param {void} updatingWishList - updating the loading message after adding and removing the product in wishlist
 * @param {string | string[]} getProductCodeForWishlist - get product code for wishlist
 * @param {boolean} isAddedToWishlist - is product added to wishlist
 * @param {void} checkIfAllVariantsSelected - checks if variants are selected or not
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setShowErrorMsgForVariantSelection - set state variable for showing error message for variant selection
 * @param {boolean} isAutomotive - is automotive
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsVariantSelected - set state variable for variant selected
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsNoSkuFindStoreClicked - set state variable for no sku find store clicked
 * @param {boolean | undefined} isToastDisplayed - is toast displayed
 * @param {any} dispatch - dispatch
 * @param {void} scrollOnToastDisplayed - scroll on toast displayed
 * @param {Vehicle | undefined} defaultVehicle - default vehicle
 * @param {boolean} isTireSizePresent - is tire size present
 * @param {boolean} enableVehicleInformationOnWishlist - enable vehicle information on wishlist
 * @param {addRemovePayLoad} productObject - product object
 * @param {string | undefined} addToWishlistInactiveProductErrorMsg - add to wishlist inactive product error message
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setShowWishListSpinner - OPTIONAL. set state variable for showing wishlist spinner
 * @returns {void}
 */
export const addRemoveWishList = (
    criticalFitmentGrouped: CriticalFitmentProduct,
    setIsAddToWishlistClicked: (val: React.SetStateAction<boolean>) => void,
    setWishLoadToggle: (val: React.SetStateAction<boolean>) => void,
    updatingWishList: () => void,
    getProductCodeForWishlist: () => string | string[],
    isAddedToWishlist: boolean,
    checkIfAllVariantsSelected: () => boolean,
    setShowErrorMsgForVariantSelection: (val: React.SetStateAction<boolean>) => void,
    isAutomotive: boolean,
    setIsVariantSelected: (val: React.SetStateAction<boolean>) => void,
    setIsNoSkuFindStoreClicked: (val: React.SetStateAction<boolean>) => void,
    isToastDisplayed: boolean | undefined,
    dispatch: Dispatch<any>,
    scrollOnToastDisplayed: () => void,
    defaultVehicle: Vehicle | undefined,
    isTireSizePresent: boolean,
    enableVehicleInformationOnWishlist: boolean,
    productObject: addRemovePayLoad,
    addToWishlistInactiveProductErrorMsg: string | undefined,
    setShowWishListSpinner?: React.Dispatch<React.SetStateAction<boolean>>,
): void => {
    const { isStaggered } = criticalFitmentGrouped
    // Set state variable on wishlist icon clicked
    setIsAddToWishlistClicked(true)
    // added for announcing state change of wishlist
    setWishLoadToggle(prevState => !prevState)
    updatingWishList()
    // checks if variants are selected or not
    const productCode = getProductCodeForWishlist()
    const allVariantsSelected = checkIfAllVariantsSelected()
    if (!allVariantsSelected) {
        setShowErrorMsgForVariantSelection(true)
        isAutomotive && setIsVariantSelected(true)
        setIsNoSkuFindStoreClicked(false)
        if (!isToastDisplayed) {
            scrollOnToastDisplayed()
        }
    } else {
        const { location, action } = analyticsAttributes.eventParameters
        if (productObject.location === location.salesAlertsLocation) {
            productObject.action = isAddedToWishlist
                ? action.removedFromWishlistBySalesAlertsCta
                : action.addedToWishlistBySalesAlertsCta
        }
        const guid = appCacheService.wishlistGuid.get()
        const productCodeArray = (isStaggered ? productCode : [productCode]) as string[]
        const wishlistRequestPayload = returnAddToWishListPayload(
            productCodeArray,
            isAutomotive,
            defaultVehicle as Vehicle,
            isTireSizePresent,
            enableVehicleInformationOnWishlist,
        )
        isAddedToWishlist
            ? dispatch(
                  removeWishListItems(
                      { wishlistRequestPayload, guid, analyticsParams: productObject, isStaggered },
                      setShowWishListSpinner,
                      undefined,
                      true,
                  ),
              )
            : dispatch(
                  addProductToWishList(
                      { guid, analyticsParams: productObject },
                      returnAddToWishListPayload(
                          productCodeArray,
                          isAutomotive,
                          defaultVehicle as Vehicle,
                          isTireSizePresent,
                          enableVehicleInformationOnWishlist,
                      ),
                      false,
                      setShowWishListSpinner,
                      undefined,
                      isStaggered,
                      false,
                      addToWishlistInactiveProductErrorMsg,
                      true,
                  ),
              )
        setIsAddToWishlistClicked(false)
    }
}
