import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators/security.actionCreators'
import { SecurityDataState } from '../models/security.interface'
import { Status } from '../../globalConstants'
import { successUpdatePassword, failUpdatePassword } from '../actionCreators/security.actionCreators'

type Action = ActionType<typeof actions>

export const initialState: SecurityDataState = {
    status: '',
    errCode: '',
}

export const securityReducer = (state: SecurityDataState = initialState, action: Action): SecurityDataState => {
    switch (action.type) {
        case getType(successUpdatePassword): {
            return {
                ...state,
                status: Status.success,
            }
        }
        case getType(failUpdatePassword): {
            return {
                ...state,
                status: Status.failed,
                errCode: action.payload,
            }
        }
        default:
            return state
    }
}
