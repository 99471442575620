import { ProductDataTypeObj } from '../models/productData.interface'
import {
    PriceAvailabilityData,
    PriceAvailabilityDataResponse,
} from '../../services/priceAvailabilityService/priceAvailabilityService.type'

/**
 * @description utility function which merge price availability data with products
 * @param {PriceAvailabilityDataResponse} priceAvailabilityData
 * @param {ProductDataTypeObj[]} products
 * @return {ProductDataTypeObj[]} merged prices and products
 */
const mergePriceAvailabilityDataWithProducts = (
    priceAvailabilityData: PriceAvailabilityDataResponse,
    products: ProductDataTypeObj[],
): ProductDataTypeObj[] => {
    const skusPriceAvailabilityMap = new Map<string, PriceAvailabilityData>(
        priceAvailabilityData?.skus?.map(sku => [sku.code, sku]),
    )
    const productsPriceAvailabilityMap = new Map<string, PriceAvailabilityData>(
        priceAvailabilityData?.products?.map(product => [product.code, product]),
    )

    return products?.map(product => {
        const skus = (product?.skus || []).map(sku => ({
            ...sku,
            ...(skusPriceAvailabilityMap.get(sku.code) || {}),
            badges: sku?.badges?.concat(skusPriceAvailabilityMap.get(sku.code)?.badges || []),
        }))

        const productPriceAvailability = productsPriceAvailabilityMap.get(product.code) || ({} as PriceAvailabilityData)
        return {
            ...product,
            ...productPriceAvailability,
            badges: product?.badges?.concat(productPriceAvailability?.badges || []),
            skus,
        }
    })
}

export default mergePriceAvailabilityDataWithProducts
