import { PREFIX } from '../../config'
import { IN_GRID_MAX_SPONSORED_ADS } from '../../services/criteoService/criteoService.constants'
import { ProductDataTypeObj } from '../models/productData.interface'

/**
 * Counts the number of columns in the grid view by examining the number of items in the first row.
 * @returns {number} - The number of columns in the grid. Returns 0 if the grid view is not found or is empty.
 */
const countGridColumns = (): number => {
    const gridView = document.querySelector(`.${PREFIX}-product-card`)?.querySelector(`.${PREFIX}-product__grid-view`)
    if (!gridView) return 0

    const htmlCollection = gridView.children
    if (htmlCollection.length === 0) return 0

    const items = Array.from(htmlCollection) as HTMLElement[]

    const currentTop = items[0].offsetTop

    const firstRow = items.filter(item => item.offsetTop === currentTop)

    return firstRow.length
}

/**
 * Merges grid ads and product cards into a single array, ensuring ads are
 * distributed according to the number of columns in the grid and removing
 * duplicates before merging.
 * @param {ProductDataTypeObj[]} inGridAds - Array of ad objects to be placed in the grid. (Sponsored)
 * @param {ProductDataTypeObj[]} organicProducts - Array of product card objects to be placed in the grid. (Organic Products)
 * @returns {ProductDataTypeObj[]} - The merged array of ads and product cards.
 */
const mergeSponsoredProducts = (
    inGridAds: ProductDataTypeObj[],
    organicProducts: ProductDataTypeObj[],
): ProductDataTypeObj[] => {
    const numberOfColumns = countGridColumns()

    const products = organicProducts.filter(product => !product.isSponsored)
    const maxInGridAds = inGridAds.slice(0, IN_GRID_MAX_SPONSORED_ADS).map(item => ({ ...item, isSponsored: true }))
    const totalAds = maxInGridAds.length

    if (!totalAds || !numberOfColumns) {
        return organicProducts
    }

    let result: ProductDataTypeObj[] = []

    if (totalAds >= numberOfColumns) {
        if (numberOfColumns === 4) {
            // Insert all in the first row if there are enough columns
            result = [...maxInGridAds.reverse(), ...products]
        } else if (numberOfColumns === 3) {
            // Insert 3 ads in the first row and 1 ad in the fourth row
            const firstRowAds = maxInGridAds.slice(0, numberOfColumns).reverse()
            const remainingAds = maxInGridAds.slice(numberOfColumns).reverse()

            const beforeFourthRow = products.slice(0, 8)
            const afterFourthRow = products.slice(8)
            result = [...firstRowAds, ...beforeFourthRow, ...remainingAds, ...afterFourthRow]
        } else if (numberOfColumns === 2) {
            // Insert 2 ads in the first row and 2 ads in the fourth row
            const firstRowAds = maxInGridAds.slice(0, numberOfColumns).reverse()
            const remainingAds = maxInGridAds.slice(numberOfColumns).reverse()

            const beforeFourthRow = products.slice(0, 4)
            const afterFourthRow = products.slice(4)
            result = [...firstRowAds, ...beforeFourthRow, ...remainingAds, ...afterFourthRow]
        }
    } else {
        // Insert fewer ads than the number of columns available
        const columnsLeftAfterAds = numberOfColumns - totalAds
        const firstFew = products.slice(0, columnsLeftAfterAds > 0 ? columnsLeftAfterAds : 0)
        const afterAds = products.slice(columnsLeftAfterAds > 0 ? columnsLeftAfterAds : 0)
        const adsReversed = maxInGridAds.reverse()
        result = [...firstFew, ...adsReversed, ...afterAds]
    }

    return result
}

export default mergeSponsoredProducts
