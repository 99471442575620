import { BaseService } from '../base.service'
import { getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import { LookupResults } from '../../redux/models/digitalFlyer.interface'

const environment = getEnvironment()
const httpClient = getHttpClient()

/**
 * Function to trigger contact us api service
 * @class
 * @extends BaseService
 */
class DigitalFlyerService extends BaseService {
    /**
     * get Digital FlyerProduct
     * @param  {number} sku
     * @param { string } storeId
     * @return {LookupResults}
     */
    getDigitalFlyerProduct(sku: number, storeId: string): Promise<{ data: LookupResults }> {
        return httpClient.apiGet(this.createDigitalFlyerProductURL(sku, storeId).toString())
    }

    /**
     * function to create Data flyer url
     * @param {number} sku
     * @param { string } storeId
     * @return {URL}
     */
    createDigitalFlyerProductURL(sku: number, storeId: string): URL {
        const {
            API_BASE_URL,
            API_ENDPOINTS: { digitalFlyerData },
        } = environment
        const locale = DigitalFlyerService.language
        const endPoint = `${API_BASE_URL}${digitalFlyerData}`
        return new URL(`${endPoint}?id=${sku}&lang=${locale}&storeId=${storeId}`)
    }
}

const digitalFlyerService = new DigitalFlyerService()

export { digitalFlyerService }
export default digitalFlyerService
