export enum seoHelperConstants {
    defaultHreflang = 'x-default',
    canadaSuffix = '-ca',
    noIndexNoFollow = 'noindex, nofollow',
    noIndex = 'noindex',
    nofollow = 'nofollow',
}

export interface SpecificPageTypes {
    isPdpPage: boolean
    isCategoryPage: boolean
    isStorePage: boolean
}

/**
 * enum for SEO Schema Types
 */
export enum SEOSchemaTypes {
    Product = 'Product',
    Brand = 'Brand',
    Offer = 'Offer',
    aggregateOffer = 'aggregateOffer',
    BreadcrumbList = 'BreadcrumbList',
    ListItem = 'ListItem',
    GeoCoordinates = 'GeoCoordinates',
    OpeningHoursSpecification = 'OpeningHoursSpecification',
    PostalAddress = 'PostalAddress',
    ItemList = 'ItemList',
    SiteNavigationElement = 'SiteNavigationElement',
    LocalBusiness = 'LocalBusiness',
}

/**
 * Interface for General Schema Tag
 * @interface
 */
export interface SEOSchema {
    '@context': string
    '@type': string | unknown
    itemListElement?: {
        '@type': string
        name: string
        position: string
        url?: string
        item?: string
    }[]
}

/**
 * Interface for Department Store Schema Tag
 * @interface
 */

export interface DepartmentStoreSchema extends SEOSchema {
    name: string
    url: string
    hasMap: string
    geo: {
        '@type': string
        latitude: number
        longitude: number
    }
    openingHoursSpecification: {
        '@type': string
        dayOfWeek?: string
        opens: string
        closes: string
        validFrom?: string
        validThrough?: string
    }[]
    image: string
    address: {
        '@type': string
        streetAddress: string
        addressLocality: string
        addressRegion: string
        postalCode: string
        addressCountry: string
    }
    telephone: string
}

/**
 * Interface for LocalBusiness Store Schema Tag
 * @interface
 */

export interface LocalBusinessSchema extends SEOSchema {
    name: string
    address: {
        '@type': string
        postalCode: string
        streetAddress: string
        addressCountry: string
        addressRegion: string
        addressLocality: string
    }
    image: string
    telephone: string
}

/**
 * enum for Product Availability only Used for SEO Purpose
 */
export enum SEOProductAvailability {
    Instock = 'InStock',
    OutOfStock = 'OutOfStock',
}

/**
 * Interface for Product Schema Tag
 * @interface
 */
export interface ProductSchema extends SEOSchema {
    '@id': string
    name: string
    description: string
    brand: {
        '@type': string
        name: string
    }
    image: string
    offers?: {
        '@type': string
        price?: string
        priceValidUntil?: string
        priceCurrency?: string
        itemCondition?: string
        availability?: string
        availableAtOrFrom?: {
            branchCode: string
        }
    }
    sku?: string
    mpn?: string[] | string
}
