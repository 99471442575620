import React from 'react'

import { PREFIX } from '../config'
import Button from '../Button'
import { magicNumber } from '../../utils'
import { Price } from '../Price/Price.types'
import { ProductCardType, ProductDataDTO } from '../..'

const cartClass = `${PREFIX}-shopping-cart__item`

/**
 * Function to return Buy it Again CTA
 * @param {string} label
 * @param {string} url
 * @param {string} productname
 * @return {string} return additional param string value
 */
export const getBuyItAgainCta = (label: string, url?: string, productname?: string): JSX.Element => {
    return (
        <div className={`${cartClass}__buy-again-button ${PREFIX}-row`}>
            <Button
                id="buy-again-button"
                type="secondary"
                size="mini"
                ariaLabel={label + ': ' + productname}
                onClick={() => {
                    window.location.href = encodeURI(url)
                }}>
                {label}
            </Button>
        </div>
    )
}

/**
 * function to render product price
 * @param {number} quantity
 * @param {Price} currentprice
 * @param {Price} totalprice
 * @return {number}
 */
export const renderItemPrice = (quantity: number, currentprice: Price, totalprice: Price): Price => {
    return quantity > magicNumber.ONE ? totalprice : currentprice
}

export enum productWheelTypes {
    Tire = 'TIRE',
    Wheel = 'WHEEL',
    WinterTire = 'WINTER_TIRE',
}

export const isProductTire = (product: ProductDataDTO | ProductCardType): boolean =>
    product?.productWheelType &&
    (product?.productWheelType?.toUpperCase() === productWheelTypes.Tire ||
        product?.productWheelType?.toUpperCase() === productWheelTypes.WinterTire)
        ? true
        : false

export const isProductWheel = (product: ProductDataDTO): boolean => {
    return product?.productWheelType && product?.productWheelType?.toUpperCase() === productWheelTypes.Wheel
}

/**
 * function to return true when product is tire or wheel
 * @param {number} quantity
 * @param {Price} currentprice
 * @param {Price} totalprice
 * @return {number}
 */
export const isTireOrWheelProduct = (product: ProductDataDTO | ProductCardType): boolean => {
    return isProductTire(product) || isProductWheel(product as ProductDataDTO)
}
