import { createAction } from 'typesafe-actions'

import {
    SET_SIGN_IN_FOR_SALES_ALERTS,
    RESET_SIGN_IN_FOR_SALES_ALERTS,
} from '../types/addToSalesAlerts/addToSalesAlerts.actionTypes.constant'
import { SignInForSalesAlerts } from '../models/addToSalesAlerts.interface'

export const setSignInForSalesAlertsAction = createAction(
    SET_SIGN_IN_FOR_SALES_ALERTS,
    (payload: SignInForSalesAlerts) => payload,
)<SignInForSalesAlerts>()
export const resetSignInForSalesAlertsAction = createAction(
    RESET_SIGN_IN_FOR_SALES_ALERTS,
    (payload: SignInForSalesAlerts) => payload,
)<SignInForSalesAlerts>()
