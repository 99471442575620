import { RootState } from '../reducers'
import {
    GlobalAPIProps,
    IAccessibility,
    IAccountDashboard,
    IAuthentication,
    IFeatureFlag,
    IGeneral,
    IGlobalLinks,
    IGlobalProps,
    IMyAccountFormValidation,
    ICart,
    IAutomotive,
    IAutoAppointment,
    IAppointmentAvailability,
} from '../models/commonContent.interface'
import { CommonContentState } from '../reducers/commonContent.reducer'
import { CategoryBreadCrumb } from '../models/category.interface'

const EMPTY_OBJECT = {}

export const commonContentAvailableSelector = (state: RootState): IGlobalProps =>
    state.commonContent.commonContentAvailable

export const appointmentAvailability = (state: RootState): IAppointmentAvailability =>
    commonContentAvailableSelector(state)?.appointmentAvailability || EMPTY_OBJECT

export const autoAppointmentSelector = (state: RootState): IAutoAppointment =>
    commonContentAvailableSelector(state)?.autoAppointment || EMPTY_OBJECT

export const autoAppointmentEditSelector = (state: RootState): string | undefined =>
    commonContentAvailableSelector(state)?.autoAppointment?.edit

// default should be created each time due to call of selector - it will cause an extra call of useEffect that depends on this selectors result
export const commonContentSelector = (state: RootState): CommonContentState => state.commonContent || EMPTY_OBJECT

export const commonAPIContentAvailableSelector = (state: RootState): GlobalAPIProps =>
    state.commonContent.commonAPIContentAvailable

export const automotiveSelector = (state: RootState): IAutomotive =>
    state.commonContent.commonContentAvailable.automotive || EMPTY_OBJECT

export const offRoadRatedLabelSelector = (state: RootState): string =>
    state.commonContent.commonContentAvailable.automotive?.offRoadRatedLabel || 'Off-Road Rating'

export const browseOnlyModeSelector = (state: RootState): boolean => state.browseOnly?.enable

export const enableMPNSearchPreselectionSelector = (state: RootState): boolean =>
    state.commonContent?.commonContentAvailable?.featureFlag?.enableMPNSearchPreselection || false

export const enableNewVehicleApiEndpointSelector = (state: RootState): boolean =>
    state.commonContent?.commonContentAvailable?.featureFlag?.enableNewVehicleApiEndpoint || false

export const plpBreadcrumbListSelector = (state: RootState): CategoryBreadCrumb[] => state.categoryIdData.breadcrumbList

export const featureFlagSelector = (state: RootState): IFeatureFlag =>
    state.commonContent.commonContentAvailable.featureFlag || EMPTY_OBJECT

export const noSearchResultsLabelSelector = (state: RootState): string =>
    state.commonContent?.commonContentAvailable?.general?.noSearchResults || ''

export const singleSearchResultLabelSelector = (state: RootState): string =>
    state.commonContent?.commonContentAvailable?.general?.singleSearchResult || ''

export const manySeachResultsLabelSelector = (state: RootState): string =>
    state.commonContent?.commonContentAvailable?.general?.manySearchResults || ''

export const clearButtonLabelSelector = (state: RootState): string =>
    state.commonContent?.commonContentAvailable?.general?.clearLabel || ''

export const jumplistPlaceholderTemplateSelector = (state: RootState): string =>
    state.commonContent?.commonContentAvailable?.general?.enterLabel || ''

export const editLabelSelector = (state: RootState): string =>
    state.commonContent.commonContentAvailable.general?.editLabel || ''

export const cancelLabelSelector = (state: RootState): string =>
    state.commonContent.commonContentAvailable.general?.cancelLabel || ''

export const changeLabelSelector = (state: RootState): string =>
    state.commonContent.commonContentAvailable.general?.changeLabel || ''

export const accessibilitySelector = (state: RootState): IAccessibility =>
    state.commonContent.commonContentAvailable.accessibility || EMPTY_OBJECT

export const closeLabelSelector = (state: RootState): string =>
    state.commonContent.commonContentAvailable.general?.closeLabel || ''

export const a11yCloseIconLabelSelector = (state: RootState): string =>
    state.commonContent.commonContentAvailable.accessibility?.a11yCloseIconLabel || ''

export const a11yRequiredLabelSelector = (state: RootState): string =>
    state.commonContent.commonContentAvailable.accessibility?.a11yRequiredLabel || ''

export const a11yTooltipIconSelector = (state: RootState): string =>
    state.commonContent.commonContentAvailable.accessibility?.a11yTooltipIcon || ''

export const myAccountFormValidationSelector = (state: RootState): IMyAccountFormValidation =>
    state.commonContent.commonContentAvailable.myAccountFormValidation || EMPTY_OBJECT

export const partiallyAuthScreenIdSelector = (state: RootState): string =>
    state.commonContent.commonContentAvailable.authentication?.partiallyAuthScreenId || ''

export const commonContentCartSelector = (state: RootState): ICart =>
    state.commonContent.commonContentAvailable.cart || EMPTY_OBJECT

export const continueLabelSelector = (state: RootState): string =>
    state.commonContent.commonContentAvailable.cart?.continueLabel || ''

export const globalLinksSelector = (state: RootState): IGlobalLinks =>
    state.commonContent.commonContentAvailable.globalLinks || EMPTY_OBJECT

export const homePageLinkSelector = (state: RootState): string =>
    state.commonContent.commonContentAvailable.globalLinks?.homePageLink || ''

export const orderDetailsLinkSelector = (state: RootState): string =>
    state.commonContent.commonContentAvailable.globalLinks?.orderDetailsLink || ''

export const packagesSelector = (state: RootState): Record<string, string> =>
    state.commonContent.commonContentAvailable.packages || EMPTY_OBJECT

export const tiresWheelsPackagesLabelSelector = (state: RootState): string =>
    state.commonContent.commonContentAvailable.packages?.tiresWheelsPackagesLabel || ''

export const authenticationSelector = (state: RootState): IAuthentication =>
    state.commonContent.commonContentAvailable.authentication || EMPTY_OBJECT

export const generalSelector = (state: RootState): IGeneral =>
    state.commonContent.commonContentAvailable.general || EMPTY_OBJECT

export const accountDashboardSelector = (state: RootState): IAccountDashboard =>
    state.commonContent.commonContentAvailable.accountDashboard || EMPTY_OBJECT
