import { pageTypes } from '../config'
import getPageType from '../utils/getPageType'
import { FitmentTypeEnum } from '../globalConstants'
import { checkDataLength } from '../components/Accounts/Addresses/checkDataLength'
import { CategoryResponseById } from '../redux/models/category.interface'
import { GlobalOnloadAnalyticData } from '../components/Accounts/OrderHistory/GlobalPageLoadAnalytics.type'
import { CategoryPagesType } from '../global.type'

/**
 * check if given page is automotive
 * @return {boolean} returns true if current page is (PDP/PLP/Product/srp/dlp)
 */
export const checkIsAutomotivePage = ({ productData, categoryData }: Partial<GlobalOnloadAnalyticData>): boolean => {
    const pageType = getPageType()
    if (pageType === pageTypes.plp) {
        return categoryData?.isFitmentRequired as boolean
    } else if (
        pageType === pageTypes.pdpPage &&
        productData?.productData?.fitmentTypeCode &&
        productData?.productData?.fitmentTypeCode !== FitmentTypeEnum.UNIVERSAL_FIT_01
    ) {
        return true
    } else if (pageTypes.categoryPages.includes(pageType as CategoryPagesType)) {
        return true
    }
    return false
}

/**
 *
 * @param {CategoryResponseById} categoryData
 * @param {Record<string, unknown>} storeData
 * @return {boolean} checking if we have catogory and store data loaded and current page is category1, category2 etc
 */
export const isPLPPageReady = (categoryData: CategoryResponseById, storeData: Record<string, unknown>): boolean => {
    const pageType = getPageType()
    return (
        pageType !== pageTypes.checkout &&
        pageTypes.categoryPages.includes(pageType as CategoryPagesType) &&
        checkDataLength(categoryData) &&
        checkDataLength(storeData)
    )
}
