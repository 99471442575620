/**
 * Interface for CardInputProps
 * @interface
 */
export enum CardType {
    loyalty = 'loyalty',
    master = 'master',
    linkmaster = 'master-link',
}

export enum InlineValidationStyle {
    error = 'error',
    success = 'success',
    informative = 'informative',
}

export interface InlineValidation {
    msg?: string
    icon?: string
    style?: string | InlineValidationStyle
    path?: string
}

export interface CardInputTooltip {
    showTooltip?: boolean
    tooltipHeaderText?: string
    tooltipBodyText?: string
}
export interface CardInputProps {
    id: string
    label?: string
    cardType?: 'loyalty' | 'master' | string | CardType
    path?: string
    value?: string
    onChange?: (value) => void
    cardImage?: string
    cardImageAltText?: string
    inlineValidation?: InlineValidation
    tooltip?: CardInputTooltip
    iconSize?: string
}

export interface CreditCardType {
    type: string
    logo: string
}
