import { createAction } from 'typesafe-actions'

import {
    FETCH_CATEGORY_REQUEST,
    FETCH_DATA_USING_CATEGORY_ID,
    FETCH_DATA_USING_CATEGORY_ID_ERROR,
    UPDATE_CATEGORY_ID_DATA,
} from '../types/category/category.actionTypes.constant'
import { CategoryResponseById, CategoryResponseErrorDTO } from '../models/category.interface'

export const fetchCategoryIdAction = createAction(
    FETCH_DATA_USING_CATEGORY_ID,
    (payload: CategoryResponseById) => payload,
)<CategoryResponseById>()

export const fetchCategoryIdErrorAction = createAction(
    FETCH_DATA_USING_CATEGORY_ID_ERROR,
    (payload: CategoryResponseErrorDTO) => payload,
)<CategoryResponseErrorDTO>()

export const updateCategoryIdData = createAction(
    UPDATE_CATEGORY_ID_DATA,
    (payload: Partial<CategoryResponseById>) => payload,
)<CategoryResponseById>()

export const fetchCategoryIdRequestAction = createAction(FETCH_CATEGORY_REQUEST)()
