/**
 * @deprecated This module is depreciated for future development please use top level module with provider approach.
 */
// TODO: This http client need to removed as we have new one on top level of folder hierarchy.

import axios, { AxiosPromise } from 'axios'
import GlobalPropsHelper from '../../analytics/helpers/globalProps/globalProps.helper'
import { getEnvironment } from '../../environments'
import { API_BASE_URL, EXT_SYSTEM_URLS } from './../../environments/apiConfig'
import { CustomAxiosRequestConfig, HttpReqHeaders } from './httpClient.type'
import { httpErrorInterceptor } from './interceptors/errorInterceptors/httpErrorInterceptor'
import { httpResponseInterceptor } from './interceptors/responseInterceptors/httpResponseInterceptor'
import { setRequestHeadersToAllowedDomains } from '@nl/lib/src/utils/HttpClientUtils/axiosHttpClientUtils'
import { modifyHeadersConfigForBrowseOnlyMode } from '../../utils/HttpClientUtils/axiosHttpClientUtils'

export interface RequestBodyType {
    [key: string]: unknown
}

// Add external systems' names here
export type ExternalSystemNameType =
    | 'CERTONA'
    | 'BLOOMREACH'
    | 'AEM_MOCK_JSON'
    | 'CDS_ON_PREM'
    | 'AEM_EXP_FRAG'
    | 'BLOOMREACH_SEARCH'
    | 'CANADA_POST'
    | 'AEM_COMMON_API'
    | 'CDS_EXP_CART'
    | 'CDS_PREFERENCE_ODP'
    | 'CDS_CLOUD'

type GlobalConfigs = {
    'apim-domain'?: string
    bannerId?: string
}

type CustomAxiosStatic = { defaults: { headers: { post: { 'Content-Type': string } } } }

const globalProps = new GlobalPropsHelper()
const configs = globalProps.init() as GlobalConfigs

;(axios as CustomAxiosStatic).defaults.headers.post['Content-Type'] = 'application/json'

export const securedRequestInstance = axios.create({
    baseURL: API_BASE_URL,
})

const environment = getEnvironment()
securedRequestInstance.interceptors.request.use((config: CustomAxiosRequestConfig) => {
    const jwt = localStorage.getItem('gigya.JWT') as string
    const domainsConfig = globalProps.readAllowedDomainsWithHeadersConfig()
    const headers = config.headers as Record<string, unknown>
    if (!config.headers) config.headers = {}
    modifyHeadersConfigForBrowseOnlyMode(config, domainsConfig)
    setRequestHeadersToAllowedDomains(environment, configs, domainsConfig, config.url as string, jwt, headers)
    return config
})

export const paramSerializer = (baseEndPoint: string, paramObj?: RequestBodyType): string => {
    if (!paramObj) {
        return baseEndPoint
    }
    const reducer = (accumulator: string, currVal: string, index: number): string =>
        `${accumulator}${index === 0 ? '?' : '&'}${currVal}=${paramObj[currVal] as string}`
    return Object.keys(paramObj).reduce(reducer, baseEndPoint)
}

export const apiGet = (
    url: string,
    params?: RequestBodyType,
    systemName?: ExternalSystemNameType,
    headers?: HttpReqHeaders,
    withCredentials = false,
): AxiosPromise => {
    const finalURL = paramSerializer(url, params)
    if (systemName) {
        const apiDomain = systemName === 'CDS_CLOUD' ? configs?.['apim-domain'] : ''
        return securedRequestInstance.get(finalURL, {
            baseURL: `${apiDomain as string}${EXT_SYSTEM_URLS[systemName]}`,
            headers,
            withCredentials: withCredentials,
        })
    }
    return headers ? securedRequestInstance.get(finalURL, { headers }) : securedRequestInstance.get(finalURL)
}

export const apiPost = (
    url: URL | string,
    requestBody?: RequestBodyType | string,
    systemName?: ExternalSystemNameType,
    headers?: HttpReqHeaders,
    withCredentials = false,
): AxiosPromise => {
    if (systemName) {
        return securedRequestInstance.post(url as string, requestBody, {
            baseURL: EXT_SYSTEM_URLS[systemName],
            headers,
            withCredentials: withCredentials,
        })
    }
    return securedRequestInstance.post(url as string, requestBody)
}

export const apiPut = (
    url: string,
    requestBody?: RequestBodyType,
    systemName?: ExternalSystemNameType,
    headers?: HttpReqHeaders,
    withCredentials = false,
): AxiosPromise => {
    if (systemName) {
        return securedRequestInstance.put(url, requestBody, {
            baseURL: EXT_SYSTEM_URLS[systemName],
            headers,
            withCredentials: withCredentials,
        })
    }
    return securedRequestInstance.put(url, requestBody)
}

export const apiDelete = (
    url: string,
    requestBody?: RequestBodyType,
    systemName?: ExternalSystemNameType,
    headers?: HttpReqHeaders,
): AxiosPromise => {
    if (systemName) {
        return axios.delete(url, { data: requestBody, headers, baseURL: EXT_SYSTEM_URLS[systemName] })
    }
    return securedRequestInstance.delete(url, { data: requestBody })
}

securedRequestInstance.interceptors.response.use(
    response => {
        return new Promise(resolve => {
            httpResponseInterceptor(response, resolve)
        })
    },
    (error: { config: { headers: Record<string, string> } }) => {
        return new Promise((resolve, reject) => {
            if (error?.config?.headers?.['X-skip-default-error-handling'] !== 'true') {
                httpErrorInterceptor(error, resolve, reject)
            } else {
                reject(error)
            }
        })
    },
)

export default {
    apiGet,
    apiPost,
    apiPut,
    apiDelete,
}
