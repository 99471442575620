export const successStatusCode = 200 // success status code
export const addToCartFailureCode = 'addToCartFailureError' // add to cart api failure error code
export const addToWishlistInactiveProductError = 'validation.product.wrongStatus'
export const addToCartErrorCodes = ['maxQuantityExceedError', 'availableQuantityExceedError']
export const addToCartErrorsMap = new Map([
    ['validation.product.bopisOnly', 'bopisOnlyProductError'],
    ['validation.product.endless_aisle', 'vendorOnlyProductError'],
    ['validation.product.sth_only', 'onlineOnlyProductError'],
    ['validation.product.quantityMismatch', 'maxQuantityExceedError'],
    ['validation.product.soldout', 'notSellableProductError'],
    [addToWishlistInactiveProductError, addToCartFailureCode],
])
export const errorCodeForInventoryError = 'error.cart.validation.entry.lowStock'
export const heliumInflationGlobalToggleOffErrorCode = '04000'
export const heliumInflationStoreToggleOffErrorCode = '04100'
export const listOfErrorCodes = [
    'validation.product.bopisOnly',
    'error.cart.validation.entry.lowStock',
    'error.cart.validation.entry.quantityRestriction',
    'validation.product.quantityMismatch',
    'validation.product.sth_only',
    'error.cart.validation.pointOfServiceModel.mismatch',
    'validation.product.soldout',
    'error.cart.add.cart.error',
    'error.cart.conversion.cart.error',
    'validation.storeName.invalid',
    'validation.product.endless_aisle',
    'error.cart.find.inventory.notFound',
    addToWishlistInactiveProductError,
]
export const fetchNextSetOfStores = 4
export const maxCount = 1

// media constants
export const mediaConstants = {
    imageAndVideo: 'image and video',
    image: 'image',
    video: 'video',
}
// corporate status Constants
export const corporateConstants = {
    fullyDiscontinued: 'fully discontinued',
    seasonallyDiscontinued: 'seasonally discontinued',
    temporaryDiscontinued: 'temporary discontinued',
    outOfStock: 'out of stock',
    discontinued: 'discontinued',
    activeStatus: 'active',
}
// fulfillment Constants
export const fulfillmentConstants = {
    limited: 'limited',
    restricted: 'restricted',
    sellable: 'sellable',
    bopisAndSth: 'bopis and sth',
    bopisOnly: 'bopis only',
    sthOnly: 'sth only',
    inStoreOnly: 'in-store only',
    unavailable: 'unavailable',
}

export const homeServiceConstants = {
    service: 'service',
    generalMerch: 'general merch',
    noInstallationAvailable: 'no',
}

export const firstVariantKey = 'firstVariant'
export const secondVariantKey = 'secondVariant'
export const thirdVariantKey = 'thirdVariant'

export const variantLength = 1

export const maxQuantitySelected = 4

export enum StoresProvinceList {
    QUBEC = 'QC',
}

export const installationAvailable = {
    yes: 'yes',
    no: 'no',
    notApplicable: 'not applicable',
}

export const setOfFourCount = 4

export const guranteedFitments = {
    true: 'true',
    false: 'false',
    undefined: 'undefined',
    notApplicable: 'not applicable',
}

export const roadRatedSpecification = {
    psSnowPeakRated: 'PS_Snow_Peak_Rated',
    psMudSnowRated: 'PS_Mud_Snow_Rated',
    psRatedLowResistance: 'PS_Low_Rolling_Res_Ind',
    psAcceptsStuds: 'PS_Accepts_Studs',
}

export const COLOR = 'COLOUR'
export const SIZECHART_CROSS_BRAND = 'sizechartcrossbrand'
export const URL_PATH_SEPARATOR = '.'
export const loc = 'loc'
export const mrRID = 'mrRID'
export const rq = 'rq'

export enum locParameterValues {
    myReg = 'myreg',
    plp = 'plp',
    srp = 'srp',
}

export const recommendationCarouselNumberOfItems = 2
export const failureToastIcon = 'ct-toast-error'
export const cautionToastIcon = 'ct-notification-caution'
