import BaseService from '../base.service'
import { getHttpClient } from '../../httpClient'
import { getEnvironment } from '../../environments'
import { RequestBodyType } from '../../httpClient/client.type'
import { ShareCartTemplate } from '../../redux/models/sharedCart.interface'
import { isArrayNotEmpty } from '@nl/lib'
import appCacheService from '../../utils/appCacheService'
import { convertToMiniCartData } from '../../utils/convertToMiniCartData'
import { CartItemsData } from '../../redux/models/cart.interface'

const environment = getEnvironment()
const httpClient = getHttpClient()
const STORE_NAME_PARAMETER = 'storeName'
const DELIVERY_CODE_MODE_PLACEHOLDER = '{deliveryModeCode}'
const LANG_PARAM = 'lang'
const CART_PARAM = 'guid'
const CART_ID_PARAM = 'cartId'

/**
 * Shared Cart service. All shared cart related server interaction will be done by this service
 */
class SharedCartService extends BaseService {
    /**
     * Function to create url for generate share cart link
     * @param {string} cartIdentifier
     * @return {URL}
     */
    private createSharedCartGenerateLinkUrl(cartIdentifier: string): URL {
        const path = environment.API_ENDPOINTS.generateSharedCartLink.replace('{cartId}', cartIdentifier)
        const url = SharedCartService.createUri(path)
        url.searchParams.append(LANG_PARAM, SharedCartService.language)
        return url
    }

    /**
     * Function to create url for generate share cart link
     * @param {string} templateId
     * @return {URL}
     */
    private createGetSharedCartTemplateUrl(templateId: string): URL {
        const path = environment.API_ENDPOINTS.getSharedCartTemplate.replace('{templateCode}', templateId)
        return SharedCartService.createUri(path)
    }

    /**
     * Function to create url for generate share cart link
     * @param {string} customerCartIdentifier
     * @param {string} storeId
     * @return {URL}
     */
    private createMergeCartsUrl(customerCartIdentifier: string, storeId: string): URL {
        const url = SharedCartService.createUri(environment.API_ENDPOINTS.mergeCarts)
        url.searchParams.append(STORE_NAME_PARAMETER, storeId)
        url.searchParams.append(CART_PARAM, customerCartIdentifier)
        return url
    }

    /**
     * Generate share cart link
     * @param {string} cartIdentifier
     * @return {string} generatedLink
     */
    generateShareCartLink(cartIdentifier: string): Promise<{ data: { link: string } }> {
        const url: URL = this.createSharedCartGenerateLinkUrl(cartIdentifier)

        return httpClient.apiPost(url.toString(), {}, {}, true)
    }

    /**
     * Function to get products
     * @param {string} templateId
     * @return {URL}
     */
    getSharedCartTemplate(templateId: string): Promise<{ data: ShareCartTemplate }> {
        const url: URL = this.createGetSharedCartTemplateUrl(templateId)

        return httpClient.apiGet<ShareCartTemplate>(url.toString(), {}, {}, true)
    }

    /**
     * Function to create url for retrieving One Time Cart
     * @param {string} responseType
     * @param {string} storeId
     * @return {URL}
     */
    private createStoreInitiatedCartURL(responseType: string, storeId: string): URL {
        const pathWithQueryParams = environment.API_ENDPOINTS.getStoreInitiatedCart.replace(
            '{responseType}',
            responseType,
        )
        const url = SharedCartService.createUri(pathWithQueryParams)
        url.searchParams.append(STORE_NAME_PARAMETER, storeId)
        return url
    }

    /**
     * Function to get One Time Cart
     * @param {string} templateCode
     * @param {string} storeId
     * @param {string[]} selectedEntries
     * @return {Promise}
     */
    getStoreInitiatedCart(templateCode: string, storeId: string, selectedEntries: string[] = []) {
        const url: URL = this.createStoreInitiatedCartURL('DEFAULT', storeId)
        const body: RequestBodyType =
            selectedEntries.length === 0 ? { templateCode } : { templateCode, selectedEntries }
        return httpClient.apiPost<CartItemsData>(url.toString(), body, {}, true)
    }

    /**
     * Function to create url for changing One Time Cart items delivery mode
     * @param {string} cartId
     * @param {string} deliveryMode
     * @return {URL}
     */
    private createChangeStoreInitiatedCartDeliveryModeURL(cartId: string, deliveryMode: string): URL {
        const pathWithQueryParams = environment.API_ENDPOINTS.changeStoreInitiatedCartDeliveryMode.replace(
            DELIVERY_CODE_MODE_PLACEHOLDER,
            deliveryMode,
        )
        const url = SharedCartService.createUri(pathWithQueryParams)
        url.searchParams.append(CART_ID_PARAM, cartId)
        return url
    }

    /**
     * Function to change One Time Cart items delivery mode
     * @param {string} cartId
     * @param {string} deliveryMode
     * @return {Promise}
     */
    changeStoreInitiatedCartDeliveryMode(cartId: string, deliveryMode: string) {
        const url: URL = this.createChangeStoreInitiatedCartDeliveryModeURL(cartId, deliveryMode)

        return httpClient.apiPut(url.toString(), {}, {}, true)
    }

    /**
     * Function to merge products
     * @param {string} customerCartIdentifier
     * @param {string} sharedCartCode
     * @param {string[]} sharedCartEntryCodes
     * @param {string} storeId
     * @return {URL}
     */
    mergeSharedCartWithUserCart(
        customerCartIdentifier: string,
        sharedCartCode: string,
        sharedCartEntryCodes: string[],
        storeId: string,
    ) {
        const url: URL = this.createMergeCartsUrl(customerCartIdentifier, storeId)
        const payload = {
            code: sharedCartCode,
            entryCodes: sharedCartEntryCodes,
        }

        return httpClient.apiPost<CartItemsData>(url.toString(), payload, {}, true).then(cartItemsResponse => {
            appCacheService.miniCartData.set(JSON.stringify(convertToMiniCartData(cartItemsResponse.data, true)))
            return cartItemsResponse
        })
    }

    /**
     * Function to create url for sending share cart link
     * @param {string} cartId
     * @return {URL}
     */
    private createSendingSharedCartLinkUrl(cartId: string): URL {
        const path = environment.API_ENDPOINTS.sendSharedCartLink.replace('{cartId}', cartId)
        const url = SharedCartService.createUri(path)
        url.searchParams.append(LANG_PARAM, SharedCartService.language)
        return url
    }

    /**
     * Send share cart link
     * @param {string} cartId
     * @param {string} sender
     * @param {string[]} emails
     * @param {string[]} phones
     * @return {void}
     */
    sendShareCartLink(cartId: string, sender: string, emails: string[], phones: string[]) {
        const url: URL = this.createSendingSharedCartLinkUrl(cartId)
        const body: RequestBodyType = { sender }
        isArrayNotEmpty(emails) ? (body.emails = emails) : (body.phones = phones)

        return httpClient.apiPost(url.toString(), body, {}, true)
    }
}

// Creating a singleton instance of service
const sharedCartService = new SharedCartService()

export { sharedCartService }
export default sharedCartService
