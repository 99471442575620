import { ProductHelper } from './../helpers/product.helper'
import { RebateData, staggeredSkuLength, Vehicle } from '@nl/lib'
import {
    CorporateStatus,
    FulfillmentDTOP,
    ProductOption,
    ProductResponseData,
    ProductSkusData,
    ProductSku as ProductSkus,
} from '../../redux/models/product.interface'
import { ProductSku, VariantDetails } from '../../components/BuyBox/BuyBox.type'
import { getProvider } from '../providers'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import {
    AnalyticsRecordInterface,
    Availability,
    FulfillmentOptionAvailable,
    ProductRecordInterface,
} from '../providers/provider.type'
import {
    checkProductType,
    checkMediaType,
    getFulfillmentOptionAvailable,
    getCorporateStatus,
    getFulfillmentDays,
    getRebateDetails,
    guranteedFitment,
    getPdpType,
    extractCorporateStatusBasedOnSku,
} from '../../components/BuyBox/BuyBox.helper'
import { firstVariantKey, secondVariantKey } from '../../components/BuyBox/BuyBox.constant'
import { installationAvailable } from '../../components/BuyBox/BuyBox.constant'
import { PriceVariant } from '../../components/BuyBox/BuyBox.type'
import { productWheelTypes } from '../../globalConstants'
import { checkDataLength } from '../../components/Accounts/Addresses/checkDataLength'
import { MagicNumber } from '../analytics.type'
import { isArrayNotEmpty } from '@nl/lib'
import { checkValueOrNull } from '../../utils/checkNotNullAndUndefined'

/**
 * this function fetches all code values from skus of a product
 * @param {ProductSku[]} skus
 * @return {string[]} returns skus code value
 */
const getSkuCodes = (skus: ProductSku[]): string[] => {
    const skuCodes: string[] = []
    skus.forEach((sku: ProductSku) => {
        skuCodes.push(sku?.code)
    })
    return skuCodes
}

/**
 * This function gets the variant details
 * @param {variantDetails} variantDetails
 * @param {string} selectedColor
 * @param {string} selectedSize
 * @param {string} selectedDynamicName
 * @param {ProductOption} options
 * @return {Record<string, unknown>} returns variant details
 */
export const getVariantDetails = (
    variantDetails: VariantDetails,
    selectedColor: string,
    selectedSize: string,
    selectedDynamicName: string,
    options: ProductOption[],
): Record<string, string | undefined> => {
    let variant1: string | undefined = undefined
    let variant2: string | undefined = undefined
    let variant3: string | undefined = undefined
    const variantsLength = 1
    const [firstVariant, secondVariant, thirdVariant] = options || []

    if (options?.length === variantsLength) {
        const [variant] = Object.entries(variantDetails)[0]
        if (variant === firstVariantKey) {
            variant1 = selectedColor ? `${firstVariant?.display} - ${selectedColor}` : ''
        } else if (variant === secondVariantKey) {
            variant1 = selectedSize ? `${secondVariant?.display} - ${selectedSize}` : ''
        } else {
            variant1 = selectedDynamicName ? `${thirdVariant?.display} - ${selectedDynamicName}` : ''
        }
    } else {
        Object.keys(variantDetails).forEach((variant: string) => {
            if (variant === firstVariantKey) {
                variant1 = selectedColor ? `${firstVariant?.display} - ${selectedColor}` : ''
            } else if (variant === secondVariantKey) {
                variant2 = selectedSize ? `${secondVariant?.display} - ${selectedSize}` : ''
            } else {
                variant3 = selectedDynamicName ? `${thirdVariant?.display} - ${selectedDynamicName}` : ''
            }
        })
    }
    return { variant1, variant2, variant3 }
}

/**
 *
 * @param {unknown} skuData
 * @param {string} productSkuCode
 * @param {string} language
 * @return {Record<string, number | string | undefined>}
 */
const getStaggeredProductDataBySku = (
    skuData: ProductSkus[],
    productSkuCode: string,
): Record<string, number | string | undefined> => {
    const skuDetails = skuData.find((sku: ProductSkus) => sku?.code?.toString() === productSkuCode.toString())
    if (checkDataLength(skuDetails)) {
        return {
            price: skuDetails?.currentPrice?.value?.toString() || 0,
            rebate: getRebateDetails(skuDetails?.rebate as RebateData),
        }
    }
    return {}
}
/**
 * returns analytics data object
 * @param {ProductResponseData} productData
 * @param {ProductSkusData} productSkuData
 * @param {VariantDetails } variantDetails
 * @param {string } selectedColor
 * @param {string } selectedSize
 * @param {string } selectedDynamicName
 * @param {boolean} inStockInSelectedStore
 * @param {boolean} isProductDiscontinued
 * @param {string} language
 * @param {boolean} financingEligible
 * @param {boolean} financingShown
 * @param {string} skuCode
 * @param {PriceVariant} getProductDataBySku
 * @param {FulfillmentDTOP} fulfillment
 * @return {AnalyticsRecordInterface} returns analytics data object
 * @param {boolean} isAutomotive
 * @param {Vehicle} vehicle
 * @param {boolean} staggeredFitment
 * @param {boolean} fitted
 * @param {string} productWheelType
 * @param {string} installationAvailability
 * @param {boolean} isShipToHome
 * @param {string} selectedProductCode
 * @param {string} isOutOfStockInAllStore
 * @param {boolean} isStoreEligibleForExpressDelivery
 */
// eslint-disable-next-line complexity
const getProductData = (
    productData: ProductResponseData,
    productSkuData: ProductSkusData,
    variantDetails: VariantDetails,
    selectedColor: string,
    selectedSize: string,
    selectedDynamicName: string,
    inStockInSelectedStore: () => boolean,
    inStockAtDC: () => boolean,
    isProductDiscontinued: () => boolean,
    language: string,
    financingEligible: boolean,
    financingShown: boolean,
    skuCode: string,
    getProductDataBySku: PriceVariant,
    fulfillment: FulfillmentDTOP,
    isAutomotive?: boolean,
    vehicle?: Vehicle,
    staggeredFitment?: boolean,
    fitted?: boolean,
    productWheelType?: string,
    installationAvailability?: string,
    isShipToHome?: boolean,
    selectedProductCode?: string,
    vehicleFitType?: string,
    isOutOfStockInAllStore?: boolean,
    isStoreEligibleForExpressDelivery?: boolean,
): AnalyticsRecordInterface => {
    const {
        videoList,
        images,
        badges,
        brand,
        name,
        code,
        ratingsCount,
        rating,
        sellable,
        messageCode,
        homeService,
        options,
        merchCategories,
    } = productData

    const { currentPrice, feeDisclaimerType, feeValue } = getProductDataBySku
    const corporateStatus = extractCorporateStatusBasedOnSku(productData, selectedProductCode)

    const breadcrumbCategories = productData.breadcrumbList
        .filter(breadcrumb => breadcrumb.categoryId)
        .map(breadcrumb => breadcrumb.label)
    const [level1, level2, level3, level4, level5] = merchCategories || breadcrumbCategories || []

    const { variant1, variant2, variant3 } = getVariantDetails(
        variantDetails,
        selectedColor,
        selectedSize,
        selectedDynamicName,
        options,
    )
    const hasYearMakeModel =
        vehicle?.autoAttributes?.make && vehicle?.autoAttributes?.year && vehicle?.autoAttributes?.model
    const hasOptionBody = vehicle?.autoAttributes?.body && vehicle?.autoAttributes?.option
    const partialVehicleJIT = hasYearMakeModel && !hasOptionBody

    const {
        event: { productDetailView },
        level: { auto, genMerch },
    } = analyticsAttributes
    const level6 = isAutomotive ? auto : genMerch
    const ror =
        productWheelType?.toUpperCase() === productWheelTypes.Wheel ||
        productWheelType?.toUpperCase() === productWheelTypes.Tire

    const getFee = (): string | undefined => {
        return feeDisclaimerType
            ? `${feeDisclaimerType?.toLowerCase().replace(/_/g, ' ')}: ${String(feeValue)}`
            : 'undefined'
    }

    const isExpress = String(
        fulfillment?.expressDelivery?.enabled && fulfillment?.storePickUp?.enabled && isStoreEligibleForExpressDelivery,
    )
    const productAnalyticsData = {
        availability: ProductHelper.checkAvailabilityOfProduct(inStockInSelectedStore, inStockAtDC) as Availability,
        category: (merchCategories || breadcrumbCategories)?.join('/'),
        level1,
        level2,
        level3,
        level4,
        level5,
        level6,
        fulfillmentOptionAvailable: getFulfillmentOptionAvailable(
            fulfillment,
            messageCode,
            sellable,
            homeService,
            isOutOfStockInAllStore,
        ) as FulfillmentOptionAvailable,
        badges: isArrayNotEmpty(badges) ? badges : 'null',
        corporateStatus: getCorporateStatus(
            corporateStatus,
            inStockInSelectedStore,
            isProductDiscontinued,
        ) as CorporateStatus,
        bulk: 'false',
        pdpType: getPdpType(isAutomotive as boolean, homeService as boolean),
        brand: brand?.label,
        fulfillmentDaysAway: checkValueOrNull(getFulfillmentDays(fulfillment, isShipToHome as boolean)),
        fee: checkValueOrNull(getFee()),
        productType: checkProductType(messageCode, sellable),
        installationAvailable: installationAvailability,
        inventory: checkValueOrNull(`${fulfillment?.availability?.quantity}`),
        name: name,
        pcode: code,
        price:
            currentPrice?.value?.toString() ||
            productData?.options?.[0]?.values?.[0]?.currentPrice?.value?.toString() ||
            0,
        rebate: checkValueOrNull(getRebateDetails(productData?.rebate as RebateData)),
        financingEligible: String(financingEligible && financingShown),
        financingShown: String(financingShown),
        rating: `${rating}`,
        numberOfReviews: `${ratingsCount}`,
        sku: checkValueOrNull(Boolean(Number(skuCode)) ? skuCode : selectedProductCode),
        variant1: checkValueOrNull(variant1),
        variant2: checkValueOrNull(variant2),
        variant3: checkValueOrNull(variant3),
        guranteedFitment: checkValueOrNull(guranteedFitment(vehicleFitType)),
        fitted: String(fitted),
        ror: String(ror),
        isExpress,
    }
    const autoProductAnalyticsData = [] as Record<string, unknown>[]
    const skuCodeList = !checkDataLength(productSkuData)
        ? [productData.code]
        : productSkuData?.skus.length === staggeredSkuLength
        ? getSkuCodes(productSkuData?.skus as unknown as ProductSku[])
        : [productSkuData?.skus?.[0]?.code]
    if (skuCodeList.length > 0) {
        skuCodeList.forEach((productSkuCode: string) => {
            const staggeredProductData =
                checkDataLength(productSkuData) && productSkuData.skus?.length > MagicNumber.ONE
                    ? getStaggeredProductDataBySku(productSkuData.skus, productSkuCode)
                    : {}
            autoProductAnalyticsData.push({
                ...productAnalyticsData,
                ...staggeredProductData,
                sku: checkValueOrNull(productSkuCode === productData.code ? '' : productSkuCode),
                vehicle: {
                    shopWithVehicle: String(!!checkDataLength(vehicle)),
                    partialVehicleJIT: String(!!partialVehicleJIT),
                    staggeredFitment: String(!!staggeredFitment),
                    type: vehicle?.vehicleType,
                    make: vehicle?.autoAttributes?.make,
                    model: vehicle?.autoAttributes?.model,
                    year: vehicle?.autoAttributes?.year,
                    body: vehicle?.autoAttributes?.body,
                    option: vehicle?.autoAttributes?.option,
                },
            })
        })
    }
    const finalProductAnalyticsData = isAutomotive ? autoProductAnalyticsData : [productAnalyticsData]
    return {
        event: productDetailView,
        page: {
            video: videoList?.length.toString() || '0',
            images: images?.length.toString() || '0',
            mediaType: checkMediaType(productData),
        },
        products: finalProductAnalyticsData as unknown as ProductRecordInterface[],
    }
}

/**
 * returns analytics data object
 * @param {ProductResponseData} productData
 * @param {ProductSkusData} productSkuData
 * @param {VariantDetails } variantDetails
 * @param {string } selectedColor
 * @param {string } selectedSize
 * @param {string } selectedDynamicName
 * @param {boolean} inStockInSelectedStore
 * @param {boolean} inStockAtDC
 * @param {boolean} isProductDiscontinued
 * @param {string } language
 * @param {boolean} financingEligible
 * @param {boolean} financingShown
 * @param {string} skuCode
 * @param {PriceVariant} getProductDataBySku
 * @param {FulfillmentDTOP} fulfillment
 * @param {boolean} isAutomotive
 * @param {Vehicle} vehicle
 * @param {boolean} staggeredFitment
 * @param {boolean} fitted
 * @param {string} productWheelType
 * @param {boolean} installationEligible
 * @param {boolean} isShipToHome
 * @param {string} selectedProductCode
 * @param {string} vehicleFitType
 * @param {boolean} isOutOfStockInAllStore
 */
export const productDetailAnalytics = (
    productData: ProductResponseData,
    productSkuData: ProductSkusData,
    variantDetails: VariantDetails,
    selectedColor: string,
    selectedSize: string,
    selectedDynamicName: string,
    inStockInSelectedStore: () => boolean,
    inStockAtDC: () => boolean,
    isProductDiscontinued: () => boolean,
    language: string,
    financingEligible: boolean,
    financingShown: boolean,
    skuCode: string,
    getProductDataBySku: PriceVariant,
    fulfillment: FulfillmentDTOP,
    isAutomotive?: boolean,
    vehicle?: Vehicle,
    staggeredFitment?: boolean,
    fitted?: boolean,
    productWheelType?: string,
    installationEligible?: boolean,
    isShipToHome?: boolean,
    selectedProductCode?: string,
    vehicleFitType?: string,
    isOutOfStockInAllStore?: boolean,
    isStoreEligibleForExpressDelivery?: boolean,
): void => {
    const { yes, no, notApplicable } = installationAvailable

    const installationAvailability = installationEligible
        ? yes
        : installationEligible === undefined
        ? notApplicable
        : no

    getProvider().push(
        getProductData(
            productData,
            productSkuData,
            variantDetails,
            selectedColor,
            selectedSize,
            selectedDynamicName,
            inStockInSelectedStore,
            inStockAtDC,
            isProductDiscontinued,
            language,
            financingEligible,
            financingShown,
            skuCode,
            getProductDataBySku,
            fulfillment,
            isAutomotive,
            vehicle,
            staggeredFitment,
            fitted,
            productWheelType,
            installationAvailability,
            isShipToHome,
            selectedProductCode,
            vehicleFitType,
            isOutOfStockInAllStore,
            isStoreEligibleForExpressDelivery,
        ),
    )
}
