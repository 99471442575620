export interface WeatherTech {
    weatherTechVehicle: WeatherTechVehicle | null
    weatherTechErrorVehicle: WeatherTechErrorVehicle | null
    isWeatherTechFlow: boolean
    isWeatherTechProductsPresent: boolean
    initialWeatherTechProductFetchNeeded: boolean
    showErrorToast: boolean
    weatherTechVehicleSelectorModeOptions: WeatherTechVehicleSelectorModeOptions
}

export enum WeatherTechStartTab {
    VEHICLE_ATTRIBUTE = 'VEHICLE_ATTRIBUTE',
    AVAILABLE_PARTS = 'AVAILABLE_PARTS',
}

export type WeatherTechVehicleSelectorModeOptions = {
    show: boolean
    startTab: WeatherTechStartTab
    keepStagesInMemory: boolean
    hasCancelButton: boolean
    returnToStartStage: boolean
}

export type WeatherTechErrorVehicle = Pick<WeatherTechVehicle, 'make' | 'year' | 'model'>

export interface WeatherTechVehicle {
    year: string
    make: string
    model: string
    groupLabel: string
    vehicleId: string
    groupId: string
    choiceIds: string // underscore separated id values for applicable vehicle option choices eg: 8891_8655_8716_8658
    vendorResults: string[]
}
