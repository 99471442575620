import { useCallback, useEffect, useRef } from 'react'

function useRoadRatingsRef() {
    const map = useRef<Map<number, HTMLElement> | null>(null)

    const getMap = useCallback(() => {
        if (!map.current) {
            map.current = new Map() as Map<number, HTMLElement>
        }
        return map.current
    }, [])

    const updateRef = useCallback(
        (key: number) => (node: HTMLDivElement | null) => {
            const nodes = getMap()

            if (nodes.has(key)) {
                nodes.get(key).offsetHeight
            }

            if (node) {
                nodes.set(key, node)
            } else {
                nodes.delete(key)
            }
        },
        [getMap],
    )

    useEffect(() => {
        const roadRatingNodes = getMap()
        return () => roadRatingNodes.clear()
    }, [getMap])

    return { updateRef, getMap }
}

export default useRoadRatingsRef
