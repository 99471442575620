import { ActionType, getType } from 'typesafe-actions'
import { fetchToggleAction } from '../actionCreators/buttonToggle.actionCreators'
import { EmptyActionType } from '../models/globalData.interface'

type Action = ActionType<typeof fetchToggleAction> | EmptyActionType

export interface ToggleState {
    toggle: string
}

export const initialState: ToggleState = {
    toggle: 'GRID',
}

export const toggleReducer = (state: ToggleState = initialState, action: Action): ToggleState => {
    if (action.type === getType(fetchToggleAction)) {
        return { ...state, toggle: action.payload }
    } else {
        return state
    }
}
