import { ProductCardClickAnalytics } from '../../redux/models/recommendations.interface'
import { constantValues } from '../../components/ProductGridView/ProductCard.constant'
import { isArrayNotEmpty } from '@nl/lib'
import { productAnalytics } from '../../components/ProductGridView/ProductCard.types'
import { ProductResponseData, ProductSku } from '../../redux/models/product.interface'
import { ProductHelper } from '../helpers/product.helper'
import { RecommendationDataForAnalytics } from '../analytics.type'

/**
 * this function fetches all code values from skus of a product
 * @param {ProductSku[]} skus
 * @return {string[]} returns skus code value
 */
const getSkuCodes = (skus: ProductSku[]): string[] => {
    const skuCodes: string[] = []
    skus.forEach((sku: ProductSku) => {
        skuCodes.push(sku?.code)
    })
    return skuCodes
}

export const getProductAnalyticsClickItem = <ProductData extends RecommendationDataForAnalytics>(
    product: ProductData[],
    index: number,
    title: string,
    recommendationType?: string,
    productData?: ProductResponseData,
): ProductCardClickAnalytics[] => {
    const selectedProduct = product[index]
    const productAnalyticsClick: ProductCardClickAnalytics[] = []

    const skuCodeList =
        selectedProduct?.skus?.length && selectedProduct?.skus?.length > 0
            ? getSkuCodes(selectedProduct?.skus as unknown as ProductSku[])
            : [selectedProduct.code]
    const feeMessage = isArrayNotEmpty(selectedProduct?.feeMessages) ? selectedProduct?.feeMessages?.[0] : {}
    if (skuCodeList.length > 0) {
        skuCodeList.forEach((code: string) => {
            productAnalyticsClick.push({
                badge: selectedProduct?.badges,
                brand: (selectedProduct?.brand as { label: string })?.label,
                category: recommendationType || undefined,
                fee: ProductHelper.transformFee(feeMessage),
                list: title || '',
                name: selectedProduct?.title,
                rebate: selectedProduct?.rebate?.message || 'null',
                pcode: productData?.code as string,
                sku: code || '',
                position: index,
                price: selectedProduct?.currentPrice?.value?.toString() || (0 as unknown as string),
                priceVariant: selectedProduct?.currentPrice?.value?.toString() || (0 as unknown as string),
                rating: selectedProduct?.rating?.toString() || '',
                numberOfReviews: selectedProduct?.ratingsCount?.toString() || undefined,
                variant1: undefined,
                variant2: '',
                variant3: '',
            })
        })
    }

    return productAnalyticsClick
}

export const getProductAnalyticsItem = <RecommendationsData extends RecommendationDataForAnalytics>(
    recommendationsData: RecommendationsData[],
    title: string,
    recommendationType?: string,
    productData?: ProductResponseData,
): productAnalytics[] => {
    const productAnalyticsList: productAnalytics[] = []
    recommendationsData.forEach((product, index) => {
        const skuCodeList =
            product?.skus?.length && product?.skus?.length > 0
                ? getSkuCodes(product?.skus as unknown as ProductSku[])
                : [product.code]

        const feeMessage = isArrayNotEmpty(product?.feeMessages) ? product?.feeMessages?.[0] : {}
        if (skuCodeList.length > 0) {
            skuCodeList.forEach((code: string) => {
                productAnalyticsList.push({
                    badge: product?.badges,
                    category: recommendationType || undefined,
                    pcode: productData?.code,
                    priceVariant: product?.currentPrice?.value?.toString() || (0 as unknown as string),
                    fee: ProductHelper.transformFee(feeMessage),
                    rebate: product?.rebate?.message || 'undefined',
                    brand: (product?.brand as { label: string })?.label,
                    list: title || '',
                    name: product?.title,
                    sku: code || '',
                    position: index + constantValues.positionIncrementValue,
                    price: product?.currentPrice?.value?.toString() || 0,
                    rating: product?.rating?.toString() || undefined,
                    numberOfReviews: product?.ratingsCount?.toString() || undefined,
                    variant1: undefined,
                    variant2: undefined,
                    variant3: undefined,
                })
            })
        }
    })
    return productAnalyticsList
}
