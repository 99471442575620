import { inImageBadgePriority } from '../ProductGridView/ProductGrid.types'
import { Akamai, MagnifierOptions } from './Akamai/Akamai.type'
import { Shoestring } from './Akamai/Shoestring'

/**
 ** Interface for MediaGallery Component
 * @interface
 */
export interface MediaGalleryProps {
    inImageBadgePriorities?: inImageBadgePriority
    productImages: ProductImages[]
    windowRef: WindowRefType
    hostNames: string[]
    path?: string
    a11yCarouselNextLabel: string
    a11yCarouselPreviousLabel: string
    a11yCloseIconLabel: string
    thumbnailImageCount: number
    mediaGalleryClick: (isModal?: boolean) => void
    selectedVariant?: string
    selectedVariantId?: number | string
    selectedProductCode?: string
    buyboxHeight?: number
    productDataCode?: string
    productDataBadges?: string[]
    isAutomotive?: boolean
    title?: string
    fullscreenEnable?: boolean
    enablePDPZoomEnhancement?: boolean
    watchFullScreenLabel?: string
    setMagnifierOptions?: (options: MagnifierOptions) => void
    enableFasterPdpLogic: boolean
    mobileStaticImageSize: number
    desktopStaticImageSize: number
    thumbnailImageCountAriaLabel: string
}

/**
 ** Interface for ProductImages
 * @interface
 */
export interface ProductImages {
    type: string
    url: string
    tags?: string[]
}

/**
 ** Interface for DynamicButton
 * @interface
 */
export interface DynamicButton {
    elementRef?: Element
    position?: InsertPosition
    className: string
    ariaLabel?: string
    type: string
    iconHrefLink?: string
    buttonId?: string
    size: string
    onClick?: () => void
}

export enum MediaTypeValue {
    image = 'image',
    video = 'video',
}

/**
 ** Interface for MediaImages
 * @interface
 */
export interface MediaImages {
    altText: string
    isListingThumbnailImage: string
    mediaType: string
    url: string
    thumbnail?: string
    type?: string
    tags?: string[]
    format?: string
}

/**
 * type for window Referance
 * @type
 */
export type WindowRefType = typeof window & {
    Akamai: Akamai
    $: (selector: string) => Shoestring
}
/**
 ** Interface for MediaGalleryHTMLVideoElement
 * @interface
 */
export interface MediaGalleryHTMLVideoElement extends HTMLVideoElement {
    webkitRequestFullScreen?: () => void
    webkitEnterFullscreen?: () => void
}
