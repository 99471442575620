import { AxiosPromise } from 'axios'

import BaseService from '../base.service'
import { getEnvironment } from '../../environments'
import { apiMethods } from '../service.constants'
import { TriangleTransactionPayload } from '../../redux/models/triangleTransactionHistory.interface'
import { loyaltyType } from '../../redux/models/user.profile.interface'
import { HttpReqHeaders } from '../../redux/utils/httpClient.type'

const environment = getEnvironment()

/**
 * transaction history service.
 */
class TransactionHistoryService extends BaseService {
    /**
     * Creates URL for payment info API
     * @return {string} returns URL for API
     */
    createFetchTransactionHistoryDataURL(): string {
        const {
            API_BASE_URL,
            API_ENDPOINTS: { triangleTransactionHistory },
        } = environment

        return `${API_BASE_URL}${triangleTransactionHistory}`
    }

    /**
     * API call service for fetch transaction history info
     * @param {TriangleTransactionPayload} requestParams
     * @param {loyaltyType} linkLoyaltyCardType
     * @return {AxiosPromise} returns API request for fetch transaction history info
     */
    fetchTransactionHistoryData(
        requestParams: TriangleTransactionPayload,
        linkLoyaltyCardType?: loyaltyType,
    ): AxiosPromise {
        const url = this.createFetchTransactionHistoryDataURL()
        const cardNumber = linkLoyaltyCardType?.cardNumber || ''
        const headers: HttpReqHeaders = {
            enrollmentId: `${cardNumber}`,
        }
        return TransactionHistoryService.apiMethod(apiMethods.POST, url, { ...requestParams }, headers)
    }
}

// Creating a singleton instance of service
const transactionHistoryService = new TransactionHistoryService()

export { transactionHistoryService }
export default transactionHistoryService
