import { currencyShortForm } from '../globalConstants/global.constant'
import { isTriangleSelectMember, wasTriangleSelectMember } from './isTriangleSelectMember'

/**
 * To get the summary line
 * @param {string} value
 *  @param {string} country
 *  @param {string} stateProvince
 *  @returns {boolean}
 */
export const checkStateProvince = (value: string, country: string, stateProvince: string): boolean => {
    if (country === currencyShortForm) {
        return value?.toUpperCase() === `${country}-${stateProvince}`
    } else return false
}

/**
 * if user is a select user and province or country gets changed on billing address, function will return as true
 * @param {string} triangleStatus
 * @param {string} updatedProvince
 * @param {string} province
 * @param {string} updatedCountry
 * @param {string} country
 * @returns {boolean}
 */
export const checkAddressChange = (
    triangleStatus?: string,
    updatedProvince?: string,
    province?: string,
    updatedCountry?: string,
    country?: string,
): boolean => {
    return Boolean(
        (isTriangleSelectMember(triangleStatus) || wasTriangleSelectMember(triangleStatus)) &&
            (updatedProvince !== province || updatedCountry !== country),
    )
}
