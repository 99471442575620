import React from 'react'
import { InitialCategoryPayloadType } from '../../redux/models/secondaryNavigation.interface'
import {
    AuthorCategories,
    AuthorSubcategories,
    MapDataAuthorCategoriesType,
    StackedCategoryListNewProps,
    StackedCategoryPayloadType,
} from './StackedCategoryListNew.type'
import { arrowActionIdentifier, isArrayNotEmpty } from '@nl/lib'
import { PREFIX } from '../../config'
import {
    getNextInteractiveElement,
    getPreviousInteractiveElement,
} from '@nl/lib/src/helpers/getInteractiveElements.helper'

/**
 *
 * @param {InitialCategoryPayloadType[]} categoryId categoryId
 * @param {string} categoryData categoryData
 * @param {string} pathname pathname
 * @returns categoryData
 */
export const getCertainCategory = (
    categoryId: string,
    categoryData: InitialCategoryPayloadType[],
    pathname: string,
): StackedCategoryPayloadType[] | InitialCategoryPayloadType[] | undefined => {
    if (!categoryId) {
        return []
    }
    const categoryL1 = categoryData?.find(item => item.id === categoryId || item.url === pathname)
    if (categoryL1) {
        return categoryL1?.categories || categoryL1?.subcategories || []
    } else {
        let categoryL2: StackedCategoryPayloadType | InitialCategoryPayloadType | undefined
        categoryData?.some(categoryItem => {
            if (categoryItem?.categories && isArrayNotEmpty(categoryItem.categories) && !categoryL2) {
                categoryL2 = categoryItem.categories.find(item => item.id === categoryId || item.url === pathname)
            } else if (categoryL2) {
                return true
            }
        })
        return categoryL2?.categories || categoryL2?.subcategories || []
    }
}

/**
 *
 * @param {StackedCategoryPayloadType[] | undefined} data data
 */
export const sortCategoryDataByName = (
    data: StackedCategoryPayloadType[] | undefined,
): StackedCategoryPayloadType[] => {
    if (data) {
        const dataForSort = [...data]
        return [
            ...dataForSort.sort((a: StackedCategoryPayloadType, b: StackedCategoryPayloadType) =>
                a.name.localeCompare(b.name),
            ),
        ]
    } else {
        return [] as StackedCategoryPayloadType[]
    }
}

/**
 * This function returns interactive elements in a node
 * @param { HTMLUListElement | null } node node
 * @param { string } selector selector
 * @return { NodeListOf<Element> | null }
 */
export const getInteractiveElements = (
    node: HTMLUListElement | null,
    selector: string,
): NodeListOf<HTMLElement> | null => {
    return node?.querySelectorAll(selector) || null
}

/**
 * This function returns first active subcategory element in node
 * @param { Element | undefined } node node
 * @return { HTMLElement | null }
 */
export const getActiveSubcategoryElement = (node: Element | undefined): HTMLElement | null => {
    return node?.querySelector(`.${PREFIX}-product-category__link`) || null
}

/**
 * This function returns active category element
 * @return { HTMLElement | null }
 */
export const getActiveCategoryElement = (): HTMLElement | null => {
    return document.querySelector(`.${PREFIX}-product-category__content.active-category`) || null
}

/**
 * This function is dedicated for arrows action
 * @param { React.KeyboardEvent<HTMLDivElement> } event
 * @param { string } action
 * @param { number } currentIndex
 * @param { <HTMLElement> } ref ref
 * @param { string } selector selector
 */
export const handleArrowsForCategories = (
    event: React.KeyboardEvent<HTMLElement>,
    action: string,
    currentIndex: number,
    ref: React.RefObject<HTMLUListElement>,
    selector: string,
) => {
    event.preventDefault()
    event.stopPropagation()
    const variantsList = getInteractiveElements(ref?.current, selector)
    let interactiveElement: HTMLElement
    if (action === arrowActionIdentifier.NEXT) {
        interactiveElement = getNextInteractiveElement(currentIndex, variantsList as NodeListOf<HTMLElement>)
        interactiveElement?.focus()
    } else if (action === arrowActionIdentifier.PREVIOUS) {
        interactiveElement = getPreviousInteractiveElement(currentIndex, variantsList as NodeListOf<HTMLElement>)
        interactiveElement?.focus()
    }
}

/**
 * @param {boolean} isAuthor isAuthor
 * @param {StackedCategoryListNewProps} categories categories
 * @param {string} categoryId categoryId
 * @param { InitialCategoryPayloadType[]} apiData apiData
 * @param {string} pathname pathname
 */
export const choiceSourceData = (
    isAuthor: boolean,
    categories: StackedCategoryListNewProps,
    categoryId: string,
    apiData: InitialCategoryPayloadType[],
    pathname: string,
) => {
    return isAuthor && categories
        ? mapDataAuthorCategories(categories as unknown as AuthorCategories[])
        : (getCertainCategory(categoryId, apiData, pathname) as StackedCategoryPayloadType[])
}

/**
 *
 * @param {AuthorCategories[] | undefined} categoriesAuthor categoriesAuthor
 */
export const mapDataAuthorCategories = (categoriesAuthor: AuthorCategories[]): MapDataAuthorCategoriesType[] => {
    return categoriesAuthor?.map((category: AuthorCategories, index) => {
        const mapDataSubcategory = category?.subcategories?.map((subcategory: AuthorSubcategories, indexSub) => {
            return {
                name: subcategory.subcategoryLabel,
                categoryImageAltText: subcategory.subcategoryImageAltText,
                id: `${subcategory.subcategoryLabel}_${indexSub}`,
                url: subcategory.subcategoryLink,
                image: subcategory.subcategoryImage,
            }
        })
        return {
            name: category.categoryLabel,
            categoryImageAltText: category.categoryImageAltText,
            id: `${category.categoryLabel}_${index}`,
            url: category.categoryLink,
            image: category.categoryImage,
            shopAllLinkLabel: category.shopAllLinkLabel,
            shopAllLink: category.shopAllLink,
            subcategories: mapDataSubcategory,
        }
    })
}
