import GlobalPropsHelper from '../analytics/helpers/globalProps/globalProps.helper'
import { checkDataLength } from '../components/Accounts/Addresses/checkDataLength'
import { libUtils } from '@nl/lib'
import { EnvironmentConfig } from './base.environment'

// TODO: Generic implementation required
/**
 * Base class to create new environment configuration
 */
export class Config extends EnvironmentConfig {
    serviceClient = 'ctr/web'
    serviceVersion = 'ctc-dev0'
    // TODO: subscription keys should not be part of code
    ocpApimSubscriptionKey = '4828eb9ff797404383701b098142d9e9'
    baseSiteId = 'CTR'
    banner = 'CTR'
    language = `${libUtils.getLanguage()}_CA`
    storeName = '363'
    defaultProductImage = ''
    // values to be fed from AEM. Created only for local dev
    TMX_SESSION_VALUE = 'tmxSessionIdInfo'
    SIGNIFYD_SESSION_VALUE = 'signifydSessionIdInfo'
    GIGYA_JWT = 'jwt'
    API_BASE_URL = 'https://odp-cds-apim.azure-api.net'
    API_V1 = '/public/dev/v1'
    SSO_BASE_URL = 'https://apim-nprd.canadiantire.ca'
    HYBRIS_BASE_URL = 'https://api.c0onq03u1z-canadiant2-d2-public.model-t.cc.commerce.ondemand.com/rest/v2/'
    API_MOCK_URL = `${window.location.origin}/content/dam/canadiantire/mock-json`
    AEM_URL = `${window.location.origin}/{0}/dynamic`
    AUTO_MOTIVE_URL = 'https://api.canadiantire.ca/search'
    CANADA_POST_BASE_URL = 'https://ws1.postescanada-canadapost.ca'
    CRITEO_CONFIG: Record<string, string> = {
        baseUrl: 'https://d.us.criteo.com/delivery/retailmedia',
        partnerId: '106646',
        noLog: '1',
    }
    JSON_RESPONSE_FORMAT = 'json'
    API_ENDPOINTS: Record<string, string> = {
        setCookie: '/public/dev/v1/authorization/sso/set-cookie',
        dssCustomerOffers: '/public/dev/v1/myaccount/loyalty/Offers',
        dssActivateCustomerOffers: '/public/dev/v1/myaccount/loyalty/Offers/Activate',
        dssActivateTargetedOffer: '/public/dev/v1/myaccount/loyalty/Offers/ActivateTargetedOffer',
        wishlist: '/mock/profile/wishlist',
        storeDetails: '/mock/store/storeDetails',
        storeList: '/mock/store/store/stores/storeList',
        storeFilters: '/public/dev/v1/store/stores/locatorFilters',
        recommendationData: '/mock/product/api/v1/products',
        cart: '/mock/cart/api/v1/getCart',
        selectedServiceCartData: '/mock/cart/api/v1/selectedServiceCartData',
        mergeCart: '/mock/cart/api/v1/mergeCart',
        deleteCart: '/public/dev/v1/cart/api/v1/cart/{cartId}',
        authMergeCart: '/mock/cart/api/v1/authMergeCart',
        getCategories: '/private/category/api/v1/categories',
        pdpFragment: '/pdp-content.model.snippets.json/',
        plpFragment: '/plp-content.model.snippets.json/',
        initPayment: '/public/dev/v1/checkout/checkout/initpayment',
        getPaymentDetails: '/public/dev/v1/checkout/checkout/getCheckoutPaymentDetails',
        initAddUpdateCard: '/public/dev/v1/profile/profile/init-add-update-card',
        addUpdateCard: '/public/dev/v1/profile/profile/add-update-card',
        brandFragment: '/brand-content.model.snippets.json/',
        storeDetailsFragment: '/store-details-content.model.snippets.json/',
        orderHistory: '/public/dev/v1/order/users/orders',
        initCheckout: '/public/dev/v1/checkout/checkout/initcheckout',
        orderConfirmation: '/mock/checkout/placeOrder',
        placeOrder: '/public/dev/v1/checkout/checkout/placeOrder',
        placeOrderError: '/mock/checkout/placeOrderError',
        getRecommendations: '/public/dev/v1/product/api/v1/product/productFamilyList',
        getSearchResults: '/public/dev/v1/search/search',
        getSuggestResults: '/public/dev/v1/search/suggest',
        doesItFit: '/mock/api/v2/automotive/fit/en',
        addVehicle: '/public/dev/v1/search/automotive/vehicles',
        addVehicleImage: '/public/dev/v1/search/automotive/vehicles/images',
        tireSizes: '/public/dev/v1/search/automotive/tire-sizes',
        initPaymentMock: '/mock/checkout/initPayment.json',
        addCartEndpoint: '/public/dev/v1/cart/api/v1/cart',
        orderDetails: '/public/dev/v1/checkout/checkout/getOrder',
        orderDetailsMock: '/mock/aoa/api/v1/aoaGetOrderDetailsResponse',
        orderReturnSlip: '/public/dev/v1/order/users/orderReturns/{orderId}/document',
        getCancelOrderReasons: '/public/dev/v1/checkout/checkout/cancellationReasons',
        cancelOrder: '/public/dev/v1/checkout/checkout/cancelOrder',
        getSaveForLater: '/public/dev/v1/cart/api/v1/saveforlater',
        removeFromSaveForLater: '/public/dev/v1/cart/api/v1/saveforlater',
        cartEndpoint: '/public/dev/v1/cart/api/v1/cart',
        updateCartPickupLocation: '/public/dev/v1/cart/api/v1/cart/v2',
        miniCartEndpoint: '/public/dev/v1/api/v1/cart/mini',
        checkoutCartEndpoint: '/public/dev/v1/api/v1/cart/checkout',
        removeCartMockEndpoint: '/removeCartData',
        updateCartEndpoint: '/public/dev/v1/cart/api/v1/cart/entries/v2',
        promoCode: '/public/dev/v1/cart/api/v1/cart/voucher',
        updateCartMockEndpoint: '/updateCartData',
        updateContactCheckout: '/public/dev/v1/checkout/checkout/replaceGuestUser',
        updateDeliveryCheckout: '/public/dev/v1/checkout/checkout/cartDeliveryAddress',
        updatePickupCheckout: '/public/dev/v1/checkout/checkout/selectedPickupLocation',
        updatePaymentCheckout: '/public/dev/v1/checkout/checkout/paymentDetails',
        redeemCTMoney: '/public/dev/v1/cart/api/v1/cart/checkout/redeemCTMoney',
        productDetails: '/public/dev/v1/product/api/v1/product/productFamily',
        productDetailsMock: '/mock/productDetails',
        updatePickupLocationCartMock: '/updatePickupLocationCart',
        ProductData: 'ProductData.json',
        getStoreDetails: '/public/dev/v1/store/store',
        rbaTmxAuth: '/public/dev/v1/authorization/signin/rba-tmx',
        cdsAccessToken: '/public/dev/v1/authorization/signin/access/token',
        cdsUserProfile: '/public/dev/v1/profile/profile',
        cdsLoyaltyLinkCard: '/public/dev/v1/link-loyalty/loyalty/link',
        cdsLoyaltyLinkCheck: '/public/dev/v1/link-loyalty/loyalty/check',
        cdsLoyaltyLinkValidate: '/public/dev/v1/link-loyalty/loyalty/validate',
        cdsLoyaltyLinkProfile: '/public/dev/v1/link-loyalty/loyalty',
        dssLoyaltyCardLookup: '/public/dev/v1/cds/triangle/loyaltyCardLookup',
        creditCardAssociation: '/merge-loyalty-cards/validate/creditcard-association',
        validateLoyalty: '/merge-loyalty-cards/validate/loyaltycard',
        validateLoyaltyExtended: '/merge-loyalty-cards/validate/loyaltycard/extended',
        mergeLoyaltyCard: '/merge-loyalty-cards/merge',
        shoppingPreference: '/public/dev/v1/profile/preferences/jnjtraits',
        cdsUserProfilePayment: '/public/dev/v1/profile/profile/paymentInfo',
        notificationPreference: '/public/dev/v1/profile/preferences/communication',
        notificationPreferenceLite: '/public/dev/v1/profile/preferences/communication/lite',
        dssLinkCountries: '/public/dev/v1/services/services/v4/countries',
        dssLinkRegion: '/public/dev/v1/services/services/v4/regions',
        cdsLinkRegion: '/public/dev/v1/services/services/v4/regions',
        getStoresDetails: '/public/dev/v1/store/stores',
        cdsSignOut: '/public/dev/v1/authorization/signout',
        contactUs: '/public/dev/v1/contactus/submit',
        criticalFitment: '/public/dev/v1/get-criticalfitment/automotive/fitment',
        addWishlist: '/public/dev/v1/profile-wishlist/profile/wishlist/products',
        getWishlist: '/public/dev/v1/profile-wishlist/profile/wishlist/v2',
        removeWishlist: '/public/dev/v1/profile-wishlist/profile/wishlist/products',
        mergeWishlist: '/public/dev/v1/profile-wishlist/profile/wishlist/mergeWishlist',
        getWishlistProductCodes: '/public/dev/v1/profile-wishlist/profile/wishlist/productCodes',
        getWishlistDetails: '/public/dev/v1/profile-wishlist/profile/wishlist/details',
        updatePostalCodeToCart: '/public/dev/v1/cart/api/v1/cart/addresses/deliveryPostalCode',
        estimateShippingCost: '/public/dev/v1/cart/api/v1/cart/addresses/deliveryPostalCode/cost',
        generateSharedCartLink: '/cartShare/carts/{cartId}/template',
        getSharedCartTemplate: '/cartShare/carts/{cartId}/template/{templateCode}',
        mergeCarts: '/cart/shared/entries',
        sendSharedCartLink: '/cartShare/carts/{cartId}/share',
        getStoreInitiatedCart: '/cartShare/carts/{cartId}/oneTimeCart?fields={responseType}',
        changeCartDeliveryMode: '/cart/api/v2/cart/chooseShippingMethod',
        changeStoreInitiatedCartDeliveryMode: '/carts/oneTimeCart/deliverymode/{deliveryModeCode}',
        orderNotificationSubscription: '/users/orders/{orderCode}/notification',
        priceAvailabilityMock: '/mock/priceAvailability',
        extraHardware: '/public/dev/v1/search/automotive/extra-hardware',
        priceAvailability: '/public/dev/v1/product/api/v1/product/sku/PriceAvailability',
        productPriceAvailability: '/public/dev/v1/product/api/v1/product/product/PriceAvailability',
        skuPrice: '/public/dev/v1/product/api/v1/product/sku/Price',
        digitalFlyerData: '/public/dev/v1/product/api/v1/product/flyer/DataLookup',
        switchFulfillment: '/mock/cart/api/v1/switchOptionData',
        estimateFees: '/public/dev/v1/product/api/v1/product/sku/shippingFees',
        tiresData: 'TiresData.json',
        wheelsData: 'WheelsData.json',
        mergeGuestAuthCart: '/public/dev/v1/cart/api/v1/cart/merge',
        triangleMCBanner: '/public/dev/v1/cart/api/v1/cart/apply/triangleMastercard',
        canadaPostFind: '/AddressComplete/Interactive/Find/v2.10/json3.ws',
        canadaPostRetrieve: '/AddressComplete/Interactive/RetrieveFormatted/v2.10/json3.ws',
        updateBulkDeliveryOption: '/public/dev/v1/cart/api/v1/cart/bulkDeliveryOption',
        triangleTransactionHistory: '/public/dev/v1/myaccount/loyalty/TransactionHistory',
        deliveryOptions: '/public/dev/v1/product/api/v1/product/delivery/options',
        saveForLater: '/mock/cart/api/v1/saveForLater',
        removeSaveForLater: '/mock/cart/api/v1/removeSaveForLater',
        getAppointmentAvailability: '/public/dev/v1/store/appointment/availability',
        saveAppointmentAvailability: '/public/dev/v1/store/appointment/save',
        getAutomotiveServicesMock: '/aoa/automotiveServices',
        getAutomotiveServices: '/public/dev3/v1/aoa/Apt_GetServiceList',
        getDateTimeSlots: '/public/dev3/v1/aoa/Apt_GetBranchAvailabilityListForWeb',
        bookAutomotiveAppointmentMock: '/aoa/bookAppointmentResponse',
        bookAutomotiveAppointment: '/public/dev3/v1/aoa/Apt_InsertWebAppointment',
        getAppointmentTime: '/public/dev3/v1/aoa/Apt_GetAppointmentTime',
        cancelAppointmentMock: '/mock/aoa/api/v1/aoaCancelAppointmentResponse',
        cancelAppointment: '/public/dev3/v1/aoa/Apt_CancelAppointment',
        applyGiftCard: '/public/dev2/v1/checkout/checkout/addGiftCard',
        deleteGiftCard: '/public/dev2/v1/checkout/checkout/removeGiftCard',
        clearTenders: '/mock/checkout/clearTenders',
        recommendationAddonsMock: '/mock/cart/api/v1/getBalloonAddons',
        balloonAddonsMock: '/mock/cart/api/v1/getBalloonAddons',
        bookEtireAutomotiveAppointment: '/public/dev3/v1/aoa/Apt_InsertWebAppointmentForExistingDocument',
        rescheduleAppointment: '/public/dev3/v1/aoa/Apt_RescheduleAppointment',
        validateCardNumber: '/public/dev3/v1/link-loyalty/kiosk/card/verify',
        earnCtMoney: '/public/dev3/v1/order/users/orders/earn-ct-money',
        estimatedDeliveryDate: '/public/qa2/v1/product/api/v1/product/sku/estimatedDeliveryDate',
        redeemCtMoneyPostPurchase: '/public/qa2/v1/checkout/checkout/redeemCtMoneyPostPurchase',
        resendVerification: '/public/dev3/v1/registration/resend/verification',
        reusableBags: '/public/qa2/v1/cart/api/v1/cart/optInReusableBags',
        apimEndPointWeatherTech: '/public/qa2/v1/search/weathertech/vehicle',
        apimEndPointWeatherTechProducts: '/public/qa2/v1/search/weathertech/vehicle/products',
        apimEndPointFeaturedProducts: '/public/qa4/v1/search/search/automotive/featured-products',
        ctMoneyBalance: '/public/dev3/v1/loyalty/ctMoneyBalance',
        modifyOrderCustomerInfo: '/public/qa2/v1/checkout/checkout/modifyOrderCustomerInfo',
        communityOffers: '/public/qa4/v1/loyalty/offerPool',
        swapOffer: '/public/qa4/v1/loyalty/swapOffer',
        addToCartEndpoint: '/public/stg2/v1/cart/api/v2/cart',
        validateOtpCode: '/public/dev4/v1/authorization/signin/validate-otp-code',
        updatePassword: '/public/dev4/v1/authorization/update-password',
        clickToPaySTHValidation: '/public/qa4/v1/checkout/checkout/clickToPaySTHValidation',
        resetPassword: '/dev/v1/authorization/reset-password',
        cartBundle: '/public/dev4/v1/cart/api/v2/cart/bundle',
    }
    LOCAL_STORAGE_KEYS = {
        userVehicles: 'vehicles',
        tiresData: 'tiresSizeData',
    }
    SESSION_STORAGE_KEYS = {
        packageFlow: 'packageFlow',
        viewedFlyer: 'viewedFlyer',
        geoLocationAuthorized: 'geoLocationAuthorized',
        defaultVehicle: 'defaultVehicle',
        // TODO: lastSelectedVehicleOption remove related code if not required anymore
        lastSelectedVehicleOption: 'lastSelectedVehicleOption',
        categoryIdData: `categoryIdData_${libUtils.getLanguage()}`,
        categoryData: `categoryData_${libUtils.getLanguage()}`,
        confirmedOrderId: 'confirmedOrderId',
    }

    /**
     * function to get API configs
     */
    setConfig(): void {
        // init config
        // TODO: need to remove this hack
        const globalProps = new GlobalPropsHelper()
        const configs = globalProps.init()
        const canadaPostConfig = globalProps.getCanadaPostConfig()
        const criteoConfig = globalProps.getCriteoConfig()

        if (checkDataLength(configs)) {
            this.API_BASE_URL = configs['apim-domain'] as string
            this.ocpApimSubscriptionKey = configs['apim-subscriptionkey'] as string
            this.baseSiteId = configs['bannerId'] as string
            this.banner = configs['bannerId'] as string
            this.serviceVersion = configs['serviceVersion'] as string
            this.serviceClient = configs['serviceClient'] as string
            this.language = (configs['apiLocale'] as string) ?? this.language
            this.defaultProductImage = (configs['defaultProductImage'] as string) ?? ''
            this.getAPIEndpoints(configs as Record<string, string>)
        }

        if (checkDataLength(canadaPostConfig)) {
            this.CANADA_POST_BASE_URL = (canadaPostConfig as Record<string, string>)['canadaPostBaseURL']
            this.getAPIEndpoints(canadaPostConfig as Record<string, string>)
        }

        if (checkDataLength(criteoConfig)) {
            this.CRITEO_CONFIG = {
                baseUrl: criteoConfig.url,
                partnerId: criteoConfig.partnerId,
                noLog: criteoConfig.nolog,
            }
        }
    }

    /**
     * function to get fallback for API Endpoints
     * @param {Record<string, string>} configs
     * @param {string} configEndpoint
     * @param {string} fallback
     * @return {string} final endpoint
     */
    getEndpointWithFallback(configs: Record<string, string>, configEndpoint: string, fallback: string): string {
        return configs[configEndpoint] || fallback
    }

    /**
     * function to get API Endpoints
     * @param {Record<string, string>} configs
     */
    getAPIEndpoints(configs: Record<string, string>): void {
        this.API_ENDPOINTS.initPayment = this.getEndpointWithFallback(
            configs,
            'apimEndpointInitPayment',
            this.API_ENDPOINTS.initPayment,
        )
        this.API_ENDPOINTS.apimEndPointWeatherTech = this.getEndpointWithFallback(
            configs,
            'apimEndPointWeatherTech',
            this.API_ENDPOINTS.apimEndPointWeatherTech,
        )
        this.API_ENDPOINTS.apimEndPointWeatherTechProducts = this.getEndpointWithFallback(
            configs,
            'apimEndPointWeatherTechProducts',
            this.API_ENDPOINTS.apimEndPointWeatherTechProducts,
        )
        this.API_ENDPOINTS.apimEndPointFeaturedProducts = this.getEndpointWithFallback(
            configs,
            'apimEndPointFeaturedProducts',
            this.API_ENDPOINTS.apimEndPointFeaturedProducts,
        )
        this.API_ENDPOINTS.getPaymentDetails = this.getEndpointWithFallback(
            configs,
            'apimEndpointGetPaymentDetails',
            this.API_ENDPOINTS.getPaymentDetails,
        )
        this.API_ENDPOINTS.initAddUpdateCard = this.getEndpointWithFallback(
            configs,
            'apimEndPointInitPaymentProfile',
            this.API_ENDPOINTS.initAddUpdateCard,
        )
        this.API_ENDPOINTS.addUpdateCard = this.getEndpointWithFallback(
            configs,
            'apimEndPointAddUpdateCard',
            this.API_ENDPOINTS.addUpdateCard,
        )
        this.API_ENDPOINTS.initCheckout = this.getEndpointWithFallback(
            configs,
            'apimEndPointInitCheckout',
            this.API_ENDPOINTS.initCheckout,
        )
        this.API_ENDPOINTS.getCategories = this.getEndpointWithFallback(
            configs,
            'apimEndPointCategories',
            this.API_ENDPOINTS.getCategories,
        )
        this.API_ENDPOINTS.getRecommendations = this.getEndpointWithFallback(
            configs,
            'apimEndPointRecommendations',
            this.API_ENDPOINTS.getRecommendations,
        )
        this.API_ENDPOINTS.getSearchResults = this.getEndpointWithFallback(
            configs,
            'apimEndPointSearch',
            this.API_ENDPOINTS.getSearchResults,
        )
        this.API_ENDPOINTS.getSuggestResults = this.getEndpointWithFallback(
            configs,
            'apimEndPointSuggest',
            this.API_ENDPOINTS.getSuggestResults,
        )
        this.API_ENDPOINTS.cartEndpoint = this.getEndpointWithFallback(
            configs,
            'apimEndPointAddToCart',
            this.API_ENDPOINTS.cartEndpoint,
        )
        this.API_ENDPOINTS.addToCartEndpoint = this.getEndpointWithFallback(
            configs,
            'apimEndPointAddToCartV2',
            this.API_ENDPOINTS.addToCartEndpoint,
        )
        this.API_ENDPOINTS.updateCartPickupLocation = this.getEndpointWithFallback(
            configs,
            'apimEndPointUpdateCartPickupLocation',
            this.API_ENDPOINTS.updateCartPickupLocation,
        )
        this.API_ENDPOINTS.promoCode = this.getEndpointWithFallback(
            configs,
            'apimEndPointPromoCode',
            this.API_ENDPOINTS.promoCode,
        )
        this.API_ENDPOINTS.miniCartEndpoint = this.getEndpointWithFallback(
            configs,
            'apimEndPointMiniAddToCart',
            this.API_ENDPOINTS.miniCartEndpoint,
        )
        this.API_ENDPOINTS.checkoutCartEndpoint = this.getEndpointWithFallback(
            configs,
            'apimEndPointCheckoutCart',
            this.API_ENDPOINTS.checkoutCartEndpoint,
        )
        this.API_ENDPOINTS.updateCartEndpoint = this.getEndpointWithFallback(
            configs,
            'apimEndpointUpdateCart',
            this.API_ENDPOINTS.updateCartEndpoint,
        )
        this.API_ENDPOINTS.productDetails = this.getEndpointWithFallback(
            configs,
            'apimEndPointProducts',
            this.API_ENDPOINTS.productDetails,
        )
        this.API_ENDPOINTS.getStoreDetails = this.getEndpointWithFallback(
            configs,
            'apimEndPointStoreDetails',
            this.API_ENDPOINTS.getStoreDetails,
        )
        this.API_ENDPOINTS.getStoresDetails = this.getEndpointWithFallback(
            configs,
            'apimEndPointSearchStore',
            this.API_ENDPOINTS.getStoresDetails,
        )
        this.API_ENDPOINTS.contactUs = this.getEndpointWithFallback(
            configs,
            'apimEndPointContactUs',
            this.API_ENDPOINTS.contactUs,
        )
        this.API_ENDPOINTS.addWishlist = this.getEndpointWithFallback(
            configs,
            'apimEndPointAddRemoveWishlist',
            this.API_ENDPOINTS.addWishlist,
        )
        this.API_ENDPOINTS.getWishlist = this.getEndpointWithFallback(
            configs,
            'apimEndPointGetWishlist',
            this.API_ENDPOINTS.getWishlist,
        )
        this.API_ENDPOINTS.removeWishlist = this.getEndpointWithFallback(
            configs,
            'apimEndPointAddRemoveWishlist',
            this.API_ENDPOINTS.removeWishlist,
        )
        this.API_ENDPOINTS.mergeWishlist = this.getEndpointWithFallback(
            configs,
            'apimEndPointMergeWishlist',
            this.API_ENDPOINTS.mergeWishlist,
        )
        this.API_ENDPOINTS.getWishlistProductCodes = this.getEndpointWithFallback(
            configs,
            'apimEndPointGetWishlistQuantity',
            this.API_ENDPOINTS.getWishlistProductCodes,
        )
        this.API_ENDPOINTS.getWishlistDetails = this.getEndpointWithFallback(
            configs,
            'apimEndPointGetWishlistDetails',
            this.API_ENDPOINTS.getWishlistDetails,
        )
        this.API_ENDPOINTS.updateContactCheckout = this.getEndpointWithFallback(
            configs,
            'apimEndPointUpdateContactCheckout',
            this.API_ENDPOINTS.updateContactCheckout,
        )
        this.API_ENDPOINTS.updatePickupCheckout = this.getEndpointWithFallback(
            configs,
            'apimEndPointUpdatePickupCheckout',
            this.API_ENDPOINTS.updatePickupCheckout,
        )
        this.API_ENDPOINTS.updatePostalCodeToCart = this.getEndpointWithFallback(
            configs,
            'apimEndPointUpdatePostalCodeCart',
            this.API_ENDPOINTS.updatePostalCodeToCart,
        )
        this.API_ENDPOINTS.estimateShippingCost = this.getEndpointWithFallback(
            configs,
            'apimEndPointEstimateShippingCost',
            this.API_ENDPOINTS.estimateShippingCost,
        )
        this.API_ENDPOINTS.updatePaymentCheckout = this.getEndpointWithFallback(
            configs,
            'apimEndPointUpdatePaymentCheckout',
            this.API_ENDPOINTS.updatePaymentCheckout,
        )
        this.API_ENDPOINTS.updateDeliveryCheckout = this.getEndpointWithFallback(
            configs,
            'apimEndPointUpdateDeliveryCheckout',
            this.API_ENDPOINTS.updateDeliveryCheckout,
        )
        this.API_ENDPOINTS.rbaTmxAuth = this.getEndpointWithFallback(
            configs,
            'apimEndpointRbaTmxAuth',
            this.API_ENDPOINTS.rbaTmxAuth,
        )
        this.API_ENDPOINTS.cdsAccessToken = this.getEndpointWithFallback(
            configs,
            'apimEndpointCdsAccessToken',
            this.API_ENDPOINTS.cdsAccessToken,
        )
        this.API_ENDPOINTS.cdsUserProfile = this.getEndpointWithFallback(
            configs,
            'apimEndpointCdsUserProfile',
            this.API_ENDPOINTS.cdsUserProfile,
        )
        this.API_ENDPOINTS.cdsLoyaltyLinkCard = this.getEndpointWithFallback(
            configs,
            'cdsLoyaltyLinkCard',
            this.API_ENDPOINTS.cdsLoyaltyLinkCard,
        )
        this.API_ENDPOINTS.cdsLoyaltyLinkCheck = this.getEndpointWithFallback(
            configs,
            'cdsLoyaltyLinkCheck',
            this.API_ENDPOINTS.cdsLoyaltyLinkCheck,
        )
        this.API_ENDPOINTS.cdsLoyaltyLinkValidate = this.getEndpointWithFallback(
            configs,
            'cdsLoyaltyLinkValidate',
            this.API_ENDPOINTS.cdsLoyaltyLinkValidate,
        )
        this.API_ENDPOINTS.cdsLoyaltyLinkProfile = this.getEndpointWithFallback(
            configs,
            'cdsLoyaltyLinkProfile',
            this.API_ENDPOINTS.cdsLoyaltyLinkProfile,
        )
        this.API_ENDPOINTS.dssLoyaltyCardLookup = this.getEndpointWithFallback(
            configs,
            'dssLoyaltyCardLookup',
            this.API_ENDPOINTS.dssLoyaltyCardLookup,
        )
        this.API_ENDPOINTS.creditCardAssociation = this.getEndpointWithFallback(
            configs,
            'creditCardAssociation',
            this.API_ENDPOINTS.creditCardAssociation,
        )
        this.API_ENDPOINTS.validateLoyalty = this.getEndpointWithFallback(
            configs,
            'validateLoyalty',
            this.API_ENDPOINTS.validateLoyalty,
        )
        this.API_ENDPOINTS.validateLoyaltyExtended = this.getEndpointWithFallback(
            configs,
            'validateLoyaltyExtended',
            this.API_ENDPOINTS.validateLoyaltyExtended,
        )
        this.API_ENDPOINTS.mergeLoyaltyCard = this.getEndpointWithFallback(
            configs,
            'mergeLoyaltyCard',
            this.API_ENDPOINTS.mergeLoyaltyCard,
        )
        this.API_ENDPOINTS.shoppingPreference = this.getEndpointWithFallback(
            configs,
            'apimEndPointShoppingPreference',
            this.API_ENDPOINTS.shoppingPreference,
        )
        this.API_ENDPOINTS.cdsUserProfilePayment = this.getEndpointWithFallback(
            configs,
            'cdsUserProfilePayment',
            this.API_ENDPOINTS.cdsUserProfilePayment,
        )
        this.API_ENDPOINTS.notificationPreference = this.getEndpointWithFallback(
            configs,
            'apimEndPointNotificationPreference',
            this.API_ENDPOINTS.notificationPreference,
        )
        this.API_ENDPOINTS.notificationPreferenceLite = this.getEndpointWithFallback(
            configs,
            'apimEndPointNotificationPreferenceLite',
            this.API_ENDPOINTS.notificationPreferenceLite,
        )
        this.API_ENDPOINTS.dssLinkCountries = this.getEndpointWithFallback(
            configs,
            'dssLinkCountries',
            this.API_ENDPOINTS.dssLinkCountries,
        )
        this.API_ENDPOINTS.dssLinkRegion = this.getEndpointWithFallback(
            configs,
            'dssLinkRegion',
            this.API_ENDPOINTS.dssLinkRegion,
        )
        this.API_ENDPOINTS.cdsSignOut = this.getEndpointWithFallback(
            configs,
            'apimEndpointCdsSignOut',
            this.API_ENDPOINTS.cdsSignOut,
        )
        this.API_ENDPOINTS.setCookie = this.getEndpointWithFallback(
            configs,
            'apimEndpointSetCookie',
            this.API_ENDPOINTS.setCookie,
        )
        this.API_ENDPOINTS.dssCustomerOffers = this.getEndpointWithFallback(
            configs,
            'dssCustomerOffers',
            this.API_ENDPOINTS.dssCustomerOffers,
        )
        this.API_ENDPOINTS.dssActivateCustomerOffers = this.getEndpointWithFallback(
            configs,
            'dssActivateCustomerOffers',
            this.API_ENDPOINTS.dssActivateCustomerOffers,
        )
        this.API_ENDPOINTS.dssActivateTargetedOffer = this.getEndpointWithFallback(
            configs,
            'dssActivateTargetedOffer',
            this.API_ENDPOINTS.dssActivateTargetedOffer,
        )
        this.API_ENDPOINTS.priceAvailability = this.getEndpointWithFallback(
            configs,
            'apimEndPointPriceAvailability',
            this.API_ENDPOINTS.priceAvailability,
        )
        this.API_ENDPOINTS.productPriceAvailability = this.getEndpointWithFallback(
            configs,
            'apimEndPointProductPriceAvailability',
            this.API_ENDPOINTS.productPriceAvailability,
        )
        this.API_ENDPOINTS.skuPrice = this.getEndpointWithFallback(
            configs,
            'apimEndPointSkuPrice',
            this.API_ENDPOINTS.skuPrice,
        )
        this.API_ENDPOINTS.redeemCTMoney = this.getEndpointWithFallback(
            configs,
            'apimEndPointRedeemCTMoney',
            this.API_ENDPOINTS.redeemCTMoney,
        )
        this.API_ENDPOINTS.storeFilters = this.getEndpointWithFallback(
            configs,
            'apimEndPointStoreFilters',
            this.API_ENDPOINTS.storeFilters,
        )
        this.API_ENDPOINTS.mergeGuestAuthCart = this.getEndpointWithFallback(
            configs,
            'apimEndPointMergeCarts',
            this.API_ENDPOINTS.mergeGuestAuthCart,
        )
        this.API_ENDPOINTS.placeOrder = this.getEndpointWithFallback(
            configs,
            'apimEndPointPlaceOrder',
            this.API_ENDPOINTS.placeOrder,
        )
        this.API_ENDPOINTS.criticalFitment = this.getEndpointWithFallback(
            configs,
            'apimEndPointCriticalFitment',
            this.API_ENDPOINTS.criticalFitment,
        )
        this.API_ENDPOINTS.addVehicle = this.getEndpointWithFallback(
            configs,
            'apimEndPointAddVehicle',
            this.API_ENDPOINTS.addVehicle,
        )
        this.API_ENDPOINTS.addVehicleImage = this.getEndpointWithFallback(
            configs,
            'apimEndPointAddVehicleImage',
            this.API_ENDPOINTS.addVehicleImage,
        )
        this.API_ENDPOINTS.tireSizes = this.getEndpointWithFallback(
            configs,
            'apimEndPointTireSizes',
            this.API_ENDPOINTS.tireSizes,
        )
        this.API_ENDPOINTS.extraHardware = this.getEndpointWithFallback(
            configs,
            'apimEndPointHardwareAddOn',
            this.API_ENDPOINTS.extraHardware,
        )
        this.API_ENDPOINTS.digitalFlyerData = this.getEndpointWithFallback(
            configs,
            'apimEndpointDigitalFlyerLookUp',
            this.API_ENDPOINTS.digitalFlyerData,
        )
        this.API_ENDPOINTS.updateBulkDeliveryOption = this.getEndpointWithFallback(
            configs,
            'apimEndPointUpdateBulkDeliveryOption',
            this.API_ENDPOINTS.updateBulkDeliveryOption,
        )
        this.API_ENDPOINTS.orderDetails = this.getEndpointWithFallback(
            configs,
            'apimEndPointOrderDetails',
            this.API_ENDPOINTS.orderDetails,
        )
        this.API_ENDPOINTS.triangleTransactionHistory = this.getEndpointWithFallback(
            configs,
            'apimEndpointTransactionHistory',
            this.API_ENDPOINTS.triangleTransactionHistory,
        )
        this.API_ENDPOINTS.triangleMCBanner = this.getEndpointWithFallback(
            configs,
            'apimEndPointTriangleMCBanner',
            this.API_ENDPOINTS.triangleMCBanner,
        )
        this.API_ENDPOINTS.orderHistory = this.getEndpointWithFallback(
            configs,
            'apimEndPointOrderHistory',
            this.API_ENDPOINTS.orderHistory,
        )
        this.API_ENDPOINTS.canadaPostFind = this.getEndpointWithFallback(
            configs,
            'canadaPostFind',
            this.API_ENDPOINTS.canadaPostFind,
        )
        this.API_ENDPOINTS.canadaPostRetrieve = this.getEndpointWithFallback(
            configs,
            'canadaPostRetrieve',
            this.API_ENDPOINTS.canadaPostRetrieve,
        )
        this.API_ENDPOINTS.deleteCart = this.getEndpointWithFallback(
            configs,
            'apimEndpointDeleteCart',
            this.API_ENDPOINTS.deleteCart,
        )
        this.API_ENDPOINTS.orderReturnSlip = this.getEndpointWithFallback(
            configs,
            'apimEndpointOrderReturnSlip',
            this.API_ENDPOINTS.orderReturnSlip,
        )
        this.API_ENDPOINTS.generateSharedCartLink = this.getEndpointWithFallback(
            configs,
            'apimEndpointGenerateSharedCartLink',
            this.API_ENDPOINTS.generateSharedCartLink,
        )
        this.API_ENDPOINTS.getSharedCartTemplate = this.getEndpointWithFallback(
            configs,
            'apimEndpointGetSharedCartTemplate',
            this.API_ENDPOINTS.getSharedCartTemplate,
        )
        this.API_ENDPOINTS.mergeCarts = this.getEndpointWithFallback(
            configs,
            'apimEndpointMergeSharedCarts',
            this.API_ENDPOINTS.mergeCarts,
        )
        this.API_ENDPOINTS.sendSharedCartLink = this.getEndpointWithFallback(
            configs,
            'apimEndpointSendSharedCartLink',
            this.API_ENDPOINTS.sendSharedCartLink,
        )
        this.API_ENDPOINTS.getStoreInitiatedCart = this.getEndpointWithFallback(
            configs,
            'apimEndpointGetStoreInitiatedCart',
            this.API_ENDPOINTS.getStoreInitiatedCart,
        )
        this.API_ENDPOINTS.changeCartDeliveryMode = this.getEndpointWithFallback(
            configs,
            'apimEndpointChangeCartDeliveryMode',
            this.API_ENDPOINTS.changeCartDeliveryMode,
        )
        this.API_ENDPOINTS.changeStoreInitiatedCartDeliveryMode = this.getEndpointWithFallback(
            configs,
            'apimEndpointChangeStoreInitiatedCartDeliveryMode',
            this.API_ENDPOINTS.changeStoreInitiatedCartDeliveryMode,
        )
        this.API_ENDPOINTS.orderNotificationSubscription = this.getEndpointWithFallback(
            configs,
            'apimEndpointOrderNotificationSubscription',
            this.API_ENDPOINTS.orderNotificationSubscription,
        )
        this.API_ENDPOINTS.deliveryOptions = this.getEndpointWithFallback(
            configs,
            'apimEndPointDeliveryOptions',
            this.API_ENDPOINTS.deliveryOptions,
        )
        this.API_ENDPOINTS.cancelOrder = this.getEndpointWithFallback(
            configs,
            'apimEndPointOrderCancellation',
            this.API_ENDPOINTS.cancelOrder,
        )
        this.API_ENDPOINTS.getCancelOrderReasons = this.getEndpointWithFallback(
            configs,
            'apimEndPointGetCancelOrderReasons',
            this.API_ENDPOINTS.getCancelOrderReasons,
        )
        this.API_ENDPOINTS.getSaveForLater = this.getEndpointWithFallback(
            configs,
            'apimEndPointGetSaveForLater',
            this.API_ENDPOINTS.getSaveForLater,
        )
        this.API_ENDPOINTS.removeFromSaveForLater = this.getEndpointWithFallback(
            configs,
            'apimEndPointRemoveFromSaveForLater',
            this.API_ENDPOINTS.removeFromSaveForLater,
        )
        this.API_ENDPOINTS.estimateFees = this.getEndpointWithFallback(
            configs,
            'apimEndPointShippingFees',
            this.API_ENDPOINTS.estimateFees,
        )
        this.API_ENDPOINTS.promoMessage = this.getEndpointWithFallback(
            configs,
            'apimEndPointPromoMessage',
            this.API_ENDPOINTS.promoMessage,
        )
        this.API_ENDPOINTS.getAutomotiveServices = this.getEndpointWithFallback(
            configs,
            'apimEndPointAOAGetServicesList',
            this.API_ENDPOINTS.getAutomotiveServices,
        )
        this.API_ENDPOINTS.getAppointmentAvailability = this.getEndpointWithFallback(
            configs,
            'apimEndPointGetAppointmentAvailability',
            this.API_ENDPOINTS.getAppointmentAvailability,
        )
        this.API_ENDPOINTS.saveAppointmentAvailability = this.getEndpointWithFallback(
            configs,
            'apimEndPointSaveAppointmentAvailability',
            this.API_ENDPOINTS.saveAppointmentAvailability,
        )
        this.API_ENDPOINTS.getDateTimeSlots = this.getEndpointWithFallback(
            configs,
            'apimEndPointAOAGetBranchAvailabilityListForWeb',
            this.API_ENDPOINTS.getDateTimeSlots,
        )
        this.API_ENDPOINTS.bookAutomotiveAppointment = this.getEndpointWithFallback(
            configs,
            'apimEndPointAOAInsertWebAppointment',
            this.API_ENDPOINTS.bookAutomotiveAppointment,
        )
        this.API_ENDPOINTS.cancelAppointment = this.getEndpointWithFallback(
            configs,
            'apimEndPointAOACancelAppointment',
            this.API_ENDPOINTS.cancelAppointment,
        )
        this.API_ENDPOINTS.getAppointmentTime = this.getEndpointWithFallback(
            configs,
            'apimEndPointAOAGetAppointmentTime',
            this.API_ENDPOINTS.bookAutomotiveAppointment,
        )
        this.API_ENDPOINTS.clearTenders = this.getEndpointWithFallback(
            configs,
            'apimEndPointClearTenders',
            this.API_ENDPOINTS.clearTenders,
        )
        this.API_ENDPOINTS.applyGiftCard = this.getEndpointWithFallback(
            configs,
            'apimEndPointApplyGiftCard',
            this.API_ENDPOINTS.applyGiftCard,
        )
        this.API_ENDPOINTS.deleteGiftCard = this.getEndpointWithFallback(
            configs,
            'apimEndPointDeleteGiftCard',
            this.API_ENDPOINTS.applyGiftCard,
        )
        this.API_ENDPOINTS.bookEtireAutomotiveAppointment = this.getEndpointWithFallback(
            configs,
            'apimEndPointAOAInsertWebAppointmentForExistingDocument',
            this.API_ENDPOINTS.bookEtireAutomotiveAppointment,
        )
        this.API_ENDPOINTS.rescheduleAppointment = this.getEndpointWithFallback(
            configs,
            'apimEndPointAOARescheduleAppointment',
            this.API_ENDPOINTS.rescheduleAppointment,
        )
        this.API_ENDPOINTS.validateCardNumber = this.getEndpointWithFallback(
            configs,
            'apimEndPointValidateLoyaltyCard',
            this.API_ENDPOINTS.validateCardNumber,
        )
        this.API_ENDPOINTS.earnCtMoney = this.getEndpointWithFallback(
            configs,
            'apimEndPointEarnCtMoney',
            this.API_ENDPOINTS.earnCtMoney,
        )
        this.API_ENDPOINTS.estimatedDeliveryDate = this.getEndpointWithFallback(
            configs,
            'apimEndPointEstimatedDeliveryDate',
            this.API_ENDPOINTS.estimatedDeliveryDate,
        )
        this.API_ENDPOINTS.redeemCtMoneyPostPurchase = this.getEndpointWithFallback(
            configs,
            'apimEndPointRedeemCTMoneyPostPurchase',
            this.API_ENDPOINTS.redeemCtMoneyPostPurchase,
        )
        this.API_ENDPOINTS.resendVerification = this.getEndpointWithFallback(
            configs,
            'resendVerification',
            this.API_ENDPOINTS.resendVerification,
        )
        this.API_ENDPOINTS.reusableBags = this.getEndpointWithFallback(
            configs,
            'apimEndPointReusableBags',
            this.API_ENDPOINTS.reusableBags,
        )
        this.API_ENDPOINTS.ctMoneyBalance = this.getEndpointWithFallback(
            configs,
            'apimEndPointCTMoneyBalance',
            this.API_ENDPOINTS.ctMoneyBalance,
        )
        this.API_ENDPOINTS.modifyOrderCustomerInfo = this.getEndpointWithFallback(
            configs,
            'apimEndPointModifyOrderCustomerInfo',
            this.API_ENDPOINTS.modifyOrderCustomerInfo,
        )
        this.API_ENDPOINTS.communityOffers = this.getEndpointWithFallback(
            configs,
            'apimEndpointOfferPool',
            this.API_ENDPOINTS.communityOffers,
        )
        this.API_ENDPOINTS.swapOffer = this.getEndpointWithFallback(
            configs,
            'apimEndpointSwapOffer',
            this.API_ENDPOINTS.swapOffer,
        )
        this.API_ENDPOINTS.validateOtpCode = this.getEndpointWithFallback(
            configs,
            'apimEndPointCdsValidateOtpCode',
            this.API_ENDPOINTS.validateOtpCode,
        )
        this.API_ENDPOINTS.updatePassword = this.getEndpointWithFallback(
            configs,
            'apimEndPointCdsUpdatePassword',
            this.API_ENDPOINTS.updatePassword,
        )
        this.API_ENDPOINTS.clickToPaySTHValidation = this.getEndpointWithFallback(
            configs,
            'apimEndPointClickToPaySTHValidation',
            this.API_ENDPOINTS.clickToPaySTHValidation,
        )
        this.API_ENDPOINTS.resetPassword = this.getEndpointWithFallback(
            configs,
            'apimEndPointResetPassword',
            this.API_ENDPOINTS.resetPassword,
        )
        this.API_ENDPOINTS.cartBundle = this.getEndpointWithFallback(
            configs,
            'apimEndPointCartBundle',
            this.API_ENDPOINTS.cartBundle,
        )
    }
}
export { Config as DevConfig }
export default Config
