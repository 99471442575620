import { useEffect } from 'react'
import { createPortal } from 'react-dom'

import { BodyEndProps } from '../Tooltip/Tooltip.types'

/**
 * @param {React.ReactNode} children - Toast.
 * @param {string} elementClassName - Class name of the container on which u want to mount the toast.
 * @return {React.ReactPortal}
 */
const ToastMount = ({ children, elementClassName }: BodyEndProps): React.ReactPortal => {
    const mount = (!!elementClassName && document.getElementsByClassName(elementClassName)[0]) || document.body
    const el = document.createElement('div')
    el.id = 'toastContainer'

    useEffect(() => {
        mount.appendChild(el)
        return () => {
            if (mount.contains(el)) {
                mount.removeChild(el)
            }
        }
    }, [el, mount])

    return createPortal(children, el)
}

export default ToastMount
export { ToastMount }
