import { PREFIX } from '../../config'

// Used for test file
export const vehicleTypes = {
    label: 'Vehicle Type',
    id: 'vehicleType',
    isDisabled: false,
    options: [
        {
            label: 'Car & Truck',
            id: 0,
            selected: true,
        },
        {
            label: 'ATV & UTV',
            id: 1,
        },
        {
            label: 'Motorcycle',
            id: 2,
        },
        {
            label: 'Personal Watercraft',
            id: 3,
        },
        {
            label: 'Snowmobile',
            id: 4,
        },
    ],
}

export const suggestionAttributeName = 'attributes'
export const categoryLevelPrefix = 'cat-level'

export const radioIds = {
    noVehicle: 'Shop with no vehicle',
    addVehicle: 'Add new vehicle',
    tireSize: 'Shop by tire size',
    visitedVehiclePLP: 'visitedVehiclePLP',
    existingVehicleCategory: 'vehicle_',
}

export const tabs = {
    list: 'Options list',
    addVehicle: radioIds.addVehicle,
    shopByTireSize: radioIds.tireSize,
}

export const vehicleSelectorOptions = {
    noVehicle: 'NO_VEHICLE',
    tireSize: 'TIRE_SIZE',
    vehicle: 'VEHICLE',
}

export enum VehicleTypeIds {
    vehicleType = 'vehicleType',
    year = 'year',
    make = 'make',
    model = 'model',
    body = 'body',
    option = 'option',
    existForYears = 'existForYears',
    indexed = 'indexed',
}

export enum FacetList {
    deliveryPickupOptions = 'delivery_pickup_options',
    partFacet = 'sku_application_part_terminology_group',
    positionFacet = 'sku_application_position',
}

export const VehicleTypeIdList = [VehicleTypeIds.vehicleType, VehicleTypeIds.year, VehicleTypeIds.make]

export const pdpURLStringCheck = {
    store: '#store',
    gs: '#gs',
    df: '#df', // This if for digital flyer
}

export const MIN_VEHICLES_COUNT_FOR_RECALL = 2
export const MIN_VEHICLES_COUNT_FOR_RECALL_ON_TIRES_PAGE = 1
export const manufacturerPartNumber = 'MANUFACTURER_PART_NUMBER'
export const addVehicleFormClass = `${PREFIX}-add-vehicle-form`
export const vehicleListClass = `${PREFIX}-vehicles-list`

export enum CtaIds {
    manageVehicles = 'manage-vehicles',
    addVehicle = 'add-vehicle',
    changeSize = 'change-size',
    updateVehicle = 'update-vehicle',
    changeVehicle = 'change-vehicle',
    seePartsThatFit = 'seePartsThatFit',
    changeTireSize = 'change-tire-size',
    shopByVehicle = 'shop-by-vehicle',
    shopByAutoParts = 'shop-by-auto-parts',
    shopByWheelSize = 'shop-by-wheel-size',
    weatherTechBrandPageLink = 'wtPageLink',
}

export enum FitType {
    success = 'success',
    warning = 'warning',
    vehicleWarning = 'vehicleWarning',
}

export const priceTypes = {
    totalCurrentPrice: 'totalCurrentPrice',
    totalOriginalPrice: 'totalOriginalPrice',
}

export const facetsIds = {
    tireSizeId: 'PS_TireRim_Diameter',
    wheelSizeId: 'PS_WheelRim_Diameter',
}
export const tireSizeId = 'ps_tire_size'
export const tireSize = 'tireSize'

export const specValues = {
    YES_EN: 'Y',
    YES_FR: 'O',
}

export const VEHICLE_ATTRIBUTES_DICTIONARY = 'vehicleAttributesDictionary'
export const NON_WORD_CHARACTERS = new RegExp('\\W+')
export const SHOP_BY_VEHICLE_CTA_CLICK = 'shopByVehicleCtaClick'

export const DEFAULT_VEHICLE_SELECTOR_TAB_LABEL = ''
