import React, { useEffect, useState } from 'react'
import { connect, InferableComponentEnhancerWithProps } from 'react-redux'
import { RootState } from '../../../redux/reducers'

import { SeoHelper } from '../../helpers/Seo.helper'
import { pageTypes } from '../../../config'
import { titleSuffix } from './Seo.constants'
import { GlobalPropsHelper } from '../../../analytics/helpers/globalProps'
import { ReduxStateType, SeoHocType } from './Seo.types'
import { getParameterFromURL, queryParameters, Vehicle, sanitizeStringContent } from '@nl/lib'

/**
 * HOC function for SEO implementation
 * @param {React.FC} WrappedComponent
 * @param {string} pageType
 * @return {React.ReactElement}
 */
function seoHoc(WrappedComponent: React.FC, pageType: string): React.FC {
    /**
     * HOC component functional component
     * @param {React.PropsWithChildren<SeoHocWrapperProps>} props
     * @constructor
     */
    const SeoHoc: React.FC<SeoHocType> = props => {
        const [canonicalLink, setCanonicalLink] = useState<HTMLElement | null>(null)
        const isCategoryPage = pageTypes.categoryPages.includes(
            pageType as 'categorylevelN' | 'categorylevel1' | 'categorylevel2',
        )
        const isCanonical = (elType: string) => elType === 'canonical'
        const pageNoFromURL = Number(getParameterFromURL(queryParameters.page))

        /**
         * Check and push title, meta, canonical and hreflang tags
         */
        useEffect(() => {
            const specificPages = {
                isPdpPage: pageType === pageTypes.pdpPage,
                isCategoryPage: isCategoryPage,
                isStorePage: pageType === pageTypes.store,
            }
            let childCompProps
            switch (true) {
                case specificPages.isPdpPage:
                    childCompProps = props.product
                    break
                case specificPages.isCategoryPage:
                    childCompProps = props.plp
                    break
                case specificPages.isStorePage:
                    childCompProps = props.storeDetails
                    break
                default:
                    childCompProps = {}
            }
            const structure = SeoHelper.prepareData(
                childCompProps as ReduxStateType,
                specificPages,
                props.defaultVehicle as Vehicle,
                props.isFitmentRequired as boolean,
                props.shopByTireSize,
                props.urlDefaultVehicle as Vehicle,
                props.isPreselectedFromURL,
            )
            if (structure.title) {
                const globalProps = new GlobalPropsHelper()
                const seometadata = globalProps.readDataAlternateLangDetails()
                const { siteBannerTitle } = seometadata
                document.title = `${structure.title} | ${siteBannerTitle || titleSuffix}`
            }
            if (structure.metaName) {
                structure.metaName.forEach(function (meta) {
                    SeoHelper.removeTagIfExist(`meta[name="${meta.name}"]`)
                    SeoHelper.createMetaTag('name', meta.name, meta.content)
                })
            }
            if (structure.metaProp) {
                structure.metaProp.forEach(function (meta) {
                    SeoHelper.removeTagIfExist(`meta[property="${meta.property}"]`)
                    SeoHelper.createMetaTag('property', meta.property, meta.content)
                })
            }
            if (structure.links) {
                structure.links.forEach(function (link) {
                    SeoHelper.removeTagIfExist(`link[rel=${link.rel}]`)
                })

                structure.links.forEach(function (link) {
                    if (link.rel === 'alternate' && !structure.isAlternateTagRenderEnabled) {
                        return
                    }
                    const linkTag = document.createElement('link')
                    linkTag.rel = link.rel
                    linkTag.href = sanitizeStringContent(link.href)
                    if (link.hreflang) {
                        linkTag.setAttribute('hrefLang', link.hreflang)
                    }
                    const headDom = document.getElementsByTagName('head')[0]
                    const existingLink = Array.from(headDom.children).find(e => {
                        return (
                            (isCanonical(e.getAttribute('rel') as string) && isCanonical(link.rel)) ||
                            (e.getAttribute('rel') === link.rel &&
                                e.getAttribute('href') === link.href &&
                                (link.hreflang ? e.getAttribute('hreflang') === link.hreflang : true))
                        )
                    })
                    if (isCategoryPage && isCanonical(link.rel)) {
                        setCanonicalLink(prevLinkTag => {
                            prevLinkTag && prevLinkTag.remove()
                            return linkTag
                        })
                        return
                    }
                    if (existingLink) {
                        existingLink.remove()
                    }
                    headDom.appendChild(linkTag)
                })
            }
        }, [
            props.product,
            props.plp,
            props.defaultVehicle,
            props.isFitmentRequired,
            props.storeDetails,
            props.shopByTireSize,
            isCategoryPage,
            pageNoFromURL,
            props.urlDefaultVehicle,
            props.isPreselectedFromURL,
        ])

        useEffect(() => {
            if (!canonicalLink || !isCategoryPage) {
                return
            }
            SeoHelper.updateCanonicalLinks(canonicalLink)
        }, [canonicalLink, isCategoryPage, props.productCardData])

        /**
         * Render wrapped component
         * @return {React.ReactElement}
         */
        return <WrappedComponent {...props} />
    }
    /**
     * Map state to props
     * @param {RootState} state
     * @return {any}
     */
    function mapStateToProps(state: RootState) {
        return {
            productCardData: state.productCardData,
            product: state.product.productData,
            plp: state.categoryIdData,
            defaultVehicle: state.userProfile.vehicle.defaultVehicle,
            isFitmentRequired: state.categoryIdData.isFitmentRequired,
            storeDetails: state.storeDetails.storeDetails,
            shopByTireSize: state.vehicleTires.tiresData,
            urlDefaultVehicle: state.userProfile.vehicle.urlDefaultVehicle,
            isPreselectedFromURL: state.preselectedVehicleFromURL.isPreselectedFromURL,
        }
    }
    return (connect<SeoHocType>(mapStateToProps) as InferableComponentEnhancerWithProps<SeoHocType, null>)(
        SeoHoc,
    ) as React.FC
}
export { seoHoc }
