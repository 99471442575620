import { useCallback, useRef } from 'react'
import { IPrevValueCache } from '../../BuyBox/BuyBox.type'
import { StoreGeoPoint } from '../../../redux/models/storeDetails.interface'

/**
 *
 * @param productDataCode
 * @param selectedPreferredStoreId
 * @param geoPoint
 * @param maxRadiusToSearchStore
 * @param selectedProductCode
 */
export const useTrackingDataChange = (
    productDataCode = '',
    selectedPreferredStoreId?: string,
    geoPoint?: StoreGeoPoint,
    maxRadiusToSearchStore?: number,
    selectedProductCode?: string,
): Record<string, () => boolean | void> => {
    const prevValueCache: IPrevValueCache = useRef()

    const isDataChanged = useCallback((): boolean => {
        return (
            prevValueCache.current?.selectedProductCode !== selectedProductCode ||
            prevValueCache.current?.selectedPreferredStoreId !== selectedPreferredStoreId ||
            prevValueCache.current?.geoPoint?.latitude !== geoPoint?.latitude ||
            prevValueCache.current?.geoPoint?.longitude !== geoPoint?.longitude ||
            prevValueCache.current?.maxRadiusToSearchStore !== maxRadiusToSearchStore ||
            prevValueCache.current?.productDataCode !== productDataCode
        )
    }, [
        prevValueCache,
        selectedProductCode,
        geoPoint,
        maxRadiusToSearchStore,
        productDataCode,
        selectedPreferredStoreId,
    ])

    const updatePrevCurrentValue = useCallback(() => {
        if (!prevValueCache.current) prevValueCache.current = {}
        prevValueCache.current.selectedProductCode = selectedProductCode
        prevValueCache.current.selectedPreferredStoreId = selectedPreferredStoreId
        prevValueCache.current.geoPoint = geoPoint
        prevValueCache.current.maxRadiusToSearchStore = maxRadiusToSearchStore
        prevValueCache.current.productDataCode = productDataCode
    }, [
        prevValueCache,
        selectedProductCode,
        geoPoint,
        maxRadiusToSearchStore,
        productDataCode,
        selectedPreferredStoreId,
    ])

    return {
        isDataChanged,
        updatePrevCurrentValue,
    }
}
