import { gigyaEventType } from './gigyaScreen.type'
import { getBannerId } from '../../utils/getCurrentBannerId'
import GigyaService from '../../services/gigyaService/gigya.service'
import { LiteNotificationPreferenceBody } from '../../redux/models/user.preferences.interface'
import { gigyaBannerIdMapping } from './gigya.constants'

const gigyaService = new GigyaService()

/**
 * function to create account info payload object
 * @param {gigyaEventType} event
 * @returns {LiteNotificationPreferenceBody}
 */
const createAccountInfoPayload = (event: gigyaEventType): LiteNotificationPreferenceBody => {
    const bannerId = getBannerId()
    const gigyaBannerId = gigyaBannerIdMapping[bannerId] ?? bannerId
    return {
        email: event.formData['profile.email'],
        postalCode: event.formData['data.eFlyers.postalCode'],
        source: event.formData[`data.marketing.${gigyaBannerId}.source`],
        subscriptionDate: event.formData[`data.marketing.${gigyaBannerId}.subscriptionDate`],
        eFlyer: event.formData['data.enableSubscription'],
        languages: event.formData['profile.languages'],
    }
}

/**
 * function call updateEmailSingUP endpoint for anonymous user
 * @param {gigyaEventType} event
 * @returns {Promise}
 */
export const updateEmailSingUpForAnonymousUser = (event: gigyaEventType) => {
    const accountInfoPayload = createAccountInfoPayload(event)
    return gigyaService.updateAccountInformation(accountInfoPayload)
}
