import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { BREAKPOINTS, PREFIX } from '../config'
import { featureProps } from './Features.type'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { useGlobalResizeEvent } from '../../utils'
import Accordion from '../Accordion'
const renderItem = (name: string, item: unknown): JSX.Element[] => {
    return Object.keys(item).map((key, index) => {
        return (
            <li key={`${name}-${index}`}>
                <p className={`${PREFIX}-features__information`}>{item[key]}</p>
            </li>
        )
    })
}
/**
 * Features component
 *
 * @param {featureProps} props - title, icon, path, featureList
 * @return {JSX.Element} returns Features component
 */

const Features: React.FC<featureProps> = ({ ...props }) => {
    const { featureList, title, isShowTitle, defaultState = true, mobileAccordionMode = false } = props

    /**
     * function to return feature component
     * @return {JSX.Element} returns Features component
     */
    const getFeatures = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-row`}>
                {isArrayNotEmpty(featureList) && (
                    <ul data-testid="features" className={`${PREFIX}-features__list`}>
                        {featureList.map(item => {
                            return renderItem('featureList', item)
                        })}
                    </ul>
                )}
            </div>
        )
    }

    /**
     * function to check whether the device is tablet/mobile/desktop
     */

    const [isMobile, setIsMobile] = useState(window.innerWidth <= BREAKPOINTS.tabletMaxWidth)

    const handleResize = () => {
        setIsMobile(window.innerWidth <= BREAKPOINTS.tabletMaxWidth)
    }

    useGlobalResizeEvent(handleResize)

    return isMobile && mobileAccordionMode ? (
        <div id="features" className={`${PREFIX}-features ${PREFIX}-section`}>
            <Accordion title={title} isHeaderOpen={defaultState}>
                {getFeatures()}
            </Accordion>
        </div>
    ) : (
        <div id="features" className={`${PREFIX}-features`}>
            {isShowTitle && <h3 className={`${PREFIX}-features__header`}>{title}</h3>}
            {getFeatures()}
        </div>
    )
}

Features.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    path: PropTypes.string,
    featureList: PropTypes.any,
}
export default Features
