import { getEnvironment } from '../environments'

const environment = getEnvironment()

export const excludeEndpointFromRemovingGuid = [
    environment.API_ENDPOINTS.mergeWishlist,
    environment.API_ENDPOINTS.placeOrder,
    environment.API_ENDPOINTS.cancelOrder,
    environment.API_ENDPOINTS.miniCartEndpoint,
    environment.API_ENDPOINTS.clickToPaySTHValidation,
    environment.API_ENDPOINTS.cartBundle,
]

/** Add all external systems where credentials or access token to be bypassed for auth scenario */
export const excludeExternalApiSystem = [
    environment.CANADA_POST_BASE_URL,
    new URL(environment.CRITEO_CONFIG.baseUrl).origin,
]

export const excludeWithCredentials = [environment.API_ENDPOINTS.getSearchResults]

export const exludeCartSFL = 'selectivecart'
