/* eslint-disable complexity */
import { ActionType, getType } from 'typesafe-actions'
import { Facet, Status } from '@nl/lib'

import * as actions from '../actionCreators'
import {
    offersFetchInProgressAction,
    offersSuccessAction,
    offersFailureAction,
    offersActivateFailureAction,
    offersActivateSuccessAction,
    clearOffersActivateAction,
    clearOfferActivationErrorAction,
    targetedOfferActivateSuccessAction,
    updateFilteredOffersSortingAction,
    filteredOffersSuccessAction,
    updateFilteredOffersAction,
    filteredOffersFailureAction,
    setLoyaltyOffersSuccessAction,
    communityOffersFailureAction,
    communityOffersSuccessAction,
    swapCommunityOfferSuccessAction,
    swapCommunityOfferFailureAction,
    communitySwappableOffer,
    clearSwapOfferData,
    clearSwapOfferErrorData,
    setIsActivateOfferApiDoneAction,
    updateSelectedFilter,
    updateSelectedFacet,
    filteredCommunityOffersSuccessAction,
    filteredCommunityOffersFailureAction,
    updateCommunityFilterOffersAction,
    updateCommunitySelectedFacet,
    updateCommunitySelectedFilter,
    updateFilteredCommunitySortingAction,
    updateCommunitySortDataAction,
    updateOffersSortDataAction,
    filteredSwappableOffersSuccessAction,
    setIsCommunitySelected,
    filteredSwappableOffersFailureAction,
    updateSwappableSelectedFilter,
    updateSwappableSelectedFacet,
    updateSwappableFilterOffersAction,
    updateFilteredSwappableOfferSortingAction,
    updateSwappableSortDataAction,
    clearFilterOfferData,
    hideEmailActivation,
    setPtePropSuccessAction,
} from '../actionCreators'
import { EmailOfferActivationErrorResponse } from '../models/emailOfferActivation.interface'
import {
    CustomerOffers,
    CommunityOffers,
    OfferActivation,
    Offers,
    SwapOffer,
    TargetedOfferActivation,
    SwapableList,
    LoyaltyOffersProps,
} from '../models/offers.interface'
import { ErrorResponse } from '../models/commonContent.interface'
import { dropdownList } from '../../components/LinkRewards/LinkRewards.types'
import isActionPartOfList from '../utils/isActionPartOfList'
import { ptePropList } from '../../components/PDPOfferBenefits/PDPOfferBenefits.type'
import { EmptyActionType } from '../models/globalData.interface'

type Action = ActionType<typeof actions> | EmptyActionType

export interface OffersState {
    offersData: CustomerOffers | null
    offersActivatedData: OfferActivation | null
    targetedOfferActivatedData: TargetedOfferActivation[] | null
    offersActivatedError: EmailOfferActivationErrorResponse | null
    offersError: boolean | null
    offersFetchStatus: Status | null
    filteredOffersData: FilteredOffers | null
    filteredCommunityOffersData: FilteredOffers | null
    filteredSwappableOffersData: FilteredOffers | null
    loyaltyOffersProps: LoyaltyOffersProps[]
    communityPoolOffers: CommunityOffers | null
    swapOfferData: SwapOffer | null
    targetSwappableOffer: SwapableList
    communityPoolOffersError: boolean | null
    communityCallFinished: boolean
    offerSwapErrorData: ErrorResponse | null
    isActivateOfferApiDone: boolean
    isCommunitySelect?: boolean
    hideEmailActivation: boolean
    potentialToEarnProps: ptePropList
}

export interface FilteredOffers {
    filteredOffers?: Offers[]
    filteredOffersLength?: number
    sortByFilter?: string
    selectedFilters?: Record<string, string[]>
    facetValues?: Facet[]
    sortData?: dropdownList[]
}

export const initialState: OffersState = {
    offersData: {} as CustomerOffers,
    offersActivatedData: {} as OfferActivation,
    targetedOfferActivatedData: {} as TargetedOfferActivation[],
    offersActivatedError: {} as EmailOfferActivationErrorResponse,
    offersError: false,
    filteredOffersData: {} as FilteredOffers,
    filteredCommunityOffersData: {} as FilteredOffers,
    filteredSwappableOffersData: {} as FilteredOffers,
    offersFetchStatus: null,
    loyaltyOffersProps: [] as LoyaltyOffersProps[],
    communityPoolOffers: {} as CommunityOffers,
    swapOfferData: {} as SwapOffer,
    targetSwappableOffer: {} as SwapableList,
    offerSwapErrorData: {} as ErrorResponse,
    communityPoolOffersError: false,
    communityCallFinished: false,
    isActivateOfferApiDone: false,
    isCommunitySelect: false,
    hideEmailActivation: false,
    potentialToEarnProps: {} as ptePropList,
}

const swapOffersActionsList = [
    communityOffersSuccessAction,
    communityOffersFailureAction,
    swapCommunityOfferSuccessAction,
    swapCommunityOfferFailureAction,
    clearSwapOfferData,
    clearSwapOfferErrorData,
    communitySwappableOffer,
    filteredCommunityOffersSuccessAction,
    filteredSwappableOffersSuccessAction,
    setIsCommunitySelected,
    hideEmailActivation,
]

/**
 * Function to perform actions with respect to swap offers
 * @param {OffersState} state
 * @param {Action} action
 * @returns {OffersState}
 */
const swapOffersReducer = (state: OffersState = initialState, action: Action): OffersState => {
    switch (action.type) {
        case getType(communityOffersSuccessAction): {
            return {
                ...state,
                communityPoolOffers: action.payload,
                communityPoolOffersError: false,
                communityCallFinished: true,
            }
        }
        case getType(communityOffersFailureAction): {
            return { ...state, communityPoolOffers: null, communityPoolOffersError: true, communityCallFinished: true }
        }
        case getType(swapCommunityOfferSuccessAction): {
            return { ...state, swapOfferData: action.payload, offerSwapErrorData: null }
        }
        case getType(swapCommunityOfferFailureAction): {
            return { ...state, swapOfferData: null, offerSwapErrorData: action.payload }
        }
        case getType(clearSwapOfferData): {
            return { ...state, swapOfferData: null, communityPoolOffers: null, offersData: null }
        }
        case getType(clearSwapOfferErrorData): {
            return { ...state, offerSwapErrorData: null }
        }
        case getType(communitySwappableOffer): {
            return {
                ...state,
                targetSwappableOffer: action.payload,
            }
        }
        case getType(filteredCommunityOffersSuccessAction): {
            return { ...state, filteredCommunityOffersData: action.payload, offersError: false }
        }
        case getType(filteredSwappableOffersSuccessAction): {
            return { ...state, filteredSwappableOffersData: action.payload, offersError: false }
        }
        case getType(setIsCommunitySelected): {
            return { ...state, isCommunitySelect: action.payload }
        }
        case getType(hideEmailActivation): {
            return { ...state, hideEmailActivation: action.payload }
        }
        default:
            return state
    }
}

export const offersReducer = (state: OffersState = initialState, action: Action): OffersState => {
    if (isActionPartOfList(swapOffersActionsList, action.type)) {
        return swapOffersReducer(state, action)
    }
    switch (action.type) {
        case getType(offersFetchInProgressAction): {
            return { ...state, offersFetchStatus: action.payload, offersError: null }
        }
        case getType(offersSuccessAction): {
            return {
                ...state,
                offersData: action.payload,
                offersError: null,
                offersFetchStatus: Status.DONE,
            }
        }
        case getType(offersFailureAction): {
            return {
                ...state,
                offersData: null,
                offersError: true,
                offersFetchStatus: Status.DONE,
            }
        }
        case getType(offersActivateSuccessAction): {
            return { ...state, offersActivatedData: action.payload, offersActivatedError: null }
        }
        case getType(offersActivateFailureAction): {
            return { ...state, offersActivatedData: null, offersActivatedError: action.payload }
        }
        case getType(clearOffersActivateAction): {
            return { ...state, offersActivatedData: null }
        }
        case getType(clearOfferActivationErrorAction): {
            return { ...state, offersActivatedError: null }
        }
        case getType(targetedOfferActivateSuccessAction): {
            return { ...state, targetedOfferActivatedData: action.payload, offersActivatedError: null }
        }
        case getType(filteredOffersSuccessAction): {
            return { ...state, filteredOffersData: action.payload, offersError: false }
        }
        case getType(updateFilteredOffersSortingAction): {
            return {
                ...state,
                offersError: false,
                filteredOffersData: {
                    ...state.filteredOffersData,
                    sortByFilter: action.payload,
                },
            }
        }

        case getType(updateCommunitySortDataAction): {
            return {
                ...state,
                offersError: false,
                filteredCommunityOffersData: {
                    ...state.filteredCommunityOffersData,
                    sortData: action.payload,
                },
            }
        }
        case getType(updateSwappableSortDataAction): {
            return {
                ...state,
                offersError: false,
                filteredSwappableOffersData: {
                    ...state.filteredSwappableOffersData,
                    sortData: action.payload,
                },
            }
        }

        case getType(updateOffersSortDataAction): {
            return {
                ...state,
                offersError: false,
                filteredOffersData: {
                    ...state.filteredOffersData,
                    sortData: action.payload,
                },
            }
        }
        case getType(updateSelectedFilter): {
            return {
                ...state,
                offersError: false,
                filteredOffersData: {
                    ...state.filteredOffersData,
                    selectedFilters: action.payload,
                },
            }
        }
        case getType(updateCommunitySelectedFilter): {
            return {
                ...state,
                offersError: false,
                filteredCommunityOffersData: {
                    ...state.filteredCommunityOffersData,
                    selectedFilters: action.payload,
                },
            }
        }
        case getType(updateSwappableSelectedFilter): {
            return {
                ...state,
                offersError: false,
                filteredSwappableOffersData: {
                    ...state.filteredSwappableOffersData,
                    selectedFilters: action.payload,
                },
            }
        }

        case getType(updateFilteredCommunitySortingAction): {
            return {
                ...state,
                offersError: false,
                filteredCommunityOffersData: {
                    ...state.filteredCommunityOffersData,
                    sortByFilter: action.payload,
                },
            }
        }
        case getType(updateFilteredSwappableOfferSortingAction): {
            return {
                ...state,
                offersError: false,
                filteredSwappableOffersData: {
                    ...state.filteredSwappableOffersData,
                    sortByFilter: action.payload,
                },
            }
        }

        case getType(updateSelectedFacet): {
            return {
                ...state,
                offersError: false,
                filteredOffersData: {
                    ...state.filteredOffersData,
                    facetValues: action.payload,
                },
            }
        }
        case getType(updateCommunitySelectedFacet): {
            return {
                ...state,
                offersError: false,
                filteredCommunityOffersData: {
                    ...state.filteredCommunityOffersData,
                    facetValues: action.payload,
                },
            }
        }
        case getType(updateSwappableSelectedFacet): {
            return {
                ...state,
                offersError: false,
                filteredSwappableOffersData: {
                    ...state.filteredSwappableOffersData,
                    facetValues: action.payload,
                },
            }
        }

        case getType(updateFilteredOffersAction): {
            return {
                ...state,
                offersError: false,
                filteredOffersData: {
                    ...state.filteredOffersData,
                    filteredOffers: action.payload,
                    filteredOffersLength: action.payload.length,
                },
            }
        }
        case getType(updateCommunityFilterOffersAction): {
            return {
                ...state,
                offersError: false,
                filteredCommunityOffersData: {
                    ...state.filteredCommunityOffersData,
                    filteredOffers: action.payload,
                    filteredOffersLength: action.payload.length,
                },
            }
        }
        case getType(updateSwappableFilterOffersAction): {
            return {
                ...state,
                offersError: false,
                filteredSwappableOffersData: {
                    ...state.filteredSwappableOffersData,
                    filteredOffers: action.payload,
                    filteredOffersLength: action.payload.length,
                },
            }
        }

        case getType(filteredOffersFailureAction): {
            return { ...state, filteredOffersData: null, offersError: true }
        }

        case getType(filteredSwappableOffersFailureAction): {
            return { ...state, filteredSwappableOffersData: null, offersError: true }
        }
        case getType(filteredCommunityOffersFailureAction): {
            return { ...state, filteredCommunityOffersData: null, offersError: true }
        }
        case getType(setLoyaltyOffersSuccessAction): {
            return {
                ...state,
                loyaltyOffersProps: [...state.loyaltyOffersProps, action.payload],
            }
        }

        case getType(setPtePropSuccessAction): {
            return { ...state, potentialToEarnProps: action.payload.data }
        }
        case getType(clearFilterOfferData): {
            return {
                ...state,
                filteredSwappableOffersData: null,
                filteredCommunityOffersData: null,
                filteredOffersData: null,
                isCommunitySelect: false,
                offersFetchStatus: null,
                communityCallFinished: false,
            }
        }
        case getType(setIsActivateOfferApiDoneAction): {
            return { ...state, isActivateOfferApiDone: action.payload }
        }
        default:
            return state
    }
}
