const objShallowCompare = <T>(obj1: T, obj2: T): boolean => {
    const props1 = Object.getOwnPropertyNames(obj1)
    const props2 = Object.getOwnPropertyNames(obj2)

    if (props1.length !== props2.length) {
        return false
    }
    for (let i = 0; i < props1.length; i++) {
        const prop = props1[i] as keyof T

        if (obj1[prop] !== obj2[prop]) {
            return false
        }
    }

    return true
}

export default objShallowCompare
