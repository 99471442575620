import React from 'react'

import { PREFIX } from '../config'
import { IGuestCheckoutProps } from './GuestCheckout.type'

const GuestCheckout: React.FC<IGuestCheckoutProps> = ({ ...props }): JSX.Element => {
    const {
        gLoginImage,
        gLoginImageAltText,
        gLoginDescription,
        gLoginHeader,
        earnCTMoneyToggle,
        isMasterOrRewardCardUser,
    } = props

    // Below function for getting the class name should be removed along with conditional check for incorporating the heading based on earnCTMoneyToggle, once toggle is not required anymore
    /**
     * Function to get the className based earnCTMoneyToggle toggle
     * @returns {string}
     */
    const getClassName = (): string => {
        return !earnCTMoneyToggle ? `${PREFIX}-guest-user-signin` : ''
    }

    return (
        <div className={`${PREFIX}-signin-copy ${getClassName()}`}>
            <div className={`${PREFIX}-login-header--copy`}>
                <img className={`${PREFIX}-login--logo`} src={`${gLoginImage}`} alt={`${gLoginImageAltText}`} />
            </div>
            <div className={`${PREFIX}-guest-user`}>
                {!earnCTMoneyToggle && <div className={`${PREFIX}-login-header--title`}>{gLoginHeader}</div>}
                <div className={`${PREFIX}-login-header`}>
                    {isMasterOrRewardCardUser || !earnCTMoneyToggle ? gLoginDescription : gLoginHeader}
                </div>
            </div>
        </div>
    )
}

export default GuestCheckout
