import React from 'react'

import { PREFIX } from '../config'
import { TransactionBackgroundImageProps } from './TransactionBackgroundImage.type'

/**
 * TransactionBackgroundImage component
 * @param {TransactionBackgroundImageProps} props
 * @returns {JSX.Element} returns annual topup image
 */
const TransactionBackgroundImage: React.FC<TransactionBackgroundImageProps> = props => {
    const { showBg, img, altText } = props

    return showBg ? <img className={`${PREFIX}-transaction-bg`} src={img} alt={altText} /> : null
}

export default TransactionBackgroundImage
