import { useEffect } from 'react'
import { libUtils } from '@nl/lib'

import { RootState } from '../../redux/reducers'
import { getOrderService } from '../../redux/actions'
import { fillCheckoutDataFromAPI, getOrderCancellationReasonsService } from '../../redux/actions'
import getQueryString from '../../utils/getQueryString'
import { getQueryStringParams } from '../../utils/getQueryString'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { storageData } from '../../globalConstants'
import { orderDetailsConfirmationSuccess } from '../../redux/actionCreators'
import { FilteredCartData } from '../../redux/models/cart.interface'
import sessionStorageService from '../../utils/sessionStorageService'
import { profilePendingSelector, userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'
import { isGigyaLoadedSelector } from '../../redux/selectors/gigyaScreenSetData.selectors'
import appCacheService from '../../utils/appCacheService'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'

/**
 * OrderDetailsPage to invoke order details api
 * @return {null}
 */
const OrderDetailsPageInit: React.FC = () => {
    const dispatch = useAppDispatch()
    const { commonContentAvailable } = useAppSelector((state: RootState) => state.commonContent)
    const userProfileData = useAppSelector(userProfileDataSelector)
    const userProfilePending = useAppSelector(profilePendingSelector)
    const isGigyaLoaded = useAppSelector(isGigyaLoadedSelector)
    const isAuthenticated = userProfileData && checkDataLength(userProfileData)
    const lang = libUtils.getLanguage()

    useEffect(() => {
        if (!checkDataLength(commonContentAvailable?.featureFlag)) {
            return
        }
        if (userProfilePending === null && (isGigyaLoaded || appCacheService.gigyaJWTToken.get())) {
            return
        }
        const linkWithoutHashSymbol = window.location.hash?.replace('#', '')
        const orderIdFromQuery = getQueryString(window.location.search, 'orderId')
        const orderNumber = getQueryString(linkWithoutHashSymbol, 'orderNumber')
        const token = getQueryString(linkWithoutHashSymbol, 'token')
        const getOrderNumberFromURLHash = () => (isAuthenticated ? orderNumber : token)
        // OCCP-18252: when user navigates to order details page via email link, orderNumber contains order number (to be used for authenticated user)
        //             and token contains cart guid (to be used for unauthenticated user)
        const isOrderPlacedByUser = () => !!orderIdFromQuery && !!token
        const orderId =
            isOrderPlacedByUser() && !isAuthenticated ? token : orderIdFromQuery || getOrderNumberFromURLHash()
        const userEmail = getQueryString(window.location.search, 'email')
        // OCCP-23648: to accommodate SFS orders, need to pass the SFS orderNumber to CDS along with token since both fields are required by CDS to fetch SFS orders.
        //             this is only required for unauthenticated users as the old CAR order details link in email direct users to login page not order details page
        const sfsOrderNumber = getQueryString(linkWithoutHashSymbol, 'orderNumber')
        const hashedOrderCode = getQueryStringParams(window.location.search, 'hashedCode')
        const orderDetailsFromStorage = JSON.parse(
            sessionStorageService.getItem(storageData.orderStatus) as string,
        ) as FilteredCartData | null

        if (orderDetailsFromStorage) {
            fillCheckoutDataFromAPI(orderDetailsFromStorage.cart, dispatch)
            dispatch(orderDetailsConfirmationSuccess(orderDetailsFromStorage))
            sessionStorageService.removeItem(storageData.orderStatus)
        } else {
            orderId &&
                dispatch(
                    getOrderService(
                        orderId,
                        userEmail,
                        undefined,
                        sfsOrderNumber,
                        hashedOrderCode,
                        commonContentAvailable.featureFlag?.enableMergingOrderDetailsConsignmentAndUnconsignmentEntries,
                    ),
                )
        }
    }, [dispatch, isAuthenticated, commonContentAvailable, userProfilePending, isGigyaLoaded])

    useEffect(() => {
        dispatch(getOrderCancellationReasonsService(lang))
    }, [dispatch, lang])

    return null
}

export default OrderDetailsPageInit
