/* eslint-disable camelcase */

/**
 * Geo Service custom address payload type param enum.
 * @enum
 */
export enum GetPlaceDetailsParamType {
    ADDRESS = 'address',
    PLACE_ID = 'placeId',
    COMPONENT_RESTRICTIONS = 'componentRestrictions',
}

export interface ComponentRestrictions {
    postalCode: string
}

/**
 * Geo service request payload interface
 * @interface
 */
export interface GetPlaceDetailsRequestDTO {
    type: GetPlaceDetailsParamType
    value: string | ComponentRestrictions
}

/**
 * Geo service response type payload interface.
 * @interface
 */
export interface GetPlaceDetailsResultType {
    types: string[]
    formatted_address: string
    address_components: {
        short_name: string
        long_name: string
        postcode_localities: string[]
        types: string[]
    }[]
    place_id: string
    geometry: {
        bounds: Record<string, unknown>
        location: {
            lat: () => number
            lng: () => number
        }
        location_type: string
        viewport: Record<string, unknown>
    }
}

/**
 * Geo service filtered response type interface.
 * @interface
 */
export interface GetPlaceDetailsResponseType {
    lat: number
    long: number
}

/**
 * state variables interface.
 * @interface
 */
export interface SetStorageVariablesType {
    storePredictions: React.Dispatch<React.SetStateAction<AutoCompletePredictionResponseType[]>>
    storeLocationDetails: React.Dispatch<React.SetStateAction<GetPlaceDetailsResponseType>>
}

/**
 * Autocomplete response interface.
 * @interface
 */
export interface AutoCompletePredictionResponseType {
    description: string
    matched_substrings: Record<string, unknown>[]
    place_id: string
    reference: string
    structured_formatting: Record<string, unknown>
    terms: Record<string, unknown>[]
    types: string[]
}

export interface PlaceServiceResponseType {
    setStorageVariables: (
        params: SetStorageVariablesType,
        isPlacesLoaded?: React.Dispatch<React.SetStateAction<boolean>>,
    ) => void
    autoSuggestion: (searchQuery: string) => void
    getPlaceDetails: (requestParams: GetPlaceDetailsRequestDTO) => void
    updateSessionToken: () => void
}

export interface Entry {
    formattedAddress?: string
    latitude?: number
    longitude?: number
    extra?: {
        googlePlaceId?: string
        confidence?: number
    }
    administrativeLevels?: {
        level1long?: string
        level1short?: string
        level2long?: string
        level2short?: string
    }
    city?: string
    streetName?: string
    streetNumber?: string
    countryCode?: string
    zipcode?: string
    provider?: string
    stateCode?: string
    district?: string
    building?: string
}

export interface Query {
    type?: GetPlaceDetailsParamType
    region: string
}

export interface GeocoderInterface {
    geocode(query: Query, cb?: (placeDetails: GetPlaceDetailsResultType[], status: string) => void): Promise<Entry[]>
}

export interface Geocoder {
    new (): GeocoderInterface
}

export interface PlacePredictionsRequest {
    input: string
    sessionToken: string
    componentRestrictions: {
        country: string
    }
    types: [string]
}
export interface AutocompleteServiceInterface {
    getPlacePredictions(
        request: PlacePredictionsRequest,
        cb?: (predictions: AutoCompletePredictionResponseType[], status: string) => void,
    ): Promise<AutoCompletePredictionResponseType>
}
export interface AutocompleteService {
    new (): AutocompleteServiceInterface
}
