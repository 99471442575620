import React, { useEffect, useMemo, useState } from 'react'
import { CustomContentCard, BrazeCardType, getUserProfileStatus, BrazeContentPropsType, ContentCardType } from '@nl/lib'
import brazeService from '../../services/brazeService/braze.service'
import { isAuthFlowExecutedSelector, userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'
import { PREFIX } from '../../config'
import { useAppSelector } from '../../hooks/react-redux.hook'

/**
 * BrazeContent card Component
 * @param {BrazeContentPropsType} props - The props
 * @returns {JSX.Element} Renders BrazeContentCard
 */
const BrazeContentCard: React.FC<BrazeContentPropsType> = ({ ...props }): JSX.Element => {
    const { position } = props
    const [cards, setCards] = useState<BrazeCardType[]>([])
    const isAuthFlowExecuted = useAppSelector(isAuthFlowExecutedSelector)
    const userProfileData = useAppSelector(userProfileDataSelector)
    const { hasLoyalty } = useMemo(
        () => getUserProfileStatus(isAuthFlowExecuted, userProfileData),
        [isAuthFlowExecuted, userProfileData],
    )
    const componentClassName = `${PREFIX}-content-card`

    // useeffect to render content card detail and set
    useEffect(() => {
        if (hasLoyalty)
            // To render custom content card trigger subscribeToContentCardsUpdates event
            brazeService.subscribeToContentCardsUpdates((event: ContentCardType): void => {
                setCards(event.cards)
            })
        // To manual refresh of Braze Content trigger requestContentCardsRefresh
        brazeService.requestContentCardsRefresh()
    }, [cards, hasLoyalty, setCards])
    return (
        <>
            {cards
                .filter(card => card.extras['web_card_location'] === position)
                .map(card => (
                    <div className={componentClassName} key={card.id}>
                        <CustomContentCard
                            cardInfo={card}
                            contentPosition={card.extras['web_position']}
                            type={card.extras['web_type']}
                        />
                    </div>
                ))}
        </>
    )
}
export default BrazeContentCard
