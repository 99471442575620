import { ActionType, getType } from 'typesafe-actions'
import { AOAHeaderState } from '../models/aoaHeader.interface'
import { setAOAHeaderVisible } from '../actionCreators/aoaHeader.actionCreators'

const initialState = {
    isAOAHeaderVisible: true,
}

type Action = ActionType<typeof setAOAHeaderVisible>
export const aoaHeaderReducer = (state: AOAHeaderState = initialState, action: Action): AOAHeaderState => {
    if (action.type === getType(setAOAHeaderVisible)) {
        return { ...state, isAOAHeaderVisible: action?.payload }
    }
    return state
}
