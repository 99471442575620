import { ActionType, getType } from 'typesafe-actions'

import {
    setMiniPDPFlyoutDetailsAction,
    initMiniPDPFlowAction,
    setSelectedVariantValueAction,
    closeMiniPDPFlyoutAction,
    setSelectedVariantIDAction,
    updateSelectedProductCodeAction,
    setMiniPDPAEMProps,
    openMiniPDPFlyoutAction,
    updateScuDataByStoreAction,
    fetchMiniPDPFlyoutDataErrorAction,
    isMiniPDPAtcButtonClicked,
} from '../actionCreators'
import * as actions from '../actionCreators/miniPDPFlyout.actionCreators'
import {
    FulfillmentInfo,
    ImageGalleryInfo,
    MiniPDPData,
    PriceInfo,
    PricesToDisplayInfo,
    ProductDataDetails,
    ProductDataPayload,
    ProductTitleInfo,
    SelectedVariantDataPayload,
    VariantsInfo,
    AEMPropsAndFlags,
    InitDataPayload,
    VariantsDataPayload,
} from '../models/miniPDPFlyout.interface'
import {
    FeeMessage,
    FulfillmentDTOP,
    ItemAvailability,
    ProductResponseData,
    ProductSku,
    ProductSkusData,
    ProductSkusObject,
} from '../models/product.interface'
import {
    checkDataLength,
    checkNotNullAndUndefined,
    combineBadges,
    enableDestructOnUndefinedData,
    isArrayEmpty,
    isArrayNotEmpty,
} from '@nl/lib'
import getVariantData from '../../components/BuyBox/getVariantData'
import { STHCodes, VariantDetails } from '../../components/BuyBox/BuyBox.type'
import {
    checkExpressDeliveryEligibilityForSelectedSku,
    checkIfProductDiscontinuedEOL,
    checkIfProductDiscontinuedTemp,
    checkIsLimitedPdp,
    checkIsRestrictedPDP,
    extractCorporateStatusBasedOnSku,
    extractFulfillmentBasedOnSku,
    findIntersection,
    getSelectedFirstVariantValues,
    getSelectedVariantSKU,
    getVariantDetailsOrder,
    hasValidCurrentPrice,
    isProductOnlineOnly,
    isSingleSku,
    productNotForSth,
} from '../../components/BuyBox/BuyBox.helper'
import { Price } from '../models/cart.interface'
import { selectedFirstVariantKey } from '../../components/MiniPDP/components/MiniBuyBox/MiniBuyBox.constant'
import { MagicNumber } from '../../analytics/analytics.type'
import { isObjectNotEmpty } from '../../utils/isObjectNotEmpty'
import { isNullOrUndefined } from '../../utils/isNullOrUndefined'
import { SizeChartDataType } from '../../components/BuyBox/SizeChart/SizeChart.type'

type Action = ActionType<typeof actions>
export interface miniPDPFlyoutDataState {
    aemPropsAndFlags: AEMPropsAndFlags
    isFlyoutVisible: boolean
    currentCode: string
    productDataList: MiniPDPData
    isFetchingData: boolean
    hasApiError: boolean
    isAtcClicked: boolean
    isQuickLookFromCertona?: boolean
    initColourVariant?: string
}

export const initialState: miniPDPFlyoutDataState = {
    aemPropsAndFlags: {} as AEMPropsAndFlags,
    isFetchingData: false,
    hasApiError: false,
    isFlyoutVisible: false,
    currentCode: '',
    productDataList: {},
    isAtcClicked: false,
}

/**
 *
 * @param state
 * @param action
 */
// eslint-disable-next-line complexity
export const miniPDPFlyoutDataReducer = (
    state: miniPDPFlyoutDataState = initialState,
    action: Action,
): miniPDPFlyoutDataState => {
    switch (action.type) {
        case getType(setMiniPDPAEMProps): {
            return { ...state, aemPropsAndFlags: action.payload }
        }
        case getType(initMiniPDPFlowAction): {
            const { code, selectedColourVariant, isQuickLookFromCertona } = action.payload
            return {
                ...state,
                currentCode: code,
                initColourVariant: selectedColourVariant,
                isFetchingData: true,
                hasApiError: false,
                isAtcClicked: false,
                isQuickLookFromCertona,
            }
        }
        case getType(setSelectedVariantValueAction): {
            return updateVariantValueSelection(state, action.payload)
        }
        case getType(setSelectedVariantIDAction): {
            return updateVariantIdSelection(state, action.payload)
        }
        case getType(setMiniPDPFlyoutDetailsAction): {
            return getFormattedProductData(state, action.payload)
        }
        case getType(updateSelectedProductCodeAction): {
            return getProductUpdatedBySelectedProductCode(state, action.payload)
        }
        case getType(updateScuDataByStoreAction): {
            return getProductUpdatedWithScuDataByStore(state, action.payload)
        }
        case getType(closeMiniPDPFlyoutAction): {
            return { ...state, isFlyoutVisible: false, currentCode: '', isQuickLookFromCertona: false }
        }
        case getType(openMiniPDPFlyoutAction): {
            return getReopenedFlyoutData(state, action.payload)
        }
        case getType(fetchMiniPDPFlyoutDataErrorAction): {
            return {
                ...state,
                currentCode: '',
                initColourVariant: '',
                isFetchingData: false,
                hasApiError: true,
                isQuickLookFromCertona: false,
            }
        }
        case getType(isMiniPDPAtcButtonClicked): {
            return { ...state, isAtcClicked: action.payload }
        }
        default:
            return state
    }
}

/**
 *
 * @param state
 * @param data
 */
export const getFormattedProductData = (
    state: miniPDPFlyoutDataState,
    data: ProductDataPayload,
): miniPDPFlyoutDataState => {
    const { aemPropsAndFlags, currentCode, initColourVariant } = state
    const { productData, variantsProductSkuData, currentStoreId, isMultiSku, sizeChartData } = data
    const isProductMultiSku = isNullOrUndefined(isMultiSku) ? !isSingleSku(productData) : (isMultiSku as boolean)
    const selectedCode = isProductMultiSku ? currentCode : productData.skus[0].code
    const variantsInfo = getVariantsInfo(
        productData,
        variantsProductSkuData,
        selectedCode,
        initColourVariant,
        isProductMultiSku,
        sizeChartData,
    )
    const productTitleInfo = getProductTitleInfo(variantsInfo)
    const imageGalleryInfo = getImageGalleryInfo(variantsInfo)
    const priceInfo = getPriceInfo(variantsInfo)
    const fulfillmentInfo = getFulfillmentInfo(aemPropsAndFlags, variantsInfo, priceInfo.isCurrentPriceAvailable)

    return {
        ...state,
        isFlyoutVisible: true,
        isFetchingData: false,
        hasApiError: false,
        productDataList: {
            ...state.productDataList,
            [productData.code]: {
                currentStoreId,
                productTitleInfo,
                imageGalleryInfo,
                priceInfo,
                variantsInfo,
                fulfillmentInfo,
            },
        },
    }
}

/**
 *
 * @param state
 * @param data
 */
export const getReopenedFlyoutData = (state: miniPDPFlyoutDataState, data: InitDataPayload): miniPDPFlyoutDataState => {
    const { code, selectedColourVariant = '', isMultiSku, isQuickLookFromCertona } = data
    const { productDataList } = state
    const { variantsInfo } = productDataList[code]
    const updatedProductDataPayload = { ...variantsInfo, isMultiSku }
    const updatedState = {
        ...state,
        currentCode: code,
        initColourVariant: selectedColourVariant,
        isAtcClicked: false,
        isQuickLookFromCertona,
    }

    return getFormattedProductData(updatedState, updatedProductDataPayload as ProductDataPayload)
}

/**
 *
 * @param state
 * @param data
 */
export const updateVariantValueSelection = (
    state: miniPDPFlyoutDataState,
    data: SelectedVariantDataPayload,
): miniPDPFlyoutDataState => {
    const { currentCode, productDataList } = state
    const { variantsInfo } = productDataList[currentCode]
    const { variantKey, variantValue, selectedFirstVariantDisplayName } = data
    const displayNameInfo = selectedFirstVariantDisplayName ? { selectedFirstVariantDisplayName } : {}
    return {
        ...state,
        productDataList: {
            ...productDataList,
            [currentCode]: {
                ...productDataList[currentCode],
                variantsInfo: { ...variantsInfo, [variantKey]: variantValue, ...displayNameInfo },
            },
        },
    }
}

/**
 *
 * @param state
 * @param data
 */
export const updateVariantIdSelection = (
    state: miniPDPFlyoutDataState,
    data: SelectedVariantDataPayload,
): miniPDPFlyoutDataState => {
    const { currentCode, productDataList } = state
    const { variantsInfo } = productDataList[currentCode]
    const { variantKey, variantValue } = data
    const updatedVariantsInfo = { ...variantsInfo, [`${variantKey}Id`]: variantValue }
    const updatedProductInfo: Partial<ProductDataDetails> = {}
    if (variantKey === selectedFirstVariantKey) {
        updatedProductInfo.productTitleInfo = getProductTitleInfo(updatedVariantsInfo)
        updatedProductInfo.imageGalleryInfo = getImageGalleryInfo(updatedVariantsInfo)
    }

    return {
        ...state,
        productDataList: {
            ...productDataList,
            [currentCode]: {
                ...productDataList[currentCode],
                variantsInfo: updatedVariantsInfo,
                ...updatedProductInfo,
            },
        },
    }
}

/**
 *
 * @param state
 * @param data
 */
export const getProductUpdatedBySelectedProductCode = (
    state: miniPDPFlyoutDataState,
    data: string,
): miniPDPFlyoutDataState => {
    const { currentCode, productDataList, aemPropsAndFlags } = state
    const { variantsInfo } = productDataList[currentCode]

    const updatedVariantsInfo = getUpdatedVariantsInfo(variantsInfo, data)
    const updatedPriceInfo = getPriceInfo(updatedVariantsInfo)
    const updatedFulfillmentInfo = getFulfillmentInfo(
        aemPropsAndFlags,
        updatedVariantsInfo,
        updatedPriceInfo.isCurrentPriceAvailable,
    )

    return {
        ...state,
        productDataList: {
            ...state.productDataList,
            [currentCode]: {
                ...productDataList[currentCode],
                variantsInfo: updatedVariantsInfo,
                priceInfo: updatedPriceInfo,
                fulfillmentInfo: updatedFulfillmentInfo,
            },
        },
    }
}

/**
 *
 * @param state
 * @param data
 */
export const getProductUpdatedWithScuDataByStore = (
    state: miniPDPFlyoutDataState,
    data: VariantsDataPayload,
): miniPDPFlyoutDataState => {
    const { currentStoreId, variantsProductSkuData } = data
    const { currentCode, productDataList, aemPropsAndFlags } = state
    const { variantsInfo } = productDataList[currentCode]

    const updatedVariantsInfo = getUpdatedVariantsInfo(
        variantsInfo,
        variantsInfo.selectedProductCode,
        variantsProductSkuData,
    )
    const updatedPriceInfo = getPriceInfo(updatedVariantsInfo)
    const updatedFulfillmentInfo = getFulfillmentInfo(
        aemPropsAndFlags,
        updatedVariantsInfo,
        updatedPriceInfo.isCurrentPriceAvailable,
    )

    return {
        ...state,
        productDataList: {
            ...state.productDataList,
            [currentCode]: {
                ...productDataList[currentCode],
                variantsInfo: updatedVariantsInfo,
                priceInfo: updatedPriceInfo,
                fulfillmentInfo: updatedFulfillmentInfo,
                currentStoreId,
            },
        },
    }
}

/**
 *
 * @param variantInfo
 */
export const getProductTitleInfo = (variantInfo: VariantsInfo): ProductTitleInfo => {
    const { selectedFirstVariant, selectedSecondVariant, selectedThirdVariant, productData } = variantInfo
    const { code, name, options, styleCodes } = productData
    const isStyleCodeExist = !!options?.[0]?.values?.find(characteristic => characteristic.styleCodes)
    let curStyleCodes = styleCodes && [...styleCodes]

    if (
        isArrayEmpty(curStyleCodes) &&
        (!!selectedFirstVariant || !!selectedSecondVariant || !!selectedThirdVariant) &&
        isStyleCodeExist
    ) {
        const selectedValues = productData?.options?.reduce((acc, productCharacteristics): any => {
            const firstVariantStyleCodes = productCharacteristics.values?.find(
                characteristic => characteristic.value === selectedFirstVariant,
            )?.styleCodes
            const secondVariantStyleCodes = productCharacteristics.values?.find(
                characteristic => characteristic.value === selectedSecondVariant,
            )?.styleCodes
            const thirdVariantStyleCodes = productCharacteristics.values?.find(
                characteristic => characteristic.value === selectedThirdVariant,
            )?.styleCodes
            const allStyleCodes = [firstVariantStyleCodes, secondVariantStyleCodes, thirdVariantStyleCodes].filter(
                chosenStyleCodes => chosenStyleCodes?.length,
            )
            return [...acc, ...allStyleCodes]
        }, [])
        const result: string[] = isArrayNotEmpty(selectedValues) ? findIntersection(...selectedValues) : []
        curStyleCodes = result
    } else if (isArrayEmpty(curStyleCodes) && isStyleCodeExist) {
        // eslint-disable-next-line
        const allFirstVariantStyleCodes = options?.[0]?.values
            ?.reduce((acc, characteristic): string[] => [...acc, characteristic.styleCodes] as string[], [])
            ?.flat()
            .filter((styleCodesList: string): string[] => styleCodesList?.length) as string[]
        curStyleCodes = Array.from(new Set(allFirstVariantStyleCodes))
    }

    return {
        pcode: code,
        title: name,
        styleCodes: curStyleCodes,
    }
}

/**
 *
 * @param variantInfo
 */
export const getImageGalleryInfo = (variantInfo: VariantsInfo): ImageGalleryInfo => {
    const { productData, variantDetails, selectedFirstVariantId } = variantInfo
    const { images } = productData
    let selectedVariantMediaSet

    if (isArrayNotEmpty(variantDetails.firstVariant?.values)) {
        const { mediaSet } = variantDetails.firstVariant.values.find(item => item.id === selectedFirstVariantId) || {}
        selectedVariantMediaSet = isArrayNotEmpty(mediaSet) ? mediaSet : null
    }

    return { images: selectedVariantMediaSet || images }
}

/**
 *
 * @param variantInfo
 */
export const getPriceInfo = (variantInfo: VariantsInfo): PriceInfo => {
    const { selectedProductCode, isAllVariantsSelected, productSkuData, productData } = variantInfo
    const { feeMessages, code, options } = productData
    const feeMessage = feeMessages?.[0] || ({} as FeeMessage)
    const hideDisclaimer = !isArrayNotEmpty(options)
    const {
        originalPrice: chosenOriginalPrice,
        currentPrice: chosenCurrentPrice,
        badges: optionBadges,
        productSaleCut,
        displayWasLabel: chosenDisplayWasLabel,
        priceMessage: chosenPriceMessage,
        productSaleEndDate,
    } = getProductPricesToDisplay(productData.originalPrice, productData.currentPrice, variantInfo)
    const isCurrentPriceAvailable = productSkuData?.skus?.every(
        skuProduct => !checkNotNullAndUndefined(skuProduct.currentPrice?.value),
    )

    if (
        selectedProductCode &&
        isAllVariantsSelected &&
        selectedProductCode !== code &&
        isArrayNotEmpty(productSkuData?.skus) &&
        productSkuData?.skus[0]
    ) {
        const {
            originalPrice: originalPriceSku,
            currentPrice: currentPriceSku,
            displayWasLabel: displayWasLabelSku,
            discount: DiscountSku,
            badges: skuBadges,
            feeValue: skuFeeValue,
            priceMessage: skuPriceMessage,
            saleCut: skuSaleCut,
        } = productSkuData?.skus?.[0]

        return {
            productDataBySku: {
                feeTitle: feeMessage?.feeTitle,
                feeValue: skuFeeValue,
                originalPrice: originalPriceSku,
                currentPrice: currentPriceSku,
                displayWasLabel: displayWasLabelSku,
                discount: DiscountSku,
                badges: combineBadges(skuBadges, productData.badges),
                feeDisclaimerType: feeMessage?.type,
                priceMessage: skuPriceMessage,
                saleCut: skuSaleCut,
            },
            hideDisclaimer,
            productSaleEndDate,
            isCurrentPriceAvailable,
        }
    }

    const { discount: productDiscount, badges: productBadges } = productData

    return {
        productDataBySku: {
            feeTitle: feeMessage?.feeTitle,
            feeValue: feeMessage?.value,
            originalPrice: chosenOriginalPrice || productData?.originalPrice,
            currentPrice: chosenCurrentPrice || productData?.currentPrice,
            displayWasLabel: chosenDisplayWasLabel,
            discount: productDiscount,
            badges: combineBadges(optionBadges, productBadges),
            feeDisclaimerType: feeMessage?.type,
            priceMessage: chosenPriceMessage,
            saleCut: productSaleCut,
        },
        hideDisclaimer,
        productSaleEndDate,
        isCurrentPriceAvailable,
    }
}

/**
 *
 * @param originalPrice
 * @param currentPrice
 * @param variantsInfo
 */
export const getProductPricesToDisplay = (
    originalPrice: Price | undefined,
    currentPrice: Price,
    variantsInfo: VariantsInfo,
): PricesToDisplayInfo => {
    const { variantDetails, isProductLevel, productData, productSkuData, selectedFirstVariantId } = variantsInfo

    const isStyleCodePricingFieldExist = Boolean(productData?.options?.[0]?.values?.[0]?.currentPrice)

    /**
     *
     */
    const isProductSkuDataAvailable = () => productSkuData?.skus && !isProductLevel

    let priceInfo = {}
    const productInfo = isProductSkuDataAvailable() ? productSkuData?.skus?.[0] : productData

    if (productInfo && !isStyleCodePricingFieldExist) {
        return {
            originalPrice,
            currentPrice,
            badges: productInfo?.badges,
            productSaleCut: productInfo?.saleCut,
            displayWasLabel: productInfo?.displayWasLabel,
            priceMessage: productInfo?.priceMessage,
        }
    } else if (isStyleCodePricingFieldExist) {
        const selectedProductValues = isProductSkuDataAvailable()
            ? productSkuData?.skus?.[0]
            : getSelectedFirstVariantValues(variantDetails, selectedFirstVariantId, productData)

        if (hasValidCurrentPrice(selectedProductValues)) {
            priceInfo = {
                originalPrice: selectedProductValues?.originalPrice,
                currentPrice: selectedProductValues?.currentPrice,
                displayWasLabel: selectedProductValues?.displayWasLabel,
                priceMessage: selectedProductValues?.priceMessage,
                productSaleEndDate: (selectedProductValues?.saleEndDate ||
                    selectedProductValues?.priceValidUntil) as string,
            }
        }
        return {
            ...priceInfo,
            badges: selectedProductValues?.badges || [],
            productSaleCut: selectedProductValues?.saleCut || {},
        }
    }
    return priceInfo
}

/**
 *
 * @param productData
 * @param variantsProductSkuData
 * @param selectedProductCode
 * @param initColourVariant
 */
export const getVariantsInfo = (
    productData: ProductResponseData,
    variantsProductSkuData: ProductSkusData,
    selectedProductCode: string,
    initColourVariant?: string,
    isMultiSku?: boolean,
    sizeChartData?: SizeChartDataType,
): VariantsInfo => {
    const isProductLevel = selectedProductCode === productData?.code
    const variantDetails = getVariantData(productData.options)
    const productSkuData = getProductScuData(variantsProductSkuData, selectedProductCode)
    const { selectedFirstVariant = '', selectedFirstVariantId = '' } = isMultiSku
        ? initFirstVariant(variantDetails, initColourVariant)
        : {}
    const saleEndDate = getSaleEndDate(
        productData,
        variantDetails,
        productSkuData,
        selectedFirstVariantId,
        isProductLevel,
    )
    const isAllVariantsSelected = checkIfAllVariantsSelected(
        productData,
        variantDetails,
        selectedFirstVariantId,
        '',
        '',
    )
    const isAllSkusOOS = checkIsAllSkusOOS(variantsProductSkuData)
    const variantDetailsOrder = getVariantDetailsOrder(productData.options, variantDetails)
    const isSkuInStoreUpdatedBySelectedSku = checkIsSkuInStoreUpdatedBySelectedSku(productSkuData, selectedProductCode)

    return {
        productData,
        variantsProductSkuData,
        productSkuData,
        variantDetails,
        isProductLevel,
        selectedFirstVariant,
        selectedSecondVariant: '',
        selectedThirdVariant: '',
        selectedFirstVariantId,
        selectedSecondVariantId: '',
        selectedThirdVariantId: '',
        selectedProductCode,
        saleEndDate,
        isAllVariantsSelected,
        isAllSkusOOS,
        variantDetailsOrder,
        selectedFirstVariantDisplayName: '',
        isSkuInStoreUpdatedBySelectedSku,
        sizeChartData,
    }
}

/**
 *
 * @param variantsProductSkuData
 */
export const checkIsAllSkusOOS = (variantsProductSkuData: ProductSkusData) => {
    return variantsProductSkuData?.skus?.every((productSku: ProductSku) => {
        const { Corporate, quantity } = productSku?.fulfillment?.availability || {}
        const dcQuantity = Corporate?.Quantity || 0
        return quantity === 0 && dcQuantity === 0
    })
}

/**
 *
 * @param productSkuData
 * @param selectedProductCode
 */
export const checkIsSkuInStoreUpdatedBySelectedSku = (
    productSkuData: ProductSkusData,
    selectedProductCode: string,
): boolean => {
    const skuCodeinStore = productSkuData?.skus?.[0].code || productSkuData?.skus?.[MagicNumber.ONE]?.code
    return skuCodeinStore === selectedProductCode
}

/**
 *
 * @param productData
 * @param variantDetails
 * @param selectedFirstVariantId
 * @param selectedSecondVariantId
 * @param selectedThirdVariantId
 */
export const checkIfAllVariantsSelected = (
    productData: ProductResponseData,
    variantDetails: VariantDetails,
    selectedFirstVariantId: string,
    selectedSecondVariantId: string,
    selectedThirdVariantId: string,
): boolean => {
    if (!isArrayNotEmpty(productData.options)) {
        return true
    } else if (!selectedFirstVariantId) {
        return false
    } else if (isArrayNotEmpty(variantDetails.firstVariant.values)) {
        const selectedColorObj = variantDetails.firstVariant.values.filter(
            item => item.id === selectedFirstVariantId,
        )[0]
        const isSecondVariantAvailable = selectedColorObj?.secondVariant?.length
        const isThirdVariantAvailable = selectedColorObj?.thirdVariant?.length
        if (
            !selectedColorObj ||
            (isSecondVariantAvailable && !selectedSecondVariantId) ||
            (isThirdVariantAvailable && !selectedThirdVariantId)
        ) {
            return false
        }
    }
    return true
}

/**
 *
 * @param productData
 * @param variantDetails
 * @param productSkuData
 * @param selectedFirstVariantId
 * @param isProductLevel
 */
export const getSaleEndDate = (
    productData: ProductResponseData,
    variantDetails: VariantDetails,
    productSkuData: ProductSkusData,
    selectedFirstVariantId: string,
    isProductLevel: boolean,
): string => {
    if (isProductLevel) {
        return (
            productData.saleEndDate ||
            getSelectedFirstVariantValues(variantDetails, selectedFirstVariantId, productData)?.saleEndDate ||
            ''
        )
    }

    return productSkuData?.skus?.[0]?.priceValidUntil
}
/**
 *
 * @param variantsProductSkuData
 * @param selectedProductCode
 */
export const getProductScuData = (
    variantsProductSkuData: ProductSkusData,
    selectedProductCode: string,
): ProductSkusData => {
    const selectedSKUObj =
        variantsProductSkuData?.skus?.find(item => item.code === selectedProductCode) || ({} as ProductSku)
    return { skus: [selectedSKUObj] }
}

/**
 *
 * @param variantDetails
 * @param initColourVariant
 */
export const initFirstVariant = (
    variantDetails: VariantDetails,
    initColourVariant?: string,
): Record<string, string> => {
    const { id, name } =
        (initColourVariant && variantDetails.firstVariant.values.find(value => value.id === initColourVariant)) ||
        variantDetails.firstVariant.values[0]

    return {
        selectedFirstVariant: name,
        selectedFirstVariantId: id,
    }
}

/**
 *
 * @param aemPropsAndFlags
 * @param variantInfo
 * @param isCurrentPriceAvailable
 */
export const getFulfillmentInfo = (
    aemPropsAndFlags: AEMPropsAndFlags,
    variantInfo: VariantsInfo,
    isCurrentPriceAvailable: boolean,
): FulfillmentInfo => {
    const { freeShippingEnabled, isProductLevelSellability } = aemPropsAndFlags
    const { productSkuData, selectedProductCode, isAllVariantsSelected, productData, isProductLevel } = variantInfo
    const fulfillment = getFulfillmentData(variantInfo)
    const { storePickUp, shipToHome } = fulfillment
    const sthCode = fulfillment?.shipToHome?.messageCode ?? ''
    const isSTHOnly = Object.values<string>(STHCodes).includes(sthCode)
    const { orderable, fulfillment: { availability = {} } = {} as FulfillmentDTOP } = productSkuData?.skus?.[0]
    const isAvailableInDC = orderable || isSTHOnly
    const selectedStoreDCQty = fulfillment?.availability?.Corporate?.Quantity || MagicNumber.ZERO
    const selectedVariantSkuData = productData?.skus?.find(variant => variant.code === selectedProductCode)
    const selectedProductDCQuantity = productSkuData?.skus?.find(variant => variant?.code === selectedProductCode)
        ?.fulfillment?.availability?.Corporate?.Quantity
    const isOnlineOnly = isProductOnlineOnly(isAllVariantsSelected, selectedVariantSkuData, selectedProductDCQuantity)
    const { quantity: selectedStoreQty } = enableDestructOnUndefinedData(availability) as ItemAvailability

    const quantityAtStoreDC =
        getInventoryQtyFromSKUForDC(variantInfo, isAvailableInDC, availability) || MagicNumber.ZERO
    const quantityAtStore = getInventoryQtyFromSKU(variantInfo)
    const inStockInSelectedStore = selectedStoreQty > MagicNumber.ZERO && !isSTHOnly && isCurrentPriceAvailable

    const isProductNotForSth = productNotForSth(
        isAllVariantsSelected,
        fulfillment,
        productData,
        freeShippingEnabled as boolean,
    )
    const isOutOfStockAtStoreAndDC = checkIsOutOfStockAtStoreAndDC(selectedStoreQty, orderable, selectedStoreDCQty)
    const corporateStatus = extractCorporateStatusBasedOnSku(productData, selectedProductCode)
    const isProductDiscontinuedEOL = checkIfProductDiscontinuedEOL(corporateStatus)
    const isProductDiscontinued = isProductDiscontinuedEOL || checkIfProductDiscontinuedTemp(corporateStatus)
    const isProductSellable = checkIsProductSellable(variantInfo, isProductLevelSellability)
    const isProductActive = isProductLevel ? !!productData?.active : !!productSkuData?.skus?.[0]?.active
    const hasOnlyBOPISEnabled = Boolean(storePickUp?.enabled) && !shipToHome?.enabled
    const isExpressDeliveryEnabledForProduct = checkExpressDeliveryEligibilityForSelectedSku(
        productData,
        selectedProductCode,
    )
    const isLimitedPDP = checkIsLimitedPdp(isProductActive, isProductSellable)
    const isRestrictedPDP = checkIsRestrictedPDP(productData.messageCode, isProductSellable)

    return {
        fulfillment,
        quantityAtStoreDC,
        storePickUp,
        availability,
        selectedStoreQty,
        selectedVariantSkuData,
        quantityAtStore,
        orderable,
        selectedStoreDCQty,
        shipToHome,
        hasOnlyBOPISEnabled,
        inStockInSelectedStore,
        isSTHOnly,
        isProductNotForSth,
        isOutOfStockAtStoreAndDC,
        isProductDiscontinued,
        isProductDiscontinuedEOL,
        isProductSellable,
        isProductActive,
        isOnlineOnly,
        isExpressDeliveryEnabledForProduct,
        isAvailableInDC,
        isLimitedPDP,
        isRestrictedPDP,
    }
}

/**
 *
 * @param variantInfo
 */
export const getFulfillmentData = (variantInfo: VariantsInfo): FulfillmentDTOP => {
    const { productData, selectedProductCode, productSkuData } = variantInfo

    if (isObjectNotEmpty(productSkuData?.skus?.[0])) {
        return productSkuData.skus[0].fulfillment
    } else if ((productSkuData as unknown as ProductSkusObject)?.skus?.fulfillment) {
        return (productSkuData as unknown as ProductSkusObject)!.skus!.fulfillment!
    }

    const defaultSkuCode = selectedProductCode ? selectedProductCode.replace(/\D/g, '') : ''
    return extractFulfillmentBasedOnSku(productData, defaultSkuCode)
}

/**
 *
 * @param variantInfo
 * @param isAvailableInDC
 * @param availability
 */
export const getInventoryQtyFromSKUForDC = (
    variantInfo: VariantsInfo,
    isAvailableInDC: boolean,
    availability?: ItemAvailability,
): number | undefined => {
    const { productData, selectedProductCode, productSkuData } = variantInfo

    if (!isAvailableInDC) {
        return MagicNumber.ZERO
    }

    if (
        checkDataLength(
            getSelectedVariantSKU(productData, selectedProductCode, productSkuData?.skus?.[0]) as unknown as Record<
                string,
                unknown
            >,
        )
    ) {
        const { availability: DCQty } = getSelectedVariantSKU(
            productData,
            selectedProductCode,
            productSkuData?.skus?.[0],
        ) as FulfillmentDTOP
        return (DCQty?.Corporate?.Quantity as number) || MagicNumber.ZERO
    }

    return availability?.Corporate?.Quantity || MagicNumber.ZERO
}

/**
 *
 * @param variantInfo
 */
export const getInventoryQtyFromSKU = (variantInfo: VariantsInfo): number => {
    const { productData, selectedProductCode, productSkuData } = variantInfo
    if (
        checkDataLength(
            getSelectedVariantSKU(productData, selectedProductCode, productSkuData?.skus?.[0]) as unknown as Record<
                string,
                unknown
            >,
        )
    ) {
        const { availability: storeQty } = getSelectedVariantSKU(
            productData,
            selectedProductCode,
            productSkuData?.skus?.[0],
        ) as FulfillmentDTOP
        return storeQty?.quantity || MagicNumber.ZERO
    }

    return MagicNumber.ZERO
}
/**
 *
 * @param variantInfo
 * @param selectedProductCode
 * @param updatedVariantsProductSkuData
 */
export const getUpdatedVariantsInfo = (
    variantInfo: VariantsInfo,
    selectedProductCode: string,
    updatedVariantsProductSkuData?: ProductSkusData,
): VariantsInfo => {
    const {
        productData,
        variantsProductSkuData,
        variantDetails,
        selectedFirstVariantId,
        selectedSecondVariantId,
        selectedThirdVariantId,
    } = variantInfo
    const actualVariantsProductSkuData = updatedVariantsProductSkuData || variantsProductSkuData
    const isProductLevel = selectedProductCode === productData?.code
    const productSkuData = getProductScuData(actualVariantsProductSkuData, selectedProductCode)
    const saleEndDate = getSaleEndDate(
        productData,
        variantDetails,
        productSkuData,
        selectedFirstVariantId,
        isProductLevel,
    )
    const isAllVariantsSelected = checkIfAllVariantsSelected(
        productData,
        variantDetails,
        selectedFirstVariantId,
        selectedSecondVariantId,
        selectedThirdVariantId,
    )

    const isSkuInStoreUpdatedBySelectedSku = checkIsSkuInStoreUpdatedBySelectedSku(productSkuData, selectedProductCode)
    return {
        ...variantInfo,
        isProductLevel,
        selectedProductCode,
        isAllVariantsSelected,
        saleEndDate,
        productSkuData,
        isSkuInStoreUpdatedBySelectedSku,
        variantsProductSkuData: actualVariantsProductSkuData,
    }
}

/**
 *
 * @param selectedStoreQty
 * @param orderable
 * @param selectedStoreDCQty
 */
export const checkIsOutOfStockAtStoreAndDC = (
    selectedStoreQty: number,
    orderable: boolean,
    selectedStoreDCQty: number,
): boolean =>
    selectedStoreQty === MagicNumber.ZERO && !orderable && (selectedStoreDCQty ?? MagicNumber.ZERO) >= MagicNumber.ZERO

/**
 *
 * @param isProductLevel
 * @param variantInfo
 * @param isProductLevelSellability
 */
export const checkIsProductSellable = (variantInfo: VariantsInfo, isProductLevelSellability: boolean): boolean => {
    const { productData, productSkuData, isProductLevel } = variantInfo

    return isProductLevel || isProductLevelSellability ? !!productData?.sellable : !!productSkuData?.skus?.[0]?.sellable
}
