import { getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import { appendQueryParam } from '@nl/lib'
import {
    ActivateOffersPayload,
    OfferActivation,
    CustomerOffers,
    FetchOffersPayload,
    ActivateTargetedOfferPayload,
    TargetedOfferActivation,
    CommunityOffers,
    CommunityOffersPayload,
    SwapOfferPayload,
    SwapOffer,
} from '../../redux/models/offers.interface'
import BaseService from '../base.service'

const httpClient = getHttpClient()
const environment = getEnvironment()

/**
 * Offers service. All offers related server interaction will be done by this service
 */
class OffersService extends BaseService {
    /**
     * Load offers for logged in customer
     * @param  {FetchOffersPayload} requestPayload
     * @param {string} lmsProfileId
     * @param {string} loyaltyCardNumber
     * @returns {CustomerOffers}
     */
    getOffers(
        requestPayload: FetchOffersPayload,
        lmsProfileId?: string | null,
        loyaltyCardNumber?: string | null,
    ): Promise<{ data: CustomerOffers }> {
        const url: URL = this.createOffersUrl('dssCustomerOffers')
        const params = [lmsProfileId, loyaltyCardNumber]
        const queryUrl = appendQueryParam(url, params)
        return httpClient.apiPost(queryUrl, { ...requestPayload }, undefined, true)
    }

    /**
     * Activate offers for customer
     * @param  {ActivateOffersPayload} requestPayload
     * @param {string} lmsProfileId
     * @param {string} loyaltyCardNumber
     * @returns {CustomerOffers}
     */
    activateOffer(
        requestPayload: ActivateOffersPayload,
        lmsProfileId?: string,
        loyaltyCardNumber?: string,
    ): Promise<{ data: OfferActivation }> {
        const url: URL = this.createOffersUrl('dssActivateCustomerOffers')
        const params = [lmsProfileId, loyaltyCardNumber]
        const queryUrl = appendQueryParam(url, params)
        return httpClient.apiPost(queryUrl, { ...requestPayload }, undefined, true)
    }

    /**
     * Activate targeted offer by offer code in parameters
     * @param {ActivateTargetedOfferPayload} requestPayload
     * @param {boolean} multiOffers
     * @return {TargetedOfferActivation} promise
     */
    activateTargetedOffer(
        requestPayload: ActivateTargetedOfferPayload,
        multiOffers: boolean,
    ): Promise<{ data: TargetedOfferActivation[] }> {
        const url: URL = this.createOffersUrl('dssActivateTargetedOffer')
        const deconstructedParams = `p1=${requestPayload?.p1 ? requestPayload.p1 : ''}&p2=${requestPayload.p2}${
            requestPayload.lang ? `&lang=${requestPayload.lang}` : ''
        }`
        const parsedParams = multiOffers ? deconstructedParams : new URLSearchParams(requestPayload).toString()
        const queryUrl = url.toString() + '?' + parsedParams
        return httpClient.apiPost(queryUrl, undefined, undefined, true)
    }

    /**
     * Create offers url.
     * @param {string} endpoint
     * @return {URL}
     */
    createOffersUrl(endpoint: string): URL {
        return OffersService.createUri(environment.API_ENDPOINTS[endpoint])
    }

    /**
     * Load community offers for logged in customer
     * @param  {CommunityOffersPayload} requestPayload
     * @return {CustomerOffers}
     */
    getCommunityOffers(requestPayload?: CommunityOffersPayload): Promise<{ data: CommunityOffers }> {
        const url: URL = this.createOffersUrl('communityOffers')
        return httpClient.apiGet(url.toString(), { ...requestPayload }, undefined, true)
    }

    /**
     * Swapping offer for customer
     * @param  {SwapOfferPayload} requestPayload
     * @param {string} language
     * @return {CustomerOffers}
     */
    swapOffer(requestPayload?: SwapOfferPayload, language?: string): Promise<{ data: SwapOffer }> {
        const url: URL = this.createOffersUrl('swapOffer')
        const languageParam = `${language ? `&lang=${language}` : ''}`
        const queryUrl = url.toString() + '?' + languageParam
        return httpClient.apiPost(queryUrl, { ...requestPayload }, undefined, true)
    }
}

const offersService = new OffersService()

export { offersService }
export default offersService
