import { statusIcons } from '../globalConstants/global.constant'
import { InlineToastType } from '../../src/components/InlineToast/InlineToast.type'

/**
 * returns the icon based on toast type
 * @param {InlineToastType} toastType
 * @return {string}
 */

export const getToastIconType = (toastType: InlineToastType): string => {
    switch (toastType) {
        case InlineToastType.INFO:
            return statusIcons.infoIcon
        case InlineToastType.WARNING:
            return statusIcons.cautionIcon
        case InlineToastType.ERROR:
            return statusIcons.failureIcon
        default:
            return ''
    }
}
