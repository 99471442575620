import { PREFIX } from '../../config'
import { preProcessSvgUseTag } from '../../utils/preProcessSvgUseTag'

/**
 * authoredTextBlock component
 * @param {string} className
 */
const authoredTextBlock = (className: string | Element): void => {
    preProcessSvgUseTag('js-icon', 'data-icon')

    // Reference to the element where the data prop is added.
    const parentElementRef = typeof className === 'string' ? document.getElementsByClassName(className)[0] : className

    const viewMoreButton = parentElementRef.querySelector('#view-more-button') as HTMLElement
    const viewLessButton = parentElementRef.querySelector('#view-less-button') as HTMLElement
    const disclaimer = parentElementRef.querySelector('#js-legal-disclaimer-block') as HTMLElement
    const container = parentElementRef.querySelector('#legal-container-block') as HTMLElement
    const viewMorecontainer = parentElementRef.querySelector('#seo-copy-view-more') as HTMLElement
    const viewLesscontainer = parentElementRef.querySelector('#seo-copy-view-less') as HTMLElement
    let previousViewLesscontainerContent = ''
    const addEllipseClass = `${PREFIX}-authored-text-block--add-ellipses`

    /**
     * Get the line height of the paragraph.
     * P tag is accessed instead of class because the p tag is a rich text.
     */
    const paragraphLineHeight = window
        .getComputedStyle(container.querySelector('p') as Element)
        .getPropertyValue('line-height')
        .replace(/[^0-9]+/g, '')

    // calculate the height of the block.
    const numberOfLines = Number(parentElementRef.getAttribute('data-numberoflines'))
    const authoredBlockHeight = `${Number(paragraphLineHeight) * numberOfLines}px`

    if (numberOfLines > 0) {
        container.style.height = authoredBlockHeight
        viewLesscontainer.style.display = 'block'
        viewMorecontainer.style.display = 'none'
    } else {
        container.classList.remove(addEllipseClass) // handle when lines is zero.
        container.style.height = '100%'
        container.style.display = 'block'
        viewMoreButton.style.display = 'none'
        viewLessButton.style.display = 'none'
    }

    let showMore = false

    /**
     * view more button event listener
     */
    viewMoreButton.addEventListener('click', () => {
        hideOrShowContent()
        document.getElementById('view-less-button')?.focus()
    })

    /**
     * view less button event listener
     */
    viewLessButton.addEventListener('click', () => {
        hideOrShowContent()
        document.getElementById('view-more-button')?.focus()
    })

    /**
     * to hide Or show Content on the basis of showMore variable and return void
     */
    const hideOrShowContent = (): void => {
        showMore ? showLessButton() : showMoreButton()
        showMore = !showMore
    }

    /**
     * to display the modified content with ellipses and return void
     */
    const showLessButton = (): void => {
        viewLessButton.style.display = 'none'
        viewMoreButton.style.display = 'block'
        container.classList.add(addEllipseClass)
        container.style.height = authoredBlockHeight
        container.style.display = ''
        viewLesscontainer.style.display = 'block'
        viewMorecontainer.style.display = 'none'
        disclaimer.style.paddingBottom = '0'
        showEllipses()
    }

    /**
     * to display the original content and return void
     */
    const showMoreButton = (): void => {
        viewLessButton.style.display = 'block'
        viewLessButton.style.position = 'absolute'
        viewLessButton.style.bottom = '0'
        viewMoreButton.style.display = 'none'
        container.classList.remove(addEllipseClass)
        container.style.height = '100%'
        container.style.display = 'contents'
        viewMorecontainer.style.display = 'block'
        disclaimer.style.position = 'relative'
        disclaimer.style.paddingBottom = '40px'
        if (previousViewLesscontainerContent.length > 0) {
            viewLesscontainer.innerHTML = previousViewLesscontainerContent
        }
    }

    /**
     * truncate content of element according to elementHeight
     * @param {Element} element
     * @param {number} elementHeight
     */
    const truncateContent = (element: Element, elementHeight: number) => {
        const words = element.textContent?.split(' ')
        let truncatedText = ''

        if (Array.isArray(words) && words.length > 0) {
            let totalHeight = 0

            for (const word of words) {
                element.textContent = truncatedText + ' ' + word
                totalHeight = element.clientHeight

                if (totalHeight > elementHeight) {
                    truncatedText += '...'
                    break
                }
                truncatedText += ' ' + word
            }

            element.textContent = truncatedText
        }
    }

    /**
     * to check the height of disclaimer and container and add ellipses and return modifiedData
     */
    const showEllipses = (): void => {
        if (viewLesscontainer?.clientHeight > container?.clientHeight) {
            const childElement = viewLesscontainer.firstElementChild
            const blockHeight = Number(paragraphLineHeight) * numberOfLines
            if (
                childElement !== null &&
                childElement.childNodes[0].nodeType === Node.TEXT_NODE &&
                childElement.childNodes[0].textContent !== ''
            ) {
                previousViewLesscontainerContent = viewLesscontainer.innerHTML
                truncateContent(childElement, blockHeight)
            }
        }
    }

    showEllipses()
}

export { authoredTextBlock }
