import store from '../store'
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'
import { RootState } from '../redux/reducers'

export type AppStore = typeof store
export type AppDispatch = AppStore['dispatch']

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
