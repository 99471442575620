import { BREAKPOINTS } from '../components/config'

/**
 * @description Calculates product per row in product grid according to current device width.
 * See packages/theming/src/scss/variables/_gridView.scss for additional details
 * @returns {number} product per row
 */
export const getProductPerRowCount = (): 2 | 3 | 4 | 5 | 6 => {
    switch (true) {
        case window.innerWidth >= BREAKPOINTS.desktopLarg:
            return 5
        case window.innerWidth > BREAKPOINTS.desktopStandard:
            return 6
        case window.innerWidth >= BREAKPOINTS.desktopMin:
            return 5
        case window.innerWidth >= BREAKPOINTS.tabletPortrait:
            return 4
        case window.innerWidth >= BREAKPOINTS.mobileLandscape:
            return 3
        default:
            return 2 // Return a default value assuming mobile
    }
}
