import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Icon from '../Icon'
import Separator from '../Separator'
import { PREFIX } from '../config'
import { SeparatorStyle } from '../Separator/Separator.types'
import { magicNumber } from '../../utils/magicNumber'
import { ExpandCardTypes } from './ExpandCard.type'
import { BrandingColors } from '../../globalConstants/global.constant'

const ExpandCard: React.FC<ExpandCardTypes> = ({ ...props }): JSX.Element => {
    const { customClass, children, separatorProps, icon } = props
    const { isColorDynamic, style, colors } = separatorProps
    const [isOpen, setIsOpen] = useState(false)
    const className = customClass ? customClass : `${PREFIX}-expand-card-button `
    const type = isOpen ? 'up' : 'down'
    const iconType = icon ? icon : `ct-chevron-${type}`
    const separatorStyle = style ? style : SeparatorStyle.SKEW
    const getColors = isOpen ? colors[0] : colors[magicNumber.ONE]
    const separatorColors = (isColorDynamic ? getColors : colors) as BrandingColors[]

    return (
        <section className={`${className}`}>
            {isOpen && <>{children}</>}
            <Button type="icon_button" id="expand-card" onClick={() => setIsOpen(!isOpen)}>
                <Icon type={iconType} />
            </Button>
            {separatorProps && <Separator colors={separatorColors} style={separatorStyle} />}
        </section>
    )
}

ExpandCard.propTypes = {
    customClass: PropTypes.string,
    children: PropTypes.node,
    icon: PropTypes.string,
}

export default ExpandCard
