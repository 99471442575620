import React, { useState } from 'react'

import { ComparisonTableProps } from './ComparisonTable.type'
import PropTypes from 'prop-types'
import { PREFIX } from '../config'
import Icon from '../Icon'
import Button from '../Button'
import Table from '../Table'

/**
 * Generate ComparisonTable Component
 * @param {props} props ComparisonTable props
 * @returns {JSX.Element} returns ComparisonTable component
 */
const ComparisonTable: React.FC<ComparisonTableProps> = ({ ...props }): JSX.Element => {
    const { rowsOnLoad, comparisonTableData, title, seeMoreLabel, seeLessLabel, tableDefaultState } = props
    const [visibleRows, setVisibleRows] = useState(!tableDefaultState ? rowsOnLoad + 1 : comparisonTableData.length)
    const [isTableExpanded, setIsTableExpanded] = useState(visibleRows === comparisonTableData.length)

    const compareSimilarProductClass = `${PREFIX}-csp`

    /**
     * Function on click of show more/ show less button
     * @returns {void}
     */
    const handleShowMore = (): void => {
        const numberOfHiddenRows = comparisonTableData.length - rowsOnLoad
        setVisibleRows(prevVisibleRows => (isTableExpanded ? rowsOnLoad + 1 : prevVisibleRows + numberOfHiddenRows))
        setIsTableExpanded(!isTableExpanded)
    }

    const visibleData = comparisonTableData.slice(0, visibleRows)
    const buttonChevron = isTableExpanded ? 'ct-chevron-up' : 'ct-chevron-down'

    return (
        <div id={compareSimilarProductClass} className={compareSimilarProductClass}>
            <div className={`${compareSimilarProductClass}__header`}>
                <h2>{title}</h2>
            </div>
            <div className={`${compareSimilarProductClass}__table`}>
                <Table tableRichText={true} tableType="stripped" headerType="column" tableData={visibleData} />
            </div>
            <div className={`${compareSimilarProductClass}__show-more-button`}>
                <Button id="viewProductBtn" type="tertiary" size="medium" onClick={handleShowMore}>
                    {isTableExpanded ? seeLessLabel : seeMoreLabel}
                    <Icon type={buttonChevron} size="sm" />
                </Button>
            </div>
        </div>
    )
}

ComparisonTable.propTypes = {
    rowsOnLoad: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    seeLessLabel: PropTypes.string,
    seeMoreLabel: PropTypes.string,
    tableDefaultState: PropTypes.bool,
}

export default ComparisonTable
