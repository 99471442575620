import getBalance from '../getBalance'
import { cardNumberFormatted } from '../FormatCardNumber/formatCardNumber'
import { LoyaltyCards, LoyaltyCardsData } from './loyaltyCards.type'
import { Types } from '../../globalConstants/global.constant'

const getLoyaltyCards: (data?: LoyaltyCardsData) => LoyaltyCards[] = data => {
    const cardNumber =
        data?.formatCardNumber && typeof data?.formatCardNumber !== Types.Boolean
            ? cardNumberFormatted(data.userProfileData?.loyalty?.cardNumber, data?.cardRegex, data?.cardSeparator)
            : data.userProfileData?.loyalty?.cardNumber
    let value: LoyaltyCards[]

    if (data?.isLoggedInRewards) {
        value = [
            {
                icon: data.cardNumberLabelIcon,
                title: data.cardNumberLabel,
                value: cardNumber,
                showIcon: true,
                iconAlt: data?.cardNumberLabelIconAltText,
            },
            {
                icon: data.rewardsBalanceLabelIcon,
                title: data.rewardsBalanceLabel,
                value: data?.userProfileData?.balance !== null && getBalance(data?.userProfileData?.balance),
                showIcon: true,
                iconAlt: data?.rewardsBalanceLabelIconAltText,
            },
        ]
    } else {
        value = [
            {
                icon: data?.triangleRewardsIcon,
                title: data?.triangleRewardsLabel,
                subtitle: data?.rewardsUpsellingMessage,
                linkLabel: data?.registrationCtaLabel,
                linkCta: data?.rewardsRegisterRichText,
                iconAlt: data?.triangleRewardsIconAltText,
                registerNowLink: data?.registerNowLink,
                registerNowLabel: data?.registerNowLabel,
                registerLabel: data?.registerLabel,
            },
        ]
    }

    return value
}

export default getLoyaltyCards
export { getLoyaltyCards }
