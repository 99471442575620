import React from 'react'

import SizeValue from './SizeValue'
import { PREFIX } from '@nl/app/src/config'
import { TablePropsType } from './SizeChart.type'
import { ROW } from './SizeChart.constant'

/**
 * Table component
 * @param {TablePropsType} props - table data
 * @returns {JSX.Element} returns Table component
 */
const Table: React.FC<TablePropsType> = ({ sizeData, isRowTitleAvailable }): JSX.Element | null => {
    const createInnerHTML = () => {
        return {
            __html: rowTitle,
        }
    }
    const rowTitle = sizeData[0]
    const firstColumnStyle = `${PREFIX}-size-chart__${isRowTitleAvailable ? 'subheader-text' : 'content'}`
    return (
        <tr>
            <th
                scope={ROW}
                className={firstColumnStyle}
                style={isRowTitleAvailable ? { textAlign: 'left' } : undefined}
                dangerouslySetInnerHTML={createInnerHTML()}
            />
            {sizeData.slice(1).map((tableValue, index) => {
                return <SizeValue tableValue={tableValue} key={`tableValueKey-${tableValue}-${index}`} />
            })}
        </tr>
    )
}

export default Table
