import { libUtils, FORWARD_SLASH } from '@nl/lib'
import { categoryURLIdentifier } from '../../config'
import { CategoryBreadCrumb } from '../../redux/models/category.interface'
import { MagicNumber } from '../../analytics/analytics.type'

/**
 * Function to get id's from the url
 * @param {SingleBreadCrumbType[]} breadCrumbData
 * @param {string} shopAllLabel
 * @return {object}
 */
const getCategoriesFromURL = (
    breadCrumbData: CategoryBreadCrumb[],
    shopAllLabel?: string,
): {
    getCategoryIds: () => string[]
    extractCategoryPath: (categoryPath?: string) => string
    extractCategoryId: (urlPath: string) => string
} => {
    const language = libUtils.getLanguage()

    /**
     * Extract Category path from the url i.e. l1/l2/l3-id
     * @param {string} categoryPath
     * @return {string}
     */
    const _extractCategoryPath = (categoryPath?: string): string => {
        const regPattern = new RegExp(
            `(${FORWARD_SLASH}${language}(?:${FORWARD_SLASH}${
                shopAllLabel ?? ''
            })?${FORWARD_SLASH}${categoryURLIdentifier}${FORWARD_SLASH})(.*)(.html)`,
        )
        return regPattern.exec(categoryPath || window.location.pathname)?.[MagicNumber.TWO] as string
    }

    /**
     * Extract Category Id from the url
     * @param {string} urlPath
     * @return {string}
     */
    const _extractCategoryId = (urlPath: string) => {
        const path = _extractCategoryPath(urlPath)
        return path?.slice(path?.lastIndexOf('-') + MagicNumber.ONE)
    }

    /**
     * Extract the id of the category from breadcrumbData of the category api response.
     * @return {string[]}
     */
    const getCategoryIds = (): string[] => {
        const categoryPath = _extractCategoryPath()
        const depth = categoryPath?.match(/\//g)?.length ?? 0
        const categoriesData = breadCrumbData ?? []
        const categoryIds = []
        for (let index = 0; index <= depth; index++) {
            categoriesData[index]?.url && categoryIds.push(_extractCategoryId(categoriesData[index].url as string))
        }
        return categoryIds
    }

    return {
        getCategoryIds,
        extractCategoryPath: _extractCategoryPath,
        extractCategoryId: _extractCategoryId,
    }
}

export { getCategoriesFromURL }
export default getCategoriesFromURL
