import React, { useEffect, useMemo, useRef } from 'react'
import classNames from 'classnames'
import { SwiperRef } from 'swiper/react'

import { CarouselSwiper, SanitizeStringContentWrapper, useIsMobile } from '@nl/lib'
import { ImageGalleryProps, GallerySwiper } from './ImageGallery.type'
import { commonContentAvailableSelector } from '../../../../redux/selectors/commonContent.selectors'
import { IAccessibility } from '../../../../redux/models/commonContent.interface'
import { MagicNumber } from '../../../../analytics/analytics.type'
import { breakpointSettings } from './ImageGallery.constant'
import { getImageThumbnailsList, updateImagesWithSizes } from './ImageGallery.helper'
import { useAppSelector } from '../../../../hooks/react-redux.hook'

/**
 *
 * @param props
 */
export const ImageGallery: React.FC<ImageGalleryProps> = props => {
    const {
        imageGalleryInfo: { images },
    } = props

    const isMobile = useIsMobile()
    const updatedImages = useMemo(() => updateImagesWithSizes(images), [images])
    const imageThumbnailsList = useMemo(() => getImageThumbnailsList(updatedImages), [updatedImages])
    const carouselRef = useRef<HTMLElement>(null)
    const { accessibility: { a11yCarouselNextLabel, a11yCarouselPreviousLabel } = {} as IAccessibility } =
        useAppSelector(commonContentAvailableSelector)
    const hasOneImage = updatedImages.length === MagicNumber.ONE

    const imageContainers = (): JSX.Element[] => {
        return updatedImages.map(({ url, altText }) => (
            <div className="image-gallery-item" key={url}>
                <SanitizeStringContentWrapper stringContent={url}>
                    {memoizedStringContent => <img src={memoizedStringContent} aria-label={altText} title={altText} />}
                </SanitizeStringContentWrapper>
            </div>
        ))
    }

    useEffect(() => {
        if (images?.length > 1 && carouselRef.current) {
            const swiperContainer = carouselRef.current as unknown as HTMLElement
            const swiper = swiperContainer?.swiper as unknown as GallerySwiper
            const paginationContainer = swiperContainer.querySelectorAll('.swiper-pagination-horizontal')?.[0]
            const paginationElementList = Array.from(paginationContainer?.children || [])
            paginationElementList.forEach((elem: HTMLElement, index) => {
                elem.style.backgroundImage = `url(${imageThumbnailsList[index].url})`
            })
            swiper.slideTo(0)
        }
    }, [images, carouselRef, imageThumbnailsList])

    if (!images?.length) {
        return null
    }

    return (
        <div className="image-gallery-wrapper">
            <CarouselSwiper
                sliderRef={carouselRef as SwiperRef}
                carouselList={imageContainers()}
                a11yCarouselPreviousLabel={a11yCarouselPreviousLabel}
                a11yCarouselNextLabel={a11yCarouselNextLabel}
                breakpointSettings={breakpointSettings}
                centerInsufficientSlides={false}
                infiniteScrolling
                dynamicBullet={{ show: true, count: isMobile ? MagicNumber.THREE : MagicNumber.FOUR }}
                carouselClassName={classNames('image-gallery-carousel', {
                    ['single-image-gallery']: hasOneImage,
                })}
            />
        </div>
    )
}
