import { statusLabel } from '../globalConstants/global.constant'
import { ButtonStatusType } from './ButtonStatus/getButtonStatus.type'
import { magicNumber } from './magicNumber'
import { replaceMultipleStrWithDynamicVal } from './replaceStrWithDynamicVal'
import { Offers } from '../components/OfferCard/OfferCard.type'

/**
 * Get header button Label.
 * @param {Offers[]} filteredOffers
 * @param {ButtonStatusType}  allOffersActivated
 * @param {string}  activateOfferLabel
 * @param {string}  activateAllOffersLabel
 * @return {string}
 */
export const getHeaderButtonLabel = (
    filteredOffers: Offers[],
    allOffersActivated: ButtonStatusType,
    activateOfferLabel: string,
    activateAllOffersLabel: string,
): string => {
    // Offer object/s that are in activate status
    const offersInActivateStatus =
        filteredOffers &&
        filteredOffers.filter(
            item => item?.offerStatus?.toLocaleLowerCase() === statusLabel.activateStatus?.toLocaleLowerCase(),
        )
    return allOffersActivated
        ? allOffersActivated?.label
        : replaceMultipleStrWithDynamicVal(
              offersInActivateStatus?.length === magicNumber.ONE ? activateOfferLabel : activateAllOffersLabel,
              [(offersInActivateStatus?.length).toString()],
          )
}
