import { PREFIX } from '../config'

/**
 * Toaster constant
 */
export const timeOutValue = 6000

export const secondaryNavigation = `${PREFIX}-secondary-navigation`

// Top headers and Banners class Names.
// These are the AEM class names.
// These can also be react classes if the aem is direct parent of the react class
export const primaryNavigationAEMClassName = `${PREFIX}-primary-navigation`
export const checkoutSimpleHeaderAEMClassName = `${PREFIX}-simple-header-checkout`
export const headers = [
    'pencil-banner',
    primaryNavigationAEMClassName,
    secondaryNavigation,
    `${PREFIX}-information-banner`,
    checkoutSimpleHeaderAEMClassName,
]
export const headersOnScroll = [primaryNavigationAEMClassName, secondaryNavigation, checkoutSimpleHeaderAEMClassName]
export const stickyHeadersOnScroll = [
    primaryNavigationAEMClassName,
    secondaryNavigation,
    checkoutSimpleHeaderAEMClassName,
]
