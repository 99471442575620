import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators'
import { CriticalFitment } from '@nl/lib'

export interface AutomotiveAccordionState {
    criticalFitmentList: CriticalFitment[] | null
    skuOnLoad: string | null
    isOpen: boolean | null
}

type Action = ActionType<typeof actions>

export const initialState: AutomotiveAccordionState = {
    criticalFitmentList: null,
    skuOnLoad: null,
    isOpen: null,
}

export const automotiveAccordionReducer = (
    state: AutomotiveAccordionState = initialState,
    action: Action,
): AutomotiveAccordionState => {
    switch (action.type) {
        case getType(actions.setCriticalFitmentListForAutomotiveAccordion):
            return {
                ...state,
                criticalFitmentList: action.payload,
            }
        case getType(actions.setAccordionOpenState):
            return { ...state, isOpen: action.payload }
        case getType(actions.setSkuOnLoad):
            return { ...state, skuOnLoad: action.payload }
        case getType(actions.resetAutomotiveAccordion):
            return { ...state, criticalFitmentList: null, skuOnLoad: null, isOpen: null }
        default:
            return state
    }
}
