import md5 from 'md5-hash'
import { RootState } from '../reducers'
import { userProfileUID, profilePendingSelector } from './userProfile.selectors'

import { UserTruefit, TruefitProps } from '../types/truefit/userTruefit.type'
import { libUtils } from '@nl/lib'

const md5OrEmpty = (input?: string): string => (input ? String(md5(input)) : '')

export const userTruefitSelector = (state: RootState): UserTruefit => {
    const userId = md5OrEmpty(userProfileUID(state))
    const isPending = profilePendingSelector(state)
    const locale = libUtils.getLanguageCA()
    const isRegistered = isPending || !!userId
    const baseProps: TruefitProps = {
        'data-locale': locale,
        'data-registered': isRegistered,
    }
    const props: TruefitProps = isRegistered ? { 'data-userid': userId, ...baseProps } : baseProps

    return { props, isPending }
}

export const enableTruefitSelector = (state: RootState): boolean | undefined =>
    state.commonContent?.commonContentAvailable?.featureFlag?.enableTruefit
export const truefitSizeDescriptorsSelector = (state: RootState): string[] =>
    state.commonContent?.commonContentAvailable?.truefitConfig?.truefitSizeDescriptors || []
