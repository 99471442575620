const breakpointInfo = {
    slidesToShow: 1,
    slidesPerGroup: 1,
    spacing: 0,
}

export const breakpointSettings = {
    xs: breakpointInfo,
    sm: breakpointInfo,
    md: breakpointInfo,
    lg: breakpointInfo,
}
