import { createAction } from 'typesafe-actions'
import {
    WeatherTechStartTab,
    WeatherTechVehicle,
    WeatherTechVehicleSelectorModeOptions,
    WeatherTechErrorVehicle,
} from '../models/weatherTech.interface'
import {
    SET_IS_WEATHER_TECH_FLOW,
    SET_IS_WEATHER_TECH_PRODUCTS_PRESENT,
    SET_WEATHER_TECH_VEHICLE,
    SET_WEATHER_TECH_ERROR_VEHICLE,
    SET_WEATHER_TECH_VEHICLE_VENDOR_RESULTS,
    SET_INITIAL_WEATHER_TECH_PRODUCT_FETCH_NEEDED,
    SET_SHOW_ERROR_TOAST,
    SET_WEATHER_TECH_VEHICLE_OPTIONS,
} from '../types/weatherTech/weatherTech.actionTypes.constant'

export const setWeatherTechVehicle = createAction(
    SET_WEATHER_TECH_VEHICLE,
    (payload: WeatherTechVehicle | null) => payload,
)<WeatherTechVehicle>()

export const setWeatherTechErrorVehicle = createAction(
    SET_WEATHER_TECH_ERROR_VEHICLE,
    (payload: WeatherTechErrorVehicle | null) => payload,
)<WeatherTechVehicle>()

export const setWeatherTechVehicleVendorResults = createAction(
    SET_WEATHER_TECH_VEHICLE_VENDOR_RESULTS,
    (payload: string[]) => payload,
)<string[]>()

export const setIsWeatherTechFlow = createAction(SET_IS_WEATHER_TECH_FLOW, (payload: boolean) => payload)<boolean>()

export const setIsWeatherTechProductsPresent = createAction(
    SET_IS_WEATHER_TECH_PRODUCTS_PRESENT,
    (payload: boolean) => payload,
)<boolean>()

export const setInitialWeatherTechProductFetchNeeded = createAction(
    SET_INITIAL_WEATHER_TECH_PRODUCT_FETCH_NEEDED,
    (payload: boolean) => payload,
)<boolean>()

export const setShowErrorToast = createAction(SET_SHOW_ERROR_TOAST, (payload: boolean) => payload)<boolean>()

export const setWeatherTechVehicleSelectorModeOptions = createAction(
    SET_WEATHER_TECH_VEHICLE_OPTIONS,
    (payload: WeatherTechVehicleSelectorModeOptions) => payload,
)<WeatherTechVehicleSelectorModeOptions>()

export const hideWeatherTechVehicleSelector = createAction(SET_WEATHER_TECH_VEHICLE_OPTIONS, () => ({
    show: false,
}))<WeatherTechVehicleSelectorModeOptions>()

export const showWeatherTechVehicleSelectorTab = createAction(
    SET_WEATHER_TECH_VEHICLE_OPTIONS,
    (keepStagesInMemory: boolean, returnToStartStage?: boolean, hasCancelButton?: boolean) => ({
        show: true,
        startTab: WeatherTechStartTab.VEHICLE_ATTRIBUTE,
        keepStagesInMemory,
        hasCancelButton,
        returnToStartStage,
    }),
)<WeatherTechVehicleSelectorModeOptions>()

export const showWeatherTechAvailableAutoPartsTab = createAction(SET_WEATHER_TECH_VEHICLE_OPTIONS, () => ({
    show: true,
    startTab: WeatherTechStartTab.AVAILABLE_PARTS,
    keepStagesInMemory: true,
    hasCancelButton: true,
    returnToStartStage: true,
}))<WeatherTechVehicleSelectorModeOptions>()

export const setReturnToStartStage = createAction(SET_WEATHER_TECH_VEHICLE_OPTIONS, () => ({
    returnToStartStage: true,
}))<WeatherTechVehicleSelectorModeOptions>()

export const resetReturnToStartStage = createAction(SET_WEATHER_TECH_VEHICLE_OPTIONS, () => ({
    returnToStartStage: false,
}))<WeatherTechVehicleSelectorModeOptions>()

export const selectWeatherTechTab = createAction(
    SET_WEATHER_TECH_VEHICLE_OPTIONS,
    (startTab: WeatherTechStartTab.AVAILABLE_PARTS) =>
        ({
            startTab,
        } as WeatherTechVehicleSelectorModeOptions),
)<WeatherTechVehicleSelectorModeOptions>()
