import React, { useState, useRef } from 'react'
import { PREFIX } from '../config'
import Icon from '../Icon/Icon'
import Tooltip from '../Tooltip'
import { addModalAttribute } from '../../utils/modalAccessibility'
import { RestrictedPromoMessagesType } from './PromoMessage.type'
import { isEnterPressed } from '../../helpers/checkKeyboardKey.helper'

/**
 * RestrictedPromoMessage component
 * @param {RestrictedPromoMessageType} props - a11yTooltipIcon, a11yCloseIconLabel, restrictedPromoMessage
 * @return {JSX.Element} returns RestrictedPromoMessage component
 */
const RestrictedPromoMessage: React.FC<RestrictedPromoMessagesType> = ({
    a11yTooltipIcon,
    a11yCloseIconLabel,
    restrictedPromoMessage,
    isModalPresent,
}): JSX.Element | null => {
    const [saveTooltipVisibility, setSaveTooltipVisibility] = useState(false)
    const saveTooltipButtonRef = useRef()

    const toolTipButtonHandler = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
        visibilitySetter: (value: React.SetStateAction<boolean>) => void,
        currentVisibility: boolean,
    ) => {
        e.stopPropagation()
        e.preventDefault()
        addModalAttribute(e)
        visibilitySetter(!currentVisibility)
    }

    /**
     * function to tool tip button when enter is pressed from keyboard
     * @param {React.KeyboardEvent<HTMLElement>} e
     * @param {React.FunctionComponent} visibilitySetter
     * @param {boolean} currentVisibility
     * @return {void}
     */
    const toolTipButtonKeyboardHandler = (
        e: React.KeyboardEvent<HTMLElement>,
        visibilitySetter: (value: React.SetStateAction<boolean>) => void,
        currentVisibility: boolean,
    ): void => {
        isEnterPressed(e.key) && toolTipButtonHandler(e, visibilitySetter, currentVisibility)
    }

    return restrictedPromoMessage.label ? (
        <div
            key={`${restrictedPromoMessage.label}-${restrictedPromoMessage.tooltip}`}
            className={`${PREFIX}-promo__message`}>
            <span className={`${PREFIX}-promo__message-label`}>{restrictedPromoMessage.label}</span>
            {restrictedPromoMessage.tooltip && (
                <button
                    ref={saveTooltipButtonRef}
                    className={`${PREFIX}-promo__tooltip-btn`}
                    onClick={event => toolTipButtonHandler(event, setSaveTooltipVisibility, saveTooltipVisibility)}
                    onKeyDown={event =>
                        toolTipButtonKeyboardHandler(event, setSaveTooltipVisibility, saveTooltipVisibility)
                    }
                    aria-label={a11yTooltipIcon}>
                    <span className={saveTooltipVisibility ? `${PREFIX}-promo__tooltip-btn--active` : ''}>
                        <Icon type="ct-information-details" size="md" />
                    </span>
                </button>
            )}
            {!!saveTooltipVisibility && (
                <Tooltip
                    visibility={saveTooltipVisibility}
                    setVisibility={setSaveTooltipVisibility}
                    iconID="ct-close"
                    headerText={restrictedPromoMessage.label}
                    bodyText={restrictedPromoMessage.tooltip}
                    coords={saveTooltipButtonRef.current}
                    a11yCloseIconLabel={a11yCloseIconLabel}
                    isModalPresent={isModalPresent}
                />
            )}
        </div>
    ) : null
}

export default RestrictedPromoMessage
