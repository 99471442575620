export const rangeFilterInputId = 'range'
export const rangeErrorIcon = 'ct-warning-triangle-sm'
export const uomFont = '14px Lato, sans-serif'
export const pixelText = 'px'
export const contextType = '2d'
export const canvasTagName = 'canvas'
export const suffixUomTestId = {
    minSuffixUomId: 'min-input-suffix-uom',
    maxSuffixUomId: 'max-input-suffix-uom',
}
