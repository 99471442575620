import { RootState } from '../reducers'
import { RecommendationsDataState } from '../reducers/recommendationsData.reducer'

export const recommendationsDataSelector = (state: RootState): RecommendationsDataState => state.recommendationsData
export const recommendationAtcStatus = (state: RootState): boolean =>
    recommendationsDataSelector(state).recommendationsAtcClicked
export const recommendShopTheLookAtcStatus = (state: RootState): boolean =>
    recommendationsDataSelector(state).isRecommendShopTheLookAtcClicked
export const recommendShoppableAtcStatus = (state: RootState): boolean =>
    recommendationsDataSelector(state).isRecommendShoppableAtcClicked
