import { ActionType, getType } from 'typesafe-actions'
import { setCommonContentFetchSuccess, setCommonAPIContentSuccess, setCommonContentLoadStatus } from '../actionCreators'
import { IGlobalProps, GlobalAPIProps } from '../models/commonContent.interface'
import { EmptyActionType } from '../models/globalData.interface'

type Action =
    | ActionType<typeof setCommonContentFetchSuccess>
    | ActionType<typeof setCommonAPIContentSuccess>
    | ActionType<typeof setCommonContentLoadStatus>
    | EmptyActionType

export interface CommonContentState {
    commonContentAvailable: IGlobalProps
    commonAPIContentAvailable: GlobalAPIProps
    isCommonContentAvailableInit: boolean
    commonContentLoadStatus: string
}

export const initialState: CommonContentState = {
    commonContentAvailable: {} as IGlobalProps,
    commonAPIContentAvailable: {} as GlobalAPIProps,
    isCommonContentAvailableInit: false,
    commonContentLoadStatus: '',
}

export const commonContentReducer = (state: CommonContentState = initialState, action: Action): CommonContentState => {
    if (action.type === getType(setCommonContentFetchSuccess)) {
        return { ...state, commonContentAvailable: action.payload, isCommonContentAvailableInit: true }
    } else if (action.type === getType(setCommonAPIContentSuccess)) {
        return { ...state, commonAPIContentAvailable: action.payload }
    } else if (action.type === getType(setCommonContentLoadStatus)) {
        return { ...state, commonContentLoadStatus: action.payload }
    } else {
        return state
    }
}
