import { createAction } from 'typesafe-actions'

import {
    SHOPPING_PREFERENCE_SUCCESS,
    SHOPPING_PREFERENCE_ERROR,
    SHOPPING_PREFERENCE_UPDATE,
    SHOPPING_PREFERENCE_UPDATE_ERROR,
    SHOPPING_PREFERENCE_CLEAR,
    NOTIFICATION_PREFERENCE_SUCCESS,
    NOTIFICATION_PREFERENCE_ERROR,
    NOTIFICATION_PREFERENCE_UPDATE,
    NOTIFICATION_PREFERENCE_CLEAR,
    NOTIFICATION_PREFERENCE_UPDATE_ERROR,
    NOTIFICATION_PREFERENCE_UPDATE_CLEAR,
} from '../types/profile/user.preferences.actionTypes.constant'
import { NotificationPreference, PreferencesJnjtraitsSuccessResponse } from '../models/user.preferences.interface'

// Notification preference actions
export const notificationPreferenceSuccess = createAction(NOTIFICATION_PREFERENCE_SUCCESS)<NotificationPreference>()

export const notificationPreferenceError = createAction(NOTIFICATION_PREFERENCE_ERROR)()

export const notificationUpdateSuccessAction = createAction(
    NOTIFICATION_PREFERENCE_UPDATE,
    (payload: boolean) => payload,
)<boolean>()
export const notificationUpdateErrorAction = createAction(NOTIFICATION_PREFERENCE_UPDATE_ERROR)()

export const resetNotificationPreferenceAction = createAction(NOTIFICATION_PREFERENCE_CLEAR)()
export const resetNotificationPreferenceUpdateAction = createAction(NOTIFICATION_PREFERENCE_UPDATE_CLEAR)()

// Shpping preference actions
export const shoppingPreferenceSuccess =
    createAction(SHOPPING_PREFERENCE_SUCCESS)<PreferencesJnjtraitsSuccessResponse>()

export const shoppingPreferenceError = createAction(SHOPPING_PREFERENCE_ERROR)()

export const shoppingUpdateSuccessAction = createAction(
    SHOPPING_PREFERENCE_UPDATE,
    (payload: boolean) => payload,
)<boolean>()
export const shoppingUpdateErrorAction = createAction(SHOPPING_PREFERENCE_UPDATE_ERROR)()

export const resetShoppingPreferenceAction = createAction(SHOPPING_PREFERENCE_CLEAR)()
