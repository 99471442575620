import React from 'react'
import { SpinnerButtonProps } from './Button.types'
import { Size } from '../../types'
import { SpinnerDefaultProps, CommonButtonClass } from './Button.constants'
import Icon from '../Icon'
import Spinner from '../Spinner'

/**
 * Buttons that contains a spinner
 * @param {SpinnerButtonProps} props Button props
 * @param {React.ForwardedRef<HTMLButtonElement>} Button with ref
 * @returns {JSX.Element} returns Button spinner component
 */
const SpinnerButton: React.FC<SpinnerButtonProps> = React.forwardRef<
    HTMLButtonElement,
    React.PropsWithChildren<SpinnerButtonProps>
>(({ ...props }, ref): JSX.Element => {
    const {
        color,
        spinner,
        variant,
        type,
        disabled,
        customClass,
        showSpinner,
        children,
        icon,
        onClick,
        reverse,
        id,
        onKeyDown,
        a11y,
        size,
        qm,
    } = props
    const variantClass = `${CommonButtonClass}--${variant} ${CommonButtonClass}--${variant}-${color} `
    const isReverse = reverse ? ` ${CommonButtonClass}--reverse` : '' // will only work if icon & children are available
    const customClassName = customClass ? ` ${customClass} ` : ''
    const sizeClass = size === Size.MINI ? ` ${CommonButtonClass}--${size}` : ''
    const qmData = qm ? { [`data-qm-${qm?.type}`]: qm?.value } : {}

    /**
     * Keyboard event on key down of any keys within button
     * @param {React.KeyboardEvent<HTMLButtonElement>} e - e is the event for keydown
     * @returns {void} if there is a keydown prop, call function, otherwise null
     */
    const keyDownHandler = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
        if (onKeyDown) onKeyDown(e)
        else null
    }

    return (
        <button
            id={id}
            className={`${CommonButtonClass}-spinner ${variantClass}${customClassName}${isReverse}${sizeClass}`}
            ref={ref}
            disabled={disabled}
            type={type}
            onClick={onClick}
            onKeyDown={e => keyDownHandler(e)}
            aria-disabled={a11y?.disabled}
            aria-label={a11y?.label ? a11y?.label : ''}
            aria-expanded={a11y?.expanded}
            aria-controls={a11y?.controls}
            {...qmData}
            {...props}>
            {showSpinner ? (
                <Spinner color={spinner?.color} style={spinner?.style} value={spinner?.value} />
            ) : (
                <>
                    {icon && <Icon size={icon.size} type={icon.type} />}
                    {children && <span>{children}</span>}
                </>
            )}
        </button>
    )
})

SpinnerButton.displayName = 'SpinnerButton'

SpinnerButton.defaultProps = SpinnerDefaultProps

export default SpinnerButton
