import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/deliveryEligibility.actionCreators'
import { SuccessResponse, ErrorResponse } from '../models/deliveryOptions.interface'
import {
    fetchDeliveryOptionsAction,
    deliveryOptionsFailureAction,
    resetDeliveryOptionsAction,
    setIsDeliveryOptionsApiDone,
} from '../actionCreators/deliveryEligibility.actionCreators'
import { EmptyActionType } from '../models/globalData.interface'

type Action = ActionType<typeof actions> | EmptyActionType

export interface deliveryOptionsState {
    deliveryOptions: SuccessResponse
    deliveryOptionsErrorResponse?: ErrorResponse | null
    deliveryOptionsFailure: boolean
    isDeliveryOptionsApiDone: boolean
}

export const initialState: deliveryOptionsState = {
    deliveryOptions: {} as SuccessResponse,
    deliveryOptionsErrorResponse: null,
    deliveryOptionsFailure: false,
    isDeliveryOptionsApiDone: false,
}

export const deliveryOptionsReducer = (
    state: deliveryOptionsState = initialState,
    action: Action,
): deliveryOptionsState => {
    switch (action.type) {
        case getType(fetchDeliveryOptionsAction): {
            return {
                ...state,
                deliveryOptions: action.payload,
                deliveryOptionsErrorResponse: null,
                deliveryOptionsFailure: false,
                isDeliveryOptionsApiDone: true,
            }
        }
        case getType(deliveryOptionsFailureAction): {
            return {
                ...state,
                deliveryOptions: {} as SuccessResponse,
                deliveryOptionsErrorResponse: action.payload,
                deliveryOptionsFailure: true,
                isDeliveryOptionsApiDone: false,
            }
        }
        case getType(resetDeliveryOptionsAction): {
            return {
                deliveryOptions: {} as SuccessResponse,
                deliveryOptionsErrorResponse: null,
                deliveryOptionsFailure: false,
                isDeliveryOptionsApiDone: false,
            }
        }
        case getType(setIsDeliveryOptionsApiDone): {
            return { ...state, isDeliveryOptionsApiDone: action.payload }
        }
        default:
            return state
    }
}
