import { roundOfToTwoDecimals } from './roundOfToTwoDecimals'

/**
 * Returns base total amount
 * @param {number} rewardsPercentage reward percentage based on user type
 * @param {number} currentPriceValue total product price
 * @param {number} quantity no of quantity selected
 * @returns {number} return total reward value
 */
export const getPTEBaseTotal = (rewardsPercentage: number, currentPriceValue: number, quantity = 1): number => {
    return roundOfToTwoDecimals(Number(rewardsPercentage * currentPriceValue * quantity))
}

/**
 * Returns sum of reward value
 * @param {number} rewardValue base or master reward value
 * @param {number} pteOfferValue npp offer value
 * @param {number} selectRewardsValue triangle select value
 * @param {number} rewardsBoostValue  triangle reward boost value
 * @returns {number} return sum of all triangle reward earn
 */
export const getTotalPteReward = (
    rewardValue: number,
    pteOfferValue = 0,
    selectRewardsValue = 0,
    rewardsBoostValue = 0,
): number => {
    return (
        rewardValue +
        roundOfToTwoDecimals(Number(pteOfferValue)) +
        roundOfToTwoDecimals(Number(selectRewardsValue)) +
        roundOfToTwoDecimals(Number(rewardsBoostValue))
    )
}
