export const offerEndDateRegex = /^(\d{2})(\d{2})(\d{4})$/
export const regexPatterns = {
    firstLetters: /(^\w)|(\s+\w)/g,
}
export const allWhitespace = /\s/g

export const allNotLetterNotNumber = /[^a-zA-Z0-9]+/g

/* Regex patterns for input fields used in checkout screen */
export const checkoutRegexPatterns = {
    contactNumber: '[0-9-]*',
    ctMoneyAmount: '[0-9$,.\\s]*',
    giftCardNumberAndPin: '[0-9\\s]*',
}

export const numberRegEx = '[0-9]+'
export const inputFieldInvalidCharactersRegEx = /\.|,|\s/g
export const offerCodesRegEx = /[.!?;,:]/
export const hexColorRegEx = /^#(?:[0-9a-f]{3}){1,2}$/i
export const colorRegex = /(?:#|0x)(?:[a-f0-9]{3}|[a-f0-9]{6})\b|(?:rgb|hsl)a?\([^)]*\)/gi
export const verificationCodeRegex = /^[0-9]{6}$/
