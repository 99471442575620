import React from 'react'
import PropTypes from 'prop-types'
import { MultiSelectCheckBoxesProps } from './MultiSelectCheckBoxes.types'
import { PREFIX } from '../config'

/**
 * @param { MultiSelectCheckBoxesProps } props
 * @returns { JSX.Element | null }
 */
const MultiSelectCheckBoxes: React.FC<MultiSelectCheckBoxesProps> = props => {
    const { multiSelectData, onChange, title, customClass } = props
    return multiSelectData.length > 0 ? (
        <fieldset className={`${PREFIX}-multi-select`}>
            {title && <legend className={`${PREFIX}-multi-select__title`}>{title}</legend>}
            <div className={customClass ? `${PREFIX}-${customClass}` : ''}>
                <ul className={`${PREFIX}-multi-select__list`}>
                    {multiSelectData.map(item => {
                        const { id, multiSelectElement, selected } = item
                        const selectedClass = selected ? `${PREFIX}-multi-select__item--selected` : ''
                        const multiSelectId = `multi-select-${id}`

                        return (
                            <li key={multiSelectId} className={`${PREFIX}-multi-select__item ${selectedClass}`}>
                                <input
                                    id={multiSelectId}
                                    type="checkbox"
                                    className={`${PREFIX}-multi-select__input sr-only`}
                                    onChange={event => onChange(event, item)}
                                    checked={selected}></input>
                                <label htmlFor={multiSelectId} className={`${PREFIX}-multi-select__label`}>
                                    {multiSelectElement}
                                </label>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </fieldset>
    ) : null
}

MultiSelectCheckBoxes.propTypes = {
    multiSelectData: PropTypes.arrayOf(
        PropTypes.exact({
            multiSelectElement: PropTypes.node.isRequired,
            id: PropTypes.string.isRequired,
            selected: PropTypes.bool,
            url: PropTypes.string,
            clearUrl: PropTypes.string,
        }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string,
    customClass: PropTypes.string,
}

export default MultiSelectCheckBoxes
