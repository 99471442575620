import { AxiosPromise } from 'axios'

import BaseService from '../base.service'
import { apiMethods } from '../service.constants'
import { EnvironmentConfig, getEnvironment } from '../../environments'
import { CtMoneyRedeemType } from '../../redux/models/ctMoneyRedeem.interface'

const environment: EnvironmentConfig = getEnvironment()

class CTMoneyRedeemService extends BaseService {
    /**
     * Function to create url for redeemCTMoney
     * @param {string} code
     * @param {string} amount
     * @return {URL}
     */
    createRedeemURL(code?: string, amount?: string): URL {
        const path = `${environment.API_ENDPOINTS.redeemCtMoneyPostPurchase}?code=${code || ''}&redemptionAmount=${
            amount || ''
        }`
        return CTMoneyRedeemService.createUri(path)
    }

    /**
     * function to call redeemCTMoney on service
     * @param {string} code
     * @param {string} amount
     * @param {CtMoneyRedeemType} payload
     * @return {AxiosPromise}
     */
    ctMoneyRedeemedService(code?: string, amount?: string, payload?: CtMoneyRedeemType): AxiosPromise {
        const url = this.createRedeemURL(code, amount)

        return CTMoneyRedeemService.apiMethod(apiMethods.PATCH, url.toString(), payload)
    }
}

// Creating a singleton instance of service
const ctMoneyRedeemService = new CTMoneyRedeemService()

export { ctMoneyRedeemService }
export default ctMoneyRedeemService
