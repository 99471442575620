import React from 'react'
import { ContentVariation, CustomContentType } from './CustomContentType.type'
import { PREFIX } from '../config'
import SanitizeStringContentWrapper from '../../utils/sanitizeStringContent'
import HeaderBlock from '../HeaderBlock/HeaderBlock'

/**
 * Braze Custom Content UI
 * @param {CustomContentType} props custom card props
 * @returns {JSX.Element} braze custom component
 */
const CustomContentCard: React.FC<CustomContentType> = ({ ...props }): JSX.Element | null => {
    const { type, cardInfo, contentPosition } = props
    const { imageUrl, title, description, url, linkText, id } = cardInfo
    const componentClass = `${PREFIX}-custom-card__container`
    const cardPosition = contentPosition === ContentVariation.ImageLeft ? `reverse` : ''
    const modifierClass =
        type === ContentVariation.CaptionImage ? `${PREFIX}-col-md-5 ${PREFIX}-col-sm-6 ${PREFIX}-col-xs-3` : ''
    /**
     * Display image only UI
     * @returns { JSX.Element } return only image ui
     */
    const renderImageContentCard = (): JSX.Element => (
        <div className={`${componentClass}__image ${modifierClass}`}>
            <picture>
                <source srcSet={imageUrl} />
                <img className={`${componentClass}__image--img`} alt={''} src={imageUrl} />
            </picture>
        </div>
    )

    /**
     * Display image with text
     * @returns { JSX.Element } return image with text Ui
     */
    const renderCaptionedImageContentCard = (): JSX.Element => (
        <div className={`${componentClass} ${PREFIX}-row ${cardPosition}`}>
            <div className={`${PREFIX}-col-md-7 ${PREFIX}-col-sm-6 ${PREFIX}-col-xs-3 ${componentClass}__content`}>
                <HeaderBlock title={title} subtitle={description} />
                <SanitizeStringContentWrapper stringContent={url}>
                    {memoizedStringContent => (
                        <a
                            id={id}
                            href={memoizedStringContent}
                            target="_self"
                            className={`${PREFIX}-button2 ${PREFIX}-button2--primary ${PREFIX}-button2--primary-dark ${PREFIX}-button2--mini ${PREFIX}-xs-inline-block`}
                            rel="noopener noreferrer">
                            {linkText}
                        </a>
                    )}
                </SanitizeStringContentWrapper>
            </div>
            {renderImageContentCard()}
        </div>
    )

    /**
     * Display braze custom card UI based on response from braze console
     */
    switch (type) {
        case ContentVariation.Image:
            return renderImageContentCard()
        case ContentVariation.CaptionImage:
            return renderCaptionedImageContentCard()
        default:
            return null
    }
}

export default CustomContentCard
