import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import Button from '../Button'
import Price from '../Price'
import ProductInformation from '../ProductInfo'
import { useGlobalScrollEvent } from '../../utils/useWindowEvent'
import { useGenerateSaveMessage } from '../Price/Price.helper'
import { StickyBuybarProps } from './StickyBuyBar.type'
import UrgencyMessage from '../UrgencyMessage'

/**
 * StickyBuyBar component
 * @param {StickyBuybarProps} props
 * @return {JSX.Element} returns StickyBuybar component
 */
const StickyBuyBar: React.FC<StickyBuybarProps> = props => {
    const {
        brandUrl,
        brand,
        title,
        desktopATCLabel,
        unitPriceLabel,
        clearancePriceLabel,
        productImageUrl,
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        onlineOrdering,
        feeTitle,
        originalPrice,
        currentPrice,
        displayWasLabel,
        language,
        addToCartClickHandler,
        showAddToCartOnStickyBuyBox,
        priceMessage,
        a11yRecycleFeesTriangleIcon,
        feeDisclaimerType,
        feeDisclaimerMessage,
        feeDisclaimerTitle,
        setIsStickyBBInView,
        showBBSpinner,
        productId,
        isOOSCurrentStore,
        saleCut,
        saleMessagesRules,
        isBrowseOnlyMode,
        a11yClickToReadFootnote,
        scrollToReviewsHandler,
        saleEndDaySoonMessage,
        productSaleEndDate,
        stickyBuyBarRatingRef,
        hasSaleOrClearanceBadge,
        plusMinusSymbol,
    } = props
    const [isSticky, setSticky] = useState(false)
    let buyBoxCompEL: HTMLElement | null
    const removeStickyClassName = '--sticky-none'
    const buttonType = isOOSCurrentStore ? 'primary' : 'call_to_action'
    const handleScroll = () => {
        buyBoxCompEL = document.getElementById(`${PREFIX}-buy-box`) || null
        const buyBoxCompELHeight = buyBoxCompEL?.clientHeight || 0
        const buyBoxCompELTop = buyBoxCompEL?.offsetTop || 0
        const buyBoxContentHeight = buyBoxCompELHeight + buyBoxCompELTop
        setStickyComponent(buyBoxContentHeight)
    }

    /**
     * @return {number} height of the primary-navbar
     */
    const getPrimaryNavHeight = (): number => {
        const primaryNav = document.querySelector(`.${PREFIX}-primary-navigation`) || null
        return primaryNav?.clientHeight || 0
    }

    /* Function to set Sticky State */
    const setStickyComponent = (buyBoxContentHeight: number) => {
        window.pageYOffset > buyBoxContentHeight - getPrimaryNavHeight() ? setSticky(true) : setSticky(false)
    }

    useGlobalScrollEvent(handleScroll)

    /**
     * @return {JSX.Element} - returns Review component
     */
    const renderReviewComp = (): JSX.Element => {
        return (
            <div
                ref={stickyBuyBarRatingRef}
                className={`${PREFIX}-sticky-buy-bar__reviews`}
                data-bv-show="rating_summary"
                data-bv-seo="false"
                data-bv-product-id={productId}
                role="link"
                onClick={scrollToReviewsHandler}
                onKeyPress={scrollToReviewsHandler}
                tabIndex={0}
            />
        )
    }

    const checkAllPropsAreNull = price => Object.values(price).filter(val => !!val).length === 0
    const isSupressedPrice =
        currentPrice || originalPrice
            ? checkAllPropsAreNull(currentPrice) && checkAllPropsAreNull(originalPrice)
            : false
    const isDisabledATC = isSupressedPrice

    /**
     * render desktop add to cart
     *
     * @return {JSX.Element}
     */
    const showDesktopATCLabel = (): JSX.Element => {
        return (
            showAddToCartOnStickyBuyBox &&
            !isBrowseOnlyMode &&
            onlineOrdering && (
                <Button
                    id="add-to-cart-sticky-buy-bar"
                    type={buttonType}
                    size="large"
                    onClick={addToCartClickHandler}
                    disabled={isDisabledATC}
                    label={desktopATCLabel}
                    showSpinner={showBBSpinner}
                />
            )
        )
    }

    const saveMessage = useGenerateSaveMessage(saleCut, originalPrice, saleMessagesRules, currentPrice)

    // function will return price component for sticky buyBar
    const showPriceComponent = (): JSX.Element => {
        return (
            <Price
                unitPriceLabel={unitPriceLabel}
                clearancePriceLabel={clearancePriceLabel}
                a11yStrikeOutPrice={a11yStrikeOutPrice}
                a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                feeTitle={feeTitle}
                originalPrice={originalPrice}
                currentPrice={currentPrice}
                displayWasLabel={displayWasLabel}
                language={language}
                priceMessage={priceMessage}
                feeDisclaimerType={feeDisclaimerType}
                a11yRecycleFeesTriangleIcon={a11yRecycleFeesTriangleIcon}
                nowFromLabel={props.nowFromLabel}
                saveFromLabel={props.saveFromLabel}
                wasFromLabel={props.wasFromLabel}
                fromLabel={props.fromLabel}
                feeDisclaimerMessage={feeDisclaimerMessage}
                feeDisclaimerTitle={feeDisclaimerTitle}
                eachLabel={props.eachLabel}
                showFromNow={true}
                saveMessage={saveMessage}
                a11yClickToReadFootnote={a11yClickToReadFootnote}
                isOnSaleOrClearance={hasSaleOrClearanceBadge}
                plusMinusSymbol={plusMinusSymbol}
            />
        )
    }

    /**
     * Function to render urgency message in Sticky Buybar component
     *
     * @return {JSX.Element}
     */
    const renderUrgencyMessage = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-sticky-buy-bar__sale-ends-message`}>
                <UrgencyMessage saleEndDaySoonMessage={saleEndDaySoonMessage} salePriceEndDate={productSaleEndDate} />
            </div>
        )
    }

    // function to return Product information for sticky BuyBar
    const productInformation = (): JSX.Element => {
        return <ProductInformation brand={brand} brandUrl={brandUrl} title={title} isSticky={true} />
    }

    useEffect(() => {
        // set isStickyBuyBarInView
        setIsStickyBBInView(isSticky)
    }, [isSticky, setIsStickyBBInView])

    return (
        <>
            <div className={`${PREFIX}-sticky-buy-bar-hide-content`}>
                <div
                    data-testid="sticky-bar"
                    id={`${PREFIX}-sticky-buy-bar`}
                    className={`${PREFIX}-row ${PREFIX}-sticky-buy-bar ${PREFIX}-sticky-buy-bar${
                        isSticky ? '--sticky' : removeStickyClassName
                    } ${PREFIX}-full-width-container `}>
                    <div className={`${PREFIX}-container ${PREFIX}-sticky-buy-bar__container`}>
                        <div className={`${PREFIX}-sticky-buy-bar__product-img`}>
                            <img
                                data-component-name="sticky-buy-box"
                                src={productImageUrl}
                                alt={brand}
                                className={`${PREFIX}-sticky-buy-bar__responsive-img`}
                            />
                        </div>
                        <div className={`${PREFIX}-sticky-buy-bar__wrapper`}>
                            <div className={`${PREFIX}-sticky-buy-bar__product-info`}>
                                {productInformation()} {renderReviewComp()}
                            </div>
                            <div className={`${PREFIX}-sticky-buy-bar__price-section`}>
                                <div className={`${PREFIX}-sticky-buy-bar__product-price`}>{showPriceComponent()}</div>
                                {productSaleEndDate ? renderUrgencyMessage() : null}
                            </div>
                        </div>
                        <div className={`${PREFIX}-sticky-buy-bar__cta`}>{showDesktopATCLabel()}</div>
                    </div>
                </div>
            </div>
        </>
    )
}
StickyBuyBar.propTypes = {
    brandUrl: PropTypes.string,
    brand: PropTypes.string,
    title: PropTypes.string,
    desktopATCLabel: PropTypes.string,
    unitPriceLabel: PropTypes.string,
    clearancePriceLabel: PropTypes.string,
    productImageUrl: PropTypes.string,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    onlineOrdering: PropTypes.bool,
    feeTitle: PropTypes.string,
    originalPrice: PropTypes.any,
    currentPrice: PropTypes.any,
    displayWasLabel: PropTypes.bool,
    language: PropTypes.string,
    addToCartClickHandler: PropTypes.func.isRequired,
    showAddToCartOnStickyBuyBox: PropTypes.bool.isRequired,
    priceMessage: PropTypes.array,
    a11yRecycleFeesTriangleIcon: PropTypes.string,
    nowFromLabel: PropTypes.string,
    saveFromLabel: PropTypes.string,
    wasFromLabel: PropTypes.string,
    fromLabel: PropTypes.string,
    feeDisclaimerType: PropTypes.string,
    feeDisclaimerMessage: PropTypes.string,
    feeDisclaimerTitle: PropTypes.string,
    eachLabel: PropTypes.string.isRequired,
    setIsStickyBBInView: PropTypes.func.isRequired,
    showBBSpinner: PropTypes.bool,
    isOOSCurrentStore: PropTypes.bool,
    isBrowseOnlyMode: PropTypes.bool,
    a11yClickToReadFootnote: PropTypes.string,
    scrollToReviewsHandler: PropTypes.func.isRequired,
    saleEndDaySoonMessage: PropTypes.string,
    productSaleEndDate: PropTypes.string,
}
export default StickyBuyBar
