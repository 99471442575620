import { MagicNumber } from '../analytics/analytics.type'
import {
    CartOrderDeliveryModes,
    CartOrderEntriesWithFilterVal,
    CartOrderEntriesWithGroupingVal,
    IMinMaxEtaEntry,
} from '../redux/models/cart.interface'
/**
 * @method getStatusGrouping: Groups the products by unique tracking id, status, ETA date and time combination
 * @param list passes the List of products to be grouped
 * @param type is the type of list (bopis,sth,services)
 * @return {Object} with unique keys and each value as array of Products List.
 **/

export const getStatusGrouping = (
    list: CartOrderEntriesWithGroupingVal[],
): Record<string, CartOrderEntriesWithFilterVal[]> => {
    const tempList: CartOrderEntriesWithFilterVal[] = []
    list.forEach(elem => {
        const { trackingID, isBulk, minETA, maxETA, fulfillment } = elem
        const strStatus = String(elem.status)

        const filterVal =
            fulfillment?.deliveryMode === CartOrderDeliveryModes.STH
                ? `${strStatus}_${trackingID}${String(isBulk)}${minETA}${maxETA}`
                : `${strStatus}${minETA}${maxETA}`
        tempList.push({
            filterVal: filterVal,
            ...elem,
        })
    })
    /**
     * @method groupBy: Groups the products by unique filterVal
     * @param productList passes the List of products to be grouped
     * @param key unique key to filter
     * @return {result} filtered grouped Object
     **/

    const groupBy = (
        productList: CartOrderEntriesWithFilterVal[],
        key: keyof CartOrderEntriesWithFilterVal,
    ): Record<string, CartOrderEntriesWithFilterVal[]> => {
        const initialValue: Record<string, CartOrderEntriesWithFilterVal[]> = {}
        return productList.reduce((result, currentValue) => {
            const resultKey = currentValue[key] as string
            if (!result[resultKey]) {
                result[resultKey] = []
            }
            result[resultKey].push(currentValue)
            return result
        }, initialValue)
    }
    return groupBy(tempList, 'filterVal')
}

const getETA = (eta?: string): Date | undefined => {
    return eta ? new Date(eta) : undefined
}

export const getMinMaxEta = (orderEntry: IMinMaxEtaEntry): Record<string, unknown> | null => {
    const fulfillment = orderEntry?.fulfillment
    const sourceTypes = fulfillment?.sourceTypes
    if (sourceTypes) {
        let minETA = getETA(sourceTypes[MagicNumber.ZERO]?.etaEarliest)
        let maxETA = getETA(sourceTypes[MagicNumber.ZERO]?.etaLatest)

        if (fulfillment?.sourceTypes.length > MagicNumber.ONE) {
            const dcMinETA = getETA(sourceTypes[MagicNumber.ONE]?.etaEarliest)
            const dcMaxETA = getETA(sourceTypes[MagicNumber.ONE]?.etaLatest)

            minETA = (
                minETA && dcMinETA && minETA?.getTime() < dcMinETA?.getTime()
                    ? minETA
                    : sourceTypes[MagicNumber.ONE]?.etaEarliest
            ) as Date
            maxETA = (
                maxETA && dcMaxETA && maxETA?.getTime() > dcMaxETA?.getTime()
                    ? maxETA
                    : sourceTypes[MagicNumber.ONE]?.etaLatest
            ) as Date
        }
        return {
            minETA: minETA,
            maxETA: maxETA,
        }
    }
    return null
}
