import { constantValues } from './ShopTheLookComponent.constant'
import { FirstSectionProps, TilesProps } from './ShopTheLookComponent.type'

/**
 * function to get certain type of tiles
 * @param { number } tiles tiles
 * @returns { string } type of tiles
 */
export const getNumberOfTiles = (tiles: number): string => {
    return tiles === constantValues.maximumProductQuantity
        ? constantValues.fourProductsClassName
        : constantValues.twoProductsClassName
}

/**
 * function to match number of tiles with setting
 * @param { number } tiles tiles
 * @param { number } component component
 * @returns {  TilesProps[] } list of tiles
 */
export const matchedProductsList = (tiles: number, component: FirstSectionProps): TilesProps[] => {
    return component.tiles.slice(0, tiles)
}
