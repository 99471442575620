import { Dispatch } from 'redux'
import { CheckoutService } from '../../services/checkoutService'
import { orderService } from '../../services/orderConfirmation/orderService'
import getFilteredCartItems from '../../utils/getFilteredCartItems'
import { setShowSpinner } from '../actionCreators'
import {
    orderConfirmationSuccess,
    orderConfirmationFailure,
    toggleNotificationSubscription,
    setOrderNotificationError,
    placeOrderConfirmationSuccess,
    placeOrderConfirmationFailure,
    clickToPayShippingAddressValidationSuccess,
    clickToPayShippingAddressValidationError,
} from '../actionCreators/orderConfirmation.actionCreators'
import {
    AxiosCartResponseErrorDTO,
    CartData,
    CartResponseErrorDTO,
    ClickToPaySTHValidationDTO,
    PlaceOrderDTO,
} from '../models/cart.interface'
import { fillCheckoutDataFromAPI } from './cart.action'
import { AxiosResponse } from 'axios'
import { IFeatureFlag } from '../models/commonContent.interface'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import { OrderResponse } from '../models/orderConfirmation.interface'

const CURRENT_ROLE = 'current'
const ANONYMOUS_ROLE = 'anonymous'

export const getOrderConfirmationDetails =
    (orderId: string) =>
    (dispatch: Dispatch): void => {
        orderService
            .getOrderConfirmation(orderId)
            .then(getOrderData => {
                replaceEmptyImagesWithDefault(
                    (getOrderData as unknown as OrderResponse)?.data?.order?.entries,
                    'images',
                )
                const orderData = getFilteredCartItems(getOrderData.data.order as CartData)
                dispatch(orderConfirmationSuccess(orderData))
                fillCheckoutDataFromAPI(orderData.cart, dispatch)
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                dispatch(orderConfirmationFailure(err?.response as unknown as CartResponseErrorDTO))
            })
    }

export const toggleOrderNotificationSubscription =
    (isSignedInUser: boolean, orderCode: string, phone: string, subscriptionFlag: boolean) =>
    (dispatch: Dispatch): Promise<void> => {
        const userRole = isSignedInUser ? CURRENT_ROLE : ANONYMOUS_ROLE
        return orderService
            .toggleOrderNotificationSubscription(userRole, orderCode, phone, subscriptionFlag)
            .then(response => {
                dispatch(toggleNotificationSubscription(response.data))
                dispatch(setOrderNotificationError(false))
            })
            .catch(() => {
                dispatch(setOrderNotificationError(true))
            })
    }

export const placeOrderFromConfirmationPage =
    (cartId: string, isNewCard: boolean, showSpinner = false, fraudFeatureFlag: Partial<IFeatureFlag>) =>
    (dispatch: Dispatch): void => {
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        const fraudSessionID = CheckoutService.getFraudSessionID(fraudFeatureFlag)
        CheckoutService.placeOrder(cartId, isNewCard, fraudSessionID)
            .then((placeOrderData: AxiosResponse<PlaceOrderDTO>) => {
                replaceEmptyImagesWithDefault(placeOrderData?.data?.order?.entries, 'images')
                const orderData = getFilteredCartItems(placeOrderData.data.order as unknown as CartData)
                dispatch(placeOrderConfirmationSuccess(orderData))
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                dispatch(placeOrderConfirmationFailure(err?.response as unknown as CartResponseErrorDTO))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * Invokes clickToPaySTHValidation endpoint to perform shipping address validation
 * @param {string} cartId - cart id of the current session
 * @returns {void}
 */
export const performShippingAddressValidation =
    (cartId: string) =>
    (dispatch: Dispatch): void => {
        CheckoutService.validateClickToPaySTHAddress(cartId)
            .then((validationResponse: AxiosResponse<ClickToPaySTHValidationDTO>) => {
                dispatch(clickToPayShippingAddressValidationSuccess(validationResponse.data))
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                err.response &&
                    dispatch(
                        clickToPayShippingAddressValidationError(err.response.data as unknown as CartResponseErrorDTO),
                    )
            })
    }
