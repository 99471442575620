/**
 * Product Cart Item constant values
 */
export const productCartItems = {
    lowerRangeOfQuantitySelector: 1, // Min value for qty selector input
    oneProductQuantity: 1, // unit quantity value
    cartDebounceTimeOut: 3000,
    etaTimeOptions: {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'UTC',
    },
    etaDateOptions: {
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC',
    },
}

/**
 * Badges
 */
export const badgesTypes = {
    onlineOnly: 'ONLINEONLY',
}

export const saveForLaterParam = 'moveToSFL'

export const createNewPackageLabel = 'createNewPackageLabel'
export const createNewPackageCTA = 'createNewPackageCTA'
