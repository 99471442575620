export const brazeCustomEvents = {
    event: {
        accessWeeklyOffers: 'web_access_weekly_offers',
        accessEmailOffers: 'web_access_email_offers',
        loyaltyOffersView: 'web_loyalty_offers_view',
        loyaltyOffersActivationAll: 'web_loyalty_offers_activation_all',
        loyaltyOffersActivation: 'web_loyalty_offers_activation',
        loyaltyOffersActivationInlinePte: 'web_loyalty_offers_activation_inline_pte',
        loyaltyOffersActivationFlyoutPte: 'web_loyalty_offers_activation_flyout_pte',
        loyaltyOffersActivationCarousel: 'web_loyalty_offers_activation_carousel',
        loyaltyOffersViewAllCarousel: 'web_loyalty_offers_view_all_carousel',
        triangleOpenOfferSwapTab: 'web_open_offer_swap_tab',
        triangleOfferClickSwap: 'web_offer_click_swap',
        triangleOfferClickSelect: 'web_offer_click_select',
        triangleOfferSwap: 'web_offer_swap_success',
        triangleOfferSwapErrorUnavailable: 'web_offer_swap_error_unavailable',
        triangleSwapErrorAlreadyInWOL: 'web_swap_error_already_in_WOL',
        triangleFilterSwapOffer: 'web_filter_swap_offer',
        successfulLoyaltySignin: 'web_successful_loyalty_signin',
        successfulGigyaSignin: 'web_successful_gigya_signin',
        registrationGigya: 'web_registration_gigya',
        registrationLoyalty: 'web_registration_loyalty',
        flyerView: 'web_flyer_view',
        flyerProductClick: 'web_flyer_product_click',
        addToCart: 'web_add_to_cart',
        shoppingCart: 'web_shopping_cart',
        removeFromCart: 'web_remove_from_cart',
        wishlistConversion: 'web_wishlist_conversion',
        webClearanceView: 'web_clearance_view',
        webSalesView: 'web_sales_view',
        webSearch: 'web_search',
        webSetStore: 'web_set_store',
    },
}

// page type to trigger braze custom event
export const brazeCustomEventsPages = {
    clearance: 'clearance',
    sale: 'sale',
}
