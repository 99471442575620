import React, { useState, useEffect, useCallback } from 'react'
import { Button, Icon } from '@nl/lib'
import PropTypes from 'prop-types'
import { PREFIX, previousElementName } from '../../config'
import OrderUpdatesSubscriptionPopup from './popup/OrderUpdatesSubscriptionPopup'
import wrapIntoPopup from '../../utils/hoc/wrapIntoPopup'
import ErrorPopup from '../ErrorPopup'
import { toggleOrderNotificationSubscription } from '../../redux/actions/orderConfirmation.action'
import { userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'

import { OrderUpdatesSubscriptionProps } from './OrderUpdatesSubscription.types'
import { notificationSubscriptionSelector, orderSelector } from '../../redux/selectors/orderItemsDetails.selectors'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'

const OrderUpdatesSubscription: React.FC<OrderUpdatesSubscriptionProps> = ({ ...props }): JSX.Element => {
    const {
        title,
        subtitle,
        buttonLabel,
        deactivateSubtitle,
        deactivateButtonLabel,
        privacyPolicyLabel,
        privacyPolicyLink,
        genericErrorTitle,
        genericErrorSubtitle,
        genericErrorCloseButtonLabel,
    } = props

    const dispatch = useAppDispatch()

    const userProfileData = useAppSelector(userProfileDataSelector)
    const isSignedInUser = userProfileData ? Boolean(Object.keys(userProfileData).length) : false

    const order = useAppSelector(orderSelector)
    const notificationSubscription = useAppSelector(notificationSubscriptionSelector)
    const { code: orderCode } = order.cart

    const { notificationSubscriptionResponse, orderNotificationError } = notificationSubscription

    const [showPopup, setShowPopup] = useState(false)

    const [isErrorStateActive, setIsErrorStateActive] = useState(false)

    const [isNotificationActivated, setIsNotificationActivated] = useState(false)

    const [isMinimize, setIsMinimize] = useState(false)

    const minimize = () => {
        setIsMinimize(!isMinimize)
    }

    const icon = isMinimize ? 'ct-add' : 'ct-subtract'

    const deactivateNotifications = (): void => {
        dispatch(
            toggleOrderNotificationSubscription(
                isSignedInUser,
                orderCode,
                notificationSubscriptionResponse.phone,
                false,
            ),
        )
    }

    const previousElement: HTMLElement | null = document.querySelector(`button[${previousElementName}]`)
    const orderUpdatesSubscriptionPopupProps = {
        isSignedInUser,
        orderCode,
        closePopupButtonLabel: genericErrorCloseButtonLabel,
        popupData: { ...props },
        closePopup: (): void => {
            setShowPopup(false)
            if (previousElement) {
                previousElement.focus() // Highlight the initiated button
                previousElement.removeAttribute(previousElementName)
            }
        },
    }

    const orderUpdatesSubscriptionProps = {
        subscriptionTitle: title,
        subscriptionSubtitle: subtitle,
        subscriptionButtonLabel: buttonLabel,
        subscriptionFunction: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
            setShowPopup(true)
            const button = event.currentTarget as HTMLElement
            button.setAttribute(previousElementName, 'true')
        },
        // TODO: better refactoring needs to be done as the next 2 fields are not used in orderUPdatesSubscription but only included for code in line 115
        subscriptionPolicyLabel: '',
        subscriptionPolicyLink: '',
    }

    const orderUpdatesSubscriptionDeactivateProps = {
        subscriptionTitle: title,
        subscriptionSubtitle: deactivateSubtitle,
        subscriptionButtonLabel: deactivateButtonLabel,
        subscriptionFunction: deactivateNotifications,
        subscriptionPolicyLabel: privacyPolicyLabel,
        subscriptionPolicyLink: privacyPolicyLink,
    }

    const isResponseHasSubscription = useCallback((): boolean => {
        return Boolean(notificationSubscriptionResponse?.smsNotificationStatus?.find(event => event.value))
    }, [notificationSubscriptionResponse])

    useEffect(() => {
        orderNotificationError ? setIsErrorStateActive(true) : setIsNotificationActivated(isResponseHasSubscription())
    }, [orderNotificationError, setIsErrorStateActive, setIsNotificationActivated, isResponseHasSubscription])

    const Popup = wrapIntoPopup(OrderUpdatesSubscriptionPopup, () => setShowPopup(false))

    const orderSubscriptionComponentProps = isNotificationActivated
        ? orderUpdatesSubscriptionDeactivateProps
        : orderUpdatesSubscriptionProps

    const {
        subscriptionTitle,
        subscriptionSubtitle,
        subscriptionFunction,
        subscriptionButtonLabel,
        subscriptionPolicyLabel,
        subscriptionPolicyLink,
    } = orderSubscriptionComponentProps

    const successfulSubscriptionIcon = isNotificationActivated ? (
        <div className={`${PREFIX}-order-updates__icon`}>
            <Icon type="ct-notification-success" size="md" />
        </div>
    ) : null

    const policyLink = isNotificationActivated ? (
        <a
            className={`${PREFIX}-order-updates__policy`}
            target="_blank"
            href={subscriptionPolicyLink}
            rel="noreferrer noopener">
            {subscriptionPolicyLabel}
        </a>
    ) : null

    const componentBody = !isMinimize ? (
        <div className={`${PREFIX}-order-updates__container`}>
            <div className={`${PREFIX}-order-updates__subtitle-container`}>
                {successfulSubscriptionIcon}
                <div className={`${PREFIX}-order-updates__subtitle`}>{subscriptionSubtitle}</div>
            </div>
            <div className={`${PREFIX}-order-updates__button`}>
                <Button type="primary" size="small" onClick={subscriptionFunction}>
                    {subscriptionButtonLabel}
                </Button>
            </div>
            {policyLink}
        </div>
    ) : null

    return (
        <>
            {orderNotificationError && isErrorStateActive && (
                <ErrorPopup
                    errorTitle={genericErrorTitle}
                    errorSubtitle={genericErrorSubtitle}
                    onCloseClickHandler={() => setIsErrorStateActive(false)}
                    errorButtonLabel={genericErrorCloseButtonLabel}
                />
            )}
            <div className={`${PREFIX}-order-updates__body`} data-testid="order-updates">
                <div className={`${PREFIX}-order-updates__title`}>
                    <div>{subscriptionTitle}</div>
                    <button
                        className={`${PREFIX}-order-updates__minimize-button`}
                        aria-expanded={!isMinimize}
                        onClick={minimize}
                        data-testid="minimize-button"
                        aria-label={subscriptionTitle}>
                        <Icon type={icon} size="md" />
                    </button>
                </div>
                {componentBody}
                {showPopup ? <Popup {...orderUpdatesSubscriptionPopupProps} /> : null}
            </div>
        </>
    )
}

OrderUpdatesSubscription.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    deactivateSubtitle: PropTypes.string.isRequired,
    deactivateButtonLabel: PropTypes.string.isRequired,
    privacyPolicyLabel: PropTypes.string.isRequired,
    privacyPolicyLink: PropTypes.string.isRequired,
    genericErrorTitle: PropTypes.string.isRequired,
    genericErrorSubtitle: PropTypes.string.isRequired,
    genericErrorCloseButtonLabel: PropTypes.string.isRequired,
}

export default OrderUpdatesSubscription
