import { PREFIX } from '../../config'

export const noOfItemsToShow = 9
export const maxCategoriesToShow = 9
export const centerCard = {
    desktop: 5,
    wide: 6,
}
export const AUTHOR = 'author'
export const CATEGORYIMAGE = 'category image'
export const megaNavIdentifier = 'mega-nav-'

export const selectorCategories = '[role="button"]'
export const gridClass = `${PREFIX}-product-subcategory__grid-items`
export const shopAllLinkStyle = `${PREFIX}-stacked-subcategories__shopall-link`
export const viewAllLinkStyle = `${PREFIX}-stacked-subcategories__view-all-link`
export const selectorSubcategories = `.${PREFIX}-product-category__link, .${shopAllLinkStyle}, .${viewAllLinkStyle}`
export const stackedCategoryIdentifier = 'stacked-clp-'
export const categoryURLIdentifier = 'cat'
export const stackedCategoryListNew = 'stacked category list new'
