import { Facet, Status } from '@nl/lib'
import { createAction } from 'typesafe-actions'
import { ErrorResponse } from '../models/mergeLoyaltyCard.interface'
import { FilteredOffers } from '../reducers/offers.reducer'
import {
    OfferActivation,
    CustomerOffers,
    TargetedOfferActivation,
    LoyaltyOffersProps,
    Offers,
    CommunityOffers,
    SwapOffer,
    SwapableList,
} from '../models/offers.interface'
import {
    FETCH_CUSTOMER_OFFERS_IN_PROGRESS,
    FETCH_CUSTOMER_OFFERS_SUCCESS,
    FETCH_CUSTOMER_OFFERS_FAILURE,
    ACTIVATE_CUSTOMER_OFFERS_SUCCESS,
    ACTIVATE_CUSTOMER_OFFERS_FAILURE,
    CLEAR_ACTIVATION_SUCCESS,
    CLEAR_ACTIVATION_ERROR,
    ACTIVATE_TARGETED_OFFER_SUCCESS,
    FETCH_FILTERED_OFFERS_FAILURE,
    FETCH_FILTERED_OFFERS_SUCCESS,
    UPDATE_FILTERED_OFFERS_SORTING,
    UPDATE_FILTERED_OFFERS,
    SET_LOYALTY_OFFERS_FETCH_SUCCESS,
    FETCH_COMMUNITY_OFFERS_SUCCESS,
    FETCH_COMMUNITY_OFFERS_FAILURE,
    SWAP_COMMUNITY_OFFER_SUCCESS,
    SWAP_COMMUNITY_OFFER_FAILURE,
    COMMUNITY_SWAPPABLE_OFFER,
    CLEAR_SWAP,
    CLEAR_SWAP_OFFER_ERROR_DATA,
    SET_ACTIVATE_OFFER_COMPLETED,
    UPDATE_SELECTED_FILTERED_OFFERS,
    UPDATE_SELECTED_FACET,
    FETCH_COMMUNITY_FILTERED_OFFERS_SUCCESS,
    UPDATE_COMMUNITY_FILTERED_OFFERS,
    SET_SORT_DATA_OFFER,
    FETCH_SWAPPABLE_FILTERED_OFFERS_SUCCESS,
    SET_COMMUNITY_SELECTED,
    UPDATE_SWAPPABLE_FILTERED_OFFERS,
    UPDATE_SWAPPABLE_SELECTED_FACET,
    UPDATE_SWAPPABLE_SELECTED_FILTERED_OFFERS,
    UPDATE_SWAPPABLE_FILTERED_OFFERS_SORTING,
    CLEAR_FILTER_OFFER,
    FETCH_COMMUNITY_FILTERED_OFFERS_FAILURE,
    UPDATE_COMMUNITY_FILTERED_OFFERS_SORTING,
    UPDATE_COMMUNITY_SELECTED_FILTERED_OFFERS,
    UPDATE_COMMUNITY_SELECTED_FACET,
    SET_SORT_DATA_COMMUNITY,
    SET_SORT_DATA_SWAPPABLE_OFFER,
    FETCH_SWAPPABLE_FILTERED_OFFERS_FAILURE,
    HIDE_EMAIL_ACTIVATION_COMPONENT,
    SET_PTE_PROPS,
} from '../types/profile/offers.actionTypes.constant'
import { dropdownList } from '../../components/LinkRewards/LinkRewards.types'
import { pteListValue } from '../../components/PDPOfferBenefits/PDPOfferBenefits.type'

export const offersSuccessAction = createAction(
    FETCH_CUSTOMER_OFFERS_SUCCESS,
    (payload: CustomerOffers) => payload,
)<CustomerOffers>()

export const offersFailureAction = createAction(FETCH_CUSTOMER_OFFERS_FAILURE)()
export const offersFetchInProgressAction = createAction(
    FETCH_CUSTOMER_OFFERS_IN_PROGRESS,
    (payload: Status) => payload,
)<Status>()

export const offersActivateSuccessAction = createAction(
    ACTIVATE_CUSTOMER_OFFERS_SUCCESS,
    (payload: OfferActivation) => payload,
)<OfferActivation>()

export const offersActivateFailureAction = createAction(ACTIVATE_CUSTOMER_OFFERS_FAILURE)<ErrorResponse>()
export const clearOffersActivateAction = createAction(CLEAR_ACTIVATION_SUCCESS)()
export const clearSwapOfferData = createAction(CLEAR_SWAP)()
export const clearFilterOfferData = createAction(CLEAR_FILTER_OFFER)()
export const clearOfferActivationErrorAction = createAction(CLEAR_ACTIVATION_ERROR)()
export const targetedOfferActivateSuccessAction = createAction(
    ACTIVATE_TARGETED_OFFER_SUCCESS,
    (payload: TargetedOfferActivation[]) => payload,
)<TargetedOfferActivation[]>()
export const filteredOffersSuccessAction = createAction(
    FETCH_FILTERED_OFFERS_SUCCESS,
    (payload: FilteredOffers) => payload,
)<FilteredOffers>()
export const filteredCommunityOffersSuccessAction = createAction(
    FETCH_COMMUNITY_FILTERED_OFFERS_SUCCESS,
    (payload: FilteredOffers) => payload,
)<FilteredOffers>()
export const filteredSwappableOffersSuccessAction = createAction(
    FETCH_SWAPPABLE_FILTERED_OFFERS_SUCCESS,
    (payload: FilteredOffers) => payload,
)<FilteredOffers>()
export const filteredCommunityOffersFailureAction = createAction(FETCH_COMMUNITY_FILTERED_OFFERS_FAILURE)()
export const filteredOffersFailureAction = createAction(FETCH_FILTERED_OFFERS_FAILURE)()
export const filteredSwappableOffersFailureAction = createAction(FETCH_SWAPPABLE_FILTERED_OFFERS_FAILURE)()
export const updateFilteredOffersSortingAction = createAction(UPDATE_FILTERED_OFFERS_SORTING)<string>()
export const updateFilteredCommunitySortingAction = createAction(UPDATE_COMMUNITY_FILTERED_OFFERS_SORTING)<string>()
export const updateFilteredSwappableOfferSortingAction = createAction(
    UPDATE_SWAPPABLE_FILTERED_OFFERS_SORTING,
)<string>()

export const updateFilteredOffersAction = createAction(UPDATE_FILTERED_OFFERS, (payload: Offers[]) => payload)<
    Offers[]
>()
export const updateCommunityFilterOffersAction = createAction(
    UPDATE_COMMUNITY_FILTERED_OFFERS,
    (payload: Offers[]) => payload,
)<Offers[]>()
export const updateSwappableFilterOffersAction = createAction(
    UPDATE_SWAPPABLE_FILTERED_OFFERS,
    (payload: Offers[]) => payload,
)<Offers[]>()

export const updateSelectedFilter = createAction(
    UPDATE_SELECTED_FILTERED_OFFERS,
    (payload: Record<string, string[]>) => payload,
)<Record<string, string[]>>()
export const updateCommunitySelectedFilter = createAction(
    UPDATE_COMMUNITY_SELECTED_FILTERED_OFFERS,
    (payload: Record<string, string[]>) => payload,
)<Record<string, string[]>>()
export const updateSwappableSelectedFilter = createAction(
    UPDATE_SWAPPABLE_SELECTED_FILTERED_OFFERS,
    (payload: Record<string, string[]>) => payload,
)<Record<string, string[]>>()
export const updateSelectedFacet = createAction(UPDATE_SELECTED_FACET, (payload: Facet[]) => payload)<Facet[]>()
export const updateCommunitySelectedFacet = createAction(
    UPDATE_COMMUNITY_SELECTED_FACET,
    (payload: Facet[]) => payload,
)<Facet[]>()
export const updateSwappableSelectedFacet = createAction(
    UPDATE_SWAPPABLE_SELECTED_FACET,
    (payload: Facet[]) => payload,
)<Facet[]>()

export const setLoyaltyOffersSuccessAction = createAction(SET_LOYALTY_OFFERS_FETCH_SUCCESS)<LoyaltyOffersProps>()
export const setPtePropSuccessAction = createAction(SET_PTE_PROPS)<pteListValue>()

export const communityOffersSuccessAction = createAction(
    FETCH_COMMUNITY_OFFERS_SUCCESS,
    (payload: CommunityOffers) => payload,
)<CommunityOffers>()

export const communityOffersFailureAction = createAction(FETCH_COMMUNITY_OFFERS_FAILURE)()

export const swapCommunityOfferSuccessAction = createAction(
    SWAP_COMMUNITY_OFFER_SUCCESS,
    (payload: SwapOffer) => payload,
)<SwapOffer>()

export const swapCommunityOfferFailureAction = createAction(SWAP_COMMUNITY_OFFER_FAILURE)<ErrorResponse>()

export const communitySwappableOffer = createAction(
    COMMUNITY_SWAPPABLE_OFFER,
    (payload: SwapableList) => payload,
)<SwapableList>()

export const clearSwapOfferErrorData = createAction(CLEAR_SWAP_OFFER_ERROR_DATA)()

export const setIsActivateOfferApiDoneAction = createAction(
    SET_ACTIVATE_OFFER_COMPLETED,
    (payload: boolean) => payload,
)<boolean>()
export const updateCommunitySortDataAction = createAction(
    SET_SORT_DATA_COMMUNITY,
    (payload: dropdownList[]) => payload,
)<dropdownList[]>()
export const updateSwappableSortDataAction = createAction(
    SET_SORT_DATA_SWAPPABLE_OFFER,
    (payload: dropdownList[]) => payload,
)<dropdownList[]>()

export const updateOffersSortDataAction = createAction(SET_SORT_DATA_OFFER, (payload: dropdownList[]) => payload)<
    dropdownList[]
>()
export const setIsCommunitySelected = createAction(SET_COMMUNITY_SELECTED, (payload: boolean) => payload)<boolean>()
export const hideEmailActivation = createAction(
    HIDE_EMAIL_ACTIVATION_COMPONENT,
    (payload: boolean) => payload,
)<boolean>()
