import { isOrderSTH } from '@nl/lib'
import { installationAvailable } from '../../components/BuyBox/BuyBox.constant'
import {
    CartAnalytics,
    CartItemFulfillmentSource,
    CartOrderDeliveryModes,
    CartOrderEntries,
    DeliveryOptionType,
    FeeMessage,
    ProductSourceTypes,
    Rebate,
    StockItemAvailability,
} from '../../redux/models/cart.interface'
import { Availability, DeliveryMethod, ShoppingExperience, VehicleInfo } from '../providers/provider.type'
import { ProductRecord } from '../models/productRecord'
import { WishlistErrorService } from '../../components/WishList/WishlistError.service'
import { CartErrorService } from '../../components/ShoppingCart/CartError.service'
import {
    FulfillmentMethods,
    GetWishlistErrorPropType,
    GetErrorFunctionPropType,
    ItemInlineErrorType,
} from '../../components/ShoppingCart/ShoppingCart.type'
import { checkNotNullAndUndefined } from '../../utils/checkNotNullAndUndefined'
import { SaveforLaterErrorService } from '../../components/ShoppingCart/SaveforLaterError.service'
import {
    AvailabilityData,
    FulfilmentData,
    InstallationsData,
    PriceData,
    SflAvailabilityData,
    VariantData,
} from '../analytics.type'

/**
 * class
 */
export class ProductHelper {
    /**
     *
     * @param {FeeMessage} fee
     * @return {string | undefined}
     */
    static transformFee(fee: FeeMessage | undefined): string | undefined {
        return fee?.type ? `${fee?.type?.toLowerCase().replace(/_/g, ' ')}: ${String(fee?.value)}` : 'null'
    }

    /**
     * returns delivery method based on ship to home and bopis eligibility
     * @param  {boolean | undefined} isEligibleToShipHome
     * @param {boolean | undefined} isEligibleToPickupFromStore
     * @return {DeliveryMethod}
     */
    static isShipToHomeAndBopis(
        isEligibleToShipHome: boolean | undefined,
        isEligibleToPickupFromStore: boolean | undefined,
    ): DeliveryMethod {
        if (isEligibleToShipHome && isEligibleToPickupFromStore) {
            return DeliveryMethod.shipToHomeAndBopis
        } else if (isEligibleToShipHome) {
            return DeliveryMethod.shipToHome
        } else if (isEligibleToPickupFromStore) {
            return DeliveryMethod.inStorePickup
        } else {
            return DeliveryMethod.unavailable
        }
    }

    /**
     * Method to find the fullfilment data
     * @template T - the type of the FulfilmentData value
     * @param {T} order order data
     * @param {boolean} isFulfillment fulfillment flag
     * @returns {string} deliveryMode
     */
    static transformFulfillment<T extends FulfilmentData>(order: T, isFulfillment = false): string | undefined {
        if ('serviceType' in order) {
            return undefined
        }
        if (isOrderSTH(order)) {
            if (order.isBulk) {
                switch (
                    order.bulkDeliveryOptions?.filter(bulkProps => bulkProps.isSelected as boolean)[0]
                        ?.deliveryOptionType
                ) {
                    case DeliveryOptionType.STANDARD:
                        return DeliveryMethod.shipToHomeBulkStandard
                    case DeliveryOptionType.IN_HOME_DELIVERY:
                        return DeliveryMethod.inHomeDelivery
                    case DeliveryOptionType.IN_HOME_DELIVERY_UNPACK:
                        return DeliveryMethod.inHomeDeliveryAndUnpack
                }
            } else if (isFulfillment) {
                return order?.fulfillment?.deliveryMode
            } else {
                return this.isShipToHomeAndBopis(
                    order?.fulfillment?.isEligibleToShipHome,
                    order?.fulfillment?.isEligibleToPickupFromStore,
                )
            }
        } else {
            return isFulfillment
                ? order?.fulfillment?.deliveryMode
                : this.isShipToHomeAndBopis(
                      order?.fulfillment?.isEligibleToShipHome,
                      order?.fulfillment?.isEligibleToPickupFromStore,
                  )
        }
    }

    /**
     * This function gets the rebate
     * @param {Rebate} rebate
     * @return {string | undefined}
     */
    static transformRebate(rebate: Rebate | undefined): string | undefined {
        return rebate ? String(rebate?.message) : 'null'
    }

    /**
     * This function is to get the availability of product
     * @template T - the type of the AvailabilityData value
     * @param {T} order
     * @return {string | undefined}
     */
    static transformAvailability<T extends AvailabilityData>(order: T): string | undefined {
        const { fca, notInStock, inStock } = Availability
        return order?.fulfillment?.stockItemAvailability?.storeQuantity
            ? inStock
            : order?.fulfillment?.orderableFromDC
            ? fca
            : notInStock
    }

    /**
     * This function gets the variant details
     * @template T - the type of the VariantData value
     * @param {T} order
     * @return {Record<string, string | undefined>}
     */
    static transFormVariantData = <T extends VariantData>(order: T): Record<string, string | undefined> => {
        const [firstVariant, secondVariant, thirdVariant] = order?.options || []
        const variant1 = firstVariant ? `${firstVariant?.display} - ${firstVariant?.value}` : ''
        const variant2 = secondVariant ? `${secondVariant?.display} - ${secondVariant?.value}` : ''
        const variant3 = thirdVariant ? `${thirdVariant?.display} - ${thirdVariant?.value}` : ''
        return { variant1, variant2, variant3 }
    }

    /**
     * This function checks the installationAvailability
     * @template T - the type of the InstallationsData value
     * @param {T} order
     * @return {string | undefined}
     */
    static transformInstallation = <T extends InstallationsData>(order: T): string => {
        const { yes, no, notApplicable } = installationAvailable
        return order?.hasStoreAutoServiceCenter
            ? yes
            : order?.hasStoreAutoServiceCenter === undefined
            ? notApplicable
            : no
    }

    /**
     * This function creates vehicle object
     * @param {CartOrderEntries} order
     * @return {VehicleInfo}
     */
    static transformVehicle = (order: CartOrderEntries): VehicleInfo => {
        return {
            staggerdFitment: checkNotNullAndUndefined(order.staggeredGroup)
                ? 'false'
                : order.staggeredGroup?.toString(),
            type: order.vehicleInformation?.type,
            make: order.vehicleInformation?.make,
            model: order.vehicleInformation?.model,
            year: order.vehicleInformation?.year,
            body: order.vehicleInformation?.body,
            option: order.vehicleInformation?.options,
        }
    }

    /**
     * This function return price value
     * @template T - the type of the PriceData value
     * @param {T} order
     * @return {number | undefined | null}
     */
    static transformPrice = <T extends PriceData>(order: T): string | undefined | null => {
        return String(order?.currentPrice?.value)
    }

    /**
     * This function return availability value
     * @param {CartOrderEntries} inStockInSelectedStore
     * @param {CartOrderEntries} inStockAtDC
     * @return {string}
     */
    static checkAvailabilityOfProduct = (inStockInSelectedStore: () => boolean, inStockAtDC: () => boolean): string => {
        const { fca, notInStock, inStock } = Availability
        let availability = ''
        if (!inStockInSelectedStore() && inStockAtDC()) {
            availability = fca
        } else if (!inStockInSelectedStore() && !inStockAtDC()) {
            availability = notInStock
        } else if (inStockInSelectedStore()) {
            availability = inStock
        }
        return availability
    }

    /**
     * This function is used to return fulfillment status of sfl
     * @template T - the type of the SflAvailabilityData
     * @param {T} entry
     * @param {unknown} props
     * @return {string}
     */
    static getItemSflAvailabilityInfo = <T extends SflAvailabilityData>(
        entry: T,
        props?: Record<string, string>,
    ): ItemInlineErrorType => {
        const {
            productCannotBePurchasedOnline,
            discontinuedInStorePickUpMsgSfl,
            outOfStockInStorePickUpMsgSfl,
            tempDiscontinuedInStorePickUpMsgSfl,
            deliverySTHErrorSfl,
            productCannotBePurchasedOnlineSfl,
        } = props || {}
        const dcQuantity = entry.fulfillment?.stockItemAvailability?.dcQuantity
        const storeQuantity = entry.fulfillment?.stockItemAvailability?.storeQuantity
        const deliveryMode = entry.fulfillment?.deliveryMode

        const { getError } = SaveforLaterErrorService
        return getError({
            itemData: entry,
            messageObject: {
                productCannotBePurchasedOnline,
                discontinuedInStorePickUpMsgSfl,
                outOfStockInStorePickUpMsgSfl,
                tempDiscontinuedInStorePickUpMsgSfl,
                deliverySTHErrorSfl,
                productCannotBePurchasedOnlineSfl,
            },
            itemType: deliveryMode as string,
            itemAvailabilityData: { dcQuantity, storeQuantity } as StockItemAvailability,
        })
    }

    /**
     * This function is used to return fulfillment status
     * @param {CartOrderEntries} cartOrderEntry
     * @param {unknown} props
     * @return {string}
     */
    static getInlineFulfillmentObject(
        cartOrderEntry: CartOrderEntries,
        props?: Record<string, string>,
    ): ItemInlineErrorType {
        const {
            discontinuedInStoreShipFromMsg,
            outOfStockInStoreShipFromMsg,
            tempOutOfStockShipFromMsg,
            onlySomeInStockShipFromMsg,
            isWishlist,
            discontinuedMsg,
            outOfStockMsg,
            discontinuedInStorePickUpMsg,
            outOfStockInStorePickUpMsg,
            tempOutOfStockPickUpMsg,
            onlySomeInStockMsg,
            productCannotBePurchasedOnline,
            deliveryBOPISError,
            deliverySTHError,
            tempDiscontinuedInStorePickUpMsg,
            tempDiscontinuedInStoreShipFromMsg,
        } = props || {}

        const { getError } = isWishlist ? WishlistErrorService : CartErrorService

        // Below code is to find the error on the product item.
        return getError({
            itemData: cartOrderEntry,
            messageObject: {
                discontinuedMsg,
                outOfStockMsg,
                discontinuedInStorePickUpMsg,
                outOfStockInStorePickUpMsg,
                tempOutOfStockPickUpMsg,
                onlySomeInStockMsg,
                productCannotBePurchasedOnline,
                tempDiscontinuedInStorePickUpMsg,
                discontinuedInStoreShipFromMsg,
                outOfStockInStoreShipFromMsg,
                tempOutOfStockShipFromMsg,
                onlySomeInStockShipFromMsg,
                tempDiscontinuedInStoreShipFromMsg,
                deliveryBOPISError,
                deliverySTHError,
            },
            itemType: isOrderSTH(cartOrderEntry) ? FulfillmentMethods.STH : FulfillmentMethods.BOPIS,
            itemAvailabilityData: cartOrderEntry?.fulfillment?.stockItemAvailability,
        } as unknown as GetErrorFunctionPropType & GetWishlistErrorPropType)
    }

    /**
     *
     * @param {CartItemFulfillmentSource[]} sourceTypes
     * @return {string}
     */
    static getShipFrom(sourceTypes: CartItemFulfillmentSource[]): string {
        let shipFrom = ''
        sourceTypes.forEach((sourceType: CartItemFulfillmentSource) => {
            if (sourceType?.productSourceType === ProductSourceTypes.DC) {
                shipFrom += `${ProductSourceTypes.SHIPFROMDC} `
            } else if (sourceType.productSourceType === ProductSourceTypes.INSTORE) {
                shipFrom += `${ProductSourceTypes.SHIPFROMINSTORE} `
            }
        })
        return shipFrom
    }

    /**
     * Method to construct the shipping method and fulfillment info for each product
     * @param {CartOrderDeliveryModes | string} deliveryMode deliverymode of the cart item or overall cart
     * @param {ProductRecord} product productRecord to update
     * @returns {ShoppingExperience} shippingMethod & fulfillmentType
     */
    static processShippingInfo(
        deliveryMode: CartOrderDeliveryModes | string,
        product?: ProductRecord | CartAnalytics,
    ): ShoppingExperience {
        const shippingMethod =
            deliveryMode === CartOrderDeliveryModes.EXPRESS
                ? 'express'
                : deliveryMode === CartOrderDeliveryModes.STH
                ? 'standard'
                : ''
        const fulfillmentType =
            deliveryMode === CartOrderDeliveryModes.EXPRESS || deliveryMode === CartOrderDeliveryModes.STH
                ? 'sth'
                : 'pickup'
        if (!!product) {
            product.fulfillmentType = fulfillmentType
            product.shippingMethod = shippingMethod
        }
        return { fulfillmentType, shippingMethod }
    }
}
