import { libUtils } from '@nl/lib'
import GigyaService from '../../services/gigyaService/gigya.service'
import { gigyaResetPasswordErrorMapping } from './gigya.constants'
import { gigyaEventType, gigyaLocaleErrorsType } from './gigyaScreen.type'

const gigyaService = new GigyaService()

/**
 * function to get new password value from the input
 * @returns {string}
 */
const getNewPassword = () => {
    const resetPwdField: HTMLInputElement | null = document.querySelector('#gigya-password-newPassword')
    return resetPwdField?.value ?? ''
}

/**
 * function to get email from forgot password form
 * @returns {string}
 */
const getEmailFromForgotPasswordForm = () => {
    const resetPwdEmailField: HTMLInputElement | null = document.querySelector('#gigya-textbox-loginID')
    return resetPwdEmailField?.value ?? ''
}

/**
 * function to get passwordResetToken from the query
 * @returns {string}
 */
const getPasswordResetToken = () => {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    const resetPasswordToken = params.get('pwrt')
    return resetPasswordToken ?? ''
}

/**
 * function to call the resetPassword endpoint to reset password
 * @returns {Promise}
 */
export const resetPassword = () => {
    const newPassword = getNewPassword()
    const passwordResetToken = getPasswordResetToken()
    return gigyaService.resetPassword(passwordResetToken, newPassword)
}

/**
 * function to call the resetPassword endpoint to send email with link to the reset password form
 * @returns {Pomise}
 */
export const sendEmailToResetPassword = () => {
    const email = getEmailFromForgotPasswordForm()
    return gigyaService.sendEmilToResetPassword(email)
}

/**
 * function to get text for reset password error from gigya screenset
 * @param {string} errorCode
 * @returns {string}
 */
export const getTextForResetPasswordError = (errorCode: string) => {
    const resetPasswordErrorKey = gigyaResetPasswordErrorMapping[errorCode] as keyof gigyaLocaleErrorsType
    return window.gigya.i18n['gigya.services.accounts.plugins.screenSet.js'][libUtils.getLanguage()][
        resetPasswordErrorKey
    ]
}

/**
 * function to append reset password error to the form
 * @param {gigyaEventType} event
 * @param {string} errorMessage
 */
export const appendErrorMessageToResetPasswordForm = (event: gigyaEventType, errorMessage: string) => {
    const errorWrapper: NodeListOf<Element> = document.querySelectorAll(
        `.${event.form} .gigya-composite-control-form-error`,
    )

    errorWrapper.forEach((item: Element) => {
        item.classList.add(`gigya-error-display-active`)
    })

    const errorElement: NodeListOf<Element> = document.querySelectorAll(
        '.gigya-reset-password-form .gigya-composite-control-form-error .gigya-form-error-msg',
    )
    errorElement.forEach((item: Element) => {
        item.classList?.add('gigya-error-msg-active')
        item.innerHTML = errorMessage
    })
}
