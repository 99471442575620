/**
 * function to check whether object is empty or not
 * @param {Record<string, unknown>} obj
 * @param {string} attr
 * @return {boolean}
 */
// object type is needed to escape `Index signature for type 'string' is missing in type...` error
// eslint-disable-next-line @typescript-eslint/ban-types
export const isObjectNotEmpty = <T extends object>(obj: T, attr?: keyof T): boolean => {
    return attr
        ? !(obj[attr] === undefined || obj[attr] === null)
        : !(obj === undefined || obj === null || Object.keys(obj).length === 0)
}
