import React, { useState } from 'react'

import AutomotiveProductCard from './AutomotiveProductCard'
import { productsCountWhenPackageIsIncomplete } from './AutomotiveProductCards.constant'

import { PREFIX } from '../config'
import {
    AutoProductCartItemsProps,
    iService,
    ProductDataDTO,
    eEntryType,
} from '../ProductCartItem/ProductItemInfo.type'
import { magicNumber } from '../../utils/magicNumber'
import { isProductTire } from './AutomotiveProductCards.helper'
import CommonWarningToast from '../ProductCartItem/CommonWarningToast'
import { incrementOne } from '../ProductCartItem/ProductItemInfo.constant'

const AutomotiveProductCardsWrapper = (props: AutoProductCartItemsProps): JSX.Element => {
    const [availabilityWarning, setAvailabilityWarning] = useState({ display: false, label: '' })
    const automotiveClass = `${PREFIX}-automotive-card`
    const productData = props.products[0]
    const { body = '', make = '', model = '', options = '', year = '' } = productData?.vehicleInformation || {}
    const [isAddCompatibleBtn, setAddCompatibleBtn] = useState(false)

    const renderAvailabilityWarning = (): JSX.Element =>
        availabilityWarning.display && (
            <CommonWarningToast icon="ct-notification-caution" bgType="orange" label={availabilityWarning.label} />
        )

    /**
     * Is the last Product
     * @param {number} index
     * @param {ProductDataDTO[]} products
     * @return {boolean}
     */
    const isLastProduct = (index: number, products: ProductDataDTO[]): boolean => {
        const resultIndex: number = index + incrementOne
        return resultIndex === products.length
    }

    /**
     * Get extra hardware for the vehicle
     * @param {Record<string, unknown>} hardwareServices - hardware data
     * @return {iService}
     */
    const getExtraHardware = (hardwareServices: Record<string, unknown>): iService[] => {
        const vehicleInfoId = `${year}${make}${model}${body}${options}`.toLowerCase()
        const filteredHardwareServices = hardwareServices[vehicleInfoId]
            ? (hardwareServices[vehicleInfoId] as iService[])
            : ([] as iService[])
        return filteredHardwareServices.slice(0, props.maxNumberOfExtraHardware)
    }

    /**
     * Checks if hardware addon is already selected
     * @return {boolean}
     */
    const hasSelectedAddOn = (): boolean => {
        return (
            props.products[props.products.length - magicNumber.ONE]?.entryType === eEntryType.PRODUCT &&
            !!props.products[props.products.length - magicNumber.ONE].cartEntryReference
        )
    }

    /**
     * Get selected hardware addon list
     * @return {ProductDataDTO[]}
     */
    const getSelectedAddOn = (): ProductDataDTO[] => {
        return props.products.filter(
            product => product.entryType === eEntryType.PRODUCT && !!product.cartEntryReference,
        )
    }

    const checkHardErrors = (): boolean => {
        return props.products[0].itemAvailabilityInfo?.hardStopError
    }

    /**
     * Function to initiate Package and set isAddCompatibleBtn to true to prevent the CompletePackageToast message getting change in background
     * @param {number} event
     * @param {ProductDataDTO[]} productDataValue
     * @return {void}
     */
    const initiatePackage = (event, productDataValue): void => {
        props.createPackageAndNavigate(event, productDataValue)
        setAddCompatibleBtn(true)
    }

    const renderCompletePackageToast = (): JSX.Element | null => {
        return (
            productData?.isPackagedItem &&
            !isAddCompatibleBtn &&
            props.products.length === productsCountWhenPackageIsIncomplete &&
            !checkHardErrors() && (
                <CommonWarningToast
                    icon="ct-notification-caution"
                    bgType="orange"
                    label={props.packageIsNotCompleteMsg}
                    checkNearByText={props.completePackageMsg}
                    actionCTAHandler={() =>
                        props.completePackage &&
                        props.completePackage(
                            productData.productWheelType,
                            productData.packageId,
                            productData.vehicleInformation,
                        )
                    }
                />
            )
        )
    }

    return (
        <>
            {renderAvailabilityWarning()}
            <div className={`${PREFIX}-common-card ${automotiveClass}`}>
                {productData?.isPackagedItem || productData?.isPackageItem ? (
                    <h3 className={`${automotiveClass}__package-label`}>{props.packagedItemLabel}</h3>
                ) : null}
                {renderCompletePackageToast()}
                <div className={`${automotiveClass}-wrapper`}>
                    {props.products.map((product, index) => (
                        <AutomotiveProductCard
                            key={index}
                            onlineOrdering={props.onlineOrdering}
                            productData={product}
                            {...props}
                            {...product}
                            setAvailabilityWarning={setAvailabilityWarning}
                            addons={
                                isLastProduct(index, props.products) && props.hasWheels
                                    ? getExtraHardware(props.hardwareServices)
                                    : []
                            }
                            hasSelectedAddOn={hasSelectedAddOn()}
                            getSelectedAddOn={getSelectedAddOn()}
                            a11yStrikeOutPrice={props.a11yStrikeOutPrice}
                            a11yStrikeOutPriceRange={props.a11yStrikeOutPriceRange}
                            isTire={isProductTire(product)}
                            sameEtaForSameYMM={props.sameEtaForSameYMM}
                            a11yClickToReadFootnote={props.a11yClickToReadFootnote}
                            productsRequirePackageCreationMessage={props.productsRequirePackageCreationMessage}
                            a11yTooltipIcon={props.a11yTooltipIcon}
                            quantitySelectorId={`${props.quantitySelectorId}-${index}`}
                            createPackageAndNavigate={event => initiatePackage(event, productData)}
                        />
                    ))}
                </div>
            </div>
        </>
    )
}

export default AutomotiveProductCardsWrapper
