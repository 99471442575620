import { AxiosPromise } from 'axios'
import { getEnvironment } from '../environments'

import { getHttpClient } from '../httpClient'
import { apiPost, apiGet, RequestBodyType, ExternalSystemNameType } from '../redux/utils/httpClient'
import { HttpReqHeaders } from '../redux/utils/httpClient.type'
import { apiMethods } from './service.constants'
const httpClient = getHttpClient()
const environment = getEnvironment()

/**
 * Base service class for new service implementations
 */
abstract class BaseService {
    static externalSystem: ExternalSystemNameType
    static language = environment.language

    /**
     * make post request for provided arguments
     * @param  {URL} url
     * @param  {RequestBodyType|string} body?
     * @param  {HttpReqHeaders} headers?
     */
    static post(url: URL, body?: RequestBodyType | string, headers?: HttpReqHeaders): void {
        void apiPost(url, body, BaseService.externalSystem, headers)
    }

    /**
     *
     * @param {URL | string} url
     * @returns {AxiosPromise}
     */
    static get(url: URL | string): AxiosPromise {
        return apiGet(url.toString())
    }

    /**
     * This function used to create uri
     * @param  {string} urlPathWithQueryParams
     * @return {URL} url
     */
    static createUri(urlPathWithQueryParams: string): URL {
        return new URL(`${environment.API_BASE_URL}${urlPathWithQueryParams}`)
    }

    /**
     * Switch between various http methods.
     * @param {string} reqMethod
     * @param {string} url
     * @param {object} requestBody
     * @param {HttpReqHeaders} headers
     * @param {boolean} credentials
     * @return {AxiosPromise}
     */
    static __configureAPIMethod = <T = RequestBodyType>(
        reqMethod: string,
        url: string,
        requestBody?: T,
        headers?: HttpReqHeaders,
        credentials?: boolean,
    ): AxiosPromise => {
        switch (reqMethod.toUpperCase()) {
            case apiMethods.POST:
                return httpClient.apiPost(url, JSON.stringify(requestBody), headers, credentials)
            case apiMethods.PUT:
                return httpClient.apiPut(url, requestBody, headers, credentials)
            case apiMethods.DELETE:
                return httpClient.apiDelete(url, requestBody, headers, credentials)
            case apiMethods.PATCH:
                return httpClient.apiPatch(url, requestBody, headers, credentials)
            default:
                return httpClient.apiGet(url, requestBody, headers, credentials)
        }
    }

    /**
     * Switch between get and post calls.
     * @param {string} reqMethod
     * @param {string} url
     * @param {object} requestBody
     * @param {HttpReqHeaders} headers
     * @param {boolean} credentials
     * @return {AxiosPromise}
     */
    static apiMethod = <T = Record<string, unknown>>(
        reqMethod: string,
        url: string,
        requestBody?: T,
        headers?: HttpReqHeaders,
        credentials = false,
    ): AxiosPromise => BaseService.__configureAPIMethod(reqMethod, url, requestBody, headers, credentials)
}

export { BaseService }
export default BaseService
