import { MiniCartData, FilteredCartData } from '../redux/models/cart.interface'

/**
 * Function to convert MiniCartData to FilteredCartData with cartId and numberOfItems fields
 * @param {MiniCartData} cartData MiniCartData for converting
 * @return {FilteredCartData} returns FilteredCartData
 */
const convertToCartItemsDataForAuth = (cartData: MiniCartData): FilteredCartData => {
    return {
        cart: {
            cartId: cartData.cartId,
            numberOfItems: cartData.numberOfItems,
        },
    } as FilteredCartData
}

export default convertToCartItemsDataForAuth
