import {
    resetAutoPackagesDataAction,
    resetTireDataAction,
    setCreatePackageModalOpenAction,
    setIsTiresDataSyncedWithLSOnPageLoadAction,
    setVehicleSelectionModeAction,
} from './../actionCreators/automotiveVehicle.actionCreators'
import { Dispatch } from 'redux'
import { AxiosError, AxiosResponse } from 'axios'

import { UPDATE_TIRE_DATA, UPDATE_VEHICLE_DATA } from '../types/automotive/automotiveVehicle.actionTypes.constant'
import { TireType } from '../models/tireVehicle.interface'
import { Vehicle } from '@nl/lib'
import { automotiveVehicleService } from '../../services/vehicleSelector'
import {
    fetchProductCardDataErrorAction,
    resetCriticalFitmentDetails,
    setCriticalFitmentDetails,
    setCriticalFitmentFailureAction,
    setVehicleFitTypeAction,
    triggeredCriticalFitmentAction,
    criticalFitmentDoneAction,
    vehicleSelectorTabsAction,
} from '../actionCreators'
import {
    setTabSelectorAction,
    updateTiresDataAction,
    updateVehicleDataAction,
    updateUserInitializedPackageFlowAction,
    updateAutoPackageAction,
    fetchTiresDataSuccessAction,
    fetchWheelsDataSuccessAction,
    updateCurrentPackageIdAction,
    setCreatePackageClickAction,
} from '../actionCreators/automotiveVehicle.actionCreators'
import { AutoPackages } from '../../components/AutomotivePackage/PackageLanding/PackageLanding.type'
import PlpService from '../../services/plpService'
import { GetProductDataOptionalValues } from '../models/productData.interface'
import localStorageService from '../../utils/localStorageService'
import { storageData, VehicleFitType } from '../../globalConstants'
import getPageType from '../../utils/getPageType'
import { pageTypes } from '../../config'
import { ProductResponseErrorDTO } from '../models/product.interface'
import { DEFAULT_VEHICLE_SELECTOR_TAB_LABEL } from '../../components/Vehicles/Vehicles.constant'

export const updateTiresData =
    (requestPayload: TireType) =>
    (dispatch: Dispatch<{ type: typeof UPDATE_TIRE_DATA; payload: TireType }>): void => {
        dispatch(updateTiresDataAction(requestPayload))
    }

export const setIsTiresDataSyncedWithLSOnPageLoad =
    (value: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsTiresDataSyncedWithLSOnPageLoadAction(value))
    }

export const resetTireData =
    () =>
    (dispatch: Dispatch): void => {
        localStorageService.removeItem(storageData.tiresSizeData)
        dispatch(setVehicleSelectionModeAction(''))
        dispatch(resetTireDataAction())
    }

export const setVehicleSelectionMode =
    (requestPayload: string) =>
    (dispatch: Dispatch): void => {
        dispatch(setVehicleSelectionModeAction(requestPayload))
    }

export const updateVehicleData =
    (requestPayload: Vehicle) =>
    (dispatch: Dispatch<{ type: typeof UPDATE_VEHICLE_DATA; payload: Vehicle }>): void => {
        dispatch(updateVehicleDataAction(requestPayload))
    }

export const criticalFitmentAction =
    (vehicle: Vehicle, breadcrumbList: string[], pCode: string, tireSize?: TireType) =>
    (dispatch: Dispatch): void => {
        dispatch(triggeredCriticalFitmentAction())
        automotiveVehicleService
            .criticalFitData(vehicle, breadcrumbList, pCode, tireSize)
            .then((resp: AxiosResponse) => {
                dispatch(setCriticalFitmentDetails(resp.data))
                dispatch(criticalFitmentDoneAction())
                dispatch(
                    vehicleSelectorTabsAction({
                        needToRenderTabs: false,
                        selectedTabLabel: DEFAULT_VEHICLE_SELECTOR_TAB_LABEL,
                    }),
                )
            })
            .catch(() => {
                dispatch(criticalFitmentDoneAction())
                dispatch(setCriticalFitmentFailureAction())
                if (getPageType() === pageTypes.productPage) {
                    dispatch(setVehicleFitTypeAction(VehicleFitType.NotFit))
                }
                dispatch(
                    vehicleSelectorTabsAction({
                        needToRenderTabs: false,
                        selectedTabLabel: DEFAULT_VEHICLE_SELECTOR_TAB_LABEL,
                    }),
                )
            })
    }

export const setSelectedVehicleSelectorTab =
    (selectedTab: string) =>
    (dispatch: Dispatch): void => {
        dispatch(setTabSelectorAction(selectedTab))
    }

/**
 *
 * @param {boolean} value
 * @return {void} update value as true when user selected wheel or tire and will be on package flow accordingly.
 */
export const updateUserInitializedPackageFlow =
    (value: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(updateUserInitializedPackageFlowAction(value))
    }
/**
 *
 * @param {number} value
 * @return {void} update current package id value
 */
export const updateCurrentPackageId =
    (value: string) =>
    (dispatch: Dispatch): void => {
        dispatch(updateCurrentPackageIdAction(value))
    }

/**
 *
 * @param {AutoPackages[]} value We need to pass array of autopackage as input
 * @return {void} update value as true when user selected wheel or tire and will be on package flow accordingly.
 */
export const updateAutoPackages =
    (value: AutoPackages[]) =>
    (dispatch: Dispatch): void => {
        dispatch(updateAutoPackageAction(value))
    }

/**
 * function to fetch list of products
 * @param {string} requestPayload
 * @param {string} selectedPreferredStoreId
 * @param {boolean | undefined} isFitmentRequired
 * @param {number} productToFetch
 * @param {string} categoryId
   @param {boolean} isTirePLPData
 * @param {number} pageNumber
 * @param {string} categoryURL
 * @param {string} defaultVehicle
 * @return {Promise}
 */
export const fetchProductCardDataForPackages =
    (
        requestPayload: string,
        selectedPreferredStoreId: string,
        isFitmentRequired?: boolean,
        productToFetch?: number,
        categoryId?: string,
        isTirePLPData?: boolean,
        pageNumber?: number,
        categoryURL?: string,
        defaultVehicle?: Vehicle,
    ): ((dispatch: Dispatch) => Promise<void>) =>
    (dispatch: Dispatch): Promise<void> => {
        const optionalValues = {
            productToFetch: productToFetch,
            pathwayId: '',
            breadcrumbList: [],
            tireOrDefaultVehicle: defaultVehicle,
            categoryId: categoryId,
            categoryURL: categoryURL,
        } as GetProductDataOptionalValues
        return PlpService.getProductData(
            requestPayload,
            selectedPreferredStoreId,
            isFitmentRequired,
            pageNumber,
            optionalValues,
        )
            .then(data => {
                if (isTirePLPData) {
                    dispatch(fetchTiresDataSuccessAction(data.data))
                } else {
                    dispatch(fetchWheelsDataSuccessAction(data.data))
                }
            })
            .catch((error: AxiosError<ProductResponseErrorDTO>) => {
                const { data, status } = error.response as unknown as ProductResponseErrorDTO
                dispatch(fetchProductCardDataErrorAction({ data, status }))
            })
    }

export const resetCriticalFitmentAction =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetCriticalFitmentDetails())
    }

export const resetAutoPackagesData =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetAutoPackagesDataAction())
    }

export const setCreatePackageModalOpen =
    (data: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setCreatePackageModalOpenAction(data))
    }

export const setCreatePackageClick =
    (data: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setCreatePackageClickAction(data))
    }
