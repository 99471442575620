import { magicNumber } from '../../utils/magicNumber'
import { Price } from '../Price/Price.types'
import { iPrice } from '../PriceOld/PriceOld.types'
import { CartOrderEntries } from './ProductCartItem.type'

/**
 * Extract each product service based on product reference number.
 *
 * @param {string} productItemIdentifier - unique identifier to identify the product item.
 * @param {CartOrderEntries} selectedService - Array which contains all the service added to the cart.
 * @param {boolean} isOrderDetailsPage
 * @param {string} parentProductStatus
 * @param {string} deliveryMode
 * @return {CartOrderEntries[]} if nothing is filtered it will return undefined. If an item is found it will return the item from the array.
 */
export const extractServiceBasedOnSku = (
    productItemIdentifier: string,
    selectedService: CartOrderEntries[],
    isOrderDetailsPage?: boolean,
    parentProductStatus?: string,
    deliveryMode?: string,
): CartOrderEntries[] => {
    if (!isOrderDetailsPage) {
        return selectedService?.filter(
            singleItem =>
                !!singleItem.cartEntryReference?.split(',').includes(productItemIdentifier) &&
                (deliveryMode
                    ? deliveryMode?.toUpperCase() === singleItem?.fulfillment?.deliveryMode?.toUpperCase()
                    : true),
        )
    } else {
        return selectedService?.filter(
            singleItem =>
                !!singleItem.cartEntryReference?.split(',').includes(productItemIdentifier) &&
                singleItem.status === parentProductStatus,
        )
    }
}

/**
 *
 * @param {CartOrderEntries}serviceData
 * @param {Price}currentPrice
 * @param {iPrice}totalPrice
 * @param {number}oneProductQuantity
 * @param {number}itemQuantity
 * @param {boolean}isWheelOrTire
 * @return {iPrice | Price}
 */
export const getPriceValue = (
    serviceData: CartOrderEntries,
    currentPrice: Price,
    totalPrice: iPrice,
    oneProductQuantity: number,
    itemQuantity: number,
    isWheelOrTire: boolean,
): iPrice | Price => {
    const otherPriceValue = !!serviceData
        ? serviceData.quantity > oneProductQuantity
            ? serviceData.totalPrice
            : serviceData.currentPrice
        : itemQuantity > oneProductQuantity
        ? totalPrice
        : currentPrice
    const newPrice = currentPrice?.value * magicNumber.FOUR
    const autoPartsPrice = { ...currentPrice, value: newPrice }
    return isWheelOrTire ? autoPartsPrice : otherPriceValue
}
