import React from 'react'
import { PREFIX } from '../config'
import { SpinnerProps, SpinnerStyle } from './Spinner.types'
import { magicNumber, isColorStrVal } from '../../utils'
import { isArrayEmpty } from '../../utils/isArrayEmpty'
import { checkDataLength } from '../../utils/checkDataLength'
import Icon from '../Icon'

const Spinner: React.FC<SpinnerProps> = ({ ...props }): JSX.Element => {
    const { color, a11yLabel, style = SpinnerStyle.DEFAULT, value } = props
    const spinnerClass = `${PREFIX}-spinner`
    const colorClass = isColorStrVal(color) ? color : 'inline'
    const borderColor = isColorStrVal(color) ? color : `${color} ${color} ${color} transparent`
    const spinnerAnimation = value?.animation ? `${spinnerClass}--${value?.animation}` : ''

    const spinnerStyle = (): JSX.Element | null => {
        switch (style) {
            case SpinnerStyle.SPLIT_TRAILING:
                return (
                    <svg aria-hidden="true" className={`${spinnerClass}__container`}>
                        {[Array(magicNumber.THREE)].map((_: undefined, i: number) => (
                            <circle key={i} cx={magicNumber.FIFTY} cy={magicNumber.FIFTY} r={magicNumber.TWENTYFIVE} />
                        ))}
                    </svg>
                )
            case SpinnerStyle.DEFAULT:
            default:
                return null
        }
    }

    const innerValue = (): JSX.Element[] =>
        value?.type.map((a: string, i: number) => <Icon key={i} type={a} size={value?.size ? value?.size : 'xl'} />)

    return (
        <div
            data-testid="spinner"
            aria-label={a11yLabel}
            className={`${spinnerClass} ${spinnerClass}--${style} ${spinnerAnimation} ${
                color ? `${spinnerClass}--color-${colorClass}` : ''
            }`}
            style={color && style === SpinnerStyle.DEFAULT ? { borderColor: borderColor } : {}}>
            {spinnerStyle()}
            {!isArrayEmpty(value?.type) && checkDataLength(value) && innerValue()}
        </div>
    )
}

export default Spinner
