import { IGlobalProps } from '../redux/models/commonContent.interface'
import { CommonContentState } from '../redux/reducers/commonContent.reducer'
import store from '../store'
import { RootState } from '../redux/reducers'
import { Status } from '../globalConstants'
import { SingleRegistration } from './ciam.type'

/**
 * Function to get Common Content
 * @returns {CommonContentState} - common content state
 */
export const getCommonContent = (): CommonContentState => {
    const state = store.getState() as unknown as RootState
    return state.commonContent
}

/**
 * Function to get ClpStatus
 * @param {IGlobalProps} commonContentAvailable - global props from commonContent
 * @param {string} commonContentLoadStatus - load status from commonContent
 * @returns {string | boolean} - clp status
 */
export const getCommonContentWithResult = (
    commonContentAvailable: IGlobalProps,
    commonContentLoadStatus: string,
    contentFilter: (commonContentAvailable: IGlobalProps) => boolean | undefined | SingleRegistration,
): string | boolean | undefined | SingleRegistration => {
    return commonContentLoadStatus === Status.error || commonContentLoadStatus === Status.failed
        ? Status.error
        : contentFilter(commonContentAvailable)
}
