import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import { PREFIX, previousElementName } from '../config'
import { OfferCardsType, BannerImagePath, SwapOfferLimit } from './OfferCard.type'
import Button from '../Button'
import { replaceMultipleStrWithDynamicVal, replaceStrWithDynamicVal } from '../../utils/replaceStrWithDynamicVal'
import { magicNumber } from '../../utils/magicNumber'
import { offerEndDateRegex } from '../../globalConstants/regexPatterns.constant'
import { applyAkamaiPolicy } from '../../utils/getAkamaiPolicy'
import Accordion from '../Accordion'
import RecommendationsList from '../RecommendationsList'
import NotificationBadge from '../NotificationBadge'
import { alertRadius, alertTypes, alertSizes } from '../NotificationBadge/NotificationBadge.type'
import Heading from '../Heading'
import { Size, Variation } from '../../types'
import ButtonV2 from '../ButtonV2'
import { ButtonColors } from '../ButtonV2/Button.types'
import { getColorClass } from './OfferCard.helper'
import { statusLabel } from '../../globalConstants/global.constant'

const OfferCard: React.FC<OfferCardsType> = props => {
    const {
        offer,
        bannerImageList,
        offerEndsLabel,
        isFlyout,
        activateOffer,
        modalLabel,
        classNameSubtitle,
        classNameDates,
        classNameAlign,
        classNameCarousel,
        closeModal,
        offerEndsTodayLabel,
        offerEndsDateLabel,
        columns = {},
        status,
        openModal,
        hasLoyaltyAccount = true,
        updateOfferState,
        component,
        filteredOffers,
        akamaiPolicy,
        moreDetailsLabel,
        isAccordionOpen,
        alidaAppId,
        offersSortBy,
        recomendationList,
        swapLimit = {},
        condensedClass,
        showSwapCTA = true,
        condensedView = false,
        loyaltyCardNumber,
    } = props

    const { md, sm, xs } = columns
    const { details, displayBanner, daysLeft, offerEndDate, offerQuantity, offerCode } = offer || {}
    const { numberOfOffersLeftText, a11yNumberOfOffersLeft, numberOfOffersLeftBadgeLimit } = swapLimit as SwapOfferLimit

    const {
        returnPolicy,
        offersCardImgComponentName,
        bannerLogoImgComponentName,
        fallbackOfferImageUrl,
        fallbackImageForBrandLogo,
        fallbackImageForBrandLogoAltText,
    } = akamaiPolicy || {}
    const colSizes = `${PREFIX}-col-md-${md} ${PREFIX}-col-sm-${sm} ${PREFIX}-col-xs-${xs}`
    const setBannerLogo = (bannerName: string): BannerImagePath | undefined => {
        const getBannerName = bannerName?.toString()

        return (
            bannerImageList &&
            bannerImageList.find((imageList: BannerImagePath) => getBannerName === imageList.bannerId)
        )
    }
    const mutipleBanner = displayBanner?.includes(',')

    const { bannerImagePath, bannerImageAlt } = setBannerLogo(displayBanner) || {}

    const brandImage = mutipleBanner ? fallbackImageForBrandLogo : bannerImagePath
    const brandImageAlt = mutipleBanner ? fallbackImageForBrandLogoAltText : bannerImageAlt

    const componentClassName = `${PREFIX}-offers-card`

    const dateLabel =
        Number(daysLeft) <= magicNumber.ONE
            ? offerEndsTodayLabel
            : replaceMultipleStrWithDynamicVal(offerEndsLabel, [daysLeft])

    const newDate =
        Number(daysLeft) > magicNumber.SEVEN
            ? `${offerEndsDateLabel || 'Offer Ends:'} ${offerEndDate.replace(offerEndDateRegex, '$1/$2/$3')}`
            : dateLabel

    /**
     * Display Modal Header containg desc and close button
     * @returns { JSX.Element }
     */
    const modalHeader = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-offers-card__modal--header`}>
                <h2 className={`${PREFIX}-offers-card__modal--title`}>{modalLabel}</h2>
                <Button type="icon_button" size="mini" id="close-modal" onClick={() => closeModal(false)}>
                    <Icon type="ct-close" size="lg" />
                </Button>
            </div>
        )
    }

    /**
     * Display subtitle and description on the basis of flyout
     * @returns { JSX.Element }
     */
    const renderOfferSubtitle = (): JSX.Element => {
        return isFlyout ? (
            <Heading
                variant={'h3'}
                componentClass={`${PREFIX}-offers-card__modal--subtitle ${classNameSubtitle ? classNameSubtitle : ''}`}>
                {details?.offerShortDesc}
            </Heading>
        ) : (
            <div className={`${PREFIX}-offers-card__wrapper--subtitle`}>
                <p
                    title={details?.offerShortDesc}
                    className={`${PREFIX}-offers-card__wrapper--subtitle-description ${
                        classNameSubtitle ? classNameSubtitle : ''
                    }`}>
                    {details?.offerShortDesc}
                </p>
            </div>
        )
    }

    /**
     * Display offer title and ends date
     * @returns { JSX.Element }
     */
    const renderOfferTitleAndDate = (): JSX.Element => (
        <div className={`${PREFIX}-offers-card__${isFlyout ? 'modal-align' : 'wrapper-align'} ${classNameAlign}`}>
            {renderOfferSubtitle()}
            {renderOffersLoyaltyContent()}
        </div>
    )

    /**
     * Display offer description
     * @returns { JSX.Element }
     */
    const renderBodyDescription = (): JSX.Element => (
        <div className={`${componentClassName}__details-desc`}>
            {isFlyout && renderOfferTitleAndDate()}
            <p className={`${PREFIX}-h4`}>{details?.bonusDescription}</p>
        </div>
    )

    /**
     * Renders offers expiry date component
     * @returns {JSX.Element}
     */
    const renderOffersLoyaltyContent = (): JSX.Element => {
        return (
            !!hasLoyaltyAccount && (
                <div className={`${PREFIX}-offers-card__wrapper-end`}>
                    <span
                        className={`${PREFIX}-offers-card__${isFlyout ? `modal` : `wrapper`}--dates ${
                            classNameDates ? classNameDates : ''
                        }`}
                        title={newDate}>
                        {newDate}
                    </span>
                    {condensedView && renderFooter()}
                </div>
            )
        )
    }

    /**
     * Renders offer notification badge to display swap limit left
     * @returns {JSX.Element}
     */
    const renderNotificationBadge = (): JSX.Element => {
        return (
            !!offerQuantity &&
            Number(offerQuantity) < numberOfOffersLeftBadgeLimit && (
                <NotificationBadge
                    value={replaceStrWithDynamicVal(numberOfOffersLeftText, offerQuantity)}
                    a11yValue={replaceStrWithDynamicVal(a11yNumberOfOffersLeft, offerQuantity)}
                    notificationClass={`${componentClassName}__notification`}
                    type={alertTypes.SUCCESS}
                    size={alertSizes.LG}
                    radius={alertRadius.ROUNDED_RIGHT}
                />
            )
        )
    }

    /**
     * Display offer card
     * @returns { JSX.Element }
     */
    const renderBody = (): JSX.Element => (
        <div className={`${isFlyout ? `${PREFIX}-offers-card__modal-content` : ''}`}>
            <ButtonV2
                variant={Variation.TERTIARY}
                color={ButtonColors.LIGHT}
                aria-haspopup="true"
                id={`offer-${offerCode}`}
                disabled={isFlyout}
                customClass={`${componentClassName}--btn`}
                onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
                    const elem = e.currentTarget as HTMLElement
                    elem.setAttribute(previousElementName, 'true')
                    !isFlyout && openModal(offer, e)
                }}>
                <div className={`${componentClassName}__banner`}>
                    <img
                        className={`${componentClassName}__logo`}
                        alt={brandImageAlt}
                        {...applyAkamaiPolicy(brandImage, bannerLogoImgComponentName, false, returnPolicy)}
                    />
                    <img
                        className={`${componentClassName}__logo`}
                        alt=""
                        {...applyAkamaiPolicy(details?.badgeImageUrl, bannerLogoImgComponentName, false, returnPolicy)}
                    />
                </div>
                {!condensedView && (
                    <div className={`${componentClassName}__image-container`}>
                        <picture>
                            <source
                                type="image/png"
                                {...applyAkamaiPolicy(
                                    details?.thumbnailUrl,
                                    offersCardImgComponentName,
                                    false,
                                    returnPolicy,
                                )}
                            />
                            <img
                                className={`${componentClassName}__img`}
                                alt=""
                                {...applyAkamaiPolicy(
                                    fallbackOfferImageUrl,
                                    offersCardImgComponentName,
                                    false,
                                    returnPolicy,
                                )}
                            />
                        </picture>
                        {renderNotificationBadge()}
                    </div>
                )}
                {!isFlyout && renderOfferTitleAndDate()}
            </ButtonV2>
            {isFlyout && renderBodyDescription()}
        </div>
    )

    /**
     * render offer status and display button
     * @returns { JSX.Element }
     */
    const renderFooter = (): JSX.Element =>
        !!hasLoyaltyAccount &&
        showSwapCTA && (
            <div className={`${componentClassName}__footer`}>
                <ButtonV2
                    id={`activate-cta-${offerCode}`}
                    variant={Variation.PRIMARY}
                    size={Size.MINI}
                    customClass={` ${
                        status?.class == statusLabel.activatedStatus?.toLocaleLowerCase()
                            ? `${componentClassName}__cta-icon`
                            : ''
                    }`}
                    color={getColorClass(status?.class)}
                    disabled={
                        status?.class == statusLabel.activatedStatus?.toLocaleLowerCase() ||
                        status?.class == statusLabel.redeemedStatus?.toLocaleLowerCase()
                    }
                    a11y={{
                        disabled:
                            status?.class == statusLabel.activatedStatus?.toLocaleLowerCase() ||
                            status?.class == statusLabel.redeemedStatus?.toLocaleLowerCase(),
                    }}
                    onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
                        e.stopPropagation()
                        alidaAppId
                            ? activateOffer(
                                  offer,
                                  updateOfferState,
                                  filteredOffers,
                                  component,
                                  alidaAppId,
                                  loyaltyCardNumber,
                              )
                            : activateOffer(
                                  offer,
                                  updateOfferState,
                                  filteredOffers,
                                  component,
                                  offersSortBy,
                                  loyaltyCardNumber,
                              )
                    }}
                    icon={{ type: status?.icon, size: 'lg' }}>
                    {status?.label}
                </ButtonV2>
            </div>
        )

    /**
     * Display more details section in offer modald
     * @returns { JSX.Element }
     */
    const renderAccordion = (): JSX.Element => (
        <div className={`${componentClassName}__modal--more-details`}>
            <Accordion title={moreDetailsLabel} isHeaderOpen={isAccordionOpen}>
                <p className={`${PREFIX}-body-sm`}>{details?.bonusDetailedDescription}</p>
            </Accordion>
        </div>
    )

    /**
     * render offer recommendation List
     * @returns { JSX.Element }
     */
    const renderRecommendationsComponent = (): JSX.Element => (
        <RecommendationsList
            title={recomendationList.title}
            productList={recomendationList?.productList}
            priceProps={recomendationList?.priceProps}
            a11yStrikeOutPrice={recomendationList.a11yStrikeOutPrice}
            a11yStrikeOutPriceRange={recomendationList.a11yStrikeOutPriceRange}
            language={recomendationList.language}
            returnPolicy={returnPolicy}
            selectedSchemaId={recomendationList.selectedSchemaId}
            isYouNeedThis={false}
            badgePriorities={recomendationList.badgePriorities}
            enableBadges={true}
            maximumNumberOfRecommendations={recomendationList.maximumNumberOfRecommendations}
        />
    )
    /**
     * Renders offer descriptions and footer
     * @returns { JSX.Element }
     */
    const renderBodyAndFooter = (): JSX.Element => (
        <div
            className={`${
                isFlyout
                    ? `${componentClassName}__details`
                    : `${colSizes} ${classNameCarousel} ${componentClassName}__tile ${condensedClass}`
            }`}
            data-testid="weekly-offers-card">
            <div className={`${componentClassName}__${isFlyout ? 'modal-wrapper' : 'wrapper'}`}>
                {renderBody()}
                {isFlyout && renderAccordion()}
                {isFlyout && recomendationList?.productList && renderRecommendationsComponent()}
                {!condensedView && renderFooter()}
            </div>
        </div>
    )

    return (
        <>
            {isFlyout && modalHeader()}
            {renderBodyAndFooter()}
        </>
    )
}

OfferCard.propTypes = {
    classNameAlign: PropTypes.string,
    classNameCarousel: PropTypes.string,
}

OfferCard.defaultProps = {
    classNameAlign: '',
    classNameCarousel: '',
}

export default OfferCard
