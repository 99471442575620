import {
    ButtonType,
    ButtonColors,
    IButtonBase,
    ButtonProps,
    IconButtonProps,
    SpinnerButtonProps,
    TabButtonProps,
} from './Button.types'
import { iconSizes } from '../Icon/Icon.types'
import { PREFIX } from '../config'
import { Variation, Size, State } from '../../types'

export const CommonButtonClass = `${PREFIX}-button2`
export const TabButtonClass = `${PREFIX}-button-tab`

export const BaseDefaultProps = {
    variant: Variation.PRIMARY,
    color: ButtonColors.DEFAULT,
    type: ButtonType.DEFAULT,
    size: Size.MEDIUM,
    disabled: false,
    a11y: {
        disabled: false,
        expanded: false,
    },
} as IButtonBase

export const ButtonDefaultProps = {
    ...BaseDefaultProps,
    reverse: false,
} as ButtonProps

export const IconDefaultProps = {
    ...BaseDefaultProps,
    icon: {
        size: iconSizes.Medium,
    },
} as IconButtonProps

export const SpinnerDefaultProps = {
    ...BaseDefaultProps,
    showSpinner: false,
    reverse: false,
} as SpinnerButtonProps

export const TabDefaultProps = {
    disabled: false,
    state: State.DEFAULT,
} as TabButtonProps
