export const storeDetailsCTALabel = 'View Store Details' // TODO: Need to take from AEM when available in global props
export const preferredStoreIndex = 3 // Tooltip index for preferred store
export const maxNearbyStoreToDisplay = 3 // MAx number of nearby store to display
export const availabilityBulletInStock = 'availability-bullet--in-stock' // Availability bullet in stock subtype
export const availabilityBulletLowStock = 'availability-bullet--low-stock' // Availability bullet low stock subtype
export const availabilityBulletOutOfStock = 'availability-bullet--out-of-stock' // Availability bullet out of stock subtype

export const inStockTag = 'in-stock'
export const lowStockTag = 'low-stock'

// from getLanguage() function
export const enLocale = 'en'
export const frLocale = 'fr'

const onlineOnlyOutOfStockFulfillmentBetweenMessageEN = 'It will be ready for pick-up between '
const onlineOnlyOutOfStockFulfillmentBetweenMessageFR = 'L`apos article sera prêt pour le ramassage entre '

export const onlineOnlyOutOfStockFulfillmentBetweenMessages = {
    [enLocale]: onlineOnlyOutOfStockFulfillmentBetweenMessageEN,
    [frLocale]: onlineOnlyOutOfStockFulfillmentBetweenMessageFR,
} as const

export const onlineOnlyBadgeLabel = 'ONLINEONLY'

// TODO: Need to take from AEM when available in global props
export const dynamicVariantLabel = 'Scent'
export const postalCodeFormat = /[^\dA-Z]/gi
export const checkNearbyStoresButtonDataTestId = 'check_nearby_stores'
export const inStockString = 'in-stock'
