import { ActionType, getType } from 'typesafe-actions'

import { setBrowseOnly } from '../actionCreators/browseOnly.actionCreators'
import * as actions from '../actionCreators'

type Action = ActionType<typeof actions>

export interface BrowseOnlyState {
    enable: boolean
}
export const initialState: BrowseOnlyState = {
    enable: false,
}

export const browseOnlyReducer = (state: BrowseOnlyState = initialState, action: Action): BrowseOnlyState => {
    if (action.type === getType(setBrowseOnly)) {
        return { ...state, enable: action.payload }
    }
    return state
}
