import React from 'react'
import { string, func } from 'prop-types'

import Button from '../../Button'
import { PREFIX } from '../../config'
import { LinkOptionsCardPropTypes } from './LinkOptionsCard.types'

/**
 * TriangleAccountCard component
 * @param {LinkOptionsCardPropTypes} props
 * @returns {JSX.Element} returns TriangleAccountCard
 */
const LinkOptionsCard: React.FC<LinkOptionsCardPropTypes> = ({
    cardId,
    cardImage,
    cardName,
    cardImageAltText,
    redirectToOption,
}): JSX.Element => {
    return (
        <Button type="tertiary" data-testid={cardId} onClick={redirectToOption}>
            <div className={`${PREFIX}-popup-link-loyalty-card`}>
                <div className={`${PREFIX}-popup-link-loyalty-card__image-wrap`}>
                    <img data-component-name="link-master-card" src={`${cardImage}`} alt={cardImageAltText} />
                </div>
                <div
                    className={`${PREFIX}-popup-link-loyalty-card__item-title`}
                    dangerouslySetInnerHTML={{ __html: cardName }}
                />
            </div>
        </Button>
    )
}
LinkOptionsCard.propTypes = {
    cardId: string.isRequired,
    cardImage: string.isRequired,
    cardName: string.isRequired,
    cardImageAltText: string.isRequired,
    redirectToOption: func,
}
export default LinkOptionsCard
