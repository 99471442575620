import { gigyaEventType, ScreenSetResponse } from './gigyaScreen.type'
import { gigyaErrorTranslationMapping } from './gigya.constants'
import { libUtils } from '@nl/lib'

const language = libUtils.getLanguage()
export const getGigyaLocaleErrors = (errorKey: string): string => {
    return window.gigya.i18n['gigya.services.accounts.plugins.screenSet.js'][`${language}`][`${errorKey}`]
}

export const gigyaErrorHandler = (
    errorCode: number,
    screenSetTranslations: Record<string, string>,
    errorName?: string,
): string => {
    let errorTranslation = errorName
    if (!errorTranslation) {
        errorTranslation = gigyaErrorTranslationMapping[errorCode?.toString()] || 'catch_all_error'
    }
    return screenSetTranslations[errorTranslation?.toUpperCase()] || getGigyaLocaleErrors(errorTranslation)
}

const getScreenSetData = (
    callbackFunc: (gigyaScreenSetResponse: ScreenSetResponse) => void,
    screenSet?: string,
): void => {
    const screenSetParams = {
        screenSetIDs: screenSet,
        include: 'translations',
        callback: callbackFunc,
        lang: libUtils.getLanguage(),
    }

    window.gigya.accounts.getScreenSets(screenSetParams)
}

export const onFormError = (event: gigyaEventType, errorMessage: string) => {
    // append error text to form error
    const errorWrapper: NodeListOf<Element> = document.querySelectorAll(
        `.${event.form} .gigya-composite-control-form-error`,
    )

    errorWrapper.forEach((item: Element) => {
        item.classList.add(`gigya-error-display-active`)
    })

    const errorElement: NodeListOf<Element> = document.querySelectorAll(
        '.gigya-login-form .gigya-composite-control-form-error .gigya-form-error-msg',
    )
    errorElement.forEach((item: Element) => {
        item.classList?.add('gigya-error-msg-active')
        item.innerHTML = errorMessage
    })
}

export const getTranslateErrorMsg = (
    callBack: (gigyaScreenSetResponse: ScreenSetResponse) => void,
    screenSet: string,
): void => {
    const getTranslatedData = (gigyaScreenSetResponse: ScreenSetResponse) => {
        const screenSetTranslations = gigyaScreenSetResponse
        callBack(screenSetTranslations)
    }
    getScreenSetData(getTranslatedData, screenSet)
}
