import React from 'react'
import Icon from '../Icon'
import {
    breadcrumbListIcon,
    linkClassName,
    listItemClassName,
    listPipeItemClassName,
    textClassName,
} from './Breadcrumb.constant'
import { getPageType } from '../../utils/getPageType'
import { pageTypes } from '../../utils/pageTypes'

type SharedProps = {
    link: string
    name: string
    nofollow?: boolean
    index?: number
    ariaLabel?: string
    breadCrumbLabel?: string
}

type OwnBreadcrumbLinkProps = {
    showPipeIcon: boolean
    isLastLinkAndNotInteractive: boolean
    pName: string
}

type BreadcrumbLinkProps = SharedProps & OwnBreadcrumbLinkProps

type InteractiveLinkProps = SharedProps

type NotInteractiveLinkProps = SharedProps & Pick<BreadcrumbLinkProps, 'pName'>

const refNoFollow = (nofollow = false) => (nofollow ? { rel: 'nofollow' } : {})

const InteractiveLink = ({ link, name, nofollow, ariaLabel, breadCrumbLabel }: InteractiveLinkProps) => {
    return (
        <a
            className={linkClassName}
            href={link}
            data-link-value={name}
            aria-label={ariaLabel}
            dap-wac-link="true"
            dap-wac-loc={breadCrumbLabel}
            dap-wac-value={name}
            {...refNoFollow(nofollow)}>
            {name}
        </a>
    )
}

const NotInteractiveLink = ({ link, name, pName, nofollow }: NotInteractiveLinkProps) => {
    return (
        <>
            <span className="sr-only" aria-current="page">
                {pName}
            </span>
            <span key={link} className={textClassName} aria-hidden={true} {...refNoFollow(nofollow)}>
                {name}
            </span>
        </>
    )
}

export const BreadcrumbLink = ({
    link,
    name,
    isLastLinkAndNotInteractive,
    pName,
    showPipeIcon,
    nofollow,
    index,
    ariaLabel,
    breadCrumbLabel,
}: BreadcrumbLinkProps): JSX.Element => {
    const isPDPPage = getPageType() === pageTypes.pdpPage
    const plpBreadcrumbClassName = getPageType() === pageTypes.plp ? `${breadcrumbListIcon}-sm` : ''

    /**
     * Function to return class name for pdp page
     * @returns {string} returns class name
     */
    const pdpBreadcrumbClassNames = (): string => {
        return isPDPPage
            ? showPipeIcon
                ? `${breadcrumbListIcon}-pdp ${index == 0 ? `${listItemClassName}-sticky` : ''}`
                : `${breadcrumbListIcon}-sm`
            : `${breadcrumbListIcon}-md`
    }

    return (
        <li
            key={link}
            className={`${
                showPipeIcon ? `${listPipeItemClassName}` : `${listItemClassName}`
            } ${pdpBreadcrumbClassNames()} ${plpBreadcrumbClassName}`}>
            <Icon type="ct-chevron-left" size="lg" />
            {isLastLinkAndNotInteractive ? (
                <NotInteractiveLink name={name} link={link} pName={pName ? pName : ariaLabel} nofollow={nofollow} />
            ) : (
                <InteractiveLink
                    link={link}
                    name={name}
                    nofollow={nofollow}
                    ariaLabel={ariaLabel}
                    breadCrumbLabel={breadCrumbLabel}
                />
            )}
        </li>
    )
}
