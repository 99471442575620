import React, { useState } from 'react'
import { shape, string, number } from 'prop-types'
import { balanceCheckerScreen, Button } from '@nl/lib'
import { BalanceCheckerScreenPropsType, BalanceCheckerWrapperProps } from './BalanceChecker.type'
import BalanceCheckerModalScreen from './BalanceCheckerModalScreen'
import { PREFIX } from '../../config'
import { userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'
import { useAppSelector } from '../../hooks/react-redux.hook'

const BalanceCheckerWrapper: React.FC<BalanceCheckerWrapperProps> = ({ ...props }) => {
    const { balanceChecker } = props
    const {
        ctMoneyBalanceCheckLabel,
        balanceCheckerImage,
        balanceCheckerImageAltText,
        balanceCheckerTitle,
        balanceCheckerDescription,
        cardNumberLabel,
        cardImage,
        cardImageAltText,
        checkBalanceCtaLabel,
        checkBalanceCtaAllyLabel,
        cancelCtaLabel,
        cancelCtaAllyLabel,
        ctMoneyLabel,
        linkCardText,
        ctaLabelGuestUser,
        ctaUrlGuestUser,
        ctaLinkTargetGuestUser,
        ctaAllyLabelGuestUser,
        ctaUrlLoggedInUser,
        ctaLinkTargetLoggedInUser,
        ctaAllyLabelLoggedInUser,
        checkBalanceOnAnotherCardCtaLabel,
        checkBalanceOnAnotherCardCtaAllyLabel,
        multipleAttemptFailureTitle,
        multipleAttemptFailureDescription,
        tryAgainCtaLabel,
        tryAgainCtaAllyLabel,
        emptyCardErrorMsg,
        invalidCardErrorMsg,
        partialCardErrorMsg,
        cardLinkedErrorMsg,
        ineligibleForBalanceCheckTitle,
        ineligibleForBalanceCheckDescription,
        cardMergeProcessTitle,
        cardMergeProcessDescription,
    } = balanceChecker

    const modalScreenProps = {
        balanceCheckerImage,
        balanceCheckerImageAltText,
        balanceCheckerTitle,
        balanceCheckerDescription,
        cardNumberLabel,
        cardImage,
        cardImageAltText,
        checkBalanceCtaLabel,
        checkBalanceCtaAllyLabel,
        cancelCtaLabel,
        cancelCtaAllyLabel,
        ctMoneyLabel,
        linkCardText,
        ctaLabelGuestUser,
        ctaUrlGuestUser,
        ctaLinkTargetGuestUser,
        ctaAllyLabelGuestUser,
        ctaUrlLoggedInUser,
        ctaLinkTargetLoggedInUser,
        ctaAllyLabelLoggedInUser,
        checkBalanceOnAnotherCardCtaLabel,
        checkBalanceOnAnotherCardCtaAllyLabel,
        multipleAttemptFailureTitle,
        multipleAttemptFailureDescription,
        tryAgainCtaLabel,
        tryAgainCtaAllyLabel,
        emptyCardErrorMsg,
        invalidCardErrorMsg,
        partialCardErrorMsg,
        cardLinkedErrorMsg,
        ineligibleForBalanceCheckTitle,
        ineligibleForBalanceCheckDescription,
        cardMergeProcessTitle,
        cardMergeProcessDescription,
    } as BalanceCheckerScreenPropsType
    const { balanceScreen } = balanceCheckerScreen as Record<string, string>
    const [openPopup, setOpenPopup] = useState(false)
    const userProfileData = useAppSelector(userProfileDataSelector)
    const authenticatedUser = userProfileData ? Boolean(Object.keys(userProfileData).length) : false
    const isLoyaltyLinked = userProfileData?.loyalty?.cardNumber

    /**
     * Handler to close modal
     *
     * @returns {void} - returns nothing
     */
    const closeHandler = (): void => {
        setOpenPopup(false)
    }

    return (
        <>
            {(!authenticatedUser || (authenticatedUser && !isLoyaltyLinked)) && (
                <div className={`${PREFIX}-balance-checker-footer-link`}>
                    <Button
                        type="tertiary_reverse"
                        ariaLabel={ctMoneyBalanceCheckLabel}
                        onClick={() => setOpenPopup(true)}
                        onKeyDown={() => false}>
                        {ctMoneyBalanceCheckLabel}
                    </Button>
                </div>
            )}
            <BalanceCheckerModalScreen
                screenId={balanceScreen}
                openPopup={openPopup}
                closeHandler={closeHandler}
                data={modalScreenProps}
            />
        </>
    )
}

BalanceCheckerWrapper.propTypes = {
    balanceChecker: shape({
        ctMoneyBalanceCheckLabel: string.isRequired,
        balanceCheckerImage: string.isRequired,
        balanceCheckerImageAltText: string.isRequired,
        balanceCheckerTitle: string.isRequired,
        balanceCheckerDescription: string.isRequired,
        cardNumberLabel: string.isRequired,
        cardImage: string.isRequired,
        cardImageAltText: string.isRequired,
        checkBalanceCtaLabel: string.isRequired,
        checkBalanceCtaAllyLabel: string.isRequired,
        cancelCtaLabel: string.isRequired,
        cancelCtaAllyLabel: string.isRequired,
        ctMoneyLabel: string.isRequired,
        linkCardText: string.isRequired,
        ctaLabelGuestUser: string.isRequired,
        ctaUrlGuestUser: string.isRequired,
        ctaLinkTargetGuestUser: string.isRequired,
        ctaAllyLabelGuestUser: string.isRequired,
        ctaUrlLoggedInUser: string.isRequired,
        ctaLinkTargetLoggedInUser: string.isRequired,
        ctaAllyLabelLoggedInUser: string.isRequired,
        checkBalanceOnAnotherCardCtaLabel: string.isRequired,
        checkBalanceOnAnotherCardCtaAllyLabel: string.isRequired,
        multipleAttemptFailureTitle: string.isRequired,
        multipleAttemptFailureDescription: string.isRequired,
        tryAgainCtaLabel: string.isRequired,
        tryAgainCtaAllyLabel: string.isRequired,
        emptyCardErrorMsg: string.isRequired,
        invalidCardErrorMsg: string.isRequired,
        partialCardErrorMsg: string.isRequired,
        cardLinkedErrorMsg: string.isRequired,
        ineligibleForBalanceCheckTitle: string.isRequired,
        ineligibleForBalanceCheckDescription: string.isRequired,
        cardMergeProcessTitle: string.isRequired,
        cardMergeProcessDescription: string.isRequired,
        numberOfFailedAttempts: number.isRequired,
        tryAgainTitle: string.isRequired,
        tryAgainDescription: string.isRequired,
    }).isRequired,
}

export default BalanceCheckerWrapper
