import { ActionType, getType } from 'typesafe-actions'
import { CartPageAnalyticsInfo } from '../../analytics/components/Cart/cartPage.type'
import { CartUpdateType } from '../../components/ShoppingCart/ShoppingCart.type'
import { HttpRequestState } from '../../httpClient/client.type'

import * as actions from '../actionCreators/wishlist.actionCreators'
import { wishlistSuccess, wishListError, WishlistResponse } from '../models/wishlist.interface'
import { EmptyActionType } from '../models/globalData.interface'

type Action = ActionType<typeof actions> | EmptyActionType

export interface wishlistState {
    wishlistData: wishlistSuccess
    wishlistItems: WishlistResponse
    // ajax calls status info
    xhrInfo?: {
        getCartInfo?: HttpRequestState
        getWishListInfo?: HttpRequestState
    }
    analyticsInfo: CartPageAnalyticsInfo
    wishlistRemoveSuccess: boolean
    mergeWishlistSuccess?: boolean
    wishlistLoadSuccess: boolean
}

export const initialState: wishlistState = {
    wishlistData: { active: false } as wishlistSuccess,
    wishlistItems: {} as WishlistResponse,
    // ajax calls status info
    xhrInfo: {
        getCartInfo: HttpRequestState.notStarted,
        getWishListInfo: HttpRequestState.notStarted,
    },
    analyticsInfo: {
        isPageLoadSent: false,
        pageName: 'Wishlist',
        updateType: CartUpdateType.NO_UPDATE,
    },
    wishlistRemoveSuccess: false,
    wishlistLoadSuccess: false,
    mergeWishlistSuccess: false,
}

export interface successErrorState {
    successErrorData: wishListError
}

export const successErrorInitialState: successErrorState = {
    successErrorData: { success: true } as wishListError,
}

// eslint-disable-next-line complexity
export const wishlistReducer = (state: wishlistState = initialState, action: Action): wishlistState => {
    switch (action.type) {
        case getType(actions.addProductToWishListSuccessAction):
            return { ...state, wishlistData: action.payload, wishlistRemoveSuccess: false }
        case getType(actions.removeProductFromWishListActionSuccess):
            return { ...state, wishlistData: action.payload, wishlistRemoveSuccess: true }
        case getType(actions.resetWishlistActive):
            return { ...state, wishlistData: { ...state.wishlistData, active: false } }
        case getType(actions.wishlistSuccessAction):
            // just a hack to avoid big flow
            const xhrInfo = action.payload?.xhrInfo
            delete action.payload?.xhrInfo
            return { ...state, wishlistItems: action.payload, xhrInfo, wishlistLoadSuccess: true }
        case getType(actions.wishlistProductCodesSuccessAction):
        case getType(actions.wishlistDetailsSuccessAction):
            return { ...state, wishlistItems: action.payload, wishlistLoadSuccess: true }
        case getType(actions.wishlistUpdateAnalyticsInfo): {
            return {
                ...state,
                analyticsInfo: Object.assign({}, { ...state.analyticsInfo }, action.payload),
            }
        }
        case getType(actions.removeWishlistSuccessAction):
            return { ...state, wishlistItems: action.payload, wishlistRemoveSuccess: true }
        case getType(actions.resetSuccessToastAction):
            return { ...state, wishlistRemoveSuccess: false }
        case getType(actions.setMergeWishlistAction):
            return { ...state, mergeWishlistSuccess: action.payload }
        default:
            return state
    }
}

// add and remove errors

export const successErrorReducer = (
    state: successErrorState = successErrorInitialState,
    action: Action,
): successErrorState => {
    switch (action.type) {
        case getType(actions.wishListErrorAction):
            return { ...state, successErrorData: action.payload }
        case getType(actions.changeSuccessValue):
            return { ...state, successErrorData: action.payload }
        default:
            return state
    }
}
