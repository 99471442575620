import React, { useCallback, useEffect, useRef } from 'react'

import appCacheService from '../../utils/appCacheService'
import {
    getCartItemsData,
    getMiniCartItemsData,
    getCartItemsForAuthForMerge,
    getMiniCartItemsForAuthForMerge,
    mergeAuthCartWithGuest,
    getCheckoutCartItemsForAuthForMerge,
} from '../../redux/actions'
import { CheckoutService } from '../../services/checkoutService'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { useAuth } from '../../hooks'
import { updateCartDataSuccessAction } from '../../redux/actionCreators'
import { CartInitProps, FilteredCartData } from '../../redux/models/cart.interface'
import { pageTypes } from '../../config'
import getPageType from '../../utils/getPageType'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { isLabourSharedCartSelector, storeSharedCartSelector } from '../../redux/selectors/sharedCart.selectors'
import {
    isAuthFlowExecutedSelector,
    signOutSuccessSelector,
    userProfileDataSelector,
} from '../../redux/selectors/userProfile.selectors'
import { cartItemsDataForAuthSelector } from '../../redux/selectors/cart.selectors'
import { selectedPreferredStoreIdSelector } from '../../redux/selectors/storeDetails.selectors'
import { isGigyaLoadedSelector } from '../../redux/selectors/gigyaScreenSetData.selectors'
import { isPageForMiniCart, isCheckoutPage, isShoppingCartPage } from '../../utils/checkPageType'
import { isNeedToClearTenders } from '../Checkout/Checkout.helper'
import { useATCLimitItemsFailureToast } from './hooks'
import { IFeatureFlag } from '../../redux/models/commonContent.interface'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'

const CartInit: React.FC<CartInitProps> = ({ ...props }) => {
    const isAuthFlowExecuted = useAppSelector(isAuthFlowExecutedSelector)
    const isGigyaLoaded = useAppSelector(isGigyaLoadedSelector)
    const userProfileData = useAppSelector(userProfileDataSelector)
    const signOutSuccess = useAppSelector(signOutSuccessSelector)
    const selectedPreferredStoreId = useAppSelector(selectedPreferredStoreIdSelector)
    const cartItemsDataForAuth = useAppSelector(cartItemsDataForAuthSelector)
    const { commonContentAvailable } = useAppSelector(commonContentSelector)
    const storeSharedCart = useAppSelector(storeSharedCartSelector)
    const isLabourSharedCartFromStore = useAppSelector(isLabourSharedCartSelector)
    const dispatch = useAppDispatch()
    const currentPageType = getPageType()
    const { isStoreSharedCart } = storeSharedCart || {}
    const authenticatedUser = userProfileData ? Boolean(Object.keys(userProfileData).length) : false

    const { featureFlag = {} as IFeatureFlag, wishlist: wishlistContent = {} } = commonContentAvailable

    const { enableCacheCartToLocalStorageForAnonymous } = featureFlag

    useEffect(() => {
        appCacheService.ttlForCartAndWishlistCache.set(wishlistContent.ttlForCartAndWishlistCache)
    }, [wishlistContent])

    const commonContentIsFetched = checkDataLength(commonContentAvailable)

    const runUnAuthenticatedOnlyOnce = useRef(0)
    const runAuthenticatedOnlyOnce = useRef(0)
    /**
     * Conditions for auth call to happen
     */
    const { authCondition } = useAuth({
        runOnce: runAuthenticatedOnlyOnce,
    })

    /**
     * function to remove mergeCartFlag from localstorage
     */
    const removeCartMergeFlag = useCallback(() => {
        currentPageType.toLowerCase() !== pageTypes.signIn.toLowerCase() && appCacheService.cartMergeFlag.remove()
    }, [currentPageType])

    /**
     * Below function checks if cart-guid is available or not
     */
    const isCartIdAvailable = useCallback(() => {
        if (isStoreSharedCart || isLabourSharedCartFromStore) {
            return false
        } else {
            return !!appCacheService.getCartGuid() || window.location.search.includes('cart-guid')
        }
    }, [isStoreSharedCart, isLabourSharedCartFromStore])

    /**
     * Conditions for Guest call.
     */
    const unAuthCondition = useCallback(() => {
        return isCartIdAvailable() && runUnAuthenticatedOnlyOnce.current === 0 && !checkDataLength(userProfileData)
    }, [userProfileData, isCartIdAvailable])

    /**
     * Conditions for Cashing cart to LocalStorage
     */
    const isEnableCashingCartToLocalStorage =
        currentPageType !== pageTypes.checkout &&
        currentPageType !== pageTypes.cart &&
        enableCacheCartToLocalStorageForAnonymous

    // Clear cart on sign out.
    useEffect(() => {
        if (signOutSuccess) {
            runAuthenticatedOnlyOnce.current = 0
            dispatch(updateCartDataSuccessAction({} as FilteredCartData))
            appCacheService.removeCartGuid()
            appCacheService.cartData.delete()
            appCacheService.removeSflGuid()
            appCacheService.miniCartData.remove()
        }
    }, [signOutSuccess, dispatch])

    /**
     * Function for getting cart data depending on the page type
     */
    const callGetCartItemsData = useCallback(
        (guid: string, option: string, showSpinner = false, enableCache = false) => {
            isPageForMiniCart()
                ? dispatch(getMiniCartItemsData(guid, option, showSpinner, enableCache))
                : !isNeedToClearTenders(guid, true)
                ? dispatch(getCartItemsData(guid, option, false, false, false, false, true, true))
                : CheckoutService.clearTenders(guid, true)
                      .then(() => appCacheService.removeTendersStorageData(guid))
                      .then(() => dispatch(getCartItemsData(guid, option, false, false, false, false, true, true)))
                      .catch(error => console.error('clearTenders request error ', error))
        },
        [dispatch],
    )

    const getCartForAuthForMerge = useCallback(() => {
        if (isCheckoutPage()) {
            dispatch(getCheckoutCartItemsForAuthForMerge(''))
        } else if (isShoppingCartPage()) {
            dispatch(getCartItemsForAuthForMerge(''))
        } else {
            dispatch(getMiniCartItemsForAuthForMerge(''))
        }
    }, [dispatch])

    const authCallGetCartItemsData = useCallback(
        (currentGuid: string): void => {
            if (!!currentGuid) {
                if (props.cartMergeFlag || !!appCacheService.cartMergeFlag.get()) {
                    getCartForAuthForMerge()
                    removeCartMergeFlag()
                } else {
                    callGetCartItemsData(currentGuid, '', false, true)
                }
            } else {
                callGetCartItemsData('', '', false, true)
                appCacheService.cartMergeFlag.remove()
            }
            runAuthenticatedOnlyOnce.current = 1
        },
        [getCartForAuthForMerge, removeCartMergeFlag, callGetCartItemsData, props],
    )

    useEffect(() => {
        /**
         * Run the code once the profile call happens i.e. isAuthenticatedFlow becomes true. whether success or failure.
         * isAuthenticatedFlow is true whether user logged or not.
         */
        if (
            !!(selectedPreferredStoreId || appCacheService.preferredStoreId.get()) &&
            (isAuthFlowExecuted || (!isGigyaLoaded && !appCacheService.gigyaJWTToken.get())) &&
            commonContentIsFetched
        ) {
            // If user logged in make a authenticated call.
            const currentGuid = appCacheService.getCartGuid()
            if (authCondition()) {
                authCallGetCartItemsData(currentGuid)
            } else if (unAuthCondition()) {
                callGetCartItemsData(currentGuid, '', false, isEnableCashingCartToLocalStorage)
                runUnAuthenticatedOnlyOnce.current = 1
            }
        }
    }, [
        dispatch,
        isAuthFlowExecuted,
        isGigyaLoaded,
        selectedPreferredStoreId,
        authCondition,
        unAuthCondition,
        isEnableCashingCartToLocalStorage,
        enableCacheCartToLocalStorageForAnonymous,
        commonContentIsFetched,
        callGetCartItemsData,
        authCallGetCartItemsData,
        getCartForAuthForMerge,
    ])

    const isMergeAuthCartWithGuestOnCheckoutPage = useCallback((): boolean => {
        return (
            currentPageType === pageTypes.checkout &&
            checkDataLength(cartItemsDataForAuth) &&
            isStoreSharedCart &&
            authenticatedUser
        )
    }, [currentPageType, cartItemsDataForAuth, isStoreSharedCart, authenticatedUser])

    useEffect(() => {
        const anonymousGuid = appCacheService.getCartGuid()
        if (
            (checkDataLength(cartItemsDataForAuth) &&
                !!anonymousGuid &&
                currentPageType !== pageTypes.checkout &&
                currentPageType.toLowerCase() !== pageTypes.signIn.toLowerCase() &&
                anonymousGuid !== cartItemsDataForAuth.cart.cartId) ||
            isMergeAuthCartWithGuestOnCheckoutPage()
        ) {
            dispatch(mergeAuthCartWithGuest(anonymousGuid, cartItemsDataForAuth.cart.cartId, undefined, true))
        }
    }, [cartItemsDataForAuth, dispatch, currentPageType, isMergeAuthCartWithGuestOnCheckoutPage])

    useATCLimitItemsFailureToast()

    return null
}

export default CartInit
export { CartInit }
