import { PREFIX } from '../../config'

/**
 * Calculates Header Height(Primary Nav + Secondary Nav)
 * @example
 * @returns {Number} Returns the height of header.
 */

export const calculateHeaderHeight = (): number => {
    const primaryNavHeight = document.getElementsByClassName(`${PREFIX}-primary-navigation-bar`)[0]?.clientHeight
    const secondaryNav = document.getElementsByClassName(`${PREFIX}-secondary-navigation-bar`)
    const notificationBanner = document.getElementsByClassName(`${PREFIX}-notification-banner__body`)
    const notificationBannerHeight =
        notificationBanner && notificationBanner.length ? notificationBanner[0].clientHeight : 0
    let secondaryNavHeight = 0
    let secondaryNavPos = 0

    if (secondaryNav && secondaryNav.length) {
        secondaryNavHeight = secondaryNav[0].clientHeight
        secondaryNavPos = secondaryNav[0].getBoundingClientRect().top
    }
    const headerHeight = secondaryNavPos >= primaryNavHeight ? secondaryNavHeight + primaryNavHeight : primaryNavHeight
    return headerHeight + notificationBannerHeight
}
