import { ActionType, getType } from 'typesafe-actions'

import { AddPaymentCardType } from '../models/profile.payment.interface'
import * as actions from '../actionCreators/profilePaymentCard.actionCreators'
import { ErrorResponse } from '../models/loyaltyRegisterForm.interface'
import { EmptyActionType } from '../models/globalData.interface'

type Action = ActionType<typeof actions> | EmptyActionType

export interface profilePaymentCardState {
    hppToken: string
    validUntil: number | null
    addCreditCardStatus: AddPaymentCardType | null
    addCreditCardError: ErrorResponse | null
}

export const initialState: profilePaymentCardState = {
    hppToken: '',
    validUntil: null,
    addCreditCardStatus: {} as unknown as AddPaymentCardType,
    addCreditCardError: {} as unknown as ErrorResponse,
}

export const ProfilePaymentCardReducer = (
    state: profilePaymentCardState = initialState,
    action: Action,
): profilePaymentCardState => {
    switch (action.type) {
        case getType(actions.addHppToken): {
            return { ...state, ...action.payload }
        }
        case getType(actions.addCreditCardAction): {
            return { ...state, addCreditCardStatus: action.payload }
        }
        case getType(actions.addCreditCardErrorAction): {
            return { ...state, addCreditCardError: action.payload }
        }
        case getType(actions.resetAddCreditCardAction): {
            return { ...state, addCreditCardStatus: null, addCreditCardError: null }
        }
        default:
            return state
    }
}
