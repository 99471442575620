const getPageType = (): string => {
    return document.getElementsByTagName('body')[0]?.getAttribute('data-pagetype') || ''
}

// Use this to setPageType for UTs
export const setPageTypeAttribute = (pageType: string): void => {
    return document.body.setAttribute('data-pagetype', pageType)
}

export default getPageType
