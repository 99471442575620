import { useState, useEffect, useRef } from 'react'

import { BREAKPOINTS } from '../config'
import { useGlobalResizeEvent } from '@nl/lib'

const checkForMobileLayout = (): boolean => window.innerWidth <= BREAKPOINTS.mobileMaxWidth

export const useMobileLayoutState = () => {
    const [isMobileLayout, setIsMobileLayout] = useState(checkForMobileLayout())

    const handleResize = (): void => {
        setIsMobileLayout(checkForMobileLayout())
    }

    useGlobalResizeEvent(handleResize)

    return [isMobileLayout, setIsMobileLayout]
}

/**
 * A custom hook that observes intersection changes on single or multiple DOM elements.
 * @param {function(IntersectionObserverEntry): void} callback - A function that is called whenever an observed element's visibility changes.
 * @param {IntersectionObserverInit} options - Options to configure the IntersectionObserver (root, rootMargin, threshold).
 * @returns {function(Element | Element[]): void} A function to set the target element(s) to be observed.
 */
export const useIntersect = (
    callback: (entry?: IntersectionObserverEntry) => void,
    options: IntersectionObserverInit,
): ((element: Element | Element[]) => void) => {
    const [nodes, setNodes] = useState<Element | Element[] | null>(null)
    const observer = useRef<IntersectionObserver | null>(null)

    useEffect(() => {
        if (observer.current) {
            observer.current.disconnect()
        }

        observer.current = new window.IntersectionObserver(
            entries => entries.forEach(entry => callback(entry)),
            options,
        )

        const { current: currentObserver } = observer

        if (nodes) {
            if (Array.isArray(nodes)) {
                nodes.forEach(node => node && currentObserver.observe(node))
            } else {
                currentObserver.observe(nodes)
            }
        }

        return () => currentObserver.disconnect()
    }, [nodes, callback, options])

    return setNodes
}
