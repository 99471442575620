import React from 'react'

import { ProductTitleProps } from './ProductTitle.type'

/**
 *
 * @param props
 */
export const ProductTitle: React.FC<ProductTitleProps> = props => {
    const {
        productTitleInfo: { title, pcode, styleCodes },
    } = props

    return (
        <div data-testid="product-info-wrapper" className="product-info-wrapper">
            <h2 className="product-info-wrapper__title">{title}</h2>
            {!!styleCodes && (
                <div className="product-info-wrapper__sku-list">
                    {styleCodes.map((styleCode, index) => (
                        <span className="product-info-wrapper__sku-item" key={styleCode}>
                            {`${index ? ', ' : ''}#${styleCode}`}
                        </span>
                    ))}
                </div>
            )}
            <div
                data-testid="product-title-rating-container"
                className="product-ratings-btn-container"
                data-bv-show="rating_summary"
                data-bv-seo="false"
                data-bv-product-id={pcode}
            />
        </div>
    )
}
