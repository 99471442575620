import { RootState } from '../reducers'
import { HideVehicleSelectorBannerState } from '../reducers/hideVehicleSelectorBanner.reducer'
export const hideVehicleSelectorBannerStateSelector = (state: RootState): HideVehicleSelectorBannerState =>
    state.hideVehicleSelectorBannerState
export const isSuggestiveDropdownStateSelector = (state: RootState): boolean =>
    state.hideVehicleSelectorBannerState.isSuggestiveDropdownState
export const hideVehicleSelectorBannerStateDataSelector = (state: RootState): boolean =>
    state.hideVehicleSelectorBannerState.hideVehicleSelectorBannerState
export const isSuggestiveDropdownAPIFetchedSelector = (state: RootState): boolean =>
    state.hideVehicleSelectorBannerState.isSuggestiveDropdownAPIFetched
export const suggestiveAttributeSelector = (state: RootState): string =>
    state.hideVehicleSelectorBannerState.suggestiveAttribute
