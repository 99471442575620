import { useCallback, useEffect, useMemo } from 'react'

import { checkDataLength, replaceStrWithDynamicVal } from '@nl/lib'
import { cartLimitItemsFailureSelector } from '../../../redux/selectors/cart.selectors'
import { commonContentSelector } from '../../../redux/selectors/commonContent.selectors'
import { dispatchToast } from '../../ToastMessage/ToastMessage.helper'
import { ToastComponentNames } from '../../../redux/models/toastMessage.interface'
import toastService from '../../../services/toastService/toast.service'
import { clearCartLimitItemsFailure } from '../../../redux/actions'
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux.hook'

/**
 * Hook to handle cart limit items errors and showing toaster with message.
 * @return {void}.
 */
export const useATCLimitItemsFailureToast = () => {
    const dispatch = useAppDispatch()
    const cartLimitItemsFailure = useAppSelector(cartLimitItemsFailureSelector)
    const { commonContentAvailable } = useAppSelector(commonContentSelector)
    const commonContentIsFetched = checkDataLength(commonContentAvailable)
    const isCartLimitItemFailureExist = checkDataLength(cartLimitItemsFailure)
    const cartItemLimitValue = cartLimitItemsFailure?.data?.cartItemLimit
    const error00177 = commonContentAvailable.errors?.error00177
    const errorToastCloseLabel = commonContentAvailable.general?.closeLabel
    const toastErrorMsg = replaceStrWithDynamicVal(error00177, cartItemLimitValue ?? 0)

    const showToast = useCallback(() => {
        const toastProps = {
            options: {
                toastCloseLabel: errorToastCloseLabel,
                toastErrorMessage: toastErrorMsg,
                toastErrorIcon: 'ct-notification-caution',
            },
            success: false,
            enableTimer: true,
        }
        dispatchToast(true, toastProps, ToastComponentNames.CART_ITEMS_ADD_TO_CART_LIMIT_FAILURE, dispatch)
    }, [dispatch, toastErrorMsg, errorToastCloseLabel])

    const successToastCallback = useMemo(
        () => ({
            toastCloseFunction: () => dispatch(clearCartLimitItemsFailure()),
            toastUnMountFunction: () => dispatch(clearCartLimitItemsFailure()),
        }),
        [dispatch],
    )

    useEffect(() => {
        if (isCartLimitItemFailureExist && commonContentIsFetched) {
            showToast()
        }
    }, [isCartLimitItemFailureExist, commonContentIsFetched, showToast])

    useEffect(() => {
        toastService.setCallback({
            [ToastComponentNames.CART_ITEMS_ADD_TO_CART_LIMIT_FAILURE]: successToastCallback,
        })
    }, [successToastCallback])
}
