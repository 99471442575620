import React from 'react'
import { IconButtonProps } from './Button.types'
import { Size } from '../../types'
import { IconDefaultProps, CommonButtonClass } from './Button.constants'
import Icon from '../Icon'

/**
 * Buttons that contains an icon
 * @param {IconButtonProps} props Button props
 * @param {React.ForwardedRef<HTMLButtonElement>} Button with ref
 * @returns {JSX.Element} returns Button icon component
 */
const IconButton: React.FC<IconButtonProps> = React.forwardRef<
    HTMLButtonElement,
    React.PropsWithChildren<IconButtonProps>
>(({ ...props }, ref): JSX.Element => {
    const { variant, icon, type, disabled, customClass, color, onClick, id, onKeyDown, a11y, size, qm } = props

    const variantClass = `${CommonButtonClass}--${variant} ${CommonButtonClass}--${variant}-${color} `
    const customClassName = customClass ? ` ${customClass}` : ''
    const sizeClass = size === Size.MINI ? ` ${CommonButtonClass}--${size}` : ''
    const qmData = qm ? { [`data-qm-${qm?.type}`]: qm?.value } : {}

    /**
     * Keyboard event on key down of any keys within button
     * @param {React.KeyboardEvent<HTMLButtonElement>} e - e for event for keydown
     * @returns {void} if there is a keydown prop, call function, otherwise null
     */
    const keyDownHandler = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
        if (onKeyDown) onKeyDown(e)
        else null
    }

    return (
        <button
            id={id}
            className={`${CommonButtonClass}-icon ${variantClass}${customClassName}${sizeClass}`}
            ref={ref}
            disabled={disabled}
            type={type}
            onClick={onClick}
            onKeyDown={e => keyDownHandler(e)}
            aria-disabled={a11y?.disabled}
            aria-label={a11y?.label ? a11y?.label : ''}
            aria-labelledby={a11y?.label ? a11y?.label : ''}
            aria-expanded={a11y?.expanded}
            aria-controls={a11y?.controls}
            {...qmData}
            {...props}>
            <Icon size={icon?.size} type={icon?.type} />
        </button>
    )
})

IconButton.displayName = 'IconButton'

IconButton.defaultProps = IconDefaultProps

export default IconButton
