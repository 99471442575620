import React from 'react'
import PropTypes from 'prop-types'

import { ProductListingAdProps } from './Recommendations.type'
import { PREFIX } from '../config'
import CarouselSwiper from '../CarouselSwiper'
import { carouselSettings } from '../Carousel/Carousel.constant'

/**
 * ProductListingAd (PLP carousel) component
 * @param {RecommendationsProps} props - carouselList , defaultSlidePerView
 * @returns {JSX.Element} returns CarouselSwiper component for pla carousel
 */
const ProductListingAd: React.FC<ProductListingAdProps> = (props: ProductListingAdProps): JSX.Element => {
    const { carouselList, breakpointSettings, infiniteScrolling, centerInsufficientSlides, dynamicBullet, sliderRef } =
        props

    return (
        <div className={`${PREFIX}-product__list-view`}>
            <CarouselSwiper
                carouselList={carouselList}
                breakpointSettings={breakpointSettings}
                centerInsufficientSlides={centerInsufficientSlides}
                spacing={carouselSettings.cardSpacing}
                dynamicBullet={dynamicBullet}
                infiniteScrolling={infiniteScrolling}
                sliderRef={sliderRef}
            />
        </div>
    )
}

ProductListingAd.propTypes = {
    carouselList: PropTypes.array,
}

export default ProductListingAd
