import React, { useRef, useState, useEffect, useCallback } from 'react'

import { PREFIX } from '../config'
import { ITabs } from './Tabs.type'
import { Role, Events } from '../../types'
import TabV2 from './TabV2'
import { stringKeyCodes, useOnClickOutside } from '../../utils'
import {
    arrowActionIdentifier,
    handleArrowsAccessibility,
    handleClickOrEnterAccessibility,
    outsideActionHandler,
} from '../../helpers/keyboardAccessibility.helper'
import { useOnKeyDownOutside } from '../../utils/useOnKeyDownOutside'

/**
 * TabsV2 component (version 2)
 * @return {JSX.Element} returns TabsV2 component
 */
const TabsV2: React.FC<ITabs> = ({ ...props }): JSX.Element => {
    const { selected, prerenderMode, id, children, tabCallback } = props
    const componentClass = `${PREFIX}-tabs`
    const labelList = children.map(child => child?.props?.label)
    const initActiveTab = selected || labelList[0]
    const [activeTab, setActiveTab] = useState(initActiveTab)
    const tabsRef = useRef(null)
    const outsideActionCallback = useCallback(() => outsideActionHandler(tabsRef.current), [])

    // useEffect to set initial active tab
    useEffect(() => {
        setActiveTab(initActiveTab)
    }, [initActiveTab])

    // Handling edge cases with respect to Tabs accessibility
    // Already in useEffect state as a hook
    useOnClickOutside(tabsRef, outsideActionCallback)
    useOnKeyDownOutside(tabsRef, outsideActionCallback)

    /**
     * This function handles click or enter event for tab both accessibility and actual event
     * @param {React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>} e
     * @param {number} index
     * @param {string} label
     * @returns {void}
     */
    const handleSwitchTab = (
        e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
        index: number,
        label: string,
    ): void => {
        if (e.type === Events.CLICK) {
            handleClickOrEnterAccessibility(e, index, tabsRef.current)
            handleSwitchEvent(label)
            tabCallback(label)
        } else if (e.type === Events.KEYDOWN) {
            handleKeyDown(e as React.KeyboardEvent<HTMLDivElement>, index, label)
        }
    }

    /**
     * This function handles keydown event for tab
     * @param {React.KeyboardEvent<HTMLDivElement>} event
     * @param {number} index
     * @param {string} label
     * @returns {void}
     */
    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, index: number, label: string): void => {
        const { key } = e
        const { rightArrow, leftArrow, enter, space } = stringKeyCodes
        const { NEXT, PREVIOUS } = arrowActionIdentifier
        switch (key) {
            case rightArrow:
                handleArrowsAccessibility(e, NEXT, index, tabsRef.current)
                break
            case leftArrow:
                handleArrowsAccessibility(e, PREVIOUS, index, tabsRef.current)
                break
            case enter:
            case space:
                handleSwitchTab(e, index, label)
                break
        }
    }

    /**
     * Function to call callback function to set active tab
     * @param {string} selectedTab - selected tab label
     * @returns {void}
     */
    const handleSwitchEvent = (selectedTab: string): void => setActiveTab(selectedTab)

    /**
     * Function to return Tab List (aka tab header)
     * @returns {JSX.Element[]} returns Overlay component
     */
    const renderTabList = (): JSX.Element[] => {
        return labelList.map(
            (label: string, i: number): JSX.Element => (
                <TabV2
                    key={id ? `${Role.TAB}-${id}-${i}` : `${Role.TAB}label-${i}`}
                    active={Boolean(label === activeTab)}
                    onSwitchTab={handleSwitchTab}
                    label={label}
                    index={i}>
                    {label}
                </TabV2>
            ),
        )
    }

    return (
        <section className={`${componentClass}`} id={id}>
            <div
                ref={tabsRef}
                className={`${PREFIX}-tab__list`}
                aria-labelledby={`${Role.TABLIST}-${id ? id : 1}`}
                role={Role.TABLIST}>
                {renderTabList()}
            </div>
            {children.map((child, i: number) =>
                !prerenderMode && child.props.label !== activeTab ? null : (
                    <div
                        className={`${PREFIX}-tab__content`}
                        key={id ? `${Role.TABPANEL}-${id}-${i}` : `${Role.TABPANEL}label-${i}`}
                        id={`${Role.TABPANEL}-${i}`}
                        aria-labelledby={`tab-${i}`}
                        role={Role.TABPANEL}>
                        {child.props.children}
                    </div>
                ),
            )}
        </section>
    )
}

export default TabsV2
