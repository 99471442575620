import { YesNoNotApplicable } from '../analytics/providers/provider.type'
import {
    FilteredCartData,
    eEntryType,
    CartOrderEntries,
    CartOrderDeliveryModes,
    FreeShippingProgress,
    CartData,
    CheckoutOrderEntries,
    SthEntries,
    Package,
    CartItemsData,
    DeliveryAddressDTO,
} from '../redux/models/cart.interface'
import { isServiceEligible } from './automotiveProducts'
import { balloonEntryType, CONFIGURABLE_BUNDLE_PACKAGE } from '../globalConstants'
import { isArrayEmpty, isArrayNotEmpty } from '@nl/lib'
import { StoreWithAvailability } from '../redux/models/storeDetails.interface'

/**
 * generic function to reduce complexity by and operator
 * @param {boolean[]}params
 * @returns {boolean}
 */
const areAllParamsValid = (...params: unknown[]): boolean => {
    const validationResult = params.reduce((paramA, paramB) => paramA && paramB)
    return Boolean(validationResult)
}

/**
 * generic function to reduce complexity by or operator
 * @param {boolean[]}params
 * @returns {boolean | undefined}
 */
const isAtleastOneParamValid = (...params: unknown[]): unknown => {
    return params.reduce((paramA, paramB) => paramA || paramB)
}

/**
 * Function checks for existing packages in a cart
 * @param { CartData } cartData - cart data
 * @returns { boolean } true if at least one package exists
 */
export const isAtLeastOnePackageExists = (cartData: CartData): boolean => {
    return !!cartData.entryGroups?.some(entryGroup => entryGroup.groupType === CONFIGURABLE_BUNDLE_PACKAGE)
}

/**
 * Check the fulfillment details of a cart or checkout item and return the delivery mode if available or the entire fulfillment object if the delivery mode is not specified.
 * @param {CartOrderEntries | CheckoutOrderEntries} item
 * @returns {boolean}
 */
const getItemFulfillment = <T extends SthEntries>(item: T): boolean => {
    const fulfillment = item.fulfillment?.deliveryMode || item.fulfillment
    return isAtleastOneParamValid(
        fulfillment === CartOrderDeliveryModes.STH,
        fulfillment === CartOrderDeliveryModes.EXPRESS,
    ) as boolean
}

/**
 * Checks whether a passed item belongs to Ship To Home type
 * @param { T } item - cart product item
 * @returns {boolean} true if item fo Ship To Home
 */
const isSthItem = <T extends SthEntries>(item: T): boolean =>
    areAllParamsValid(getItemFulfillment(item), item.entryType === eEntryType.PRODUCT, !item.cartEntryReference)

/**
 * Checks whether a passed item belongs to Buy Online Pick Up In Store type
 * @param { T } item - cart product item
 * @returns {boolean} true if item fo Buy Online Pick Up In Store
 */
const isBopisItem = (item: CartOrderEntries | CheckoutOrderEntries): boolean =>
    areAllParamsValid(
        isAtleastOneParamValid(
            item.fulfillment?.deliveryMode === CartOrderDeliveryModes.BOPIS,
            item.fulfillment?.deliveryMode === CartOrderDeliveryModes.CURBSIDE,
        ),
        item.entryType === eEntryType.PRODUCT,
        !item.cartEntryReference,
    )

const getSth = <T extends SthEntries>(orderEntries?: Array<T> | null): Array<T> => {
    return orderEntries?.filter(isSthItem) ?? []
}

const getBopis = (orderEntries: Array<CartOrderEntries | CheckoutOrderEntries>) => {
    return orderEntries?.filter(isBopisItem)
}

/**
 * Returns package items with balloons weight before balloons bag
 * @param { CartOrderEntries[] } packageItems - product items of package
 * @returns { CartOrderEntries[] } array of ordered package items
 */
export const getItemsWithWeightsBeforeBags = (packageItems: CartOrderEntries[]): CartOrderEntries[] => {
    const weightItems = packageItems.filter(item => item.balloonEntryType === balloonEntryType.weightSku)
    const bagItems = packageItems.filter(item => item.balloonEntryType === balloonEntryType.bagSku)

    if (weightItems.length && bagItems.length) {
        const orderedItems = packageItems.filter(item => !weightItems.includes(item) && !bagItems.includes(item))
        orderedItems.push(...weightItems)
        orderedItems.push(...bagItems)

        return orderedItems
    }

    return packageItems
}

/**
 * Checks if balloonEntryType is bouquet or not
 * @param {CartOrderEntries} entry entry object
 * @returns {boolean} comparison result
 */
const validateBalloonEntryTypeForBouquet = (entry: CartOrderEntries) => {
    return (
        entry.balloonEntryType === balloonEntryType.prePackagedBalloonSku ||
        entry.balloonEntryType === balloonEntryType.preDesignedBalloonBouquet
    )
}

const extractPackageItemsForOrderConfirmationPage = (
    groupNumber: number,
    cartEntries?: CartOrderEntries[],
): CartOrderEntries[] => {
    const packageItems: CartOrderEntries[] = []
    const bouquetItem = cartEntries?.find(
        entry => entry.entryGroupNumbers?.includes(groupNumber) && validateBalloonEntryTypeForBouquet(entry),
    )
    const otherItems = cartEntries?.filter(
        entry => entry.entryGroupNumbers?.includes(groupNumber) && !validateBalloonEntryTypeForBouquet(entry),
    )
    if (bouquetItem && otherItems) {
        packageItems.push(bouquetItem)
        otherItems.forEach(item => packageItems.push(item))
    }

    return getItemsWithWeightsBeforeBags(packageItems)
}

/**
 * Creates packages with product items that belong to them
 * @param { CartData } cartData - cart data object
 * @returns { Package[] } array of packages
 */
export const collectAllPackages = (cartData: CartData): Package[] => {
    return (cartData.entryGroups || [])
        .filter(entryGroup => entryGroup.groupType === CONFIGURABLE_BUNDLE_PACKAGE)
        .map(entryGroup => {
            const cartPackage: Package = {
                groupNumber: entryGroup.groupNumber,
                items: [],
            }

            const packageItems: CartOrderEntries[] = []
            if (cartData.orderEntries) {
                entryGroup?.entryGroup?.forEach(group => {
                    const item = (cartData.orderEntries as CartOrderEntries[])?.filter(entry =>
                        entry.entryGroupNumbers?.includes(group.groupNumber),
                    )
                    if (item?.length) {
                        packageItems.push(...item)
                    }
                })
                cartPackage.items = getItemsWithWeightsBeforeBags(packageItems)
            } else if (cartData.entries) {
                cartPackage.items = extractPackageItemsForOrderConfirmationPage(
                    entryGroup.groupNumber,
                    cartData.entries,
                )
            }

            return cartPackage
        })
}

/**
 * Finds Balloon Bouquet product item inside passed package
 * @param { Package } cartPackage - cart package object
 * @returns { CartOrderEntries | CheckoutOrderEntries | undefined } balloon bouquet product item if found
 */
const findBouquetItemInPackage = (cartPackage: Package): CartOrderEntries | CheckoutOrderEntries | undefined => {
    return cartPackage.items.find(item => validateBalloonEntryTypeForBouquet(item))
}

/**
 * Filters packages of Ship To Home type
 * @param { Package[] } packages - collected packages
 * @returns { Package[] } Ship To Home packages
 */
export const getSthPackages = (packages: Package[]): Package[] => {
    return packages.filter(cartPackage => {
        const bouquetItem = findBouquetItemInPackage(cartPackage)
        return bouquetItem && isSthItem(bouquetItem)
    })
}

/**
 * Filters packages of Buy Online Pick Up In Store type
 * @param { Package[] } packages - collected packages
 * @returns { Package[] } Buy Online Pick Up In Store packages
 */
export const getBopisPackages = (packages: Package[]): Package[] => {
    return packages.filter(cartPackage => {
        const bouquetItem = findBouquetItemInPackage(cartPackage)
        return bouquetItem && isBopisItem(bouquetItem)
    })
}

/**
 * Filters array of cart items that do not belong to packages
 * @param { Array<CartOrderEntries | CheckoutOrderEntries> } items - cart items
 * @returns { Array<CartOrderEntries | CheckoutOrderEntries> } cart items that do not belong to packages
 */
export const filterByNotPackageRelatedItems = (
    items: Array<CartOrderEntries | CheckoutOrderEntries> = [],
): Array<CartOrderEntries | CheckoutOrderEntries> => {
    return items.filter(item => !(isArrayNotEmpty(item.entryGroupNumbers) && item.balloonEntryType))
}

/**
 * Filters array of cart items that do not belong to packages for OrderConfirmationPage
 * Single Balloon Sku don't have empty entryGroupNumbers
 * @param { Array<CartOrderEntries | CheckoutOrderEntries> } items - cart items
 * @param {Package[]} packageData - bopis/sth packages
 * @returns { Array<CartOrderEntries | CheckoutOrderEntries> } cart items that do not belong to packages
 */
export const filterPackagesForOrderConfirmationPage = (
    items: Array<CartOrderEntries> = [],
    packageData?: Package[],
): Array<CartOrderEntries> => {
    const dataSet: CartOrderEntries[] = []

    if (items && packageData) {
        items?.forEach(item => {
            if (isArrayEmpty(item.entryGroupNumbers)) {
                dataSet.push(item)
            } else {
                if (isArrayNotEmpty(packageData)) {
                    if (!packageData?.find(data => data?.items?.some(val => val.code === item.code))) {
                        dataSet.push(item)
                    }
                } else {
                    dataSet.push(item)
                }
            }
        })
    } else {
        return items
    }

    return dataSet
}

// TODO: This code should be moved to cart helper
const getFilteredCartItems = <T extends CartData>(cartData: T, quantity?: number): FilteredCartData => {
    const orderEntries = isAtleastOneParamValid(
        cartData.orderEntries,
        cartData.updatedCart?.orderEntries,
        cartData.entries,
        [],
    ) as Array<CartOrderEntries | CheckoutOrderEntries>
    const cart = isAtleastOneParamValid(cartData.updatedCart, cartData) as CartItemsData
    const bopis = getBopis(orderEntries) as CartOrderEntries[]
    const sth = getSth(orderEntries) as CartOrderEntries[]
    const services = orderEntries.filter(item => item.entryType === eEntryType.SERVICE_STANDALONE) as CartOrderEntries[]
    const storeData = isAtleastOneParamValid(
        cartData.entries ? cartData.deliveryPointOfService : cartData.store,
        cartData.updatedCart?.store,
        [],
    ) as StoreWithAvailability
    const deliveryAddress = cartData?.deliveryAddress as DeliveryAddressDTO
    const selectedServiceList = orderEntries.filter(item =>
        isAtleastOneParamValid(
            item.entryType === eEntryType.SERVICE_ADDON,
            areAllParamsValid(item.entryType === eEntryType.PRODUCT, !!item.cartEntryReference),
        ),
    ) as CartOrderEntries[]
    const installationAvailable = orderEntries.filter(
        (item: CartOrderEntries | CheckoutOrderEntries) => item.hasStoreAutoServiceCenter,
    ).length
        ? isServiceEligible(bopis)
            ? YesNoNotApplicable.yes
            : YesNoNotApplicable.no
        : YesNoNotApplicable.notApplicable
    const freeShippingProgressData = cartData?.shippingProgress as FreeShippingProgress

    const isCartHasPackages = isAtLeastOnePackageExists(cartData)
    let bopisPackages: Package[] = []
    let sthPackages: Package[] = []

    if (isCartHasPackages) {
        const allPackages = collectAllPackages(cartData)
        bopisPackages = getBopisPackages(allPackages)
        sthPackages = getSthPackages(allPackages)
    }

    return {
        cart,
        bopis,
        sth,
        services,
        storeData,
        deliveryAddress,
        selectedServiceList,
        installationAvailable,
        freeShippingProgressData,
        isUpdateQuantity: !!quantity,
        bopisPackages,
        sthPackages,
    } as FilteredCartData
}
export default getFilteredCartItems
export { areAllParamsValid, isAtleastOneParamValid, getSth, getBopis }
