import { addDynamicToken, getFormattedPriceValue, replaceStrWithDynamicVal } from '@nl/lib'
import { Price as PriceType } from '../../../redux/models/cart.interface'

/**
 * Function to get fee title
 * @param {PriceType | number | undefined} feeMessageValue fee message
 * @param {string | undefined} feeTitle fee title
 * @param {string} language language
 * @returns {string} fee title
 */
export const getFeeTitle = (
    feeMessageValue: PriceType | number | undefined,
    feeTitle: string | undefined,
    language: string,
): string => {
    return feeMessageValue
        ? replaceStrWithDynamicVal(
              addDynamicToken(feeTitle as string, '$x'),
              getFormattedPriceValue(language, feeMessageValue),
          )
        : ''
}
