import { BREAKPOINTS } from '../components/config'
import { useEffect, useState } from 'react'

/**
 * Hook that checks the window width is less than particular breakpoint (mobile maxwidth by default) dynamically
 * @param { number } breakpoint
 * @return {boolean}
 */
export const useIsMobile = (breakpoint: number = BREAKPOINTS.mobileMaxWidth): boolean => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const resizeWindow = () => {
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        resizeWindow()
        window.addEventListener('resize', resizeWindow)
        return () => window.removeEventListener('resize', resizeWindow)
    }, [])

    return windowWidth <= breakpoint
}
