import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { FilteredCartData } from '../../redux/models/cart.interface'
import getPageType from '../../utils/getPageType'
import { addToCartAnalytics } from './addToCartAnalytics'
import { getCartAnalytics } from './index'

/**
 * Fire analytics for merge cart event
 * @param {string} guestCartId
 * @param {string} authCartId
 * @param {FilteredCartData} cartItemsDataForAuth
 * @param {number[]} orderEntries
 * @param {boolean} fromLogin
 */
export const fireAnalyticsForMerge = (
    guestCartId: string,
    authCartId: string,
    cartItemsDataForAuth: FilteredCartData,
    orderEntries?: number[],
    fromLogin?: boolean,
): void => {
    const cartAnalytics = getCartAnalytics('')
    const {
        eventParameters: {
            location: { mergeCart: mergeCartAnalytics },
        },
    } = analyticsAttributes

    const makeSkuList = (entries?: string[]) => entries?.join(' - ') || ''

    let mergedSKUs = ''
    const pageType = getPageType()
    if (fromLogin) {
        const filteredOrderEntries = cartItemsDataForAuth.cart.orderEntries || []
        mergedSKUs = makeSkuList(filteredOrderEntries.map(entry => entry.code))
        addToCartAnalytics(
            filteredOrderEntries,
            guestCartId,
            pageType,
            mergeCartAnalytics,
            false,
            false,
            true,
            true,
            true,
            cartItemsDataForAuth?.cart?.cartSummary?.shippingFees?.value as number | undefined,
        )
    } else if (orderEntries && orderEntries.length) {
        const filteredOrderEntries =
            cartItemsDataForAuth.cart.orderEntries?.filter(entry => orderEntries.includes(entry.entryNumber)) || []
        mergedSKUs = makeSkuList(filteredOrderEntries.map(entry => entry.code))
        addToCartAnalytics(
            filteredOrderEntries,
            guestCartId,
            pageType,
            mergeCartAnalytics,
            false,
            false,
            true,
            true,
            true,
            cartItemsDataForAuth?.cart?.cartSummary?.shippingFees?.value as number | undefined,
        )
    }
    const locationIsModal = !!orderEntries
    cartAnalytics.fireMergeCartAnalytics(authCartId, mergedSKUs, locationIsModal)
}

export default fireAnalyticsForMerge
