import { analyticsAttributes } from '../../globalConstants'
import getPageType from '../../utils/getPageType'
import { getProvider } from '../providers'

/**
 * This Function used to fire analytics event for shop the look image.
 * @param {string} imageLinkAnalyticsTrackingId
 */

export const shopTheLookAnalytics = (imageLinkAnalyticsTrackingId: string): void => {
    const {
        event: { promoClick },
        eventParameters: {
            action: { link },
            labels: { photo },
        },
    } = analyticsAttributes

    getProvider().push({
        event: promoClick,
        eventParameters: {
            action: link,
            category: getPageType() || '',
            label: photo,
            value: imageLinkAnalyticsTrackingId,
        },
    })
}
