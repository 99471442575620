import {
    currencyFormat,
    currencyShortForm,
    multiLanguageLabel,
    replaceNumberFormattedPrice,
} from '../globalConstants/global.constant'
import { localization } from '../helpers/Localization.helper'
import getLanguage from './getLanguage'

/**
 * getFormattedPriceWithLocale - Return price format price based on language and the currency code
 */

export const getFormattedPriceWithLocale = (price: number): string => {
    const language = getLanguage()
    return localization.formattedPrice(language, price, currencyShortForm, currencyFormat)
}

export const getNumberFromFormattedPrice = (currency: string) =>
    Number(currency.replace(replaceNumberFormattedPrice, ''))

/**
 * Method to extract currency values in number format from formatted locale values
 * @param {string} currency currency in locale value(string)
 * @returns {number} currency in number with formatted locale values
 */
export const getNumberFromFormattedPriceWithLocale = (currency: string): number => {
    if (multiLanguageLabel.frenchLangLabel.toLowerCase() === getLanguage()) {
        currency = currency.replace(/,/g, '.')
    }
    return Number(currency.replace(replaceNumberFormattedPrice, ''))
}
export default { getFormattedPriceWithLocale, getNumberFromFormattedPrice, getNumberFromFormattedPriceWithLocale }
