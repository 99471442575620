import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/orderConfirmation.actionCreators'
import { CartResponseErrorDTO, ClickToPaySTHValidationDTO, FilteredCartData } from '../models/cart.interface'
import { OrderNotificationSubscriptionResponse } from '../models/orderConfirmation.interface'

type Action = ActionType<typeof actions>

export interface orderConfirmationState {
    order: FilteredCartData
    getOrderConfirmationError: CartResponseErrorDTO
    placeOrderConfirmationError: CartResponseErrorDTO
    notificationSubscription: NotificationSubscription
    clickToPayAddressValidation: ClickToPaySTHValidationDTO
    clickToPaySTHValidationError: CartResponseErrorDTO
}
export interface NotificationSubscription {
    notificationSubscriptionResponse: OrderNotificationSubscriptionResponse
    orderNotificationError: boolean
}

export const initialState: orderConfirmationState = {
    order: {} as FilteredCartData,
    getOrderConfirmationError: {} as CartResponseErrorDTO,
    placeOrderConfirmationError: {} as CartResponseErrorDTO,
    notificationSubscription: {
        notificationSubscriptionResponse: {} as OrderNotificationSubscriptionResponse,
        orderNotificationError: false,
    },
    clickToPayAddressValidation: {} as ClickToPaySTHValidationDTO,
    clickToPaySTHValidationError: {} as CartResponseErrorDTO,
}

export const orderItemsDetailsReducer = (
    state: orderConfirmationState = initialState,
    action: Action,
): orderConfirmationState => {
    switch (action.type) {
        case getType(actions.orderConfirmationSuccess):
        case getType(actions.placeOrderConfirmationSuccess):
            return { ...state, order: action.payload }
        case getType(actions.placeOrderConfirmationFailure):
            return { ...state, placeOrderConfirmationError: action.payload }
        case getType(actions.orderConfirmationFailure):
            return { ...state, getOrderConfirmationError: action.payload }
        case getType(actions.toggleNotificationSubscription):
            return {
                ...state,
                notificationSubscription: {
                    ...state.notificationSubscription,
                    notificationSubscriptionResponse: action.payload,
                },
            }
        case getType(actions.setOrderNotificationError):
            return {
                ...state,
                notificationSubscription: {
                    ...state.notificationSubscription,
                    orderNotificationError: action.payload,
                },
            }
        case getType(actions.clickToPayShippingAddressValidationSuccess):
            return {
                ...state,
                clickToPayAddressValidation: action.payload,
            }
        case getType(actions.clickToPayShippingAddressValidationError):
            return {
                ...state,
                clickToPaySTHValidationError: action.payload,
            }
        default:
            return state
    }
}
