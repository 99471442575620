import { BaseService } from '../base.service'
import { getEnvironment } from '../../environments'
import appCacheService from '../../utils/appCacheService'
import { getHttpClient } from '../../httpClient'
import { PriceAvailabilityDataResponse, RequestBodyItem } from './priceAvailabilityService.type'
import { ProductDataTypeObj } from '../../redux/models/productData.interface'
import { emptySpace, inflationData } from '../../globalConstants'
import { MagicNumber } from '../../analytics/analytics.type'

const httpClient = getHttpClient()
const environment = getEnvironment()

/**
 * Service to get price availability data
 */
class PriceAvailabilityService extends BaseService {
    /**
     * Creates Product Price Availability API URL
     * @param {string} preferredStoreId - preferred store id.
     * @returns {URL} url Pricing API URL
     */
    private createUrl(preferredStoreId: string): URL {
        const storeId = preferredStoreId || appCacheService.preferredStoreId.get()
        const {
            language,
            API_BASE_URL,
            API_ENDPOINTS: { productPriceAvailability },
        } = environment

        const url = new URL(`${API_BASE_URL}${productPriceAvailability}`)
        url.searchParams.set('lang', language)
        url.searchParams.set('storeId', storeId)

        return url
    }

    /**
     * Extracts product's brand label
     * @param {ProductDataTypeObj} product Product object
     * @returns {string} Brand label
     */
    private extractBrandLabel(product: ProductDataTypeObj): string {
        return product?.brand?.label || ''
    }

    /**
     * Creates request body item
     * @param {string} code SKU or product Code
     * @param {string} brand brand label
     * @returns {RequestBodyItem} Formatted request payload item
     */
    private createRequestBodyItem(code: string, brand: string): RequestBodyItem {
        return {
            code: code,
            brand: brand,
        }
    }

    /**
     * Creates request body item with additional fields for helium inflated balloon products
     * @param {ProductDataTypeObj} product product object
     * @returns {RequestBodyItem} Formatted request payload item
     */
    private createRequestBodyItemIncludingBalloonProduct(product: ProductDataTypeObj): RequestBodyItem {
        const skuObj = product?.skus?.[MagicNumber.ZERO]
        const badges = product?.badges?.concat(skuObj?.badges || [])
        let payload: RequestBodyItem = {
            code: product.code,
            brand: product?.brand?.label ?? emptySpace,
        }

        if (skuObj?.supportedStoreServiceTypes) {
            payload = {
                ...payload,
                supportedStoreServiceTypes: skuObj.supportedStoreServiceTypes,
            }
        }

        if (product?.productType || skuObj?.productType) {
            payload = {
                ...payload,
                productType: product?.productType || skuObj?.productType,
            }
        }

        if (badges?.includes(inflationData.heliumIncluded)) {
            payload = {
                ...payload,
                isHeliumIncluded: true,
            }
        }

        if (skuObj?.couponCode) {
            payload = {
                ...payload,
                couponCode: skuObj?.couponCode,
            }
        }

        return payload
    }

    /**
     * Creates request body item with additional fields for helium inflated balloon products
     * @param {ProductDataTypeObj} product product object
     * @returns {RequestBodyItem} Formatted request payload item
     */
    private createRequestBodyItemForBalloonProduct(product: ProductDataTypeObj): RequestBodyItem {
        const skuObj = product?.skus?.[MagicNumber.ZERO]
        const badges = product?.badges?.concat(skuObj?.badges || [])
        let payload: RequestBodyItem = {
            code: product.code,
            brand: this.extractBrandLabel(product),
        }

        if (skuObj?.supportedStoreServiceTypes) {
            payload = {
                ...payload,
                supportedStoreServiceTypes: skuObj.supportedStoreServiceTypes,
            }
        }

        if (product?.productType || skuObj?.productType) {
            payload = {
                ...payload,
                productType: product?.productType || skuObj?.productType,
            }
        }

        if (badges?.includes(inflationData.heliumIncluded)) {
            payload = {
                ...payload,
                isHeliumIncluded: true,
            }
        }

        if (skuObj?.couponCode) {
            payload = {
                ...payload,
                couponCode: skuObj?.couponCode,
            }
        }

        return payload
    }

    /**
     * Creates request payload from products
     * @param {ProductDataTypeObj[]} products products to process
     * @returns {string} generated request payload
     */
    private createRequestBody(products: ProductDataTypeObj[]): string {
        return JSON.stringify({
            products: products.map(product => this.createRequestBodyItemForBalloonProduct(product)),
            skus: products.flatMap(product =>
                product.skus.map(({ code }) => this.createRequestBodyItem(code, this.extractBrandLabel(product))),
            ),
        })
    }

    /**
     * This function used to fetch price and availability data for specified products
     * @method
     * @param {ProductDataTypeObj[]} products
     * @param {string} preferredStoreId - preferred store id.
     * @return {AxiosPromise<APIProductGroupResponse>} PriceAvailabilityDataResponse
     */
    fetchPriceAvailabilityData(
        products: ProductDataTypeObj[],
        preferredStoreId: string,
    ): Promise<PriceAvailabilityDataResponse> {
        const url = this.createUrl(preferredStoreId)
        const requestBody = this.createRequestBody(products)

        return new Promise<PriceAvailabilityDataResponse>((resolve, reject) => {
            httpClient
                .apiPost<PriceAvailabilityDataResponse>(url, requestBody)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

export const priceAvailabilityService = new PriceAvailabilityService()
