import { Dispatch } from 'redux'

import { AnalyticsRecordInterface } from '../../analytics/providers/provider.type'
import getPageType from '../../utils/getPageType'
import {
    analyticsInteractionCreator,
    analyticsClearInteractionCreator,
} from '../actionCreators/pageAnalytics.actionCreators'

export const analyticsInteraction =
    (
        label: string,
        componentName: string | undefined,
        eventName: string,
        actionName: string,
        categoryName?: string,
        locationName?: string,
        eventValue?: string,
    ) =>
    (dispatch: Dispatch): void => {
        const interactionInfo: AnalyticsRecordInterface = {
            event: eventName,
            eventParameters: {
                action: actionName,
                category: categoryName || getPageType(),
                label: `${label}`,
                value: (eventValue || null) as string | undefined,
            },
        }
        if (componentName) {
            interactionInfo.eventParameters = {
                ...interactionInfo.eventParameters,
                location: locationName || `${getPageType()}` || '',
            }
        }

        dispatch(analyticsInteractionCreator(interactionInfo))
    }

export const clearInteraction =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(analyticsClearInteractionCreator(undefined))
    }
