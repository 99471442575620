import React, { useRef, useEffect, useCallback, useMemo } from 'react'

import { keyCodes } from '../../utils/keyCodes'
import { PREFIX, previousElementName, BREAKPOINTS } from '../config'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'
import { FlyoutModalComponentProps } from './FlyoutModalComponent.type'
import { modalAccessibilityHandler } from '../../utils/modalAccessibility'
import { disableFocusLock, enableFocusLock } from '../../utils/focusLock'
import { useIsMobile } from '../../hooks/useIsMobile.hook'
import { isVerticalFilters } from '../FacetPanelModal/FacetPanelModal.helper'

/**
 * Flyout Modal
 * @param {FlyoutModalComponentProps} param - Flyout Modal Component Props
 * @returns {JSX.Element} JSX Element
 */
export const FlyoutModalComponent: React.FC<FlyoutModalComponentProps> = ({
    children,
    closeFlyout,
    isOpen,
    modalTriggeredElement,
    disableCloseByEscOrOutsideClick = false,
    title,
    isStoreSelectorModalUsed,
    isPrerender,
    enableVerticalFilterToggle,
}: FlyoutModalComponentProps): JSX.Element => {
    const flyOutContent = useRef<HTMLDivElement>(null)

    const modalClassName = `${PREFIX}-flyout-modal-overlay`

    const undoAccessibilityProps = useMemo(
        () => ({
            modalOpen: false,
            modalClassName,
        }),
        [modalClassName],
    )

    const previousElement: HTMLElement =
        document.querySelector(`button[${previousElementName}]`) || modalTriggeredElement
    const focusLockEventRef = useRef<EventListener>()
    const isMobileOrTablet = useIsMobile(BREAKPOINTS.tabletMaxWidth)

    const closeHandler = useCallback(
        (event?: MouseEvent | KeyboardEvent) => {
            if (
                (event?.target as HTMLElement).contains(flyOutContent.current) ||
                ((event as KeyboardEvent)?.keyCode === keyCodes.esc && !disableCloseByEscOrOutsideClick)
            ) {
                modalAccessibilityHandler(undoAccessibilityProps)
                closeFlyout() // Closing Modal UI
                // checking if it exists or not to avoid focus null error when the clicked element goes off from the ui due to data change.
                if (previousElement) {
                    previousElement.focus() // Highlight the initiated button
                    previousElement.removeAttribute(previousElementName) // Removing it when user close it.
                }
            }
        },
        [closeFlyout, previousElement, undoAccessibilityProps, disableCloseByEscOrOutsideClick],
    )

    useEffect(() => {
        if (isOpen) {
            document.body.style.position = 'relative'
        }
        return () => {
            document.body.style.position = 'unset'
        }
    }, [isOpen])

    // Execute the below when user press escape key.
    const closeModalEsc = useCallback(
        (e: KeyboardEvent): void => {
            closeHandler(e)
        },
        [closeHandler],
    )

    useEffect(() => {
        if (isOpen) {
            flyOutContent.current.addEventListener('keydown', closeModalEsc)
        } else {
            flyOutContent.current.removeEventListener('keydown', closeModalEsc)
        }
    }, [isOpen, closeModalEsc])

    // Separated enable focus logic into a dedicated useEffect
    useEffect(() => {
        if (open) {
            if (!isStoreSelectorModalUsed && !isVerticalFilters(enableVerticalFilterToggle, isMobileOrTablet)) {
                focusLockEventRef.current = enableFocusLock(flyOutContent)
            }
        } else {
            !isStoreSelectorModalUsed && disableFocusLock(focusLockEventRef.current)
        }
    }, [isOpen, isStoreSelectorModalUsed])

    useClickOutsideClose(flyOutContent, closeHandler, isOpen, !disableCloseByEscOrOutsideClick, true)
    const verticalAlignFiltersClass = isVerticalFilters(enableVerticalFilterToggle, isMobileOrTablet)
        ? `${PREFIX}-flyout-modal-component--vertical-align-filters`
        : ''

    return (
        <div
            className={
                isVerticalFilters(enableVerticalFilterToggle, isMobileOrTablet)
                    ? `${verticalAlignFiltersClass}--sticky`
                    : `${PREFIX}-overlay ${modalClassName} ${isPrerender ? `${PREFIX}-sm-none ${PREFIX}-xs-none` : ``} `
            }>
            <div
                className={`${PREFIX}-flyout-modal-component ${verticalAlignFiltersClass}`}
                ref={flyOutContent}
                role={isVerticalFilters(enableVerticalFilterToggle, isMobileOrTablet) ? 'region' : 'dialog'}
                aria-modal="true"
                aria-label={isVerticalFilters(enableVerticalFilterToggle, isMobileOrTablet) ? '' : title}>
                {children}
            </div>
        </div>
    )
}

FlyoutModalComponent.displayName = 'FlyoutModalComponent'
