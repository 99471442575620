import React from 'react'
import { PREFIX } from '../../../config'
import { MeasureDescriptionType } from './SizeChart.type'

/**
 * MeasureDescription component
 * @param {MeasureDescriptionType} props - measure points
 * @returns {JSX.Element} returns MeasureDescription component
 */
const MeasureDescription: React.FC<MeasureDescriptionType> = ({ title, description }): JSX.Element | null => {
    const createInnerHTML = () => {
        return {
            __html: description,
        }
    }

    const howToMeasureTitle = !!title ? <strong>{`${title}:`}</strong> : null
    return (
        <li className={`${PREFIX}-size-chart__measure-point`} key={`pointKey-${description}`}>
            {howToMeasureTitle} <span dangerouslySetInnerHTML={createInnerHTML()} />
        </li>
    )
}

export default MeasureDescription
