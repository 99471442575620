import { ActionType, getType } from 'typesafe-actions'

import { setOptionalRequestHeaderParameter } from '../actionCreators/optionalRequestHeaderParameter.actionCreators'
import * as actions from '../actionCreators/optionalRequestHeaderParameter.actionCreators'
import { OptionalRequestHeaderParameter } from '../models/optionalRequestHeaderParameter.interface'

type Action = ActionType<typeof actions>

const initialState: OptionalRequestHeaderParameter = {}

export const optionalRequestHeaderParameterReducer = (
    state = initialState,
    action: Action,
): OptionalRequestHeaderParameter => {
    if (action.type === getType(setOptionalRequestHeaderParameter)) {
        return { ...action.payload }
    } else {
        return state
    }
}
