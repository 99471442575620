import { subscriptionStatus, subscriptionType } from '../globalConstants/global.constant'

const isTriangleSelectMember = (tsSubscriptionStatus?: string): boolean => {
    return tsSubscriptionStatus === subscriptionStatus.active
}

const wasTriangleSelectMember = (tsSubscriptionStatus?: string): boolean => {
    return tsSubscriptionStatus === subscriptionStatus.expired
}

/**
 * identifies whether the subscription type is regular or freetrial
 * @param {tsSubscriptionType} string subscription type
 * @returns {string}
 */

const getSubscriptionType = (tsSubscriptionType?: string): string => {
    return tsSubscriptionType?.toLowerCase() === subscriptionType.freeTrial
        ? subscriptionType.freeTrial
        : subscriptionType.regular
}

export default isTriangleSelectMember
export { isTriangleSelectMember, wasTriangleSelectMember, getSubscriptionType }
