import { MagicNumber } from '../analytics/analytics.type'

/**
 * Returns Date Format with or without time
 * @param {boolean} dateOnly - should be true to fetch date without time
 * @param {number} previousDate - previous date number for which date format is required
 * @return {string} - returns Date or DateTime
 */
const getDateTime = (dateOnly = false, previousDate = 0): string => {
    const currentMonth = 1
    const numOfHours = 10
    const newDate = new Date()
    const hours = newDate.getHours()
    const minutes = newDate.getMinutes()
    const seconds = newDate.getSeconds()

    /**
     * function to calculate date 90 days before current date
     * @return {number}
     */
    const getModifiedDate = () => {
        const startDate = newDate.setDate(newDate.getDate() - previousDate)
        const formattedStartDate = new Date(startDate)
        return formattedStartDate.getDate()
    }

    /**
     * Returns Date
     * @param {number} previous - should be greater than 0
     * @return {string} Previous Date or Current Date
     */
    const date = previousDate > 0 ? getModifiedDate() : newDate.getDate()
    const month = newDate.getMonth() + currentMonth
    const year = newDate.getFullYear()

    /**
     * New date needs to be set when previousDate > 0
     * If previousDate <= 0, there will be no change as it calls the same on the ternary operator on the date const
     * @param {number} setDate
     * @return {number} New Formatted Date String.
     */
    const setDate = newDate.getDate()

    /**
     * Formatting Year, Month & Date to required format(double digits).
     * @param {string} year
     * @param {string} month
     * @param {string} date
     *
     * @return {string} Formatted Date String.
     */

    const formatedDate = `${month < numOfHours ? `0${month}` : `${month}`}${
        date < numOfHours ? `0${setDate}` : `${setDate}`
    }${year}`

    /**
     * Formatting Hours, Minutes & Seconds to required format(double digits).
     * @param {string} hours
     * @param {string} minutes
     * @param {string} seconds
     *
     * @return {string} Formatted Time String.
     */
    const formatedTime = `${hours < numOfHours ? `0${hours}` : `${hours}`}:${
        minutes < numOfHours ? `0${minutes}` : `${minutes}`
    }:${seconds < numOfHours ? `0${seconds}` : `${seconds}`}`

    return dateOnly ? formatedDate : formatedDate + formatedTime
}

export default getDateTime

/**
 * Returns Date Format DDMMYY
 * @param {string} extendedFormatDate - ISO 8601 Extended format YYYY-MM-DDTHH:mm:ss.sssZ
 * @return {string} - returns Date or DateTime
 */
export const getDateFormatDDMMYY = (extendedFormatDate: string): string => {
    const date = new Date(extendedFormatDate)
    const year = date.getFullYear().toString()

    let month = (MagicNumber.ONE + date.getMonth()).toString()
    month = month.length > MagicNumber.ONE ? month : `0${month}`

    let day = date.getDate().toString()
    day = day.length > MagicNumber.ONE ? day : `0${day}`

    return day + month + year
}

/**
 * Function return current date '
 * @return {Date} - Returns current date
 */
export const getCurrentDateTime = (): Date => {
    return new Date()
}
