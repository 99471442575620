import { Facet } from '../FacetPanelModal/FacetPanelModal.type'
import { contextType } from './RangeFilter.constants'
import { RangeFacetUomInterface, RangeFacetValuesInterface } from './RangeFilter.type'

/**
 * Below function returns Range Facet Values
 * @param { Facet } facet
 * @returns { RangeFacetValuesInterface }
 */
export const getRangeFacetValues = (facet: Facet): RangeFacetValuesInterface => {
    const { min, max, minSelected, maxSelected } = facet
    return {
        startVal: minSelected || `${min}`,
        endVal: maxSelected || `${max}`,
    }
}

/**
 * Below function returns Range Facet Uom
 * @param { Facet } facet
 * @returns { RangeFacetUomInterface }
 */
export const getRangeFacetUom = (facet: Facet): RangeFacetUomInterface => {
    const { value, uom } = facet
    if (value.startsWith(uom)) {
        return {
            prefix: uom,
        }
    } else {
        return {
            suffix: uom,
        }
    }
}

/**
 * Below function accepts value and return it with UOM
 * @param { number } value
 * @param { RangeFacetUomInterface } uom
 * @returns { string }
 */
export const getValueWithUOM = (value: number, uom: RangeFacetUomInterface): string => {
    const { prefix = '', suffix = '' } = uom
    return `${prefix} ${value} ${suffix}`.trim()
}

/**
 * Below function checks if input value is greater than max value
 * @param { string } inputVal
 * @param { string } maxVal
 * @returns { boolean }
 */
export const inputValGreaterThanMaxVal = (inputVal: string, maxVal: string) => {
    return Number(inputVal) > Number(maxVal)
}

/**
 * Below function checks if input value is less than min value
 * @param { string } inputVal
 * @param { string } minVal
 * @returns { boolean }
 */
export const inputValLessThanMinVal = (inputVal: string, minVal: string) => {
    return Number(inputVal) < Number(minVal)
}

/**
 * function to calculate the width of text for UOM placement
 * @param {string} text
 * @param {string} font
 * @param {React.RefObject<HTMLCanvasElement>} canvasRef
 * @returns {number}
 */
export const getTextWidth = (text: string, font: string, canvasRef: React.RefObject<HTMLCanvasElement>): number => {
    const canvas = canvasRef.current
    const context = canvas.getContext(contextType)
    context.font = font
    const metrics = context.measureText(text)
    return metrics.width
}

/**
 * Below function checks if input value is valid or not
 * @param { string } inputValue
 * @returns { boolean }
 */
export const isInputValid = (inputValue: string): boolean => {
    return /^\d*(\.\d*)?$/.test(inputValue)
}
