import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS, PREFIX } from '../config'
import { OverviewProps } from './Overview.types'
import Icon from '../Icon'
import { checkDataLength } from '../../utils/checkDataLength'
import Button from '../Button'
import { maxHeight } from './overview.constant'
import { magicNumber } from '../../utils/magicNumber'
import { getViewMoreDescriptionClass } from './OverviewComp.helper'
/**
 * Overview component
 *
 * @param {OverviewProps} props - heading, body
 * @return {JSX.Element} returns Overview component
 */
const Overview: React.FC<OverviewProps> = (props: OverviewProps): JSX.Element => {
    const { bodyText, path, viewMoreDetailsLabel, viewLessDetailsLabel } = props
    const isMobile = window.innerWidth <= BREAKPOINTS.tabletMaxWidth
    const [showViewMoreOrLess, setShowViewMoreOrLess] = useState(false)
    const [viewMoreOrLess, setViewMoreOrLess] = useState(true)
    const buttonRef = useRef<HTMLButtonElement>()
    const roadRatedSpecsEleLength = document.querySelectorAll(`.${PREFIX}-overviewcontent__roadratingspecs`).length
    const isRoadRatedSpecs = document.querySelector(`.${PREFIX}-overviewcontent__roadratingspecs`)
    const isShowViewMoreOrLessBtn = isRoadRatedSpecs
        ? roadRatedSpecsEleLength > magicNumber.ONE
        : showViewMoreOrLess && isMobile
    const viewMoreDescriptionClass = getViewMoreDescriptionClass(
        viewMoreOrLess,
        isShowViewMoreOrLessBtn,
        `${PREFIX}-overviewcontent--collapsed`,
    )
    const viewMoreRoadRatingSpecsClass = getViewMoreDescriptionClass(
        viewMoreOrLess,
        isShowViewMoreOrLessBtn,
        `${PREFIX}-overviewcontent--roadratingspecs-collapsed`,
    )
    const descriptionEle = document.querySelector(`.${PREFIX}-overviewcontent`)
    /**
     * useEffect to set view more/less section
     */
    useEffect(() => {
        if (descriptionEle instanceof HTMLElement) {
            setShowViewMoreOrLess(descriptionEle && descriptionEle.scrollHeight > maxHeight)
        }
    }, [setShowViewMoreOrLess, descriptionEle])

    /**
     * Click handler on ViewMore / ViewLess button.
     * @return {void}
     */
    const viewMoreOrLessBtnClick = (): void => {
        setViewMoreOrLess(!viewMoreOrLess)
        viewMoreOrLess
            ? buttonRef.current?.classList.add('view-more-less-btn-focus')
            : buttonRef.current?.classList.remove('view-more-less-btn-focus')
    }

    /**
     * function to return viewmoredetails or viewlessdetails button
     * @return {JSX.Element}
     */
    const showViewMoreOrLessBtn = (): JSX.Element => {
        return (
            isShowViewMoreOrLessBtn && (
                <div className={`${PREFIX}-overviewcontent__viewmoreorlessbtn`}>
                    <Button type="tertiary" onClick={() => viewMoreOrLessBtnClick()} ref={buttonRef}>
                        {viewMoreOrLess ? viewMoreDetailsLabel : viewLessDetailsLabel}
                        <Icon type={viewMoreOrLess ? 'ct-chevron-down' : 'ct-chevron-up'} size="sm" path={path} />
                    </Button>
                </div>
            )
        )
    }

    /**
     * Function to return RoadRatedSpecifications
     * @param {string} iconType
     * @param {string} iconTitle
     * @param {string} iconDescription
     * @return {JSX.Element}
     */
    const displayRoadRatedSpecifications = (
        iconType: string,
        iconTitle: string,
        iconDescription: string,
    ): JSX.Element => {
        return (
            <div className={`${PREFIX}-col-sm-12 ${PREFIX}-col-md-4 ${PREFIX}-col-xs-12 ${PREFIX}-display-content`}>
                <div className={`${PREFIX}-overviewcontent__roadratingspecs`}>
                    <Icon type={iconType} size="lg" />
                    <div>
                        <h4 id={`head-${iconType}`} className={`${PREFIX}-overviewcontent__icon-title`}>
                            {iconTitle}
                        </h4>
                        <p>{iconDescription}</p>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <div id="overview" className={`${PREFIX}-overviewcontent`} data-testid="overview">
                {checkDataLength(bodyText.longDescription) && (
                    <div className={`${PREFIX}-col-sm-12 ${PREFIX}-overviewcontent__grid`}>
                        <p className={`${PREFIX}-overviewcontent__description ${viewMoreDescriptionClass}`}>
                            {bodyText.longDescription}
                        </p>
                    </div>
                )}

                <div className={`${PREFIX}-row ${viewMoreRoadRatingSpecsClass}`}>
                    <div
                        className={
                            viewMoreOrLess
                                ? `${PREFIX}-overviewcontent--hidden`
                                : `${PREFIX}-overviewcontent--display-content-wrapper`
                        }>
                        {checkDataLength(props.isSnowPeakRated) &&
                            displayRoadRatedSpecifications(
                                'ct-snwpeak',
                                props.isSnowPeakRated.title,
                                props.isSnowPeakRated.discription,
                            )}
                        {checkDataLength(props.isMudSnowRated) &&
                            displayRoadRatedSpecifications(
                                'ct-madandsnow',
                                props.isMudSnowRated.title,
                                props.isMudSnowRated.discription,
                            )}
                        {checkDataLength(props.isLowResistanceRated) &&
                            displayRoadRatedSpecifications(
                                'ct-lowresistance',
                                props.isLowResistanceRated.title,
                                props.isLowResistanceRated.discription,
                            )}
                        {checkDataLength(props.isAcceptStuds) &&
                            displayRoadRatedSpecifications(
                                'ct-acceptstuds',
                                props.isAcceptStuds.title,
                                props.isAcceptStuds.discription,
                            )}
                    </div>
                </div>
                {showViewMoreOrLessBtn()}
            </div>
        </>
    )
}
Overview.propTypes = {
    bodyText: PropTypes.any,
    isSnowPeakRated: PropTypes.any,
    isMudSnowRated: PropTypes.any,
    isLowResistanceRated: PropTypes.any,
    isAcceptStuds: PropTypes.any,
}
export default Overview
