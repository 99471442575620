import { createAction } from 'typesafe-actions'

import { ShareCartTemplate, SendLinkFormData } from '../models/sharedCart.interface'
import { StoreWithAvailability } from '../models/storeDetails.interface'
import { FilteredCartData, CartResponseErrorDTO } from '../models/cart.interface'

import {
    SET_STORE_INITIATED_CART_TEMPLATE,
    SET_STORE_INITIATED_CART_TEMPLATE_ERROR,
    SET_STORE_INITIATED_CART,
    SET_STORE_INITIATED_CART_ERROR,
    RESET_STORE_INITIATED_CART_DATA,
    SET_GENERATED_SHARED_CART_LINK,
    SET_GENERATED_SHARED_CART_ERROR,
    SET_CUSTOMER_SHARED_CART,
    SET_CUSTOMER_SHARED_CART_ERROR,
    SET_CUSTOMER_SHARED_CART_MERGING_ERROR,
    SET_SEND_LINK_FORM_DATA,
    SET_SHARED_CART_LINK_SENT,
    SET_SHARED_CART_LINK_SENDING_ERROR,
    SET_ONE_TIME_CART_DELIVERY_MODE_CHANGED,
    SET_CHANGE_DELIVERY_MODE_ERROR,
    SET_ONE_TIME_CART_STORE,
    SET_ONE_TIME_CART_STORE_FAILURE,
    UPDATE_STORE_INITIATED_CART,
    UPDATE_STORE_INITIATED_CART_POSTAL_CODE,
    UPDATE_STORE_INITIATED_CART_POSTAL_CODE_FAIL,
    CLEAR_POSTAL_CODE_ERROR_STATE_FOR_ONE_TIME_CART,
} from '../types/sharedCart/sharedCart.actionTypes.constant'

export const setGeneratedSharedCartLink = createAction(SET_GENERATED_SHARED_CART_LINK)<string>()

export const setGeneratedSharedCartError = createAction(SET_GENERATED_SHARED_CART_ERROR)<string>()

export const setCustomerSharedCart = createAction(SET_CUSTOMER_SHARED_CART)<ShareCartTemplate>()

export const setCustomerSharedCartError = createAction(SET_CUSTOMER_SHARED_CART_ERROR)<string>()

export const setCustomerSharedCartMergingError = createAction(SET_CUSTOMER_SHARED_CART_MERGING_ERROR)<string>()

export const setStoreInitiatedCartTemplate = createAction(SET_STORE_INITIATED_CART_TEMPLATE)<ShareCartTemplate>()

export const setStoreInitiatedCartTemplateError = createAction(SET_STORE_INITIATED_CART_TEMPLATE_ERROR)<string>()

export const setStoreInitiatedCart = createAction(SET_STORE_INITIATED_CART)<FilteredCartData>()

export const setStoreInitiatedCartError = createAction(SET_STORE_INITIATED_CART_ERROR)<boolean>()

export const resetStoreInitiatedCartData = createAction(RESET_STORE_INITIATED_CART_DATA)()

export const setOneTimeCartDeliveryModeChanged = createAction(SET_ONE_TIME_CART_DELIVERY_MODE_CHANGED)<boolean>()

export const setChangeDeliveryModeError = createAction(SET_CHANGE_DELIVERY_MODE_ERROR)<boolean>()

export const setSendLinkFormData = createAction(SET_SEND_LINK_FORM_DATA)<SendLinkFormData>()

export const setSharedCartLinkSent = createAction(SET_SHARED_CART_LINK_SENT)<boolean>()

export const setSharedCartLinkSendingError = createAction(SET_SHARED_CART_LINK_SENDING_ERROR)<string>()

export const setOneTimeCartStore = createAction(SET_ONE_TIME_CART_STORE)<StoreWithAvailability>()

export const setOneTimeCartStoreErrorAction = createAction(SET_ONE_TIME_CART_STORE_FAILURE)()

export const updateStoreInitiatedCart = createAction(UPDATE_STORE_INITIATED_CART)<FilteredCartData>()

export const updateStoreInitiatedCartPostalCode = createAction(
    UPDATE_STORE_INITIATED_CART_POSTAL_CODE,
)<FilteredCartData>()

export const updateStoreInitiatedCartPostalCodeFail = createAction(
    UPDATE_STORE_INITIATED_CART_POSTAL_CODE_FAIL,
)<CartResponseErrorDTO>()

export const clearPostalErrorStateForOneTimeCart = createAction(CLEAR_POSTAL_CODE_ERROR_STATE_FOR_ONE_TIME_CART)()
