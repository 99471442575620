import { useMemo } from 'react'
import { productSelector } from '../../redux/selectors/product.selectors'
import { wishlistItemsSelector } from '../../redux/selectors/wishlist.selectors'
import { userProfileVehicleSelector } from '../../redux/selectors/userProfile.selectors'
import { FitmentNote } from '@nl/lib'
import { WishlistProduct } from '../../redux/models/wishlist.interface'
import { CriticalFitmentProduct } from '../AutomotivePDP/AutomotiveBuyBox/AutomotiveBuyBox.type'
import { isAutomotivePDP } from '../Vehicles/Vehicle.helper'
import useExtractPCodeFromUrl from '../../utils/PDP/useExtractPCodeFromUrl.hook'
import { getCriticalFitmentProducts } from '../AutomotivePDP/AutomotiveBuyBox/AutomotiveBuyBox.helper'
import { miniPDPFlyoutDetailsSelector } from '../../redux/selectors/miniPDPFlyoutData.selectors'
import { useAppSelector } from '../../hooks/react-redux.hook'

/**
 * Custom hook to get product code for wishlist
 * @param {boolean} isAddedToWishlist - is product added to wishlist
 * @returns {string | string[]} product code for wishlist
 */
const useProductCodeForWishlist = (isAddedToWishlist: boolean) => {
    const { productData: productDataPDP, selectedProductCode: selectedProductCodePDP } = useAppSelector(productSelector)
    const { productDataList, currentCode, isFlyoutVisible } = useAppSelector(miniPDPFlyoutDetailsSelector)
    const wishlistItems = useAppSelector(wishlistItemsSelector)
    const { criticalFitment } = useAppSelector(userProfileVehicleSelector)

    const { variantsInfo } = productDataList[currentCode] || {}
    const { productData: productDataMiniPDP, selectedProductCode: selectedProductCodeMiniPDP } = variantsInfo || {}
    const productData = isFlyoutVisible ? productDataMiniPDP : productDataPDP
    const selectedProductCode = isFlyoutVisible ? selectedProductCodeMiniPDP : selectedProductCodePDP
    const isAutomotive = isAutomotivePDP(productData?.fitmentTypeCode, productData?.productWheelType)
    const { allSelectedSkus } = useExtractPCodeFromUrl()

    const criticalFitmentGrouped = useMemo(
        () =>
            isAutomotive
                ? getCriticalFitmentProducts(criticalFitment, allSelectedSkus)
                : ({} as CriticalFitmentProduct),
        [criticalFitment, isAutomotive, allSelectedSkus],
    )

    const { staggeredFitment: staggeredFitmentList, isStaggered } = criticalFitmentGrouped

    /**
     * Returns staggered wishlist array to add or remove from wishlist
     * @returns {string[]} staggered wishlist array
     */
    const getStaggeredSKUForWishList = (): string[] => {
        const wishListProductList = wishlistItems.products
        let staggeredSkuExistsList: string[] = []
        if (isAddedToWishlist) {
            staggeredFitmentList.forEach((staggeredFitment: FitmentNote) => {
                const wishListProduct = wishListProductList.find(
                    (wishListItem: WishlistProduct) => staggeredFitment.sku === wishListItem.code,
                )
                wishListProduct && staggeredSkuExistsList.push(wishListProduct.code)
            })
        } else {
            staggeredSkuExistsList = staggeredFitmentList?.map(
                (staggeredFitment: FitmentNote) => staggeredFitment.sku as string,
            )
        }
        return staggeredSkuExistsList
    }

    /**
     * Get product code for wishlist
     * @returns {string | string[]} single code or array
     */
    const getProductCodeForWishlist = (): string | string[] => {
        if (selectedProductCode && 'options' in productData) {
            return selectedProductCode
        } else if (isStaggered) {
            return getStaggeredSKUForWishList()
        } else {
            return productData.code
        }
    }

    return {
        getProductCodeForWishlist,
    }
}

export { useProductCodeForWishlist }
export default useProductCodeForWishlist
