import React from 'react'
import PropTypes from 'prop-types'

import { CartProps } from './Cart.type'
import Icon from '../Icon'
import { PREFIX } from '../config'

/**
 * Cart component
 * @param {CartProps} props
 * @return {JSX.Element} returns Cart component
 */
const Cart: React.FC<CartProps> = props => {
    const { cartUrl, a11yCart, ariaLabelCart, cartCount, maximumCount, path, featureType, isCartStoreSelectorModal } =
        props

    const renderCartIcon = () => {
        return (
            <>
                <Icon type="ct-cart" size="lg" path={path} decorative={true} />
                {cartCount > 0 && (
                    <>
                        {cartCount > maximumCount ? (
                            <span className={`${PREFIX}-cart-badge ${PREFIX}-cart-max`}>{maximumCount}+</span>
                        ) : (
                            <span className={`${PREFIX}-cart-badge`}>{cartCount}</span>
                        )}
                    </>
                )}
                {isCartStoreSelectorModal && <span className={`${PREFIX}-cart-badge ${PREFIX}-all-in-stock`}></span>}
            </>
        )
    }

    return (
        <div className={`${PREFIX}-cart`} data-testid="header-cart">
            {cartUrl ? (
                <a href={cartUrl} aria-label={ariaLabelCart} data-link-value={a11yCart} data-feature-type={featureType}>
                    {renderCartIcon()}
                </a>
            ) : (
                renderCartIcon()
            )}
        </div>
    )
}

Cart.propTypes = {
    cartUrl: PropTypes.string,
    a11yCart: PropTypes.string,
    cartCount: PropTypes.number,
    maximumCount: PropTypes.number,
    path: PropTypes.string,
    featureType: PropTypes.string,
    ariaLabelCart: PropTypes.string,
}

export default Cart
