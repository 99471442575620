import { RootState } from '../reducers'
import { FilteredCartData, CartResponseErrorDTO, ClickToPaySTHValidationDTO } from '../models/cart.interface'
import { NotificationSubscription } from '../reducers/orderConfirmation.reducer'

export const orderSelector = (state: RootState): FilteredCartData => state.orderItemsDetails.order

export const orderConfirmationAOAUrlEnabledSelectorOrderDetails = (state: RootState): boolean | undefined =>
    state.orderDetails.orderDetailsData?.cart?.deliveryPointOfService?.confirmationAoaUrlEnabled
export const orderConfirmationAOAUrlEnabledSelectorOrderConfirmation = (state: RootState): boolean | undefined =>
    state.orderItemsDetails.order?.cart?.deliveryPointOfService?.confirmationAoaUrlEnabled
export const orderConfirmationAOAUrlEnabledSelector = (state: RootState): boolean =>
    orderConfirmationAOAUrlEnabledSelectorOrderDetails(state) ??
    orderConfirmationAOAUrlEnabledSelectorOrderConfirmation(state) ??
    false

export const placeOrderConfirmationErrorSelector = (state: RootState): CartResponseErrorDTO =>
    state.orderItemsDetails.placeOrderConfirmationError
export const notificationSubscriptionSelector = (state: RootState): NotificationSubscription =>
    state.orderItemsDetails.notificationSubscription
export const clickToPayAddressValidationSelector = (state: RootState): ClickToPaySTHValidationDTO =>
    state.orderItemsDetails.clickToPayAddressValidation
export const clickToPayAddressValidationErrorSelector = (state: RootState): CartResponseErrorDTO =>
    state.orderItemsDetails.clickToPaySTHValidationError
