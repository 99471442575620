import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { PREFIX, previousElementName } from '../config'
import ReactModal from '../ReactModal'
import { modalAccessibilityHandler } from '../../utils/modalAccessibility'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'
import { InformativeModalProps } from './InformativeModal.types'
import Icon from '../Icon'

import Button from '../Button'
import Heading from '../Heading'

/**
 * Rich Text modal component
 * @return {JSX.Element} returns Overlay component
 */
const InformativeModal: React.FC<InformativeModalProps> = ({ ...props }): JSX.Element => {
    const { closeInformationModal, title, isModalOpen, children, headingVariant } = props

    const componentClassName = `${PREFIX}-informative-modal`

    const contentRef = useRef(null)

    // Below code is for handling the accessibility when clicked on close button
    const modalClassName = `${PREFIX}-react-modal`

    const previousElement: HTMLElement = document.querySelector(`button[${previousElementName}]`)

    /**
     * To handle accessibility on keyboard tab. Enables the modal accessibility when clicked on the trigger element.
     *
     * @return {void}
     */
    const closeHandler = (): void => {
        modalAccessibilityHandler({ modalOpen: false, modalClassName })
        closeInformationModal()
        if (previousElement) {
            previousElement.focus() // Highlight the initiated button
            previousElement.removeAttribute(previousElementName) // Removing it when user close it.
        }
    }

    useClickOutsideClose(contentRef, () => closeHandler(), isModalOpen, true)

    return (
        <ReactModal closeHandler={() => closeHandler()} isOpen={isModalOpen}>
            <div className={componentClassName} ref={contentRef}>
                <div className={`${componentClassName}__close-container`}>
                    <Button type="icon_button" onClick={() => closeHandler()}>
                        <Icon type="ct-close" size="lg" />
                    </Button>
                </div>

                <div className={` ${componentClassName}__container`}>
                    <Heading variant={headingVariant} componentClass={`${componentClassName}__header-text`}>
                        {title}
                    </Heading>
                    {children}
                </div>
            </div>
        </ReactModal>
    )
}

InformativeModal.propTypes = {
    closeInformationModal: PropTypes.func,
    isModalOpen: PropTypes.bool,
}

export default InformativeModal
