import React from 'react'
import { ButtonProps } from './Button.types'
import { Size } from '../../types'
import { ButtonDefaultProps, CommonButtonClass } from './Button.constants'
import Icon from '../Icon'

/**
 * Core ButtonV2 component
 * @param {ButtonProps} props ButtonV2 props
 * @param {React.ForwardedRef<HTMLButtonElement>} ButtonV2 with ref
 * @returns {JSX.Element} returns ButtonV2 component
 */
const ButtonV2: React.FC<ButtonProps> = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<ButtonProps>>(
    ({ ...props }, ref): JSX.Element => {
        const {
            children,
            icon,
            variant,
            reverse,
            type,
            disabled,
            customClass,
            color,
            onClick,
            id,
            onKeyDown,
            a11y,
            size,
            qm,
        } = props

        const variantClass = `${CommonButtonClass}--${variant} ${CommonButtonClass}--${variant}-${color}`
        const isReverse = reverse ? ` ${CommonButtonClass}--reverse` : '' // will only work if icon & children are available
        const customClassName = customClass ? ` ${customClass} ` : ''
        const sizeClass = size === Size.MINI ? ` ${CommonButtonClass}--${size}` : ''
        const qmData = qm ? { [`data-qm-${qm?.type}`]: qm?.value } : {}

        /**
         * Keyboard event on key down of any keys within button
         * @param {React.KeyboardEvent<HTMLButtonElement>} e - e for event for keydown
         * @returns {void} if there is a keydown prop, call function, otherwise null
         */
        const keyDownHandler = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
            if (onKeyDown) onKeyDown(e)
            else null
        }

        return (
            <button
                id={id}
                className={`${CommonButtonClass} ${variantClass}${customClassName}${isReverse}${sizeClass}`}
                ref={ref}
                disabled={disabled}
                type={type}
                onClick={onClick}
                onKeyDown={e => keyDownHandler(e)}
                aria-disabled={a11y?.disabled}
                aria-expanded={a11y?.expanded}
                aria-label={a11y?.label ? a11y?.label : ''}
                aria-controls={a11y?.controls}
                {...qmData}
                {...props}>
                {icon && <Icon size={icon.size} type={icon.type} />}
                {children && <span>{children}</span>}
            </button>
        )
    },
)

ButtonV2.displayName = 'ButtonV2'

ButtonV2.defaultProps = ButtonDefaultProps

export default ButtonV2
