export const SET_IS_INITIAL_DROP_DOWNS_FETCH_IN_PROGRESS = 'SET_IS_INITIAL_DROP_DOWNS_FETCH_IN_PROGRESS'
export const SET_IS_INITIAL_DROP_DOWNS_RESPONSE_DATA_FETCHED = 'SET_IS_INITIAL_DROP_DOWNS_RESPONSE_DATA_FETCHED'
export const SET_REMOVED_VEHICLE = 'SET_REMOVED_VEHICLE'
export const SET_VEHICLE_KNOWN_VALUES = 'SET_KNOWN_VALUES'
export const SET_VEHICLE_KNOWN_VALUES_AND_CLEAR_OTHER = 'SET_VEHICLE_KNOWN_VALUES_AND_CLEAR_OTHER'
export const SET_VEHICLE_BASE_ATTRIBUTES_ORDER = 'SET_VEHICLE_BASE_ATTRIBUTES_ORDER'
export const SET_CLEAR_TO_VEHICLE_ATTRIBUTE = 'SET_CLEAR_TO_VEHICLE_ATTRIBUTE'
export const SET_VEHICLE_RESPONSE = 'SET_VEHICLE_RESPONSE'
export const SET_VEHICLE_LAZY_LOAD_RESPONSE = 'SET_VEHICLE_LAZY_LOAD_RESPONSE'
export const SET_IS_VEHICLE_FETCH_IN_PROGRESS = 'SET_IS_VEHICLE_FETCH_IN_PROGRESS'
export const SET_SAVED_VEHICLE_CHECKED = 'SET_SAVED_VEHICLE_CHECKED'
export const SET_VEHICLE_DROPDOWN_LOCALIZATION = 'SET_VEHICLE_DROPDOWN_LOCALIZATION'
export const RECALL_VEHICLE_API = 'RECALL_VEHICLE_API'
export const API_ERROR = 'API_ERROR'

export const SET_IS_INITIAL_DROP_DOWNS_FETCH_IN_PROGRESS_FOR_SAVED_VEHICLE =
    'SET_IS_INITIAL_DROP_DOWNS_FETCH_IN_PROGRESS_FOR_SAVED_VEHICLE'
export const SET_IS_INITIAL_DROP_DOWNS_RESPONSE_DATA_FETCHED_FOR_SAVED_VEHICLE =
    'SET_IS_INITIAL_DROP_DOWNS_RESPONSE_DATA_FETCHED_FOR_SAVED_VEHICLE'
export const SET_REMOVED_VEHICLE_FOR_SAVED_VEHICLE = 'SET_REMOVED_VEHICLE_FOR_SAVED_VEHICLE'
export const SET_VEHICLE_KNOWN_VALUES_FOR_SAVED_VEHICLE = 'SET_KNOWN_VALUES_FOR_SAVED_VEHICLE'
export const SET_VEHICLE_KNOWN_VALUES_AND_CLEAR_OTHER_FOR_SAVED_VEHICLE =
    'SET_VEHICLE_KNOWN_VALUES_AND_CLEAR_OTHER_FOR_SAVED_VEHICLE'
export const SET_VEHICLE_BASE_ATTRIBUTES_ORDER_FOR_SAVED_VEHICLE = 'SET_VEHICLE_BASE_ATTRIBUTES_ORDER_FOR_SAVED_VEHICLE'
export const SET_CLEAR_TO_VEHICLE_ATTRIBUTE_FOR_SAVED_VEHICLE = 'SET_CLEAR_TO_VEHICLE_ATTRIBUTE_FOR_SAVED_VEHICLE'
export const SET_VEHICLE_RESPONSE_FOR_SAVED_VEHICLE = 'SET_VEHICLE_RESPONSE_FOR_SAVED_VEHICLE'
export const SET_VEHICLE_LAZY_LOAD_RESPONSE_FOR_SAVED_VEHICLE = 'SET_VEHICLE_LAZY_LOAD_RESPONSE_FOR_SAVED_VEHICLE'
export const SET_IS_VEHICLE_FETCH_IN_PROGRESS_FOR_SAVED_VEHICLE = 'SET_IS_VEHICLE_FETCH_IN_PROGRESS_FOR_SAVED_VEHICLE'
export const SET_SAVED_VEHICLE_CHECKED_FOR_SAVED_VEHICLE = 'SET_SAVED_VEHICLE_CHECKED_FOR_SAVED_VEHICLE'
export const SET_VEHICLE_DROPDOWN_LOCALIZATION_FOR_SAVED_VEHICLE = 'SET_VEHICLE_DROPDOWN_LOCALIZATION_FOR_SAVED_VEHICLE'
export const SET_IS_IGNORE_ATTRIBUTES_THRESHOLD = 'SET_IS_IGNORE_ATTRIBUTES_THRESHOLD'
