import { createAction } from 'typesafe-actions'

import { CartPageAnalyticsInfo } from '../../analytics/components/Cart/cartPage.type'
import { HttpRequestState } from '../../httpClient/client.type'
import {
    AddProductToCartGuestResponseDTO,
    CartResponseErrorDTO,
    FilteredCartData,
    AddServiceToCart,
    AddServiceSuccessToCart,
    ServiceDTO,
    RemoveCartItem,
    CartModificationDTO,
    PostalCodeData,
    CartItemsData,
    CartOrderEntries,
    SwitchFulfillmentType,
    sflAddAndMoveCart,
    MiniCartData,
    DeliveryOptionsCost,
    IAddToCartBundleRequestError,
    CartResponseErrorData,
} from '../models/cart.interface'
import {
    ADD_TO_CART_GUEST_SUCCESS,
    ADD_TO_CART_GUEST_FAILURE,
    RESET_CART_GUID_CREATED_FLAG,
    CLEAR_ADD_TO_CART_RESPONSE,
    ADD_SERVICE_TO_CART_SUCCESS,
    ADD_SERVICE_TO_CART_FAILURE,
    GET_CART_ITEMS_SUCCESS,
    GET_CART_ITEMS_FAILURE,
    UPDATE_CART_DATA_SUCCESS_ACTION,
    UPDATE_CART_DATA_FAILURE_ACTION,
    RESERVATION_REQUEST_IN_PROGRESS,
    RESERVATION_REQUEST_FINISHED,
    RESERVATION_REQUEST_RESET,
    SET_SERVICE,
    RESET_SERVICE,
    REMOVE_CART_ITEM_SUCCESS,
    REMOVE_CART_FAILURE,
    UPDATE_DELIVERY_METHOD_SUCCESS_ACTION,
    UPDATE_DELIVERY_METHOD_FAILURE_ACTION,
    CART_DELIVERY_MODE_CHANGE_FAILURE,
    CART_DELIVERY_MODE_CHANGE_RESET,
    UPDATE_POSTAL_CODE_SUCCESS,
    UPDATE_POSTAL_CODE_FAILURE,
    SET_FULFILLMENT_OPTION_CLICK,
    UPDATE_POSTAL_CODE_ERROR,
    GET_POSTAL_CODE_DATA_ERROR,
    GET_POSTAL_CODE_DATA_SUCCESS,
    RESET_CART_VALIDATIONS,
    UPDATE_ANALYTICS_INFO,
    GET_MERGE_CART,
    SET_SELECTED_AUTHCART,
    SET_XHR_INFO_GET_CART,
    SET_ERROR_INFO_CART,
    MERGE_CART_SUCCESS,
    GET_CART_ITEMS_AUTH,
    POSTAL_CODE_DISPATCH_STARTED,
    RESET_REMOVE_SUCCESS,
    UPDATE_POSTAL_CODE_PARTIAL_SUCCESS,
    SET_SFL_TOAST_VARIABLES,
    SHIPPING_ESTIMATION_IN_PROGRESS,
    SHIPPING_ESTIMATION_SUCCESS,
    SHIPPING_ESTIMATION_ERROR,
    RESET_SHIPPING_ESTIMATION,
    APPLY_PROMO_CODE_SUCCESS,
    APPLY_PROMO_CODE_ERROR,
    RESET_PROMO_CODE_DATA,
    CART_PROMO_CODE_ERROR_TOAST_MSG,
    CLEAR_ADD_TO_CART_ERROR_DATA,
    RESET_POSTAL_CODE_CHANGE_SUCCESS,
    RESET_SHOW_AVAILABILITIES_CHANGE_TOAST,
    RESET_INIT_CHECKOUT_ERROR,
    INIT_CHECKOUT_FAILURE,
    GET_MINI_CART_ITEMS_SUCCESS,
    GET_CHECKOUT_CART_ITEMS_SUCCESS,
    SET_EXPRESS_DELIVERY_SELECTION,
    SET_EXPRESS_DELIVERY_PO_OUT_OF_RANGE,
    SET_SHOPPING_CART_LIMIT_ITEMS_FAILURE,
    CLEAR_SHOPPING_CART_LIMIT_ITEMS_FAILURE,
    SHOW_ESTIMATE_SHIPPING_MODAL,
    SET_NO_POSTAL_CODE_ERROR,
    SET_CHECKOUT_AUTH_CART_LOADED,
    ADD_TO_CART_BUNDLE_SUCCESS,
    ADD_TO_CART_BUNDLE_FAILURE,
    RESET_ADD_TO_CART_BUNDLE_STATE,
    REMOVE_CART_BALLOON_PACKAGE_ITEM_SUCCESS,
    RESET_BALLOON_PACKAGE_ITEM_REMOVE_SUCCESS,
    SET_CHECK_ERRORS_IN_CART,
} from '../types/cart/cart.actionTypes.constant'

export const addToCartGuestSuccessAction = createAction(ADD_TO_CART_GUEST_SUCCESS)<AddProductToCartGuestResponseDTO>()

export const addToCartGuestFailureAction = createAction(ADD_TO_CART_GUEST_FAILURE)<CartResponseErrorDTO>()

export const clearAddToCartErrorDataAction = createAction(CLEAR_ADD_TO_CART_ERROR_DATA)()

export const resetCartGUIDCreatedFlagAction = createAction(RESET_CART_GUID_CREATED_FLAG)()

export const clearAddToCartResponse = createAction(CLEAR_ADD_TO_CART_RESPONSE)()

export const addServiceToCartSuccessAction = createAction(ADD_SERVICE_TO_CART_SUCCESS)<AddServiceSuccessToCart>()

export const addServiceToCartFailureAction = createAction(ADD_SERVICE_TO_CART_FAILURE)<AddServiceToCart>()

export const getCartItemsSuccessAction = createAction(GET_CART_ITEMS_SUCCESS)<FilteredCartData>()

export const getMiniCartItemsSuccessAction = createAction(GET_MINI_CART_ITEMS_SUCCESS)<MiniCartData>()

export const getCheckoutCartItemsSuccessAction = createAction(GET_CHECKOUT_CART_ITEMS_SUCCESS)<FilteredCartData>()

export const getCartItemsFailureAction = createAction(GET_CART_ITEMS_FAILURE)<CartResponseErrorDTO>()

export const updateCartDataSuccessAction = createAction(UPDATE_CART_DATA_SUCCESS_ACTION)<FilteredCartData>()

export const updateCartDataFailureAction = createAction(UPDATE_CART_DATA_FAILURE_ACTION)<CartResponseErrorDTO>()

export const reservationRequestInProgress = createAction(RESERVATION_REQUEST_IN_PROGRESS)()

export const reservationRequestFinished = createAction(RESERVATION_REQUEST_FINISHED)()

export const resetReservationVariables = createAction(RESERVATION_REQUEST_RESET)()

export const setSelectedServiceAction = createAction(SET_SERVICE)<ServiceDTO[]>()

export const resetSelectedServiceAction = createAction(RESET_SERVICE)()

export const removeCartItemSuccessAction = createAction(REMOVE_CART_ITEM_SUCCESS)<FilteredCartData>()

export const removeCartBalloonPackageItemSuccessAction = createAction(
    REMOVE_CART_BALLOON_PACKAGE_ITEM_SUCCESS,
)<FilteredCartData>()

export const resetRemoveSuccess = createAction(RESET_REMOVE_SUCCESS)()

export const resetBalloonPackageItemRemoveSuccess = createAction(RESET_BALLOON_PACKAGE_ITEM_REMOVE_SUCCESS)()

export const removeCartItemFailureAction = createAction(REMOVE_CART_FAILURE)<RemoveCartItem>()

export const changeCartDeliveryModeFailure = createAction(CART_DELIVERY_MODE_CHANGE_FAILURE)<CartResponseErrorDTO>()

export const changeCartDeliveryModeReset = createAction(CART_DELIVERY_MODE_CHANGE_RESET)()

export const updateDeliveryMethodSuccessAction = createAction(UPDATE_DELIVERY_METHOD_SUCCESS_ACTION)<FilteredCartData>()

export const updateDeliveryMethodFailureAction = createAction(
    UPDATE_DELIVERY_METHOD_FAILURE_ACTION,
)<CartResponseErrorDTO>()

export const updatePostalCodeSuccessAction = createAction(UPDATE_POSTAL_CODE_SUCCESS)<FilteredCartData, boolean>()

export const updatePostalCodePartialSuccessAction = createAction(UPDATE_POSTAL_CODE_PARTIAL_SUCCESS)<FilteredCartData>()

export const updatePostalCodeFailureAction = createAction(UPDATE_POSTAL_CODE_FAILURE)<CartResponseErrorDTO>()

export const updatePostalCodeErrorAction = createAction(UPDATE_POSTAL_CODE_ERROR)<CartResponseErrorDTO>()

export const setSelectedFulFillmentOption = createAction(SET_FULFILLMENT_OPTION_CLICK)<SwitchFulfillmentType>()

export const getPostalCodeDataAction = createAction(GET_POSTAL_CODE_DATA_SUCCESS)<PostalCodeData>()

export const getPostalCodeDataError = createAction(GET_POSTAL_CODE_DATA_ERROR)<CartModificationDTO>()

export const resetPostalCodeChangeSuccess = createAction(RESET_POSTAL_CODE_CHANGE_SUCCESS)()

export const updatePostalCodeDispatchStartedAction = createAction(POSTAL_CODE_DISPATCH_STARTED)<boolean>()

export const resetCartValidationsAction = createAction(RESET_CART_VALIDATIONS)()

export const updateAnalyticsInfo = createAction(UPDATE_ANALYTICS_INFO)<Partial<CartPageAnalyticsInfo>>()

export const getMergeCartSuccessAction = createAction(GET_MERGE_CART)<CartItemsData>()

export const setSelectedAuthCartItems = createAction(SET_SELECTED_AUTHCART)<CartOrderEntries[]>()

export const setXhrInfoForGetCart = createAction(SET_XHR_INFO_GET_CART)<HttpRequestState>()

export const setErrorInfoOnCart = createAction(SET_ERROR_INFO_CART)<boolean[]>()

export const getCartItemsForAuthForMergeSuccessAction = createAction(GET_CART_ITEMS_AUTH)<FilteredCartData>()

export const mergeCartSuccessAction = createAction(MERGE_CART_SUCCESS)<boolean>()

export const setSflToastVariables = createAction(SET_SFL_TOAST_VARIABLES)<sflAddAndMoveCart>()

export const shippingEstimationInProgress = createAction(SHIPPING_ESTIMATION_IN_PROGRESS)<boolean>()

export const shippingEstimationSuccess = createAction(SHIPPING_ESTIMATION_SUCCESS)<{
    deliveryCosts: DeliveryOptionsCost[]
}>()

export const shippingEstimationError = createAction(SHIPPING_ESTIMATION_ERROR)<CartResponseErrorDTO>()

export const resetShippingEstimation = createAction(RESET_SHIPPING_ESTIMATION)()

export const applyPromoCodeSuccessAction = createAction(APPLY_PROMO_CODE_SUCCESS)<string>()

export const applyPromoCodeErrorAction = createAction(APPLY_PROMO_CODE_ERROR)<CartResponseErrorData>()

export const resetPromoCodeDataAction = createAction(RESET_PROMO_CODE_DATA)()

export const setCartPromoCodeErrorToastMsg = createAction(CART_PROMO_CODE_ERROR_TOAST_MSG)<string>()

export const resetShowAvailabilitiesChangeToast = createAction(RESET_SHOW_AVAILABILITIES_CHANGE_TOAST)()

export const resetInitCheckoutError = createAction(RESET_INIT_CHECKOUT_ERROR)()

export const initCheckoutFailure = createAction(INIT_CHECKOUT_FAILURE)()

export const setExpressDeliverySelection = createAction(SET_EXPRESS_DELIVERY_SELECTION)<boolean>()

export const renderEstimateShippingModal = createAction(SHOW_ESTIMATE_SHIPPING_MODAL)<boolean>()

export const setNoPostalCodeError = createAction(SET_NO_POSTAL_CODE_ERROR)<boolean>()

export const setExpressDeliveryPostalCodeOutOfRange = createAction(SET_EXPRESS_DELIVERY_PO_OUT_OF_RANGE)<boolean>()

export const setShoppingCartLimitItemsFailure = createAction(
    SET_SHOPPING_CART_LIMIT_ITEMS_FAILURE,
)<CartResponseErrorDTO>()

export const clearShoppingCartLimitItemsFailure = createAction(CLEAR_SHOPPING_CART_LIMIT_ITEMS_FAILURE)<void>()

export const setCheckoutAuthCartLoaded = createAction(SET_CHECKOUT_AUTH_CART_LOADED)<boolean>()
export const addToCartBundleSuccess = createAction(ADD_TO_CART_BUNDLE_SUCCESS)<void>()
export const addToCartBundleFailure = createAction(
    ADD_TO_CART_BUNDLE_FAILURE,
    (payload: IAddToCartBundleRequestError) => payload,
)<IAddToCartBundleRequestError>()
export const resetAddToCartBundleState = createAction(RESET_ADD_TO_CART_BUNDLE_STATE)<void>()
export const setCheckErrorsInCart = createAction(SET_CHECK_ERRORS_IN_CART)<() => boolean>()
