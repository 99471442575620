// Custom Components
import GigyaScreen from '../components/GigyaScreen'
import FeaturedProductListComponent from '../components/FeaturedProductList'
import ToastMessageComponent from '../components/ToastMessage'
import { CertonaInit } from '../components/CertonaInit'
import { TmxInit } from '../components/TmxInit'
import OrderUpdatesSubscription from '../components/OrderUpdatesSubscription'
import DynamicFeaturedList from '../components/DynamicFeaturedList'
import LanguageToggleHandler from '../components/AccountsPencilBanner/PencilBanner/LanguageToggle/LanguageToggleHandler'
import PageInit from '../components/PageInit'
import { FeaturedProductListDynamic } from '../components/FeaturedProductListDynamic'
import StoreSelectorModalComp from '../components/StoreSelectorModal'
import ReferenceComponentWrapper from '../components/ReferenceComponentWrapper/ReferenceComponentWrapper'
import OrderExpiredError from '../components/OrderDetails/OrderExpired/OrderExpiredError'
import Video from '../components/Video'
import { FullPageSpinnerComponent } from '../components/FullPageSpinnerComponent'
import AutoServiceButton from '../components/AutoServiceButton'
import BackToTop from '../components/BackToTopButton/BackToTop'
import EmailOfferActivation from '../components/EmailOfferActivation/EmailOfferActivation'
import OpenGraphImageTagComponent from '../components/OpenGraphImageTagComponent'
import SignifydInit from '../components/SignifydInit'
import BrowseOnlyInformationBanner from '../components/BrowseOnlyInformationBanner'
import BalanceCheckerWrapper from '../components/BalanceChecker/BalanceCheckerWrapper'
import DynamicVehicleSeoCopyBlock from '../components/DynamicVehicleSeoCopyBlock'
import HallOfFame from '../components/AutomotivePLP/FeaturedProducts/HallOfFame'
import ShoppableComponent from '../components/ShoppableComponent/ShoppableComponent'
import ShopTheLookComponent from '../components/ShopTheLook/ShopTheLookComponent'
import KeepShoppingWidget from '../components/KeepShoppingWidget/KeepShoppingWidget'
import StackedCategoryListNew from '../components/StackedCategoryListNew/StackedCategoryListNew'
import { MiniPDP } from '../components/MiniPDP'
import BrazeContentCard from '../components/BrazeContentCard/BrazeContentCard'

export const staticComponentMap: {
    [key: string]: unknown
} = {
    GigyaScreen: GigyaScreen,
    ThreatMetrixTag: TmxInit,
    SignifydTag: SignifydInit,
    FeaturedProductListComponent,
    ToastMessage: ToastMessageComponent,
    MiniPDP,
    CertonaInit,
    DynamicFeaturedList,
    ReferenceComponent: ReferenceComponentWrapper,
    LanguageToggleHandler,
    PageInit,
    FeaturedProductListDynamic,
    StoreSelectorModalComp,
    OrderExpiredError,
    Video,
    FullPageSpinnerComponent,
    OrderUpdatesSubscription,
    AutoServiceButton,
    BackToTop,
    EmailOfferActivation,
    OpenGraphImageTagComponent,
    BrowseOnlyInformationBanner,
    BalanceChecker: BalanceCheckerWrapper,
    DynamicVehicleSeoCopyBlock,
    FeaturedProducts: HallOfFame,
    ShoppableComponent,
    ShopTheLook: ShopTheLookComponent,
    KeepShoppingWidget,
    StackedCategoryListNew,
    BrazeContentCard,
}
