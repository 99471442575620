import { Choose, Predicate, Supplier, afterDefault, afterThen, afterWhen } from './choose.type'

/**
 * Gets method chain what allow to select one option by condition without switch complexity limitation
 * @returns {Choose}
 */
export const choose = (): Choose => {
    let defaultValue = null
    let chosen: Supplier<unknown>
    let isChosen = false
    let whenHappens = false

    /**
     * Returns result value according to condition results in when
     * @returns {T}
     */
    function get<T>() {
        return (isChosen ? chosen?.() : defaultValue) as unknown as T
    }

    /**
     * Registers supplier for default cases
     * @param {Supplier} supplier - zero params function to return some result
     * @returns {afterDefault}
     */
    function defaultThen<T>(supplier: Supplier<T>): afterDefault<T> {
        defaultValue = supplier
        return { get }
    }

    /**
     * Registers condition of using next function in then
     * @param {Predicate} predicate
     * @returns
     */
    function when(predicate: Predicate): afterWhen {
        whenHappens = whenHappens || predicate()
        return { then }
    }

    /**
     * Registers supplier for cases in when before this call
     * @param {Supplier} supplier - zero params function to return some result
     * @returns {afterThen}
     */
    function then<T>(supplier: Supplier<T>): afterThen<T> {
        if (!isChosen && whenHappens) {
            chosen = supplier
            isChosen = true
        }
        return { when, get, defaultThen }
    }

    return { when }
}

export default choose
