/**
 * Function to remove all tags from text by name.
 * @param {string} text with tags
 * @param {string} tagName to remove (for example 'p', 'span', etc)
 * @returns {string} text without tags or original text
 */
export const removeHtmlTagsFromStringByName = (text: string, tagName: string): string => {
    const regex = new RegExp(`<${tagName}(\\s+[^>]*)?>|</${tagName}>`, 'g')
    return text ? text.replace(regex, '') : text
}
