import { areAllParamsValid, isAtleastOneParamValid, plaCarouselSchemaId } from '@nl/lib'
import { pageTypes, PREFIX } from '../../config'
import { gclid, google, social, utmMedium } from '../../globalConstants'
import { ProductCardType } from '../../redux/models/recommendations.interface'
import getPageType from '../../utils/getPageType'

/**
 * function to return boolean to show recommendation component
 * @param {ProductCardType[]} recommendationsData array of recommendation data
 * @param {number} minimumNumberOfRecommendationsPC minimum number of recommendation
 * @param {boolean} showSkeleton boolean to show recommendation skeleton
 * @return {boolean} if true it shows recommendation
 */
export const shouldShowComponent = (
    recommendationsData: ProductCardType[],
    minimumNumberOfRecommendationsPC: number,
    showSkeleton: boolean,
) => {
    const hasMinimunNumberOfRecommendations =
        recommendationsData &&
        recommendationsData.length > 0 &&
        recommendationsData.length >= minimumNumberOfRecommendationsPC
    return hasMinimunNumberOfRecommendations && !showSkeleton
}

/**
 * function to return string for pla carousel class
 * @param {ProductCardType[]} recommendationsData array of recommendation data
 * @param {number} minimumNumberOfRecommendationsPC minimum number of recommendation
 * @param {boolean} showSkeleton boolean to show recommendation skeleton
 * @param {string} schemaId boolean to show recommendation skeleton
 * @return {boolean} add class name for pla carousel
 */
export const plaCarouselClass = (
    recommendationsData: ProductCardType[],
    minimumNumberOfRecommendationsPC: number,
    showSkeleton: boolean,
    schemaId: string,
) => {
    return shouldShowComponent(recommendationsData, minimumNumberOfRecommendationsPC, showSkeleton) &&
        plaCarouselSchemaId.includes(schemaId)
        ? `${PREFIX}-pla-carousel`
        : ''
}

/**
 * function to return boolean for show and hide of recommendation component. This will also hide skeleton
 * @param {boolean} hideRecommendation boolean value for payment info page if information available
 * @param {boolean} orderExpired boolean value for payment info page if information available
 * @param {boolean} hidePlaCarouselComponent boolean to show/hide PLA recommendation skeleton
 * @param {boolean} hideCspComponent boolean to show/hide compare similar product table
 * @returns {boolean} boolean to show/hide recommendation skeleton
 */
export const hideRecommendationComponent = (
    hideRecommendation: boolean,
    orderExpired: boolean,
    hidePlaCarouselComponent: boolean,
    hideCspComponent: boolean,
) => {
    return !hideRecommendation && !orderExpired && !hidePlaCarouselComponent && !hideCspComponent
}

/**
 * function to return true for pdp pla carousel with gclid or utm medium value as social.
 * And for cat1, cat2 pla carousel with doc referrer as google
 * @returns {boolean} boolean
 */
export const showPLACarousel = (): boolean => {
    const currentPageType = getPageType()
    const [cat1, cat2] = pageTypes.categoryPages

    const isCategoryPage = currentPageType === cat1 || currentPageType === cat2
    const isPdpPage = currentPageType === pageTypes.productPage

    const params = new URLSearchParams(window.location.search)
    const showPlaInPdp = isAtleastOneParamValid(params.has(gclid), params.get(utmMedium) === social)

    const isUrlHasPlaParam = areAllParamsValid(isPdpPage, showPlaInPdp as boolean)
    const isDocReferrerIsGoogle = document.referrer?.includes(google)

    const showCatPla = areAllParamsValid(isCategoryPage, isDocReferrerIsGoogle)

    return isUrlHasPlaParam || showCatPla
}
