import { ReactNode } from 'react'

export enum BannerType {
    Fit = 'Fit',
    PartialFit = 'PartialFit',
    NotFit = 'NotFit',
    Unknown = 'UnKnown',
}

export enum productWheelTypes {
    Tire = 'TIRE',
    Wheel = 'WHEEL',
    WinterTire = 'WINTER_TIRE',
}

export enum CtaType {
    normal = 'normal',
    dropDown = 'dropdown',
}

export interface Cta {
    ctaType: CtaType
    text: string
    AfterActionUi: React.FC
    uiProps: any
    ctaAction: () => void
    ctaId?: string
}
export interface VehicleBannerProps {
    bannerType: BannerType
    title: string
    subTitle: string
    showSubtitle: boolean
    ctas: Cta[]
    subCtas: Cta[]
    defaultCtasState: any
    showVehicleInfo?: () => ReactNode
    isNotShowIcon?: boolean
    isAdditionalInfo?: boolean
    closeClickHandler?: (isOpen: boolean) => void
    pageType?: string
    hideVehicleSelectorBannerState?: boolean
    isPackageFlow?: boolean
    renderAutoAppointmentButton: () => JSX.Element | null
    setDefaultSelectedCard?: React.Dispatch<React.SetStateAction<string>>
    isTiresDataAvailable?: boolean
    showPackageInfoPopup: boolean
    closeManageVehiclesDropdown?: () => void
    defaultImage?: string
    vehicleImage?: string
    vehicleImageAltText?: string
    shoppingForTitle?: string
    vehicleDetails?: string
    mayNotFitLabel?: string
}
