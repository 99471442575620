import React from 'react'

export interface HeadingType {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | ISize
    variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | IVariant
    componentClass?: string
    children?: React.ReactNode
}

export enum ISize {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
    XXL = '2xl',
    XXXL = '3xl',
    XXXXL = '4xl',
}

export enum IVariant {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6',
}
