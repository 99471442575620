import { ActionType, getType } from 'typesafe-actions'

import {
    fetchSponsoredAdsErrorAction,
    fetchSponsoredAdsRequestAction,
    fetchSponsoredAdsSuccessAction,
    setSponsoredAdsLoading,
    setSponsoredAdsCarouselProducts,
    setSponsoredAds,
} from '../actionCreators/sponsoredAds.actionCreators'
import * as actions from '../actionCreators/sponsoredAds.actionCreators'
import { SponsoredAdsState } from '../../services/criteoService/criteo.interface'
import { initialPlacementObj } from '../../services/criteoService/criteoService.constants'

type Action = ActionType<typeof actions>

export const initialState: SponsoredAdsState = {
    inGrid: initialPlacementObj,
    carousel: initialPlacementObj,
    carouselProducts: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
}

export const sponsoredAdsReducer = (state: SponsoredAdsState = initialState, action: Action): SponsoredAdsState => {
    switch (action.type) {
        case getType(fetchSponsoredAdsRequestAction): {
            // Reinitialize state for each sponsored ads request
            return { ...initialState, isLoading: true }
        }
        case getType(fetchSponsoredAdsErrorAction): {
            return { ...state, isLoading: false, isError: true, isSuccess: false }
        }
        case getType(fetchSponsoredAdsSuccessAction): {
            const { inGrid, carousel } = action.payload.placements

            return {
                ...state,
                inGrid,
                carousel,
                isLoading: false,
                isError: false,
                isSuccess: true,
            }
        }
        case getType(setSponsoredAds): {
            const { inGrid, carousel } = action.payload.placements

            return { ...state, inGrid, carousel, isLoading: false, isError: false, isSuccess: true }
        }
        case getType(setSponsoredAdsLoading): {
            return {
                ...state,
                isLoading: action.payload,
            }
        }
        case getType(setSponsoredAdsCarouselProducts): {
            return {
                ...state,
                carouselProducts: action.payload,
            }
        }
        default:
            return state
    }
}
