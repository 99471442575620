/**
 * This function results opposite to its name, we will need to improve name of the function in future
 * function to check if the given value is undefined or null
 * @param {unknown} value
 * @return {boolean}
 */

export const checkNotNullAndUndefined = (value: unknown): boolean => {
    return value === null || value === undefined || value === 'undefined'
}

/**
 * function to return processed value for analytics object
 * @param {string}value
 * @return {string}
 */

export const checkValueOrNull = (value: string | undefined | null): string => {
    return value === undefined || value === null || value === '' || value === 'undefined' ? 'null' : value
}

/**
 * function to return zero if value is null or undefined
 * @param {string}value
 * @return {string}
 */

export const checkNumberNotNullAndUndefined = (value: number | undefined | null): number => {
    return value === undefined || value === null ? 0 : value
}

/**
 * function for changing string 'null' to undefined type
 * @param {string}value
 * @param {boolean}shouldTransform
 * @return {string | undefined}
 */

export const changeNullToUndefined = (value: string, shouldTransform: boolean): string | undefined => {
    return shouldTransform && value === 'null' ? undefined : value
}

/**
 * function to check whether "value" is empty, null, undefined, ''
 * @param {string}value
 * @return {string}
 */

export const checkEmptyValue = (value: string | undefined | null): boolean => {
    return value === undefined || value === 'undefined' || value === null || value === 'null' || value === ''
}

/**
 * function to check if value is undefined
 * @param {unknown} value
 * @return {boolean}
 */
export const checkUndefined = (value: unknown): boolean => {
    return value === undefined
}
