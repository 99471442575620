import {
    FilteredCartData,
    CartOrderEntries,
    AddProductToCartGuestRequestDTO,
    CartModificationsDTO,
    ItemAvailability,
} from '../../redux/models/cart.interface'

import { AutoPackageModalData, MultiSelectItem, ProductDataObj } from './ProductCard.types'
import { productWheelTypes } from '../../globalConstants/global.constant'
import { isTirePDP, isWheelPDP } from '../Vehicles/Vehicle.helper'
import { removeBlankSpaceFromString } from '../../utils/removeBlankSpaceFromString'
import { MagicNumber } from '../../analytics/analytics.type'
import {
    brazeCustomEvents,
    brazeCustomEventsPages,
    checkDataLength,
    getSourceParam,
    isArrayNotEmpty,
    replaceStrWithDynamicVal,
    Vehicle,
    iProductFulfillment,
} from '@nl/lib'
import { PREFIX, pageTypes } from '../../config'
import getPageType from '../../utils/getPageType'
import appCacheService from '../../utils/appCacheService'
import { FulfillmentMethods } from '../ShoppingCart/ShoppingCart.type'
import localStorageService from '../../utils/localStorageService'
import { ProductResponseData, ProductSku } from '../../redux/models/product.interface'
import { addToCartAnalytics } from '../../analytics/components/addToCartAnalytics'
import { analyticsAttributes } from '../../globalConstants'
import { VehicleState } from '../../redux/reducers/user.profile.reducer'
import { createVehiclePayload } from '../BuyBox/BuyBox.helper'
import { ProductDataType, QuickLink, CompareProductDataType } from '../../redux/models/productData.interface'
import brazeService from '../../services/brazeService/braze.service'
import { getOfferBannerId } from '../../utils/getCurrentBannerId'
import getBrazeEventName from '../../utils/getBrazeEventName'
import { ProductCardType } from '../../redux/models/recommendations.interface'
import { compareProductList } from './ProductCard.constant'

/**
 * function to check if current product wheel type is same plp page tire type
 * @param {string | undefined} currentWheelType
 * @param {string} productWheelType
 *
 * @return {boolean}
 */
const checkCurrentWheelType = (currentWheelType: string | undefined, productWheelType: string): boolean => {
    if (productWheelType === productWheelTypes.Wheel) {
        return currentWheelType === productWheelType
    } else {
        return currentWheelType === productWheelType || currentWheelType === productWheelTypes.WinterTire
    }
}

/**
 * function to add tires for wheel plp and vice versa
 * @param {AutoPackageModalData[]} autopackageUpdatedList
 * @param {string} productWheelTypeValue
 *
 * @return {AutoPackageModalData[]}
 */
const checkWheelType = (
    autopackageUpdatedList: AutoPackageModalData[],
    productWheelTypeValue: string,
): AutoPackageModalData[] => {
    return autopackageUpdatedList?.filter((categoryType: AutoPackageModalData) =>
        checkCurrentWheelType(categoryType.productWheelType?.toUpperCase(), productWheelTypeValue),
    )
}
/**
 * function to return productWheelTypes string
 * @param {string | undefined} productWheelType
 * @return {string | undefined} returns TIRE or WHEEL
 */
const wheelTypeValue = (productWheelType: string | undefined): string | undefined => {
    if (isTirePDP(productWheelType)) return productWheelTypes.Tire
    if (isWheelPDP(productWheelType)) return productWheelTypes.Wheel
}

/**
 * function to add product in an array which are tire for wheel plp and vice versa
 * @param {AutoPackageModalData[]} autopackageUpdatedList
 * @param {string | undefined} productWheelType
 *
 * @return {AutoPackageModalData[]}
 */
const wheelOrTireModalData = (
    autopackageUpdatedList: AutoPackageModalData[],
    productWheelType: string | undefined,
): AutoPackageModalData[] => {
    let wheelOrTireList: AutoPackageModalData[] = []
    if (wheelTypeValue(productWheelType)) {
        wheelOrTireList = checkCurrentWheelType(wheelTypeValue(productWheelType), productWheelTypes.Wheel)
            ? checkWheelType(autopackageUpdatedList, productWheelTypes.Tire)
            : checkWheelType(autopackageUpdatedList, productWheelTypes.Wheel)
    }
    return wheelOrTireList
}

/**
 * function to add isSelected flag. Flag will te true foe selected product and by default first product will be selected
 * @param {AutoPackageModalData[]} autoPackageData
 * @param {number} currentAutoPackIndex
 * @param {string | undefined} productWheelType
 *
 * @return {AutoPackageModalData[]}
 */
const updatedWheelOrTireList = (
    autoPackageData: AutoPackageModalData[],
    currentAutoPackIndex: number,
    productWheelType: string | undefined,
): AutoPackageModalData[] => {
    // TODO: func wheelOrTireModalData is redundant as it was used to create autoPackageData
    return wheelOrTireModalData(autoPackageData, productWheelType)?.map(
        (wheelOrTireData: AutoPackageModalData, index: number) => ({
            ...wheelOrTireData,
            isSelected: index === currentAutoPackIndex,
        }),
    )
}

/**
 * function to add product which are part of both complete and incomplete auto-package.
 * @param {AutoPackageModalData[]} sameVehicleProduct
 *
 * @return {AutoPackageModalData[]}
 */
export const getAutoPackageList = (sameVehicleProduct: AutoPackageModalData[]): AutoPackageModalData[] => {
    return sameVehicleProduct?.filter((autopackage: AutoPackageModalData) => !!autopackage.packageId)
}

/**
 * function returns auto-package modal data with same vehicle name as selected vehicle
 * @param {CartOrderEntries[]} orderEntries
 * @param {string} vehicleInfo
 *
 * @return {AutoPackageModalData[]}
 */
export const getSameVehicleProduct = (
    orderEntries: CartOrderEntries[] = [],
    vehicleInfo: string,
): AutoPackageModalData[] => {
    const sameVehicleProduct: AutoPackageModalData[] = []
    orderEntries?.forEach((currentCartData: CartOrderEntries) => {
        if (currentCartData.vehicleInformation) {
            const vehicleNameList = [
                currentCartData?.vehicleInformation?.year,
                currentCartData.vehicleInformation.make,
                currentCartData.vehicleInformation.model,
                currentCartData.vehicleInformation.body,
                currentCartData.vehicleInformation.options,
            ]
            const currentVehicleName = vehicleNameList.join(' ')

            if (
                removeBlankSpaceFromString(vehicleInfo).toLowerCase() ===
                removeBlankSpaceFromString(currentVehicleName).toLowerCase()
            ) {
                sameVehicleProduct.push(currentCartData as AutoPackageModalData)
            }
        }
    })
    return sameVehicleProduct
}

/**
 * function returns auto-package modal data
 * @param {FilteredCartData} cartItemsData
 * @param {string} vehicleInfo
 * @param {number} currentAutoPackIndex
 * @param {string | undefined} productWheelType
 *
 * @return {AutoPackageModalData[]}
 */
export const openAutoPackageModal = (
    cartItemsData: FilteredCartData,
    vehicleInfo: string,
    currentAutoPackIndex: number,
    productWheelType: string | undefined,
): AutoPackageModalData[] => {
    const sameVehicleProduct = getSameVehicleProduct(cartItemsData.cart?.orderEntries, vehicleInfo)
    const autopackageUpdatedList = sameVehicleProduct?.filter(
        // TODO: looks like getAutoPackageList function is redundant and this part can be simplified
        (item: AutoPackageModalData) => !getAutoPackageList(sameVehicleProduct).includes(item),
    )
    return updatedWheelOrTireList(
        wheelOrTireModalData(autopackageUpdatedList, productWheelType),
        currentAutoPackIndex,
        productWheelType,
    )
}

/**
 * function returns incomplete auto-package list from same vehicle cart array.
 * @param {AutoPackageModalData[]} sameVehicleProduct
 *
 * @return {AutoPackageModalData[]}
 */
export const incompletePackageList = (sameVehicleProduct: AutoPackageModalData[]): AutoPackageModalData[] => {
    const autoPackageIncompleteList: AutoPackageModalData[] = []
    const packageIdOccurance: { [key: string]: number } = {}

    sameVehicleProduct?.forEach((vehicleProduct: AutoPackageModalData): void => {
        // packageIdString constant is created to maintain the order of package id in object
        const packageIdString = `a-${String(vehicleProduct.packageId)}`
        packageIdOccurance[packageIdString] =
            Number(packageIdOccurance[packageIdString]) + MagicNumber.ONE || MagicNumber.ONE
    })

    for (const packageIdValue in packageIdOccurance) {
        if (packageIdOccurance[packageIdValue] === MagicNumber.ONE) {
            autoPackageIncompleteList.push(
                sameVehicleProduct.find(
                    (product: AutoPackageModalData) =>
                        product.packageId === packageIdValue.split('a-')[MagicNumber.ONE],
                ) as AutoPackageModalData,
            )
        }
    }
    return autoPackageIncompleteList
}

/**
 * function returns incomplete auto-package list from same vehicle cart array.
 * @param {boolean} initialLoad
 * @param {number} pageNoFromURL
 * @param {number} initialProductCount
 * @param {number} showMoreClickCount
 * @return {number}
 */
export const productFetchCount = (
    initialLoad: boolean,
    pageNoFromURL: number,
    initialProductCount: number,
    showMoreClickCount: number,
): number => {
    return initialLoad && pageNoFromURL > 0
        ? Number(initialProductCount) + showMoreClickCount * (pageNoFromURL - MagicNumber.ONE)
        : Number(initialProductCount) + Number(showMoreClickCount)
}

/**
 * fuction to check if additional vehicle info present
 * @param {Vehicle[]} list
 * @param { boolean }isSuggestiveDropdownState
 * @return {bollean}
 */
export const checkIfCompleteVehicleState = (list: Vehicle[], isSuggestiveDropdownState: boolean): boolean => {
    return isArrayNotEmpty(list) && !Array.isArray(list[0]) && !isSuggestiveDropdownState
}

/**
 * Function returns true if page will be PLP or SRP
 * because need to set min height for price components element.
 * @return {boolean}
 */

export const overridePriceHeight = (): boolean => {
    const isSRPPage = getPageType() === pageTypes.searchPage
    const isPLPPage = getPageType() === pageTypes.plp
    return isSRPPage || isPLPPage
}

/**
 * Function to create the fitment message based on complete vehicle/partial vehicle/no vehicle state
 * @param { boolean } isSuggestiveDropdownState
 * @param { string } fitmentMessage
 * @param {Vehicle} defaultVehicle
 * @param { string } partialFitMessageOnCard
 * @return {string}
 */
export const getFitmentMessage = (
    isSuggestiveDropdownState: boolean,
    fitmentMessage: string,
    defaultVehicle: Vehicle,
    partialFitMessageOnCard: string,
): string => {
    if (!isSuggestiveDropdownState && checkDataLength(defaultVehicle)) {
        const vehicleInformation = `${defaultVehicle?.autoAttributes?.year as string} ${
            defaultVehicle?.autoAttributes?.make as string
        } ${defaultVehicle?.autoAttributes?.model as string}`
        return replaceStrWithDynamicVal(
            fitmentMessage,
            `<span class=${`${PREFIX}-vehicle-information`}>${vehicleInformation}</span>`,
        )
    } else {
        return `<span class=${`${PREFIX}-not-fit-message`}>${partialFitMessageOnCard}</span>`
    }
}

/**
 * Function to return fitment icon based on complete vehicle/partial vehicle/no vehicle state
 * @param { boolean }isSuggestiveDropdownState
 * @param {Vehicle} defaultVehicle
 * @return {string}
 */
export const getFitmentIcon = (isSuggestiveDropdownState: boolean, defaultVehicle: Vehicle): string => {
    return !isSuggestiveDropdownState && checkDataLength(defaultVehicle)
        ? 'ct-notification-success-green'
        : 'ct-notification-caution-orange'
}

/**
 * Function to return object for cart api call.
 * @param { ProductResponseData }productData
 * @param { boolean } isTirshowVehicleInfoeWheel
 * @param { VehicleState } vehicle
 * @param { boolean } isTireWheel
 * @return {AddProductToCartGuestRequestDTO}
 */
export const constructCartRequestObj = (
    productData: ProductResponseData,
    showVehicleInfo: boolean,
    vehicle: VehicleState,
    isTireWheel?: boolean,
    isSTH?: boolean,
): AddProductToCartGuestRequestDTO => {
    const cartGUID = localStorageService.getItem('cart-guid') as string
    const code = productData?.skus?.[0]
        ? productData?.skus?.[0]?.code
        : productData?.code?.toUpperCase().replace('P', '')
    const deliveryModeType = isSTH ? FulfillmentMethods.STH : FulfillmentMethods.BOPIS

    let productInfoPayload = {
        product: {
            code: code,
        },
        quantity: !!isTireWheel ? 4 : 1,
        deliveryPointOfService: {
            name: appCacheService.preferredStoreId.get(),
        },
        deliveryMode: { code: deliveryModeType },
    }
    if (showVehicleInfo) {
        productInfoPayload = { ...productInfoPayload, ...{ vehicleInformation: createVehiclePayload(vehicle) } }
    }
    return {
        guid: cartGUID,
        entries: {
            orderEntries: [productInfoPayload],
        },
    }
}

/**
 * function to return true if we want to show add button on PLP cards and recommendation cards
 * @param {ProductDataObj} productData
 * @returns {boolean}
 */
export const isSellableAndInStockGenMerch = (productData: ProductDataObj | ProductCardType): boolean => {
    const { fulfillment } = productData
    const { availability } = (fulfillment as unknown as iProductFulfillment) || {}
    const qty = availability?.quantity || (fulfillment as unknown as ItemAvailability)?.quantity

    const isCorpObj = availability?.Corporate || (fulfillment as unknown as ItemAvailability)?.Corporate
    const DCQty = isCorpObj ? isCorpObj.Quantity : 0
    const isProductSellable = productData?.sellable === true
    const isProductOrderable = productData?.orderable === true
    return isProductSellable && (qty > 0 || (DCQty > 0 && isProductOrderable))
}

/**
 * function to return analytics object for add to cart
 * @param {CartModificationsDTO[]} finalCartModifications
 * @param {boolean} isRecommendationsCarousel
 * @param {boolean} isMiniPDP
 * @param {string} fulfillmentType
 * @param {string} atcLocation
 * @returns {void}
 */
export const atcAnalytics = (
    finalCartModifications: CartModificationsDTO[],
    isRecommendationsCarousel = false,
    isMiniPDP?: boolean,
    fulfillmentType?: string,
    atcLocation?: string,
): void => {
    const cartGUID = localStorageService.getItem('cart-guid')
    const location = isMiniPDP ? analyticsAttributes.eventParameters?.location?.miniPDP : getPageType()
    addToCartAnalytics(
        finalCartModifications,
        cartGUID,
        isRecommendationsCarousel && !isMiniPDP ? getPageType() : location,
        isRecommendationsCarousel
            ? analyticsAttributes.eventParameters?.labels?.recommendationAddToCart
            : analyticsAttributes.eventParameters?.labels?.plpAddToCart,
        false,
        false,
        false,
        false,
        undefined,
        undefined,
        fulfillmentType ?? '',
        atcLocation ?? '',
    )
}

/**
 * function to return true if sku length = 1
 * @param {ProductDataObj} productData
 * @returns {boolean}
 */
const singleSkuProduct = (productData: ProductDataObj): boolean => {
    return productData?.skus?.length === 1
}

/**
 * function to return sku object
 * @param {ProductDataObj} productData
 * @returns {ProductSku}
 */
const getSkuDataFromProductData = (productData: ProductDataObj): ProductSku => {
    return productData?.skus[0]
}

/**
 * function to return true if we have tires/wheels in stock
 * @param {ProductDataObj} productData
 * @param {boolean} isCompleteVehicleState
 * @param {boolean} isTireDataExist
 * @returns {boolean}
 */
const isTireWheelInStock = (
    productData: ProductDataObj,
    isCompleteVehicleState: boolean,
    isTireDataExist: boolean,
): boolean => {
    const skuData = getSkuDataFromProductData(productData)
    const orderable = skuData?.orderable
    const quantity = skuData?.fulfillment?.availability?.quantity || 0
    const corpQuantity = skuData?.fulfillment?.availability?.Corporate?.Quantity || 0
    return (
        (isCompleteVehicleState || isTireDataExist) &&
        (quantity >= 4 || (quantity + corpQuantity >= 4 && orderable === true))
    )
}

/**
 * function to return true if we have auto parts in stock
 * @param {ProductDataObj} productData
 * @returns {boolean}
 */
const isAutoPartInStock = (productData: ProductDataObj): boolean => {
    const isFit = getSkuDataFromProductData(productData)?.fitment?.autoPartsFit === 'directFit'
    const skuData = getSkuDataFromProductData(productData)
    const orderable = skuData?.orderable
    const quantity = skuData?.fulfillment?.availability?.quantity || 0
    const corpQuantity = skuData?.fulfillment?.availability?.Corporate?.Quantity || 0
    return isFit && (quantity > 0 || (corpQuantity > 0 && orderable === true))
}

/**
 * function to return true if we want to show add button
 * @param {ProductDataObj} productData
 * @param {boolean} completeVehicleInfo
 * @param {boolean} isTireDataExist
 * @param {boolean} isTireOrWheelPLP
 * @returns {boolean}
 */
export const isSellableAndInStockAuto = (
    productData: ProductDataObj,
    completeVehicleInfo: boolean,
    isTireDataExist: boolean,
    isTireOrWheelPLP: boolean,
): boolean => {
    const isSingleSkuProduct = singleSkuProduct(productData)
    const skuData = getSkuDataFromProductData(productData)
    const sellable = skuData?.sellable
    return (
        isSingleSkuProduct &&
        sellable &&
        ((isTireOrWheelPLP && isTireWheelInStock(productData, completeVehicleInfo, isTireDataExist)) ||
            isAutoPartInStock(productData))
    )
}

/**
 * Below function returns multi select data
 * @param { QuickLink[] } quickLinks
 * @param { number } maxRequired
 * @returns { MultiSelectItem[] }
 */
export const returnMultiSelectData = (quickLinks: QuickLink[], maxRequired: number): MultiSelectItem[] => {
    return quickLinks.slice(0, maxRequired).map(item => {
        return {
            multiSelectElement: item.label,
            id: item.id,
            selected: item.selected,
            url: item.url,
            clearUrl: item.clearUrl,
        }
    })
}

/**
 * trigger braze custom event when user performs search on search bar
 * @param {string} searchKeyword searched keyword
 * @param {ProductDataType} productCardData searched product list
 * @returns {void}
 */
export const triggerSearchBrazeCustomEvent = (searchKeyword: string, productCardData?: ProductDataType): void => {
    // Log braze custom event when personalized offer is selected
    brazeService.logCustomEvent(brazeCustomEvents.event.webSearch, {
        ...getSourceParam(getOfferBannerId()),
        search_results: productCardData?.resultCount || '',
        search_term: searchKeyword,
        search_auto_correct: productCardData?.autoCorrectQuery || '',
    })
}

/**
 * trigger braze custom event when user views clearance or sale pages
 * @returns {void}
 */
export const triggerBrazeCustomEvent = (): void => {
    // Log braze custom event when personalized offer is selected
    const eventName =
        getBrazeEventName() === brazeCustomEventsPages.clearance
            ? brazeCustomEvents.event.webClearanceView
            : brazeCustomEvents.event.webSalesView

    brazeService.logCustomEvent(eventName, {
        ...getSourceParam(getOfferBannerId()),
    })
}

/**
 * function to get the prdoct information for compare functionality
 * @param {ProductResponseData} entry product entry
 * @returns {CompareProductDataType} return CompareProductDataType object
 */
export const getCompareProductData = (entry: ProductResponseData): CompareProductDataType => {
    return {
        code: entry?.code,
        url: entry?.url,
        rating: entry?.rating,
        ratingsCount: entry?.ratingsCount,
        images: entry?.images,
        title: entry?.title,
        brand: entry?.brand,
        badges: entry?.badges,
        currentPrice: entry?.currentPrice,
        skus: entry?.skus,
        isOnSale: entry?.isOnSale,
    }
}

/**
 * function to update the products array for compare similar products functionality
 * @param {CompareProductDataType} compareSimilarProducts array of products
 * @param {CompareProductDataType} productObj product to add or remove
 * @param {boolean} isCompareProductChecked product is checked or not
 * @returns {void} return void
 */
export const updateCompareProductData = (
    compareSimilarProducts: CompareProductDataType[],
    productObj: CompareProductDataType,
    isCompareProductChecked: boolean,
): void => {
    if (isCompareProductChecked) {
        const productsList = [...compareSimilarProducts]
        const removeIndex = productsList.findIndex(productItem => productItem.code === productObj?.code)
        productsList.splice(removeIndex, 1)
        localStorageService.setItem(compareProductList, JSON.stringify([...productsList]))
    } else {
        localStorageService.setItem(
            compareProductList,
            JSON.stringify([...compareSimilarProducts, getCompareProductData(productObj)]),
        )
    }
}
