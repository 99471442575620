import React from 'react'

import { PREFIX } from '../config'
import { ILabelValue, SuccessScreenProps, CTATypes } from './SuccessScreen.type'
import Button from '../Button'
import { SpinnerButton } from '../ButtonV2'

import BrandFooter from '../BrandFooter'
import { magicNumber } from '../../utils'
import Icon, { BaseIcon } from '../Icon'
import { ButtonSizes, ButtonTypes } from '../Button/Button.types'
import { linkTargetOptions } from '../../globalConstants/global.constant'
import Heading from '../Heading'
import { IVariant } from '../Heading/Heading.types'
import { Variation, Size, Animation } from '../../types'

/**
 * Error Screen
 * @param {SuccessScreenProps} props
 * @return {JSX.Element} returns Error screen
 */
const SuccessScreen: React.FC<SuccessScreenProps> = ({ ...props }) => {
    const {
        headerBrandLogo,
        footerBrandLogo,
        successLogo,
        successMessageHeader,
        successMessageSubHeader,
        labelValueArray,
        successScreenContent,
        successScreenFooterContent,
        isDivider,
        stackedView,
        ctaList,
        headingVariant,
    } = props.data
    const { children } = props

    const componentName = `${PREFIX}-success-card`
    const successMessageHeaderVariant = headingVariant ? headingVariant : IVariant.H2

    /**
     * renders block with image and optional divider
     * @param {string} image
     * @param {string} altText
     * @param {string} classname
     * @param {boolean} showDivider
     * @return {JSX.Element}
     */
    const renderBranding = (
        image: string,
        altText?: string,
        classname?: string,
        showDivider?: boolean,
    ): JSX.Element => <BrandFooter image={image} altText={altText} classname={classname} showDivider={showDivider} />

    /**
     * function to render header section
     * @return {JSX.Element}
     */
    const renderImageHeader = (): JSX.Element => (
        <>
            {headerBrandLogo &&
                renderBranding(headerBrandLogo.image, headerBrandLogo.imageAlt, `${componentName}__header`)}
            <div className={`${componentName}__logo`}>
                <img src={successLogo.image} alt={successLogo.imageAlt} />
            </div>
        </>
    )

    /**
     * renders pair of label: value
     * @return {JSX.Element[]}
     */
    const renderLabelValuePairs = (): JSX.Element[] => {
        return labelValueArray.map((item: ILabelValue, index) => {
            return (
                <div key={index} className={`${componentName}__info-pair${stackedView ? `--stacked` : ``}`}>
                    {stackedView ? (
                        <>
                            <p aria-hidden={item?.a11LabelHidden} dangerouslySetInnerHTML={{ __html: item.label }} />
                            {item?.a11AriaLabel && <span className="sr-only">{item?.a11AriaLabel}</span>}
                        </>
                    ) : (
                        <>
                            <div aria-hidden={item?.a11LabelHidden} dangerouslySetInnerHTML={{ __html: item.label }} />
                            {item?.a11AriaLabel && <span className="sr-only">{item?.a11AriaLabel}</span>}
                        </>
                    )}
                    <>
                        <span
                            aria-hidden={item?.a11ValueHidden}
                            className={`${componentName}__info-pair-value`}
                            dangerouslySetInnerHTML={{ __html: item.value }}
                        />
                        {item?.a11AriaValueLabel && <span className="sr-only">{item?.a11AriaValueLabel}</span>}
                    </>
                </div>
            )
        })
    }

    /**
     * renders cta as Button version 1 (this is planned for depreciation soon once ButtonV2 is implemented completely)
     * @param {CTATypes} cta // cta as per version 1completely
     * @param {number} i // index
     * @returns {JSX.Element}
     */
    const renderV1Cta = (cta: CTATypes, i: number): JSX.Element => {
        return cta.url ? (
            <a
                href={cta.url}
                key={i}
                target={cta.target}
                aria-label={cta.a11yLabel}
                className={`${PREFIX}-button ${PREFIX}-button--${cta.type || 'primary'} ${cta.ctaClassName ?? ''}`}>
                {cta.label}
                {cta.target === linkTargetOptions.newTarget && <Icon type="ct-north-east" size="lg" />}
            </a>
        ) : (
            <Button
                type={(cta.type as ButtonTypes) || 'primary'}
                size={(cta.size as ButtonSizes) || 'large'}
                onClick={cta.btnFunc}
                key={i}
                showSpinner={Boolean(cta.showSpinner)}
                ariaLabel={cta.a11yLabel}>
                {cta.label}
                {cta.target === linkTargetOptions.newTarget && <Icon type="ct-north-east" size="lg" />}
            </Button>
        )
    }

    /**
     * renders cta as Button version 2
     * @param {CTATypes} cta // cta as per version 2
     * @param {number} i // index
     * @returns {JSX.Element}
     */
    const renderV2Cta = (cta: CTATypes, i: number): JSX.Element => {
        return cta.url ? (
            <a
                href={cta.url}
                key={i}
                target={cta.target}
                aria-label={cta.a11yLabel}
                className={`${PREFIX}-button2
                     ${PREFIX}-button2--${cta.variant || 'primary'}
                     ${PREFIX}-button2--${cta.variant || 'primary'}-${cta.color || 'default'}
                     ${cta.ctaClassName ?? ''}`}>
                {cta.label}
                {cta.target === linkTargetOptions.newTarget && <Icon type="ct-north-east" size="lg" />}
            </a>
        ) : (
            <SpinnerButton
                variant={cta.variant || Variation.PRIMARY}
                color={cta.color}
                size={Size.MEDIUM}
                onClick={cta.btnFunc}
                key={i}
                showSpinner={Boolean(cta.showSpinner)}
                icon={cta.target === linkTargetOptions.newTarget ? { type: 'ct-north-east', size: 'lg' } : null}
                a11y={{
                    label: cta.a11yLabel,
                }}>
                {cta.showBaseIcon && <BaseIcon animation={{ type: Animation.PULSE }} size={Size.XL} outline={true} />}
                {cta.label}
            </SpinnerButton>
        )
    }

    /**
     * renders ctaList based on button version
     * @returns {JSX.Element[]}
     */
    const renderCTA = (): JSX.Element[] => {
        return ctaList?.map((cta: CTATypes, i: number): JSX.Element => {
            const btnV2 = Boolean(cta.btnVersion === 2)
            return btnV2 ? renderV2Cta(cta, i) : renderV1Cta(cta, i)
        })
    }

    return (
        <div className={componentName}>
            <div className={`${componentName}__content-wrapper`}>
                {renderImageHeader()}
                {successMessageHeader && (
                    <Heading variant={successMessageHeaderVariant} componentClass={`${componentName}__title`}>
                        {successMessageHeader}
                    </Heading>
                )}
                {successMessageSubHeader && (
                    <p
                        className={`${componentName}__subtitle`}
                        dangerouslySetInnerHTML={{ __html: successMessageSubHeader }}
                    />
                )}
                {labelValueArray && renderLabelValuePairs()}
                {children}
                {successScreenContent && (
                    <div
                        className={`${componentName}__content`}
                        dangerouslySetInnerHTML={{ __html: successScreenContent }}
                    />
                )}
                {ctaList.length > magicNumber.ONE ? (
                    <div className={`${componentName}__cta`}>{renderCTA()}</div>
                ) : (
                    renderCTA()
                )}
                {successScreenFooterContent && (
                    <div
                        className={`${componentName}__content`}
                        dangerouslySetInnerHTML={{ __html: successScreenFooterContent }}
                    />
                )}
            </div>
            {footerBrandLogo &&
                renderBranding(footerBrandLogo.image, footerBrandLogo.imageAlt, `${componentName}__footer`, isDivider)}
        </div>
    )
}

export default SuccessScreen
